





































































  import PeopleIcon from "../../view-models/PeopleIcon";
  import Notifications from "./Notifications.vue";
  import PeopleDefaultIcon from "./PeopleDefaultIcon.vue";

  export default {
    name: "PageHeader",
    components: { PeopleDefaultIcon, Notifications },
    data() {
      return {
        text: {
          viewAccount: this.$t("viewAccount"),
          hello: this.$t("hello"),
          signOut: this.$t("signOut")
        }
      };
    },
    computed: {
      languageSelected: function (): string {
        if (this.getLanguage == "ES") {
          return "ES";
        } else {
          return "EN";
        }
      },
      userPeopleIcon: function (): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(this.getUser);
      }
    },
    methods: {
      async signOut() {
        await this.logOut();
      },
      goToHomePage() {
        if (this.$router.currentRoute.name != "projects") {
          this.$router.push("/home");
        }
      },
      async changeLanguage(lang) {
        if (this.$i18n.locale != lang) {
          this.$i18n.locale = lang; // Language Plugin
          this.setLanguage(lang); // Local Storage
          localStorage.setItem("sogetiHubLanguage", lang);
          if (this.getUser) {
            this.getUser.setLanguage = lang;
            await this.putPeople(this.getUser);
            this.$router.go(0);
          }
        }
      }
    }
  };
