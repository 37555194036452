import DocumentDx from "../../models/deliveryexperience/DocumentDx";
import utils from "../../utils/utils";
import service from "../service";
import baseEndpoint from "./serviceDx";

const documentEndpoint = baseEndpoint + "/Document";

export default {
  components: { service: service },
  methods: {
    async getDocsByProject(projectId: number): Promise<DocumentDx[]> {
      const response = await service.methods.getEndpointAsync(
        `${documentEndpoint}/project/${projectId}`
      );
      return response.map(doc => new DocumentDx(doc));
    },
    async putDocument(modifiedDocument: DocumentDx): Promise<DocumentDx> {
      const response = await service.methods.putEndpointAsync(
        `${documentEndpoint}/${modifiedDocument.getId}`,
        modifiedDocument
      );
      return new DocumentDx(response);
    },
    async deleteDocument(id: string): Promise<any> {
      return await service.methods.deleteEndpointAsync(
        `${documentEndpoint}/${id}`
      );
    },
    async postDocumentAndBlob(formData): Promise<DocumentDx> {
      const response = await service.methods.postEndpointFormDataAsync(
        documentEndpoint,
        formData
      );
      return new DocumentDx(response);
    },
    async getDocumentVersions(filename: string): Promise<any> {
      const response = await service.methods.getEndpointAsync(
        `${documentEndpoint}/file/${filename}/versions`
      );
      return response;
    },
    async downloadDocumentFromBlob(
      filename: string,
      versionId: string = ""
    ): Promise<any> {
      const response = await service.methods.getBlobEndpointAsync(
        `${documentEndpoint}/file/${filename}/${versionId}`
      );

      // Parsing legacy uploads type (octet streams) by its name extension instead of mime type
      let type = response.type;
      if (response.type === "application/octet-stream")
        type = utils.getContentType(filename.split(".").pop(), true);

      return { blob: response, type };
    }
  }
};
