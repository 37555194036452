












































































  import { projectTypes } from "../../_helpers/projectMetadata";
  import {
    getExtensionIcon,
    getServiceIcon,
    getServiceUrl,
    servicesExtensions
  } from "../../_helpers/servicesExtensions";
  import ProjectCore from "../../models/core/ProjectCore";

  export default {
    name: "project-header",
    props: {
      service: String,
      extension: String,
      expandNavbar: Boolean
    },
    data() {
      return {
        servicesExtensions,
        icon: "",
        logo: undefined,
        header: undefined
      };
    },
    computed: {
      projectCore: function (): ProjectCore {
        return this.getProjectById(Number(this.$route.params.projectId));
      },
      projectTheme(): boolean {
        return this.projectCore.getTheme;
      }
    },
    methods: {
      getServiceIcon(serviceName: string): NodeRequire | undefined {
        if (serviceName) {
          return getServiceIcon(serviceName);
        }
        return undefined;
      },
      getExtensionIcon(extensionName: string): NodeRequire | undefined {
        if (extensionName) {
          return getExtensionIcon(extensionName);
        }
        return undefined;
      },
      goToService(service) {
        this.$router.push(
          `/${getServiceUrl(service)}/${this.$route.params.projectId}`
        );
      },
      determineType: function (type): string {
        switch (type) {
          case projectTypes.liabilityTemporary.name:
            return this.$tc(projectTypes.liabilityTemporary.i18n, 1);
          case projectTypes.liabilityEconomic.name:
            return this.$tc(projectTypes.liabilityEconomic.i18n, 1);
          case projectTypes.liabilityFull.name:
            return this.$tc(projectTypes.liabilityFull.i18n, 1);
          case projectTypes.timeAndMaterials.name:
            return this.$t(projectTypes.timeAndMaterials.i18n);
          case projectTypes.capacity.name:
            return this.$t(projectTypes.capacity.i18n);
          case projectTypes.QBR.name:
            return this.$tc(projectTypes.QBR.i18n, 1);
          case projectTypes.CSP.name:
            return this.$tc(projectTypes.CSP.i18n, 1);
          default:
            return "Unknown type";
        }
      }
    }
  };
