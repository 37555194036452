export interface IMethodologyDx {
  phase: number;
  scope: number;
  artifactName?: string;
}

export default class MethodologyDx {
  private phase: number;
  private scope: number;
  private artifactName?: string;

  constructor(dto: IMethodologyDx) {
    Object.assign(this, dto);
  }

  get getPhase(): number {
    return this.phase;
  }

  set setPhase(value: number) {
    this.phase = value;
  }

  get getScope(): number {
    return this.scope;
  }

  set setScope(value: number) {
    this.scope = value;
  }

  get getArtifactName(): string {
    return this.artifactName;
  }

  set setArtifactName(value: string) {
    this.artifactName = value;
  }
}
