<template>
  <div class="img-container">
    <div @change="changeCroppedImg" id="croppie"></div>
  </div>
</template>

<script>
  import Croppie from "croppie";
  import "croppie/croppie.css";

  export default {
    props: ["imgUrl"],
    data() {
      return {
        image: null,
        croppie: null
      };
    },
    mounted() {
      this.setUpCroppie();
    },
    methods: {
      setUpCroppie() {
        if (this.croppie) {
          this.croppie.destroy();
        }
        this.image = this.imgUrl;
        const el = document.getElementById("croppie");
        this.croppie = new Croppie(el, {
          viewport: { width: "100%", height: 56 },
          boundary: { width: "100%", height: 120 },
          showZoomer: false,
          enableOrientation: true
        });
        this.croppie.bind({
          url: this.image,
          zoom: 0
        });
        el.addEventListener("update", this.updateImage);
      },
      updateImage(ev) {
        this.image = ev.detail;
      },
      changeCroppedImg() {
        this.croppie
          .result({
            type: "canvas",
            quality: 1,
            imageSmoothingQuality: "high",
            size: "original"
          })
          .then(response => {
            this.image = response;
            this.$emit("changeImage", this.image);
          });
      }
    },
    watch: {
      imgUrl: function () {
        this.setUpCroppie();
      }
    },
    beforeDestroy() {
      if (this.croppie) {
        this.croppie.destroy();
      }
    }
  };
</script>
