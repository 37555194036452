import MarginCSP from "../../models/csp/MarginCSP";
import service from "../service";
import baseEndpoint from "./serviceCSP";

const endpoint = baseEndpoint + "/Margin";

export default {
  components: { service: service },
  methods: {
    async getMargins(): Promise<MarginCSP[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(margin => new MarginCSP(margin));
    },

    async getMarginById(customerId: string): Promise<MarginCSP> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/margin/${customerId}`
      );
      return response.map(margin => new MarginCSP(margin));
    },
    async postMargin(margin: MarginCSP): Promise<MarginCSP> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        margin
      );
      return new MarginCSP(response);
    },
    async putMargin(margin: MarginCSP): Promise<MarginCSP> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${margin.getCustomerId}`,
        margin
      );
      return new MarginCSP(response);
    }
  }
};
