






































































  import Card from "../../components/view_elements/Card.vue";
  import HiredSwitch from "../../components/view_elements/HiredSwitch.vue";
  import ServiceCore from "../../models/core/ServiceCore";

  export default {
    name: "ServicesTab",
    components: { Card, HiredSwitch },
    data() {
      return {
        text: {
          noServicesYet: this.$t("noServicesYetFor")
        },
        displayHiredOnly: Boolean
      };
    },
    created: async function () {
      await this.loadHiredServicesByClientId(this.getUser.getClientId);
    },
    methods: {
      servicesInCategory: function (categoryId: number): ServiceCore[] {
        return this.getServicesByCategoryId(categoryId);
      },
      hiredService: function (serviceId): boolean {
        for (const service of this.getHiredServicesByClientId(
          this.getUser.getClientId
        )) {
          if (service.getId == serviceId) return true;
        }
        return false;
      },
      numberHiredServicesInCategory: function (categoryId: number): number {
        let i = 0;
        for (const service of this.servicesInCategory(categoryId)) {
          if (this.hiredService(service.getId)) i++;
        }
        return i;
      },
      numberServices: function (
        displayHiredOnly: boolean,
        categoryId: number
      ): number {
        return displayHiredOnly
          ? this.numberHiredServicesInCategory(categoryId)
          : this.servicesInCategory(categoryId).length;
      },
      descriptionNameService: function (serviceName: string): string {
        serviceName = "serviceDesc" + serviceName.replaceAll(" ", "");
        return serviceName;
      },
      getCategoryIcon: function (categoryIcon): NodeRequire {
        let result;
        try {
          result = require("../../assets/icons/categories/" + categoryIcon);
        } catch (error) {
          result = require("../../assets/icons/placeholders/default-service.png");
        }
        return result;
      }
    }
  };
