import ClientServiceCore from "../../models/core/client/ClientServiceCore";
import ProjectServiceCore from "../../models/core/project/ProjectServiceCore";
import ServiceCore from "../../models/core/ServiceCore";
import service from "../service";
import baseEndpoint from "./serviceCore";

const endpoint = baseEndpoint + "/Services";

export default {
  components: { service: service },
  methods: {
    async getServices(): Promise<ServiceCore[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(service => new ServiceCore(service));
    },
    async getServicesByClient(clientId: number): Promise<ServiceCore[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/client/${clientId}`
      );
      return response.map(service => new ServiceCore(service));
    },
    async getActiveServicesByClient(
      clientId: number
    ): Promise<ProjectServiceCore[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/client/${clientId}/active`
      );
      return response.map(service => new ProjectServiceCore(service));
    },
    async getHiredServicesByClient(
      clientId: number
    ): Promise<ClientServiceCore[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/client/${clientId}/hired`
      );
      return response.map(service => new ClientServiceCore(service));
    }
  }
};
