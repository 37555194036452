export interface IProjectService {
  projectId: number;
  serviceId: number;
  preactivated?: boolean;
}

export default class ProjectService {
  private projectId: number;
  private serviceId: number;
  private preactivated?: boolean;

  constructor(dto: IProjectService) {
    Object.assign(this, dto);
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(projectId: number) {
    this.projectId = projectId;
  }

  get getServiceId(): number {
    return this.serviceId;
  }

  set setServiceId(serviceId: number) {
    this.serviceId = serviceId;
  }

  get getPreactivated(): boolean {
    return this.preactivated;
  }

  set setPreactivated(preactivated: boolean) {
    this.preactivated = preactivated;
  }
}
