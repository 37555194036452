var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticStyle:{"display":"block","padding":"5px","border-bottom":"solid 1px #ececec","margin-left":"0px","margin-right":"0px"}},[_c('b-col',[_c('button',{staticClass:"btn button-hover collapse-transition pl-0",class:{ 'collapse-transition-open': _vm.collapse },on:{"click":function($event){return _vm.switchCollapseZone()}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"20"},attrs:{"icon":"fa-solid fa-chevron-down"}}),_c('p',{staticClass:"mb-0 text-truncate",attrs:{"title":_vm.product.title}},[_c('b',[_vm._v(_vm._s(_vm.product.title))])])],1)]),(_vm.collapse)?_c('b-col',{staticClass:"ml-3"},_vm._l((_vm.skus),function(sku){return _c('div',{key:_vm.product.getId + '-' + sku.id},[_c('b-row',{staticClass:"pt-3 ml-2"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b',{staticClass:"align-items-center justify-content-center"},[_c('i',[_vm._v(_vm._s(sku.title))])]),_c('div',{staticClass:"text-link",attrs:{"id":("description-link-" + (sku.productId) + "-" + (sku.id))},on:{"click":function($event){return _vm.toggleProductDescription(sku)}}},[_vm._v(" "+_vm._s(_vm.text.fullDescription)+" ")])]),_c('b-col',{staticClass:"d-flex",attrs:{"cols":"4"}},[_c('input',{staticClass:"input",attrs:{"id":("sku-quantity-" + (sku.productId) + "-" + (sku.id)),"type":"number","pattern":"[0-9]*","min":sku.minimumQuantity,"max":sku.maximumQuantity > 300 ? 300 : sku.maximumQuantity},domProps:{"value":sku.minimumQuantity},on:{"change":function($event){return _vm.restrictionsQuantity(
                $event,
                sku.minimumQuantity,
                sku.maximumQuantity > 300 ? 300 : sku.maximumQuantity
              )}}}),(
              !_vm.cartProducts.find(
                function (p) { return p.productId === sku.productId && p.id === sku.id; }
              )
            )?_c('button',{staticClass:"button-hover btn btn-sm mt-1",staticStyle:{"background-color":"transparent","border":"0","align-self":"flex-start"},on:{"click":function($event){return _vm.addToCart(sku)}}},[_c('img',{staticClass:"switch-button-carousel",staticStyle:{"z-index":"3"},attrs:{"height":"25","src":require("../../../../assets/icons/services/csp/operations/cart-circle-plus.svg")}})]):_c('button',{staticClass:"button-hover btn btn-sm mt-1",staticStyle:{"background-color":"transparent","border":"0","align-self":"flex-start","cursor":"default"}},[_c('img',{staticClass:"switch-button-carousel",staticStyle:{"z-index":"3"},attrs:{"height":"25","src":require("../../../../assets/icons/services/csp/operations/cart-circle-check.svg")}})])])],1),_c('b-row',[_c('b-col',{staticClass:"ml-4"},[_c('div',{staticClass:"mt-2",staticStyle:{"display":"none","text-align":"justify","text-justify":"inter-word"},attrs:{"id":("product-description-" + (sku.productId) + "-" + (sku.id))},domProps:{"innerHTML":_vm._s(sku.description)}})])],1)],1)}),0):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }