import AuthService from ".";
import config from "../config";

let authSrv: AuthService;

/**
 * Acts as a singleton.
 */
function get(): AuthService {
  if (authSrv == null) {
    authSrv = new AuthService(config.dxScope);
  }
  return authSrv;
}

export default {
  get
};
