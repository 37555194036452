export default {
  spanish: "Español",
  english: "Inglés",
  hello: "Hola",
  signOut: "Cerrar sesión",
  search: "Buscar",
  notifications: "Notificaciones",
  noNotifications: "No hay notificaciones",
  noNewNotificationsToRead: "No hay notificaciones nuevas sin leer",
  hasBeenResolved: "ha sido resuelta",
  hasBeenClosed: "ha sido cerrada",
  hasBeenCommented: "ha sido comentada",
  seeAllNotifications: "Ver todas las notificaciones",
  service: "Servicio | Servicios",
  servicesLowCase: "servicio",
  categories: "Categoría",
  hire: "Contratar",
  hired: "Contratado | Contratados",
  all: "Todos | Todas",
  clear: "Limpiar",
  legalWarning: "Aviso Legal",
  privacyPolicy: "Política de privacidad",
  informationSecurityPolicy: "Política de seguridad de la información",
  contact: "CONTACTO",
  mainContacts: "Contactos",
  responsibleOf: "Responsable de",
  date: "Fecha",
  user: "Usuario | Usuarios",
  userLowCase: "usuario | usuarios",
  exMember: "(Ex-miembro)",
  external: "Externo",
  eventPeopleTitle: "Miembros del evento",
  stagePeopleTitle: "Miembros de la etapa",
  requestPeopleTitle: "Autor de la petición",
  requestThreadTitle: "Comentarios",
  requestRelatedTitle: "Eventos relacionados",
  progress: "Progreso | PROGRESO",
  eventsCompleted: "Eventos completados",
  stagesCompleted: "Etapas completadas",
  noMembers:
    "Este {entity} no tiene miembros. | Esta {entity} no tiene miembros.",
  lackOfPermissionsSection:
    "No tienes permisos suficientes para ver a esta sección.",
  lackOfPermissionsPage: "No tienes permisos suficientes para ver esta página.",
  seeAllMembers: "Ver todos los miembros",
  onlySelectedMembers: "Sólo miembros seleccionados",
  itemAuthor: "Autor del item",
  itemName: "Nombre del item",
  nobody: "Nadie",
  name: "Nombre",
  nickname: "Nombre personalizado",
  enterName: "Introducir nombre",
  enterLastName: "Introducir apellido",
  enterEmail: "Introducir correo electrónico",
  enterJobPosition: "Introducir puesto de trabajo",
  enterProjectRole: "Introducir rol",
  notSpecifiedJobPosition: "Puesto no especificado",
  related: "Relacionado",
  author: "Autor",
  readers: "Lectores",
  noMembersForDocReaders: "Este ítem no tiene miembros.",
  version: "Versión | Versiones",
  download: "Descarga | Descargar",
  downloadAll: "Descargar todos los documentos",
  monthsView: "Vista {months} mes | Vista {months} meses",
  lastMonths: "Últimos {months} meses",
  allProject: "Todo el proyecto",
  affectedEvents: "Eventos afectados",
  affectedStages: "Etapas afectadas",
  objective: "Objetivo | Objetivos",
  member: "Miembro | Miembros",
  hour: "Hora | Horas",
  seeAll: "Ver todas",
  seeMore: "Ver más",
  admin: "Administrador | Administradores",
  youAreAdmin: "Eres administrador",
  makeAdmin: "Hacer administrador",
  manager: "Manager | Managers",
  welcomeBack: "Bienvenido/a",
  enter: "Acceder",
  for: "Para | para",
  moreInformation: "Más información | más información",
  visitWebsite: "visitar sitio web",
  usernameIsRequired: "Nombre de usuario requerido",
  password: "Contraseña | contraseña",
  email: "Correo",
  signUp: "Registrar",
  signUpUser: "Dar de alta nuevo usuario",
  userRegistration: "Registro de usuario",
  firstName: "Nombre",
  lastName: "Apellido",
  username: "Nombre de usuario",
  language: "Idioma",
  confirm: "Confirmar",
  cancel: "Cancelar",
  restore: "Restaurar",
  deletePermanently: "Eliminar permanentemente",
  deleteAll: "Eliminar todo",
  project: "Proyecto | proyecto",
  projects: "Proyectos",
  myProjects: "Mis proyectos",
  newProject: "Nuevo proyecto",
  duration: "Duración | duración",
  team: "Equipo",
  client: "Cliente | Clientes",
  clientManagement: "Gestión del cliente",
  role: "Rol",
  startDate: "Fecha de inicio",
  projectType: "Tipo de proyecto",
  projectTeam: "Equipo",
  teamMember: "Miembro del equipo",
  noTeamMembers:
    "No hay nadie asignado al equipo del proyecto. Puedes añadir a alguien creando un evento <b>{event}</b>.",
  start: "Inicio",
  end: "Fin",
  endDate: "Fecha de fin",
  closingDate: "Fecha de cierre",
  deletedDate: "Fecha de eliminación",
  modified: "Modificado",
  left: "Quedan",
  daysFor: "días para",
  invalidEmailAddress: "Dirección de correo inválida",
  unableToConnectSogetiSpainHubServices:
    "No se puede conectar con los servicios de Sogeti Hub",
  account: "Cuenta",
  viewAccount: "Ver cuenta",
  action: "Acción | Acciones",
  information: "Información",
  alert: "Alerta",
  warning: "Peligro",
  ascendant: "Ascendente",
  descending: "Descendente",
  resultsFound: "resultados encontrados",
  cleanFilters: "Limpiar filtros",
  page: "Página | PÁGINA",
  of: "de | DE",
  previous: "Previas",
  preview: "Previsualización",
  next: "Próximo | Próxima",
  nextEvent: "Próximo evento",
  nextStage: "Próxima etapa",
  currentStage: "Etapa actual| Etapas actuales",
  description: "Descripción",
  the: "El | el",
  edit: "Editar",
  save: "Guardar",
  sortBy: "Ordenar por",
  editProject: "Edición de proyecto",
  addNewJob: "Intro para añadir nuevo puesto",
  incorporationDate: "Fecha de incorporación",
  incorporation: "Incorporación",
  thereAreNoProjects: "No hay proyectos",
  noServicesYetFor: "Aún no hay servicios activos para",
  serviceNotHired: "Este servicio no está contratado.",
  thereAreNoExtensions: "Este servicio no tiene extensiones.",
  create: "Crear",
  editRemove: "Editar/Eliminar",
  newVersionMsg:
    "Hay una nueva versión disponible. Refrescar la página para ver los cambios.",
  newEvent: "Nuevo evento",
  newStage: "Nueva etapa",
  addRelatedEvent: "Añadir evento relacionado",
  editEvent: "Editar evento",
  editStage: "Editar etapa",
  editRequest: "Editar petición",
  eventTitle: "Título del evento",
  title: "Título",
  type: "Tipo",
  attachments: "Adjunto | Adjuntos",
  searchProject: "Buscar proyecto",
  searchAMember: "Buscar un miembro",
  searchSomeone: "Buscar a alguien",
  delete: "Eliminar",
  teamManagement: "Gestión de equipo",
  permissionsManagement: "Gestión de permisos",
  newDocument: "Nuevo documento",
  not_added_document: "Ningún documento añadido",
  accept: "Aceptar",
  extension: "Extensión | Extensiones",
  noServiceQBR: "Servicio no disponible para proyectos QBR.",
  from: " de ",
  stage: "Etapa | etapa",
  stages: "Etapas",
  today: "Hoy",
  event: "Evento | evento",
  events: "Eventos",
  eventDescChangeRequest:
    "Representa una petición de cambio en algun aspecto relacionado con la responsabilidad del proyecto.",
  eventDescReload:
    "Indica una recarga en la cantidad total de unidades de capacidad del proyecto.",
  eventDescBilling:
    "Refiere a una actividad o proceso relacionado con la facturación del proyecto.",
  eventDescConsume:
    "Indica un consumo de las unidades de capacidad en ciertas actividades del proyecto.",
  eventDescQBR:
    "Se refiere a una Quarterly Business Review, para la evaluación del progreso, rendimiento, y objetivos del proyecto durante un cuatrimestre específico.",
  eventDescCSPOperation: "Refiere a una operación de CSP realizada.",
  eventDescActivity: "Representa una actividad o tarea general del proyecto.",
  eventDescKickOff: "Marca el inicio del proyecto.",
  eventDescFollowUp:
    "Indica una reunión o actividad de seguimiento relacionada con el proyecto.",
  eventDescMilestone:
    "Representa una meta o logro significativo en el proyecto.",
  eventDescClosing:
    "Refiere al cierre o completitud de una fase del proyecto o de todo el proyecto.",
  eventDescMajorIssue:
    "Alerta sobre un problema o bloqueante grave encontrado en el proyecto.",
  eventDescMinorIssue:
    "Denota un problema o incidencia leve afrontada en el proyecto.",
  eventDescProposalResolution:
    "Provee la propuesta o resolución de una cuestión o demanda abierta en el proyecto.",
  eventDescVirtualVisit:
    "Refiere a una reunión virtual o demo convocada para el proyecto.",
  eventDescWorkshopTraining:
    "Convoca una sesión de workshop o formación llevada a cabo como parte del proyecto.",
  eventDescNewIncorporation:
    "Representa la incorporación de un recurso individual en el proyecto.",
  eventDescUnassignment: "Indica la desasignación de un recurso del proyecto.",
  requestDescServiceRequest:
    "Solicita la activación de un servicio para el proyecto.",
  requestDescQuestion:
    "Plantea una pregunta o consulta relacionada con el proyecto.",
  requestDescVirtualVisitRequest:
    "Solicita una reunión virtual o demo sobre el proyecto.",
  projectPeopleIncludedByDefault:
    "Los miembros del proyecto se incluirán por defecto como lectores si no se ha seleccionado a nadie.",
  issues: "Incidencias",
  dragAndDropOr: "Arrastrar y soltar un archivo aquí (max 20MB)",
  fileTooLarge: "El archivo es demasiado grande. El límite son 20MB",
  fileTooShort: "El tamaño del archivo tiene que ser mayor que 0",
  fileFormat: "El formato de este archivo no es valido",
  noDocumentsUploaded: "No hay documentos subidos visibles para ti.",
  maxSizeDocs: "Arrastra y suelta un archivo aquí (max 20MB).",
  versionOverwrite: "Se generará una nueva versión para el fichero existente.",
  versionCopy:
    "Se conservará el fichero existente generando una copia para el fichero subido.",
  upload: "Subir",
  selectAnOption: "Seleccionar una opción",
  select: "Seleccionar",
  selectOne: "Seleccionar uno",
  selectService: "Seleccionar un servicio",
  selectDate: "Seleccionar una fecha",
  selectAction: "Seleccionar una acción",
  selectLanguage: "Seleccionar idioma",
  selectRole: "Seleccionar rol",
  selectType: "Seleccionar tipo",
  selectAnItem: "Seleccionar un ítem",
  selectAnEvent: "Seleccionar un evento",
  selectEvents: "Seleccionar eventos",
  selectAStage: "Seleccionar una etapa",
  selectStages: "Seleccionar etapas",
  selectAFile: "Seleccionar un archivo",
  selectScope: "Seleccionar un ámbito para editar sus artefactos",
  selectAEventStage: "Seleccionar un evento o una etapa.",
  selectProjectType: "Seleccionar el tipo de proyecto",
  selectClient: "Seleccionar el cliente",
  selectOrAddUnit: "Seleccionar o añadir unidad",
  selectOrAddClient: "Seleccionar o añadir cliente",
  selectOrAddTag: "Asignar o añadir etiqueta",
  selectVersion: "Selecionar una versión",
  selectPerson: "Seleccionar una persona",
  selectNotificationsPolicy: "Seleccionar una politica",
  categorySelection: "Seleccionar una categoría",
  serviceSelection: "Seleccionar un servicio",
  extensionSelection: "Seleccionar una extensión",
  membersSelected: "miembros seleccionados",
  noClientSelected: "Selecciona un cliente para gestionar su información",
  dropdownAdd: "'Enter' para añadir",
  new: "Nuevo | Nueva",
  profile: "Perfil",
  unhiredServicesForCategory: "No hay servicios contratados en esta categoría.",
  servicesHiring: "Contratación de servicios",
  month: "mes | meses",
  basicInformation: "Información básica",
  saveContinue: "Guardar y continuar",
  saveExit: "Guardar y salir",
  liability: "Responsabilidad",
  liabilityDesc:
    "Proyecto cerrado a un presupuesto económico, marco temporal o ambos según aplique, permite centrarse en la evolución de los eventos, etapas y el propio ciclo de vida del proyecto.",
  liabilityEconomic: "Responsabilidad Económica | Responsabilidad económica",
  liabilityTemporary: "Responsabilidad Temporal | Responsabilidad temporal",
  liabilityFull: "Responsabilidad Total | Responsabilidad total",
  liabEconomic: "Resp. económica",
  liabTemporary: "Resp. temporal",
  liabFull: "Resp. total",
  timeAndMaterials: "Time & Materials",
  timeAndMaterialsDesc:
    "Proyecto basado en la asignación de recursos, se centra en la evolución de las incorporaciones y desasignaciones al equipo del proyecto durante su transcurso.",
  capacity: "Capacidad",
  capacityDesc:
    "Proyecto centrado en la evolución del consumo de ciertas unidades de capacidad o dedicación a su ejecución, habitualmente en términos de horas o jornadas de trabajo.",
  qbr: "QBR | qbr",
  qbrDesc:
    "Proyecto indicado para reuniones de seguimiento ejecutivo cuatrimestral mediante Quarterly Business Reviews (QBR), organizadas en un calendario.",
  csp: "CSP | csp",
  cspDesc:
    "Proyecto enfocado a la autogestión y compra de licencias y productos Cloud de Microsoft gracias al servicio Cloud Service Provider (CSP) como Microsoft Partner, que resume los totales de licencias, suscripciones, Azure Plans y coste acumulado.",
  close: "Cerrar",
  collapse: "Colapsar",
  activate: "Activar",
  deactivate: "Desactivar",
  confirmation: "¿Estás seguro de que quieres desactivar ",
  cancelService: "¿Estás seguro de que quieres cancelar ",
  includedExtensions: "Extensiones incluidas",
  bookmarks: "Bookmarks",
  activeToday: "Activas hoy",
  thereAreNoBookmarks: "No hay bookmarks.",
  amount: "Cantidad",
  pending: "Pendiente",
  consumed: "Consumido | consumida",
  initialCapacity: "Capacidad inicial",
  reloaded: "recargada",
  billed: "facturada",
  modify: "Modificar",
  eventsOccurred: "Eventos ocurridos",
  eventsPending: "Eventos pendientes",
  majorIssue: "Incidencia grave | Incidencias graves",
  minorIssue: "Incidencia leve | Incidencias leves",
  configuration: "Configuración",
  projectName: "Nombre de proyecto",
  enterValue: "Introducir valor",
  underConstructionTitle: "Esta página está en construcción.",
  underConstructionText: "El contenido estará disponible pronto.",
  error404title: "La página no está disponible",
  error404text:
    "El link puede estar roto o la página puede haber sido eliminada.",
  error500title: "Uups, esto es inesperado…",
  error500text:
    "Ha ocurrido un error y ya estamos trabajando para solucionarlo.",
  error403title: "Parece que no puedes acceder aquí.",
  error403text:
    "Puede que no tengas permisos suficientes o no estés autorizado.",
  errorTitle: "Perdón por el inconveniente!",
  errorText: "Ha ocurrido un error o no estás autorizado.",
  noProjectsNavbar: "No hay proyectos disponibles",
  actuaState: "Estado actual",
  view: "Vista | Ver",
  current: "Actual",
  pendingEvents: "Eventos pendientes",
  pendingStages: "Etapas pendientes",
  noAffectations: "No afectaciones",
  theServiceHasNoExtensions: "El servicio no tiene extensiones.",
  activated: "Activado",
  generateNotes: "Genera notas",
  pendingToOccur: "Pendiente de ocurrir",
  pendingToConfirm: "Pendiente de confirmar",
  occurred: "Ocurrido",
  cancelled: "Cancelado",
  itemNotFound: "Elemento no encontrado.",
  noElementsFound: "No se encontraron elementos.",
  listEmpty: "La lista está vacía.",
  invalidUnitType: "Primera letra en mayúscula y el resto en minúscula.",
  amountUnits: "Unidades de capacidad",
  tag: "Etiqueta | Etiquetas",
  filterTags: "Filtrar etiquetas",
  filterItems: "Filtrar ítems",
  hideStages: "Ocultar/Mostrar etapas",
  filter: "Filtrar",
  wantToConfirmDeleteDoc:
    "¿Estás seguro de que quieres eliminar este documento?",
  messageDeleteDoc: "Esta acción eliminará <b>{docName}</b>.",
  wantToConfirmDeletePeople:
    "¿Estás seguro de que quieres eliminar a este usuario?",
  messageDeletePeople:
    "Esta acción eliminará automáticamente todo lo relacionado con el usuario.",
  wantToConfirmDeleteClient:
    "Estás seguro de que quieres eliminar a este cliente?",
  messageDeleteClient:
    "Esta acción eliminará automáticamente todo lo relacionado con el cliente",
  wantToConfirmProjectClosing:
    "¿Estás seguro de que quieres cerrar del proyecto?",
  messageCloseProject:
    "Esta acción cerrará automaticamente todas las actividades del proyecto seleccionado.",
  wantToConfirmProjectDeleting:
    "¿Estás seguro de que quieres eliminar el proyecto?",
  wantToConfirmProjectRestoring:
    "¿Estás seguro de que quieres restaurar el proyecto?",
  wantToConfirmProjectDeletingPermanently:
    "¿Estás seguro de que quieres eliminar permanentemente el proyecto?",
  wantToConfirmAllProjectsDeletingPermanently:
    "¿Estás seguro de que quieres eliminar permanentemente todos los proyectos?",
  messageRemoveProject:
    "Esta acción eliminará automaticamente todas las actividades del proyecto seleccionado.",
  messageRestoreProject: "Esta acción restaurará el proyecto seleccionado.",
  messageDeletePermanentlyProject:
    "Esta acción eliminará permanentemente el proyecto seleccionado y todos sus datos.",
  messageDeletePermanentlyAllProjects:
    "Esta acción eliminará permanentemente todos los proyectos borrados",
  participantsAndDocs: "Participantes & Docs",
  active: "Activo",
  closed: "Cerrado",
  removed: "Eliminado",
  noNextEvent: "No hay siguiente evento",
  noCurrentStage: "No hay ninguna etapa en curso",
  noContacts: "No hay contactos",
  noTeam: "No hay equipo",
  participants: "Participantes",
  newReport: "Nuevo reporte",
  creationDate: "Fecha de creación",
  eventDate: "Fecha de evento",
  addMaxThreeObjs: "Añadir un máximo de 3 objetivos",
  actionsReview: "Revisión de acciones",
  person: "Persona",
  state: "Estado",
  objectiveDescription: "Descripción del objetivo",
  projectDescription: "Descripción del proyecto",
  completed: "Completado",
  inProgress: "En progreso",
  locked: "Bloqueado",
  newActions: "Nuevas acciones",
  revisionDate: "Fecha revisión",
  thereAreNoParticipants: "No hay participantes",
  thereAreNoDocs: "No hay documentos",
  clientLogo: "LogoCliente",
  report: "Reporte",
  created: "creado",
  index: "Índice",
  eventInformation: "Información del evento",
  eventObjs: "Objectivos del evento",
  documentation: "Documentación",
  reviewLastActions: "Revisión de acciones anteriores",
  agreedActions: "Acciones acordadas",
  clientName: "Nombre cliente",
  clientNameExist: "Ya existe un cliente con este nombre",
  docName: "Nombre documento",
  authorName: "Nombre autor",
  responsible: "Responsable",
  lastActionsReview: "Revisión de acciones anteriores",
  lastActionsReviewText:
    "En la tabla, hay acciones pendientes de revisar, responsables y respectivos estados",
  newActionsText:
    "En la tabla, hay nuevas acciones por hacer, responsables y fechas de ejecución",
  eventReport: "ReporteEvento",
  notAssigned: "Sin asignar",
  noAvaliableVersions: "Sin versiones disponibles",
  replanning: "Replanificación",
  eventName: "Nombre de evento",
  stageName: "Nombre de stage",
  eventType: "Tipo de evento",
  differenceDays: "Días de diferencia",
  assignedBaseline: "Baseline asignada",
  dependence: "Dependencia | Dependencias",
  request: "Petición | petición",
  requests: "Peticiones",
  comment: "Comentario | comentario",
  comments: "Comentarios | comentarios",
  issue: "Asunto",
  send: "Enviar",
  serviceRequest: "Petición de servicio",
  virtualVisit: "Visita virtual",
  question: "Consulta",
  thereAreNoOpenRequests: "No hay ninguna petición abierta.",
  thereAreNoRequests: "No hay peticiones.",
  requestSended: 'La petición "{issue}" se ha registrado en SogetiHub',
  requestCommented:
    'Se ha añadido un nuevo comentario en la petición "{issue}".',
  requestChangedStatus: 'La petición "{issue}" ha sido {status}',
  requestDetails: "Detalles de la petición",
  commentDetails: "Detalles del comentario",
  subject: "Asunto",
  document: "Documento | Documentos",
  documentLowCase: "documento | documentos",
  eventDocuments: "Documentos adjuntos en el evento",
  stageDocuments: "Documentos adjuntos en la etapa",
  requestDocuments: "Documentos adjuntos en la petición",
  requestLink:
    'Ver la petición en <a href="{link}" target="_blank">SogetiHub</a>.',
  thankYou: "Muchas gracias",
  postpone: "Posponer",
  and: "y",
  or: "o",
  durationYears: "1 año | {number} años",
  durationMonths: "1 mes |{number} meses",
  durationDays: "1 día | {number} días",
  maxInputAlert: "Valor máximo",
  samedayEvents: "Eventos del día: ",
  reservedRights: `Todos los derechos reservados por SogetiLabs. Copyright © ${new Date().getFullYear()}`,
  kpiNotAvailable: "KPI no disponible",
  eventAffecPred:
    "Este evento ya está afectado por otro evento, el cual es su predecesor.",
  stageAffecPred:
    "Esta etapa ya está afectada por otra etapa, la cual es su predecesora.",
  affected: "Afectado",
  predecessor: "Predecesor",
  minAmountAlert: "Las unidades deben ser mayores que 0",
  projectNameExist: "Ya existe un proyecto con este nombre",
  projectTypeCSPNotAvailable:
    "Se debe contratar el servicio CSP Panel para crear este proyecto.",
  projectIcon: "Icono",
  projectHeader: "Imagen de cabecera",
  messageAlertError: "La acción no pudo ser completada",
  messageAlertSuccess: "Acción completada",
  status: "Estado",
  openRequests: "Requests abiertas",
  requestStatusOpen: "Abierta",
  requestStatusResolved: "Resuelta",
  requestStatusClosed: "Cerrada",
  requestStatusUpdated: "actualizado",
  subscriptionId: "ID de Suscripción",
  subscription: "Suscripción | Suscripciones",
  subscriptionName: "Nombre de la suscripción",
  licences: "Licencias",
  licence: "licencia(s)",
  azurePlans: "Planes Azure",
  currentCost: "Coste actual",
  newClient: "Nuevo cliente",
  editClient: "Editar cliente",
  lifetime: "Tiempo de vida",
  timelineItem: "Ítem de Timeline",
  back: "Volver",
  serviceDescDeliveryExperience:
    "Portal de interacción con los servicios de Sogeti con acceso al equipo, timeline del servicio, peticiones, documentos asociados y estadísticas del servicio.",
  serviceDescQualityEye:
    "Análisis de datos detallado para ayudar en las actividades y decisiones de quality assurance.",
  serviceDescCSPPanel:
    "Panel de control de licencias y consumo de servicios y recursos del Cloud de Microsoft, consumidos a través de los servicios de Cloud Service Provider de Sogeti.",
  serviceDescSpiderLinksChecker:
    "Herramienta que, mediante algoritmos de scraping y crawling, analiza un sitio web y encuentra enlaces caídos.",
  newIncorporationDesc: "Nueva incorporación al proyecto:<br>",
  unassignmentDesc: "Se ha desasignado una persona del proyecto:<br>",
  cspCustomerInput: "Introducir el nombre del customer en CSP",
  docsVisible: "Hacer visibles los documentos adjuntos a nivel de proyecto.",
  docsSelectedMembersVisible:
    "Los documentos adjuntos ahora serán visibles sólo para los miembros seleccionados.",
  docsSelectedItemMembersVisible:
    "Los documentos adjuntos ahora serán visibles sólo para los miembros del ítem seleccionado.",
  docsAllMembersVisible:
    "Los documentos adjuntos ahora serán visibles para todos los miembros del proyecto.",
  noRelatedEvent: "No hay eventos relacionados",
  requestNoTag: "Esta petición no tiene etiqueta",
  requestIssue: "Asunto de la petición",
  showEventsTooltips: "Mostrar detalles | Ocultar detalles",
  noRequestDescription: "Esta petición no tiene descripción",
  pointOfContact: "Punto de contacto",
  projectPointOfContact: "Puntos de contacto del proyecto",
  writeEmail: "Click para escribir un correo.",
  notifyTo: "Notificar a:",
  allMembers: "Todos los miembros",
  notificationTheEvent: "el evento",
  notificationTheRequest: "la petición",
  notificationTheStage: "la etapa",
  notificationThread: "ha <b> comentado </b> la petición",
  notificationCreated: "ha <b> creado </b> {item}",
  notificationEdited: "ha <b> editado </b> {item}",
  notificationDeleted: "ha <b> borrado </b> {item}",
  notificationDocUploaded: "ha <b> subido </b> un documento en",
  notificationDocDeleted: "ha <b> borrado </b> un documento en",
  notificationDocChanged: "ha hecho <b> cambios </b> en los documentos en",
  notificationDocUploadedProject: "ha <b> subido </b> un documento",
  notificationDocDeletedProject: "ha <b> borrado </b> un documento",
  notificationRescheduled: "ha <b> reprogramado </b> {item}",
  noAttachDocsClosedRequests:
    "La petición seleccionada ya está cerrada y no se le pueden adjuntar más documentos.",
  urlExample: "https://ejemplo.com",
  urlInvalid: "No es una URL válida",
  analysis: "Análisis",
  results: "Resultados",
  depth: "Profundidad",
  executionDate: "Fecha de ejecución",
  startAnalysis: "Iniciar análisis",
  optional: "Opcional",
  element: "Elemento",
  link: "Enlace",
  origin: "Origen",
  brokenLinksHeader: "Tabla de enlaces caídos",
  emptyAnalysis: "Realice un análisis para empezar",
  emptyLinks: "No hay enlaces caídos",
  linksPass: "ENLACES CORRECTOS",
  linksFail: "ENLACES CAÍDOS",
  cantScan: "NO ESCANEADOS",
  totalAnalyzed: "TOTAL ANALIZADOS",
  executionTime: "TIEMPO DE EJECUCIÓN",
  createdItem: "Item creado",
  editedItem: "Item editado",
  deletedItem: "Item eliminado",
  docUpload: "Documento subido",
  docDelete: "Documento eliminado",
  docChange: "Documento cambiado",
  newNotification: "Nueva notificación",
  newCommentThread: "Nuevo comentario",
  newCommentThreadInRequest:
    'Se ha añadido un nuevo comentario en la petición "{request}".',
  activeProjects: "Proyectos activos",
  closedProjects: "Proyectos cerrados",
  removedProjects: "Proyectos eliminados",
  readAll: "Leer todos",
  unknownProject: "Proyecto desconocido",
  selectTenant: "Selecciona un tenant",
  tenantId: "ID de Directorio (Tenant)",
  tenant: "Tenant",
  tenantSelected: "tenant seleccionado | tenants seleccionados",
  directory: "Directorio",
  dark: "Oscuro",
  light: "Claro",
  theme: "Tema",
  selectTheme: "Selecciona un tema para el título",
  methodology: "Metodología",
  waterfall: "Waterfall",
  agile: "Agile",
  linkInsert: "Introducir un enlace",
  projectTimeline: "Proyecto & Timeline",
  invalidURL: "URL inválida",
  filterPhases: "Filtra por fases",
  phase: "Fase | Fases",
  scope: "Ámbito | Ámbitos",
  artifact: "Artefacto | Artefactos",
  noArtifactsForScope:
    "No hay nigún artefacto subido para el ámbito seleccionado.",
  importantScopesUploaded:
    "Se ha subido almenos un artefacto para todos los ámbitos requeridos.",
  detailsAndTemplates: "Detalles y plantillas",
  addLink: "Añadir enlace",
  alreadyUploadedDoc: "Se ha encontrado el siguiente documento:",
  docToUpload: "Documento seleccionado ",
  selectScopeToContinue: "Selecciona un ámbito para continuar",
  noArtifactsUploaded: "No hay artefactos subidos.",
  missingImportantArtifacts:
    "Se requieren artefactos para los siguientes ámbitos: ",
  importantPendingScope:
    "ámbito requerido pendiente | ámbitos requeridos pendientes",
  finish: "Terminar",
  color: "Color",
  docPreviewMessage:
    "Este tipo de archivo no es previsualizable, por favor descárgalo.",
  activity: "Actividad",
  finishEnter: "Terminar y entrar",
  quantity: "Cantidad",
  billing: "Facturación",
  nextBilling: "Próxima facturación: ",
  noItems: "No hay ningún ítem todavía.",
  until: "hasta",
  refundable: "Reembolsable(s) | reembolsable(s)",
  autorenewOn: "Autorrenovación el",
  autorenew: "Autorrenovación",
  expiresOn: "Expira el",
  expires: "Expira",
  operation: "Operación | operación",
  operationHistory: "Historial de operaciones",
  addSubscriptions: "Añadir suscripciones",
  addToCart: "Añadir al carrito",
  cart: "Carrito",
  emptyCart:
    "Tu carrito está vacío.<br>Especifica una cantidad de cualquier producto y añadelo al carrito para continuar.",
  product: "Producto",
  reviewOrder: "Revisar pedido",
  fullDescription: "Descripción",
  showLess: "Mostrar menos",
  purchase: "Comprar",
  licencesPurchase: "Compra de licencias | compra de licencias",
  licencesCancellation: "Cancelación de licencias | cancelación de licencias",
  licenceIncrement: "Incremento de licencias",
  cancellation: "Cancelación",
  cancelSubscription: "Cancelar suscripción",
  autoRenewEnable: "Renovación automática activada",
  autoRenewDisable: "Renovación automática desactivada",
  termDuration: "duración del término",
  billingFrequency: "Frecuencia de facturación | frecuencia de facturación",
  frequency: "Frecuencia | frecuencia",
  changeFriendlyName: "Cambio de apodo de la suscripción",
  changeDurationBilling: "Cambio de {type}",
  changeBillingFrequency: "Cambio de frecuencia de facturación",
  purchaseReceipt: "Confirmación de compra",
  productsPurchase: "Compra de productos",
  changeTermDuration: "Cambio de duración del término",
  confirmationTxt: "Confirmación",
  confirmationIncrement:
    "Estás adquiriendo <b>{quantity}</b> licencias de <b>{subscription}</b>. Tienes 7 días para pedir el reembolso.",
  confirmationDecrement:
    "Estás cancelando <b>{quantity}</b> licencias de <b>{subscription}</b>. Dado que han sido adquiridas en los últimos 7 días, serán reembolsadas.",
  confirmationChangeFriendlyName:
    "Estás seguro de que quieres cambiar el nombre personalizado de <b>{oldName}</b> a <b>{newName}</b>?",
  confirmationCancellationNo:
    "Las <b style='color: red'>{licences} licencia(s)</b> en este producto no son reembolsables, por lo que <b style='color: red'>no pueden ser canceladas automáticamente</b>. Por favor indica el motivo y fecha deseada de cancelación y nuestro equipo CSP se pondrá en contacto.",
  confirmationCancellationMixed:
    "Las <b>{refundableLicences} licencia(s)</b> en este producto que fueron adquiridas en los últimos 7 días serán <b>reembolsadas</b>. En cuanto a las <b style='color: red'>{licences} licencia(s)</b> restantes, estas <b style='color: red'>no pueden ser canceladas automáticamente</b>, así que por favor indica el motivo de cancelación y nuestro equipo CSP se pondrá en contacto.",
  confirmationCancellationAll:
    "Las <b>{refundableLicences} licencia(s)</b> en este producto fueron adquiridas en los últimos 7 días, por lo que serán <b>reembolsadas</b>.",
  confirmationAutoRenewEnable:
    "Estás seguro de que quieres activar la renovación automática para este producto? \n Ten en cuenta que la fecha de renovación será {date}.",
  confirmationAutoRenewDisable:
    "Estás seguro de que deseas que se desactive la renovación automática para este producto? \n Por favor, indica el motivo de la desactivación y nuestro equipo se pondrá en contacto pronto.",
  confirmationDurationBilling:
    "Estás seguro de que deseas que se cambie la {type} para este producto? \n Por favor, indica el motivo y nuestro equipo se pondrá en contacto pronto.",
  reason: "Motivo",
  subscriptionCancellation: "Cancelación de la suscripción",
  contactWithClient:
    "Por favor contactar con el cliente para resolver la petición de cancelación.",
  checkConfirmation:
    "Confirmo que quiero cancelar las licencias y recibir el reembolso",
  insertCancellationDate: "Indica la fecha de cancelación",
  reasonPlaceholder: "Indica el motivo",
  cancellationDate: "Fecha de cancelación",
  requestDate: "Fecha de solicitud",
  operationDetails: "Detalles de la operación",
  endingEmailDisable:
    "El cliente ha solicitado la desactivación de la renovación automática para el producto indicado arriba. Por favor, contactar con el cliente para resolver la petición.",
  endingEmailEnable:
    "Se ha activado la renovación automática para el producto indicado arriba. En caso de que sea un error, puede proceder a la extensión CSP Operations de su proyecto para deshacer esta acción.",
  productType: "Tipo de producto",
  endingDurationBilling:
    "El cliente ha solicitado un cambio en la {type} para el producto indicado arriba. Por favor, contactar con el cliente para resolver la petición.",
  suspendedToKnowMore:
    "Para obtener más información sobre este estado, por favor pregunte a su punto de contacto en Sogeti.",
  refundableLicenceLink:
    'Las licencias recién compradas serán reembolsables solo durante los próximos 7 días. En ese caso, puede realizar la operación de cancelación desde la extensión <a href="{link}" target="_blank">CSP Operations</a> de su proyecto.',
  newOperation:
    "Se ha realizado una nueva operación en la extensión CSP Operations en SogetiHub",
  newOperationByClient:
    "El cliente {client} ha realizado una nueva operación en la extensión CSP Operations en SogetiHub",
  cspStatusActive: "Activa",
  cspStatusCancelled: "Cancelada",
  cspStatusSuspended: "Suspendida",
  cspStatusExpired: "Expirada",
  cspStatusDisabled: "Deshabilitada",
  cspStatusPending: "Pendiente",
  cspStatusPendingCancellation: "Cancelación pendiente",
  annual: "Anual",
  monthly: "Mensual",
  triennial: "Trienal",
  na: "N/A",
  unknown: "Desconocido | Desconocida",
  pendingOperation: "Hay operaciones en curso",
  unitPrice: "Precio unitario",
  totalPrice: "Precio total",
  searchProduct: "Buscar producto",
  noTenantForClient: "No hay tenant seleccionados para este cliente.",
  subscriptionsLoading: "Las suscripciones aparecerán aquí.",
  noProductsMatchSearch: "No hay productos que coincidan con tu búsqueda.",
  cspTypeDescAzure:
    "Las Azure Reservations permiten a los partners precomprar recursos de Azure con anticipación, como máquinas virtuales, almacenamiento y bases de datos. Al comprometerse con una reserva, se pueden ahorrar costes y garantizar la disponibilidad de recursos para sus clientes.",
  cspTypeDescOnlineServices:
    "Los Online Services se refieren a software y aplicaciones basados en la nube. Esta categoría incluye productos como Microsoft 365 (anteriormente Office 365), Dynamics 365 y servicios de Azure.",
  cspTypeDescSoftware:
    "La categoría de Software incluye una amplia gama de opciones de software para ayudar a las empresas a mejorar su productividad y optimizar sus flujos de trabajo, centrándose en la colaboración, gestión de datos y otros aspectos de las operaciones de negocio (Microsoft Office suite, Visual Studio, Teams, etc.).",
  employee: "Empleado",
  jobPosition: "Puesto de trabajo",
  projectRole: "Rol en el proyecto",
  resolvedRequest: "Petición resuelta: ",
  closedRequest: "Petición cerrada: ",
  openRequest: "Petición abierta: ",
  cspAlertSuccess:
    "La operación ha sido completada con éxito. Los cambios pueden tardar unos minutos en reflejarse.",
  cspAlertError:
    "Algo ha ido mal mientras se realizaba la operación. Por favor, intenta de nuevo más tarde o contacta con nuestro equipo.",
  cspAlertInfo: "Operación en curso, por favor espera unos segundos.",
  endingClientGeneral:
    "Por favor, espera a que nuestro equipo resuelva la petición de esta operación.",
  emailAlert: "Los cambios pueden tardar unos minutos en reflejarse.",
  refresh: "Actualizar",
  loading: "Cargando",
  creatingSlowOperation: "Creando, esta operación puede tardar un poco.",
  methodPhaseProposal: "Propuesta",
  methodProposalApproach: "Enfoque de la propuesta",
  methodCustomerOffer: "Oferta a cliente",
  methodPhaseStarting: "Arranque",
  methodPrerequirements: "Pre-requisitos",
  methodProjectPlan: "Plan de proyecto",
  methodTransparencyPlan: "Plan de transparencia",
  methodKickOff: "Kick-off",
  methodPhaseExecutionDevelopment: "Ejecución - Desarrollo",
  methodRequirementsFunctional: "Requisitos funcionales",
  methodRequirementsNonFunctional: "Requisitos no funcionales",
  methodRisks: "Riesgos",
  methodGoodPractices: "Buenas prácticas",
  methodEnvironmentsDeployment: "Entornos y despliegue",
  methodSoftwareAssetsManagement: "Gestión de activos del software",
  methodDesignArchitecture: "Diseño y arquitectura",
  methodUXUI: "UX/UI",
  methodPhaseExecutionQualityEngineering:
    "Ejecución - Ingeniería de Calidad (IC)",
  methodQEStrategy: "Estrategia de IC",
  methodReportingMetrics: "Reporte y métricas",
  methodTestingPlan: "Plan de pruebas",
  methodDefectManagement: "Gestión de defectos",
  methodTestAutomation: "Automatización de pruebas",
  methodDataEnvironmentManagement: "Gestión de datos y entornos",
  methodTestware: "Testware",
  methodPhaseExecutionReportingGovernance: "Ejecución - Reporte y Gobierno",
  methodCustomerSatisfactionAcceptance: "Satisfacción y aceptación del cliente",
  methodRelationshipMonitoringModel: "Modelo de relación y seguimiento",
  methodInternalMonitoring: "Seguimiento interno",
  methodPlanningManagement: "Gestión de la planificación",
  methodKnowledgeManagement: "Gestión del conocimiento",
  methodEconomicManagement: "Gestión económica",
  methodTeamRolesResponsibilities: "Equipo, roles y responsabilidades",
  methodSubcontracting: "Subcontratación",
  methodChangesManagement: "Gestión de cambios",
  methodPhaseWarranty: "Garantía",
  methodWarrantyPlanning: "Planificación de la garantía",
  methodWarrantyExecution: "Ejecución de la garantía",
  methodPhaseClosing: "Cierre",
  methodClosingManagement: "Gestión del cierre",
  methodPossibleSuccessCase: "Posible caso de éxito",
  onboardingEmailTitle: "Bienvenido a SogetiHub!",
  onboardingEmailSubtitle: "Has sido registrado en la plataforma",
  projectIncorporationEmailTitle: "Has sido añadido a un proyecto",
  projectIncorporationEmailGreeting:
    "Se te ha añadido al proyecto <b>{project}</b> en SogetiHub. A continuación puedes consultar las características y tipos de eventos específicos de tu proyecto:",
  projectIncorporationEmailEnding:
    'Accede al proyecto desde la página principal de SogetiHub o haciendo clic <a href="{link}" target="_blank">aquí</a>. También puedes revisar las <b>funcionalidades de SogetiHub</b> en este breve vídeo:'
};
