




































































































  import { projectTypes } from "../../_helpers/projectMetadata";
  import { servicesExtensions } from "../../_helpers/servicesExtensions";
  import ClientServiceCore from "../../models/core/client/ClientServiceCore";
  import ProjectServiceCore from "../../models/core/project/ProjectServiceCore";
  import ServiceCore from "../../models/core/ServiceCore";
  import SingleSelectType from "../../view-models/SingleSelectType";
  import ServiceCard from "./ServiceCard.vue";

  export default {
    name: "ProjectFormServices",
    components: { ServiceCard },
    props: {
      projectId: Number,
      clientId: Number,
      creating: Boolean,
      selectedProjectType: Object,
      parent: String
    },
    data() {
      return {
        projectType: SingleSelectType,
        qbrProjectTypeString: projectTypes.QBR.name,
        cspProjectTypeString: projectTypes.CSP.name,
        projectPreactivatedServices: [],
        text: {
          noServiceQBR: this.$t("noServiceQBR"),
          servicesHiring: this.$t("servicesHiring"),
          unhiredServicesForCategory: this.$t("unhiredServicesForCategory"),
          close: this.$t("close"),
          clientManagement: this.$t("clientManagement"),
          newProject: this.$t("newProject"),
          editProject: this.$t("editProject"),
          finish: this.$t("finish"),
          finishEnter: this.$t("finishEnter")
        }
      };
    },
    async created() {
      await this.loadHiredServicesByClientId(this.clientId);
      await this.loadActiveServicesByClientId(this.clientId);
      if (this.parent == "projectForm")
        this.projectPreactivatedServices =
          await this.getPreactivatedServicesByProjectId(this.projectId);
      if (this.selectedProjectType)
        this.projectType = this.selectedProjectType.value;
    },
    methods: {
      servicesToShow(categoryId): ServiceCore[] | undefined {
        if (this.parent == "projectForm")
          return this.projectServices(categoryId);
        if (this.parent == "clients")
          return this.getServicesByCategoryId(categoryId);
        return undefined;
      },
      projectServices(categoryId): ServiceCore[] {
        let services = [];
        for (const service of this.getServicesByCategoryId(categoryId)) {
          if (
            this.parent == "projectForm" &&
            this.selectedProjectType.key == projectTypes.QBR.name
          ) {
            // Only adding Delivery Experience
            if (service.isDeliveryExperience()) {
              services.push(service);
            }
          } else {
            for (const hiredService of this.getHiredServicesByClientId(
              this.clientId
            )) {
              if (
                hiredService.getId == service.getId &&
                this.isAvailableService(hiredService.getName)
              ) {
                services.push(service);
              }
            }
          }
        }
        return services;
      },
      isAvailableService(serviceName: string): boolean {
        return !(
          this.cspProjectTypeString != this.selectedProjectType.name &&
          serviceName == servicesExtensions.cspPanel.name
        );
      },
      close() {
        this.$emit("closeProjectForm");
      },
      openProject(projectId) {
        this.$router.push({
          path: `/${servicesExtensions.deliveryExperience.url}/${projectId}`
        });
      },
      // EMITS FROM SERVICECARD
      async addService(serviceId) {
        const projectServiceCore = new ProjectServiceCore({
          projectId: this.projectId,
          serviceId: serviceId,
          preactivated: false
        });
        await this.postProjectServices(projectServiceCore);
      },
      async hireService(serviceId) {
        const clientServiceCore = new ClientServiceCore({
          clientId: this.clientId,
          serviceId: serviceId
        });
        await this.postClientService(clientServiceCore);
      },
      async deleteService(serviceId) {
        const projectServiceCore = new ProjectServiceCore({
          projectId: this.projectId,
          serviceId: serviceId
        });
        await this.deleteProjectServices(projectServiceCore);
      },
      async cancelService(serviceId) {
        const projectServiceCore = new ClientServiceCore({
          clientId: this.clientId,
          serviceId: serviceId
        });
        await this.deleteClientService(projectServiceCore);
      }
    },
    watch: {
      async clientId() {
        await this.loadHiredServicesByClientId(this.clientId);
      }
    }
  };
