export interface ICommentDx {
  author: string;
  date: Date;
  comment: string;
}

export default class CommentDx {
  private author: string;
  private date: Date;
  private comment: string;

  constructor(dto: ICommentDx) {
    Object.assign(this, dto);
  }

  get getAuthor(): string {
    return this.author;
  }

  set setAuthor(value: string) {
    this.author = value;
  }

  get getDate(): Date {
    return this.date;
  }

  set setDate(value: Date) {
    this.date = value;
  }

  get getComment(): string {
    return this.comment;
  }

  set setComment(value: string) {
    this.comment = value;
  }
}
