import { IBaseLineDx } from "../../models/deliveryexperience/BaseLineDx";
import EventDx from "../../models/deliveryexperience/EventDx";
import service from "../service";
import baseEndpoint from "./serviceDx";

const endpoint = baseEndpoint + "/Event";

export default {
  components: { service: service },
  methods: {
    async getEvents(): Promise<EventDx[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(event => new EventDx(event));
    },

    async getEvent(id: string): Promise<EventDx> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new EventDx(response);
    },

    async putEvent(modifiedEvent: EventDx): Promise<EventDx> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${modifiedEvent.getId}`,
        modifiedEvent
      );
      return new EventDx(response);
    },
    async putBaseline(data: {
      eventId: string;
      baseline: IBaseLineDx;
    }): Promise<EventDx> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${data.eventId}/update-baseline`,
        data.baseline
      );
      return new EventDx(response);
    },
    async postBaseline(data: {
      eventId: string;
      baseline: IBaseLineDx;
    }): Promise<EventDx> {
      const response = await service.methods.postEndpointAsync(
        `${endpoint}/${data.eventId}/add-baseline`,
        data.baseline
      );
      return new EventDx(response);
    },
    async postEvent(event: EventDx): Promise<EventDx> {
      const response = await service.methods.postEndpointAsync(endpoint, event);
      return new EventDx(response);
    },
    async deleteEvent(id: string): Promise<any> {
      return await service.methods.deleteEndpointAsync(`${endpoint}/${id}`);
    },
    async getEventsByProjectId(projectId: number): Promise<EventDx[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/project/${projectId}`
      );
      return response.map(event => new EventDx(event));
    }
  }
};
