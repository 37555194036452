import config from "../../config";
import { i18n } from "../../plugins/i18";
import emailBuilder from "./emailBuilder";

function build(
  sended: string,
  details: string,
  mailDetails: any,
  link: string,
  lang: string
): string {
  return `
    <p>${sended}.</p>
    <p>&nbsp;</p>
    <p>${details}:</p>
    <p>
      ${emailBuilder.bodyDetailsList(mailDetails)}
    </p>
    <p>&nbsp;</p>
    <p>${i18n.t("requestLink", lang, { link: config.redirectUri + link })}</p>
  `;
}

export default {
  build
};
