



























































  import ProjectCardMedia from "../../view_elements/ProjectCardMedia.vue";
  import ProjectCore from "../../../models/core/ProjectCore";

  export default {
    name: "ProjectClientRow",
    components: { ProjectCardMedia },
    props: {
      clientId: Number,
      projects: Array,
      shouldBeCollapsable: Boolean
    },
    data() {
      return {
        showCollapse: true
      };
    },
    computed: {
      clientProjects(): ProjectCore[] {
        let projects = [];
        for (const project of this.projects) {
          if (project["clientId"] == this.clientId) {
            projects.push(project);
          }
        }
        return projects;
      }
    },
    methods: {
      collapse() {
        this.showCollapse = true;
      }
    }
  };
