import Vue from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import SubscriptionCSP from "../../../models/csp/SubscriptionCSP";
import subscriptionsCSP from "../../../services/csp/subscriptionsService";
import { RootState } from "../../index";

const namespaced = true;

interface SubscriptionState {
  subscriptions: SubscriptionCSP[];
}

const state: SubscriptionState = {
  subscriptions: []
};

const mutations: MutationTree<SubscriptionState> = {
  setSubscriptions(state, subscriptions: []) {
    state.subscriptions = subscriptions;
  },
  addSubscription(state, subscription: SubscriptionCSP) {
    const index = state.subscriptions.findIndex(
      s => subscription.getId == s.getId
    );
    if (index >= 0) state.subscriptions[index] = subscription;
    else state.subscriptions.push(subscription);
  },
  setSubscriptionAvailabilities(state, { subscriptionId, subscriptionTerms }) {
    const index = state.subscriptions.findIndex(s => subscriptionId == s.getId);
    if (index >= 0) state.subscriptions[index].setTerms = subscriptionTerms;
  }
};

const getters: GetterTree<SubscriptionState, RootState> = {
  getSubscriptionsCSP: (state): SubscriptionCSP[] => {
    return state.subscriptions;
  },
  getSubscriptionCSPById:
    state =>
    (subscriptionId: string): SubscriptionCSP => {
      return state.subscriptions.find(
        subscription => subscriptionId == subscription.getId
      );
    }
};

const actions: ActionTree<SubscriptionState, RootState> = {
  async loadSubscriptionCSPIdsByTenantId({ commit }, tenantId) {
    const subscriptionIds: SubscriptionCSP[] =
      await subscriptionsCSP.methods.getSubscriptionIdsByTenantId(tenantId);
    commit("setSubscriptions", subscriptionIds);
  },
  async loadSubscriptionCSPByTenantIdAndSubscriptionId(
    { commit },
    { tenantId, subscriptionId }
  ) {
    const subscription =
      await subscriptionsCSP.methods.getSubscriptionByTenantIdAndSubscriptionId(
        tenantId,
        subscriptionId
      );
    commit("addSubscription", subscription);
  },
  async loadSubscriptionCSPAvailabilities(
    { commit },
    { subscriptionId, endpoint }
  ) {
    try {
      const subscriptionTerms =
        await subscriptionsCSP.methods.getAvailabilities(endpoint);
      commit("setSubscriptionAvailabilities", {
        subscriptionId,
        subscriptionTerms
      });
    } catch {
      Vue.$log.error(
        "Availabilities not found for subscription",
        subscriptionId
      );
    }
  },
  async putSubscriptionCSP({}, { tenantId, subscriptionId, body }) {
    await subscriptionsCSP.methods.putSubscription(
      tenantId,
      subscriptionId,
      body
    );
  },
  async clearSubscriptionsCSP({ commit }) {
    commit("setSubscriptions", []);
  }
};

export const subscriptionModuleCSP: Module<SubscriptionState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
