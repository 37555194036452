export interface ITimeElapsed {
  twoDays: boolean;
  color: string;
}

export default class TimeElapsed {
  private twoDays: boolean;
  private color: string;

  constructor(event: ITimeElapsed) {
    Object.assign(this, event);
  }

  //Getters
  get getTwoDays(): boolean {
    return this.twoDays;
  }

  get getColor(): string {
    return this.color;
  }

  //Setters
  set setTwoDays(value: boolean) {
    this.twoDays = value;
  }

  set setColor(value: string) {
    this.color = value;
  }
}
