export interface INotificaionDx {
  itemName: string;
  projectId: number;
  itemType: string;
  itemId: string;
  recipients: string[];
  action: string;
  author: string;
}

export default class NotificationDx {
  itemName: string;
  projectId: number;
  itemType: string;
  itemId: string;
  recipients: string[]; // [usernames] (users who will receive the notification depending on the item policy)
  action: string;
  author: string; // Logged user's username (email)

  constructor(dto: INotificaionDx) {
    Object.assign(this, dto);
  }

  get getItemName(): string {
    return this.itemName;
  }

  set setItemName(value: string) {
    this.itemName = value;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get getItemType(): string {
    return this.itemType;
  }

  set setItemType(value: string) {
    this.itemType = value;
  }

  get getItemId(): string {
    return this.itemId;
  }

  set setItemId(value: string) {
    this.itemId = value;
  }

  get getRecipients(): string[] {
    return this.recipients;
  }

  set setRecipients(value: string[]) {
    this.recipients = value;
  }

  addRecipient(username: string): void {
    this.recipients.push(username);
  }

  removeRecipient(username: string): void {
    const recipientsIndex: number = this.recipients.indexOf(username);
    if (recipientsIndex > -1) {
      this.recipients.splice(recipientsIndex, 1);
    }
  }

  get getAction(): string {
    return this.action;
  }

  set setAction(value: string) {
    this.action = value;
  }

  get getAuthor(): string {
    return this.author;
  }

  set setAuthor(value: string) {
    this.author = value;
  }
}
