import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import ProjectDx from "../../../models/deliveryexperience/ProjectDx";
import projectServiceDx from "../../../services/deliveryexperience/projectService";
import { RootState } from "../../index";

interface IProjectState {
  projectsDx: ProjectDx[];
}

const namespaced = true;

const state: IProjectState = {
  projectsDx: []
};

const getters: GetterTree<IProjectState, RootState> = {
  getProjectsDx: (state): ProjectDx[] => {
    return state.projectsDx;
  },
  getProjectDx:
    state =>
    (projectId: number): ProjectDx => {
      return state.projectsDx.find(project => project.getId == projectId);
    }
};

const mutations: MutationTree<IProjectState> = {
  setProjectsDx(state, projects: ProjectDx[]): void {
    state.projectsDx = projects;
  },
  updateProjectDx(state, updatedProjectDx: ProjectDx): void {
    const index = state.projectsDx.findIndex(
      p => p.getId === updatedProjectDx.getId
    );
    if (index != -1) {
      state.projectsDx.splice(index, 1, updatedProjectDx);
    }
  },
  addProjectDx(state, project: ProjectDx): void {
    state.projectsDx.push(project);
  },
  deleteProjectDx(state, projectId: number): void {
    const index = state.projectsDx.findIndex(
      project => project.getId == projectId
    );
    state.projectsDx.splice(index, 1);
  }
};

const actions: ActionTree<IProjectState, RootState> = {
  async loadProjectsDx({ commit }): Promise<any> {
    const projects: ProjectDx[] = await projectServiceDx.methods.getProjects();
    commit("setProjectsDx", projects);
  },
  async loadProjectDx({ commit }, projectId: number): Promise<any> {
    const project: ProjectDx = await projectServiceDx.methods.getProject(
      projectId
    );
    commit("setProjectsDx", [project]);
  },
  async postProjectDx({ commit }, newProject: ProjectDx): Promise<any> {
    const project: ProjectDx = await projectServiceDx.methods.postProject(
      newProject
    );
    commit("addProjectDx", project);
    return project;
  },
  async putProjectDx({ commit }, modifiedProject: ProjectDx): Promise<any> {
    const project: ProjectDx = await projectServiceDx.methods.putProject(
      modifiedProject
    );
    commit("updateProjectDx", project);
    return project;
  },
  async deleteProjectDx(
    { commit, dispatch, rootGetters },
    projectId: number
  ): Promise<any> {
    await projectServiceDx.methods.deleteProject(projectId);
    commit("deleteProjectDx", projectId);

    /* Cascade */
    for (const event of rootGetters["eventDx/getEventsDxByProjectId"](
      projectId
    )) {
      await dispatch("eventDx/deleteEventDxLocal", event.getId, { root: true });
    }

    for (const stage of rootGetters["stageDx/getStagesDxByProjectId"](
      projectId
    )) {
      await dispatch("stageDx/deleteStageDxLocal", stage.getId, { root: true });
    }

    for (const people of rootGetters["peopleDx/getPeopleDxByProjectId"](
      projectId
    )) {
      await dispatch("peopleDx/deletePeopleDxLocal", people.getId, {
        root: true
      });
    }

    for (const document of rootGetters["documentDx/getDocumentsDxByProjectId"](
      projectId
    )) {
      await dispatch("documentDx/deleteDocumentDxLocal", document.getId, {
        root: true
      });
    }

    for (const request of rootGetters["requestDx/getRequestsDxByProjectId"](
      projectId
    )) {
      await dispatch("requestDx/deleteRequestDxLocal", request.getId, {
        root: true
      });
    }

    for (const notification of rootGetters[
      "notificationDx/getNotificationsDxByProjectId"
    ](projectId)) {
      await dispatch(
        "notificationDx/deleteNotificationDxLocal",
        notification.getId,
        {
          root: true
        }
      );
    }

    const team = rootGetters["teamDx/getTeamDxByProjectId"](projectId);
    if (team) {
      await dispatch("teamDx/deleteTeamDxLocal", team.getId, { root: true });
    }

    for (const version of rootGetters["versionDx/getVersionsDxByProjectId"](
      projectId
    )) {
      await dispatch("versionDx/deleteVersionDxLocal", version, { root: true });
    }
  }
};

export const projectModuleDx: Module<IProjectState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
