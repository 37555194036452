



























  import { getServiceIcon } from "../../_helpers/servicesExtensions";

  export default {
    props: {
      id: Number,
      name: String,
      hired: Boolean,
      description: String,
      border: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      getServiceIcon(): NodeRequire {
        return getServiceIcon(this.name);
      }
    }
  };
