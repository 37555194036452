






















































































































































































































































  import Multiselect from "vue-multiselect";
  import Datepicker from "vuejs-datepicker";
  import PeopleInfo from "../../../view_elements/PeopleInfo.vue";

  export default {
    name: "NewReportActions",
    components: { Multiselect, Datepicker, PeopleInfo },
    props: {
      event: Object,
      actionsReview: Array,
      people: Array,
      newActions: Array
    },
    data() {
      return {
        text: {
          newReport: this.$t("newReport"),
          actions: this.$tc("action", 2),
          actionsReview: this.$t("actionsReview"),
          newActions: this.$t("newActions"),
          objectiveDescription: this.$t("objectiveDescription"),
          selectOne: this.$t("selectOne"),
          thereAreNoParticipants: this.$t("thereAreNoParticipants")
        },
        actionsReviewfieldsNames: [
          {
            label: this.$tc("action", 1),
            key: "action",
            tdClass: "align-middle w-40"
          },
          {
            label: this.$t("person"),
            key: "person",
            tdClass: "align-middle w-40"
          },
          {
            label: this.$t("state"),
            key: "state",
            tdClass: "align-middle w-20"
          }
        ],
        actionsStates: [
          { color: "#c8ff16", state: "completed", name: this.$t("completed") },
          {
            color: "var(--main-bg-color)",
            state: "in_progress",
            name: this.$t("inProgress")
          },
          { color: "#ff6327", state: "locked", name: this.$t("locked") }
        ],
        newActionsfieldsNames: [
          {
            label: this.$tc("action", 1),
            key: "action",
            tdClass: "align-middle w-40"
          },
          {
            label: this.$t("person"),
            key: "person",
            tdClass: "align-middle w-40"
          },
          {
            label: this.$t("revisionDate"),
            key: "revision_date",
            tdClass: "align-middle w-20"
          }
        ],
        disabledDates: {
          to: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate() + 1
          )
        },
        reviewActions: this.actionsReview,
        actionsNew: this.newActions,
        actionsMaxLength: 100,
        reviewActionkey: 0,
        newActionKey: 0
      };
    },
    computed: {
      languageSelected: function () {
        if (this.$i18n.locale == "EN") {
          return this.en;
        } else {
          return this.es;
        }
      }
    },
    methods: {
      removeReviewAction(item) {
        for (const reviewAction in this.reviewActions) {
          if (this.reviewActions[reviewAction].action == item.action) {
            this.reviewActions.splice(reviewAction, 1);
            break;
          }
        }
        this.$emit(
          "objectiveAdded",
          this.atLeastOneReviewActionAndNewActionFilled()
        );
        this.reviewActionkey++;
      },
      removeNewAction(item) {
        for (const newAction in this.actionsNew) {
          if (this.actionsNew[newAction].action == item.action) {
            this.actionsNew.splice(newAction, 1);
            break;
          }
        }
        this.$emit(
          "objectiveAdded",
          this.atLeastOneReviewActionAndNewActionFilled()
        );
        this.newActionKey++;
      },
      onHover: function (item) {
        item.hovered = true;
      },
      onUnHover: function (item) {
        item.hovered = false;
      },
      showRemoveBtn(item): boolean {
        if (!item.hovered) {
          return false;
        } else if (item.action != "" && item.person != "" && item.state != "") {
          return true;
        } else {
          return false;
        }
      },
      atLeastOneReviewActionAndNewActionFilled(): boolean {
        if (
          this.reviewActions[0].action != "" &&
          this.reviewActions[0].state != "" &&
          this.actionsNew[0].action != "" &&
          this.actionsNew[0].revision_date != null
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    watch: {
      reviewActions: {
        deep: true,
        handler(actions) {
          if (
            actions[0].action != "" &&
            actions[0].state != "" &&
            actions[1] == undefined
          ) {
            this.reviewActions.push({
              action: "",
              person: null,
              state: "",
              hovered: false
            });
            this.$emit(
              "objectiveAdded",
              this.atLeastOneReviewActionAndNewActionFilled()
            );
          } else if (
            actions[0].action != "" &&
            actions[0].state != "" &&
            actions[1] != undefined
          ) {
            if (
              actions[1].action != "" &&
              actions[1].state != "" &&
              actions[2] == undefined
            ) {
              this.reviewActions.push({
                action: "",
                person: null,
                state: "",
                hovered: false
              });
            }
          }
        }
      },
      actionsNew: {
        deep: true,
        handler(actions) {
          if (
            actions[0].action != "" &&
            actions[0].revision_date != null &&
            actions[1] == undefined
          ) {
            this.actionsNew.push({
              action: "",
              person: null,
              revision_date: null,
              hovered: false
            });
            this.$emit(
              "objectiveAdded",
              this.atLeastOneReviewActionAndNewActionFilled()
            );
          } else if (
            actions[0].action != "" &&
            actions[0].revision_date != null &&
            actions[1] != undefined
          ) {
            if (
              actions[1].action != "" &&
              actions[1].revision_date != null &&
              actions[2] == undefined
            ) {
              this.actionsNew.push({
                action: "",
                person: null,
                revision_date: null,
                hovered: false
              });
            }
          }
        }
      }
    }
  };
