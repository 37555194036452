var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"p-0 cont responsive-view",attrs:{"fluid":""}},[_c('div',[(!_vm.loadedData)?void 0:[_c('div',{attrs:{"id":"new-report"}},[_c('b-container',{staticClass:"p-0",attrs:{"fluid":""}},[_c('b-container',{staticClass:"new-report-ctn"},[_c('b-row',{staticStyle:{"width":"100%","margin-left":"0","margin-right":"0"}},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-row',{staticStyle:{"display":"flex","margin-bottom":"0.5rem","margin-left":"-30px"}},[_c('div',{staticClass:"circle-confirmation",style:({
                      backgroundColor: _vm.sectionsRequiredFieldsFilled[0]
                        ? 'transparent'
                        : 'var(--main-bg-color)'
                    })}),_c('p',{staticClass:"cursor-pointer",staticStyle:{"font":"13px Ubuntu"},style:({
                      fontWeight: _vm.actualSeccion == 0 ? 'bold' : '400'
                    }),on:{"click":function($event){return _vm.gotToSection(0)}}},[_vm._v(" "+_vm._s(_vm.text.basicInformation)+" ")])]),_c('b-row',{staticStyle:{"display":"flex","margin-bottom":"0.5rem","margin-left":"-30px"}},[_c('div',{staticClass:"circle-confirmation",style:({
                      backgroundColor: _vm.sectionsRequiredFieldsFilled[1]
                        ? 'transparent'
                        : 'var(--main-bg-color)'
                    })}),_c('p',{staticClass:"cursor-pointer",staticStyle:{"font":"13px Ubuntu"},style:({
                      fontWeight: _vm.actualSeccion == 1 ? 'bold' : '400'
                    }),on:{"click":function($event){return _vm.gotToSection(1)}}},[_vm._v(" "+_vm._s(_vm.text.participantsAndDocs)+" ")])]),_c('b-row',{staticStyle:{"display":"flex","margin-left":"-30px"}},[_c('div',{staticClass:"circle-confirmation",style:({
                      backgroundColor: _vm.sectionsRequiredFieldsFilled[2]
                        ? 'transparent'
                        : 'var(--main-bg-color)'
                    })}),_c('p',{staticClass:"cursor-pointer",staticStyle:{"font":"13px Ubuntu"},style:({
                      fontWeight: _vm.actualSeccion == 2 ? 'bold' : '400'
                    }),on:{"click":function($event){return _vm.gotToSection(2)}}},[_vm._v(" "+_vm._s(_vm.text.actions)+" ")])])],1),(_vm.actualSeccion == 0)?_c('basic-information',{attrs:{"event":_vm.event,"reportObjectives":_vm.objectives,"reportCreationDate":_vm.reportCreationDate,"eventDate":_vm.eventDate},on:{"addObjective":_vm.addObjectiveBeforeChangeSection,"objectiveAdded":_vm.fillAtLeastOneObjectiveBi}}):(_vm.actualSeccion == 1)?_c('participants-docs',{attrs:{"event":_vm.event,"participants":_vm.participants,"documents":_vm.getDocumentsTable}}):(_vm.actualSeccion == 2)?_c('actions',{attrs:{"event":_vm.event,"people":_vm.peopleToShow,"actionsReview":_vm.actionsReview,"newActions":_vm.newActions},on:{"objectiveAdded":_vm.fillAtLeastOneObjectiveA}}):_vm._e(),_c('b-col',{staticStyle:{"display":"flex"},attrs:{"cols":"2"}},[_c('b-button',{staticStyle:{"margin-right":"10px","height":"35px"},attrs:{"pill":"","variant":"outline-secondary"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(_vm._s(_vm.text.cancel))]),_c('b-button',{staticClass:"button-new-report-save",attrs:{"pill":"","disabled":!(
                      _vm.sectionsRequiredFieldsFilled[0] &&
                      _vm.sectionsRequiredFieldsFilled[1] &&
                      _vm.sectionsRequiredFieldsFilled[2]
                    )},on:{"click":function($event){return _vm.save()}}},[_vm._v(_vm._s(_vm.text.save))])],1)],1)],1)],1)],1)]],2)])}
var staticRenderFns = []

export { render, staticRenderFns }