var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-dropdown',{staticClass:"ellipsis-button-dropdown dropdown-shadow",attrs:{"no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('FontAwesomeIcon',{staticStyle:{"height":"25px"},attrs:{"icon":"fa-solid fa-ellipsis-vertical"}})]},proxy:true}])},[_c('b-dropdown-group',{attrs:{"id":_vm.text.configuration,"header":_vm.text.configuration}},[(_vm.projectState == _vm.activeStateString)?_c('b-dropdown-item-button',{on:{"click":function($event){return _vm.editProject()}}},[_vm._v(" "+_vm._s(_vm.text.modify)+" ")]):_vm._e(),(_vm.projectState == _vm.activeStateString)?_c('b-dropdown-item-button',{on:{"click":function($event){_vm.collapse,
            (_vm.action = 'closeProject'),
            _vm.$bvModal.show('modal-confirmation-' + _vm.project.getId)}}},[_vm._v(" "+_vm._s(_vm.text.close)+" ")]):_vm._e(),(
          _vm.projectState == _vm.closedStateString ||
          _vm.projectState == _vm.removedStateString
        )?_c('b-dropdown-item-button',{on:{"click":function($event){_vm.collapse,
            (_vm.action = 'restoreProject'),
            _vm.$bvModal.show('modal-confirmation-' + _vm.project.getId)}}},[_vm._v(" "+_vm._s(_vm.text.restore)+" ")]):_vm._e(),(
          _vm.projectState == _vm.activeStateString ||
          _vm.projectState == _vm.closedStateString
        )?_c('b-dropdown-item-button',{on:{"click":function($event){_vm.collapse,
            (_vm.action = 'removeProject'),
            _vm.$bvModal.show('modal-confirmation-' + _vm.project.getId)}}},[_vm._v(" "+_vm._s(_vm.text.remove)+" ")]):_vm._e(),(_vm.projectState == _vm.removedStateString && _vm.getUser.isAdmin())?_c('b-dropdown-item-button',{on:{"click":function($event){_vm.collapse,
            (_vm.action = 'deleteProject'),
            _vm.$bvModal.show('modal-confirmation-' + _vm.project.getId)}}},[_vm._v(" "+_vm._s(_vm.text.deletePermanently)+" ")]):_vm._e()],1)],1),_c('ConfirmationModal',{attrs:{"id":'modal-confirmation-' + _vm.project.getId,"titleConfirmation":_vm.confirmationModalText.titleConfirmation,"messageConfirmation":_vm.confirmationModalText.messageConfirmation},on:{"confirm":_vm.modifyProject}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }