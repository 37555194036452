



































































































  import { dragscroll } from "vue-dragscroll";
  import { eventTypes } from "../../../_helpers/eventTypesStates";
  import {
    getExtensionIcon,
    servicesExtensions
  } from "../../../_helpers/servicesExtensions";
  import ReadWrite from "../../../models/deliveryexperience/Permissions/ReadWrite";
  import ProjectDx from "../../../models/deliveryexperience/ProjectDx";
  import RequestDx from "../../../models/deliveryexperience/RequestDx";
  import RequestForm from "./request/RequestForm.vue";

  export default {
    name: servicesExtensions.deliveryExperience.extensions.requests.name,
    components: { RequestForm },
    directives: {
      dragscroll
    },
    props: { cols: String, projectDx: ProjectDx },
    data() {
      return {
        servicesExtensions,
        requestFormId: "dx-request-form-from-open-requests",
        text: {
          thereAreNoOpenRequests: this.$t("thereAreNoOpenRequests"),
          seeMore: this.$t("seeMore"),
          seeAll: this.$t("seeAll"),
          openRequests: this.$t("openRequests"),
          lackOfPermissionsSection: this.$t("lackOfPermissionsSection")
        }
      };
    },
    computed: {
      projectDxOpenRequests(): RequestDx {
        return this.getRequestsDxByProjectIdAndStatus(
          this.projectDx.getId,
          "Open"
        ).reverse();
      },
      requestsToShow(): { RequestsDx: RequestDx[]; moreThanFour: Boolean } {
        let result: { RequestsDx: RequestDx[]; moreThanFour: Boolean } = {
          RequestsDx: [],
          moreThanFour: false
        };
        let totalRequests: number = 0;
        for (const i in this.projectDxOpenRequests) {
          if (totalRequests < 4) {
            result.RequestsDx.push(this.projectDxOpenRequests[i]);
            totalRequests++;
          } else {
            result.moreThanFour = true;
            break;
          }
        }
        return result;
      },
      permissionsRequests(): ReadWrite {
        return this.getUserPermissions.requests;
      },
      getExtensionIcon(): NodeRequire {
        return getExtensionIcon(
          servicesExtensions.deliveryExperience.extensions.requests.name
        );
      }
    },
    methods: {
      openRequestForm() {
        this.$bvModal.show(this.requestFormId);
      },
      showRequestMoreDetails(event, request: RequestDx) {
        event.stopPropagation();
        this.$router.push(
          `/${servicesExtensions.deliveryExperience.url}/${this.projectDx.getId}/${servicesExtensions.deliveryExperience.extensions.requests.url}/${request.getId}`
        );
      },
      getRequestTranslationByType(type: string): string {
        switch (type) {
          case eventTypes.REQUEST.events.serviceRequest.type:
            return this.$t(eventTypes.REQUEST.events.serviceRequest.i18n);
          case eventTypes.REQUEST.events.questionRequest.type:
            return this.$t(eventTypes.REQUEST.events.questionRequest.i18n);
          case eventTypes.REQUEST.events.virtualVisitRequest.type:
            return this.$t("virtualVisit");
          case eventTypes.REQUEST.events.cspRequest.type:
            return this.$tc(eventTypes.REQUEST.events.cspRequest.i18n);
          default:
            return type;
        }
      },
      requestDateParset(date: Date): string {
        const dateSplited = date.toString().split("T")[0].split("-");
        return dateSplited[2] + "/" + dateSplited[1] + "/" + dateSplited[0];
      }
    }
  };
