
































































  import ProgressBar from "./ProgressBar.vue";
  import PeopleHistogram from "./PeopleHistogram.vue";
  import ProjectTypeCapacityKpi from "./ProjectTypeCapacityKpi.vue";
  import ProjectTypeCspKpi from "./ProjectTypeCspKpi.vue";
  import EventDateCalendar from "./EventDateCalendar.vue";
  import ProjectCapacity from "../../../models/core/ProjectCapacityCore";
  import { projectTypes } from "../../../_helpers/projectMetadata";
  import { eventTypes } from "../../../_helpers/eventTypesStates";

  export default {
    name: "ProjectKpi",
    data() {
      return {
        capacityEventTypes: [
          eventTypes[projectTypes.capacity.name].events.reload.type,
          eventTypes[projectTypes.capacity.name].events.consume.type,
          eventTypes[projectTypes.capacity.name].events.billing.type
        ],
        timeAndMaterialsEventTypes: [
          eventTypes.PEOPLE.events.unassignment.type,
          eventTypes.PEOPLE.events.newIncorporation.type
        ],
        liabilityEcomonicString: projectTypes.liabilityEconomic.name,
        liabilityTemporaryString: projectTypes.liabilityTemporary.name,
        liabilityFullString: projectTypes.liabilityFull.name,
        timeAndMaterialsString: projectTypes.timeAndMaterials.name,
        capacityString: projectTypes.capacity.name,
        qbrString: projectTypes.QBR.name,
        cspString: projectTypes.CSP.name,
        cameFromModal: this.comeFrom.includes("overview-modal")
      };
    },
    props: {
      project: Object,
      sortedEventsByDate: Array,
      sortedStagesByDate: Array,
      comeFrom: String,
      modalView: Number
    },
    components: {
      PeopleHistogram,
      ProjectTypeCapacityKpi,
      ProjectTypeCspKpi,
      ProgressBar,
      EventDateCalendar
    },
    computed: {
      sortedEventsCapacity(): Object {
        return this.sortedEventsByDate.filter(event =>
          this.capacityEventTypes.includes(event.getEventType)
        );
      },
      sortedEventsTimeAndMaterials(): Object {
        return this.sortedEventsByDate.filter(event =>
          this.timeAndMaterialsEventTypes.includes(event.getEventType)
        );
      },
      sortedEventsQbr(): Object {
        return this.sortedEventsByDate.filter(
          event => event.getEventType === projectTypes.QBR.name
        );
      },
      getCapacityType(): string {
        const capacityProject: ProjectCapacity = this.getProjectById(
          this.project.getId
        );
        return this.getCapacityTypeById(capacityProject.getCapacityTypeId)
          .getName;
      }
    }
  };
