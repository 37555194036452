import PeopleCore from "../../models/core/PeopleCore";
import ProjectPeopleCore from "../../models/core/project/ProjectPeopleCore";
import service from "../service";
import baseEndpoint from "./serviceCore";

const endpoint = baseEndpoint + "/People";

export default {
  components: { service: service },
  methods: {
    async postPeople(person: PeopleCore): Promise<PeopleCore> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        person
      );
      return new PeopleCore(response);
    },
    async getUser(username: string): Promise<PeopleCore> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${username}`
      );
      return new PeopleCore(response);
    },
    async getPeople(): Promise<PeopleCore[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(person => new PeopleCore(person));
    },
    async putPeople(person: PeopleCore): Promise<PeopleCore> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${person.getUsername}`,
        person
      );
      return new PeopleCore(response);
    },
    async deletePeople(username: string): Promise<any> {
      return await service.methods.deleteEndpointAsync(
        `${endpoint}/${username}`
      );
    },
    async getPeopleByClientId(clientId: number): Promise<PeopleCore[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/client/${clientId}`
      );
      return response.map(person => new PeopleCore(person));
    },
    async getProjectsByUsername(
      username: string
    ): Promise<ProjectPeopleCore[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/projects/user/${username}`
      );
      return response.map(projects => new ProjectPeopleCore(projects));
    },
    async postProjectPeople(
      projectPeople: ProjectPeopleCore
    ): Promise<ProjectPeopleCore> {
      const projectId = projectPeople.getProjectId;
      const username = projectPeople.getUsername;
      const response = await service.methods.postEndPointWithoutDataAsync(
        `${endpoint}/${projectId}/people/${username}`
      );
      return new ProjectPeopleCore(response);
    },
    async deleteProjectPeople(projectPeople: ProjectPeopleCore): Promise<any> {
      const projectId = projectPeople.getProjectId;
      const username = projectPeople.getUsername;
      return await service.methods.deleteEndpointAsync(
        `${endpoint}/${projectId}/people/${username}`
      );
    }
  }
};
