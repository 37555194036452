
































































































































  import { projectTypes } from "../../../../_helpers/projectMetadata";
  import { servicesExtensions } from "../../../../_helpers/servicesExtensions";
  import PeopleDx from "../../../../models/deliveryexperience/PeopleDx";
  import Permissions, {
    IPermissions
  } from "../../../../models/deliveryexperience/Permissions/Permissions";
  import ReadWrite from "../../../../models/deliveryexperience/Permissions/ReadWrite";
  import PeopleIcon from "../../../../view-models/PeopleIcon";
  import PeopleInfo from "../../../view_elements/PeopleInfo.vue";

  export default {
    name: "PeoplePermissions",
    components: { PeopleInfo },
    props: {
      id: String
    },
    data() {
      return {
        permissionsScopes: Object.getOwnPropertyNames(new Permissions()),
        fieldsNames: [
          { label: this.$tc("member", 2), key: "member" },
          { label: this.$tc("all", 1), key: "all", class: "all-permissions" },
          { label: this.$t("bookmarks"), key: "bookmarks" },
          {
            label:
              servicesExtensions.deliveryExperience.extensions.requests.name,
            key: "requests",
            class: "background-grey"
          },
          { label: this.$t("events"), key: "events" },
          { label: this.$t("stages"), key: "stages", class: "background-grey" },
          {
            label:
              servicesExtensions.deliveryExperience.extensions.planner.name,
            key: "planner"
          },
          {
            label: servicesExtensions.deliveryExperience.extensions.docs.name,
            key: "docs",
            class: "background-grey"
          },
          {
            label: servicesExtensions.deliveryExperience.extensions.people.name,
            key: "people"
          },
          { label: projectTypes.CSP.name, key: "csp", class: "background-grey" }
        ],
        text: {
          permissionsManagement: this.$t("permissionsManagement"),
          view: this.$tc("view", 2),
          edit: this.$t("edit"),
          save: this.$t("save"),
          cancel: this.$t("cancel")
        },
        loading: false,
        peoplePermissions: []
      };
    },
    mounted() {
      if (
        this.$route.params.projectId &&
        this.getProjectById(this.$route.params.projectId).getProjectType !=
          projectTypes.CSP.name
      ) {
        const index = this.fieldsNames.findIndex(
          field => field.label == projectTypes.CSP.name
        );
        if (index >= 0) this.fieldsNames.splice(index, 1);
      }
      this.peoplePermissions = this.loadTableContentPermissions;
    },
    computed: {
      loadTableContentPermissions(): PeopleDx[] {
        const sortedPeopleProject = this.getPeopleDxByProjectId(
          this.$route.params.projectId
        ).sort((p1, p2) => {
          const p1Core = this.getPeopleByUsername(p1.getUsername);
          const p2Core = this.getPeopleByUsername(p2.getUsername);
          return p1Core.getFirstName < p2Core.getFirstName
            ? -1
            : p1Core.getFirstName > p2Core.getFirstName
            ? 1
            : p1Core.getLastName < p2Core.getLastName
            ? -1
            : p1Core.getLastName > p2Core.getLastName
            ? 1
            : 0;
        });
        return sortedPeopleProject.map(p => {
          const mapPeopleProject = new Object({
            member: PeopleIcon.newPeopleIconFromDx(p)
          });
          for (const permission of this.permissionsScopes) {
            if (p.permissions[permission]) {
              mapPeopleProject[permission] = new ReadWrite({
                read: p.permissions[permission].read,
                write: p.permissions[permission].write
              });
            }
          }
          return mapPeopleProject;
        });
      }
    },
    methods: {
      save(bvModalEvent) {
        this.loading = true;
        bvModalEvent.preventDefault();
        const personInProject = this.getPeopleDxByProjectId(
          this.$route.params.projectId
        );
        for (const person of this.peoplePermissions) {
          let user: PeopleDx = personInProject.find(
            x => x.getUsername == person.member.username
          );
          let permissions = {} as IPermissions;
          for (const permission of this.permissionsScopes) {
            permissions[permission] = new ReadWrite({
              read: person[permission].read,
              write: person[permission].write
            });
          }

          user.setPermissions = new Permissions(permissions);
          this.putPeopleDx(user);
          this.loading = false;
          this.$nextTick(async () => {
            await this.$refs[this.id].hide();
          });
        }
      },
      allChecked(item, option): boolean {
        for (const column in item) {
          // We check only the Permissions objects
          if (
            item[column].hasOwnProperty("read") &&
            item[column].hasOwnProperty("write")
          ) {
            if (!item[column][option]) return false;
          }
        }
        return true;
      },
      read(checked, itemValue) {
        if (!checked) {
          itemValue.write = false;
        }
      },
      write(checked, itemValue) {
        if (checked) itemValue.read = true;
      },
      changeAll(checked, item, option) {
        for (const column in item) {
          if (!item[column].hasOwnProperty("name")) {
            item[column][option] = checked;
            if (option == "read" && !checked) item[column].write = false;
            if (option == "write" && checked) item[column].read = true;
          }
        }
      }
    },
    watch: {
      loadTableContentPermissions() {
        this.peoplePermissions = this.loadTableContentPermissions;
      }
    }
  };
