function getFileIconByExtension(filename, isLink = false) {
  let icon;

  switch (filename.split(".").pop().toLowerCase()) {
    case "jpg":
    case "jpeg":
      icon = "jpg.png";
      break;
    case "png":
      icon = "png.png";
      break;
    case "gif":
      icon = "gif.png";
      break;
    case "pdf":
      icon = "pdf.png";
      break;
    case "doc":
    case "docx":
    case "odt":
      icon = "word.png";
      break;
    case "ppt":
    case "pptx":
      icon = "powerpoint.png";
      break;
    case "xlsm":
    case "xlsx":
      icon = "excel.png";
      break;
    case "zip":
    case "7z":
    case "gz":
    case "bz":
    case "rar":
      icon = "zip.png";
      break;
    case "txt":
      icon = "txt.png";
      break;
    case "mpp":
    case "mpt":
      icon = "mpp.png";
      break;
    default:
      icon = isLink ? "link.png" : "file.png";
      break;
  }
  return getDocIcon(icon);
}

function getDocIcon(icon): NodeRequire {
  return require("../assets/icons/services/deliveryexperience/docs/" + icon);
}

function isDocPreviewable(filename): boolean {
  const previewableDocs = ["pdf", "jpg", "jpeg", "png", "gif", "txt"];
  return previewableDocs.includes(filename.split(".").pop().toLowerCase());
}

export default {
  getFileIconByExtension,
  isDocPreviewable,
  getDocIcon
};
