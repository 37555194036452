export interface IPendingOperation {
  subscriptionId: string;
  tenantId: string;
  cancelSub?: boolean;
  disableAutorenew?: boolean;
  termDuration?: string;
  billingFrequency?: string;
}

export default class PendingOperation {
  private subscriptionId: string;
  private tenantId: string;
  private cancelSub?: boolean = false;
  private disableAutorenew?: boolean = false;
  private termDuration?: string;
  private billingFrequency?: string;

  constructor(dto: IPendingOperation) {
    Object.assign(this, dto);
  }

  get getSubscriptionId(): string {
    return this.subscriptionId;
  }

  set setSubscriptionId(id: string) {
    this.subscriptionId = id;
  }

  get getTenantId(): string {
    return this.tenantId;
  }

  set setTenantId(id: string) {
    this.tenantId = id;
  }

  get getCancelSub(): boolean {
    return this.cancelSub;
  }

  set setCancelSub(cancelSub: boolean) {
    this.cancelSub = cancelSub;
  }

  get getDisableAutorenew(): boolean {
    return this.disableAutorenew;
  }

  set setDisableAutorenew(disableAutorenew: boolean) {
    this.disableAutorenew = disableAutorenew;
  }

  get getTermDuration(): string {
    return this.termDuration;
  }

  set setTermDuration(termDuration: string) {
    this.termDuration = termDuration;
  }

  get getBillingFrequency(): string {
    return this.billingFrequency;
  }

  set setBillingFrequency(billingFrequency: string) {
    this.billingFrequency = billingFrequency;
  }
}
