









































































































  import ProjectPeopleCore from "../../models/core/project/ProjectPeopleCore";

  export default {
    name: "Home",
    data() {
      return {
        componentCreated: false,
        text: {
          projects: this.$tc("projects"),
          services: this.$tc("service", 2),
          extensions: this.$tc("extension", 2),
          users: this.$tc("user", 2),
          categories: this.$t("categories"),
          clients: this.$tc("client", 2)
        },
        items: [],
        filteredItems: [],
        currentPageItems: [],
        search: "",
        menuOption: "projects",
        backgroundURL: require("../../assets/media/background-portal.png")
      };
    },
    created: async function () {
      this.addLoadingComponent(this.$options.name);
      await this.loadProjectsPeopleCoreByUsername(this.getUser.getUsername);
      await this.loadProjectsCore();
      await this.loadCapacityTypes();
      await this.loadProjectStates();
      await this.loadCatalog(); // Load Catalog: Categories, Services, Extensions, Clients
      await this.loadPeopleDx();
      await this.loadProjectsDx();
      await this.loadPeopleDxByUsername(this.getUser.getUsername); // Load PeopleDx of projects where you are added
      await this.loadActiveServicesByClientId(this.getUser.getClientId);
      await this.loadHiredServicesByClientId(this.getUser.getClientId);
      await this.loadExtensionService(this.getUser.getClientId);
      await this.loadCapacityTypes();
      this.componentCreated = true;
      this.removeLoadingComponent(this.$options.name);
    },
    beforeMount() {
      if (this.$route.name && this.$route.name != "index") {
        this.menuOption = this.$route.name;
      }
    },
    updated() {
      this.menuOption = this.$route.name;
    },
    methods: {
      async loadProjectsCore(): Promise<any> {
        if (this.getUser.isAdmin()) {
          await this.loadProjects();
        } else {
          const userProjects: ProjectPeopleCore[] = this.getProjectPeople;
          for (const userProject of userProjects) {
            await this.loadProject(userProject.getProjectId);
          }
        }
      }
    }
  };
