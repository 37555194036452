export interface ITeamDx {
  id?: string;
  projectid?: number;
  people: string[];
}

export default class TeamDx {
  private id?: string;
  private projectId?: number;
  private people: string[];

  constructor(dto: ITeamDx) {
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }
  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get getPeople(): string[] {
    return this.people;
  }

  set setPeople(value: string[]) {
    this.people = value;
  }

  addPeople(value: string): void {
    this.people.push(value);
  }

  deletePeople(value: string): void {
    const index = this.people.indexOf(value);
    if (index > -1) {
      this.people.splice(index, 1);
    }
  }
}
