import Vue from "vue";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { RootState } from "../..";
import NotificationDx from "../../../models/deliveryexperience/NotificationDx";
import notificationService from "../../../services/deliveryexperience/notificationService";
import NotificationInfo from "../../../view-models/deliveryexperience/NotificationInfo";

interface INotificationState {
  notificationsInfo: NotificationInfo[]; //notifications for the logged user only
}

const namespaced = true;

const state: INotificationState = {
  notificationsInfo: [] //notifications for the logged user only
};

const getters: GetterTree<INotificationState, RootState> = {
  getNotifications: (state): NotificationInfo[] => {
    return state.notificationsInfo;
  },
  getNotificationById:
    state =>
    (id: string): NotificationInfo => {
      return state.notificationsInfo.find(
        notification => notification.getId == id
      );
    },
  getNotificationsDxByProjectId:
    state =>
    (projectId: number): NotificationInfo[] => {
      return state.notificationsInfo.filter(n => n.getProjectId == projectId);
    }
};

const mutations: MutationTree<INotificationState> = {
  setNotifications(state, notifications: NotificationInfo[]): void {
    notifications.forEach(n => {
      const index = state.notificationsInfo.findIndex(
        notification => notification.getId === n.getId
      );
      if (index === -1) state.notificationsInfo.push(n);
      else state.notificationsInfo[index] = n;
    });
  },
  updateNotification(state, updatedNotification: NotificationInfo): void {
    const index = state.notificationsInfo.findIndex(
      n => n.getId === updatedNotification.getId
    );
    if (index != -1) {
      state.notificationsInfo.splice(index, 1, updatedNotification);
    }
  },
  deleteNotification(state, notificationId: string): void {
    const index = state.notificationsInfo.findIndex(
      n => n.getId == notificationId
    );
    state.notificationsInfo.splice(index, 1);
  }
};

const actions: ActionTree<INotificationState, RootState> = {
  async loadNotificationsByUser({ commit }, username: string): Promise<any> {
    const notifications: NotificationInfo[] =
      await notificationService.methods.getNotificationsForUser(username);
    commit("setNotifications", notifications);
  },
  async postNotificationDx({}, newNotification: NotificationDx): Promise<any> {
    const notification: NotificationInfo =
      await notificationService.methods.postNotification(newNotification);

    if (notification) {
      Vue.prototype.$notificationConnection.invoke(
        "newNotification",
        newNotification.getRecipients
      );
    }
  },
  async markNotificationAsRead(
    { commit },
    editRead: { id: string; username: string }
  ): Promise<any> {
    const notification: NotificationInfo =
      await notificationService.methods.markNotificationAsRead(
        editRead.id,
        editRead.username
      );
    commit("updateNotification", notification);
  },
  deleteNotificationDxLocal({ commit }, notificationId: NotificationDx): void {
    commit("deleteNotification", notificationId);
  }
};

export const notificationModuleDx: Module<INotificationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
