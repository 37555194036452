export interface IVersionDx {
  id?: string;
  versionId: number;
  projectId: number;
}

export default class VersionDx {
  private id?: string;
  private versionId: number;
  private projectId: number;

  constructor(dto: IVersionDx) {
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }

  get getVersionId(): number {
    return this.versionId;
  }

  set setVersionId(value: number) {
    this.versionId = value;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }
}
