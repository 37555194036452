













































































































  import {
    projectStates,
    projectTypes
  } from "../../../_helpers/projectMetadata";
  import ServiceCore from "../../../models/core/ServiceCore";
  import PeopleIcon from "../../../view-models/PeopleIcon";
  import ContextMenu from "../../view_elements/ContextMenu.vue";
  import PeopleIcons from "../../view_elements/PeopleIcons.vue";
  import ServicesCollapse from "./ProjectCollapseServices.vue";

  export default {
    name: "ProjectRow",
    components: { PeopleIcons, ServicesCollapse, ContextMenu },
    props: {
      id: String,
      project: Object
    },
    data() {
      return {
        showCollapse: false,
        projectEndDate: null,
        activeStateString: projectStates.active.name
      };
    },
    computed: {
      servicesByProject(): ServiceCore[] {
        return this.getServicesByProjectId(this.project.getId);
      },
      projectState(): string {
        return this.getLastProjectStateByProjectId(this.project.getId).getState;
      },
      getPeopleIcons(): PeopleIcon[] {
        const projectPOCs = this.getPOCsByProjectId(this.project.getId);
        return projectPOCs.map(people => {
          return PeopleIcon.newPeopleIconFromDx(people);
        });
      }
    },
    methods: {
      changeDateFormat(date: string): string {
        return date != undefined ? this.$moment(date).format("L") : "-";
      },
      showProjectTypeText(projectType): string {
        switch (projectType) {
          case projectTypes.liabilityEconomic.name:
            return this.$t(projectTypes.liabilityEconomic.i18nShort);
          case projectTypes.liabilityTemporary.name:
            return this.$t(projectTypes.liabilityTemporary.i18nShort);
          case projectTypes.liabilityFull.name:
            return this.$t(projectTypes.liabilityFull.i18nShort);
          case projectTypes.timeAndMaterials.name:
            return this.$t(projectTypes.timeAndMaterials.i18n);
          case projectTypes.capacity.name:
            return this.$t(projectTypes.capacity.i18n);
          case projectTypes.QBR.name:
            return this.$tc(projectTypes.QBR.i18n, 1);
          case projectTypes.CSP.name:
            return this.$tc(projectTypes.CSP.i18n, 1);
          default:
            return "";
        }
      },
      projectNameFormated(name: string): string {
        if (name.length > 25) {
          name = name.substring(0, 22) + "...";
        }
        return name;
      },
      clientNameFormated(name: string): string {
        if (name.length > 20) {
          name = name.substring(0, 17) + "...";
        }
        return name;
      },
      collapse() {
        this.showCollapse = true;
      }
    }
  };
