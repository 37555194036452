import Configuration from "../utils/configuration";

export default {
  tenant: Configuration.value("TENANT_ID"),
  clientId: Configuration.value("CLIENT_ID"),
  redirectUri: Configuration.value("LOCAL_REDIRECT"),
  authority: Configuration.value("AUTHORITY"),
  apiCoreEndpoint: Configuration.value("API_CORE_ENDPOINT"),
  coreScope: Configuration.value("CORE_SCOPE"),
  apiDxEndpoint: Configuration.value("API_DELIVERY_EXPERIENCE_ENDPOINT"),
  dxScope: Configuration.value("DELIVERYEXP_SCOPE"),
  apiSpiderLinksCheckerEndpoint: Configuration.value(
    "API_SPIDERLINKSCHECKER_ENDPOINT"
  ),
  spiderLinksCheckerScope: Configuration.value("SPIDERLINKSCHECKER_SCOPE"),
  apiPartnerCenterEndpoint: Configuration.value("API_PARTNER_CENTER_ENDPOINT"),
  unoconvEndpoint: Configuration.value("UNOCONV_ENDPOINT"),
  unoconvScope: Configuration.value("API_UNOCONV_SCOPE"),
  apiCSPEndpoint: Configuration.value("API_CSP_ENDPOINT"),
  cspScope: Configuration.value("CSP_SCOPE"),
  prodEnv: Configuration.value("PROD_ENV"),
  cspMail: Configuration.value("CSP_MAIL")
};
