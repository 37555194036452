import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import DocumentDx from "../../../models/deliveryexperience/DocumentDx";
import documentServiceDx from "../../../services/deliveryexperience/documentService";
import DocToUpload from "../../../view-models/deliveryexperience/DocToUpload";
import { RootState } from "../../index";

interface IDocumentState {
  documentsInfoDx: DocumentDx[];
}

const namespaced = true;

const state: IDocumentState = {
  documentsInfoDx: []
};

const getters: GetterTree<IDocumentState, RootState> = {
  getDocumentDx:
    state =>
    (documentId: string): DocumentDx => {
      return state.documentsInfoDx.find(
        document => document.getId == documentId
      );
    },
  getDocumentsDxByProjectId:
    state =>
    (projectId: number): DocumentDx[] => {
      return state.documentsInfoDx.filter(
        document => document.getProjectId == projectId
      );
    },
  getDocumentsDxByEventId:
    state =>
    (eventId: string): DocumentDx[] => {
      return state.documentsInfoDx.filter(
        document => document.getEventId == eventId
      );
    },
  getDocumentsDxByStageId:
    state =>
    (stageId: string): DocumentDx[] => {
      return state.documentsInfoDx.filter(
        document => document.getStageId == stageId
      );
    },
  getDocumentsDxByRequestId:
    state =>
    (requestId: string): DocumentDx[] => {
      return state.documentsInfoDx.filter(
        document => document.getRequestId == requestId
      );
    },
  getDocumentsDxByPeopleId:
    state =>
    (peopleId: string): DocumentDx[] => {
      return state.documentsInfoDx.filter(document =>
        document.getPeople.includes(peopleId)
      );
    }
};

const mutations: MutationTree<IDocumentState> = {
  setDocumentsDx(state, documents: DocumentDx[]): void {
    state.documentsInfoDx = documents;
  },
  updateDocumentDx(state, updatedDocumentDx: DocumentDx): void {
    const index = state.documentsInfoDx.findIndex(
      d => d.getId === updatedDocumentDx.getId
    );
    if (index != -1) {
      state.documentsInfoDx.splice(index, 1, updatedDocumentDx);
    }
  },
  addDocumentDx(state, document: DocumentDx): void {
    state.documentsInfoDx.push(document);
  },
  deleteDocumentDx(state, documentId: string): void {
    const index = state.documentsInfoDx.findIndex(
      document => document.getId == documentId
    );
    state.documentsInfoDx.splice(index, 1);
  }
};

const actions: ActionTree<IDocumentState, RootState> = {
  async loadDocumentsDxByProjectId(
    { commit },
    projectId: number
  ): Promise<any> {
    const documents: DocumentDx[] =
      await documentServiceDx.methods.getDocsByProject(projectId);
    commit("setDocumentsDx", documents);
  },
  async postDocumentDxAndBlob(
    { commit },
    upload: DocToUpload
  ): Promise<string> {
    upload.docDx.setLastModifiedDate = new Date();
    const data = new FormData();
    data.append("file", upload.file ? upload.file : null);
    data.append("documentInfo", JSON.stringify(upload.docDx));

    // Case 1: Not existing doc -> POST[Create(doc info) + Upload(new blob)]
    // Case 2: Existing doc & not overwrite -> POST[Create(doc info) + Upload(new blob)]
    // Case 3: Existing doc & is link(overwrite by default) -> POST[Update(doc info)]
    // Case 4: Existing doc & overwrite -> POST[Update(doc info) + Upload(new version blob)]
    const document: DocumentDx =
      await documentServiceDx.methods.postDocumentAndBlob(data);
    if (
      upload.existingDoc &&
      (upload.overwrite || upload.docDx.isExternalLink)
    ) {
      commit("updateDocumentDx", document);
    } else commit("addDocumentDx", document);

    return document.getId;
  },
  async putDocumentDx({ commit }, modifiedDocument: DocumentDx): Promise<any> {
    modifiedDocument.setLastModifiedDate = new Date();
    const document: DocumentDx = await documentServiceDx.methods.putDocument(
      modifiedDocument
    );
    commit("updateDocumentDx", document);
    return document;
  },
  async deleteDocumentDx({ commit }, documentId: string): Promise<any> {
    await documentServiceDx.methods.deleteDocument(documentId);
    commit("deleteDocumentDx", documentId);
  },
  updateDocumentDxLocal({ commit }, modifiedDocument: DocumentDx): void {
    commit("updateDocumentDx", modifiedDocument);
  },
  deleteDocumentDxLocal({ commit }, documentId: string): void {
    commit("deleteDocumentDx", documentId);
  }
};

export const documentModuleDx: Module<IDocumentState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
