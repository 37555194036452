export interface ICustomer {
  id?: number;
  name: string;
}

export default class Customer {
  private id?: number;
  private name: string;

  constructor(dto: ICustomer) {
    Object.assign(this, dto);
  }

  get getId(): number {
    return this.id;
  }

  set setId(id: number) {
    this.id = id;
  }

  get getName(): string {
    return this.name;
  }

  set setName(name: string) {
    this.name = name;
  }
}
