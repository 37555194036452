
















































































































































  import {
    getTimelineItemIcon,
    getTimelineItemTypeFromSubtype
  } from "../../_helpers/eventTypesStates";
  import notifications from "../../_helpers/notifications";
  import { servicesExtensions } from "../../_helpers/servicesExtensions";
  import NotificationInfo from "../../view-models/deliveryexperience/NotificationInfo";
  import PeopleIcon from "../../view-models/PeopleIcon";
  import PeopleDefaultIcon from "./PeopleDefaultIcon.vue";

  export default {
    name: "Notifications",
    components: { PeopleDefaultIcon },
    data() {
      return {
        text: {
          notifications: this.$t("notifications"),
          noNewNotificationsToRead: this.$t("noNewNotificationsToRead"),
          seeAllNotifications: this.$t("seeAllNotifications"),
          readAll: this.$t("readAll"),
          unknownProject: this.$t("unknownProject")
        },
        switchItemBackground: false
      };
    },
    async created() {
      await this.loadPeoplesCoreByClientIdAndSogeti(this.getUser.getClientId);
      await this.loadNotificationsByUser(this.getUser.getUsername);
    },
    computed: {
      notificationsUnread(): NotificationInfo[] {
        const notifications: NotificationInfo[] = this.getNotifications
          .filter(p => p.read == false)
          .reverse();
        return notifications;
      }
    },
    methods: {
      openNotificationsPage() {
        this.$router.push("/notifications");
      },
      getPeopleIcon(username): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(
          this.getPeopleByUsername(username)
        );
      },
      getNotificationItemIcon(
        notificationId: string
      ): NodeRequire | File | any {
        const notification: NotificationInfo =
          this.getNotificationById(notificationId);
        if (notification.itemType == "project") {
          const project = this.getProjectById(notification.projectId);
          if (project === undefined)
            require("../../assets/icons/placeholders/default-project.png");
          else return project.icon;
        } else {
          return getTimelineItemIcon(notification.itemType);
        }
      },
      getTimelineItemTypeFromSubtype(itemType): string {
        return getTimelineItemTypeFromSubtype(itemType);
      },
      async markAsRead(ev, id: string) {
        ev.stopPropagation();
        await this.markNotificationAsRead({
          id: id,
          username: this.getUser.getUsername
        });
      },
      async markAllAsRead(notifications) {
        for (const n of notifications) {
          await this.markNotificationAsRead({
            id: n.id,
            username: this.getUser.getUsername
          });
        }
      },
      actionTranslate(action: string, itemSubType: string): string {
        let type = "";
        const itemType = getTimelineItemTypeFromSubtype(itemSubType);
        if (itemType == "event") type = "notificationTheEvent";
        if (itemType == "stage") type = "notificationTheStage";
        if (itemType == "request") type = "notificationTheRequest";
        if (
          action == "created" ||
          action == "edited" ||
          action == "deleted" ||
          action == "rescheduled"
        )
          return this.$tc(
            notifications.getTranslationByActionName(action).i18nShort,
            1,
            {
              item: this.$t(type)
            }
          );
        else
          return this.$t(
            notifications.getTranslationByActionName(action).i18nShort
          );
      },
      getTimer(time): string {
        return this.$moment().diff(this.$moment(time), "days") <= 7
          ? this.$moment(time).fromNow()
          : this.$moment(time).format("DD MMMM");
      },
      async goToItem(notification: NotificationInfo) {
        let itemType: string;

        let projectId: number;
        if (this.$route.params.hasOwnProperty("projectId"))
          projectId = this.$route.params.projectId;

        let route;
        if (notification.itemType != "project")
          itemType = getTimelineItemTypeFromSubtype(notification.itemType);
        if (itemType == "event" || itemType == "stage") {
          if (projectId == notification.projectId) {
            await this.loadEventsDxByProjectId(projectId);
            await this.loadStagesDxByProjectId(projectId);
          }
          route = `/${servicesExtensions.deliveryExperience.url}/${notification.projectId}/${itemType}/${notification.itemId}`;
        } else if (itemType == "request") {
          if (projectId == notification.projectId)
            await this.loadRequestsDxByProjectId(projectId);
          route = `/${servicesExtensions.deliveryExperience.url}/${notification.projectId}/${servicesExtensions.deliveryExperience.extensions.requests.url}/${notification.itemId}`;
        } else if (notification.itemType == "project") {
          if (projectId == notification.projectId)
            await this.loadDocumentsDxByProjectId(projectId);
          route = `/${servicesExtensions.deliveryExperience.url}/${notification.projectId}/docs`;
        }
        this.$refs.notifications.hide(true);
        const routeData = this.$router.resolve({ path: route });

        if (route == this.$route.fullPath) this.$router.go();
        else if (projectId == notification.projectId) this.$router.push(route);
        else window.open(routeData.href, "_blank");
      }
    }
  };
