import { ILinkDx } from "./LinkDx";
export interface IProjectDx {
  id?: number;
  links: ILinkDx[];
}

export default class ProjectDx {
  private id?: number;
  private links: ILinkDx[];

  constructor(dto: IProjectDx) {
    Object.assign(this, dto);
  }

  get getId(): number {
    return this.id;
  }

  set setId(value: number) {
    this.id = value;
  }

  get getLinks(): ILinkDx[] {
    return this.links;
  }

  set setLinks(value: ILinkDx[]) {
    this.links = value;
  }

  addLink(value: ILinkDx) {
    this.links.push(value);
  }

  editLink(value: ILinkDx) {
    const index = this.links.findIndex(l => l.id == value.id);
    this.links[index] = value;
  }
}
