var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-dropdown',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.popoverText),expression:"popoverText",modifiers:{"hover":true,"top":true}}],staticClass:"checkbox-filter",attrs:{"no-caret":"","left":"","no-flip":"","size":"lg"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(!_vm.title)?_c('FontAwesomeIcon',{style:({
        height: _vm.iconSize,
        color: _vm.selected.length
          ? 'var(--main-bg-color)'
          : _vm.lightGrey
          ? 'var(--dark-gray-25)'
          : ''
      }),attrs:{"icon":"fas fa-filter"}}):_c('span',{style:({
        color: _vm.selected.length ? 'var(--main-bg-color)' : '',
        fontWeight: _vm.selected.length ? 'bold' : ''
      })},[_vm._v(_vm._s(_vm.title)+" ")])]},proxy:true}])},[_c('b-dropdown-header',[_c('div',{staticClass:"title-filter-checkbox"},[_vm._v(" "+_vm._s(_vm.headerText)+" ")]),(_vm.allButton)?_c('b-form-checkbox',{staticClass:"header-button",on:{"change":function($event){return _vm.selectAll()}},model:{value:(_vm.allSelected),callback:function ($$v) {_vm.allSelected=$$v},expression:"allSelected"}},[_vm._v(" "+_vm._s(_vm.allText)+" ")]):_c('button',{staticClass:"header-button dark-button-hover",on:{"click":function($event){return _vm.clear()}}},[_vm._v(" "+_vm._s(_vm.allText)+" ")])],1),_c('b-dropdown-divider'),_c('b-dropdown-form',{staticClass:"checkbox-dropdown vertical-scrollbar small-scrollbar",attrs:{"id":_vm.id},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-checkbox-group',{staticClass:"cursor-pointer text-truncate",attrs:{"options":_vm.options,"stacked":""},on:{"change":_vm.changeFilter},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }