






















































  import CommentDx from "../../models/deliveryexperience/CommentDx";
  import PeopleIcon from "../../view-models/PeopleIcon";
  import PeopleDefaultIcon from "./PeopleDefaultIcon.vue";
  import Singleton from "../../utils/singleton";
  export default {
    name: "Thread",
    components: { PeopleDefaultIcon },
    props: { active: Boolean, thread: Array },
    data() {
      return {
        newComment: undefined
      };
    },
    mounted: function () {
      Array.from(document.getElementsByClassName("thread-content")).forEach(
        threadContent => (threadContent.scrollTop = threadContent.scrollHeight)
      );
      Array.from(document.getElementsByClassName("comment-area-input")).forEach(
        threadInput => {
          threadInput.addEventListener("input", function () {
            threadInput.setAttribute("style", "height: 30px");
            threadInput.setAttribute(
              "style",
              "height: " + threadInput.scrollHeight + "px"
            );
          });
          threadInput.addEventListener("scroll", function () {
            threadInput.setAttribute("style", "height: 30px");
            threadInput.setAttribute(
              "style",
              "height: " + threadInput.scrollHeight + "px"
            );
          });
        }
      );
      Singleton.get().vue.$chatConnection.on(
        "messageReceived",
        (commentDx: CommentDx, requestId: string) => {
          this.$emit("addIncomingComment", commentDx, requestId);
        }
      );
      this.$emit("registerThreadConnection");
    },
    updated: function () {
      Array.from(document.getElementsByClassName("thread-content")).forEach(
        threadContent => (threadContent.scrollTop = threadContent.scrollHeight)
      );
    },
    beforeDestroy: function () {
      this.$emit("unregisterThreadConnection");
    },
    methods: {
      saveComment() {
        if (this.newComment != "" || this.shiftKeyDown == false) {
          this.$emit(
            "addCommentToThread",
            new CommentDx({
              author: this.getUser.getUsername,
              date: new Date(),
              comment: this.newComment.trim()
            })
          );
          this.newComment = "";
          this.resetTextAreaInputSize();
        }
      },
      resetTextAreaInputSize() {
        Array.from(
          document.getElementsByClassName("comment-area-input")
        ).forEach(threadInput =>
          threadInput.setAttribute("style", "height: 30px")
        );
      },
      authorIcon(authorUsername: string): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(
          this.getPeopleByUsername(authorUsername)
        );
      },
      getDate(date: Date): string {
        let dateObject = new Date(date);
        return this.$moment(dateObject).format("DD/MM/YYYY HH:mm");
      }
    }
  };
