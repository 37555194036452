





















































































































































































































































































































































































































































































































  import {
    eventStates,
    eventTypes,
    getEventTypesByProjectType,
    getTimelineItemIcon
  } from "../../_helpers/eventTypesStates";
  import { projectStates, projectTypes } from "../../_helpers/projectMetadata";
  import { servicesExtensions } from "../../_helpers/servicesExtensions";
  import ProjectCore from "../../models/core/ProjectCore";
  import PeopleIcon from "../../view-models/PeopleIcon";
  import ContextMenu from "./ContextMenu.vue";
  import ProjectKpi from "./graphics/ProjectKpi.vue";
  import PeopleIcons from "./PeopleIcons.vue";

  export default {
    name: "ProjectCardMedia",
    props: {
      project: ProjectCore,
      id: String
    },
    components: { PeopleIcons, ProjectKpi, ContextMenu },

    data() {
      return {
        componentCreated: false, //flag to indicate if data is loaded and charge card component with project info
        text: {
          nextEvent: this.$t("nextEvent"),
          currentStage: this.$tc("currentStage", 1),
          currentStages: this.$tc("currentStage", 2),
          mainContacts: this.$t("mainContacts"),
          activity: this.$t("activity"),
          majorIssues: this.$tc("majorIssue", 2),
          minorIssues: this.$tc("minorIssue", 2),
          startDate: this.$t("startDate"),
          closingDate: this.$t("closingDate"),
          endDate: this.$t("endDate"),
          eventsOccurred: this.$t("eventsOccurred"),
          eventsPending: this.$t("eventsPending"),
          openRequests: this.$t("openRequests"),
          start: this.$t("start"),
          end: this.$t("end")
        },
        projectEventTypes: [],
        timeAndMaterialsString: projectTypes.timeAndMaterials.name,
        liabilityTemporaryString: projectTypes.liabilityTemporary.name,
        liabilityFullString: projectTypes.liabilityFull.name,
        activeStateString: projectStates.active.name,
        sortedEventsByDate: [],
        sortedStagesByDate: [],
        showEvent: false,
        showStage: false
      };
    },
    created: async function () {
      await this.loadPeopleDxByProjectId(this.project.getId);
      await this.loadEventsDxByProjectId(this.project.getId);
      await this.loadStagesDxByProjectId(this.project.getId);
      await this.loadRequestsDxByProjectId(this.project.getId);
      this.projectEventTypes = getEventTypesByProjectType(
        this.project.getProjectType
      );
      this.sortedEventsByDate = await this.getEventsDxByProjectId(
        this.project.getId
      ).sort(function (a, b) {
        //Change 'a' and 'b' to order in descendent order.
        return (
          new Date(a.getLastHistoryBaseline.startedDate).getTime() -
          new Date(b.getLastHistoryBaseline.startedDate).getTime()
        );
      });
      this.sortedStagesByDate = await this.getStagesDxByProjectId(
        this.project.getId
      ).sort(function (a, b) {
        return (
          new Date(a.getLastHistoryBaseline.startedDate).getTime() -
          new Date(b.getLastHistoryBaseline.startedDate).getTime()
        );
      });
      this.componentCreated = true;
    },
    computed: {
      getPeopleIcons(): PeopleIcon[] {
        const projectPOCs = this.getPOCsByProjectId(this.project.getId);
        return projectPOCs.map(people => {
          return PeopleIcon.newPeopleIconFromDx(people);
        });
      },
      projectType(): string | null {
        switch (this.project.getProjectType) {
          case projectTypes.liabilityEconomic.name:
            return this.$tc(projectTypes.liabilityEconomic.i18n, 1);
          case projectTypes.liabilityTemporary.name:
            return this.$tc(projectTypes.liabilityTemporary.i18n, 1);
          case projectTypes.liabilityFull.name:
            return this.$tc(projectTypes.liabilityFull.i18n, 1);
          case projectTypes.timeAndMaterials.name:
            return this.$t(projectTypes.timeAndMaterials.i18n);
          case projectTypes.capacity.name:
            return this.$t(projectTypes.capacity.i18n);
          case projectTypes.CSP.name:
            return this.$tc(projectTypes.CSP.i18n, 1);
          case projectTypes.QBR.name:
            return this.$tc(projectTypes.QBR.i18n, 1);
          default:
            return null;
        }
      },
      nextEvent() {
        let nextEvent = {
          name: "",
          date: "",
          hour: "",
          type: ""
        };
        if (
          this.getEventsDxByProjectId(this.project.getId) != undefined &&
          this.getEventsDxByProjectId(this.project.getId).length > 0
        ) {
          const currentDate = new Date();
          for (const event of this.sortedEventsByDate) {
            if (
              new Date(event.getLastHistoryBaseline.startedDate).getTime() >
              currentDate.getTime()
            ) {
              const eventDate = this.getFullDateFormatted(
                event.getLastHistoryBaseline.startedDate
              ).split(" ");
              nextEvent.name = event.getName;
              nextEvent.type = event.getEventType;
              nextEvent.date = eventDate[0];
              nextEvent.hour = eventDate[1] != "00:00" ? eventDate[1] : null;
              break;
            }
          }
        }
        return nextEvent;
      },
      currentStages() {
        let nextStage = {
          id: "",
          name: "",
          startDate: "",
          endDate: "",
          color: ""
        };
        let listStages = [];
        if (
          this.getStagesDxByProjectId(this.project.getId) != undefined &&
          this.getStagesDxByProjectId(this.project.getId).length > 0
        ) {
          const currentDate = new Date();
          for (const stage of this.sortedStagesByDate) {
            if (
              new Date(stage.getLastHistoryBaseline.startedDate).getTime() <
                currentDate.getTime() &&
              new Date(stage.getLastHistoryBaseline.endDate).getTime() >
                currentDate.getTime()
            ) {
              const stageBaseLine = stage.getLastHistoryBaseline;
              nextStage.id = stage.getId;
              nextStage.name = stage.getName;
              nextStage.startDate = this.getDateFormatted(
                stageBaseLine.startedDate
              );
              nextStage.endDate = this.getDateFormatted(stageBaseLine.endDate);
              nextStage.color = stage.getColor ? stage.getColor : "";
              listStages.push(nextStage);
              nextStage = {
                id: "",
                name: "",
                startDate: "",
                endDate: "",
                color: ""
              };
            }
          }
        }
        return listStages;
      },
      openMajorIssues(): number {
        const currentDate = this.$moment();
        return this.getEventsDxByProjectId(this.project.getId).filter(
          e =>
            currentDate < this.$moment(e.getLastHistoryBaseline.startedDate) &&
            e.getLastHistoryBaseline.status ==
              eventStates.pendingToOccur.name &&
            e.getEventType == eventTypes.COMMON.events.majorIssue.type
        ).length;
      },
      openMinorIssues(): number {
        const currentDate = this.$moment();
        return this.getEventsDxByProjectId(this.project.getId).filter(
          e =>
            currentDate < this.$moment(e.getLastHistoryBaseline.startedDate) &&
            e.getLastHistoryBaseline.status ==
              eventStates.pendingToOccur.name &&
            e.getEventType == eventTypes.COMMON.events.minorIssue.type
        ).length;
      },
      eventsCompleted(): number {
        const currentDate = this.$moment();
        return this.sortedEventsByDate.filter(
          e =>
            currentDate >= this.$moment(e.getLastHistoryBaseline.startedDate) &&
            e.getLastHistoryBaseline.status == eventStates.occurred.name
        ).length;
      },
      projectState(): string {
        return this.getLastProjectStateByProjectId(parseInt(this.project.getId))
          .getState;
      },
      projectUrl(): string | null {
        if (this.projectState == projectStates.active.name) {
          return `/${servicesExtensions.deliveryExperience.url}/${this.project.getId}`;
        }
        return null;
      }
    },
    methods: {
      openProject(event) {
        event.preventDefault();
        this.$router.push({
          path: this.projectUrl
        });
      },
      getFullDateFormatted(date: string): string {
        return date != undefined ? this.$moment(date).format("L HH:mm") : "-";
      },
      getDateFormatted(date: string): string {
        return date != undefined ? this.$moment(date).format("L") : "-";
      },
      getTimelineItemIcon(
        timelineItemType: string,
        color?: string
      ): NodeRequire {
        return getTimelineItemIcon(timelineItemType, color);
      },
      getIcon(type): any {
        for (const event in this.projectEventTypes) {
          if (this.projectEventTypes[event].type === type) {
            return this.projectEventTypes[event].icon.normal;
          }
        }
      }
    }
  };
