














































































  export default {
    data() {
      return {
        text: {
          legalWarning: this.$t("legalWarning"),
          privacyPolicy: this.$t("privacyPolicy"),
          informationSecurityPolicy: this.$t("informationSecurityPolicy"),
          contact: this.$t("contact"),
          responsibleOf: this.$t("responsibleOf"),
          reserved_rights: this.$t("reservedRights")
        }
      };
    }
  };
