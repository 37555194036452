
































































  import CapsuleLabelInput from "../../../view_elements/CapsuleLabelInput.vue";

  export default {
    name: "NewReportBasicInformation",
    components: {
      CapsuleLabelInput
    },
    props: {
      event: Object,
      reportObjectives: Array,
      reportCreationDate: String,
      eventDate: String
    },
    data() {
      return {
        text: {
          newReport: this.$t("newReport"),
          basicInformation: this.$t("basicInformation"),
          creation_date: this.$t("creationDate"),
          eventDate: this.$t("eventDate"),
          eventTitle: this.$t("eventTitle"),
          objectives: this.$tc("objective", 2),
          addMaxThreeObjs: this.$t("addMaxThreeObjs")
        },
        objectiveMaxLength: "75",
        objectives: this.reportObjectives,
        objsReplicated: {
          0: this.reportObjectives[0],
          1: this.reportObjectives[1],
          2: this.reportObjectives[2]
        },
        listeners: [],
        key: 0
      };
    },
    mounted() {
      let dis = this;
      //Add objective by pressing ENTER key
      document.addEventListener("keyup", function (e) {
        if (e.target && e.target["id"] == "report-event-objective-0") {
          if (e.key === "Enter" || e.key === "NumpadEnter") {
            dis.objectives[0] = dis.objsReplicated[0];
            dis.key++;
            //Focus on next input
            const interv_1 = setInterval(function () {
              const input = document.getElementById("report-event-objective-1");
              if (input != null) {
                input.focus();
                clearInterval(interv_1);
              }
            }, 10);
            dis.$emit("objectiveAdded", true);
          }
        } else if (e.target && e.target["id"] == "report-event-objective-1") {
          if (e.key === "Enter" || e.key === "NumpadEnter") {
            dis.objectives[1] = dis.objsReplicated[1];
            dis.key++;
            //Focus on next input
            const interv_2 = setInterval(function () {
              const input = document.getElementById("report-event-objective-2");
              if (input != null) {
                input.focus();
                clearInterval(interv_2);
              }
            }, 10);
            dis.$emit("objectiveAdded", true);
          }
        } else if (e.target && e.target["id"] == "report-event-objective-2") {
          if (e.key === "Enter" || e.key === "NumpadEnter") {
            dis.objectives[2] = dis.objsReplicated[2];
            dis.key++;
            dis.$emit("objectiveAdded", true);
          }
        }
      });
    },
    methods: {
      async addRemoveObjective(index) {
        if (this.objectives[index] == "") {
          this.objectives[index] = this.objsReplicated[index];
          this.key++;
          this.$emit("objectiveAdded", true);
        } else {
          this.objectives[index] = "";
          this.objectives = await this.orderObjectives();
          this.objsReplicated[0] = this.objectives[0];
          this.objsReplicated[1] = this.objectives[1];
          this.objsReplicated[2] = this.objectives[2];
          this.key++;
          this.$emit("objectiveAdded", this.atLeastOneObjectiveFilled());
        }
      },
      orderObjectives(): string[] {
        let orderObectives = this.objectives;
        let aux = "";
        for (let i = 0; i < 3; i++) {
          if (orderObectives[i] == "") {
            for (let j = i + 1; j < 3; j++) {
              if (orderObectives[j] != "") {
                aux = orderObectives[i];
                orderObectives[i] = orderObectives[j];
                orderObectives[j] = aux;
                break;
              }
            }
          }
        }
        return orderObectives;
      },
      atLeastOneObjectiveFilled(): boolean {
        return (
          this.objectives[0] != "" ||
          this.objectives[1] != "" ||
          this.objectives[2] != ""
        );
      }
    },
    beforeDestroy() {
      this.$emit("addObjective", this.objectives);
    },
    watch: {
      //Delete objective by deleting all string
      objsReplicated: {
        deep: true,
        async handler(objs) {
          let update = false;
          let index = 0;
          if (this.objectives[0] != objs[0] && objs[0] == "") {
            update = true;
          } else if (this.objectives[1] != objs[1] && objs[1] == "") {
            update = true;
            index = 1;
          } else if (this.objectives[2] != objs[2] && objs[2] == "") {
            update = true;
            index = 2;
          }
          if (update) {
            this.objectives[index] = "";
            this.objectives = await this.orderObjectives();
            this.objsReplicated[0] = this.objectives[0];
            this.objsReplicated[1] = this.objectives[1];
            this.objsReplicated[2] = this.objectives[2];
            this.key++;
            this.$emit("objectiveAdded", this.atLeastOneObjectiveFilled());
          }
        }
      }
    }
  };
