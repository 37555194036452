









































































































































































































  import { getServiceIcon } from "../../_helpers/servicesExtensions";
  import ExtensionCore from "../../models/core/ExtensionCore";
  import ServiceCore from "../../models/core/ServiceCore";
  import Extension from "../view_elements/Extension.vue";

  export default {
    name: "ServiceCard",
    components: { Extension },
    props: {
      service: {
        type: ServiceCore,
        required: true
      },
      clientId: {
        type: Number,
        required: true
      },
      projectId: Number,
      preactivated: Boolean,
      parent: String
    },
    data() {
      return {
        active: false,
        hired: false,
        showInformation: false,
        text: {
          activate: this.$t("activate"),
          deactivate: this.$t("deactivate"),
          confirmation: this.$t("confirmation") + this.service.getName + "?",
          includedExtensions: this.$t("includedExtensions"),
          cancel: this.$t("cancel"),
          theServiceHasNoExtensions: this.$t("theServiceHasNoExtensions"),
          activated: this.$t("activated"),
          close: this.$t("close"),
          servicesLowCase: this.$t("servicesLowCase"),
          hire: this.$t("hire"),
          hired: this.$tc("hired", 1),
          cancelService: this.$t("cancelService") + this.service.getName + "?"
        }
      };
    },
    created() {
      if (this.parent == "projectForm") {
        this.checkActive();
      }
      if (this.parent == "clients") {
        this.checkHired();
      }
    },
    computed: {
      activeServices: function (): ServiceCore[] {
        // Kept here to be used by its watcher, which would not trigger if the Vuex getter name is used
        return this.getActiveServicesByProjectId(this.projectId);
      },
      hiredServices: function (): ServiceCore[] {
        // Kept here to be used by its watcher, which would not trigger if the Vuex getter name is used
        return this.getHiredServicesByClientId(this.clientId);
      },
      extensionsInService(): ExtensionCore[] {
        return this.getExtensionServiceByServiceId(this.service.id);
      }
    },
    methods: {
      getServiceIcon(serviceName: string): NodeRequire {
        return getServiceIcon(serviceName);
      },
      checkActive() {
        if (
          this.activeServices.find(
            service => service.getId == this.service.getId
          )
        ) {
          this.active = true;
        } else this.active = false;
      },
      checkHired() {
        if (
          this.hiredServices.find(
            service => service.getId == this.service.getId
          )
        ) {
          this.hired = true;
        } else this.hired = false;
      },
      openModalWithInfo(showInfo) {
        this.showInformation = showInfo;
        this.$refs.serviceModal.show();
      },
      // EMITS TO NEWPROJECTFROMSERVICES
      activateService() {
        this.$emit("addService", this.service.getId);
        this.$refs.serviceModal.hide();
        this.changeActive();
      },
      hireService() {
        this.$emit("hireService", this.service.getId);
        this.$refs.serviceModal.hide();
        this.changeHired();
      },
      deactivateService() {
        this.$emit("deleteService", this.service.getId);
        this.$refs.serviceModal.hide();
        this.changeActive();
      },
      cancelService() {
        this.$emit("cancelService", this.service.getId);
        this.$refs.serviceModal.hide();
        this.changeHired();
      },
      changeActive() {
        setTimeout(() => {
          this.active = !this.active;
        }, 200);
      },
      changeHired() {
        setTimeout(() => {
          this.hired = !this.hired;
        }, 200);
      }
    },
    watch: {
      activeServices() {
        this.checkActive();
      },
      hiredServices() {
        this.checkHired();
      }
    }
  };
