































  import documentService from "../../services/deliveryexperience/documentService";

  export default {
    name: "preview-document-modal",
    props: {
      id: String
    },
    data() {
      return {
        docUrl: "",
        docName: ""
      };
    },
    methods: {
      openDoc: async function (doc) {
        this.docName = doc.name;
        const download = await documentService.methods.downloadDocumentFromBlob(
          doc.url
        );
        this.docUrl = window.URL.createObjectURL(
          new Blob([download.blob], { type: download.type })
        );
        this.$refs.readDoc.show();
      }
    }
  };
