import CustomerSummaryCSP from "../../models/csp/CustomerSummaryCSP";
import service from "../service";
import baseEndpoint from "./serviceCSP";

const endpoint = baseEndpoint + "/CustomerSummary";

export default {
  components: { service: service },
  methods: {
    async getCustomersSummaryCSP(): Promise<CustomerSummaryCSP[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(summary => new CustomerSummaryCSP(summary));
    },
    async getCustomerSummaryCSPById(id: number): Promise<CustomerSummaryCSP> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new CustomerSummaryCSP(response);
    }
  }
};
