

















































































































  import $ from "jquery";
  import ProductCSP from "../../../../models/csp/ProductCSP";

  export default {
    name: "ProductRow",
    props: {
      product: ProductCSP,
      cartProducts: Array
    },
    data() {
      return {
        collapse: false,
        skusQty: [],
        text: {
          addToCart: this.$t("addToCart"),
          showLess: this.$t("showLess"),
          fullDescription: this.$t("fullDescription")
        }
      };
    },
    methods: {
      async switchCollapseZone() {
        if (!this.collapse) {
          await this.loadProductCSPSkusByProductId(this.product.getId);
          for (const sku of this.skus) {
            this.skusQty.push(sku.minimumQuantity);
          }
        }
        this.collapse = !this.collapse;
      },
      addToCart: async function (sku) {
        const catalogItemIdAndTerms =
          await this.fetchCSPProductCatalogItemIdAndTerms({
            productId: sku.productId,
            skuId: sku.id
          });
        sku.catalogItemId = catalogItemIdAndTerms.catalogItemId;
        sku.terms = catalogItemIdAndTerms.terms;
        sku.friendlyName = sku.title;
        this.$emit(
          "addToCart",
          sku,
          $(`#sku-quantity-${sku.productId}-${sku.id}`).val()
        );
      },
      toggleProductDescription(sku) {
        //const description = document.getElementById("product-description");
        $(`#product-description-${sku.productId}-${sku.id}`).toggle();
        if (
          $(`#product-description-${sku.productId}-${sku.id}`).is(":visible")
        ) {
          $(`#description-link-${sku.productId}-${sku.id}`).text(
            this.text.showLess
          );
        } else {
          $(`#description-link-${sku.productId}-${sku.id}`).text(
            this.text.fullDescription
          );
        }
      },
      restrictionsQuantity(event, min, max) {
        if (event.target.value < min) event.target.value = min;
        else if (event.target.value > max) event.target.value = max;
      }
    },
    computed: {
      skus(): any {
        return this.getProductCSPSkus(this.product.getId);
      }
    }
  };
