

































































































































  import {
    eventStates,
    getEventType
  } from "../../../../_helpers/eventTypesStates";
  import { IBaseLineDx } from "../../../../models/deliveryexperience/BaseLineDx";
  import EventDx from "../../../../models/deliveryexperience/EventDx";
  import StageDx from "../../../../models/deliveryexperience/StageDx";
  import CapsuleInputDate from "../../../view_elements/CapsuleInputDate.vue";
  import CapsuleLabelInput from "../../../view_elements/CapsuleLabelInput.vue";

  export default {
    name: "ReplanningForm.vue",
    components: { CapsuleLabelInput, CapsuleInputDate },
    props: {
      id: String,
      projectId: Number,
      eventStage: Object,
      isItEventOrStage: String,
      affected: Object,
      assignedVersionDx: String
    },
    data() {
      return {
        text: {
          replanning: this.$t("replanning"),
          new: this.$tc("new", 2),
          eventName: this.$t("eventName"),
          stageName: this.$t("stageName"),
          eventType: this.$t("eventType"),
          startDate: this.$t("startDate"),
          differenceDays: this.$t("differenceDays"),
          assignedBaseline: this.$t("assignedBaseline"),
          dependences: this.$tc("dependence", 2),
          cancel: this.$t("cancel"),
          save: this.$t("save")
        },
        startDateRequired: Boolean(false),
        actualDate: new Date(),
        date: undefined,
        difference_days: undefined,
        oneDayInMilliseconds: 86400000
      };
    },
    computed: {
      getEventIcon(): string {
        return (
          "icons/services/deliveryexperience/timeline/" +
          this.getEventTypeInfo(this.eventStage.getEventType).icon
        );
      },
      getAffectedEventIcon(): string {
        return (
          "icons/services/deliveryexperience/timeline/" +
          this.getEventTypeInfo(this.affected.getEventType).icon
        );
      },
      differenceDays: {
        get() {
          return this.difference_days;
        },
        set(value: Date) {
          const delayedDate = value;
          const initStartDate = new Date(
            this.eventStage.getLastHistoryBaseline.startedDate
          );
          this.difference_days = (
            (delayedDate.getTime() - initStartDate.getTime()) /
            this.oneDayInMilliseconds
          ).toFixed(0);
        }
      }
    },
    methods: {
      getEventTypeInfo(eventType: string): {
        key: String;
        name: String;
        icon: String;
      } {
        return getEventType(eventType);
      },
      setDate(value: Date) {
        this.date = value;
        //Also set Difference days between previous startDate and delayed startDate
        this.differenceDays = value;
      },
      getNewStartDate(lastStart: string): Date {
        const lastStartDate = new Date(lastStart);
        const diffDaysInMilliseconds =
          this.oneDayInMilliseconds * parseInt(this.difference_days);
        return new Date(lastStartDate.getTime() + diffDaysInMilliseconds);
      },
      getNewEndDate(lastEnd: string): Date {
        const lastEndDate = new Date(lastEnd);
        const diffDaysInMilliseconds =
          this.oneDayInMilliseconds * parseInt(this.difference_days);
        return new Date(lastEndDate.getTime() + diffDaysInMilliseconds);
      },
      async replant(bvModalEvent) {
        //prevent default closing
        bvModalEvent.preventDefault();
        this.startDateRequired = true;
        if (this.date != undefined) {
          //Create Assigned Baseline
          await this.postVersionDx(this.projectId);
          if (this.isItEventOrStage == "event") {
            await this.updateEventDx();
            await this.postEventsBaseline();
            this.updateTimelineChartType = "events";
          } else {
            await this.updateStageDx();
            await this.postStagesBaseline();
            this.updateTimelineChartType = "stages";
          }
          this.handleCloseSubmit();
        }
      },
      async handleCloseSubmit(): Promise<void> {
        await this.$emit("updateTimeline", this.updateTimelineChartType);
        this.$nextTick(async () => {
          await this.$refs[this.id].hide();
        });
      },
      async updateEventDx() {
        //Update Predecessor event
        let updatedEvent: EventDx = this.eventStage;
        updatedEvent.setConfirmed = false;
        updatedEvent.setOccured = false;
        await this.putEventDx(updatedEvent);
        //Update Affected event
        if (this.eventStage.getLastHistoryBaseline.affecteds.length > 0) {
          let updatedAffectedEvent: EventDx = this.eventStage;
          updatedAffectedEvent.setConfirmed = false;
          updatedAffectedEvent.setOccured = false;
          await this.putEventDx(updatedAffectedEvent);
        }
      },
      async updateStageDx() {
        //Update Predecessor event
        let updatedStage: StageDx = this.eventStage;
        updatedStage.setConfirmed = false;
        updatedStage.setOccured = false;
        await this.putStageDx(updatedStage);
        //Update Affected event
        if (this.eventStage.getLastHistoryBaseline.affecteds.length > 0) {
          let updatedAffectedStage: StageDx = this.eventStage;
          updatedAffectedStage.setConfirmed = false;
          updatedAffectedStage.setOccured = false;
          await this.putStageDx(updatedAffectedStage);
        }
      },
      async postEventsBaseline() {
        let affecteds = [];
        let predecessors = [];
        let eventAffectedBaseline: {
          eventId: string;
          baseline: IBaseLineDx;
        };
        //AFFECTED Event
        if (this.eventStage.getLastHistoryBaseline.affecteds.length > 0) {
          eventAffectedBaseline = {
            eventId: this.affected.getId,
            baseline: {
              version: this.assignedVersionDx,
              status: eventStates.pendingToOccur.name,
              startedDate: this.getNewStartDate(
                this.affected.getLastHistoryBaseline.startedDate
              ),
              endDate: null,
              affecteds: [],
              predecessors: []
            }
          };
          await this.postEventDxBaseline(eventAffectedBaseline);
          affecteds.push(this.eventStage.getLastHistoryBaseline.affecteds[0]);
        }
        if (this.eventStage.getLastHistoryBaseline.predecessors.length > 0) {
          predecessors.push(
            this.eventStage.getLastHistoryBaseline.predecessors[0]
          );
        }
        const eventPredecessorBaseline: {
          eventId: string;
          baseline: IBaseLineDx;
        } =
          //PREDECESSOR event
          {
            eventId: this.eventStage.getId,
            baseline: {
              version: this.assignedVersionDx,
              status: eventStates.pendingToOccur.name,
              startedDate: this.getNewStartDate(
                this.eventStage.getLastHistoryBaseline.startedDate
              ),
              endDate: null,
              affecteds: affecteds,
              predecessors: predecessors
            }
          };
        await this.postEventDxBaseline(eventPredecessorBaseline);
        //Put affected event baseline adding PREDEcCESSOR
        eventAffectedBaseline.baseline.predecessors.push(this.eventStage.getId);
        await this.putEventDxBaseline(eventAffectedBaseline);
      },
      async postStagesBaseline() {
        let stagePredecessorBaseline: {
          stageId: string;
          baseline: IBaseLineDx;
        };
        let affecteds = [];
        let predecessors = [];
        let stageAffectedBaseline: {
          stageId: string;
          baseline: IBaseLineDx;
        };
        //AFFECTED Stage
        if (this.eventStage.getLastHistoryBaseline.affecteds.length > 0) {
          stageAffectedBaseline = {
            stageId: this.affected.getId,
            baseline: {
              version: this.assignedVersionDx,
              status: eventStates.pendingToOccur.name,
              startedDate: this.getNewStartDate(
                this.affected.getLastHistoryBaseline.startedDate
              ),
              endDate: this.getNewEndDate(
                this.affected.getLastHistoryBaseline.endDate
              ),
              affecteds: [],
              predecessors: []
            }
          };
          await this.postStageDxBaseline(stageAffectedBaseline);
          affecteds.push(this.eventStage.getLastHistoryBaseline.affecteds[0]);
        }
        if (this.eventStage.getLastHistoryBaseline.predecessors.length > 0) {
          predecessors.push(
            this.eventStage.getLastHistoryBaseline.predecessors[0]
          );
        }
        //PREDECESSOR Stage
        stagePredecessorBaseline = {
          stageId: this.eventStage.getId,
          baseline: {
            version: this.assignedVersionDx,
            status: eventStates.pendingToOccur.name,
            startedDate: this.getNewStartDate(
              this.eventStage.getLastHistoryBaseline.startedDate
            ),
            endDate: this.getNewEndDate(this.date),
            affecteds: affecteds,
            predecessors: predecessors
          }
        };
        await this.postStageDxBaseline(stagePredecessorBaseline);
        //Put affected event baseline adding PREDEcCESSOR
        stageAffectedBaseline.baseline.predecessors.push(this.eventStage.getId);
        await this.putStageDxBaseline(stageAffectedBaseline);
      },
      resetValues() {
        this.date = undefined;
        this.difference_days = undefined;
        this.startDateRequired = false;
      }
    }
  };
