








































































































































































































































  import { VueEditor } from "vue2-editor";
  import emailBuilder from "../../../../_helpers/email/emailBuilder";
  import requestEmailBody from "../../../../_helpers/email/requestEmailBody";
  import {
    eventTypes,
    getRequestEventType,
    getRequestTypesByProjectType
  } from "../../../../_helpers/eventTypesStates";
  import notifications from "../../../../_helpers/notifications";
  import config from "../../../../config";
  import MailCore from "../../../../models/core/MailCore";
  import NotificationDx from "../../../../models/deliveryexperience/NotificationDx";
  import PeopleDx from "../../../../models/deliveryexperience/PeopleDx";
  import RequestDx from "../../../../models/deliveryexperience/RequestDx";
  import TagDx from "../../../../models/deliveryexperience/TagDx";
  import mailService from "../../../../services/core/mailService";
  import PeopleIcon from "../../../../view-models/PeopleIcon";
  import SingleSelectType from "../../../../view-models/SingleSelectType";
  import CapsuleInputDate from "../../../view_elements/CapsuleInputDate.vue";
  import CapsuleLabelInput from "../../../view_elements/CapsuleLabelInput.vue";
  import CapsuleMultiselect from "../../../view_elements/CapsuleMultiselect.vue";
  import DocsAttach from "../../../view_elements/DocsAttach.vue";

  export default {
    name: "RequestForm",
    components: {
      VueEditor,
      CapsuleLabelInput,
      CapsuleInputDate,
      DocsAttach,
      CapsuleMultiselect
    },
    props: {
      id: String,
      projectId: Number,
      requestIdToEdit: String,
      defaultRequestType: {
        type: SingleSelectType,
        default: () => new SingleSelectType({ key: undefined, name: undefined })
      }
    },
    data() {
      return {
        loading: "",
        eventTypes: eventTypes,
        text: {
          newRequest: this.$tc("new", 2) + " " + this.$tc("request", 2),
          editRequest: this.$t("editRequest"),
          type: this.$t("type"),
          issue: this.$t("issue"),
          description: this.$t("description"),
          selectRequest: this.$t("select") + " " + this.$tc("request", 2),
          author: this.$t("author"),
          date: this.$t("date"),
          attachments: this.$tc("attachments", 2),
          dropdownAdd: this.$t("dropdownAdd"),
          delete: this.$t("delete"),
          cancel: this.$t("cancel"),
          send: this.$t("send"),
          tag: this.$tc("tag", 1),
          requestIssue: this.$t("requestIssue"),
          requestStatus: {
            open: this.$t("requestStatusOpen"),
            resolved: this.$t("requestStatusResolved"),
            closed: this.$t("requestStatusClosed")
          },
          searchAMember: this.$t("searchAMember"),
          notifyTo: this.$t("notifyTo"),
          selectPolicy: this.$t("selectNotificationsPolicy")
        },
        saveDocsAttached: false,
        uploadedDocsNames: [],
        notifyCreatedDocs: false,
        notifyDeletedDocs: false,
        requestId: undefined,
        newRequest: undefined as RequestDx,
        issue: "",
        description: "",
        date: new Date(),
        author: undefined as PeopleIcon,
        type: this.defaultRequestType,
        notificationsPolicy: new SingleSelectType({
          key: "ALL_MEMBERS",
          name: "All members"
        }),
        selectedTag: null,
        types: [],
        requiredFields: {
          issue: false,
          type: false
        }
      };
    },
    async mounted() {
      this.projectCore = await this.getProjectById(this.projectId);
      this.setRequestsTypes();
      this.setDefaultAuthor();
    },
    computed: {
      dateParsed(): string {
        return this.$moment(this.date).format("L");
      },
      peopleToShowDropdown(): PeopleIcon[] {
        return this.mapPeopleDxToPeopleIcon(
          this.getPeopleDxByProjectId(this.projectId)
        );
      },
      datesFrom(): Date {
        return new Date(this.projectCore.startDate);
      },
      datesTo(): Date {
        const today = new Date();
        const endProject = new Date(this.projectCore.endDate);
        return today >= endProject ? endProject : today;
      },
      projectTagsDx(): TagDx {
        let projectTagsDx = this.getProjectTags;
        if (!Object.keys(projectTagsDx).length) {
          this.postTagsDx(
            new TagDx({
              projectId: this.projectId,
              tags: []
            })
          );
          projectTagsDx = this.getProjectTags;
        }
        return projectTagsDx;
      }
    },
    methods: {
      singleSelectTypeSelected(): SingleSelectType {
        const requestObj = getRequestEventType(
          this.getRequestDx(this.requestIdToEdit).getType
        );
        return new SingleSelectType({
          key: requestObj.type,
          name: requestObj.name,
          icon: "services/deliveryexperience/timeline/" + requestObj.icon.normal
        });
      },
      setRequestsTypes() {
        this.types = getRequestTypesByProjectType(
          this.projectCore.getProjectType
        ).map(type => {
          return new SingleSelectType({
            key: type.type,
            name:
              type.type == eventTypes.REQUEST.events.cspRequest.type
                ? this.$tc(type.i18n, 1)
                : this.$t(type.i18n),
            icon: "services/deliveryexperience/timeline/" + type.icon
          });
        });
      },
      dateRequestSelected(): Date {
        return new Date(this.getRequestDx(this.requestIdToEdit).getDate);
      },
      authorRequestSelected(): PeopleIcon {
        const person = this.getPeopleByUsername(
          this.getRequestDx(this.requestIdToEdit).getAuthor
        );
        return PeopleIcon.newPeopleIconFromCore(person);
      },
      getNotificationsPolicies(): SingleSelectType[] {
        let policies: SingleSelectType[] = [];
        let notificationsPolicies = notifications.notificationsPolicies;
        delete notificationsPolicies.onlySelectedMembers;
        for (const policy in notificationsPolicies) {
          policies.push(
            new SingleSelectType({
              key: notificationsPolicies[policy].name,
              name: this.$t(notificationsPolicies[policy].i18n)
            })
          );
        }
        return policies;
      },
      setDefaultAuthor(): void {
        this.author = this.peopleToShowDropdown.find(
          p => p.username == this.getUser.username
        );
      },
      mapPeopleDxToPeopleIcon(peopleDx: PeopleDx[]): PeopleIcon[] {
        return peopleDx.map(personDx => {
          return PeopleIcon.newPeopleIconFromDx(personDx);
        });
      },
      async postMailCore(lang: string, user: string): Promise<any> {
        return await mailService.methods.postMailCore(
          new MailCore({
            to: user,
            subject: this.issue,
            body: this.getMailBody(
              this.issue,
              this.author.username,
              this.dateParsed,
              this.selectedTag,
              this.uploadedDocsNames.join(", "),
              lang
            )
          })
        );
      },
      getMailBody(
        issue: string,
        author: string,
        date: string,
        tag: string,
        documentName: string,
        lang: string
      ): string {
        const mailDetails = {
          subject: { name: this.$t("subject", lang), value: issue },
          author: { name: this.$t("author", lang), value: author },
          date: { name: this.$t("date", lang), value: date },
          tag: {
            name: this.$tc("tag", 1, lang),
            value: tag ? tag : this.$t("requestNoTag", lang)
          },
          document: { name: this.$tc("document", 1, lang), value: documentName }
        };
        return emailBuilder.buildEmail(
          this.$tc("new", 2, lang) + " " + this.$tc("request", 2, lang),
          "",
          requestEmailBody.build(
            this.$t("requestSended", lang, { issue: issue }),
            this.$t("requestDetails", lang),
            mailDetails,
            this.$route.fullPath,
            lang
          ),
          lang
        );
      },
      activeRequiredFields() {
        this.requiredFields.issue = true;
        this.requiredFields.type = true;
      },
      deActiveRequiredFields() {
        this.requiredFields.issue = false;
        this.requiredFields.type = false;
      },
      async deleteRequest() {
        this.loading = "delete";
        if (this.id != "dx-request-form-from-requests-page") {
          this.$root.$emit("closeTimelineItem");
        }
        /**
         * Saving the request id before it is reset due to resetValues method (modal @hidden).
         * Then, we force emitting the reset before hidden occurs to prevent the request
         * to be fetched in the store AFTER it is deleted, therefore being undefined.
         **/
        const requestIdToDelete = this.requestIdToEdit;
        this.$emit("resetValues");

        await this.deleteRequestDx(requestIdToDelete);
        this.$bvModal.hide(this.id);
      },
      async send(bvModalEvent) {
        this.loading = "send";
        bvModalEvent.preventDefault();

        if (this.requestIdToEdit) {
          await this.putRequest();
        } else {
          await this.postRequest();
          const pocs = this.getPOCsByProjectId(this.projectId)
            .filter(p => p.getUsername != this.author.username)
            .map(p => p.getUsername);
          pocs.forEach(poc => {
            const language = this.getPeopleByUsername(poc).language;
            this.postMailCore(language, poc);
          });
          if (
            config.prodEnv &&
            this.type.key == eventTypes.REQUEST.events.cspRequest.type
          ) {
            this.postMailCore("ES", config.cspMail);
          }
        }
        await this.sendNotificationDx(
          this.requestId ? this.requestId : this.requestIdToEdit
        );
        this.saveDocsAttached = true; // Triggers Docs saving due to prop change
      },
      async putRequest(): Promise<void> {
        try {
          this.newRequest = this.getRequestDx(this.requestIdToEdit);
          this.newRequest.setType = this.type.getKey;
          this.newRequest.setDate = this.date;
          this.newRequest.setIssue = this.issue;
          this.newRequest.setAuthor = this.author.getUsername;
          this.newRequest.setDescription = this.description;
          this.newRequest.setTag = this.selectedTag;
          this.newRequest.setNotificationsPolicy = this.notificationsPolicy.key;
          await this.putRequestDx(this.newRequest);
          if (this.id != "dx-request-form-from-requests-page") {
            this.$root.$emit(
              "rerenderTimelineItem",
              this.getRequestDx(this.requestIdToEdit).getId,
              "request"
            );
          }
          // save tags if there is any
          if (this.selectedTag) {
            await this.putTagsDx(this.selectedTag);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async postRequest() {
        try {
          this.newRequest = await this.postRequestDx(
            new RequestDx({
              projectId: this.projectId,
              issue: this.issue,
              status: "Open",
              type: this.type.getKey,
              description: this.description,
              author: this.author.username,
              date: this.date,
              tag: this.selectedTag,
              notificationsPolicy: this.notificationsPolicy.key,
              thread: []
            })
          );
          this.requestId = this.newRequest.getId;

          // Save tags if there is any
          if (this.selectedTag) {
            await this.putTagsDx(this.selectedTag);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      // Emit from DocsAttach component once attached docs are saved
      async docsUploadedHandler(
        documentsIds: string[],
        documentsNames: string[],
        notifyCreated: boolean,
        notifyDeleted: boolean
      ) {
        if (documentsIds.length) this.uploadedDocsNames = documentsNames;
        else this.uploadedDocsNames = [this.$t("not_added_document")];
        this.notifyCreatedDocs = notifyCreated;
        this.notifyDeletedDocs = notifyDeleted;
        if (notifyCreated || notifyDeleted) {
          await this.sendNotificationDx(
            this.requestId ? this.requestId : this.requestIdToEdit
          );
        }
        this.$nextTick(async () => {
          await this.$refs[this.id].hide();
        });
      },
      async sendNotificationDx(itemId: string) {
        const notificationDx = new NotificationDx({
          itemName: this.newRequest.getIssue,
          projectId: this.projectId,
          itemType: this.newRequest.getType,
          itemId: itemId,
          recipients: notifications.getPeopleForRecipients(
            this.newRequest.getNotificationsPolicy,
            this.getPeopleDxByProjectId(this.projectId),
            this.newRequest.getAuthor,
            this.getUser.getUsername,
            itemId
          ),
          action: this.getNotificationAction(),
          author: this.getUser.getUsername
        });
        if (notificationDx.recipients.length)
          await this.postNotificationDx(notificationDx);
      },
      getNotificationAction(): string | null {
        if (this.notifyCreatedDocs && this.notifyDeletedDocs)
          return "docChanged";
        else if (this.notifyCreatedDocs) return "docUploaded";
        else if (this.notifyDeletedDocs) return "docDeleted";
        else if (this.requestId) return "created";
        else if (this.requestIdToEdit) return "edited";
        return null;
      },
      resetValues() {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.setRequestsTypes();
        this.setDefaultAuthor();
        this.deActiveRequiredFields();
        this.$emit("rerenderOverview");
        this.$emit("resetValues");
        this.$emit("resetActionAndTypeValues");
      },
      addTag(value) {
        this.selectedTag = value;
      }
    },
    watch: {
      requestIdToEdit() {
        if (this.requestIdToEdit) {
          this.selectedTag = this.getRequestDx(this.requestIdToEdit).getTag;
          this.author = this.authorRequestSelected();
          this.date = this.dateRequestSelected();
          this.type = this.singleSelectTypeSelected();
          this.issue = this.getRequestDx(this.requestIdToEdit).getIssue;
          this.description = this.getRequestDx(
            this.requestIdToEdit
          ).getDescription;
          const policyItem = notifications.getNotificationsPolicy(
            this.getRequestDx(this.requestIdToEdit).getNotificationsPolicy
          );
          this.notificationsPolicy = new SingleSelectType({
            key: policyItem.name,
            name: this.$t(policyItem.i18n)
          });
        }
      }
    }
  };
