var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loadedData)?_c('section',{staticClass:"extension-ctn"},[_c('b-row',{staticClass:"planner-content"},[_c('ReplanningForm',{attrs:{"id":"planner-replanning-modal","projectId":_vm.projectId,"isItEventOrStage":_vm.isItEventOrStage,"eventStage":_vm.replanEventStage,"affected":_vm.affected,"assignedVersionDx":_vm.assignedVersionDx},on:{"updateTimeline":_vm.forceRerenderAndUpdateVersion}}),_c('PostponeForm',{attrs:{"id":"planner-postpone-modal","projectId":_vm.projectId,"isItEventOrStage":_vm.isItEventOrStage,"eventStage":_vm.replanEventStage,"assignedVersionDx":_vm.assignedVersionDx},on:{"updateTimeline":_vm.forceRerender}}),(_vm.permissionsPlanner.read)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-container',[_c('p',{staticClass:"title-state"},[_c('strong',[_vm._v(_vm._s(_vm.text.actuaState))])])]),_c('b-row',{staticStyle:{"margin-top":"19px"}},[_c('b-col',{staticClass:"version",attrs:{"cols":"6"}},[_c('p',{staticClass:"version-text"},[_vm._v(" "+_vm._s(_vm.text.current)+" ")])]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('b-dropdown',{staticClass:"version-dropdown",attrs:{"id":"planner-version-dropdown","text":_vm.versionsDxSelectorText,"disabled":_vm.versionsDxSelector.length == 0}},_vm._l((_vm.versionsDxSelector),function(version,index){return _c('b-dropdown-item',{key:'version-' + index,on:{"click":function($event){_vm.selectedVersionDxId = version.getVersionId}}},[_vm._v("v."+_vm._s(version.getVersionId))])}),1)],1)],1)],1),_c('b-row',[_c('b',{staticStyle:{"margin":"13px 0 -9px 30px"}},[_vm._v(_vm._s(_vm.text.pendingToConfirm))])]),_c('b-row',[_c('b-col',[(_vm.permissionsPlanner.read && _vm.permissionsEvents.read)?_c('b-row',{staticClass:"planner-list"},[_c('b-col',[_c('b-row',{staticClass:"view-list",staticStyle:{"border-top":"solid 1px gray","border-bottom":"solid 1px gray"},on:{"click":function($event){(_vm.collapsePendingEvents = !_vm.collapsePendingEvents),
                    (_vm.collapsePengingStages = false)}}},[_c('b-col',{attrs:{"cols":"1"}},[(
                      _vm.collapsePendingEvents &&
                      _vm.eventsPendingToConfirm.length > 0
                    )?_c('FontAwesomeIcon',{staticStyle:{"height":"15"},attrs:{"icon":"fa-solid fa-chevron-up"}}):_c('FontAwesomeIcon',{staticStyle:{"height":"15"},attrs:{"icon":"fa-solid fa-chevron-down"}})],1),_c('b-col',[_c('b',[_vm._v(_vm._s(_vm.text.events))])]),_c('b-col',{attrs:{"cols":"1"}},[(_vm.projectEventsDx.length == 0)?_c('b-badge',{staticClass:"view-badge",attrs:{"pill":""}},[_vm._v(_vm._s(_vm.projectEventsDx.length)+" ")]):_c('b-badge',{staticClass:"view-badge-active",attrs:{"pill":""}},[_vm._v(_vm._s(_vm.eventsPendingToConfirm.length))])],1)],1),(_vm.collapsePendingEvents)?_c('b-row',{staticClass:"planner-list-scroll small-scrollbar",attrs:{"id":"collapse-1"}},[_c('b-col',_vm._l((_vm.eventsPendingToConfirm),function(event,index){return _c('div',{key:'event-collapse-key-' + index},[_c('b-row',{staticClass:"view-collapse",staticStyle:{"padding":"0.5rem 1rem 0.5rem 1rem","border-top-style":"none"},style:({
                        backgroundColor: !_vm.getEventsCollapse[index].value
                          ? event.timeElapsed.getColor
                          : '#ffffff',
                        borderBottom: !_vm.getEventsCollapse[index].value
                          ? 'solid 1px gray'
                          : 'solid 1px #ffffff'
                      })},[_c('b-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"1"},on:{"click":function($event){_vm.eventsCollapse[index].value =
                            !_vm.eventsCollapse[index].value}}},[(_vm.getEventsCollapse[index].value)?_c('FontAwesomeIcon',{staticClass:"font-awesome-chevron",attrs:{"icon":"fa-solid fa-chevron-up"}}):_c('FontAwesomeIcon',{staticClass:"font-awesome-chevron",attrs:{"icon":"fa-solid fa-chevron-down"}})],1),_c('b-col',{staticStyle:{"display":"flex"}},[_c('b-img',{staticClass:"align-vertical-middle",staticStyle:{"width":"30px","height":"auto"},attrs:{"src":_vm.getTimelineItemIcon(
                              event.timeElapsed,
                              event.event.getEventType
                            )}}),_c('p',{staticClass:"align-vertical-middle text-multiple-lines-max-2 text-overflow-ellipsis",staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(event.event.getName)+" ")])],1),_c('b-col',{staticClass:"p-0 align-vertical-middle",staticStyle:{"text-align":"center","display":"flex"},attrs:{"cols":"3"}},[(_vm.getEventsCollapse[index].value)?[(_vm.permissionsPlanner.write)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.confirmEventAction(event)}}},[_c('FontAwesomeIcon',{staticStyle:{"color":"var(--main-bg-color)","height":"14"},attrs:{"icon":"fa-solid fa-check"}})],1):_vm._e(),(_vm.permissionsPlanner.write)?_c('div',{style:({
                              opacity: event.affected != null ? '0.5' : '1',
                              cursor:
                                event.affected != null
                                  ? 'default'
                                  : 'pointer',
                              'margin-left': '5px'
                            }),attrs:{"disabled":event.affected != null},on:{"click":function($event){return _vm.openPostponeModal(event.event)}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"14","color":"var(--main-bg-color)","margin-left":"6px"},attrs:{"icon":"fa-regular fa-clock"}})],1):_vm._e(),(false)?_c('div',{staticStyle:{"margin-left":"10px","cursor":"pointer"},style:({
                              opacity: event.affected == null ? '0.3' : '1',
                              cursor:
                                event.affected == null ? 'default' : 'pointer'
                            }),attrs:{"disabled":event.affected == null},on:{"click":function($event){return _vm.openReplanningModal(event.event)}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"14","color":"var(--main-bg-color)"},attrs:{"icon":"fa-solid fa-calendar-days"}})],1):_vm._e()]:_vm._e()],2)],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.getEventsCollapse[index].value),expression:"getEventsCollapse[index].value"}],staticClass:"view-collapse-child",attrs:{"id":'event-collapse-' + index}},[_c('b-col',{attrs:{"cols":"1"}}),_c('b-col',{staticStyle:{"display":"flex"}},[(event.affected != null)?_c('b-img',{staticClass:"align-vertical-middle",staticStyle:{"width":"25px","height":"auto","margin-left":"2px"},attrs:{"src":_vm.getTimelineItemIcon(
                              event.affected.timeElapsed,
                              event.affected.getType
                            )}}):_vm._e(),(event.affected != null)?_c('p',{staticClass:"align-vertical-middle text-multiple-lines-max-2 text-overflow-ellipsis",staticStyle:{"margin-left":"12px"}},[_vm._v(" "+_vm._s(event.affected.getName)+" ")]):_c('p',{staticClass:"align-vertical-middle",staticStyle:{"margin-left":"40px"}},[_vm._v(" "+_vm._s(_vm.text.noAffectations)+" ")])],1),_c('b-col',{staticClass:"p-0 align-vertical-middle",attrs:{"cols":"2"}})],1)],1)}),0)],1):_vm._e()],1)],1):_vm._e(),(_vm.permissionsPlanner.read && _vm.permissionsStages.read)?_c('b-row',{staticClass:"planner-list-2"},[_c('b-col',[_c('b-row',{staticClass:"view-list",staticStyle:{"border-top":"none","border-bottom":"solid 1px gray"},on:{"click":function($event){_vm.collapsePengingStages = !_vm.collapsePengingStages;
                  _vm.collapsePendingEvents = false;}}},[_c('b-col',{attrs:{"cols":"1"}},[(
                      _vm.collapsePengingStages &&
                      _vm.stagesPendingToConfirm.length > 0
                    )?_c('FontAwesomeIcon',{staticStyle:{"height":"15"},attrs:{"icon":"fa-solid fa-chevron-up"}}):_c('FontAwesomeIcon',{staticStyle:{"height":"15"},attrs:{"icon":"fa-solid fa-chevron-down"}})],1),_c('b-col',[_c('b',[_vm._v(_vm._s(_vm.text.stages))])]),_c('b-col',{attrs:{"cols":"1"}},[(_vm.projectEventsDx.length == 0)?_c('b-badge',{staticClass:"view-badge",attrs:{"pill":""}},[_vm._v(_vm._s(_vm.projectEventsDx.length)+" ")]):_c('b-badge',{staticClass:"view-badge-active",attrs:{"pill":""}},[_vm._v(_vm._s(_vm.stagesPendingToConfirm.length))])],1)],1),(_vm.collapsePengingStages)?_c('b-row',{staticClass:"planner-list-scroll",attrs:{"id":"collapse-2"}},[_c('b-col',_vm._l((_vm.stagesPendingToConfirm),function(stage,index){return _c('div',{key:'stage-collapse-affected-key-' + index},[_c('b-row',{staticClass:"view-collapse",staticStyle:{"padding":"1rem","border-top-style":"none"},style:({
                        backgroundColor: !_vm.getStagesCollapse[index].value
                          ? stage.timeElapsed.getColor
                          : '#ffffff',
                        borderBottom: !_vm.getStagesCollapse[index].value
                          ? 'solid 1px gray'
                          : 'solid 1px #ffffff'
                      })},[_c('b-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"1"},on:{"click":function($event){_vm.stagesCollapse[index].value =
                            !_vm.stagesCollapse[index].value}}},[(_vm.getStagesCollapse[index].value)?_c('FontAwesomeIcon',{staticStyle:{"opacity":"0.5"},attrs:{"icon":"fa-solid fa-chevron-up"}}):_c('FontAwesomeIcon',{staticStyle:{"opacity":"0.5"},attrs:{"icon":"fa-solid fa-chevron-down"}})],1),_c('b-col',{staticStyle:{"display":"flex"}},[_c('b-img',{staticClass:"align-vertical-middle",staticStyle:{"width":"30px","height":"auto"},attrs:{"src":_vm.getTimelineItemIcon(
                              stage.timeElapsed,
                              '',
                              stage.stage.color
                            )}}),_c('p',{staticClass:"align-vertical-middle text-multiple-lines-max-2 text-overflow-ellipsis",staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(stage.stage.getName)+" ")])],1),_c('b-col',{staticClass:"p-0 align-vertical-middle",staticStyle:{"text-align":"center","display":"flex"},attrs:{"cols":"3"}},[(_vm.getStagesCollapse[index].value)?[(_vm.permissionsPlanner.write)?_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.confirmStageAction(stage)}}},[_c('FontAwesomeIcon',{staticStyle:{"color":"var(--main-bg-color)","height":"14"},attrs:{"icon":"fa-solid fa-check"}})],1):_vm._e(),(_vm.permissionsPlanner.write)?_c('div',{staticStyle:{"cursor":"pointer","margin-left":"10px"},style:({
                              opacity: stage.affected != null ? '0.5' : '1',
                              cursor:
                                stage.affected != null ? 'default' : 'pointer'
                            }),attrs:{"disabled":stage.affected != null},on:{"click":function($event){return _vm.openPostponeModal(stage.stage)}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"14","color":"var(--main-bg-color)"},attrs:{"icon":"fa-regular fa-clock"}})],1):_vm._e(),(false)?_c('div',{staticStyle:{"margin-left":"10px","cursor":"pointer"},style:({
                              opacity: stage.affected == null ? '0.3' : '1',
                              cursor:
                                stage.affected == null ? 'default' : 'pointer'
                            }),attrs:{"disabled":stage.affected == null},on:{"click":function($event){return _vm.openReplanningModal(stage.stage)}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"14","color":"var(--main-bg-color)"},attrs:{"icon":"fa-solid fa-calendar-days"}})],1):_vm._e()]:_vm._e()],2)],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.getStagesCollapse[index].value),expression:"getStagesCollapse[index].value"}],staticClass:"view-collapse-child",attrs:{"id":'stage-collapse-affected-' + index}},[_c('b-col',{attrs:{"cols":"1"}}),_c('b-col',{staticStyle:{"display":"flex"}},[(stage.affected != null)?_c('b-img',{staticClass:"align-vertical-middle",staticStyle:{"width":"30px","height":"auto"},attrs:{"src":_vm.getTimelineItemIcon(
                              stage.affected.timeElapsed,
                              '',
                              stage.stage.color
                            )}}):_vm._e(),(stage.affected != null)?_c('p',{staticClass:"align-vertical-middle text-multiple-lines-max-2 text-overflow-ellipsis",staticStyle:{"margin-left":"10px"}},[_vm._v(" "+_vm._s(stage.affected.getName)+" ")]):_c('p',{staticClass:"align-vertical-middle",staticStyle:{"margin-left":"40px"}},[_vm._v(" "+_vm._s(_vm.text.noAffectations)+" ")])],1),_c('b-col',{staticClass:"p-0 align-vertical-middle",attrs:{"cols":"2"}})],1)],1)}),0)],1):_vm._e()],1)],1):_vm._e()],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"cols":"8"}},[_c('Timeline',{attrs:{"comeFrom":"planner","projectId":_vm.projectId,"projectCore":_vm.projectCore,"eventsDx":_vm.projectEventsDx,"stagesDx":_vm.projectStagesDx,"requestsDx":_vm.projectRequestsDx,"selectedVersionDxId":_vm.currentVersionDxId}}),(_vm.selectedVersionDxId)?_c('hr',{staticClass:"separation-line"}):_vm._e(),(_vm.selectedVersionDxId)?_c('Timeline',{attrs:{"comeFrom":"planner","projectId":_vm.projectId,"projectCore":_vm.projectCore,"eventsDx":_vm.projectEventsDx,"stagesDx":_vm.projectStagesDx,"requestsDx":_vm.projectRequestsDx,"selectedVersionDxId":_vm.selectedVersionDxId}}):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }