













































































































































































































































































































































































































































































































  import { defineComponent } from "vue";
  import {
    eventStates,
    getTimelineItemIcon,
    getTimelineItemIconFired
  } from "../../../_helpers/eventTypesStates";
  import { servicesExtensions } from "../../../_helpers/servicesExtensions";
  import PostponeForm from "../../../components/services/deliveryexperience/planner/PostponeForm.vue";
  import ReplanningForm from "../../../components/services/deliveryexperience/planner/ReplanningForm.vue";
  import Timeline from "../../../components/services/deliveryexperience/Timeline.vue";
  import ProjectCore from "../../../models/core/ProjectCore";
  import { IBaseLineDx } from "../../../models/deliveryexperience/BaseLineDx";
  import EventDx from "../../../models/deliveryexperience/EventDx";
  import RequestDx from "../../../models/deliveryexperience/RequestDx";
  import StageDx from "../../../models/deliveryexperience/StageDx";
  import VersionDx from "../../../models/deliveryexperience/VersionDx";
  import AffectedEventStage from "../../../view-models/deliveryexperience/AffectedEventStage";
  import PredecessorWithAffected from "../../../view-models/deliveryexperience/PredecessorWithAffected";
  import TimeElapsed from "../../../view-models/deliveryexperience/TimeElapsed";

  export default defineComponent({
    name: servicesExtensions.deliveryExperience.extensions.planner.name.toLowerCase(),
    components: {
      Timeline,
      ReplanningForm,
      PostponeForm
    },
    props: { projectId: Number },
    data() {
      return {
        loadedData: false,
        collapsePendingEvents: false,
        collapsePengingStages: false,
        eventId: undefined,
        stageId: undefined,
        assignedVersionDx: "",
        replanEventStage: undefined,
        affected: undefined,
        isItEventOrStage: "",
        eventsCollapse: [],
        stagesCollapse: [],
        currentDate: new Date(),
        twoDaysInMilliseconds: 172800000,
        // Timeline
        currentVersionDxId: undefined as Number, // Always last version
        selectedVersionDxId: undefined as Number, // The one selected in the replanning section
        permissionsPlanner: {},
        permissionsEvents: {},
        permissionsStages: {},
        text: {
          noAvaliableVersions: this.$t("noAvaliableVersions"),
          selectVersion: this.$t("selectVersion"),
          actuaState: this.$t("actuaState"),
          current: this.$t("current"),
          events: this.$t("events"),
          noAffectations: this.$t("noAffectations"),
          stages: this.$t("stages"),
          pendingToConfirm: this.$t("pendingToConfirm")
        }
      };
    },
    mounted: async function () {
      this.permissionsPlanner = this.getUserPermissions.planner;
      this.permissionsEvents = this.getUserPermissions.events;
      this.permissionsStages = this.getUserPermissions.stages;
      // Load Versions
      await this.loadVersionsDxByProjectId(this.projectId);
      await this.loadRequestsDxByProjectId(this.projectId);
      this.currentVersionDxId = this.getLastProjectVersionDx(
        this.projectId
      ).getVersionId;
      this.loadedData = true;
      this.$emit(
        "projectHeaderService",
        servicesExtensions.deliveryExperience.name
      );
      this.$emit(
        "projectHeaderExtension",
        servicesExtensions.deliveryExperience.extensions.planner.name
      );
    },
    computed: {
      projectCore(): ProjectCore {
        return this.getProjectById(this.projectId);
      },
      versionsDxSelector(): VersionDx[] {
        //make a copy of project versions
        let versions: VersionDx[] = this.getVersionsDxByProjectId(
          this.projectId
        ).map(
          versionDx => new VersionDx(JSON.parse(JSON.stringify(versionDx)))
        );
        //Remove last version
        versions.pop();
        return versions;
      },
      versionsDxSelectorText(): string {
        if (this.versionsDxSelector.length == 0) {
          return this.text.noAvaliableVersions;
        } else {
          if (this.selectedVersionDxId) {
            return "V " + this.selectedVersionDxId.toString();
          } else return this.text.selectVersion;
        }
      },
      projectEventsDx(): EventDx {
        return this.getEventsDxByProjectId(this.projectId);
      },
      projectStagesDx(): StageDx {
        return this.getStagesDxByProjectId(this.projectId);
      },
      projectRequestsDx(): RequestDx[] {
        return this.getRequestsDxByProjectId(
          Number(this.$route.params.projectId)
        );
      },
      eventsPendingToConfirm(): PredecessorWithAffected[] {
        const result: PredecessorWithAffected[] = [];
        let pendingEvent: PredecessorWithAffected;
        let affectedEvent: AffectedEventStage;

        for (let i in this.projectEventsDx) {
          if (
            this.projectEventsDx[i].getLastHistoryBaseline.status ==
            eventStates.pendingToConfirm.name
          ) {
            affectedEvent = null;
            //Add affected event
            if (
              this.projectEventsDx[i].getLastHistoryBaseline.affecteds.length >
              0
            ) {
              affectedEvent = this.addAffectedEvent(
                this.projectEventsDx[i].getLastHistoryBaseline.affecteds[0]
              );
            }
            pendingEvent = new PredecessorWithAffected({
              event: this.projectEventsDx[i],
              affected: affectedEvent,
              timeElapsed: this.addTimeElapsed(
                this.projectEventsDx[i].getLastHistoryBaseline.startedDate
              )
            });
            result.push(pendingEvent);
          }
        }
        return result;
      },
      stagesPendingToConfirm(): PredecessorWithAffected[] {
        let result = [];
        let pendingStage: PredecessorWithAffected;
        let affectedStage: AffectedEventStage;
        for (let i in this.projectStagesDx) {
          if (
            this.projectStagesDx[i].getLastHistoryBaseline.status ==
            eventStates.pendingToConfirm.name
          ) {
            affectedStage = null;
            //Add affected event
            if (
              this.projectStagesDx[i].getLastHistoryBaseline.affecteds.length >
              0
            ) {
              affectedStage = this.addAffectedStage(
                this.projectStagesDx[i].getLastHistoryBaseline.affecteds[0]
              );
            }
            pendingStage = new PredecessorWithAffected({
              stage: this.projectStagesDx[i],
              affected: affectedStage,
              timeElapsed: this.addTimeElapsed(
                this.projectStagesDx[i].getLastHistoryBaseline.endDate
              )
            });
            result.push(pendingStage);
          }
        }
        return result;
      },
      getEventsCollapse: {
        set(value) {
          this.eventsCollapse = value;
        },
        get(): { value: Boolean }[] {
          if (this.eventsCollapse.length == 0) {
            this.eventsPendingToConfirm.forEach(() => {
              this.eventsCollapse.push({ value: false });
            });
          }
          return this.eventsCollapse;
        }
      },
      getStagesCollapse: {
        set(value) {
          this.stagesCollapse = value;
        },
        get(): { value: Boolean }[] {
          if (this.stagesCollapse.length == 0) {
            this.stagesPendingToConfirm.forEach(() => {
              this.stagesCollapse.push({ value: false });
            });
          }
          return this.stagesCollapse;
        }
      }
    },
    methods: {
      getEventDxFromProject(eventId: string): EventDx {
        return this.projectEventsDx.find(event => event.getId == eventId);
      },
      getStageDxFromProject(stageId: string): StageDx {
        return this.projectStagesDx.find(stage => stage.getId == stageId);
      },
      addTimeElapsed(startDate: Date): TimeElapsed {
        const eventDate: Date = new Date(startDate);
        if (
          this.currentDate.getTime() > eventDate.getTime() &&
          this.currentDate.getTime() <=
            eventDate.getTime() + this.twoDaysInMilliseconds
        ) {
          return new TimeElapsed({
            twoDays: true,
            color: "#ff7e83"
          });
        }
        //this.currentDate.getTime() > eventDate.getTime() + twoDaysInMilliseconds
        else {
          return new TimeElapsed({
            twoDays: false,
            color: "#ececec"
          });
        }
      },
      addAffectedEvent(affectedId: string): AffectedEventStage | null {
        let ev: EventDx;
        let te: TimeElapsed = undefined;
        ev = this.projectEventsDx.find(event => event.getId == affectedId);
        if (ev != undefined) {
          if (
            ev.getLastHistoryBaseline.status ==
            eventStates.pendingToConfirm.name
          ) {
            te = this.addTimeElapsed(ev.getLastHistoryBaseline.startedDate);
          }
          return new AffectedEventStage({
            name: ev.getName,
            type: ev.getEventType,
            timeElapsed: te
          });
        } else {
          return null;
        }
      },
      addAffectedStage(affectedId: string): AffectedEventStage | null {
        let st: StageDx;
        let te: TimeElapsed;
        st = this.projectStagesDx.find(stage => stage.getId == affectedId);
        if (st != undefined) {
          te = this.addTimeElapsed(st.getLastHistoryBaseline.endDate);
          return new AffectedEventStage({
            name: st.getName,
            type: "",
            timeElapsed: te
          });
        } else {
          return null;
        }
      },
      getTimelineItemIcon(
        timeElapsed,
        type: string,
        color?: string
      ): NodeRequire {
        if (timeElapsed == undefined) return getTimelineItemIcon(type);
        else if (timeElapsed.twoDays)
          return getTimelineItemIconFired(type, true, color);
        else return getTimelineItemIconFired(type, false, color);
      },
      setIsItEventOrStage(eventStage: any): string {
        if (eventStage.hasOwnProperty("eventType")) {
          return "event";
        } else {
          return "stage";
        }
      },
      getAffected(eventStage: any): EventDx | StageDx {
        if (this.isItEventOrStage == "event")
          return this.getEventDxFromProject(
            eventStage.getLastHistoryBaseline.affecteds[0]
          );
        else
          return this.getStageDxFromProject(
            eventStage.getLastHistoryBaseline.affecteds[0]
          );
      },
      // Delete relation with PREDECESSOR, then with AFFECTED, then UPDATE EVENT then update EVENT BASELINE
      async confirmEventAction(event: PredecessorWithAffected) {
        const lastProjectVersionDxId: string =
          await this.fetchLastProjectVersionDx(this.projectId);
        const eventToConfirm: EventDx = event.getEvent;
        const predecessorId: string =
          eventToConfirm.getLastHistoryBaseline.predecessors[0];
        const affectedId: string =
          eventToConfirm.getLastHistoryBaseline.affecteds[0];
        if (predecessorId) {
          await this.deleteRelationEventDxPredecessor(
            predecessorId,
            lastProjectVersionDxId
          );
        }
        if (affectedId) {
          await this.deleteRelationEventDxAffected(
            affectedId,
            lastProjectVersionDxId
          );
        }
        await this.confirmEventDx(eventToConfirm);
        await this.confirmEventDxBaseline(
          eventToConfirm,
          lastProjectVersionDxId
        );
        this.forceRerender("events");
      },
      async deleteRelationEventDxPredecessor(
        predecessorId: string,
        lastProjectVersionDxId: number
      ): Promise<void> {
        const predecessorEvent: EventDx =
          this.getEventDxFromProject(predecessorId);
        const lastPredecessorBaseline: IBaseLineDx =
          predecessorEvent.getLastHistoryBaseline;
        let updatedPredecessorBaseline: {
          eventId: string;
          baseline: IBaseLineDx;
        };
        //Event PREDECESSOR baseline to update: delete affected
        updatedPredecessorBaseline = {
          eventId: predecessorEvent.getId,
          baseline: {
            version: lastProjectVersionDxId,
            status: lastPredecessorBaseline.status,
            startedDate: lastPredecessorBaseline.startedDate,
            endDate: null,
            affecteds: [],
            predecessors: lastPredecessorBaseline.predecessors
          }
        };
        try {
          if (lastPredecessorBaseline.version == lastProjectVersionDxId) {
            await this.putEventDxBaseline(updatedPredecessorBaseline);
          } else {
            await this.postEventDxBaseline(updatedPredecessorBaseline);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async deleteRelationEventDxAffected(
        affectedId: string,
        lastProjectVersionDxId: number
      ): Promise<void> {
        const affectedEvent: EventDx = this.getEventDxFromProject(affectedId);
        const lastAffectedBaseline: IBaseLineDx =
          affectedEvent.getLastHistoryBaseline;
        let updatedAffectedBaseline: {
          eventId: string;
          baseline: IBaseLineDx;
        };
        //Event PREDECESSOR baseline to update: delete predecessor
        updatedAffectedBaseline = {
          eventId: affectedEvent.getId,
          baseline: {
            version: lastProjectVersionDxId,
            status: lastAffectedBaseline.status,
            startedDate: lastAffectedBaseline.startedDate,
            endDate: null,
            affecteds: lastAffectedBaseline.affecteds,
            predecessors: []
          }
        };
        try {
          if (lastAffectedBaseline.version == lastProjectVersionDxId) {
            await this.putEventDxBaseline(updatedAffectedBaseline);
          } else {
            await this.postEventDxBaseline(updatedAffectedBaseline);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async confirmEventDx(eventToConfirm: EventDx): Promise<void> {
        eventToConfirm.setConfirmed = true;
        eventToConfirm.setOccured = true;
        await this.putEventDx(eventToConfirm);
      },
      async confirmEventDxBaseline(
        eventToConfirm: EventDx,
        lastProjectVersionDxId: number
      ): Promise<void> {
        const lastEventToConfirmBaseline: IBaseLineDx =
          eventToConfirm.getLastHistoryBaseline;
        let updatedEventToConfirmBaseline: {
          eventId: string;
          baseline: IBaseLineDx;
        };
        updatedEventToConfirmBaseline = {
          eventId: eventToConfirm.getId,
          baseline: {
            version: lastProjectVersionDxId,
            status: eventStates.occurred.name,
            startedDate: lastEventToConfirmBaseline.startedDate,
            endDate: null,
            affecteds: [],
            predecessors: []
          }
        };
        try {
          if (lastEventToConfirmBaseline.version == lastProjectVersionDxId) {
            await this.putEventDxBaseline(updatedEventToConfirmBaseline);
          } else {
            await this.postEventDxBaseline(updatedEventToConfirmBaseline);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      // Delete relation with PREDECESSOR, then with AFFECTED, then UPDATE STAGE then update STAGE BASELINE
      async confirmStageAction(stage: PredecessorWithAffected) {
        const lastProjectVersionDxId: string =
          await this.fetchLastProjectVersionDx(this.projectId);
        const stageToConfirm: StageDx = stage.getStage;
        const predecessorId: string =
          stageToConfirm.getLastHistoryBaseline.predecessors[0];
        const affectedId: string =
          stageToConfirm.getLastHistoryBaseline.affecteds[0];
        if (predecessorId) {
          await this.deleteRelationStageDxPredecessor(
            predecessorId,
            lastProjectVersionDxId
          );
        }
        if (affectedId) {
          await this.deleteRelationStagetDxAffected(
            affectedId,
            lastProjectVersionDxId
          );
        }
        await this.confirmStageDx(stageToConfirm);
        await this.confirmStageDxBaseline(
          stageToConfirm,
          lastProjectVersionDxId
        );
        this.forceRerender("stages");
      },
      async deleteRelationStageDxPredecessor(
        predecessorId: string,
        lastProjectVersionDxId: number
      ): Promise<void> {
        const predecessorStage: StageDx =
          this.getStageDxFromProject(predecessorId);
        const lastPredecessorBaseline: IBaseLineDx =
          predecessorStage.getLastHistoryBaseline;
        let updatedPredecessorBaseline: {
          stageId: string;
          baseline: IBaseLineDx;
        };
        //Event PREDECESSOR baseline to update: delete affected
        updatedPredecessorBaseline = {
          stageId: predecessorStage.getId,
          baseline: {
            version: lastProjectVersionDxId,
            status: lastPredecessorBaseline.status,
            startedDate: lastPredecessorBaseline.startedDate,
            endDate: lastPredecessorBaseline.endDate,
            affecteds: [],
            predecessors: lastPredecessorBaseline.predecessors
          }
        };
        try {
          if (lastPredecessorBaseline.version == lastProjectVersionDxId) {
            await this.putStageDxBaseline(updatedPredecessorBaseline);
          } else {
            await this.postStageDxBaseline(updatedPredecessorBaseline);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async deleteRelationStagetDxAffected(
        affectedId: string,
        lastProjectVersionDxId: number
      ): Promise<void> {
        const affectedStage: StageDx = this.getStageDxFromProject(affectedId);
        const lastAffectedBaseline: IBaseLineDx =
          affectedStage.getLastHistoryBaseline;
        let updatedAffectedBaseline: {
          stageId: string;
          baseline: IBaseLineDx;
        };
        //Event PREDECESSOR baseline to update: delete predecessor
        updatedAffectedBaseline = {
          stageId: affectedStage.getId,
          baseline: {
            version: lastProjectVersionDxId,
            status: lastAffectedBaseline.status,
            startedDate: lastAffectedBaseline.startedDate,
            endDate: lastAffectedBaseline.endDate,
            affecteds: lastAffectedBaseline.affecteds,
            predecessors: []
          }
        };
        try {
          if (lastAffectedBaseline.version == lastProjectVersionDxId) {
            await this.putStageDxBaseline(updatedAffectedBaseline);
          } else {
            await this.postStageDxBaseline(updatedAffectedBaseline);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async confirmStageDx(stageToConfirm: StageDx): Promise<void> {
        stageToConfirm.setConfirmed = true;
        stageToConfirm.setOccured = true;
        await this.putStageDx(stageToConfirm);
      },
      async confirmStageDxBaseline(
        stageToConfirm: StageDx,
        lastProjectVersionDxId: number
      ): Promise<void> {
        const lastStageToConfirmBaseline: IBaseLineDx =
          stageToConfirm.getLastHistoryBaseline;
        let updatedStageToConfirmBaseline: {
          stageId: string;
          baseline: IBaseLineDx;
        };
        updatedStageToConfirmBaseline = {
          stageId: stageToConfirm.getId,
          baseline: {
            version: lastProjectVersionDxId,
            status: eventStates.occurred.name,
            startedDate: lastStageToConfirmBaseline.startedDate,
            endDate: lastStageToConfirmBaseline.endDate,
            affecteds: [],
            predecessors: []
          }
        };
        try {
          if (lastStageToConfirmBaseline.version == lastProjectVersionDxId) {
            await this.putStageDxBaseline(updatedStageToConfirmBaseline);
          } else {
            await this.postStageDxBaseline(updatedStageToConfirmBaseline);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      forceRerender(updateType: string): void {
        this.$root.$emit("updateTimeline", updateType);
      },
      // When change currentVersionDxId the Timeline is updated automatically.
      forceRerenderAndUpdateVersion(): void {
        this.currentVersionDxId = this.getLastProjectVersionDx(
          this.projectId
        ).getVersionId;
      },
      async openReplanningModal(eventStage: Object) {
        this.isItEventOrStage = await this.setIsItEventOrStage(eventStage);
        const lastVersion: string = await this.fetchLastProjectVersionDx(
          this.projectId
        );
        this.assignedVersionDx = (parseInt(lastVersion) + 1).toString();
        this.replanEventStage = eventStage;
        this.affected = await this.getAffected(eventStage);
        this.$bvModal.show("planner-replanning-modal");
      },
      async openPostponeModal(eventStage: Object) {
        this.isItEventOrStage = await this.setIsItEventOrStage(eventStage);
        const lastVersion: string = await this.fetchLastProjectVersionDx(
          this.projectId
        );
        this.assignedVersionDx = lastVersion;
        this.replanEventStage = eventStage;
        this.$bvModal.show("planner-postpone-modal");
      }
    }
  });
