






































  export default {
    name: "ConfirmationModal",
    props: {
      titleConfirmation: String,
      messageConfirmation: String,
      id: String
    },
    data() {
      return {
        loading: false,
        text: {
          confirm: this.$t("confirm"),
          cancel: this.$t("cancel")
        }
      };
    },
    methods: {
      confirm(bvModalEvent) {
        this.loading = true;
        bvModalEvent.preventDefault();
        this.$emit("confirm");
      }
    }
  };
