export interface IMail {
  to: string;
  subject: string;
  body: string;
  attachments?: { filename: string; content: any; contentType: string }[];
}

export default class Mail {
  private to: string;
  private subject: string;
  private body: string;

  constructor(dto: IMail) {
    Object.assign(this, dto);
  }

  get getTo(): string {
    return this.to;
  }

  set setTo(value: string) {
    this.to = value;
  }

  get getSubject(): string {
    return this.subject;
  }

  set setSubject(value: string) {
    this.subject = value;
  }

  get getBody(): string {
    return this.body;
  }

  set setBody(value: string) {
    this.body = value;
  }
}
