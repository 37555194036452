import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import ProjectCore from "../../../models/core/ProjectCore";
import ProjectServiceCore from "../../../models/core/project/ProjectServiceCore";
import ProjectStateCore from "../../../models/core/project/ProjectStateCore";
import projectService from "../../../services/core/projectService";
import { RootState } from "../../index";

const namespaced = true;

interface ProjectState {
  projects: ProjectCore[];
  projectStates: ProjectStateCore[];
}

const state: ProjectState = {
  projects: [],
  projectStates: []
};

const getters: GetterTree<ProjectState, RootState> = {
  getProjects: (state): ProjectCore[] => {
    return state.projects;
  },
  getProjectById:
    state =>
    (id: number): ProjectCore => {
      return state.projects.find(project => project.getId == id);
    },
  getUserProjectsByClientId:
    state =>
    (clientId: number): ProjectCore[] => {
      return state.projects.filter(project => project.getClientId == clientId);
    },
  getLastProjectStateByProjectId:
    state =>
    (projectId: number): ProjectStateCore => {
      let projectStates: ProjectStateCore[] = state.projectStates.filter(
        element => element.getProjectId == projectId
      );
      projectStates = projectStates.sort(function (a, b) {
        return b.getId - a.getId;
      });
      return projectStates[0];
    }
};

const mutations: MutationTree<ProjectState> = {
  setProjects(state, projects: ProjectCore[]) {
    state.projects = projects;
  },
  setProjectStates(state, projectStates: ProjectStateCore[]) {
    state.projectStates = projectStates;
  },
  addProjectState(state, projectState: ProjectStateCore) {
    state.projectStates.push(projectState);
  },
  addProject(state, project: ProjectCore) {
    const index = state.projects.findIndex(p => p.getId === project.getId);
    if (index === -1) state.projects.push(project);
    else state.projects[index] = project;
  },
  updateProject(state, updatedProject: ProjectCore) {
    const index = state.projects.findIndex(
      p => p.getId === updatedProject.getId
    );
    if (index != -1) {
      state.projects.splice(index, 1, updatedProject);
    }
  },
  deleteProject(state, projectId: number) {
    const index = state.projects.findIndex(
      project => project.getId == projectId
    );
    state.projects.splice(index, 1);
  }
};

const actions: ActionTree<ProjectState, RootState> = {
  async loadProjectStates({ commit }) {
    const projectStates: ProjectStateCore[] =
      await projectService.methods.getProjectStates();
    commit("setProjectStates", projectStates);
  },
  async loadProjectState({}, projectId: number) {
    const projectState: ProjectStateCore =
      await projectService.methods.getProjectState(projectId);
    //commit("setProjectState", projectState);
    return projectState;
  },
  async loadProjects({ commit }) {
    const projects = await projectService.methods.getProjects();
    commit("setProjects", projects);
  },
  async loadProject({ commit }, projectId: number) {
    const project = await projectService.methods.getProject(projectId);
    commit("addProject", project);
    return project;
  },
  async loadProjectsByClient({ commit }, clientId: number) {
    const projects = await projectService.methods.getProjectsByClient(clientId);
    commit("setProjects", projects);
  },
  async fetchProjectService(
    {},
    data: { serviceId: number; projectId: number }
  ) {
    const activeService: ProjectServiceCore =
      await projectService.methods.getProjectService(data);
    return activeService;
  },
  async postProject({ commit }, projectCore: ProjectCore) {
    const createdProject = await projectService.methods.postProject(
      projectCore
    );
    commit("addProject", createdProject);
    return createdProject;
  },
  async postProjectState({ commit }, projectStateCore: ProjectStateCore) {
    const createdProjectState = await projectService.methods.postProjectState(
      projectStateCore
    );
    commit("addProjectState", createdProjectState);
  },
  async postProjectServices({}, projectServiceCore: ProjectServiceCore) {
    await projectService.methods.postProjectServices(projectServiceCore);
  },
  async putProject({ commit }, project: ProjectCore) {
    const updatedProject = await projectService.methods.putProject(project);
    commit("updateProject", updatedProject);
    return updatedProject;
  },
  async deleteProject({ commit }, projectId: number) {
    await projectService.methods.deleteProject(projectId);
    commit("deleteProject", projectId);
  },
  async deleteProjectServices({}, projectServiceCore: ProjectServiceCore) {
    await projectService.methods.deleteProjectServices(projectServiceCore);
  },
  async validateProjectName({}, projectName: string): Promise<any> {
    return await projectService.methods.validateProjectName(projectName);
  }
};

export const projectModuleCore: Module<ProjectState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
