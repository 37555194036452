

































  /* eslint-disable */
  import * as d3 from "d3";
  import { selectAll } from "d3-selection";
  import { eventStates, eventTypes } from "../../../_helpers/eventTypesStates";
  import PeopleCore from "../../../models/core/PeopleCore";
  import EventDx from "../../../models/deliveryexperience/EventDx";
  import PeopleIcon from "../../../view-models/PeopleIcon";
  import PeopleInfo from "../PeopleInfo.vue";

  export default {
    name: "PeopleHistogram",
    components: { PeopleInfo },
    props: {
      id: String, // like this: project-overview-people-histogram-999
      projectId: String,
      projectStartDate: String,
      events: Array,
      xKey: String,
      yKey: String
    },
    data() {
      return {
        svgWidth: 0,
        numMonths: 0,
        text: {
          kpi_not_available: this.$t("kpiNotAvailable")
        },
        resources: [],
        popoverIndexes: [0, 1, 2, 3, 4]
      };
    },
    async mounted() {
      this.numMonths = this.getSelectedViewEventsData.length;
      this.svgWidth = (this.numMonths * 165) / 5;
      this.createHistogram();
      // this.animateLoad();
    },
    computed: {
      svgHeight(): number {
        const width = document.getElementById(
          this.id + "-histogram-ctn"
        ).offsetWidth;
        return width * 0.8;
      },
      middlePeopleValue(): number {
        let aux = 0;
        for (let i in this.getSelectedViewEventsData) {
          aux += this.getSelectedViewEventsData[i].balance;
        }
        // only one month visualization
        if (this.numMonths == 1) {
          return Math.ceil(aux / this.getSelectedViewEventsData.length);
        }
        return Math.round(aux / this.getSelectedViewEventsData.length);
      },
      createArrayMonth(): {
        date: Date;
        balance: number;
        resources: PeopleCore[];
      }[] {
        let resultData: {
          date: Date;
          balance: number;
          resources: PeopleCore[];
        }[] = [];

        let startDate;
        const currentDate = this.$moment(new Date()).startOf("month");
        const projectStartDate = this.$moment(this.projectStartDate).startOf(
          "month"
        );
        //Project creation date is equal or smaller than current date
        if (projectStartDate <= currentDate) {
          const diffStartEnd = currentDate.diff(projectStartDate, "months");
          startDate =
            diffStartEnd <= 4
              ? projectStartDate
              : this.$moment().subtract(4, "months").startOf("month");

          //Add months to array
          while (startDate <= currentDate) {
            resultData.push({
              date: startDate.toDate(),
              balance: 0,
              resources: []
            });
            startDate = startDate.add(1, "months");
          }
        }

        return resultData;
      },
      getSelectedViewEventsData(): {
        date: Date;
        balance: number;
        resources: PeopleCore[];
      }[] {
        let resultData: {
          date: Date;
          balance: number;
          resources: PeopleCore[];
        }[] = this.createArrayMonth;
        //ALL EVENTS OF CURRENT PROJECT
        let eventsList: EventDx[] = this.events;
        //FILTER EVENTS OF TYPE NEW INCORPORATION OR UNASSIGNMENT OF CURRENT PROJECT
        //AND ADD/SUBSTRACT QUANTITY TO BALANCE EACH MONTH OF resultData
        let balance: number = 0;
        let resources: Array<PeopleCore> = [];
        let eventIndex = 0;
        for (let i = 0; i < resultData.length; i++) {
          const resultDataDate = this.$moment(resultData[i].date).startOf(
            "month"
          );
          while (
            eventIndex < eventsList.length &&
            this.$moment(
              eventsList[eventIndex].getLastHistoryBaseline.startedDate
            ).startOf("month") <= resultDataDate
          ) {
            const status = eventsList[eventIndex].getLastHistoryBaseline.status;
            const pendingToConfirm = eventStates.pendingToConfirm.name;
            const occurred = eventStates.occurred.name;
            const eventTime = this.$moment(
              this.events[eventIndex].getLastHistoryBaseline.startedDate
            ).startOf("minute");
            if (
              (status == pendingToConfirm || status == occurred) &&
              this.$moment().startOf("minute") > eventTime
            ) {
              if (
                eventsList[eventIndex].getEventType ==
                eventTypes.PEOPLE.events.newIncorporation.type
              ) {
                balance++;
                resources.push(
                  this.getPeopleByUsername(eventsList[eventIndex].getTamUser)
                );
              }
              if (
                eventsList[eventIndex].getEventType ==
                eventTypes.PEOPLE.events.unassignment.type
              ) {
                balance--;
                const index = resources.indexOf(
                  this.getPeopleByUsername(eventsList[eventIndex].getTamUser)
                );
                if (index != -1) resources.splice(index, 1);
              }
            }
            eventIndex++;
          }

          resultData[i].balance = balance;
          resultData[i].resources = [...resources];
        }
        return resultData;
      }
    },
    methods: {
      getPeopleIcon(peopleCore: PeopleCore): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(peopleCore);
      },
      // d3 graphics functions
      createHistogram() {
        //Append svg container:
        const svg = d3
          .select("#" + this.id + "-histogram-ctn")
          .append("svg")
          .attr("id", "histogram-svg-" + this.id)
          .attr("overflow", "visible")
          .attr("width", this.svgWidth)
          .attr("height", this.svgHeight);

        let dis = this;
        //Append rects
        svg
          .selectAll(".bar")
          .data(this.getSelectedViewEventsData)
          .enter()
          .append("rect")
          .classed("bar", true)
          .classed("bar-over-mean", function (d, i) {
            return d.balance >= dis.middlePeopleValue;
          })
          .attr("rx", 6)
          .attr("ry", 6)
          .attr("x", function (d, i) {
            return dis.xScale(i);
          })
          .attr("y", function (d, i) {
            return dis.svgHeight - dis.yScale(d.balance);
          })
          .attr("width", function (d, i) {
            return dis.xScale(1) - 10;
          })
          .attr("height", function (d, i) {
            return dis.yScale(d.balance);
          })
          .style("fill", "var(--main-bg-color)");

        //Add rect > hover effect
        svg
          .selectAll(".bar-rect")
          .data(this.getSelectedViewEventsData)
          .enter()
          .append("rect")
          .classed("bar-rect", true)
          .attr("id", function (d, i) {
            return "id-bar-rect-" + i;
          })
          .attr("rx", 6)
          .attr("ry", 6)
          .attr("x", function (d, i) {
            return dis.xScale(i) - 5;
          })
          .attr("y", function (d, i) {
            return 0;
          })
          .attr("width", function (d, i) {
            return dis.xScale(1);
          })
          .attr("height", function (d, i) {
            return dis.svgHeight;
          })
          .on("mouseover", function (e) {
            const index = Array.from(
              e.target.parentNode.getElementsByClassName("bar-rect")
            ).indexOf(e.target);
            dis.resources = [...dis.getSelectedViewEventsData[index].resources];
          })
          .on("mouseout", function (e) {});

        //Add bar-labels
        svg
          .selectAll(".bar-label")
          .data(this.getSelectedViewEventsData)
          .enter()
          .append("text")
          .attr("id", function (d, i) {
            return dis.id + "-text-people-amount-" + i;
          })
          .classed("bar-label", true)
          .attr("opacity", "1")
          .style("fill", "var(--main-bg-color)")
          .attr("x", function (d, i) {
            return dis.xScale(i);
          })
          .attr("y", function (d, i) {
            return dis.svgHeight - dis.yScale(d.balance) - 5;
          })
          .attr("dx", function (d, i) {
            return dis.xScale(1) / 4;
          })
          .text(function (d, i) {
            return d.balance;
          });

        //Add x-label > Months
        svg
          .selectAll(".x-label")
          .data(this.getSelectedViewEventsData)
          .enter()
          .append("text")
          .classed("x-label", true)
          .attr("x", function (d, i) {
            return dis.xScale(i);
          })
          .attr("y", function (d, i) {
            return dis.svgHeight + 15;
          })
          .text(function (d, i) {
            return dis.$moment(d.date).format("MMM").toUpperCase().substr(0, 3);
          })
          .style("font", "8pt Ubuntu");
      },
      yScale(d) {
        const maxBalance = d3.max(this.getSelectedViewEventsData, function (d) {
          return d.balance;
        });
        const y = d3
          .scaleLinear()
          .domain([0, maxBalance])
          .range([5, maxBalance ? this.svgHeight - 20 : 5]);
        return y(d);
      },
      xScale(d) {
        const x = d3
          .scaleLinear()
          .domain([0, this.getSelectedViewEventsData.length])
          .range([0, this.svgWidth]);
        return x(d);
      },
      animateLoad() {
        selectAll("rect")
          .data(this.getSelectedViewEventsData)
          .transition()
          .delay((d, i) => {
            return i * 150;
          })
          .duration(1000)
          .attr("y", d => {
            return this.yScale(d[this.yKey]);
          })
          .attr("height", d => {
            return this.svgHeight - this.yScale(d[this.yKey]);
          });
      }
    }
  };
