import TeamDx from "../../models/deliveryexperience/TeamDx";
import service from "../service";
import baseEndpoint from "./serviceDx";

const endpoint = baseEndpoint + "/Team";

export default {
  components: { service: service },
  methods: {
    async getTeams(): Promise<TeamDx[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(team => new TeamDx(team));
    },
    async putTeam(modifiedTeam: TeamDx): Promise<TeamDx> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${modifiedTeam.getId}`,
        modifiedTeam
      );
      return new TeamDx(response);
    }
  }
};
