














































































































































  import {
    getTimelineItemIcon,
    getTimelineItemTypeFromSubtype
  } from "../_helpers/eventTypesStates";
  import notifications from "../_helpers/notifications";
  import { servicesExtensions } from "../_helpers/servicesExtensions";
  import CapsuleLabelInput from "../components/view_elements/CapsuleLabelInput.vue";
  import PeopleInfo from "../components/view_elements/PeopleInfo.vue";
  import PeopleIcon from "../view-models/PeopleIcon";

  export default {
    name: "notifications",
    components: { CapsuleLabelInput, PeopleInfo },
    data() {
      return {
        notificationsHelper: notifications,
        text: {
          notifications: this.$t("notifications"),
          noNotifications: this.$t("noNotifications"),
          unknownProject: this.$t("unknownProject"),
          unknown: this.$tc("unknown", 2)
        },
        pagination: {
          currentPage: 1,
          perPage: 20
        },
        filters: {
          author: "",
          item: "",
          project: ""
        },
        fieldsNames: [
          {
            label: this.$tc("description", 1),
            key: "description"
          },
          {
            label: this.$t("author"),
            key: "author",
            sortable: true
          },
          {
            label: "Item",
            key: "item",
            thClass: "max-width-column",
            tdClass: "max-width-column"
          },
          {
            label: this.$tc("project", 1),
            key: "project",
            sortable: true
          },
          {
            label: this.$t("date"),
            key: "date",
            sortable: true
          }
        ]
      };
    },
    async created() {
      await this.loadProjectsByClient(this.getUser.getClientId);
    },
    computed: {
      notificationsItems() {
        return this.getNotifications
          .map(n => {
            return {
              description: {
                action: n.getAction
              },
              author: {
                username: n.getAuthor,
                fullName: this.getPeopleByUsername(
                  n.getAuthor
                ).getNaturalFullName()
              },
              item: {
                type: n.getItemType,
                id: n.getItemId,
                name: n.getItemName
              },
              project: {
                id: n.getProjectId,
                name: this.projectName(n.getProjectId)
              },
              date: n.getTimeStamp
            };
          })
          .filter(n => {
            if (
              this.filters.author &&
              !n.author.fullName
                .toLowerCase()
                .includes(this.filters.author.toLowerCase())
            ) {
              return false;
            }
            if (
              this.filters.item &&
              !n.item.name
                .toLowerCase()
                .includes(this.filters.item.toLowerCase())
            ) {
              return false;
            }
            if (
              this.filters.project &&
              !n.project.name
                .toLowerCase()
                .includes(this.filters.project.toLowerCase())
            ) {
              return false;
            }
            return true;
          })
          .reverse();
      }
    },
    methods: {
      getTimer(time): string {
        return this.$moment().diff(this.$moment(time), "days") <= 7
          ? this.$moment(time).fromNow()
          : this.$moment(time).format("DD MMMM");
      },
      getNotificationActionTranslation(action: string): string {
        const actionObj = notifications.getTranslationByActionName(action);
        return actionObj ? this.$t(actionObj.i18nLong) : this.text.unknown;
      },
      getNotificationItemIcon(itemType: string): NodeRequire | undefined {
        if (itemType == "project") {
          return undefined;
        } else {
          return getTimelineItemIcon(itemType);
        }
      },
      getPeopleIcon(username: string): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(
          this.getPeopleByUsername(username)
        );
      },
      compareElements(itemA, itemB, key): number {
        if (key === "author") {
          return itemA[key].fullName.localeCompare(itemB[key].fullName);
        } else if (key === "project") {
          return itemA[key].name.localeCompare(itemB[key].name);
        } else
          return (
            new Date(itemA[key]).getTime() - new Date(itemB[key]).getTime()
          );
      },
      itemLink(itemId: string, subtype: string, projectId: number): string {
        const type = getTimelineItemTypeFromSubtype(subtype);
        if (type == "request")
          return `${servicesExtensions.deliveryExperience.url}/${projectId}/${servicesExtensions.deliveryExperience.extensions.requests.url}/${itemId}`;
        else if (type == "event")
          return `/${servicesExtensions.deliveryExperience.url}/${projectId}/event/${itemId}`;
        else
          return `/${servicesExtensions.deliveryExperience.url}/${projectId}/stage/${itemId}`;
      },
      projectLink(projectId: number): string {
        return `/${servicesExtensions.deliveryExperience.url}/${projectId}`;
      },
      projectIcon(projectId: number): string | null {
        if (this.getProjectById(projectId) != undefined) {
          const icon = this.getProjectById(projectId).getIcon;
          return icon
            ? icon
            : require("../assets/icons/placeholders/default-project.png");
        }
        return null;
      },
      projectName(projectId): string | null {
        if (this.getProjectById(projectId) != undefined) {
          return this.getProjectById(projectId).getName;
        }
        return null;
      }
    }
  };
