import ProjectCore, { IProject } from "./ProjectCore";
export interface IProjectCapacity extends IProject {
  capacityTypeId: number;
}

export default class ProjectCapacity extends ProjectCore {
  private capacityTypeId: number;

  constructor(dto: IProjectCapacity) {
    super(dto);
    Object.assign(this, dto);
  }

  get getCapacityTypeId(): number {
    return this.capacityTypeId;
  }
  set setCapacityTypeId(capacityTypeId: number) {
    this.capacityTypeId = capacityTypeId;
  }
}
