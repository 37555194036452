import config from "../../config";

function build(): string {
  return `
    <p>Se te ha registrado en SogetiHub y ya puedes <b>acceder a la plataforma</b> clicando en el botón de abajo.</p>
    <p>&nbsp;</p>
    <p><a href="${config.redirectUri}"><button style="padding: 1rem; color: white; background-color: #0070ad !important; border-radius: 1rem;"><b>Acceder a SogetiHub</b></button></a></p>
    <p>&nbsp;</p>
    <p style="text-align: justify">Si formas parte de un <b>proyecto existente</b> en la plataforma, pronto lo verás en la página principal. En cambio, si deseas crear <b>nuevos proyectos</b>, un administrador los creará para que puedas acceder a ellos en breve, pero antes, <b>proporciona los siguientes detalles para cada uno</b> a <a href="mailto:elliot.ribas-garcia@sogeti.com?subject=[SogetiHub] Solicitud de creación de proyecto">elliot.ribas-garcia@sogeti.com</a>:</p>
    <p>
      <ul>
        <li>Tu <b>puesto</b> de trabajo/<b>perfil</b> (puedes editarlo desde "Cuenta" dentro de la plataforma)</li>
        <li><b>Nombre</b> del proyecto</li>
        <li><b>Descripción</b> breve del proyecto</li>
        <li><b>Tipo</b> de proyecto:</li>
          <ul>
            <li>Responsabilidad Económica/Temporal/Completa (cerrado/ajustado)</li>
            <li>Capacidad (basado en bolsa de horas, jornadas, etc.)</li>
            <li>Time &amp; Materials (proporciona los correos de los miembros del equipo ejecutivo asignados)</li>
            <li>CSP (autogestión de suscripciones y licencias de productos de Cloud Microsoft)</li>
            <li>QBR (centrado en las Quarterly Business Reviews)</li>
          </ul>
        <li><b>Cliente</b> ("Sogeti" para proyectos internos o nombre del cliente)</li>
        <li><b>Miembros</b> del equipo del proyecto:</li>
          <ul>
            <li>Correo electrónico</li>
            <li>Rol/perfil en el proyecto</li>
            <li>Nombre del cliente</li>
          </ul>
        <li>Fecha de <b>inicio</b></li>
        <li>Fecha de <b>cierre</b> (opcional)</li>
        <li>Imagen de <b>icono</b> (cuadrada, opcional)</li>
        <li>Imagen de <b>cabecera</b> (rectangular, opcional)</li>
      </ul>                                  
      <style>
        li {
          line-height: 1.5;
          word-break: break-word;
          font-size: 14px;
          mso-line-height-alt: 21px;
        }
      </style>
    </p>
    <p>&nbsp;</p>
    <p>Por ahora, descubre las <b>funcionalidades de SogetiHub</b> en este breve vídeo:</p>
    <p>&nbsp;</p>
    <p><a href="https://sogetisaportalhubp.blob.core.windows.net/sogetihub/SogetiHub%20Onboarding.mp4"><img style="width: 100%" src="https://sogetisaportalhubp.blob.core.windows.net/sogetihub/SogetiHub%20Onboarding.png"></img></a></p>
    <p>&nbsp;</p>
    <p>SogetiHub es una plataforma desarrollada por SogetiLabs España, <b>en estado beta</b>. Para cualquier duda, incidencia o mejora, puedes enviarla desde <b>Requests</b> dentro de tu proyecto en la plataforma.</p>
  `;
}

export default {
  build
};
