import Category from "./CategoryCore";

export interface IService {
  id?: number;
  name: string;
  category?: Category;
  categoryId: number;
  clients?: any[];
  extensions?: any[];
  projects?: any[];
}

export default class Service {
  private id?: number;
  private name: string;
  private category?: Category;
  private categoryId: number;
  private clients?: any[];
  private extensions?: any[];
  private projects?: any[];

  constructor(dto: IService) {
    Object.assign(this, dto);
  }

  get getId(): number {
    return this.id;
  }

  set setId(id: number) {
    this.id = id;
  }

  get getName(): string {
    return this.name;
  }

  set setName(name: string) {
    this.name = name;
  }

  get getCategory(): Category {
    return this.category;
  }

  get getCategoryId(): number {
    return this.categoryId;
  }

  set setCategoryId(categoryId: number) {
    this.categoryId = categoryId;
  }

  get getClients(): any[] {
    return this.clients;
  }

  get getExtensions(): any[] {
    return this.extensions;
  }

  get getProjects(): any[] {
    return this.projects;
  }

  isDeliveryExperience(): boolean {
    return this.id == 1;
  }
}
