






































































































































































































































  import {
    eventStates,
    eventTypes,
    getEventTypesByProjectType,
    getRequestTypesByProjectType,
    getTimelineItemIcon
  } from "../../../_helpers/eventTypesStates";
  import { projectTypes } from "../../../_helpers/projectMetadata";
  import {
    getExtensionIcon,
    servicesExtensions
  } from "../../../_helpers/servicesExtensions";
  import RequestForm from "../../../components/services/deliveryexperience/request/RequestForm.vue";
  import ProjectCore from "../../../models/core/ProjectCore";
  import { IBaseLineDx } from "../../../models/deliveryexperience/BaseLineDx";
  import EventDx from "../../../models/deliveryexperience/EventDx";
  import ReadWrite from "../../../models/deliveryexperience/Permissions/ReadWrite";
  import RequestDx from "../../../models/deliveryexperience/RequestDx";
  import StageDx from "../../../models/deliveryexperience/StageDx";
  import SingleSelectType from "../../../view-models/SingleSelectType";
  import CapsuleMultiselect from "../../view_elements/CapsuleMultiselect.vue";
  import FilterDropdown from "../../view_elements/FilterDropdown.vue";
  import PeoplePermissions from "./people/PeoplePermissions.vue";
  import TimelineCarousel from "./timeline/TimelineCarousel.vue";
  import TimelineChart from "./timeline/TimelineChart.vue";
  import TimelineItemForm from "./timeline/TimelineItemForm.vue";

  export default {
    name: "Timeline",
    components: {
      TimelineChart,
      TimelineCarousel,
      TimelineItemForm,
      RequestForm,
      PeoplePermissions,
      CapsuleMultiselect,
      FilterDropdown
    },
    props: {
      comeFrom: String,
      projectId: Number,
      projectCore: ProjectCore,
      eventsDx: Array<EventDx>,
      stagesDx: Array<StageDx>,
      requestsDx: Array<RequestDx>,
      selectedVersionDxId: Number
    },
    data() {
      return {
        currentDate: new Date(),
        showChart: true,
        monthsNum: 3,
        showStages: true,
        showToday: false,
        filterItems: [],
        filterTags: [],
        timelineItemIdAndType: { id: undefined, type: undefined },
        timelineItemFormId: "dx-timeline-item-form",
        requestFormId: "dx-request-form-from-timeline",
        timelineItemFormAction: "",
        timelineItemFormType: "",
        timelineItemFormItemId: undefined,
        lastProjectVersionDxId: undefined as Number,
        showTooltip: false,
        filter: {
          events: true,
          stages: true,
          lifetime: true
        },
        monthOptions: [],
        months: undefined,
        text: {
          monthsView1: this.$tc("monthsView", 1, { months: "1" }),
          monthsView3: this.$tc("monthsView", 2, { months: "3" }),
          monthsView6: this.$tc("monthsView", 2, { months: "6" }),
          monthsView9: this.$tc("monthsView", 2, { months: "9" }),
          today: this.$t("today"),
          stage: this.$t("stages"),
          event: this.$t("events"),
          requests: this.$t("requests"),
          create: this.$t("create"),
          edit: this.$t("editRemove"),
          lifetime: this.$t("lifetime"),
          tags: this.$tc("tag", 2),
          filterTags: this.$t("filterTags"),
          all: this.$tc("all", 2),
          clear: this.$t("clear")
        },
        liabilityTemporaryString: projectTypes.liabilityTemporary.name,
        liabilityFullString: projectTypes.liabilityFull.name
      };
    },
    async created() {
      this.lastProjectVersionDxId = await this.fetchLastProjectVersionDx(
        this.projectId
      );
      await this.setPendingToConfirmEventsStatus();
      await this.setPendingToConfirmStagesStatus();

      this.$root.$on("editTimelineItemForm", timelineItemFormData => {
        this.timelineItemFormType = timelineItemFormData.type;
        this.timelineItemFormAction = timelineItemFormData.action;
        this.timelineItemFormItemId = timelineItemFormData.itemId;
        this.$bvModal.show(
          this.timelineItemFormType === "request"
            ? this.requestFormId
            : this.timelineItemFormId
        );
      });

      // Calls from outer components to redirect to timeline and open an item
      this.$root.$on("goToTimelineItem", (id, type) =>
        this.goToTimelineItem(id, type)
      );

      const monthsView = ["1", "3", "6", "9"];
      for (const months of monthsView) {
        this.monthOptions.push(
          new SingleSelectType({
            key: months,
            name: this.text[`monthsView${months}`]
          })
        );
      }
      this.months = this.monthOptions[1];
    },
    computed: {
      showLifetime(): boolean {
        return true;
      },
      filteredProjectEventsDx(): EventDx[] {
        return this.eventsDx.filter(event => {
          if (this.filterItems.length) {
            return this.filterItems.includes(event.getEventType);
          } else return true;
        });
      },
      filteredProjectRequestsDx(): RequestDx[] {
        return this.requestsDx.filter(request => {
          if (this.filterItems.length) {
            return this.filterItems.includes(request.getType);
          } else return true;
        });
      },
      permissionsEvents(): ReadWrite {
        return this.getUserPermissions.events;
      },
      permissionsStages(): ReadWrite {
        return this.getUserPermissions.stages;
      },
      getExtensionIcon(): NodeRequire {
        return getExtensionIcon(
          servicesExtensions.deliveryExperience.extensions.timeline.name
        );
      },
      filterDropdownEvents(): any {
        return this.getEventTypesByProjectType(
          this.projectCore.getProjectType
        ).map(eventType => {
          return {
            value: eventType.type,
            html: this.getFilterDropdownHtmlContent(
              eventType.type,
              eventType.name,
              this.eventsDx.filter(e => e.getEventType == eventType.type).length
            )
          };
        });
      },
      filterDropdownRequests(): any {
        return this.getRequestTypesByProjectType(
          this.projectCore.getProjectType
        ).map(requestType => {
          return {
            value: requestType.type,
            html: this.getFilterDropdownHtmlContent(
              requestType.type,
              this.$tc(requestType.i18n, 1),
              this.requestsDx.filter(r => r.getType == requestType.type).length
            )
          };
        });
      },
      filterDropdownItems(): any {
        return this.filterDropdownEvents.concat(this.filterDropdownRequests);
      }
    },
    methods: {
      resetActionAndTypeValues(): void {
        this.timelineItemFormAction = undefined;
        this.timelineItemFormType = undefined;
        this.timelineItemFormItemId = undefined; // Reset it after an item may have been created
      },
      openTimelineItemForm: function (action: string, type: string) {
        this.timelineItemFormItemId = undefined; // Reset it after an item may have been created
        this.chargeTypePage(action, type);
      },
      chargeTypePage: function (action: string, type: string) {
        this.timelineItemFormAction = action;
        this.timelineItemFormType = type;
        this.$bvModal.show(this.timelineItemFormId);
      },
      changeMonthsView: function (months: Number) {
        this.monthsNum = months;
      },
      rerenderOverview(): void {
        this.$emit("rerenderOverview");
      },
      showFilterButton: function (filterType): boolean {
        return (
          filterType !== "lifetime" ||
          ((this.projectCore.getProjectType ===
            projectTypes.liabilityTemporary.name ||
            this.projectCore.getProjectType ===
              projectTypes.liabilityFull.name) &&
            this.showChart)
        );
      },
      showHideStages() {
        this.showStages = !this.showStages;
      },
      evalTextVariable(key): string {
        switch (key) {
          case "lifetime":
            return this.text.lifetime;
          case "events":
            return this.text.event + " / " + this.text.requests;
          default:
            return this.text.stage;
        }
      },
      getGroupItems(): string[] {
        let items = [];
        if (this.permissionsEvents.write) {
          items.push("event");
        }
        if (this.permissionsStages.write) {
          items.push("stage");
        }
        return items;
      },
      async loadPermissionsManagement() {
        this.$bvModal.show("permissions-management-modal-timeline");
      },
      getEventTypesByProjectType(projectType: string): any[] {
        return Object.values(getEventTypesByProjectType(projectType));
      },
      getRequestTypesByProjectType(projectType: string): {
        type: string;
        i18n: string;
        icon: string;
      }[] {
        return getRequestTypesByProjectType(projectType);
      },
      getFilterDropdownHtmlContent(type, text, count): string {
        return `
          <div class="d-flex justify-content-between">
            <div class="text-truncate">
              <img width="20" src='${getTimelineItemIcon(type)}'></img>
              <span class="ml-1">${text}</span>
            </div>
            <span class="ml-1">${count}</span>
          </div>
        `;
      },
      switchView(): void {
        this.showChart = !this.showChart;

        if (this.timelineItemIdAndType.id != undefined) {
          this.$nextTick(() =>
            this.goToTimelineItem(
              this.timelineItemIdAndType.id,
              this.timelineItemIdAndType.type
            )
          );
        }
      },
      filterByTags(selectedTags: []): void {
        this.filterTags = selectedTags;
      },
      filterByItems(selectedItems: []): void {
        this.filterItems = selectedItems;
      },
      scrollToToday() {
        if (this.showChart) this.$refs.timelineChart.showToday();
        else this.$refs.timelineCarousel.showToday();
      },
      goToTimelineItem(id: string, type: string): void {
        if (this.showChart) this.$refs.timelineChart.openTimelineItem(id, type);
        else this.$refs.timelineCarousel.selectItem(id, type);
      },
      async setPendingToConfirmEventsStatus(): Promise<void> {
        let eventId: string = undefined;
        let baseline: IBaseLineDx = undefined;
        let eventStartDate: Date;
        for (const i in this.eventsDx) {
          //Event baseline
          eventId = this.eventsDx[i].getId;
          baseline = this.eventsDx[i].getLastHistoryBaseline;
          baseline.endDate = null;
          eventStartDate = new Date(baseline.startedDate);
          if (this.currentDate.getTime() > eventStartDate.getTime()) {
            if (baseline.status == eventStates.pendingToOccur.name) {
              if (
                [
                  eventTypes[projectTypes.capacity.name].events.reload.type,
                  eventTypes[projectTypes.capacity.name].events.billing.type,
                  eventTypes[projectTypes.capacity.name].events.consume.type,
                  eventTypes.PEOPLE.events.unassignment.type,
                  eventTypes.PEOPLE.events.newIncorporation.type
                ].includes(this.eventsDx[i].getEventType)
              ) {
                baseline.status = eventStates.occurred.name;
              } else {
                baseline.status = eventStates.pendingToConfirm.name;
              }
              if (baseline.affecteds.length > 0) {
                await this.updateEventDxRelationWithAffected(
                  eventId,
                  baseline,
                  baseline.affecteds[0]
                );
              } else if (baseline.predecessors.length > 0) {
                await this.updateEventDxRelationWithPredecessor(
                  eventId,
                  baseline,
                  baseline.predecessors[0]
                );
              } else {
                await this.updateEventWithoutRelation(eventId, baseline);
              }
            }
          }
        }
      },
      async updateEventWithoutRelation(
        eventId: string,
        baseline: IBaseLineDx
      ): Promise<void> {
        let eventBaseline: IBaseLineDx = {
          version: this.lastProjectVersionDxId,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: null,
          affecteds: [],
          predecessors: []
        };
        try {
          if (baseline.version == this.lastProjectVersionDxId) {
            await this.putEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          } else {
            await this.postEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async updateEventDxRelationWithAffected(
        eventToModifyId: string,
        eventToModifyBaseline: IBaseLineDx,
        affectedId: string
      ): Promise<void> {
        let affectedEvent: EventDx = this.getEventDx(affectedId);
        let affectedVersionHasChanged: boolean = false;
        //First update event and its Affected baselines to last Version:
        //Add new baseline to history in both Predecessor and Afected if they don't have it
        if (eventToModifyBaseline.version != this.lastProjectVersionDxId) {
          await this.modifyRelationEventsDxWithAffected(
            eventToModifyId,
            eventToModifyBaseline,
            null
          );
          eventToModifyBaseline.version = this.lastProjectVersionDxId;
        }
        if (
          affectedEvent.getLastHistoryBaseline.version !=
          this.lastProjectVersionDxId
        ) {
          await this.modifyRelationEventsDxWithPredecessor(
            affectedEvent.getId,
            affectedEvent.getLastHistoryBaseline,
            null
          );
          affectedEvent.getLastHistoryBaseline.version =
            this.lastProjectVersionDxId;
          affectedVersionHasChanged = true;
        }
        //Add relation with affected
        await this.modifyRelationEventsDxWithAffected(
          eventToModifyId,
          eventToModifyBaseline,
          affectedEvent.getId
        );
        //Put new affected event baseline
        if (affectedVersionHasChanged) {
          await this.modifyRelationEventsDxWithPredecessor(
            affectedEvent.getId,
            affectedEvent.getLastHistoryBaseline,
            eventToModifyId
          );
        }
      },
      async updateEventDxRelationWithPredecessor(
        eventToModifyId: string,
        eventToModifyBaseline: IBaseLineDx,
        predecessorId: string
      ): Promise<void> {
        let predecessorEvent: EventDx = this.getEventDx(predecessorId);
        let predecessorVersionHasChanged: boolean = false;
        //First update event and its Predecessor baselines to last Version:
        //Add new baseline to history in both Predecessor and Afected if they don't have it
        if (eventToModifyBaseline.version != this.lastProjectVersionDxId) {
          await this.modifyRelationEventsDxWithPredecessor(
            eventToModifyId,
            eventToModifyBaseline,
            null
          );
          eventToModifyBaseline.version = this.lastProjectVersionDxId;
        }
        if (
          predecessorEvent.getLastHistoryBaseline.version !=
          this.lastProjectVersionDxId
        ) {
          await this.modifyRelationEventsDxWithAffected(
            predecessorEvent.getId,
            predecessorEvent.getLastHistoryBaseline,
            null
          );
          predecessorEvent.getLastHistoryBaseline.version =
            this.lastProjectVersionDxId;
          predecessorVersionHasChanged = true;
        }
        //Add relation with predecessor
        await this.modifyRelationEventsDxWithPredecessor(
          eventToModifyId,
          eventToModifyBaseline,
          predecessorEvent.getId
        );
        //Put new epredecessor event baseline
        if (predecessorVersionHasChanged) {
          await this.modifyRelationEventsDxWithAffected(
            predecessorEvent.getId,
            predecessorEvent.getLastHistoryBaseline,
            eventToModifyId
          );
        }
      },
      async setPendingToConfirmStagesStatus(): Promise<void> {
        let stageId: string = undefined;
        let baseline: IBaseLineDx = undefined;
        let stageEndDate: Date;
        for (const i in this.stagesDx) {
          //Stage baseline
          stageId = this.stagesDx[i].getId;
          baseline = this.stagesDx[i].getLastHistoryBaseline;
          stageEndDate = new Date(baseline.endDate);
          if (this.currentDate.getTime() > stageEndDate.getTime()) {
            if (baseline.status == eventStates.pendingToOccur.name) {
              baseline.status = eventStates.pendingToConfirm.name;
              if (baseline.affecteds.length > 0) {
                await this.updateStageDxRelationWithAffected(
                  stageId,
                  baseline,
                  baseline.affecteds[0]
                );
              } else if (baseline.predecessors.length > 0) {
                await this.updateStageDxRelationWithPredecessor(
                  stageId,
                  baseline,
                  baseline.predecessors[0]
                );
              } else {
                await this.updateStageWithoutRelation(stageId, baseline);
              }
            }
          }
        }
      },
      async updateStageWithoutRelation(
        stageId: string,
        baseline: IBaseLineDx
      ): Promise<void> {
        const stageBaseline: IBaseLineDx = {
          version: this.lastProjectVersionDxId,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: baseline.endDate,
          affecteds: [],
          predecessors: []
        };
        try {
          if (baseline.version == this.lastProjectVersionDxId) {
            await this.putStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          } else {
            await this.postStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async updateStageDxRelationWithAffected(
        stageToModifyId: string,
        stageToModifyBaseline: IBaseLineDx,
        affectedId: string
      ): Promise<void> {
        let affectedStage: StageDx = this.getStageDx(affectedId);
        let affectedVersionHasChanged: boolean = false;
        //First update event and its Affected baselines to last Version:
        //Add new baseline to history in both Predecessor and Afected if they don't have it
        if (stageToModifyBaseline.version != this.lastProjectVersionDxId) {
          await this.modifyRelationStagesDxWithAffected(
            stageToModifyId,
            stageToModifyBaseline,
            null
          );
          stageToModifyBaseline.version = this.lastProjectVersionDxId;
        }
        if (
          affectedStage.getLastHistoryBaseline.version !=
          this.lastProjectVersionDxId
        ) {
          await this.modifyRelationStagesDxWithPredecessor(
            affectedStage.getId,
            affectedStage.getLastHistoryBaseline,
            null
          );
          affectedStage.getLastHistoryBaseline.version =
            this.lastProjectVersionDxId;
          affectedVersionHasChanged = true;
        }
        //Add relation with affected
        await this.modifyRelationStagesDxWithAffected(
          stageToModifyId,
          stageToModifyBaseline,
          affectedStage.getId
        );
        //Put new affected event baseline
        if (affectedVersionHasChanged) {
          await this.modifyRelationStagesDxWithPredecessor(
            affectedStage.getId,
            affectedStage.getLastHistoryBaseline,
            stageToModifyId
          );
        }
      },
      async updateStageDxRelationWithPredecessor(
        stageToModifyId: string,
        stageToModifyBaseline: IBaseLineDx,
        predecessorId: string
      ): Promise<void> {
        let predecessorStage: StageDx = this.getStageDx(predecessorId);
        let predecessorVersionHasChanged: boolean = false;
        //First update event and its Predecessor baselines to last Version:
        //Add new baseline to history in both Predecessor and Afected if they don't have it
        if (stageToModifyBaseline.version != this.lastProjectVersionDxId) {
          await this.modifyRelationStagesDxWithPredecessor(
            stageToModifyId,
            stageToModifyBaseline,
            null
          );
          stageToModifyBaseline.version = this.lastProjectVersionDxId;
        }
        if (
          predecessorStage.getLastHistoryBaseline.version !=
          this.lastProjectVersionDxId
        ) {
          await this.modifyRelationStagesDxWithAffected(
            predecessorStage.getId,
            predecessorStage.getLastHistoryBaseline,
            null
          );
          predecessorStage.getLastHistoryBaseline.version =
            this.lastProjectVersionDxId;
          predecessorVersionHasChanged = true;
        }
        //Add relation with predecessor
        await this.modifyRelationStagesDxWithPredecessor(
          stageToModifyId,
          stageToModifyBaseline,
          predecessorStage.getId
        );
        //Put new epredecessor event baseline
        if (predecessorVersionHasChanged) {
          await this.modifyRelationStagesDxWithAffected(
            predecessorStage.getId,
            predecessorStage.getLastHistoryBaseline,
            stageToModifyId
          );
        }
      },
      async modifyRelationEventsDxWithPredecessor(
        eventId: string,
        baseline: IBaseLineDx,
        predecessorId: string
      ): Promise<void> {
        let eventBaseline: IBaseLineDx = {
          version: this.lastProjectVersionDxId,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: null,
          affecteds: baseline.affecteds,
          predecessors: []
        };
        if (predecessorId) {
          eventBaseline.predecessors.push(predecessorId);
        }
        try {
          if (baseline.version == this.lastProjectVersionDxId) {
            await this.putEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          } else {
            await this.postEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async modifyRelationEventsDxWithAffected(
        eventId: string,
        baseline: IBaseLineDx,
        affectedId: string
      ): Promise<void> {
        let eventBaseline: IBaseLineDx = {
          version: this.lastProjectVersionDxId,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: null,
          affecteds: [],
          predecessors: baseline.predecessors
        };
        if (affectedId) {
          eventBaseline.affecteds.push(affectedId);
        }
        try {
          if (baseline.version == this.lastProjectVersionDxId) {
            await this.putEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          } else {
            await this.postEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async modifyRelationStagesDxWithPredecessor(
        stageId: string,
        baseline: IBaseLineDx,
        predecessorId: string
      ): Promise<void> {
        let stageBaseline: IBaseLineDx = {
          version: this.lastProjectVersionDxId,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: baseline.endDate,
          affecteds: baseline.affecteds,
          predecessors: []
        };
        if (predecessorId) {
          stageBaseline.predecessors.push(predecessorId);
        }
        try {
          if (baseline.version == this.lastProjectVersionDxId) {
            await this.putStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          } else {
            await this.postStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async modifyRelationStagesDxWithAffected(
        stageId: string,
        baseline: IBaseLineDx,
        affectedId: string
      ): Promise<void> {
        let stageBaseline: IBaseLineDx = {
          version: this.lastProjectVersionDxId,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: baseline.endDate,
          affecteds: [],
          predecessors: baseline.predecessors
        };
        if (affectedId) {
          stageBaseline.affecteds.push(affectedId);
        }
        try {
          if (baseline.version == this.lastProjectVersionDxId) {
            await this.putStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          } else {
            await this.postStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      }
    },
    watch: {
      showTooltip() {
        this.$root.$emit("openTooltips", this.showTooltip, { root: true });
      },
      months() {
        this.changeMonthsView(parseInt(this.months.key));
      }
    }
  };
