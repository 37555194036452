export interface IClient {
  id?: number;
  name: string;
  tenant?: string;
}

export default class Client {
  private id?: number;
  private name: string;
  private tenant?: string;

  constructor(dto: IClient) {
    Object.assign(this, dto);
  }

  get getId(): number {
    return this.id;
  }

  set setId(id: number) {
    this.id = id;
  }

  get getName(): string {
    return this.name;
  }

  set setName(name: string) {
    this.name = name;
  }

  get getTenant(): string {
    return this.tenant;
  }

  set setTenant(tenant: string) {
    this.tenant = tenant;
  }
}
