import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import PendingOperationCSP from "../../../models/csp/PendingOperationCSP";
import pendingOperationService from "../../../services/csp/pendingOperationService";
import { RootState } from "../../index";

const namespaced = true;

interface PendingOperationState {
  pendingOperations: PendingOperationCSP[];
}

const state: PendingOperationState = {
  pendingOperations: []
};

const mutations: MutationTree<PendingOperationState> = {
  setPendingOperations(state, pendingOperations: PendingOperationCSP[]) {
    state.pendingOperations = pendingOperations;
  },
  addPendingOperation(state, newPendingOperation: PendingOperationCSP) {
    const index = state.pendingOperations.findIndex(
      p => p.getSubscriptionId == newPendingOperation.getSubscriptionId
    );
    if (index >= 0) state.pendingOperations[index] = newPendingOperation;
    else state.pendingOperations.push(newPendingOperation);
  },
  updatePendingOperation(state, pendingOperation: PendingOperationCSP) {
    const index = state.pendingOperations.findIndex(
      p => p.getSubscriptionId === pendingOperation.getSubscriptionId
    );
    if (index != -1) {
      state.pendingOperations.splice(index, 1, pendingOperation);
    }
  },
  deletePendingOperation(state, subscriptionId: string): void {
    const index = state.pendingOperations.findIndex(
      op => op.getSubscriptionId == subscriptionId
    );
    state.pendingOperations.splice(index, 1);
  }
};

const getters: GetterTree<PendingOperationState, RootState> = {
  getPendingOperationsCSPByTenantId:
    state =>
    (tenantId: string): PendingOperationCSP[] => {
      return state.pendingOperations.filter(
        operation => operation.getTenantId == tenantId
      );
    },
  getPendingOperationCSPBySubscriptionId:
    state =>
    (subscriptionId: string): PendingOperationCSP => {
      return state.pendingOperations.find(
        pendingOperation => pendingOperation.getSubscriptionId == subscriptionId
      );
    }
};

const actions: ActionTree<PendingOperationState, RootState> = {
  async loadPendingOperationsCSP({ commit }) {
    const pendingOperations: PendingOperationCSP[] =
      await pendingOperationService.methods.getPendingOperations();
    commit("setPendingOperations", pendingOperations);
  },
  async loadPendingOperationCSPBySubscriptionId(
    { commit },
    subscriptionId: string
  ) {
    const pendingOperation: PendingOperationCSP =
      await pendingOperationService.methods.getPendingOperationBySubscriptionId(
        subscriptionId
      );
    if (pendingOperation != null)
      commit("addPendingOperation", pendingOperation);
  },
  async postPendingOperationCSP(
    { commit },
    pendingOperation: PendingOperationCSP
  ) {
    const newPendingOperation: PendingOperationCSP =
      await pendingOperationService.methods.postPendingOperation(
        pendingOperation
      );
    commit("addPendingOperation", newPendingOperation);
  },
  async putPendingOperationCSP(
    { commit },
    pendingOperation: PendingOperationCSP
  ) {
    const updatePendingOperation: PendingOperationCSP =
      await pendingOperationService.methods.putPendingOperation(
        pendingOperation
      );

    /**
     * Pending operation may have been deleted from DB
     * if all operations were completed (null), so we only
     * update it if exist, otherwise we delete it from state.
     * */
    if (updatePendingOperation)
      commit("updatePendingOperation", updatePendingOperation);
    else commit("deletePendingOperation", pendingOperation.getSubscriptionId);
  }
};

export const pendingOperationModuleCSP: Module<
  PendingOperationState,
  RootState
> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
