



















































































































































































































































































































































  import docTypes from "../../../../_helpers/docTypes";
  import emailBuilder from "../../../../_helpers/email/emailBuilder";
  import requestEmailBody from "../../../../_helpers/email/requestEmailBody";
  import {
    eventTypes,
    getTimelineItemIcon
  } from "../../../../_helpers/eventTypesStates";
  import notifications from "../../../../_helpers/notifications";
  import { requestStates } from "../../../../_helpers/requestStates";
  import { servicesExtensions } from "../../../../_helpers/servicesExtensions";
  import config from "../../../../config";
  import MailCore from "../../../../models/core/MailCore";
  import PeopleCore from "../../../../models/core/PeopleCore";
  import CommentDx from "../../../../models/deliveryexperience/CommentDx";
  import EventDx from "../../../../models/deliveryexperience/EventDx";
  import NotificationDx from "../../../../models/deliveryexperience/NotificationDx";
  import RequestDx from "../../../../models/deliveryexperience/RequestDx";
  import VersionDx from "../../../../models/deliveryexperience/VersionDx";
  import mailService from "../../../../services/core/mailService";
  import documentService from "../../../../services/deliveryexperience/documentService";
  import Singleton from "../../../../utils/singleton";
  import PeopleIcon from "../../../../view-models/PeopleIcon";
  import PeopleInfo from "../../../view_elements/PeopleInfo.vue";
  import PreviewDocumentModal from "../../../view_elements/PreviewDocumentModal.vue";
  import Thread from "../../../view_elements/Thread.vue";

  export default {
    name: "RequestRow",
    components: {
      Thread,
      PeopleInfo,
      PreviewDocumentModal
    },
    props: {
      id: String,
      requestDx: RequestDx,
      index: Number,
      initialShow: Boolean
    },
    data() {
      return {
        text: {
          requestStatusOpen: this.$t(requestStates.open.i18n),
          requestStatusResolved: this.$t(requestStates.resolved.i18n),
          requestStatusClosed: this.$t(requestStates.closed.i18n),
          description: this.$t("description"),
          downloadAll: this.$t("downloadAll"),
          download: this.$tc("download", 2),
          documents: this.$tc("document", 2)
        },
        downloading: undefined,
        showCollapse: Boolean(false),
        commentsCollapse: Boolean(false),
        requestRowHovered: Boolean(false),
        requestStatusHovered: Boolean(false),
        requestStates: requestStates,
        permissionsRequests: {},
        permissionsEvents: {},
        permissionsDocs: {}
      };
    },
    mounted: function () {
      this.permissionsRequests = this.getUserPermissions.requests;
      this.permissionsEvents = this.getUserPermissions.events;
      this.permissionsDocs = this.getUserPermissions.docs;
      if (this.initialShow) {
        this.showCollapse = true;
      }
    },
    computed: {
      currentVersionDxId(): number {
        const lastVersion: VersionDx = this.getLastProjectVersionDx(
          this.$route.params.projectId
        );
        if (lastVersion) {
          return lastVersion.getVersionId;
        } else {
          return 1;
        }
      },
      requestAuthor(): PeopleCore {
        return this.getPeopleByUsername(this.requestDx.getAuthor);
      },
      requestAuthorIcon(): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(this.requestAuthor);
      },
      requestTypeParset(): string {
        switch (this.requestDx.getType) {
          case eventTypes.REQUEST.events.serviceRequest.type:
            return this.$tc("service", 1);
          case eventTypes.REQUEST.events.questionRequest.type:
            return this.$t("question");
          case eventTypes.REQUEST.events.virtualVisitRequest.type:
            return this.$t("virtualVisit");
          case eventTypes.REQUEST.events.cspRequest.type:
            return this.$tc("csp", 1);
          default:
            return this.request.getType;
        }
      },
      docsDxRequest(): string[] {
        return this.getDocumentsDxByRequestId(this.requestDx.getId);
      },
      eventsRelated(): EventDx[] {
        let eventsList = this.getEventsDxByRelated(this.requestDx.getId);
        eventsList.sort((a, b) => {
          const dateA = new Date(
            a.getBaselineByVersion(this.currentVersionDxId).startedDate
          ).getTime();
          const dateB = new Date(
            b.getBaselineByVersion(this.currentVersionDxId).startedDate
          ).getTime();

          return dateB - dateA;
        });
        return eventsList;
      }
    },
    methods: {
      async postMailCore(mail: MailCore): Promise<any> {
        return await mailService.methods.postMailCore(mail);
      },
      getDateParsed(date: Date): string {
        return this.$moment(date).format("L");
      },
      getDateParsedForRelatedEvents(date: Date): string {
        return (
          this.$moment(date).format("L") +
          (this.$moment(date).format("HH:mm") != "00:00"
            ? " - " + this.$moment(date).format("HH:mm")
            : "")
        );
      },
      goToTimelineEvent(event: EventDx) {
        this.$router.push(
          `/${servicesExtensions.deliveryExperience.url}/${this.$route.params.projectId}/event/${event.getId}`
        );
      },
      openRequestModal(requestId) {
        this.$emit("editRequest", requestId);
      },
      openTimelineItemForm: function () {
        this.$parent.createRelatedEvent(this.requestDx);
      },
      async download(el, index) {
        this.downloading = index;
        if (el.length) {
          for (const doc of el) {
            await this.downloadDocument(doc);
          }
        } else {
          await this.downloadDocument(el);
        }
        this.downloading = undefined;
      },
      async downloadDocument(doc) {
        const download = await documentService.methods.downloadDocumentFromBlob(
          doc.url
        );
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([download.blob], { type: download.type })
        );
        link.setAttribute("download", doc.name);
        document.body.appendChild(link);
        link.click();
      },
      async requestEditStatus(newStatus: string): Promise<any> {
        let editRequestDx: RequestDx = this.requestDx;
        if (editRequestDx.getStatus != newStatus) {
          editRequestDx.setStatus = newStatus;
          this.putRequestDx(editRequestDx);
          const recipients = this.mailRecipients(this.getUser.getUsername);
          recipients.forEach(user => {
            const lang = this.getPeopleByUsername(user).language;
            this.postMailStatus(newStatus, user, lang);
          });
          this.$emit("editStatus", this.index, newStatus);
        }
      },
      async postMailStatus(newStatus: string, user: string, lang: string) {
        const subject =
          this.$t(newStatus.toLowerCase() + "Request", lang) +
          this.requestDx.getIssue;

        const body = emailBuilder.buildEmail(
          this.$t("status", lang) + " " + this.$t("requestStatusUpdated", lang),
          "",
          requestEmailBody.build(
            this.$t("requestChangedStatus", lang, {
              issue: this.requestDx.getIssue,
              status: this.$tc(`requestStatus${newStatus}`, lang).toLowerCase()
            }),
            this.$t("requestDetails", lang),
            this.getMailDetailsStatus(lang),
            this.$route.fullPath,
            lang
          ),
          lang
        );

        await this.postMailCore(
          new MailCore({
            to: user,
            subject: subject,
            body: body
          })
        );
      },
      getMailDetailsStatus(lang: string) {
        return {
          author: {
            name: this.$t("author", lang),
            value: this.getUser.getNaturalFullName()
          },
          date: {
            name: this.$t("date", lang),
            value: this.$moment().format("L LTS")
          }
        };
      },
      async updateRequestThread(newComment: CommentDx): Promise<any> {
        const modifiedRequest = this.addCommentToThread(newComment);
        await this.putRequestDx(modifiedRequest);
        await this.sendNotificationDx(modifiedRequest.getId);
        const recipients = this.mailRecipients(newComment.getAuthor);
        recipients.forEach(user => {
          const lang = this.getPeopleByUsername(user).language;
          this.postMailThread(newComment, user, lang);
        });
      },
      async addIncomingComment(
        newComment: CommentDx,
        requestId: string
      ): Promise<any> {
        // Make sure that the received message belongs to this requestId
        if (this.requestDx.getId == requestId) {
          this.addCommentToThread(newComment);
        }
      },
      addCommentToThread(newComment: CommentDx) {
        let requestThread: CommentDx[] = [];
        if (this.requestDx.getThread != undefined) {
          requestThread = this.requestDx.getThread;
        }
        requestThread.push(newComment);
        const modifiedRequest: RequestDx = this.requestDx; // Copying it because prop is not mutable
        modifiedRequest.setThread = requestThread;
        return modifiedRequest;
      },
      async requestRegisterThreadConnection(): Promise<any> {
        await Singleton.get()
          .vue.$chatConnection.invoke(
            "RegisterConnection",
            this.requestDx.getId
          )
          .catch(err => Singleton.get().vue.$log.error(err));
      },
      async requestUnregisterThreadConnection(): Promise<any> {
        await Singleton.get()
          .vue.$chatConnection.invoke(
            "UnregisterConnection",
            this.requestDx.getId
          )
          .catch(err => Singleton.get().vue.$log.error(err));
      },
      async toggleThread() {
        this.$emit("toggleRequestThread", this.requestDx.getId);
        this.commentsCollapse = !this.commentsCollapse;
      },
      async postMailThread(newComment: CommentDx, user: string, lang: string) {
        const subject = this.$t("newCommentThreadInRequest", lang, {
          request: this.requestDx.getIssue
        });

        const body = emailBuilder.buildEmail(
          this.$tc("new", 2, lang) + " " + this.$tc("comment", 2, lang),
          "",
          requestEmailBody.build(
            this.$t("requestCommented", lang, {
              issue: this.requestDx.getIssue
            }),
            this.$t("commentDetails", lang),
            this.getMailDetailsThread(newComment),
            this.$route.fullPath,
            lang
          ),
          lang
        );

        await this.postMailCore(
          new MailCore({
            to: user,
            subject: subject,
            body: body
          })
        );
        if (
          config.prodEnv &&
          this.requestDx.getType == eventTypes.REQUEST.events.cspRequest.type
        ) {
          await this.postMailCore(
            new MailCore({ to: config.cspMail, subject: subject, body })
          );
        }
      },
      getMailDetailsThread(newComment: CommentDx, lang: string) {
        return {
          subject: {
            name: this.$tc("comment", 1, lang),
            value: newComment.getComment
          },
          author: {
            name: this.$t("author", lang),
            value: this.getPeopleByUsername(
              newComment.getAuthor
            ).getNaturalFullName()
          },
          date: {
            name: this.$t("date", lang),
            value: this.$moment(newComment.getDate).format("L LTS")
          }
        };
      },
      // Method that returns the recipients who has to receive
      // the mail of request comment added
      // Sended to request author and POC of the project
      mailRecipients(user): string {
        const recipients = this.getPOCsByProjectId(this.$route.params.projectId)
          .filter(
            p =>
              p.getUsername != user && p.getUsername != this.requestDx.getAuthor
          )
          .map(p => p.getUsername);
        if (user != this.requestDx.getAuthor) {
          recipients.push(this.requestDx.getAuthor);
        }
        return recipients;
      },
      getTimelineItemIcon(timelineItemType: string): NodeRequire {
        return getTimelineItemIcon(timelineItemType);
      },
      getFileIconByExtension: function (name): NodeRequire {
        return docTypes.getFileIconByExtension(name);
      },
      async sendNotificationDx(itemId) {
        const notificationDx = new NotificationDx({
          itemName: this.requestDx.getIssue,
          projectId: this.$route.params.projectId,
          itemType: this.requestDx.getType,
          itemId: itemId,
          recipients: notifications.getPeopleForRecipients(
            this.requestDx.getNotificationsPolicy,
            this.getPeopleDxByProjectId(this.$route.params.projectId),
            this.requestDx.getAuthor,
            this.getUser.getUsername,
            itemId
          ),
          action: "thread",
          author: this.getUser.getUsername
        });
        if (notificationDx.recipients.length)
          await this.postNotificationDx(notificationDx);
      },
      isDocPreviewable: function (docName): boolean {
        return docTypes.isDocPreviewable(docName);
      }
    }
  };
