






























































































































































  import ClientForm from "../../components/home/clients/ClientForm.vue";
  import ProjectFormServices from "../../components/new-project/ProjectFormServices.vue";
  import CapsuleMultiselect from "../../components/view_elements/CapsuleMultiselect.vue";
  import ConfirmationModal from "../../components/view_elements/ConfirmationModal.vue";
  import ClientCore from "../../models/core/ClientCore";
  import MarginCSP from "../../models/csp/MarginCSP";
  import SingleSelectType from "../../view-models/SingleSelectType";

  export default {
    name: "ClientsTab",
    components: {
      ClientForm,
      CapsuleMultiselect,
      ProjectFormServices,
      ConfirmationModal
    },
    data() {
      return {
        clientFormId: "client-form",
        CLIENT_NAME_MAX_LENGTH: 50,
        clientToEdit: null,
        editClient: false,
        parent: "clients",
        clientTenants: [],
        editMargins: false,
        text: {
          newClient: this.$t("newClient"),
          selectClient: this.$t("selectClient"),
          noClientSelected: this.$t("noClientSelected"),
          noTenantForClient: this.$t("noTenantForClient")
        },
        fieldsNames: [
          {
            label: "Id",
            key: "tenantId",
            thClass: "table-width-20",
            tdClass: "table-width-20"
          },
          {
            label: "Name",
            key: "tenantName",
            thClass: "table-width-20",
            tdClass: "table-width-20"
          },
          {
            label: "Margin Licences",
            key: "marginLicences",
            thClass: "table-width-5",
            tdClass: "table-width-5"
          },
          {
            label: "Margin Azure",
            key: "marginAzure",
            thClass: "table-width-5",
            tdClass: "table-width-5"
          },
          {
            label: "",
            key: "remove",
            thClass: "table-width-5",
            tdClass: "table-width-5"
          }
        ]
      };
    },
    async created() {
      await this.loadCustomersCSP();
      await this.loadMarginsCSP();
    },
    computed: {
      clientList(): Array<SingleSelectType> {
        return this.getClients.map(
          c =>
            new SingleSelectType({
              key: c.getId,
              name: c.getName
            })
        );
      },
      clientFormToEdit(): ClientCore | null {
        if (!this.clientToEdit) return null;
        else {
          return this.getClients.find(e => e.getName == this.clientToEdit.name);
        }
      }
    },
    methods: {
      openModal(editClient: boolean) {
        this.editClient = editClient;
        this.$bvModal.show(this.clientFormId);
      },
      rerenderMultiselect() {
        // Check if user creates, updates or deletes clientToEdit and rerender Multiselect
        this.clientToEdit = null;
      },
      loadMultiselect(clientName) {
        if (this.clientList != null) {
          if (this.clientList.find(e => e.name == clientName)) {
            this.clientToEdit = this.clientList.find(
              e => e.getName == clientName
            );
          }
        }
      },
      performDeleteClient(clientId) {
        this.deleteClient(clientId);
        this.rerenderMultiselect();
        this.$nextTick(async () => {
          await this.$bvModal.hide("modal-confirmation-delete-client");
        });
      },
      async removeTenant(id) {
        const index = this.clientTenants.findIndex(t => t.id == id);
        if (index !== -1) {
          this.clientTenants.splice(index, 1);
        }

        await this.putClient(
          new ClientCore({
            id: this.clientToEdit.key,
            name: this.getClients.find(e => e.getId == this.clientToEdit.key)
              .name,
            tenant: this.clientTenants.map(t => t.id).join(",") // Redo CSV format
          })
        );
      },
      saveAndClose() {
        if (this.editMargins) {
          for (const tenant of this.clientTenants) {
            const margin = this.getMarginCSPByCustomerId(
              e => e.customerId == tenant.id
            );
            const marginLicences = margin ? margin.marginLicences : 0;
            const marginAzure = margin ? margin.marginAzure : 0;
            const idMarginLicences = "tenant-margin-licences-" + tenant.id;
            const idMarginAzure = "tenant-margin-azure-" + tenant.id;
            const inputMarginLicences = Number.parseFloat(
              (<HTMLInputElement>document.getElementById(idMarginLicences))
                .value
            );
            const inputMarginAzure = Number.parseFloat(
              (<HTMLInputElement>document.getElementById(idMarginAzure)).value
            );
            if (
              this.getMarginCSPByCustomerId(tenant.id) &&
              (marginLicences != inputMarginLicences ||
                marginAzure != inputMarginAzure)
            ) {
              this.putMarginCSP(
                new MarginCSP({
                  customerId: tenant.id,
                  marginLicences: inputMarginLicences,
                  marginAzure: inputMarginAzure
                })
              );
            } else if (
              marginLicences != inputMarginLicences ||
              marginAzure != inputMarginAzure
            )
              this.postMarginCSP(
                new MarginCSP({
                  customerId: tenant.id,
                  marginLicences: inputMarginLicences,
                  marginAzure: inputMarginAzure
                })
              );
          }
        }
        this.editMargins = !this.editMargins;
      }
    },
    watch: {
      clientToEdit() {
        if (this.clientToEdit) {
          const tenantsCSV = this.getClients.find(
            e => e.getId == this.clientToEdit.key
          ).tenant;
          if (tenantsCSV) {
            const tenantsIds = tenantsCSV.split(",");
            let tenantsArray = [];
            for (const tenant of tenantsIds) {
              tenantsArray.push(this.getCustomersCSP.find(e => e.id == tenant));
            }
            this.clientTenants = tenantsArray;
          } else this.clientTenants = [];
        }
      }
    }
  };
