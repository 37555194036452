


















































































  import EventDx from "../../../models/deliveryexperience/EventDx";
  export default {
    name: "EventDateCalendar",
    props: {
      id: String,
      events: Array
    },
    data() {
      return {
        cameFromModal: this.id.includes("overview-modal"),
        currentMonthIndex: new Date().getMonth()
      };
    },
    mounted() {
      if (this.validateEvents) {
        this.$nextTick(() => this.setEventDaysInCalendar());
      }
    },
    computed: {
      validateEvents(): boolean {
        return this.events.length !== 0;
      }
    },
    methods: {
      fillSpanWithEvent(eventDayOfMonth, i) {
        document
          .getElementById(`${this.id}-month-${i}`)
          .querySelector("span").innerText = eventDayOfMonth.toString();
      },
      setEventDaysInCalendar(): void {
        Array.from(document.getElementsByTagName("td")).forEach((month, i) => {
          const currentIterationMonthEvents: EventDx[] = this.events.filter(
            ev => {
              const startedDate: Date = new Date(
                ev.getLastHistoryBaseline.startedDate
              );
              return (
                startedDate.getFullYear() === new Date().getFullYear() &&
                new Date(ev.getLastHistoryBaseline.startedDate).getMonth() == i
              );
            }
          );

          if (currentIterationMonthEvents.length) {
            const currentDayOfMonth = new Date().getTime();
            let eventDayOfMonth;
            document
              .getElementById(`${this.id}-month-${i}`)
              .classList.add("meeting-month");

            try {
              eventDayOfMonth = new Date(
                currentIterationMonthEvents.find(
                  ev =>
                    new Date(ev.getLastHistoryBaseline.startedDate).getTime() >=
                    currentDayOfMonth
                ).getLastHistoryBaseline.startedDate
              ).getDate();
              this.fillSpanWithEvent(eventDayOfMonth, i);
            } catch {
              eventDayOfMonth = new Date(
                currentIterationMonthEvents[
                  currentIterationMonthEvents.length - 1
                ].getLastHistoryBaseline.startedDate
              ).getDate();
              this.fillSpanWithEvent(eventDayOfMonth, i);
            }

            //Style of current months with events
            if (i === this.currentMonthIndex) {
              document
                .getElementById(`${this.id}-month-${i}`)
                .classList.add("current-month-meeting");
            }
          }
        });
      }
    }
  };
