var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%","height":"100%"}},[(_vm.label)?_c('label',{staticClass:"input-label",class:{
      disabled: _vm.disabled,
      grey: _vm.background == 'grey',
      white: _vm.background == 'white'
    }},[_vm._v(" "+_vm._s(_vm.label + (_vm.required.status ? " *" : ""))+" ")]):_vm._e(),_c('div',{staticClass:"div-multiselect",class:{
      disabled: _vm.disabled,
      active: _vm.activeStyle,
      required: _vm.required.style
    },on:{"focusin":function($event){_vm.activeStyle = true},"focusout":function($event){_vm.activeStyle = false}}},[(_vm.options.length && _vm.type == 'people')?_c('multiselect',{staticClass:"input-multiselect",attrs:{"options":_vm.options,"placeholder":_vm.placeholder,"allow-empty":!_vm.required.status,"max-height":_vm.maxHeight,"multiple":_vm.multiple,"close-on-select":!_vm.multiple,"custom-label":_vm.searchableName,"label":"getUsername","track-by":"getUsername","openDirection":"bottom","show-labels":false,"disabled":_vm.disabled,"type":_vm.type},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("listEmpty")))])])]},proxy:true},{key:"noResult",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("noElementsFound")))])]},proxy:true},{key:"option",fn:function(ref){
    var option = ref.option;
return [_c('PeopleInfo',{attrs:{"person":option,"bold":false}})]}},(_vm.multiple)?{key:"selection",fn:function(){return [(_vm.optionSelected != undefined && _vm.optionSelected.length)?_c('span',{staticClass:"multiselect-layout multiselect-single"},[_vm._v(" "+_vm._s(_vm.optionSelected.length)+" "+_vm._s(_vm.text.membersSelected)+" ")]):_vm._e()]},proxy:true}:null,(!_vm.multiple)?{key:"singleLabel",fn:function(ref){
    var option = ref.option;
return [_c('PeopleInfo',{attrs:{"person":option,"bold":false,"iconSize":25}})]}}:null],null,true),model:{value:(_vm.optionSelected),callback:function ($$v) {_vm.optionSelected=$$v},expression:"optionSelected"}}):(_vm.options.length && _vm.type == 'tenant')?_c('multiselect',{staticClass:"input-multiselect",attrs:{"options":_vm.options,"placeholder":_vm.placeholder,"allow-empty":!_vm.required.status,"max-height":_vm.maxHeight,"multiple":_vm.multiple,"close-on-select":!_vm.multiple,"custom-label":_vm.searchableTenant,"label":"name","track-by":"name","openDirection":"bottom","show-labels":false,"disabled":_vm.disabled,"type":_vm.type},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("listEmpty")))])])]},proxy:true},{key:"noResult",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("noElementsFound")))])]},proxy:true},{key:"option",fn:function(ref){
    var option = ref.option;
return [_c('b-row',[_c('b-col',{staticClass:"text-truncate"},[_c('div',{staticClass:"multiselect-layout multiselect-ctn"},[_vm._v(" "+_vm._s(option.name)+" "),_c('div',{staticClass:"multiselect-option"},[_vm._v(" "+_vm._s(option.id)+" ")])])])],1)]}},(!_vm.multiple)?{key:"singleLabel",fn:function(ref){
    var option = ref.option;
return [_c('b-row',[_c('b-col',[_c('div',{staticClass:"multiselect-layout multiselect-ctn"},[_vm._v(" "+_vm._s(option.name)+" ")])])],1)]}}:null,(_vm.multiple && _vm.optionSelected.length)?{key:"selection",fn:function(){return [(_vm.optionSelected != undefined && _vm.optionSelected.length)?_c('span',{staticClass:"multiselect-layout multiselect-single"},[_vm._v(" "+_vm._s(_vm.optionSelected.length)+" "+_vm._s(_vm.$tc("tenantSelected", _vm.optionSelected.length > 1 ? 2 : 1))+" ")]):_vm._e()]},proxy:true}:null],null,true),model:{value:(_vm.optionSelected),callback:function ($$v) {_vm.optionSelected=$$v},expression:"optionSelected"}}):(_vm.type == 'singleSelect')?_c('multiselect',{staticClass:"input-multiselect",attrs:{"options":_vm.options,"placeholder":_vm.placeholder,"allow-empty":!_vm.required.status,"tag-placeholder":_vm.tagPlaceholder,"max-height":_vm.maxHeight,"multiple":_vm.multiple,"taggable":_vm.taggable,"label":"name","track-by":"name","searchable":_vm.searchable,"openDirection":"bottom","show-labels":false,"type":_vm.type,"disabled":_vm.disabled},on:{"input":function($event){return _vm.$emit('input')},"tag":function($event){return _vm.$emit('tag', $event)}},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("listEmpty")))])])]},proxy:true},{key:"noResult",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("noElementsFound")))])]},proxy:true},{key:"singleLabel",fn:function(ref){
    var option = ref.option;
return [(option.key != 'undefined')?_c('div',{staticClass:"d-flex"},[(option.icon)?_c('b-img',{staticStyle:{"margin-right":"10px"},attrs:{"height":"25","src":require('../../assets/icons/' + option.icon),"alt":""}}):_vm._e(),_c('p',{staticClass:"label-input",attrs:{"title":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])],1):_vm._e()]}},{key:"option",fn:function(ref){
    var option = ref.option;
return [(option.key != 'undefined')?_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:(
            Object.keys(_vm.tooltips).length ? _vm.getTooltipConfig(option.key) : null
          ),expression:"\n            Object.keys(tooltips).length ? getTooltipConfig(option.key) : null\n          ",modifiers:{"hover":true}}],staticClass:"d-flex"},[(option.icon)?_c('b-img',{staticStyle:{"margin-right":"10px"},attrs:{"height":"25","src":require('../../assets/icons/' + option.icon),"alt":""}}):_vm._e(),_c('p',{staticClass:"label-props",attrs:{"title":option.name}},[_vm._v(" "+_vm._s(option.name)+" ")])],1):_vm._e(),_vm._v(" "+_vm._s(option.label)+" ")]}}]),model:{value:(_vm.optionSelected),callback:function ($$v) {_vm.optionSelected=$$v},expression:"optionSelected"}}):(_vm.options.length && _vm.type == 'colorPicker')?_c('multiselect',{staticClass:"input-multiselect",attrs:{"options":_vm.options,"placeholder":_vm.placeholder,"allow-empty":!_vm.required.status,"max-height":_vm.maxHeight,"multiple":false,"close-on-select":!_vm.multiple,"openDirection":"bottom","show-labels":false,"disabled":_vm.disabled,"type":_vm.type},on:{"input":function($event){return _vm.$emit('input')}},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("listEmpty")))])])]},proxy:true},{key:"noResult",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("noElementsFound")))])]},proxy:true},{key:"option",fn:function(ref){
          var option = ref.option;
return [_c('b-row',[_c('b-col',[_c('span',{staticClass:"colour-picker",class:[option.color]})])],1)]}},{key:"singleLabel",fn:function(ref){
          var option = ref.option;
return [_c('b-row',[_c('b-col',[_c('span',{staticClass:"colour-picker",class:[option.color]})])],1)]}}]),model:{value:(_vm.optionSelected),callback:function ($$v) {_vm.optionSelected=$$v},expression:"optionSelected"}}):_c('multiselect',{staticClass:"input-multiselect",attrs:{"options":_vm.options,"placeholder":_vm.placeholder,"allow-empty":!_vm.required.status,"max-height":_vm.maxHeight,"taggable":_vm.taggable,"tag-placeholder":_vm.tagPlaceholder,"multiple":_vm.multiple,"openDirection":"bottom","show-labels":false,"disabled":_vm.disabled,"type":_vm.type},on:{"input":function($event){return _vm.$emit('input')},"tag":function($event){return _vm.$emit('tag', $event)}},scopedSlots:_vm._u([{key:"option",fn:function(ref){
          var option = ref.option;
return [_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:(
            Object.keys(_vm.tooltips).length ? _vm.getTooltipConfig(option) : null
          ),expression:"\n            Object.keys(tooltips).length ? getTooltipConfig(option) : null\n          ",modifiers:{"hover":true}}]},[_vm._v(" "+_vm._s(option.label ? option.label : option)+" ")])]}},{key:"noOptions",fn:function(){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("listEmpty")))])])]},proxy:true},{key:"noResult",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.$t("noElementsFound")))])]},proxy:true}]),model:{value:(_vm.optionSelected),callback:function ($$v) {_vm.optionSelected=$$v},expression:"optionSelected"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }