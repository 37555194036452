export interface ICapacityType {
  id?: number;
  name: string;
  clientId: number;
}

export default class CapacityType {
  private id?: number;
  private name: string;
  private clientId: number;

  constructor(dto: ICapacityType) {
    Object.assign(this, dto);
  }

  get getId(): number {
    return this.id;
  }

  set setId(id: number) {
    this.id = id;
  }

  get getName(): string {
    return this.name;
  }

  set setName(name: string) {
    this.name = name;
  }

  get getClientId(): number {
    return this.clientId;
  }

  set setClientId(clientId: number) {
    this.clientId = clientId;
  }
}
