










































































































































































































  import { dragscroll } from "vue-dragscroll";
  import {
    getExtensionIcon,
    servicesExtensions
  } from "../../../_helpers/servicesExtensions";
  import { ILinkDx } from "../../../models/deliveryexperience/LinkDx";
  import ReadWrite from "../../../models/deliveryexperience/Permissions/ReadWrite";
  import ProjectDx from "../../../models/deliveryexperience/ProjectDx";

  export default {
    name: "Bookmarks",
    directives: {
      dragscroll
    },
    props: {
      projectDx: ProjectDx
    },
    data() {
      return {
        create: false,
        nAlias: "",
        nUrl: "",
        bookmarkSelected: {
          action: null,
          id: null
        }
      };
    },
    methods: {
      openLink(url) {
        const linkProtocol: string = url.split(":")[0];
        if (["http", "https"].includes(linkProtocol)) {
          window.open(url, "_blank");
        } else {
          window.open("//" + url, "_blank");
        }
      },
      createBookmarkState() {
        this.create = true;
        this.bookmarkSelected = { action: null, id: null };
        this.nAlias = null;
        this.nUrl = null;
        this.$nextTick(() => {
          let bmContainer = document.getElementById("bookmarks-section");
          bmContainer.scrollLeft =
            bmContainer.scrollWidth - bmContainer.clientWidth;
        });
      },
      newBookmark() {
        let project: ProjectDx = this.projectDx;
        const link: ILinkDx = {
          id: this.projectDx.getLinks.length,
          alias: this.nAlias,
          url: this.nUrl
        };
        project.addLink(link);
        this.putProjectDx(project);
        this.create = false;
      },
      cancelSave() {
        this.create = false;
      },
      selectBookmark(
        id: string,
        action: string,
        aliasToEdit?: string,
        urlToEdit?: string
      ) {
        this.create = false;
        this.bookmarkSelected = { action: action, id: id };
        if (action == "edit") {
          this.nAlias = aliasToEdit;
          this.nUrl = urlToEdit;
        }
      },
      actionBookmark(event, linkId) {
        if (this.bookmarkSelected.action == "edit") {
          this.saveBookmarkChanges(linkId);
        }
        if (this.bookmarkSelected.action == "delete") {
          this.deleteLink(linkId);
        }
        this.bookmarkSelected = { action: null, id: null };
        this.nAlias = null;
        this.nUrl = null;
        event.stopPropagation();
      },
      saveBookmarkChanges(linkId) {
        let projectToEditLink: ProjectDx = this.projectDx;
        const link: ILinkDx = {
          id: linkId,
          alias: this.nAlias,
          url: this.nUrl
        };
        projectToEditLink.editLink(link);
        this.putProjectDx(projectToEditLink);
      },
      deleteLink(linkId) {
        const projectToDeleteLink: ProjectDx = this.projectDx;
        projectToDeleteLink.setLinks = projectToDeleteLink.getLinks.filter(
          link => link.id != linkId
        );
        this.putProjectDx(projectToDeleteLink);
      }
    },
    computed: {
      permissionsBookmarks(): ReadWrite {
        return this.getUserPermissions.bookmarks;
      },
      getExtensionIcon(): NodeRequire {
        return getExtensionIcon(
          servicesExtensions.deliveryExperience.extensions.bookmarks.name
        );
      }
    }
  };
