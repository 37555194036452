export interface ISubscriptionData {
  id: string;
  offerName: string;
  friendlyName: string;
  termDuration: string;
  billingCycle: string;
  billingCycleEndDate: string;
  commitmentEndDate: string;
  autoRenewEnabled: boolean;
  quantity: number;
  refundableQuantity: number;
  status: string;
}

export default class SubscriptionData {
  private id: string;
  private offerName: string;
  private friendlyName: string;
  private termDuration: string;
  private billingCycle: string;
  private billingCycleEndDate: string;
  private commitmentEndDate: string;
  private autoRenewEnabled: boolean;
  private quantity: number;
  private refundableQuantity: number;
  private status: string;

  constructor(subscription: ISubscriptionData) {
    Object.assign(this, subscription);
  }
}
