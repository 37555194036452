import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import VersionDx from "../../../models/deliveryexperience/VersionDx";
import versionServiceDx from "../../../services/deliveryexperience/versionService";
import { RootState } from "../../index";

interface IVersionState {
  versionsDx: VersionDx[];
}

const namespaced = true;

const state: IVersionState = {
  versionsDx: []
};

const getters: GetterTree<IVersionState, RootState> = {
  getVersionsDxByProjectId:
    state =>
    (projectId: number): VersionDx[] => {
      return state.versionsDx.filter(
        version => version.getProjectId == projectId
      );
    },
  getLastProjectVersionDx:
    state =>
    (projectId: number): VersionDx => {
      const projectVersions: VersionDx[] = state.versionsDx.filter(
        version => version.getProjectId == projectId
      );
      return projectVersions.reduce((max, version) =>
        max.getVersionId > version.getVersionId ? max : version
      );
    }
};

const mutations: MutationTree<IVersionState> = {
  setVersionsDx(state, versions: VersionDx[]): void {
    state.versionsDx = versions;
  },
  addVersionDx(state, version: VersionDx): void {
    state.versionsDx.push(version);
  },
  addProjectVersionsDx(state, versions: VersionDx[]): void {
    const projectVersion: number = versions[0].getProjectId;
    state.versionsDx = [
      ...state.versionsDx.filter(
        element => element.getProjectId !== projectVersion
      )
    ].concat(versions);
  },
  deleteVersionDx(state, versionId: string): void {
    const index = state.versionsDx.findIndex(
      version => version.getId == versionId
    );
    state.versionsDx.splice(index, 1);
  }
};

const actions: ActionTree<IVersionState, RootState> = {
  async loadVersionsDx({ commit }): Promise<any> {
    const versions: VersionDx[] = await versionServiceDx.methods.getVersions();
    commit("setVersionsDx", versions);
  },
  async loadVersionsDxByProjectId({ commit }, projectId: number): Promise<any> {
    const versions: VersionDx[] =
      await versionServiceDx.methods.getVersionsByProject(projectId);
    commit("addProjectVersionsDx", versions);
  },
  async fetchLastProjectVersionDx({}, projectId: number): Promise<string> {
    return await versionServiceDx.methods.getLatestProjectVersion(projectId);
  },
  async postVersionDx({ commit }, projectId: number): Promise<any> {
    const version: VersionDx = await versionServiceDx.methods.postVersion(
      projectId
    );
    commit("addVersionDx", version);
  },
  async deleteVersionDxLocal({ commit }, versionId: string): Promise<any> {
    commit("deleteVersionDx", versionId);
  }
};

export const versionModuleDx: Module<IVersionState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
