
































































































































  /* eslint-disable */
  import * as d3 from "d3";
  import { eventStates, eventTypes } from "../../../_helpers/eventTypesStates";
  import { projectTypes } from "../../../_helpers/projectMetadata";
  import EventDx from "../../../models/deliveryexperience/EventDx";

  export default {
    name: "ProjectTypeCapacityKpi",
    props: {
      id: String,
      projectStartDate: String,
      events: Array,
      unitType: String,
      modalView: Number
    },
    data() {
      return {
        divId: this.id + "-histogram-ctn",
        svgId: this.id + "-histogram-svg",
        currentDate: new Date(),
        capacityEvents: [],
        capacityEventsForLineFloor: [],
        line: "",
        lineFloor: "",
        circleOpacity: Number(0),
        svgWidth: 0,
        text: {
          kpi_not_available: this.$t("kpiNotAvailable"),
          pending: this.$t("pending")
        }
      };
    },
    mounted() {
      this.capacityEvents = this.getSelectedViewEventsData;
      if (this.validateCapacity && this.createArrayMonth.length > 0) {
        this.svgWidth =
          this.id.startsWith("overview-modal") && this.modalView == 0
            ? 62 * this.capacityEvents.length
            : document.getElementById(this.divId).offsetWidth * 0.95;
        this.capacityEventsForLineFloor = this.getSelectedViewEventsData.map(
          data => JSON.parse(JSON.stringify(data))
        );
        for (const ev of this.capacityEventsForLineFloor) {
          ev.date = new Date(ev.date);
        }
        this.capacityEventsForLineFloor.unshift({
          date: this.capacityEventsForLineFloor[0].date,
          balance: 0
        });
        this.capacityEventsForLineFloor.push({
          date: this.currentDate,
          balance: 0
        });
        //Draw Histogram
        this.drawHistogram();
      }
    },
    computed: {
      validateCapacity(): boolean {
        for (const ev of this.events) {
          if (
            isNaN(new Date(ev.getLastHistoryBaseline.startedDate).getTime())
          ) {
            return false;
          }
        }
        return true;
      },
      createArrayMonth(): {
        date: Date;
        balance: number;
      }[] {
        let resultData: {
          date: Date;
          balance: number;
        }[] = [];

        let startDate;
        const currentDate = this.$moment(new Date()).startOf("month");
        const projectStartDate = this.$moment(this.projectStartDate).startOf(
          "month"
        );
        //Project creation date is equal or smaller than current date
        if (projectStartDate <= currentDate) {
          const diffStartEnd = currentDate.diff(projectStartDate, "months");
          //if kpi opened is in modal switch between view selected
          if (this.id.startsWith("overview-modal")) {
            switch (this.modalView) {
              case 3:
                startDate =
                  diffStartEnd <= 2
                    ? projectStartDate
                    : this.$moment().subtract(2, "months").startOf("month");
                break;
              case 6:
                startDate =
                  diffStartEnd <= 5
                    ? projectStartDate
                    : this.$moment().subtract(5, "months").startOf("month");
                break;
              case 12:
                startDate =
                  diffStartEnd <= 11
                    ? projectStartDate
                    : this.$moment().subtract(11, "months").startOf("month");
                break;
              default:
                startDate = projectStartDate;
                break;
            }
          } else {
            startDate =
              diffStartEnd <= 4
                ? projectStartDate
                : this.$moment().subtract(4, "months").startOf("month");
          }

          //Add months to array
          while (startDate <= currentDate) {
            resultData.push({
              date: startDate.toDate(),
              balance: 0
            });
            startDate = startDate.add(1, "months");
          }
        }
        return resultData;
      },
      getSelectedViewEventsData(): {
        date: Date;
        balance: number;
      }[] {
        let resultData: {
          date: Date;
          balance: number;
        }[] = this.createArrayMonth;
        //ALL EVENTS OF CURRENT PROJECT
        let eventsList: EventDx[] = this.events;
        //FILTER EVENTS OF TYPE REALOAD OR CONSUME OF CURRENT PROJECT
        //AND ADD/SUBSTRACT QUANTITY TO BALANCE EACH MONTH OF resultData
        let balance: number = 0;
        resultData.forEach(data => {
          let eventIndex = 0;
          const resultDataDate = this.$moment(data.date).startOf("month");
          while (
            eventIndex < eventsList.length &&
            this.$moment(
              eventsList[eventIndex].getLastHistoryBaseline.startedDate
            ).startOf("month") <= resultDataDate
          ) {
            const status = eventsList[eventIndex].getLastHistoryBaseline.status;
            const pendingToConfirm = eventStates.pendingToConfirm.name;
            const occurred = eventStates.occurred.name;
            const eventTime = this.$moment(
              this.events[eventIndex].getLastHistoryBaseline.startedDate
            ).startOf("minute");
            if (
              (status == pendingToConfirm || status == occurred) &&
              this.$moment().startOf("minute") > eventTime
            ) {
              if (
                eventsList[eventIndex].getEventType ==
                eventTypes[projectTypes.capacity.name].events.reload.type
              ) {
                balance += eventsList[eventIndex].getAmount;
              } else if (
                eventsList[eventIndex].getEventType ==
                eventTypes[projectTypes.capacity.name].events.consume.type
              ) {
                balance -= eventsList[eventIndex].getAmount;
              }
            }
            eventIndex++;
          }
          eventsList = eventsList.slice(eventIndex);
          data.balance = balance;
        });
        return resultData;
      },
      projectTypeCapacityProgressBar() {
        let result = {
          totalConsumed: 0,
          totalAdded: 0,
          consumedPercentage: 0
        };
        let eventTime: number;
        for (const event of this.events) {
          const status = event.getLastHistoryBaseline.status;
          const pendingToConfirm = eventStates.pendingToConfirm.name;
          const occurred = eventStates.occurred.name;
          eventTime = this.$moment(
            event.getLastHistoryBaseline.startedDate
          ).startOf("minute");
          if (
            (status == pendingToConfirm || status == occurred) &&
            this.$moment().startOf("minute") > eventTime
          ) {
            switch (event.getEventType) {
              case eventTypes[projectTypes.capacity.name].events.reload.type:
                result.totalAdded += event.getAmount;
                break;
              case eventTypes[projectTypes.capacity.name].events.consume.type:
                result.totalConsumed += event.getAmount;
                break;
            }
          }
        }
        if (result.totalAdded != 0) {
          result.consumedPercentage =
            (result.totalConsumed / result.totalAdded) * 100;
        }
        return result;
      },
      svgHeight(): number {
        return this.id.startsWith("overview-modal") ? 400 : this.svgWidth * 0.6;
      }
    },
    methods: {
      getScales(): {
        x: d3.ScaleTime<number, number>;
        y: d3.ScaleLinear<number, number>;
        xAxis: d3.Axis<d3.AxisDomain>;
      } {
        let dis = this;
        const x = d3
          .scaleTime()
          .domain(
            d3.extent(dis.capacityEvents, function (d) {
              return d.date;
            })
          )
          .rangeRound([0, this.svgWidth]);

        const y = d3
          .scaleLinear()
          .domain([
            0,
            d3.max(dis.capacityEvents, function (d) {
              return d.balance + 50;
            })
          ])
          .range([this.svgHeight, 0]);
        d3.axisBottom().scale(x);
        d3.axisLeft().scale(y);

        //Key funtions to draw X-axis,Y-axis.
        //play around with time format to get it to display as you want : d3.timeFormat("%b-%d")
        const xAxis = d3
          .axisBottom()
          .scale(x)
          .tickFormat(d3.timeFormat("%b"))
          .tickValues(
            dis.capacityEvents.map(function (d, i) {
              if (i % 2 == 0) {
                return d.date;
              } else {
                return false;
              }
            })
          );
        // Return the key calculations and functions to draw the chart
        return {
          x,
          y,
          xAxis
        };
      },
      showMonthBalance(monthIndex: string) {
        if (this.circleOpacity == 0) {
          const balanceContainer = d3.select(
            "#" + this.svgId + "-g-container-" + monthIndex
          );
          //Show balance
          balanceContainer.attr("opacity", "1");
        }
      },
      hideMonthBalance(monthIndex: string) {
        if (this.circleOpacity == 0) {
          const balanceContainer = d3.select(
            "#" + this.svgId + "-g-container-" + monthIndex
          );
          //Hide balance
          balanceContainer.attr("opacity", "0");
        }
      },
      getColWidth(scale): number {
        switch (this.capacityEvents.length) {
          case 0:
            return 0;
          case 1:
            this.circleOpacity = 1;
            return scale.x(this.capacityEvents[0].date) * 0.5;
          case 2:
            return scale.x(this.capacityEvents[1].date) * 0.4;
          case 3:
            return scale.x(this.capacityEvents[1].date) * 0.2;
          case 4:
            return scale.x(this.capacityEvents[1].date);
          case 5:
            return scale.x(this.capacityEvents[1].date);
          case 6:
            return scale.x(this.capacityEvents[1].date) * 0.5;
          case 12:
            return scale.x(this.capacityEvents[1].date);
          default:
            return 62;
        }
      },
      getLinearGradientPath(scale): string {
        //Define path floor (to color with gradient)
        const floorPathCurved = d3
          .line()
          .x(d => {
            return scale.x(d.date);
          })
          .y(d => {
            return scale.y(d.balance);
          });
        const floorPathLineal = d3
          .line()
          .x(d => {
            return scale.x(d.date);
          })
          .y(d => {
            return scale.y(d.balance);
          })
          .curve(d3.curveLinear);
        //Define begining path of gradient
        let capacityEventsForLineFloor1 = [];
        //Define curve path of gradient
        let capacityEventsForLineFloor2 = this.capacityEventsForLineFloor;
        //Define end path of gradient
        let capacityEventsForLineFloor3 = [];
        let floorPath2: any;

        switch (this.capacityEvents.length) {
          case 1:
            return null;
          case 2:
            return floorPathLineal(capacityEventsForLineFloor2);
          default:
            capacityEventsForLineFloor1.push(
              this.capacityEventsForLineFloor[0]
            );
            capacityEventsForLineFloor1.push(
              this.capacityEventsForLineFloor[1]
            );

            capacityEventsForLineFloor3.push(
              this.capacityEventsForLineFloor[
                this.capacityEventsForLineFloor.length - 2
              ]
            );
            capacityEventsForLineFloor3.push(
              this.capacityEventsForLineFloor[
                this.capacityEventsForLineFloor.length - 1
              ]
            );

            capacityEventsForLineFloor2.shift();
            capacityEventsForLineFloor2.pop();
            floorPath2 = floorPathCurved(capacityEventsForLineFloor2).split(
              ","
            );
            floorPath2 = floorPath2.join();
            return (
              floorPathLineal(capacityEventsForLineFloor1) +
              "," +
              floorPath2.split("M")[1] +
              "," +
              floorPathLineal(capacityEventsForLineFloor3).split("M")[1]
            );
        }
      },
      drawHistogram() {
        //Get histogram scale
        const scale = this.getScales();
        let dis = this;
        //Define path
        const path = d3
          .line()
          .x(d => {
            return scale.x(d.date);
          })
          .y(d => {
            return scale.y(d.balance);
          });
        //Select svg container
        const svg = d3.select("#" + this.svgId).attr("overflow", "visible");
        //Append rect column to each month
        const colWidth: number = this.getColWidth(scale);
        svg.selectAll(".bar-rect").remove();
        svg
          .selectAll(".bar-rect")
          .data(this.capacityEvents)
          .enter()
          .append("rect")
          .classed("bar-rect", true)
          .attr("rx", 6)
          .attr("ry", 6)
          .attr("x", function (d, i) {
            return scale.x(d.date) - colWidth / 2;
          })
          .attr("y", function (d, i) {
            return 0;
          })
          .attr("width", function (d, i) {
            return colWidth;
          })
          .attr("height", function (d, i) {
            return dis.svgHeight;
          })
          .on("mouseover", function (e) {
            dis.showMonthBalance(
              Array.from(
                e.target.parentNode.getElementsByClassName("bar-rect")
              ).indexOf(e.target)
            );
          })
          .on("mouseout", function (e) {
            dis.hideMonthBalance(
              Array.from(
                e.target.parentNode.getElementsByClassName("bar-rect")
              ).indexOf(e.target)
            );
          });

        //Append g for circles and amount
        svg.selectAll("g").remove();
        const circleContainer = svg
          .selectAll("g")
          .data(this.capacityEvents)
          .enter()
          .append("g")
          .attr("id", function (d, i) {
            return dis.svgId + "-g-container-" + i;
          })
          .attr("opacity", this.circleOpacity)
          .attr("pointer-events", "none");

        //Append circles to previous 'g' container
        const circles = circleContainer
          .append("circle")
          .attr("r", 4)
          .attr("opacity", "1")
          .attr("cx", function (d) {
            return scale.x(d.date);
          })
          .attr("cy", function (d) {
            return scale.y(d.balance);
          })
          .attr("fill", "#0070ad");

        //Append amount text
        const text = circleContainer
          .append("text")
          .classed("bar-label", true)
          .text(function (d) {
            return d.balance;
          })
          .attr("x", function (d) {
            return scale.x(d.date) - 4;
          })
          .attr("y", function (d) {
            return scale.y(d.balance) - 10;
          })
          .attr("fill", "#0070ad");

        //Append X-label > months abr
        svg.selectAll(".x-label").remove();
        svg
          .selectAll(".x-label")
          .data(this.capacityEvents)
          .enter()
          .append("text")
          .classed("x-label", true)
          .text(function (d, i) {
            return dis.getMonthNameAbbr(d.date);
          })
          .attr("x", function (d) {
            return scale.x(d.date) - 19;
          })
          .attr("y", function (d) {
            return dis.svgHeight + 15;
          });
        // draw line then this.line is injected into the template
        this.line = path(this.capacityEvents);
        //Draw path of Linear gradient
        this.lineFloor = this.getLinearGradientPath(scale);
      },
      getMonthNameAbbr(date): string {
        return this.id.startsWith("overview-modal")
          ? `${this.$moment(date)
              .format("MMM")
              .toUpperCase()
              .substr(0, 3)} ${this.$moment(date).format("YY").toUpperCase()}`
          : this.$moment(date).format("MMM").toUpperCase().substr(0, 3);
      }
    },
    watch: {
      modalView: function () {
        if (this.validateCapacity && this.createArrayMonth.length > 0) {
          this.capacityEvents = this.getSelectedViewEventsData;
          this.svgWidth =
            this.id.startsWith("overview-modal") && this.modalView == 0
              ? 62 * this.capacityEvents.length
              : document.getElementById(this.divId).offsetWidth * 0.95;
          this.capacityEventsForLineFloor = this.getSelectedViewEventsData.map(
            data => JSON.parse(JSON.stringify(data))
          );
          for (const ev of this.capacityEventsForLineFloor) {
            ev.date = new Date(ev.date);
          }
          this.capacityEventsForLineFloor.unshift({
            date: this.capacityEventsForLineFloor[0].date,
            balance: 0
          });
          this.capacityEventsForLineFloor.push({
            date: this.currentDate,
            balance: 0
          });
          //Draw Histogram
          this.drawHistogram();
        }
      }
    }
  };
