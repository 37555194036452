





















































  import CustomerSummary from "../../../models/csp/CustomerSummaryCSP";

  export default {
    name: "ProjectTypeCspKpi",
    props: {
      id: String
    },
    data() {
      return {
        text: {
          subscriptions: this.$tc("subscription", 2),
          licences: this.$t("licences"),
          azurePlans: this.$t("azurePlans"),
          currentCost: this.$t("currentCost")
        },
        customerId: null,
        summary: CustomerSummary
      };
    },
    async mounted() {
      this.customerId = this.getProjectById(this.id).getCustomerId;
      await this.loadCustomerSummaryCSPById(this.customerId);
      this.summary = this.getCustomerSummaryCSPById(this.customerId);
    }
  };
