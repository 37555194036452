import moment from "moment";

export interface IAnalysisResult {
  url: string;
  depth: number;
  projectId: number;
  metadataId: number;
  executionDate: Date;
  linksPass: number;
  linksFail: number;
  cantScan: number;
  totalAnalyzed: number;
  executionTime: number;
  brokenLinks: object[];
}

export default class AnalysisResult {
  private url: string = "-";
  private depth: number = 0;
  private projectId: number = 0;
  private metadataId: number = 0;
  private executionDate: Date = null;
  private linksPass: number = 0;
  private linksFail: number = 0;
  private cantScan: number = 0;
  private totalAnalyzed: number = 0;
  private executionTime: number = 0;
  private brokenLinks: object[] = [];

  constructor(result: any) {
    if (result.hasOwnProperty("metadata") && result.hasOwnProperty("results")) {
      this.url = result.metadata.analyzed_url;
      this.depth = result.metadata.depth;
      this.projectId = result.metadata.project_id;
      this.linksPass = result.results.links_pass;
      this.linksFail = result.results.links_fail;
      this.cantScan = result.results.cant_scan;
      this.totalAnalyzed = result.results.total_analyzed;
      this.executionTime = result.results.execution_time;
      this.brokenLinks = result.results.data;
      this.metadataId = result.metadata.metadata_id;
      this.executionDate = result.metadata.execution_date;
    }
  }

  get getUrl(): string {
    return this.url;
  }

  set setUrl(value: string) {
    this.url = value;
  }

  get getDepth(): number {
    return this.depth;
  }

  set setDepth(value: number) {
    this.depth = value;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get getMetadataId(): number {
    return this.metadataId;
  }

  set setMetadataId(value: number) {
    this.metadataId = value;
  }

  get getExecutionDate(): string {
    return moment(this.executionDate).format("DD/MM/YYYY HH:mm");
  }

  set setExecutionDate(value: Date) {
    this.executionDate = value;
  }

  get getLinksPass(): number {
    return this.linksPass;
  }

  set setLinksPass(value: number) {
    this.linksPass = value;
  }

  get getLinksFail(): number {
    return this.linksFail;
  }

  set setLinksFail(value: number) {
    this.linksFail = value;
  }

  get getCannotScan(): number {
    return this.cantScan;
  }

  set setCannotScan(value: number) {
    this.cantScan = value;
  }

  get getTotalAnalyzed(): number {
    return this.totalAnalyzed;
  }

  set setTotalAnalyzed(value: number) {
    this.totalAnalyzed = value;
  }

  get getExecutionTime(): string {
    return this.formatExecutionTime(this.executionTime);
  }

  set setExecutionTime(value: number) {
    this.executionTime = value;
  }

  get getBrokenLinks(): object[] {
    return this.brokenLinks;
  }

  set setBrokenLinks(value: object[]) {
    this.brokenLinks = value;
  }

  private formatExecutionTime(time: number): string {
    const timeAux = moment.unix(time).utc();
    const seconds = timeAux.second();
    const minutes = timeAux.minute();
    const hours = timeAux.hour();

    if (hours != 0) {
      return hours + "h " + minutes + "m";
    }
    if (minutes != 0) {
      return minutes + "m " + seconds + "s";
    }
    return seconds + "s";
  }
}
