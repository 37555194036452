var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"navBarCollapse",staticClass:"hub-navbar overflow-hidden",style:({ width: _vm.expandNavbar ? '200px' : '60px' }),attrs:{"id":"nav-bar-collapse"}},[(_vm.expandNavbar)?_c('b-row',{staticClass:"not-divider mb-2"},[_vm._v(_vm._s(_vm.text.projects))]):_vm._e(),_c('b-row',{staticClass:"text-align: left;"},[_c('b-col',[_c('b-dropdown',{ref:"dropdown",staticClass:"hub-navbar-project-selector",class:_vm.expandNavbar ? 'expanded' : '',attrs:{"id":"project-selector"},on:{"show":function($event){_vm.expandNavbar = true;
          _vm.expandedProjectSelector = true;
          _vm.$nextTick(function () { return _vm.$refs.dropdown.show(); });},"hide":function($event){_vm.expandedProjectSelector = false}},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('div',{staticStyle:{"display":"flex"}},[_c('b-img',{attrs:{"src":require('../../assets/icons/globals/projects-selector.png'),"width":"24px"}}),(_vm.expandNavbar)?_c('p',{staticClass:"hub-navbar-text text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.text.selectAProject)+" ")]):_vm._e(),_c('div',[(_vm.expandedProjectSelector)?_c('FontAwesomeIcon',{staticClass:"hub-navbar-arrow hub-navbar-arrow--project-selector",attrs:{"icon":"fa-solid fa-chevron-up"}}):_c('FontAwesomeIcon',{staticClass:"hub-navbar-arrow hub-navbar-arrow--project-selector",attrs:{"icon":"fa-solid fa-chevron-down"}})],1)],1)]},proxy:true},(_vm.displayedProjects.length)?{key:"default",fn:function(){return _vm._l((_vm.projectsSelector),function(project){return _c('b-dropdown-item',{key:project.getId,class:{ active: project.getId == _vm.selectedProjectId },attrs:{"title":project.getName,"to":("/" + (_vm.servicesExtensions.deliveryExperience.url) + "/" + (project.getId))},on:{"click":function($event){return _vm.changeProject(project)}}},[_vm._v(_vm._s(project.getName))])})},proxy:true}:{key:"default",fn:function(){return [_c('b-dropdown-item',[_vm._v(" "+_vm._s(_vm.text.noProjectsNavbar)+" ")])]},proxy:true}],null,true)})],1)],1),(_vm.expandNavbar)?_c('b-row',{staticClass:"not-divider mb-2"},[_vm._v(_vm._s(_vm.text.services))]):_vm._e(),_c('b-row',{staticClass:"service-container",class:_vm.expandNavbar ? 'pl-2' : 'pl-3'},[_c('b-col',_vm._l((_vm.projectActiveServices),function(service,index){return _c('div',{key:service.getId,attrs:{"title":service.getName}},[_c('b-row',{staticClass:"service-row",class:_vm.isServiceSelected(service) &&
            (!_vm.expandNavbar || !_vm.haveExtension[index])
              ? 'current-selected-color'
              : '',attrs:{"id":'service-' + service.getId},on:{"click":function($event){_vm.changeExpandedStatus(index), _vm.goToService(service)}}},[_c('b-col',{staticClass:"pl-4",attrs:{"cols":"1"}},[_c('b-img',{staticClass:"service-icon",attrs:{"src":_vm.getServiceIcon(service.getName)}})],1),(_vm.expandNavbar)?_c('b-col',{staticClass:"service-text"},[_vm._v(_vm._s(service.getName)+" ")]):_vm._e()],1),(!_vm.expandNavbar || !_vm.isServiceSelected(service))?_c('b-popover',{staticStyle:{"box-shadow":"2px 2px 4px 0px rgba(161, 161, 161, 1)"},attrs:{"target":'service-' + service.getId,"triggers":"hover","placement":"right","boundary":"viewport"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{style:(!_vm.extensions[index].length ? 'border-radius: 0.3rem' : '')},[_vm._v(" "+_vm._s(service.getName)+" ")])]},proxy:true}],null,true)},_vm._l((_vm.extensions[index]),function(extension){return _c('b-row',{key:extension.getId,staticClass:"extension-submenu",class:_vm.isExtensionSelected(extension, service)
                ? 'extension-submenu-selected'
                : '',attrs:{"no-gutters":""},on:{"click":function($event){_vm.changeExpandedStatus(index),
                _vm.goToExtension(extension, service.getName)}}},[_c('b-col',{attrs:{"cols":"2"}},[_c('b-img',{staticClass:"pl-2",attrs:{"src":_vm.getExtensionIcon(extension.getName),"height":"24px"}})],1),_c('b-col',{staticClass:"submenu-text ml-3"},[_c('span',[_vm._v(_vm._s(extension.getName))])])],1)}),1):_vm._e(),_c('transition-group',{attrs:{"name":"collapse"}},[(_vm.expandedExtension[index] && _vm.expandNavbar)?_vm._l((_vm.extensions[index]),function(extension){return _c('b-row',{key:extension.getId,staticClass:"pl-0 extension-row",class:_vm.isExtensionSelected(extension, service)
                  ? 'current-selected-color'
                  : '',attrs:{"title":extension.getName},on:{"click":function($event){return _vm.goToExtension(extension, service.getName)}}},[_c('b-col',{staticClass:"extension-icon-container",attrs:{"cols":"2"}},[_c('b-img',{staticClass:"extension-icon",attrs:{"src":_vm.getExtensionIcon(extension.getName)}})],1),_c('b-col',{staticClass:"extension-text"},[_vm._v(" "+_vm._s(extension.getName)+" ")])],1)}):_vm._e()],2)],1)}),0)],1),_c('b-row',{class:_vm.expandNavbar ? 'expanded-btn-row' : 'collapsed-btn-row'},[_c('b-col',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.expandNavbar = !_vm.expandNavbar}}},[(!_vm.expandNavbar)?[_c('FontAwesomeIcon',{staticStyle:{"height":"25","color":"white"},attrs:{"icon":"fa-solid fa-chevron-right"}}),_c('FontAwesomeIcon',{staticStyle:{"height":"25","margin-left":"-7px","color":"white"},attrs:{"icon":"fa-solid fa-chevron-right"}})]:[_c('FontAwesomeIcon',{staticStyle:{"height":"25","color":"white"},attrs:{"icon":"fa-solid fa-chevron-left"}}),_c('FontAwesomeIcon',{staticStyle:{"height":"25","margin-left":"-7px","color":"white"},attrs:{"icon":"fa-solid fa-chevron-left"}})]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }