import CategoryCore from "../../models/core/CategoryCore";
import service from "../service";
import baseEndpoint from "./serviceCore";

const endpoint = baseEndpoint + "/category";

export default {
  components: { service: service },
  methods: {
    async getCategories(): Promise<CategoryCore[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(category => new CategoryCore(category));
    },
    async getCategory(id: number): Promise<CategoryCore> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new CategoryCore(response);
    }
  }
};
