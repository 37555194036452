var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-center",staticStyle:{"margin":"0","height":"75%"}},[(_vm.orderedTimelineItems.length)?_c('div',{directives:[{name:"autoscroll",rawName:"v-autoscroll",value:({
      x: _vm.chartScroll,
      y: 0,
      type: 'absolute'
    }),expression:"{\n      x: chartScroll,\n      y: 0,\n      type: 'absolute'\n    }"},{name:"dragscroll",rawName:"v-dragscroll"}],staticClass:"horizontal-scrollbar small-scrollbar grabbable d-flex",attrs:{"id":"timeline-external-ctn"},on:{"dragscrollstart":function($event){_vm.ignoreClicks = true},"dragscrollend":function($event){return _vm.timeoutClick()}}},[_vm._l((_vm.orderedTimelineItems),function(item,index){return [_c('div',{key:'card' + item.id,staticClass:"item-card card",class:{
          'future-cards': index > _vm.todayItemIndex,
          'selected-card': item.id == _vm.selected,
          'first-card': index == 0,
          'last-card': index == _vm.orderedTimelineItems.length - 1
        },on:{"click":function($event){_vm.selectItem(item.id, _vm.getStageEventRequestType(item.id))}}},[_c('TimelineItem',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],staticClass:"timeline-item-info",attrs:{"projectId":_vm.projectCore.id,"eventStageRequest":item,"versionDx":_vm.versionDx,"type":_vm.getStageEventRequestType(item.id),"comeFrom":'carousel',"projectType":_vm.projectCore.projectType,"showCancel":item.id == _vm.selected},on:{"handleClose":function (event) { return _vm.deselectItem(event); }}})],1),(
          index == _vm.todayItemIndex && index != _vm.orderedTimelineItems.length - 1
        )?_c('div',{key:'current-date-' + index,ref:"currentDate",refInFor:true,staticStyle:{"margin-top":"7px"},attrs:{"id":"current-date"}}):_vm._e()]})],2):_c('div',{staticClass:"text-center align-self-center"},[_c('p',{staticClass:"info-text"},[_vm._v(_vm._s(_vm.text.noItems))]),_c('div',[(_vm.permissionsEvents.write)?_c('b-button',{staticClass:"button-small button-cancel",attrs:{"pill":""},on:{"click":function($event){return _vm.openTimelineItemForm('event')}}},[_vm._v(" "+_vm._s(_vm.text.newEvent)+" ")]):_vm._e(),(_vm.permissionsStages.write)?_c('b-button',{staticClass:"button-small button-cancel",staticStyle:{"margin-right":"0"},attrs:{"pill":""},on:{"click":function($event){return _vm.openTimelineItemForm('stage')}}},[_vm._v(_vm._s(_vm.text.newStage)+" ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }