import { library } from "@fortawesome/fontawesome-svg-core";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import * as signalR from "@microsoft/signalr";
import BootstrapVue from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap/dist/css/bootstrap.css";
import Moment from "moment";
import Vue from "vue";
import interceptors from "./_helpers/interceptors";
import App from "./App.vue";
import authService from "./authentication/AuthServiceInst";
import config from "./config";
import storeMixins from "./mixins/storeMixins";
import { i18n } from "./plugins/i18";
import router from "./router";
import { store } from "./store";
import Log from "./utils/logger";
import Singleton from "./utils/singleton";

Vue.prototype.$moment = Moment;

library.add(fas);
library.add(far);
Vue.component("FontAwesomeIcon", FontAwesomeIcon);

Vue.config.productionTip = true;
Vue.use(BootstrapVue);
Vue.use(Log.logger, Log.options);

// Save a reference to the original $log methods
const originalLog = Vue.prototype.$log;
// Extend Vue prototype
Vue.prototype.$log = {
  debug: function (...args: any[]): void {
    originalLog.debug(Log.formatter(args));
  },
  info: function (...args: any[]): void {
    originalLog.info(Log.formatter(args));
  },
  warn: function (...args: any[]): void {
    originalLog.warn(Log.formatter(args));
  },
  error: function (...args: any[]): void {
    originalLog.error(Log.formatter(args));
  },
  fatal: function (...args: any[]): void {
    originalLog.fatal(Log.formatter(args));
  }
};

Vue.mixin(storeMixins);
interceptors();
require("../style.css");

const coreAuth = authService.get();

Singleton.get(Vue);

coreAuth.registerAuthCallback().then(() =>
  coreAuth.getToken().then(async () => {
    await coreAuth.getAccountAndSave();
    const loggedUsername = store.getters["peopleCore/getUser"].getUsername;

    new Vue({
      router,
      store,
      i18n,
      render: h => h(App),
      watch: {
        $route: async function () {
          // To reset App component inServicePage prop to use global page header by default
          await this.$root.$emit("inServicePage", false);
        }
      }
    }).$mount("#app");

    Vue.prototype.$notificationConnection = new signalR.HubConnectionBuilder()
      .withUrl(config.apiDxEndpoint + "/hub", {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
      })
      .build();

    Vue.prototype.$notificationConnection.on("messageReceived", () => {
      store.dispatch("notificationDx/loadNotificationsByUser", loggedUsername);
      const alertMessage = {
        text: i18n.t("newNotification"),
        time: 5,
        type: "message"
      };
      store.dispatch("message/setMessage", alertMessage);
    });

    Vue.prototype.$notificationConnection
      .start()
      .then(() => {
        Vue.prototype.$notificationConnection.invoke(
          "registerConnection",
          loggedUsername
        );
      })
      .catch(err => Vue.$log.error(err));
  })
);
