var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"h-75",attrs:{"id":("timeline-chart-" + _vm.comeFrom)}},[(!_vm.loadedData)?[_c('b-col')]:(_vm.versionDx)?[_c('TimelineItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.timelineItemInfo.show),expression:"timelineItemInfo.show"}],staticClass:"timeline-item-info",attrs:{"projectId":_vm.projectCore.id,"eventStageRequest":_vm.timelineItemInfo.item,"versionDx":_vm.versionDx,"type":_vm.timelineItemInfo.type,"comeFrom":_vm.comeFrom,"projectType":_vm.timelineItemInfo.projectType,"showCancel":true},on:{"handleClose":function($event){return _vm.closeTimelineItem()}}}),_c('b-col',{directives:[{name:"dragscroll",rawName:"v-dragscroll"},{name:"autoscroll",rawName:"v-autoscroll",value:({
        x: _vm.chartScroll,
        y: 0,
        type: 'absolute'
      }),expression:"{\n        x: chartScroll,\n        y: 0,\n        type: 'absolute'\n      }"}],staticClass:"small-scrollbar pb-1 h-100",attrs:{"id":"timeline-external-ctn"},on:{"dragscrollstart":function($event){_vm.dragscrolling = true},"dragscrollend":_vm.dragscrollEndTimeout,"click":function($event){return _vm.groupPopoverCloseHandler($event)},"scroll":function($event){_vm.currentScroll = $event.target.scrollLeft}}},[_c('b-row',{ref:"timelineCtn",staticClass:"months-row grabbable"},[_vm._l((_vm.projectTimelineDates),function(date){return _c('b-col',{key:("month-" + (date.getMonth()) + "-" + (date.getFullYear())),staticClass:"timeline-month back-timeline",class:{
            'back-timeline-first':
              date.getTime() === _vm.projectTimelineDates[0].getTime()
          },style:({ width: _vm.monthWidth + 'px' })},[_vm._v(_vm._s(_vm.$moment(date).format("MMMM YYYY").toUpperCase()))])}),(_vm.showCurrentDate)?[_c('div',{ref:"currentDate",attrs:{"id":"current-date"}})]:_vm._e()],2),_c('b-row',{directives:[{name:"dragscroll",rawName:"v-dragscroll.y",modifiers:{"y":true}}],staticClass:"small-scrollbar",style:({
          width: _vm.monthWidth * _vm.projectTimelineDates.length + 'px'
        }),attrs:{"id":"timeline-content"}},[_c('b-col',[_c('b-row',{staticClass:"items-row"},[_c('div',{ref:"firedLinks"}),_c('div',{staticClass:"items-line grabbable"}),_vm._l((_vm.orderedTimelineChartItems),function(item){return [(!item.hasOwnProperty('initDate'))?_c('div',{key:("timeline-item-div-" + (item.id)),attrs:{"id":("timeline-item-" + (item.id))}},[_c('b-img',{key:("item-" + (item.id)),ref:"timelineItem",refInFor:true,staticClass:"item",class:{
                    'item-selected': _vm.getTimelineItemId == item.id
                  },attrs:{"id":("item-" + _vm.comeFrom + "-" + (item.id)),"top":item.top,"date":item.date,"name":item.name,"width":item.width,"src":require('../../../../assets/icons/' + item.iconSrc),"alt":""},on:{"click":function($event){return _vm.openTimelineItem(item.id, item.type)},"mouseover":function($event){_vm.showEventLink(item.id);
                    _vm.highlightAffected(item.id);},"mouseleave":function($event){_vm.hideEventLink(item.id);
                    _vm.undoHighlightAffected(item.id);}}}),_c('b-popover',{key:("popover-item-" + (item.id)),attrs:{"target":("item-" + _vm.comeFrom + "-" + (item.id)),"placement":"bottom","show":_vm.showItemsPopovers,"triggers":!_vm.showItemsPopovers ? 'hover' : 'manual',"boundary":_vm.getPopoverBoundary(),"container":("timeline-item-" + (item.id))}},[_c('b-container',[_c('a',[_vm._v(" "+_vm._s(item.name)),_c('br'),_vm._v(" "+_vm._s(_vm.$moment(item.date).format("L"))+" ")])])],1)],1):_c('div',{key:("item-group-ctn-" + (item.items[0].id)),staticClass:"item-group-ctn"},[_c('b-button',{key:("item-group-" + (item.items[0].id)),ref:"timelineItem",refInFor:true,staticClass:"item-stacked",class:{
                    'item-selected': item.items
                      .map(function (i) { return i.id; })
                      .includes(_vm.getTimelineItemId)
                  },attrs:{"tabindex":"-1","id":("item-group-" + _vm.comeFrom + "-" + (item.items[0].id)),"top":0,"date":item.initDate,"name":item.items[0].name,"alt":""},on:{"mouseover":function($event){_vm.showEventLink(item.items[0].id);
                    _vm.highlightAffected(item.items[0].id);},"mouseleave":function($event){_vm.hideEventLink(item.items[0].id);
                    _vm.undoHighlightAffected(item.items[0].id);}}},[_vm._v(" "+_vm._s(item.items.length)+" ")]),_c('b-popover',{key:("popover-item-group-" + (item.items[0].id)),attrs:{"target":("item-group-" + _vm.comeFrom + "-" + (item.items[0].id)),"placement":"bottom","triggers":"hover","boundary":"#timeline-chart-timeline"},scopedSlots:_vm._u([(
                      new Date(item.initDate).getDate() !=
                      new Date(item.endDate).getDate()
                    )?{key:"title",fn:function(){return [_c('b',[_vm._v(" "+_vm._s(_vm.$moment(item.initDate).format("L"))+" - "+_vm._s(_vm.$moment(item.endDate).format("L"))+" ")])]},proxy:true}:{key:"title",fn:function(){return [_c('b',[_vm._v(" "+_vm._s(_vm.text.samedayEvents)+" "+_vm._s(_vm.$moment(item.initDate).format("L"))+" ")])]},proxy:true}],null,true)},[_c('b-container',{staticClass:"vertical-scrollbar small-scrollbar",attrs:{"fluid":"","id":"item-group-ctn"}},_vm._l((item.items),function(it){return _c('b-row',{key:it.id,staticClass:"item-group",attrs:{"id":("item-group-" + _vm.comeFrom + "-" + (it.id))},on:{"click":function($event){return _vm.openTimelineItem(it.id, it.type)},"mouseover":function($event){_vm.showEventLink(it.id);
                        _vm.highlightAffected(it.id);},"mouseleave":function($event){_vm.hideEventLink(it.id);
                        _vm.undoHighlightAffected(it.id);}}},[_c('b-col',{staticClass:"item-group-item-icon text-center",attrs:{"cols":"2"}},[_c('b-img',{attrs:{"width":it.iconSrc.startsWith('fire-') ? 37 : it.width,"src":require('../../../../assets/icons/' + it.iconSrc)}})],1),_c('b-col',{staticClass:"popover-name text-truncate",attrs:{"cols":"10"}},[_c('div',{staticClass:"text-overflow-ellipsis",staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(it.name)+" ")])])],1)}),1)],1)],1)]}),(_vm.comeFrom != 'planner-2' && _vm.nextEvent.target)?_c('b-popover',{attrs:{"target":_vm.nextEvent.target,"placement":"bottom","disabled":!_vm.showPopover,"show":_vm.showPopover}},[_c('FontAwesomeIcon',{staticStyle:{"height":"14","color":"var(--main-bg-color)"},attrs:{"icon":"fa-regular fa-clock"}}),_c('br'),_vm._v(" "+_vm._s(_vm.text.left)+" "+_vm._s(_vm.nextEvent.days)+" "+_vm._s(_vm.text.daysFor)+": "),_c('br'),_c('strong',[_vm._v(_vm._s(_vm.nextEvent.name)+"!")])],1):_vm._e()],2),(_vm.permissionsStages.read)?_c('b-row',{staticClass:"stage-row small-scrollbar"},[_c('b-col',[_vm._l((_vm.orderedStages),function(row,index){return [(row.length > 0)?_c('b-row',{key:("timeline-stage-row-" + index),staticClass:"stage-row-ordered"},[_vm._l((row),function(stage){return [(stage.getBaselineByVersion(_vm.versionDx))?_c('div',{key:("timeline-stage-" + (stage.id)),ref:"stage",refInFor:true,staticClass:"stage",class:[
                        _vm.stagePendingToConfirmClass(
                          stage.getBaselineByVersion(_vm.versionDx).status,
                          stage.getBaselineByVersion(_vm.versionDx).endDate
                        ),
                        _vm.getTimelineItemId == stage.getId ? stage.getColor : ''
                      ],attrs:{"id":("timeline-stage-" + _vm.comeFrom + "-" + (stage.id)),"date":stage.getBaselineByVersion(_vm.versionDx).startedDate +
                        ' ' +
                        stage.getBaselineByVersion(_vm.versionDx).endDate},on:{"click":function($event){return _vm.openTimelineItem(stage.getId, 'stage')},"mouseover":function($event){_vm.showStageLink(
                          _vm.stagePendingToConfirm(
                            stage.getBaselineByVersion(_vm.versionDx).status,
                            stage.getBaselineByVersion(_vm.versionDx).endDate
                          ),
                          stage.getBaselineByVersion(_vm.versionDx).affecteds[0]
                        )},"mouseleave":function($event){_vm.hideStageLink(
                          _vm.stagePendingToConfirm(
                            stage.getBaselineByVersion(_vm.versionDx).status,
                            stage.getBaselineByVersion(_vm.versionDx).endDdate
                          ),
                          stage.getBaselineByVersion(_vm.versionDx).affecteds[0]
                        )}}},[(
                          _vm.stageTitleFits(
                            stage.getBaselineByVersion(_vm.versionDx).startedDate,
                            stage.getBaselineByVersion(_vm.versionDx).endDate
                          )
                        )?_c('span',{staticClass:"stage-title"},[_vm._v(" "+_vm._s(stage.name)+" ")]):_vm._e(),_c('b-popover',{key:("popover-stage-" + (stage.id)),attrs:{"target":("timeline-stage-" + _vm.comeFrom + "-" + (stage.id)),"placement":"top","triggers":"hover","boundary":_vm.getPopoverBoundary(),"container":("timeline-stage-" + _vm.comeFrom + "-" + (stage.id))}},[_c('b-container',[_c('a',[_vm._v(" "+_vm._s(stage.name)),_c('br'),_vm._v(" "+_vm._s(_vm.$moment( stage.getBaselineByVersion(_vm.versionDx) .startedDate ).format("L"))+" - "+_vm._s(_vm.$moment( stage.getBaselineByVersion(_vm.versionDx).endDate ).format("L"))+" ")])])],1)],1):_vm._e()]})],2):_vm._e()]}),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.projectCore.getProjectType === _vm.liabilityTemporaryString ||
                  _vm.projectCore.getProjectType === _vm.liabilityFullString
                ),expression:"\n                  projectCore.getProjectType === liabilityTemporaryString ||\n                  projectCore.getProjectType === liabilityFullString\n                "}]},[_c('div',{ref:"lifetimeLine",staticClass:"lifetime-line"})])],2)],1):_vm._e()],1)],1)],1),(!_vm.existTimelineItems)?_c('b-col',{attrs:{"id":"timeline-empty"}},[_c('p',{staticClass:"info-text"},[_vm._v(_vm._s(_vm.text.noItems))]),_c('div',[(_vm.permissionsEvents.write)?_c('b-button',{staticClass:"button-small button-cancel",attrs:{"pill":""},on:{"click":function($event){return _vm.openTimelineItemForm('event')}}},[_vm._v(" "+_vm._s(_vm.text.newEvent))]):_vm._e(),(_vm.permissionsStages.write)?_c('b-button',{staticClass:"button-small button-cancel",staticStyle:{"margin-right":"0"},attrs:{"pill":""},on:{"click":function($event){return _vm.openTimelineItemForm('stage')}}},[_vm._v(_vm._s(_vm.text.newStage))]):_vm._e()],1)]):_vm._e(),(_vm.comeFrom != 'timeline')?_c('div',{staticStyle:{"position":"absolute"},style:({
        margin:
          _vm.comeFrom == 'planner-1' ? '-24px 0 0 -24px' : '-295px 0 0 -24px'
      })},[_c('span',{staticStyle:{"font":"bold 11pt Ubuntu"}},[_vm._v("V "+_vm._s(_vm.versionDx))])]):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }