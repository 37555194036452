import { i18n } from "../../plugins/i18";
import emailBuilder from "./emailBuilder";

function build(
  newOperation: string,
  mailDetails: any,
  ending: string,
  lang: string
): string {
  return `
    <p>${newOperation}. ${i18n.t("emailAlert", lang)}</p>
    <p>&nbsp;</p>
    <p>${i18n.t("operationDetails", lang)}:</p>
    <p>${emailBuilder.bodyDetailsList(mailDetails)}</p>
    <p>&nbsp;</p>
    <p>${ending}</p>
  `;
}

export default {
  build
};
