var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"cols":"7"}},[_c('b-row',{staticStyle:{"padding-left":"0","display":"table"}},[_c('p',{staticStyle:{"font":"bold 20px Ubuntu"}},[_vm._v(" "+_vm._s(_vm.text.newReport)+" ")]),_c('b',[_vm._v(_vm._s(_vm.text.actions))])]),_c('b-row',{staticStyle:{"margin-top":"4rem"}},[_c('p',[_vm._v(_vm._s(_vm.text.actionsReview))])]),_c('b-row',{staticStyle:{"margin-bottom":"1.5rem"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('b-table',{key:_vm.reviewActionkey,attrs:{"striped":"","hover":"","items":_vm.reviewActions,"fields":_vm.actionsReviewfieldsNames,"head-variant":"light","id":"new-report-actions-actions-review"},on:{"row-hovered":_vm.onHover,"row-unhovered":_vm.onUnHover},scopedSlots:_vm._u([{key:"cell(action)",fn:function(data){return [_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-sm remove-btn-new-action",class:{
                hidden:
                  !data.item.hovered ||
                  data.item.action == '' ||
                  data.item.state == ''
              },on:{"click":function($event){return _vm.removeReviewAction(data.item)}}},[_c('FontAwesomeIcon',{staticStyle:{"opacity":"0.5","height":"20","color":"#ff304c"},attrs:{"icon":"fa-regular fa-trash-can"}})],1),_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(data.item.action),expression:"data.item.action"}],staticClass:"text-area-actions",style:({
                  paddingTop: data.item.action.length > 36 ? '0' : '12px'
                }),attrs:{"name":'textarea-actions-' + data.index,"rows":"2","cols":"50","maxlength":_vm.actionsMaxLength,"placeholder":_vm.text.objectiveDescription},domProps:{"value":(data.item.action)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data.item, "action", $event.target.value)}}})])])]}},{key:"cell(person)",fn:function(data){return [_c('multiselect',{staticClass:"multiselection-actions-person",attrs:{"openDirection":"bottom","options":_vm.people,"showLabels":false,"searchable":false,"placeholder":_vm.text.selectOne,"label":"name","track-by":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var option = ref.option;
return [_c('div',[_c('PeopleInfo',{attrs:{"person":option}})],1)]}},{key:"singleLabel",fn:function(ref){
                var option = ref.option;
return [_c('div',[_c('PeopleInfo',{attrs:{"person":option,"onlyName":true}})],1)]}},{key:"noOptions",fn:function(){return [_c('p',{staticStyle:{"font":"10pt Ubuntu","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.text.thereAreNoParticipants)+" ")])]},proxy:true}],null,true),model:{value:(data.item.person),callback:function ($$v) {_vm.$set(data.item, "person", $$v)},expression:"data.item.person"}})]}},{key:"cell(state)",fn:function(data){return [_c('multiselect',{staticClass:"multiselection-actions-state",attrs:{"openDirection":"bottom","options":_vm.actionsStates,"showLabels":false,"searchable":false,"allowEmpty":false,"placeholder":_vm.text.selectOne,"label":"name","track-by":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var option = ref.option;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"max-content"}},[_c('div',{staticClass:"action-state",style:({ backgroundColor: option.color })}),_c('div',{staticStyle:{"margin-top":"auto","margin-bottom":"auto","margin-left":"5px"}},[_c('p',{staticStyle:{"margin":"0","font":"11pt Ubuntu"}},[_vm._v(" "+_vm._s(option.name)+" ")])])])]}},{key:"singleLabel",fn:function(ref){
                var option = ref.option;
return [_c('div',{staticClass:"d-flex",staticStyle:{"width":"max-content"}},[_c('div',{staticClass:"action-state",style:({ backgroundColor: option.color })}),_c('div',{staticStyle:{"margin-left":"5px"}},[_c('p',{staticStyle:{"margin":"0","font":"11pt Ubuntu"}},[_vm._v(" "+_vm._s(option.name)+" ")])])])]}}],null,true),model:{value:(data.item.state),callback:function ($$v) {_vm.$set(data.item, "state", $$v)},expression:"data.item.state"}})]}}])})],1)]),_c('b-row',{staticStyle:{"margin-top":"0"}},[_c('p',[_vm._v(_vm._s(_vm.text.newActions))])]),_c('b-row',{staticStyle:{"margin-bottom":"1rem"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('b-table',{key:_vm.newActionKey,attrs:{"striped":"","hover":"","items":_vm.actionsNew,"fields":_vm.newActionsfieldsNames,"head-variant":"light","id":"new-report-actions-new-actions"},on:{"row-hovered":_vm.onHover,"row-unhovered":_vm.onUnHover},scopedSlots:_vm._u([{key:"cell(action)",fn:function(data){return [_c('div',{staticStyle:{"display":"flex"}},[_c('button',{staticClass:"btn btn-sm remove-btn-new-action",class:{
                hidden:
                  !data.item.hovered ||
                  data.item.action == '' ||
                  data.item.revision_date == null
              },on:{"click":function($event){return _vm.removeNewAction(data.item)}}},[_c('FontAwesomeIcon',{staticStyle:{"opacity":"0.5","height":"20","color":"#ff304c"},attrs:{"icon":"fa-regular fa-trash-can"}})],1),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(data.item.action),expression:"data.item.action"}],staticClass:"text-area-actions",style:({
                paddingTop: data.item.action.length > 36 ? '0' : '12px'
              }),attrs:{"name":'textarea-new-actions-' + data.index,"rows":"2","cols":"50","maxlength":_vm.actionsMaxLength,"placeholder":_vm.text.objectiveDescription},domProps:{"value":(data.item.action)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(data.item, "action", $event.target.value)}}})])]}},{key:"cell(person)",fn:function(data){return [_c('multiselect',{staticClass:"multiselection-actions-person",attrs:{"openDirection":"bottom","options":_vm.people,"showLabels":false,"searchable":false,"placeholder":_vm.text.selectOne,"label":"name","track-by":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
              var option = ref.option;
return [_c('div',[_c('PeopleInfo',{attrs:{"person":option}})],1)]}},{key:"singleLabel",fn:function(ref){
              var option = ref.option;
return [_c('div',[_c('PeopleInfo',{attrs:{"person":option,"onlyName":true}})],1)]}},{key:"noOptions",fn:function(){return [_c('p',{staticStyle:{"font":"10pt Ubuntu","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.text.thereAreNoParticipants)+" ")])]},proxy:true}],null,true),model:{value:(data.item.person),callback:function ($$v) {_vm.$set(data.item, "person", $$v)},expression:"data.item.person"}})]}},{key:"cell(revision_date)",fn:function(data){return [_c('Datepicker',{staticClass:"datepicker-input-parent",staticStyle:{"padding-top":"0px","width":"150px","text-align":"left"},attrs:{"name":'new-report-actions-new-actions-' + data.index,"placeholder":"--/--/--","language":_vm.languageSelected,"monday-first":"","clear-button":false,"disabledDates":_vm.disabledDates,"input-class":"new-report-calendar-input"},model:{value:(data.item.revision_date),callback:function ($$v) {_vm.$set(data.item, "revision_date", $$v)},expression:"data.item.revision_date"}})]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }