import { roleTypes } from "../../_helpers/roleTypes";
import { i18n } from "../../plugins/i18";

export interface IPeople {
  id?: string;
  username: string;
  firstName: string;
  lastName: string;
  language: string;
  jobPosition?: string;
  incorporationDate: Date;
  roleId: string;
  clientId: number;
}

export default class People {
  private id?: string;
  private username: string;
  private firstName: string;
  private lastName: string;
  private language: string;
  private jobPosition?: string;
  private incorporationDate: Date;
  private roleId: string;
  private clientId: number;

  constructor(dto: IPeople) {
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }
  get getUsername(): string {
    return this.username;
  }

  set setUsername(username: string) {
    this.username = username;
  }

  get getFirstName(): string {
    return this.firstName;
  }

  set setFirstName(firstName: string) {
    this.firstName = firstName;
  }

  get getLastName(): string {
    return this.lastName;
  }

  set setLastName(lastName: string) {
    this.lastName = lastName;
  }

  get getLanguage(): string {
    return this.language;
  }

  set setLanguage(language: string) {
    this.language = language;
  }

  get getJobPosition(): string {
    return this.jobPosition
      ? this.jobPosition
      : i18n.t("notSpecifiedJobPosition").toString();
  }

  set setJobPosition(jobPosition: string) {
    this.jobPosition = jobPosition;
  }

  get getIncorporationDate(): Date {
    return this.incorporationDate;
  }

  set setIncorporationDate(incorporationDate: Date) {
    this.incorporationDate = incorporationDate;
  }

  get getRoleId(): string {
    return this.roleId;
  }

  set setRoleId(roleId: string) {
    this.roleId = roleId;
  }

  get getClientId(): number {
    return this.clientId;
  }

  set setClientId(clientId: number) {
    this.clientId = clientId;
  }

  getNaturalFullName(): string {
    return this.getFirstName + " " + this.getLastName;
  }

  isAdminOrMgr(): boolean {
    return this.isAdmin() || this.isManager();
  }

  isAdmin(): boolean {
    return this.roleId == roleTypes.admin.name;
  }

  isManager(): boolean {
    return this.roleId == roleTypes.manager.name;
  }

  isUser(): boolean {
    return this.roleId == roleTypes.user.name;
  }

  isSogeti(): boolean {
    return this.clientId == 1;
  }
}
