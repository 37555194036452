export const servicesExtensions = {
  deliveryExperience: {
    name: "Delivery Experience",
    url: "delivery-experience",
    iconFolder: "deliveryexperience",
    icon: "delivery-experience.png",
    extensions: {
      dxHome: {
        name: "DX Home",
        url: "",
        icon: "dx-home.png"
      },
      bookmarks: {
        name: "Bookmarks",
        url: "error",
        icon: "bookmarks-extension.png"
      },
      timeline: {
        name: "Timeline",
        url: "error",
        icon: "timeline-extension.png"
      },
      people: {
        name: "People",
        url: "error",
        icon: "people-extension.png"
      },
      docs: {
        name: "Docs",
        url: "error",
        icon: "docs-extension.png"
      },
      planner: {
        name: "Planner",
        url: "planner",
        icon: "planner-extension.png"
      },
      requests: {
        name: "Requests",
        url: "requests",
        icon: "requests-extension.png"
      },
      overview: {
        name: "Overview",
        url: "error",
        icon: "overview-extension.png"
      }
    }
  },
  qualityEye: {
    name: "Quality Eye",
    url: "quality-eye",
    iconFolder: "qualityeye",
    icon: "quality-eye.png",
    extensions: {}
  },
  cspPanel: {
    name: "CSP Panel",
    url: "csp-panel",
    iconFolder: "csp",
    icon: "csp-panel.png",
    extensions: {
      cspDashboard: {
        name: "CSP Dashboard",
        url: "csp-dashboard",
        icon: "csp-dashboard-extension.png"
      },
      cspOperations: {
        name: "CSP Operations",
        url: "csp-operations",
        icon: "csp-operations-extension.png"
      }
    }
  },
  spiderLinksChecker: {
    name: "Spider Links Checker",
    url: "spider-links-checker",
    iconFolder: "spiderlinkschecker",
    icon: "spider-links-checker.png",
    extensions: {
      analysis: {
        name: "Analysis",
        url: "analysis",
        icon: "analysis-extension.png"
      }
    }
  },
  // Fallback extension for services without main (project) page dedicated extension
  defaultExtension: {
    name: "Default",
    url: "",
    iconFolder: "",
    icon: "",
    extensions: {}
  }
};

export function getServiceByName(serviceName: string): any {
  for (const service of Object.values(servicesExtensions)) {
    if (service.name === serviceName) {
      return service;
    }
  }

  return servicesExtensions.deliveryExperience;
}

export function getExtensionByName(
  serviceObj: Object,
  extensionName: string
): any {
  for (const extension of Object.values(serviceObj["extensions"])) {
    if (extension["name"] === extensionName) {
      return extension;
    }
  }

  return servicesExtensions.defaultExtension;
}

export function getServiceIcon(serviceName: string): NodeRequire {
  for (const service of Object.values(servicesExtensions)) {
    if (service.name === serviceName) {
      return require(`../assets/icons/services/${service.iconFolder}/${service.icon}`);
    }
  }

  return require("../assets/icons/placeholders/default-service.png");
}

export function getExtensionIcon(extensionName: string): NodeRequire {
  const service = getServiceByExtensionName(extensionName);
  for (const extension of Object.values(service["extensions"])) {
    if (extension["name"] === extensionName) {
      return require(`../assets/icons/services/${service.iconFolder}/${extension["icon"]}`);
    }
  }
  return require("../assets/icons/placeholders/default-extension.png");
}

function getServiceByExtensionName(extensionName: string): any | null {
  for (const service of Object.values(servicesExtensions)) {
    for (const extension of Object.values(service["extensions"])) {
      if (extension["name"] === extensionName) {
        return service;
      }
    }
  }
  if (extensionName == servicesExtensions.defaultExtension.name)
    return servicesExtensions.defaultExtension;
  return null;
}

export function getServiceUrl(serviceName: string): string | null {
  for (const service of Object.values(servicesExtensions)) {
    if (service["name"] === serviceName) {
      return service["url"];
    }
  }

  return null;
}

export function getExtensionUrl(extensionName: string): string | null {
  for (const service of Object.values(servicesExtensions)) {
    if (service["name"] == getServiceByExtensionName(extensionName).name) {
      for (const extension in service.extensions) {
        if (service.extensions[extension].name === extensionName) {
          return service.extensions[extension].url;
        }
      }
    }
  }

  return null;
}
