import Permissions from "./Permissions/Permissions";

export interface IPeopleDx {
  id?: string;
  userName: string;
  jobPosition?: string;
  projectId?: number;
  pointOfContact?: boolean;
  events: string[];
  stages: string[];
  permissions?: Permissions;
}

export default class PeopleDx {
  private id?: string;
  private userName: string;
  private jobPosition?: string;
  private projectId?: number;
  private pointOfContact?: boolean;
  private events: string[];
  private stages: string[];
  private permissions?: Permissions;

  constructor(dto: IPeopleDx) {
    if (!dto.permissions) {
      this.permissions = new Permissions();
    }
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }
  get getUsername(): string {
    return this.userName;
  }

  set setUsername(value: string) {
    this.userName = value;
  }

  get getJobPosition(): string {
    return this.jobPosition;
  }

  set setJobPosition(value: string) {
    this.jobPosition = value;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get isPOC(): boolean {
    return this.pointOfContact != undefined && this.pointOfContact != null
      ? this.pointOfContact
      : false;
  }

  set setPOC(value: boolean) {
    this.pointOfContact = value;
  }

  get getEvents(): string[] {
    return this.events;
  }

  set setEvents(value: string[]) {
    this.events = value;
  }

  addEvent(eventId: string): void {
    this.events.push(eventId);
  }

  removeEvent(eventId: string): void {
    const eventIndex: number = this.events.indexOf(eventId);
    if (eventIndex > -1) {
      this.events.splice(this.events.indexOf(eventId), 1);
    }
  }

  get getStages(): string[] {
    return this.stages;
  }

  set setStages(value: string[]) {
    this.stages = value;
  }

  addStage(stageId: string): void {
    this.stages.push(stageId);
  }

  removeStage(stageId: string): void {
    const stageIndex: number = this.stages.indexOf(stageId);
    if (stageIndex > -1) {
      this.stages.splice(this.stages.indexOf(stageId), 1);
    }
  }

  get getPermissions(): Permissions {
    return this.permissions;
  }

  set setPermissions(value: Permissions) {
    this.permissions = value;
  }
}
