
















  import PeopleIcon from "../../view-models/PeopleIcon";

  export default {
    name: "PeopleDefaultIcon",
    props: {
      people: PeopleIcon,
      size: {
        type: Number,
        default: 22
      }
    },
    data() {
      return {
        colorsArray: [
          "var(--green)",
          "var(--bright-purple)",
          "var(--aqua)",
          "var(--tech-red)",
          "var(--light-claret)",
          "var(--dark-green)",
          "var(--purple)",
          "var(--bright-aqua)",
          "var(--orange)",
          "var(--dark-purple)",
          "var(--peach)",
          "var(--claret)"
        ]
      };
    },
    computed: {
      isExternalPeople(): boolean {
        return this.people.clientId == -1 || this.people.clientId == null;
      },
      // Display Name Index of PeopleDx from Prop
      displayName: function (): string {
        if (this.people.getFirstName.length < 3)
          return this.people.getFirstName;
        else {
          if (
            this.people.getFirstName.length > 1 &&
            this.people.getLastName.length > 0
          )
            return (
              this.people.getFirstName[0].toUpperCase() +
              this.people.getLastName[0].toUpperCase()
            );
          else return "EU";
        }
      },
      // Color Index of PeopleDx from Prop
      colorIndex: function (): number {
        if (!this.getPeopleDxColorIds.includes(this.people.getUsername)) {
          this.pushPeopleDxColorIdLocal(this.people.getUsername);
        }
        return (
          this.getPeopleDxColorIds.indexOf(this.people.getUsername) %
          this.colorsArray.length
        );
      }
    }
  };
