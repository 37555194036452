import CustomerCSP from "../../models/csp/CustomerCSP";
import service from "../service";
import baseEndpoint from "./serviceCSP";

const endpoint = baseEndpoint + "/Customer";

export default {
  components: { service: service },
  methods: {
    async getCustomersCSP(): Promise<CustomerCSP[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(customer => new CustomerCSP(customer));
    }
  }
};
