import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import CapacityTypeCore from "../../../models/core/CapacityTypeCore";
import capacityTypesService from "../../../services/core/capacityTypeService";
import { RootState } from "../../index";

const namespaced = true;

interface CapacityTypeState {
  capacityTypes: CapacityTypeCore[];
}

const state: CapacityTypeState = {
  capacityTypes: []
};

const mutations: MutationTree<CapacityTypeState> = {
  setCapacityTypes(state, capacityTypes: CapacityTypeCore[]) {
    state.capacityTypes = capacityTypes;
  },
  addCapacityType(state, capacityType: CapacityTypeCore) {
    state.capacityTypes.push(capacityType);
  }
};

const getters: GetterTree<CapacityTypeState, RootState> = {
  getCapacityTypes: (state): CapacityTypeCore[] => {
    return state.capacityTypes;
  },
  getCapacityTypeById:
    state =>
    (id: number): CapacityTypeCore => {
      return state.capacityTypes.find(capacityType => capacityType.getId == id);
    }
};

const actions: ActionTree<CapacityTypeState, RootState> = {
  async loadCapacityTypes({ commit }) {
    const capacityTypes: CapacityTypeCore[] =
      await capacityTypesService.methods.getCapacityTypes();
    commit("setCapacityTypes", capacityTypes);
  },
  async postCapacityType({ commit }, capacityType: CapacityTypeCore) {
    const newCapacityType: CapacityTypeCore =
      await capacityTypesService.methods.postCapacityType(capacityType);
    commit("addCapacityType", newCapacityType);
  }
};

export const capacityTypeModuleCore: Module<CapacityTypeState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
