export default {
  namespaced: true,
  state: {
    loadingComponents: []
  },
  getters: {
    getLoadingComponents: state => {
      return state.loadingComponents.length > 0;
    }
  },
  mutations: {
    addComponent(state, component) {
      const index = state.loadingComponents.indexOf(component);
      if (index <= -1) state.loadingComponents.push(component);
    },
    removeComponent(state, component) {
      const index = state.loadingComponents.indexOf(component);
      if (index > -1) state.loadingComponents.splice(index, 1);
    },
    clearLoadingComponents(state) {
      state.loadingComponents = [];
    }
  },
  actions: {
    addLoadingComponent({ commit }, component) {
      commit("addComponent", component);
    },
    removeLoadingComponent({ commit }, component) {
      commit("removeComponent", component);
    },
    clearLoadingComponents({ commit }) {
      commit("clearLoadingComponents");
    }
  }
};
