

































































  import ProjectFormBasicInformation from "../components/new-project/ProjectFormBasicInformation.vue";
  import ProjectFormServices from "../components/new-project/ProjectFormServices.vue";
  import PeoplePermissions from "../components/services/deliveryexperience/people/PeoplePermissions.vue";
  import ProjectCore from "../models/core/ProjectCore";
  import SingleSelectType from "../view-models/SingleSelectType";
  import { getProjectTypeTranslation } from "../_helpers/projectMetadata";

  export default {
    name: "project-form",
    components: {
      ProjectFormBasicInformation,
      ProjectFormServices,
      PeoplePermissions
    },
    data() {
      return {
        projectId: Number(this.$route.params.projectId),
        clientId: undefined,
        firstSection: true,
        creating: !this.$route.params.projectId,
        loadedData: false,
        selectedProjectType: new SingleSelectType({
          key: undefined,
          name: undefined
        })
      };
    },
    async created() {
      this.loadedData = false;
      if (this.$route.params.projectId) {
        const modifyProject: ProjectCore = await this.loadProject(
          this.$route.params.projectId
        );
        this.selectedProjectType = new SingleSelectType({
          key: modifyProject.getProjectType,
          name: this.$tc(
            getProjectTypeTranslation(modifyProject.getProjectType),
            1
          )
        });
      }
      await this.loadCapacityTypes();
      await this.loadCatalog();
      await this.loadPeople();
      await this.loadHiredServicesByClientId(this.getUser.getClientId);
      await this.loadActiveServicesByClientId(this.getUser.getClientId);
      this.loadedData = true;
    },
    methods: {
      goToServicesSection(projId, projectClientId) {
        if (projId) {
          this.projectId = projId;
          this.clientId = projectClientId;
        }
        this.firstSection = false;
      },
      closeProjectForm() {
        this.$router.push("/home");
      },
      async loadPermissionsManagement() {
        this.$bvModal.show("permissions-management-modal-project-form");
      }
    }
  };
