import CommentDx from "./CommentDx";
export interface IRequestDx {
  id?: string;
  projectId: number;
  issue: string;
  status: string;
  type: string;
  description: string;
  author: string;
  date: Date;
  thread: CommentDx[];
  tag?: string;
  notificationsPolicy?: string;
}

export default class RequestDx {
  private id?: string;
  private projectId: number;
  private issue: string;
  private status: string;
  private type: string;
  private description: string;
  private author: string;
  private date: Date;
  private thread: CommentDx[];
  private tag?: string;
  private notificationsPolicy?: string;

  constructor(dto: IRequestDx) {
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get getIssue(): string {
    return this.issue;
  }

  set setIssue(value: string) {
    this.issue = value;
  }

  get getStatus(): string {
    return this.status;
  }

  set setStatus(value: string) {
    this.status = value;
  }

  get getType(): string {
    return this.type;
  }

  set setType(value: string) {
    this.type = value;
  }

  set setDescription(value: string) {
    this.description = value;
  }

  get getDescription() {
    return this.description;
  }

  get getAuthor(): string {
    return this.author;
  }

  set setAuthor(value: string) {
    this.author = value;
  }

  get getDate(): Date {
    return this.date;
  }

  set setDate(value: Date) {
    this.date = value;
  }

  get getThread(): CommentDx[] {
    return this.thread;
  }

  set setThread(value: CommentDx[]) {
    this.thread = value;
  }

  get getTag(): string {
    return this.tag;
  }

  set setTag(value: string) {
    this.tag = value;
  }

  get getNotificationsPolicy(): string {
    return this.notificationsPolicy;
  }

  set setNotificationsPolicy(value: string) {
    this.notificationsPolicy = value;
  }
}
