










































































































































































































































































































































































































































































































































































  import docTypes from "../../../_helpers/docTypes";
  import {
    getTimelineItemIcon,
    getTimelineItemTypeFromSubtype
  } from "../../../_helpers/eventTypesStates";
  import helpers from "../../../_helpers/helperFunctions";
  import methodology from "../../../_helpers/methodology";
  import notifications from "../../../_helpers/notifications";
  import { requestStates } from "../../../_helpers/requestStates";
  import {
    getExtensionIcon,
    servicesExtensions
  } from "../../../_helpers/servicesExtensions";
  import PeopleCore from "../../../models/core/PeopleCore";
  import DocumentDx from "../../../models/deliveryexperience/DocumentDx";
  import EventDx from "../../../models/deliveryexperience/EventDx";
  import NotificationDx from "../../../models/deliveryexperience/NotificationDx";
  import PeopleDx from "../../../models/deliveryexperience/PeopleDx";
  import ReadWrite from "../../../models/deliveryexperience/Permissions/ReadWrite";
  import RequestDx from "../../../models/deliveryexperience/RequestDx";
  import StageDx from "../../../models/deliveryexperience/StageDx";
  import documentService from "../../../services/deliveryexperience/documentService";
  import DocToShow from "../../../view-models/deliveryexperience/DocToShow";
  import PeopleIcon from "../../../view-models/PeopleIcon";
  import CapsuleLabelInput from "../../view_elements/CapsuleLabelInput.vue";
  import ConfirmationModal from "../../view_elements/ConfirmationModal.vue";
  import FilterDropdown from "../../view_elements/FilterDropdown.vue";
  import PeopleDefaultIcon from "../../view_elements/PeopleDefaultIcon.vue";
  import PeopleIcons from "../../view_elements/PeopleIcons.vue";
  import PreviewDocumentModal from "../../view_elements/PreviewDocumentModal.vue";
  import AddDocumentModal from "./docs/AddDocumentModal.vue";
  import MethodologyModal from "./docs/MethodologyModal.vue";

  export default {
    name: servicesExtensions.deliveryExperience.extensions.docs.name,
    components: {
      PeopleDefaultIcon,
      PeopleIcons,
      AddDocumentModal,
      MethodologyModal,
      PreviewDocumentModal,
      ConfirmationModal,
      CapsuleLabelInput,
      FilterDropdown
    },
    props: {
      projectId: Number,
      eventId: String,
      stageId: String,
      requestId: String
    },
    data() {
      return {
        requestStatesHelper: requestStates,
        documentsNameFilter: "",
        showSearchFilter: false,
        methodologyHelper: methodology,
        fieldsNames: [
          {
            label: this.$t("name"),
            key: "name",
            thClass: "doc-field-name",
            tdClass: "doc-field-name text-truncate"
          },
          {
            label: this.$tc("attachments", 1),
            key: "attached",
            thClass: "align-middle table-width-15",
            tdClass: "align-middle table-width-15"
          },
          {
            label: this.$t("author"),
            key: "author",
            thClass: "align-middle table-width-15",
            tdClass: "align-middle table-width-15"
          },
          {
            label: this.$t("readers"),
            key: "readers",
            thClass: "table-width-15 table-padding-readers",
            tdClass: "table-width-15 table-padding-readers"
          },
          {
            label: this.$t("modified"),
            key: "date",
            thClass: "table-width-15",
            tdClass: "table-width-15"
          },
          {
            label: this.$tc("download", 1),
            key: "download",
            thClass: "align-middle",
            tdClass: "align-middle"
          },
          {
            label: "",
            key: "delete",
            thClass: "align-middle",
            tdClass: "align-middle"
          }
        ],
        methodologyFields: [
          {
            label: this.$tc("scope", 1),
            key: "methodology",
            tdClass: "table-width-20"
          },
          {
            label: this.$t("name"),
            key: "name",
            tdClass: "doc-field-name text-truncate"
          },
          {
            label: this.$t("modified"),
            key: "date",
            tdClass: "table-width-15"
          },
          {
            label: "",
            key: "download",
            tdClass: "align-middle table-width-5"
          }
        ],
        docTypes: docTypes,
        downloadingItem: undefined,
        downloadingItemVersions: [],
        addDocumentModalId: "deliveryexperience-docs-adddocument",
        methodologyModalId: "deliveryexperience-docs-methodology",
        draggingover: false,
        displayDropZone: false,
        docToDelete: undefined,
        loading: false,
        methodologySelected: false,
        selectedPhases: [],
        filteredMethodologyDocs: [],
        dateFormat: "DD/MM/YYYY HH:mm:ss",
        text: {
          searchDocument: `${this.$t("search")} ${this.$tc(
            "documentLowCase",
            1
          )}`,
          newDocument: this.$t("newDocument"),
          noDocumentsUploaded: this.$t("noDocumentsUploaded"),
          maxSizeDocs: this.$t("maxSizeDocs"),
          eventDocTitle: this.$t("eventDocuments"),
          stageDocTitle: this.$t("stageDocuments"),
          requestDocTitle: this.$t("requestDocuments"),
          docsTitle: servicesExtensions.deliveryExperience.extensions.docs.name,
          delete: this.$t("delete"),
          projectTimeline: this.$t("projectTimeline"),
          methodology: this.$t("methodology"),
          all: this.$tc("all", 2),
          all1: this.$tc("all", 1),
          wantToConfirmDeleteDoc: this.$t("wantToConfirmDeleteDoc"),
          lackOfPermissionsSection: this.$t("lackOfPermissionsSection"),
          noAttachDocsClosedRequests: this.$t("noAttachDocsClosedRequests"),
          filterPhases: this.$t("filterPhases"),
          phases: this.$tc("phase", 2),
          noArtifactsForScope: this.$t("noArtifactsForScope"),
          thereAreNoDocs: this.$t("thereAreNoDocs"),
          versions: this.$tc("version", 2)
        }
      };
    },
    async mounted() {
      this.$nextTick(function () {
        if (this.$route.path.includes("docs")) {
          document
            .getElementById("docs-container")
            .scrollIntoView({ behavior: "smooth", block: "center" });
        }
      });
      await this.loadDocumentsDxByProjectId(this.projectId);
    },
    computed: {
      itemPreselected(): EventDx | StageDx | RequestDx | undefined {
        if (this.eventId) return this.getEventDx(this.eventId);
        if (this.stageId) return this.getStageDx(this.stageId);
        if (this.requestId) return this.getRequestDx(this.requestId);
        return undefined;
      },
      formattedDocsToShow(): DocToShow[] {
        const result = [];
        for (const document of this.filteredDocsDxForUser) {
          const date = document.getLastModifiedDate
            ? document.getLastModifiedDate
            : document.getCreationDate;
          result.push({
            id: document.getId,
            name: document.getName,
            author: {
              ...this.getFirstLastNameByUsername(document.getAuthor),
              username: document.getAuthor
            },
            readers: this.getPeopleDxUsernames(document.getPeople),
            date: this.$moment(date).format(this.dateFormat),
            hovered: false,
            url: document.getUrl,
            attached: this.getDocumentAttachedItem(document),
            isLink: document.isExternalLink,
            methodology: document.getMethodology
          });
        }
        return result;
      },
      formattedProjectDocsToShow(): DocToShow[] {
        return this.formattedDocsToShow
          .filter(
            doc =>
              !doc.methodology &&
              (this.getUser.isAdmin() ||
                !doc.readers.length ||
                doc.readers.includes(this.getUser.getUsername))
          )
          .sort(
            (a, b) =>
              this.$moment(b.date, this.dateFormat) -
              this.$moment(a.date, this.dateFormat)
          );
      },
      formattedMethodologyDocsToShow(): DocToShow[] {
        return this.formattedDocsToShow
          .filter(doc => doc.methodology)
          .sort(
            (a, b) =>
              a.methodology.scope - b.methodology.scope ||
              this.$moment(b.date, this.dateFormat) -
                this.$moment(a.date, this.dateFormat)
          );
      },
      // Gets all DocumentDx that can be seen by the logged user,
      // filtering by event, stage or project selected.
      filteredDocsDxForUser(): DocumentDx[] {
        let result: DocumentDx[] = [];
        let documents: DocumentDx[] = [];
        let isItRequest: boolean = false;

        if (this.eventId) {
          documents = this.getDocumentsDxByEventId(this.eventId);
        } else if (this.stageId) {
          documents = this.getDocumentsDxByStageId(this.stageId);
        } else if (this.requestId) {
          isItRequest = true;
          documents = this.getDocumentsDxByRequestId(this.requestId);
        } else {
          // All documents of the project
          documents = this.getDocumentsDxByProjectId(this.projectId);
        }

        // For members added
        for (const document of documents) {
          if (document != undefined) {
            const peopleDocument = document.getPeople;
            if (!peopleDocument.length || this.getUser.isAdmin()) {
              result.push(document);
            } else {
              for (const personDxId of document.getPeople) {
                const personDx: PeopleDx = this.getPeopleDxById(personDxId);
                // Check if it was not deleted from project
                if (personDx) {
                  // If the logged user is a reader or the author of the doc,
                  // or if the doc is attached to a request
                  if (
                    personDx.getUsername == this.getUser.getUsername ||
                    document.getAuthor == this.getUser.getUsername ||
                    isItRequest
                  ) {
                    result.push(document);
                    break;
                  }
                }
              }
            }
          }
        }
        return result.filter(
          doc =>
            !(
              this.documentsNameFilter &&
              !doc.getName
                .toLowerCase()
                .includes(this.documentsNameFilter.toLowerCase())
            )
        );
      },
      filteredMethodologyDocsDxForUser(): DocumentDx[] {
        return this.filteredDocsDxForUser
          .filter(doc => doc.methodology)
          .sort(
            (a, b) =>
              a.methodology.scope - b.methodology.scope ||
              this.$moment(b.date, this.dateFormat) -
                this.$moment(a.date, this.dateFormat)
          );
      },
      permissionsDocs(): ReadWrite {
        return this.getUserPermissions.docs;
      },
      permissionsRequests(): ReadWrite {
        return this.getUserPermissions.requests;
      },
      permissionsEvents(): ReadWrite {
        return this.getUserPermissions.events;
      },
      permissionsStages(): ReadWrite {
        return this.getUserPermissions.stages;
      },
      getMethodologyPhasesNames(): string[] {
        let result = [];
        for (const phase of methodology.phasesAndScopes) {
          result.push(this.$t(phase.i18n));
        }
        return result;
      },
      getExtensionIcon(): NodeRequire {
        return getExtensionIcon(
          servicesExtensions.deliveryExperience.extensions.docs.name
        );
      }
    },
    methods: {
      openSearchFilter() {
        this.showSearchFilter = true;
        let searchCapsule = <HTMLInputElement>(
          document.getElementById("input_search-docs-input")
        );

        this.$nextTick(() => searchCapsule.focus());
      },
      getTimelineItemTypeFromSubtype(type: string): string {
        return getTimelineItemTypeFromSubtype(type);
      },
      getTimelineItemIcon(
        timelineItemType: string,
        color?: string
      ): NodeRequire {
        return getTimelineItemIcon(timelineItemType, color);
      },
      getPeopleIconByUsername(username: string): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(
          this.getPeopleByUsername(username)
        );
      },
      getPeopleIconByUsernames(peopleDxUsernames: string[]): PeopleIcon[] {
        return this.getPeopleByUsernamesList(peopleDxUsernames).map(
          personCore => PeopleIcon.newPeopleIconFromCore(personCore)
        );
      },
      getDocumentAttachedItem(document: DocumentDx): {
        type: string;
        name: string;
        id: string;
      } {
        const attachedObj = { type: "project", name: undefined, id: undefined };
        if (document.getEventId) {
          const attached: EventDx = this.getEventDx(document.getEventId);
          attachedObj.type = attached.getEventType;
          attachedObj.name = attached.getName;
          attachedObj.id = attached.getId;
        } else if (document.getStageId) {
          const attached: StageDx = this.getStageDx(document.getStageId);
          attachedObj.type = "stage";
          attachedObj.name = attached.getName;
          attachedObj.id = attached.getId;
        } else if (document.getRequestId) {
          const attached: RequestDx = this.getRequestDx(document.getRequestId);
          attachedObj.type = attached.getType;
          attachedObj.name = attached.getIssue;
          attachedObj.id = attached.getId;
        }
        return attachedObj;
      },
      getPeopleDxUsernames(peopleDxIds: string[]): string[] {
        let result: string[] = [];
        for (const peopleDxId of peopleDxIds) {
          const peopleDx: PeopleDx = this.getPeopleDxById(peopleDxId);
          // May be undefined if it has been removed from the project people DX
          if (peopleDx != undefined) {
            result.push(peopleDx.getUsername);
          }
        }
        return result;
      },
      getFirstLastNameByUsername(username: string): {
        firstName: string;
        lastName: string;
      } {
        const personCore: PeopleCore = this.getPeopleByUsername(username);
        return {
          firstName: personCore.getFirstName,
          lastName: personCore.getLastName
        };
      },
      getAuthorFullName(firstName, lastName): string {
        return helpers.getUserFullName(firstName, lastName);
      },
      tryDownloadUniqueVersion: async function (doc) {
        this.downloadingItemVersions =
          await documentService.methods.getDocumentVersions(doc.id);
        if (this.downloadingItemVersions.length > 1) {
          this.$refs[`methodologyVersions${doc.id}`].show();
        } else {
          await this.downloadDocument(doc, this.downloadingItemVersions[0]);
        }
      },
      downloadDocument: async function (doc, version) {
        this.downloadingItem = doc;
        const download = await documentService.methods.downloadDocumentFromBlob(
          doc.url,
          version
        );
        const url = window.URL.createObjectURL(
          new Blob([download.blob], { type: download.type })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", doc.name);
        document.body.appendChild(link);
        link.click();
        this.downloadingItem = undefined;
      },
      handleSelect: function (ev) {
        this.openAddDocumentModal();
        this.$nextTick(() => this.$refs.addDocumentModal.transferSelect(ev));
      },
      handleDrop: function (ev) {
        this.openAddDocumentModal();
        this.$nextTick(() => this.$refs.addDocumentModal.transferDrop(ev));
        this.draggingover = false;
        this.displayDropZone = false;
      },
      displayDocsDropZone() {
        if (this.permissionsDocs.write) this.displayDropZone = true;
      },
      getFileIconByExtension: function (name: string): NodeRequire {
        return docTypes.getFileIconByExtension(name);
      },
      openAddDocumentModal() {
        this.$bvModal.show(this.addDocumentModalId);
      },
      openMethodologyModal() {
        this.$bvModal.show(this.methodologyModalId);
      },
      openMethodologyDiagram() {
        this.$root.$emit("openModalWithMethodologyDiagram");
      },
      showTrash(item) {
        item.hovered = true;
      },
      hideTrash(item) {
        item.hovered = false;
      },
      setDocToDelete(item) {
        this.docToDelete = item;
      },
      async deleteDoc() {
        this.loading = true;
        this.sendNotificationDx();
        await this.deleteDocumentDx(this.docToDelete.id);
        this.$nextTick(async () => {
          await this.$bvModal.hide("modal-confirmation-delete-doc");
        });
      },
      async sendNotificationDx() {
        const notificationDx = new NotificationDx({
          itemName: this.itemPreselected
            ? this.itemPreselected.getName
            : "none",
          projectId: this.projectId,
          itemType: this.getItemType(),
          itemId: this.itemPreselected ? this.itemPreselected.getId : "none",
          recipients: this.getRecipients(),
          action: this.itemPreselected ? "docDeleted" : "docDeletedProject",
          author: this.getUser.getUsername
        });
        if (notificationDx.recipients.length)
          await this.postNotificationDx(notificationDx);
      },
      getItemType(): string | undefined {
        if (this.itemPreselected) {
          if (this.eventId) return this.itemPreselected.getEventType;
          if (this.stageId) return "stage";
          if (this.requestId) return this.itemPreselected.getType;
        } else return "project";
        return undefined;
      },
      getRecipients(): string[] {
        if (!this.itemPreselected) {
          const recipients = this.getPeopleDxByProjectId(this.projectId).map(
            p => p.getUsername
          );
          const index = recipients.indexOf(this.getUser.getUsername);
          if (index >= 0) recipients.splice(index, 1);
          return recipients;
        } else {
          let item;
          if (this.eventId) item = this.getEventDx(this.eventId);
          if (this.stageId) item = this.getStageDx(this.stageId);
          if (this.requestId) item = this.getRequestDx(this.requestId);
          return notifications.getPeopleForRecipients(
            item.getNotificationsPolicy,
            this.getPeopleDxByProjectId(this.projectId),
            item.getAuthor,
            this.getUser.getUsername,
            item.getId
          );
        }
      },
      onDeleteConfirmationHide() {
        this.docToDelete = undefined;
        this.loading = false;
      },
      launchNotPreviewableNotification() {
        const alertMessage = {
          text: this.$t("docPreviewMessage"),
          time: 5,
          type: "info"
        };
        this.setMessage(alertMessage);
      },
      getLinkIcon(): NodeRequire {
        return docTypes.getDocIcon("link.png");
      },
      filterPhases(selectedPhases) {
        this.selectedPhases = selectedPhases;
        this.filteredMethodologyDocs = [];
        for (const phase of methodology.phasesAndScopes) {
          if (this.selectedPhases.find(e => e == this.$t(phase.i18n))) {
            for (const scope of phase.scopes) {
              const found = this.formattedMethodologyDocsToShow.filter(
                e => e.methodology.scope == scope.id
              );
              if (found) this.filteredMethodologyDocs.push(...found);
            }
          }
        }
      },
      goToTimelineItem(id, type) {
        this.$root.$emit("goToTimelineItem", id, type);
      }
    }
  };
