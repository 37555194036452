

























































































































  import docTypes from "../../../../_helpers/docTypes";
  import PeopleIcon from "../../../../view-models/PeopleIcon";
  import PeopleInfo from "../../../view_elements/PeopleInfo.vue";

  export default {
    name: "ParticipantsDocs",
    components: { PeopleInfo },
    props: {
      event: Object,
      participants: Array,
      documents: Array
    },
    data() {
      return {
        text: {
          newReport: this.$t("newReport"),
          participantsAndDocs: this.$t("participantsAndDocs"),
          participants: this.$t("participants"),
          documents: this.$tc("document", 2),
          thereAreNoParticipants: this.$t("thereAreNoParticipants"),
          thereAreNoDocs: this.$t("thereAreNoDocs")
        },
        fieldsName: [
          {
            label: "",
            tdClass: "align-middle w-10",
            key: "extension"
          },
          {
            label: "",
            tdClass: "align-middle w-85",
            key: "name"
          },
          {
            label: "",
            tdClass: "align-middle w-5",
            key: "remove"
          }
        ],
        documentsList: this.documents, // To avoid mutating prop
        participantsList: this.participants, // To avoid mutating prop
        participantIndexHovered: null,
        key: 0
      };
    },
    computed: {
      participantsFiltered(): PeopleIcon[] {
        return this.participantsList.map(people => {
          return PeopleIcon.newPeopleIconFromDx(people);
        });
      }
    },
    methods: {
      onHover: function (item) {
        item.hovered = true;
      },
      onUnHover: function (item) {
        item.hovered = false;
      },
      removeParticipant(participantUsername) {
        for (const participant in this.participantsFiltered) {
          if (
            this.participantsFiltered[participant].getUsername ==
            participantUsername
          ) {
            this.participantsFiltered.splice(participant, 1);
            this.participantsList.splice(participant, 1);
            break;
          }
        }
        this.key++;
      },
      removeDoc(item) {
        for (const document in this.documentsList) {
          if (this.documentsList[document].name == item.name) {
            this.documentsList.splice(document, 1);
            break;
          }
        }
        this.key++;
      },
      getFileIconByExtension(name): NodeRequire {
        return docTypes.getFileIconByExtension(name);
      }
    }
  };
