import axios from "axios";
import { i18n } from "../plugins/i18";
import router from "../router/index";
import { store } from "../store";

export default {
  created: function () {
    this.setAuthHeader();
  },
  methods: {
    getEndpointAsync: async function (endpoint): Promise<any> {
      return axios
        .get(endpoint)
        .then(response => this.handleResponse(response))
        .catch(error => this.handleResponse(error.response));
    },
    getBlobEndpointAsync: async function (endpoint): Promise<any> {
      return axios
        .get(endpoint, {
          transformRequest: [
            (data, headers) => {
              delete headers.authorization;
              return data;
            }
          ],
          responseType: "blob"
        })
        .then(response => this.handleResponse(response))
        .catch(error => this.handleResponse(error.response));
    },
    postEndpointAsync: async function (endpoint, data): Promise<any> {
      return axios
        .post(endpoint, data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => this.handleResponse(response))
        .catch(error => this.handleResponse(error.response));
    },
    postEndPointWithoutDataAsync: async function (endpoint): Promise<any> {
      return axios
        .post(endpoint, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => this.handleResponse(response))
        .catch(error => this.handleResponse(error.response));
    },
    postEndpointFormDataAsync: async function (
      endpoint,
      formData
    ): Promise<any> {
      return axios
        .postForm(endpoint, formData)
        .then(response => this.handleResponse(response))
        .catch(error => this.handleResponse(error.response));
    },
    putEndpointAsync: async function (endpoint, data): Promise<any> {
      return axios
        .put(endpoint, data, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => this.handleResponse(response))
        .catch(error => this.handleResponse(error.response));
    },
    deleteEndpointAsync: async function (endpoint): Promise<any> {
      return axios
        .delete(endpoint, {
          headers: {
            "Content-Type": "application/json"
          }
        })
        .then(response => this.handleResponse(response))
        .catch(error => this.handleResponse(error.response));
    },
    setAuthHeader: function () {
      axios.defaults.headers.common["Authorization"];
    },
    handleResponse: function (response): any {
      if (response) {
        if (response.status == 200) {
          return response.data;
        } else if (response.status == 201) {
          return response.data;
        } else if (response.status == 403) {
          router.push({ name: "errorPage", params: { code: "403" } });
          throw "Error 403 in request";
        } else if (response.status == 404) {
          router.push({ name: "errorPage", params: { code: "404" } });
          throw "Error 404 in request";
        } else if (
          (response.status == 400 ||
            response.status == 401 ||
            response.status == 409 ||
            response.status == 404 ||
            response.status == 415 ||
            response.status == 500) &&
          response.config.method != "get"
        ) {
          const message = {
            text: i18n.t("messageAlertError"),
            time: 3,
            type: "error"
          };
          store.dispatch("message/setMessage", message);
          throw "Error " + response.status + " in request";
        } else {
          return response;
        }
      } else {
        router.push({ name: "errorPage", params: { code: "500" } });
        throw "Error 500 in request";
      }
    }
  }
};
