import Service from "./ServiceCore";

export interface ICategory {
  id?: number;
  name: string;
  icon: string;
  services?: Service[];
}

export default class Category {
  private id?: number;
  private name: string;
  private icon: string;
  private services?: Service[];

  constructor(dto: ICategory) {
    Object.assign(this, dto);
  }

  get getId(): number {
    return this.id;
  }

  set setId(id: number) {
    this.id = id;
  }

  get getName(): string {
    return this.name;
  }

  set setName(name: string) {
    this.name = name;
  }

  get getIcon(): string {
    return this.icon;
  }

  set setIcon(icon: string) {
    this.icon = icon;
  }

  get getServices(): Service[] {
    return this.services;
  }
}
