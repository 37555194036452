



























  import storeMixins from "./mixins/storeMixins";
  import authService from "./authentication/AuthServiceInst";
  import MessageAlert from "./components/view_elements/MessageAlert.vue";
  import PageFooter from "./components/view_elements/PageFooter.vue";
  import PageHeader from "./components/view_elements/PageHeader.vue";

  export default {
    name: "App",
    mixins: [storeMixins],
    components: {
      PageHeader,
      PageFooter,
      MessageAlert
    },
    data() {
      return {
        expandNavbar: false,
        hideHeaderFooter: false,
        inServicePage: false,
        loadedData: false
      };
    },
    computed: {
      isAuthenticated() {
        return authService.get().isAuthenticated();
      }
    },
    methods: {
      reloadLang() {
        if (
          this.$i18n &&
          localStorage.getItem("sogetiHubLanguage") != "undefined"
        ) {
          if (this.$i18n.locale != localStorage.getItem("sogetiHubLanguage")) {
            this.$router.go(0);
          }
        }
      }
    },
    async created() {
      this.addLoadingComponent(this.$options.name);
      this.$i18n.locale = this.getUser.getLanguage;
      this.setLanguage(this.getUser.getLanguage);
      this.$moment.locale(this.$i18n.locale == "EN" ? "en_gb" : "es");
      localStorage.setItem("sogetiHubLanguage", this.getUser.getLanguage);
      this.removeLoadingComponent(this.$options.name);
      this.loadedData = true;
    },
    mounted() {
      this.$root.$on(
        "hideHeaderFooter",
        value => (this.hideHeaderFooter = value)
      );
      this.$root.$on("inServicePage", value => (this.inServicePage = value));
      this.$root.$on("expandNavbar", value => (this.expandNavbar = value));
    }
  };
