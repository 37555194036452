import VersionDx from "../../models/deliveryexperience/VersionDx";
import service from "../service";
import baseEndpoint from "./serviceDx";

const endpoint = baseEndpoint + "/Version";

export default {
  components: { service: service },
  methods: {
    async getVersions(): Promise<VersionDx[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(version => new VersionDx(version));
    },

    async getVersion(id: string): Promise<VersionDx> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new VersionDx(response);
    },

    async getVersionsByProject(projectId: number): Promise<VersionDx[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/project/${projectId}`
      );
      return response.map(version => new VersionDx(version));
    },

    async getLatestProjectVersion(projectId: number): Promise<string> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/latestVersion/${projectId}`
      );
      return response;
    },
    async postVersion(projectId: number): Promise<VersionDx> {
      const response = await service.methods.postEndPointWithoutDataAsync(
        `${endpoint}/${projectId}`
      );
      return new VersionDx(response);
    },
    async deleteVersion(id: string): Promise<any> {
      return await service.methods.deleteEndpointAsync(`${endpoint}/${id}`);
    }
  }
};
