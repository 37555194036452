import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import ClientCore from "../../../models/core/ClientCore";
import ClientServiceCore from "../../../models/core/client/ClientServiceCore";
import clientService from "../../../services/core/clientService";
import { RootState } from "../../index";

const namespaced = true;

interface ClientState {
  clients: ClientCore[];
  clientServices: ClientServiceCore[];
}

const state: ClientState = {
  clients: [],
  clientServices: []
};

const mutations: MutationTree<ClientState> = {
  setClients(state, clients: ClientCore[]) {
    state.clients = [];
    state.clients = clients;
  },
  addClient(state, newClient: ClientCore) {
    if (
      state.clients.findIndex(client => client.getId == newClient.getId) == -1
    ) {
      state.clients.push(newClient);
    }
  },
  deleteClient(state, id: number) {
    const index = state.clients.findIndex(client => client.getId == id);
    state.clients.splice(index, 1);
  },
  updateClient(state, client: ClientCore) {
    const index = state.clients.findIndex(c => c.getId === client.getId);
    if (index != -1) {
      state.clients.splice(index, 1, client);
    }
  },
  addClientInService(state, clientServiceCore: ClientServiceCore) {
    if (
      state.clientServices.findIndex(
        clientService => clientService == clientServiceCore
      ) == -1
    ) {
      state.clientServices.push(clientServiceCore);
    }
  },
  deleteClientInService(state, clientServiceCore: ClientServiceCore) {
    const index = state.clientServices.findIndex(
      clientService => clientService == clientServiceCore
    );
    state.clientServices.splice(index, 1);
  }
};

const getters: GetterTree<ClientState, RootState> = {
  getClients: (state): ClientCore[] => {
    return state.clients;
  },
  getClientById:
    state =>
    (id: number): ClientCore => {
      return state.clients.find(client => client.getId == id);
    }
};

const actions: ActionTree<ClientState, RootState> = {
  async loadClients({ commit }) {
    const clients: ClientCore[] = await clientService.methods.getClients();
    commit("setClients", clients);
  },
  async postClient({ commit }, client: ClientCore) {
    const newClient: ClientCore = await clientService.methods.postClient(
      client
    );
    commit("addClient", newClient);
  },
  async putClient({ commit }, client: ClientCore) {
    const updatedClient: ClientCore = await clientService.methods.putClient(
      client
    );
    commit("updateClient", updatedClient);
  },
  async deleteClient({ commit }, id: number) {
    await clientService.methods.deleteClient(id);
    commit("deleteClient", id);
  },
  async postClientService({ commit }, clientServiceCore: ClientServiceCore) {
    await clientService.methods.postClientService(clientServiceCore);
    commit("addClientInService", clientServiceCore);
  },
  async deleteClientService({ commit }, clientServiceCore: ClientServiceCore) {
    await clientService.methods.deleteClientService(clientServiceCore);
    commit("deleteClientInService", clientServiceCore);
  }
};

export const clientModuleCore: Module<ClientState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
