import ProjectDx from "../../models/deliveryexperience/ProjectDx";
import service from "../service";
import baseEndpoint from "./serviceDx";

const endpoint = baseEndpoint + "/Project";

export default {
  components: { service: service },
  methods: {
    async getProjects(): Promise<ProjectDx[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(project => new ProjectDx(project));
    },
    async getProject(id: number): Promise<ProjectDx> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new ProjectDx(response);
    },
    async putProject(modifiedProject: ProjectDx): Promise<ProjectDx> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${modifiedProject.getId}`,
        modifiedProject
      );
      return new ProjectDx(response);
    },
    async postProject(project: ProjectDx): Promise<ProjectDx> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        project
      );
      return new ProjectDx(response);
    },
    async deleteProject(id: number): Promise<any> {
      return await service.methods.deleteEndpointAsync(`${endpoint}/${id}`);
    }
  }
};
