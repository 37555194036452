


























































  import { getExtensionIcon } from "../../_helpers/servicesExtensions";
  import ServiceCore from "../../models/core/ServiceCore";
  import PeopleInfo from "./PeopleInfo.vue";

  export default {
    name: "ShowAllItems",
    components: { PeopleInfo },
    props: {
      comeFrom: String,
      id: String,
      service: ServiceCore,
      extensions: Array,
      people: Array, // PeopleIcon[]
      extraItems: Number
    },
    methods: {
      getExtensionIcon(extensionName: string): NodeRequire {
        return getExtensionIcon(extensionName);
      }
    }
  };
