

















  import { getExtensionIcon } from "../../_helpers/servicesExtensions";
  import ExtensionCore from "../../models/core/ExtensionCore";

  export default {
    data() {
      return {
        hire: this.hired
      };
    },
    props: {
      id: Number,
      serviceId: Number,
      hired: Boolean
    },
    computed: {
      getExtensionIcon(): NodeRequire {
        return getExtensionIcon(this.extension().getName);
      }
    },
    methods: {
      extension(): ExtensionCore | null {
        for (const ext of this.getExtensions) {
          if (ext.getId == this.id) {
            return ext;
          }
        }
        return null;
      }
    }
  };
