























  import { getExtensionIcon } from "../../_helpers/servicesExtensions";
  import ExtensionCore from "../../models/core/ExtensionCore";
  import ServiceCore from "../../models/core/ServiceCore";
  import ShowAllItems from "./ShowAllItems.vue";

  export default {
    name: "ExtensionsIcons",
    components: { ShowAllItems },
    props: {
      id: Number,
      extensions: Array,
      service: ServiceCore
    },
    computed: {
      shownExtensions: function (): {
        result: any[];
        expandable: boolean;
        extraExtensions: number;
      } {
        let result = [];
        let index = 0;
        let expandable = false;
        let extraExtensions = 0;
        while (index < 3 && index < this.extensions.length) {
          result.push(this.extensions[index]);
          ++index;
        }
        if (this.extensions.length > 3) {
          expandable = true;
          extraExtensions = this.extensions.length - 3;
        }
        return {
          result: result,
          expandable: expandable,
          extraExtensions: extraExtensions
        };
      }
    },
    methods: {
      getExtensionIcon(extension: ExtensionCore): NodeRequire {
        return getExtensionIcon(extension.getName);
      }
    }
  };
