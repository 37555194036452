




















































  import { dragscroll } from "vue-dragscroll";
  import { projectStates } from "../../../_helpers/projectMetadata";
  import {
    getServiceByName,
    getServiceIcon
  } from "../../../_helpers/servicesExtensions";
  import ExtensionCore from "../../../models/core/ExtensionCore";
  import { default as ServiceCore } from "../../../models/core/ServiceCore";
  import utils from "../../../utils/utils";
  import ExtensionsIcons from "../../view_elements/ExtensionsIcons.vue";

  export default {
    name: "ProjectCollapseServices",
    directives: {
      dragscroll
    },
    props: {
      id: Number, // The projectId selected
      state: String,
      servicesOfProject: Array, // The array of ServiceCore. Services of project selected.
      showCollapse: Boolean
    },
    components: { ExtensionsIcons },
    data() {
      return {
        text: {
          services: this.$tc("service", 2),
          extensions: this.$tc("extension", 2)
        }
      };
    },
    computed: {
      // Gets all active services core of the project selected
      activeServicesCore: function (): ServiceCore[] {
        return this.getActiveServicesByProjectId(this.id);
      },

      showCollapseF: {
        get(): boolean {
          return this.showCollapse && this.state == projectStates.active.name;
        },
        set() {}
      }
    },
    methods: {
      extensionsInService(serviceId: number): ExtensionCore[] {
        return this.getExtensionServiceByServiceId(serviceId);
      },
      // Wrapped in a method at component scope to make it available for the template
      getServiceByNameHelper(serviceName: string): any {
        return getServiceByName(serviceName);
      },
      getServiceIcon(serviceName: string): NodeRequire {
        return getServiceIcon(serviceName);
      },
      getServiceHref(serviceUrl, projectId): string {
        let href;
        if (utils.isValidUrl(serviceUrl)) {
          href = serviceUrl;
        } else if (serviceUrl == "default") {
          href = this.$router.resolve("/under-construction").href;
        } else {
          // Redirect to the appropriate service
          href = this.$router.resolve(`/${serviceUrl}/${projectId}`).href;
        }
        return href;
      }
    }
  };
