var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:_vm.id,attrs:{"id":_vm.id,"size":"xl","hide-header":true,"hide-footer":true,"centered":""},on:{"ok":_vm.send,"hidden":_vm.resetValues},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var cancel = ref.cancel;
var ok = ref.ok;
return [_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{staticClass:"modal-request-col modal-form__divider-line"},[(_vm.requestIdToEdit)?_c('b-row',[_c('b-col',{staticStyle:{"margin-left":"-15px"}},[_c('h6',{staticClass:"title-request-text"},[_vm._v(_vm._s(_vm.text.editRequest))])]),_c('b-col',{staticStyle:{"margin-right":"-15px"}},[_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"flex-end","margin-top":"-2px"}},[_c('div',{staticClass:"status-circle mr-1",class:("status-" + (_vm.getRequestDx(
                    _vm.requestIdToEdit
                  ).getStatus.toLowerCase()))}),_c('div',{staticStyle:{"margin-right":"1rem"}},[_vm._v(" "+_vm._s(_vm.text.requestStatus[ _vm.getRequestDx(_vm.requestIdToEdit).getStatus.toLowerCase() ])+" ")])])])],1):_c('b-row',[_c('b-col',{staticStyle:{"margin-left":"-15px"}},[_c('h6',{staticClass:"title-request-text"},[_vm._v(_vm._s(_vm.text.newRequest))])]),_c('b-col',{staticStyle:{"margin-right":"-15px"}},[_c('div',{staticClass:"d-flex",staticStyle:{"justify-content":"flex-end","margin-top":"-2px"}},[_c('div',{staticClass:"status-circle status-open mr-1",staticStyle:{"margin-top":"7px"}}),_c('div',{staticStyle:{"margin-right":"1rem"}},[_vm._v(" "+_vm._s(_vm.text.requestStatus.open)+" ")])])])],1),_c('b-row',{staticClass:"form-row-padding"},[_c('b-col',[_c('CapsuleLabelInput',{attrs:{"label":_vm.text.issue,"placeholder":_vm.text.requestIssue,"input":_vm.issue,"required":{ status: true, style: _vm.requiredFields.issue }},on:{"setValue":function (value) { return (_vm.issue = value); }}})],1)],1),_c('b-row',{staticClass:"form-row-padding"},[_c('b-col',[_c('CapsuleMultiselect',{attrs:{"label":_vm.text.type,"placeholder":_vm.text.selectRequest,"input":_vm.type,"options":_vm.types,"required":{ status: true, style: _vm.requiredFields.type },"disabled":_vm.defaultRequestType.key ==
                  _vm.eventTypes.REQUEST.events.cspRequest.type,"type":"singleSelect"},on:{"setValue":function (value) { return (_vm.type = value); }}})],1),_c('b-col',[_c('CapsuleInputDate',{attrs:{"label":_vm.text.date,"date":_vm.date,"datesFrom":_vm.datesFrom,"datesTo":_vm.datesTo},on:{"setValue":function (value) { return (_vm.date = value); }}})],1)],1),_c('b-row',{staticClass:"form-row-padding"},[_c('b-col',[_c('CapsuleMultiselect',{attrs:{"label":_vm.text.author,"options":_vm.peopleToShowDropdown,"input":_vm.author,"placeholder":_vm.text.searchAMember,"maxHeight":230,"type":"people"},on:{"setValue":function (value) { return (_vm.author = value); }}})],1)],1),_c('b-row',{staticClass:"form-row-padding"},[_c('b-col',{attrs:{"cols":"6"}},[_c('CapsuleMultiselect',{attrs:{"label":_vm.text.tag,"input":_vm.selectedTag,"options":_vm.projectTagsDx.tags,"placeholder":_vm.selectedTag == null ? _vm.$t('selectOrAddTag') : _vm.selectedTag,"taggable":true,"tagPlaceholder":_vm.text.dropdownAdd},on:{"tag":_vm.addTag,"setValue":function (value) { return (_vm.selectedTag = value); }}})],1)],1),_c('b-row',{staticClass:"form-row-padding"},[_c('b-col',[_c('label',{staticClass:"input-label white"},[_vm._v(" "+_vm._s(_vm.text.description)+" ")]),_c('vue-editor',{staticClass:"vue-editor",model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1),_c('b-col',{staticClass:"modal-request-col",scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return undefined},proxy:true}],null,true)},[_c('p',{staticClass:"attachments-text"},[_vm._v(" "+_vm._s(_vm.text.attachments)+" ")]),_c('DocsAttach',{staticStyle:{"height":"80%","padding-bottom":"3rem"},attrs:{"action":_vm.requestIdToEdit ? 'edit' : 'create',"projectId":_vm.projectId,"requestId":_vm.requestIdToEdit
                ? _vm.getRequestDx(_vm.requestIdToEdit).getId
                : _vm.requestId,"saveDocsAttached":_vm.saveDocsAttached},on:{"docsUploaded":_vm.docsUploadedHandler}}),_c('b-row',{staticClass:"form-row-padding",staticStyle:{"display":"flex","align-items":"flex-end"}},[_c('b-col',{attrs:{"cols":"5"}},[_c('label',[_vm._v(" "+_vm._s(_vm.text.notifyTo)+" ")])]),_c('b-col',{attrs:{"cols":"7"}},[_c('CapsuleMultiselect',{attrs:{"input":_vm.notificationsPolicy,"options":_vm.getNotificationsPolicies(),"placeholder":_vm.text.selectNotificationsPolicy,"type":"singleSelect"},on:{"setValue":function (value) { return (_vm.notificationsPolicy = value); }}})],1)],1),_c('b-row',{staticClass:"new-request-buttons-row"},[(_vm.requestIdToEdit && _vm.getUser.isSogeti())?_c('b-button',{staticClass:"button-small button-delete button-disabled",attrs:{"pill":"","disabled":_vm.loading.length > 0},on:{"click":function($event){return _vm.deleteRequest()}}},[(_vm.loading != 'delete')?_c('p',[_vm._v(_vm._s(_vm.text.delete))]):_c('b-spinner',{staticClass:"button-spinner"})],1):_vm._e(),_c('b-button',{staticClass:"button-small button-cancel button-disabled",attrs:{"pill":"","disabled":_vm.loading.length > 0},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.text.cancel)+" ")]),_c('b-button',{staticClass:"button-small button-disabled",staticStyle:{"width":"100px"},attrs:{"pill":"","disabled":_vm.issue.length == 0 ||
                _vm.type.key == undefined ||
                _vm.date == undefined ||
                _vm.loading.length > 0},on:{"click":ok}},[(_vm.loading != 'send')?_c('p',[_vm._v(_vm._s(_vm.text.send))]):_c('b-spinner',{staticClass:"button-spinner"})],1),_c('div',{staticClass:"trigger-required-fields",style:({
                display:
                  _vm.issue.length == 0 || _vm.type.key == undefined
                    ? 'block'
                    : 'none'
              }),on:{"click":_vm.activeRequiredFields}})],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }