import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import CustomerCSP from "../../../models/csp/CustomerCSP";
import customerService from "../../../services/csp/customerService";
import { RootState } from "../../index";

const namespaced = true;

interface CustomerState {
  customers: CustomerCSP[];
}

const state: CustomerState = {
  customers: []
};

const mutations: MutationTree<CustomerState> = {
  setCustomers(state, customers: CustomerCSP[]) {
    state.customers = customers;
  }
};

const getters: GetterTree<CustomerState, RootState> = {
  getCustomersCSP: (state): CustomerCSP[] => {
    return state.customers;
  }
};

const actions: ActionTree<CustomerState, RootState> = {
  async loadCustomersCSP({ commit }) {
    const customers: CustomerCSP[] =
      await customerService.methods.getCustomersCSP();
    commit("setCustomers", customers);
  }
};

export const customerModuleCSP: Module<CustomerState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
