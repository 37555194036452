export interface INotificaionInfo {
  id: string;
  itemName: string;
  projectId: number;
  itemType: string;
  itemId: string;
  timestamp: Date;
  read: boolean;
  action: string;
  author: string;
}

export default class NotificationInfo {
  id: string;
  itemName: string;
  projectId: number;
  itemType: string;
  itemId: string;
  timestamp: Date;
  read: boolean;
  action: string;
  author: string; // Logged user's username (email)

  constructor(dto: INotificaionInfo) {
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }

  get getItemName(): string {
    return this.itemName;
  }

  set setItemName(value: string) {
    this.itemName = value;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get getItemType(): string {
    return this.itemType;
  }

  set setItemType(value: string) {
    this.itemType = value;
  }

  get getItemId(): string {
    return this.itemId;
  }

  set setItemId(value: string) {
    this.itemId = value;
  }

  get getTimeStamp() {
    return this.timestamp;
  }

  set setTimeStamp(value: Date) {
    this.timestamp = value;
  }

  get getRead() {
    return this.read;
  }

  set setRead(value: boolean) {
    this.read = value;
  }

  get getAction(): string {
    return this.action;
  }

  set setAction(value: string) {
    this.action = value;
  }

  get getAuthor(): string {
    return this.author;
  }

  set setAuthor(value: string) {
    this.author = value;
  }
}
