import axios from "axios";
import baseEndpoint from "./servicePartnerCenter";

export default {
  methods: {
    createCart: async function (tenantId, body) {
      const data = body;
      const endpoint = baseEndpoint + "/customers/" + tenantId + "/carts";
      return await axios
        .post(endpoint, data)
        .then(response => {
          return response.data.id;
        })
        .catch(error => {
          return error;
        });
    },
    purchaseOrder: async function (tenantId, cartId) {
      const endpoint =
        baseEndpoint +
        "/customers/" +
        tenantId +
        "/carts/" +
        cartId +
        "/checkout";
      return await axios
        .post(endpoint)
        .then(response => {
          return response.data;
        })
        .catch(error => {
          return error;
        });
    }
  }
};
