export interface IExtensionService {
  extensionId: number;
  serviceId: number;
}

export default class ExtensionService {
  private extensionId: number;
  private serviceId: number;

  constructor(dto: IExtensionService) {
    Object.assign(this, dto);
  }

  get getExtensionId(): number {
    return this.extensionId;
  }

  set setExtensionId(extensionId: number) {
    this.extensionId = extensionId;
  }

  get getServiceId(): number {
    return this.serviceId;
  }

  set setServiceId(serviceId: number) {
    this.serviceId = serviceId;
  }
}
