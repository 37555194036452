var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"people-default-icon-circle",style:({
    width: _vm.size + 'px',
    height: _vm.size + 'px',
    background: _vm.isExternalPeople
      ? ("url(" + (require('../../assets/icons/placeholders/user.png')) + ")")
      : _vm.colorsArray[_vm.colorIndex],
    'line-height': _vm.size + 'px',
    'font-size': 10 + (_vm.size - 20) / 3 + 'px'
  })},[_vm._v(" "+_vm._s(!_vm.isExternalPeople ? _vm.displayName : "")+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }