import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { IBaseLineDx } from "../../../models/deliveryexperience/BaseLineDx";
import StageDx from "../../../models/deliveryexperience/StageDx";
import stageServiceDx from "../../../services/deliveryexperience/stageService";
import { RootState } from "../../index";

interface IStageState {
  stagesDx: StageDx[];
}

const namespaced = true;

const state: IStageState = {
  stagesDx: []
};

const getters: GetterTree<IStageState, RootState> = {
  getStageDx:
    state =>
    (stageId: string): StageDx => {
      return state.stagesDx.find(stage => stage.getId == stageId);
    },
  getStagesDxByProjectId:
    state =>
    (projectId: number): StageDx[] => {
      return state.stagesDx.filter(stage => stage.getProjectId == projectId);
    }
};

const mutations: MutationTree<IStageState> = {
  setStagesDx(state, stages: StageDx[]): void {
    stages.forEach(s => {
      const index = state.stagesDx.findIndex(stage => stage.getId === s.getId);
      if (index === -1) state.stagesDx.push(s);
      else state.stagesDx[index] = s;
    });
  },
  updateStageDx(state, updatedStageDx: StageDx): void {
    const index = state.stagesDx.findIndex(
      s => s.getId === updatedStageDx.getId
    );
    if (index != -1) {
      state.stagesDx.splice(index, 1, updatedStageDx);
    }
  },
  addStageDx(state, stage: StageDx): void {
    state.stagesDx.push(stage);
  },
  deleteStageDx(state, stageId: string): void {
    const index = state.stagesDx.findIndex(stage => stage.getId == stageId);
    state.stagesDx.splice(index, 1);
  }
};

const actions: ActionTree<IStageState, RootState> = {
  async loadStagesDxByProjectId({ commit }, projectId: number): Promise<any> {
    const stages: StageDx[] = await stageServiceDx.methods.getStagesByProjectId(
      projectId
    );
    commit("setStagesDx", stages);
  },
  async postStageDx({ commit }, newStage: StageDx): Promise<any> {
    const stage: StageDx = await stageServiceDx.methods.postStage(newStage);
    commit("addStageDx", stage);
    return stage.getId;
  },
  async postStageDxBaseline(
    { commit },
    data: { stageId: string; baseline: IBaseLineDx }
  ): Promise<any> {
    const stage: StageDx = await stageServiceDx.methods.postBaseline(data);
    commit("updateStageDx", stage);
  },
  async putStageDx({ commit }, modifiedStage: StageDx): Promise<any> {
    const stage: StageDx = await stageServiceDx.methods.putStage(modifiedStage);
    commit("updateStageDx", stage);
  },
  async putStageDxBaseline(
    { commit },
    data: { stageId: string; baseline: IBaseLineDx }
  ): Promise<any> {
    const stage: StageDx = await stageServiceDx.methods.putBaseline(data);
    commit("updateStageDx", stage);
  },
  async deleteStageDx(
    { commit, dispatch, getters, rootGetters },
    stageId: string
  ): Promise<any> {
    await stageServiceDx.methods.deleteStage(stageId);
    const deletedStage = getters.getStageDx(stageId);
    commit("deleteStageDx", stageId);

    /* Cascade */
    for (const people of rootGetters["peopleDx/getPeopleDxByStageId"](
      stageId
    )) {
      const index = people.getStages.indexOf(stageId);
      people.setStages = people.getStages.splice(index, 1);
      await dispatch("peopleDx/updatePeopleDxLocal", people, { root: true });
    }

    for (const document of rootGetters["documentDx/getDocumentsDxByStageId"](
      stageId
    )) {
      await dispatch("documentDx/deleteDocumentDxLocal", document.getId, {
        root: true
      });
    }

    if (deletedStage.getTag) {
      await dispatch(
        "tagDx/checkRemainingProjectTag",
        { tag: deletedStage.getTag, projectId: deletedStage.getProjectId },
        { root: true }
      );
    }
  },
  deleteStageDxLocal({ commit }, stageId: string): void {
    commit("deleteStageDx", stageId);
  }
};

export const stageModuleDx: Module<IStageState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
