




























































































































































































































































  import {
    getEventTypesByProjectType,
    getTimelineItemIcon
  } from "../../../_helpers/eventTypesStates";
  import {
    getProjectTypeTranslation,
    projectTypes
  } from "../../../_helpers/projectMetadata";
  import {
    getExtensionIcon,
    servicesExtensions
  } from "../../../_helpers/servicesExtensions";
  import ProjectCore from "../../../models/core/ProjectCore";
  import EventDx from "../../../models/deliveryexperience/EventDx";
  import StageDx from "../../../models/deliveryexperience/StageDx";
  import SingleSelectType from "../../../view-models/SingleSelectType";
  import CapsuleMultiselect from "../../view_elements/CapsuleMultiselect.vue";
  import ProjectKpi from "../../view_elements/graphics/ProjectKpi.vue";

  export default {
    name: "Overview",
    components: { ProjectKpi, CapsuleMultiselect },
    props: {
      projectCore: ProjectCore,
      eventsDx: Array,
      stagesDx: Array
    },
    data() {
      return {
        showFullScreenProjectKpi: false, // Flag to delay render of full screen ProjectKpi until modal is completely loaded
        loadedData: false,
        chart: Object,
        projectTypes: projectTypes,
        text: {
          nextEvent: this.$t("nextEvent"),
          currentStage: this.$tc("currentStage", 1),
          currentStages: this.$tc("currentStage", 2),
          noNextEvent: this.$t("noNextEvent"),
          noCurrentStage: this.$t("noCurrentStage"),
          projectType: this.$tc(
            getProjectTypeTranslation(this.projectCore.getProjectType),
            1
          ),
          lastMonths3: this.$t("lastMonths", { months: "3" }),
          lastMonths6: this.$t("lastMonths", { months: "6" }),
          lastMonths12: this.$t("lastMonths", { months: "12" }),
          allProject: this.$t("allProject"),
          start: this.$t("start"),
          end: this.$t("end")
        },
        actualDate: new Date(),
        projectDxEventTypes: [],
        showEvent: false,
        showStage: false,
        months: undefined,
        monthOptions: []
      };
    },
    created: async function () {
      this.projectDxEventTypes = getEventTypesByProjectType(
        this.projectCore.getProjectType
      );

      const monthsView = [3, 6, 12, 0];
      for (const month of monthsView) {
        this.monthOptions.push(
          new SingleSelectType({
            key: month,
            name:
              month == 0
                ? this.text.allProject
                : this.text[`lastMonths${month}`]
          })
        );
      }
      this.months = this.monthOptions[1];
    },
    mounted() {
      this.loadedData = true;
    },
    computed: {
      sortEventsDx: function (): EventDx[] {
        return [...this.eventsDx].sort(function (a, b) {
          //Change 'a' and 'b' to order in descendent order.
          return (
            new Date(a.getLastHistoryBaseline.startedDate).getTime() -
            new Date(b.getLastHistoryBaseline.startedDate).getTime()
          );
        });
      },
      sortStagesDx: function (): StageDx[] {
        return [...this.stagesDx].sort(function (a, b) {
          return (
            new Date(a.getLastHistoryBaseline.startedDate).getTime() -
            new Date(b.getLastHistoryBaseline.startedDate).getTime()
          );
        });
      },
      nextEvent() {
        let nextEvent = {
          id: "",
          name: "",
          date: "",
          hour: "",
          type: ""
        };
        if (this.eventsDx && this.eventsDx.length > 0) {
          const currentDate = new Date();
          for (const event of this.sortEventsDx) {
            const dateOriginal = event.getLastHistoryBaseline.startedDate;
            if (new Date(dateOriginal).getTime() > currentDate.getTime()) {
              const eventDate =
                this.getFullDateFormatted(dateOriginal).split(" ");
              nextEvent.id = event.getId;
              nextEvent.name = event.getName;
              nextEvent.type = event.getEventType;
              nextEvent.date = eventDate[0];
              nextEvent.hour = eventDate[1] != "00:00" ? eventDate[1] : null;
              break;
            }
          }
        }
        return nextEvent;
      },
      currentStages() {
        let nextStage = {
          id: "",
          name: "",
          startDate: "",
          endDate: "",
          color: ""
        };
        let listStages = [];
        if (this.stagesDx && this.stagesDx.length > 0) {
          const currentDate = new Date();
          for (const stage of this.sortStagesDx) {
            if (
              new Date(stage.getLastHistoryBaseline.startedDate).getTime() <
                currentDate.getTime() &&
              new Date(stage.getLastHistoryBaseline.endDate).getTime() >
                currentDate.getTime()
            ) {
              const stageBaseLine = stage.getLastHistoryBaseline;
              nextStage.id = stage.getId;
              nextStage.name = stage.getName;
              nextStage.startDate = this.getDateFormatted(
                stageBaseLine.startedDate
              );
              nextStage.endDate = this.getDateFormatted(stageBaseLine.endDate);
              nextStage.color = stage.getColor ? stage.getColor : "";

              listStages.push(nextStage);
              nextStage = {
                id: "",
                name: "",
                startDate: "",
                endDate: "",
                color: ""
              };
            }
          }
        }
        return listStages;
      },
      getExtensionIcon(): NodeRequire {
        return getExtensionIcon(
          servicesExtensions.deliveryExperience.extensions.overview.name
        );
      }
    },
    methods: {
      getCurrentStage: function (): StageDx {
        let currentStage = null;
        if (this.projectCore.getId) {
          for (const stage of this.stagesDx) {
            if (
              new Date(stage.startDate) < new Date() &&
              new Date(stage.endDate) > new Date()
            )
              currentStage = stage;
          }
        }
        return currentStage;
      },
      dateToString: function (date): string {
        let d = new Date(date);
        if (
          !isNaN(d.getDate()) &&
          !isNaN(d.getMonth()) &&
          !isNaN(d.getFullYear())
        ) {
          return d.getHours() == 0
            ? this.$moment(d).format("L")
            : this.$moment(d).format("L HH:mm");
        } else return "";
      },
      getFullDateFormatted(date: string): string {
        return date != undefined ? this.$moment(date).format("L HH:mm") : "-";
      },
      getDateFormatted(date: string): string {
        return date != undefined ? this.$moment(date).format("L") : "-";
      },
      getTimelineItemIcon(
        timelineItemType: string,
        color?: string
      ): NodeRequire {
        return getTimelineItemIcon(timelineItemType, color);
      },
      showModalProjectKpi() {
        setTimeout(() => (this.showFullScreenProjectKpi = true), 1);
      },
      goToTimelineItem(id, type) {
        this.$root.$emit("goToTimelineItem", id, type);
      }
    }
  };
