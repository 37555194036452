















































































  import CapsuleMultiselect from "../../../components/view_elements/CapsuleMultiselect.vue";
  import ClientCore from "../../../models/core/ClientCore";
  import CapsuleLabelInput from "../../view_elements/CapsuleLabelInput.vue";

  export default {
    components: { CapsuleLabelInput, CapsuleMultiselect },
    props: {
      id: String,
      clientToEdit: Object,
      parent: String,
      editing: { type: Boolean, default: false }
    },
    data() {
      return {
        name: "ClientForm",
        existClientName: false,
        clientName: "",
        tenantsSelected: [],
        text: {
          newClient: this.$t("newClient"),
          editClient: this.$t("editClient"),
          cancel: this.$t("cancel"),
          delete: this.$t("delete"),
          save: this.$t("save"),
          selectTenant: this.$t("selectTenant"),
          tenant: this.$t("tenant"),
          directory: this.$t("directory"),
          name: this.$t("name")
        }
      };
    },
    computed: {
      multiselectLabel(): string {
        return this.text.directory + " (" + this.text.tenant + ")";
      }
    },
    methods: {
      hideModal() {
        this.$bvModal.hide(this.id);
      },
      tenantsCSV(): string {
        return this.tenantsSelected.map(t => t.id).join(",");
      },
      async save() {
        try {
          if (this.clientName) {
            if (!this.editing) {
              await this.postClient(
                new ClientCore({
                  name: this.clientName,
                  tenant: this.tenantsCSV()
                })
              );
              this.$emit("newClient", this.clientName);
            } else {
              const client = new ClientCore({
                id: this.clientToEdit.id,
                name: this.clientName,
                tenant: this.tenantsCSV()
              });
              await this.putClient(client);
              this.$emit("newClient", client.getName);
            }
          }
          this.tenantsSelected = [];
          this.clientName = "";
        } catch (e) {
          this.$log.error(e);
        }
      },
      async checkClientName() {
        this.existClientName = false;
        for (let i = 0; i < this.getClients.length; i++) {
          if (this.getClients[i].getName == this.clientName) {
            this.existClientName = true;
            break;
          }
        }
      },
      deleteClient() {
        this.hideModal();
        this.$bvModal.show("modal-confirmation-delete-client");
      },
      uploadTenants() {
        let newSelected = [];
        if (this.clientToEdit.tenant) {
          const clientTenantIds = this.clientToEdit.tenant.split(",");
          for (const clientTenant of clientTenantIds) {
            const cspTenant = this.getCustomersCSP.find(
              e => e.id == clientTenant
            );
            if (cspTenant) {
              newSelected.push(cspTenant);
            }
          }
        }
        this.tenantsSelected = newSelected;
      },
      resetValues() {
        this.tenantsSelected = [];
        this.clientName = "";
      }
    },
    watch: {
      clientToEdit() {
        if (this.clientToEdit == null) {
          Object.assign(this.$data, this.$options.data.apply(this));
        } else {
          this.clientName = this.clientToEdit.name;
          this.uploadTenants();
        }
      },
      editing() {
        if (!this.editing) this.resetValues();
      }
    }
  };
