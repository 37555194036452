import NotificationDx from "../../models/deliveryexperience/NotificationDx";
import NotificationInfo from "../../view-models/deliveryexperience/NotificationInfo";
import service from "../service";
import baseEndpoint from "./serviceDx";

const endpoint = baseEndpoint + "/Notification";

export default {
  components: { service: service },
  methods: {
    async getNotificationsForUser(
      username: string
    ): Promise<NotificationInfo[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/user/${username}`
      );
      return response.map(notification => new NotificationInfo(notification));
    },
    async markNotificationAsRead(
      id: string,
      username: string
    ): Promise<NotificationInfo> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${id}`,
        username
      );
      return new NotificationInfo(response);
    },
    async postNotification(
      notification: NotificationDx
    ): Promise<NotificationInfo> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        notification
      );
      return new NotificationInfo({
        id: response.id,
        itemName: response.itemName,
        projectId: response.projectId,
        itemType: response.itemType,
        itemId: response.itemId,
        timestamp: response.timestamp,
        read: false,
        action: response.action,
        author: response.author
      });
    }
  }
};
