


































































  import {
    getOperationType,
    operationTypes
  } from "../../../_helpers/cspOperations";
  import CapsuleInputDate from "../../view_elements/CapsuleInputDate.vue";

  export default {
    name: "ConfirmationSubscriptionModal",
    components: {
      CapsuleInputDate
    },
    props: {
      id: String,
      subscriptionData: Object,
      opType: String
    },
    data() {
      const currentDate = new Date();
      return {
        loading: false,
        accept: false,
        reason: "",
        cancellationDate: currentDate,
        datesFrom: currentDate,
        text: {
          confirm: this.$t("confirm"),
          cancel: this.$t("cancel"),
          licencesPurchase: this.$tc("licencesPurchase", 1),
          licencesCancellation: this.$tc("licencesCancellation", 1),
          subscriptionCancellation: this.$t("subscriptionCancellation"),
          confirmationAutoRenewDisable: this.$t("confirmationAutoRenewDisable"),
          confirmationTxt: this.$t("confirmationTxt"),
          changeBillingFrequency: this.$t("changeBillingFrequency"),
          changeTermDuration: this.$t("changeTermDuration"),
          checkConfirmation: this.$t("checkConfirmation"),
          reasonPlaceholder: this.$t("reasonPlaceholder"),
          termDuration: this.$tc("termDuration", 2),
          billingFrequency: this.$tc("billingFrequency", 2)
        }
      };
    },
    computed: {
      titleConfirmation(): string {
        return this.text[
          getOperationType(this.opType).i18n.modalConfirmationTitle
        ];
      },
      messageConfirmation(): string {
        const i18n = getOperationType(this.opType).i18n
          .modalConfirmationMessage;
        if (this.opType == operationTypes.increment.key) {
          return this.$t(i18n, {
            quantity: this.subscriptionData.variation,
            subscription: this.getSubscriptionCSPById(
              this.subscriptionData.subscriptionId
            ).offerName
          });
        } else if (this.opType == operationTypes.decrement.key)
          return this.$t(i18n, {
            quantity: this.subscriptionData.variation,
            subscription: this.getSubscriptionCSPById(
              this.subscriptionData.subscriptionId
            ).offerName
          });
        else if (this.opType == operationTypes.friendlyName.key)
          return this.$t(i18n, {
            oldName: this.getSubscriptionCSPById(
              this.subscriptionData.subscriptionId
            ).friendlyName,
            newName: this.subscriptionData.reqBody.friendlyName
          });
        else if (this.opType == operationTypes.cancellation.key) {
          if (
            this.subscriptionData.refundables == this.subscriptionData.quantity
          )
            return this.$t("confirmationCancellationAll", {
              refundableLicences: this.subscriptionData.refundables
            });
          if (this.subscriptionData.refundables)
            return this.$t("confirmationCancellationMixed", {
              refundableLicences: this.subscriptionData.refundables,
              licences: this.subscriptionData.quantity
            });
          else
            return this.$t("confirmationCancellationNo", {
              licences: this.subscriptionData.quantity
            });
        } else if (this.opType == operationTypes.autoRenewEnable.key)
          return this.$t(i18n, {
            date: this.$moment(this.subscriptionData.date)
              .add("1", "d")
              .format("L")
          });
        else if (
          this.opType == operationTypes.termDuration.key ||
          this.opType == operationTypes.billingFrequency.key
        )
          return this.$t(i18n, {
            type: this.text[getOperationType(this.opType).key]
          });
        else return this.$t(i18n);
      },
      confirmDisabled(): boolean {
        if (this.opType == operationTypes.decrement.key) return !this.accept;
        else if (this.needReason) return !this.reason.length;
        else return false;
      },
      needReason(): boolean {
        return (
          (this.opType == operationTypes.cancellation.key &&
            this.subscriptionData.refundables !=
              this.subscriptionData.quantity) ||
          getOperationType(this.opType).needReason
        );
      }
    },
    methods: {
      confirm: async function () {
        this.$emit("confirm", {
          reason: this.reason,
          date: this.cancellationDate
        });
      },
      cancel() {
        this.$emit("resetSubscriptionsValues");
      },
      resetValues() {
        this.reason = "";
        this.cancellationDate = this.datesFrom;
      }
    }
  };
