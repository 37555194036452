

























































































































































































































































  import { servicesExtensions } from "../../../_helpers/servicesExtensions";
  import CapsuleLabelInput from "../../../components/view_elements/CapsuleLabelInput.vue";
  import AnalysisResult from "../../../view-models/spiderlinkschecker/AnalysisResult";

  export default {
    name: servicesExtensions.spiderLinksChecker.name
      .toLowerCase()
      .replace(/\s+/g, ""),
    components: { CapsuleLabelInput },
    data() {
      return {
        loadedData: false,
        isLoading: false,
        isLoadingPrevious: false,
        isLoadingNext: false,
        storedResult: new AnalysisResult({}),
        url: "",
        depth: 0,
        currentDepth: 0,
        executionDate: null,
        metadataId: null,
        username: null,
        password: null,
        isValid: false,
        linksPass: 0,
        linksFail: 0,
        cantScan: 0,
        totalAnalyzed: 0,
        executionTime: "0s",
        brokenLinks: [],
        projectsFields: [
          {
            label: this.$t("element"),
            key: "element",
            sortable: false,
            tdClass: "text-truncate"
          },
          {
            label: this.$t("link"),
            key: "link",
            sortable: false,
            tdClass: "text-truncate"
          },
          {
            label: this.$t("origin"),
            key: "parent",
            sortable: false,
            tdClass: "text-truncate"
          },
          {
            label: this.$t("status"),
            key: "status_code",
            sortable: false,
            tdClass: "text-truncate",
            thStyle: { width: "5%" }
          },
          {
            label: "",
            key: "status_message",
            sortable: false,
            tdClass: "text-truncate",
            thStyle: { width: "25%" }
          }
        ],
        text: {
          analysis: this.$t("analysis"),
          results: this.$t("results"),
          urlLabel: "URL",
          depthLabel: this.$t("depth"),
          executionDateLabel: this.$t("executionDate"),
          startAnalysisLabel: this.$t("startAnalysis"),
          urlInvalid: this.$t("urlInvalid"),
          brokenLinksHeader: this.$t("brokenLinksHeader"),
          emptyAnalysisText: this.$t("emptyAnalysis"),
          previousLabel: this.$t("previous"),
          nextLabel: this.$tc("next", 2),
          usernameLabel: this.$t("username") + " (" + this.$t("optional") + ")",
          passwordLabel:
            this.$tc("password", 1) + " (" + this.$t("optional") + ")"
        }
      };
    },
    async created() {
      this.addLoadingComponent(this.$options.name);
      const queryOptions = {
        projectid: this.$route.params.projectId
      };
      try {
        await this.loadLastAnalysis(queryOptions);
        this.updateAttributes();
      } catch (error) {
        this.$log.error(error);
      }
      this.loadedData = true;
      this.removeLoadingComponent(this.$options.name);
    },
    async mounted() {
      this.$emit(
        "projectHeaderService",
        servicesExtensions.spiderLinksChecker.name
      );
      this.$emit(
        "projectHeaderExtension",
        servicesExtensions.spiderLinksChecker.extensions.analysis.name
      );
    },
    computed: {
      isValidHttpUrl(): boolean {
        let pattern = new RegExp(
          "^(https?:\\/\\/)" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
          "i"
        ); // fragment locator
        return !!pattern.test(this.url);
      },
      showInvalidUrlText(): boolean {
        return !this.isValidHttpUrl && this.url != "";
      }
    },
    methods: {
      async startAnalysis() {
        this.isLoading = true;
        const queryOptions = {
          url: this.url,
          depth: this.depth,
          projectid: this.$route.params.projectId,
          username: this.username,
          password: this.password
        };
        try {
          await this.postAnalysis(queryOptions);
          this.updateAttributes();
        } catch (error) {
          this.$log.error(error);
        }
        this.isLoading = false;
      },
      async previousAnalysis() {
        this.isLoadingPrevious = true;
        const queryOptions = {
          projectid: this.$route.params.projectId,
          currentid: this.metadataId
        };
        try {
          await this.loadPreviousAnalysis(queryOptions);
          this.updateAttributes();
        } catch (error) {
          this.$log.error(error);
        }
        this.isLoadingPrevious = false;
      },
      async nextAnalysis() {
        this.isLoadingNext = true;
        const queryOptions = {
          projectid: this.$route.params.projectId,
          currentid: this.storedResult.getMetadataId
        };
        try {
          await this.loadNextAnalysis(queryOptions);
          this.updateAttributes();
        } catch (error) {
          this.$log.error(error);
        }
        this.isLoadingNext = false;
      },
      updateAttributes() {
        if (this.getLastAnalysisResult.getUrl != "-") {
          this.storedResult = this.getLastAnalysisResult;
          this.metadataId = this.storedResult.metadataId;
        }
      }
    }
  };
