import TimeElapsed from "./TimeElapsed";

export interface IAffectedEventStage {
  name: string;
  type: string;
  timeElapsed: TimeElapsed;
}

export default class AffectedEventStage {
  private name: string;
  private type: string;
  private timeElapsed: TimeElapsed;

  constructor(event: IAffectedEventStage) {
    Object.assign(this, event);
  }

  //Getters
  get getName(): string {
    return this.name;
  }

  get getType(): string {
    return this.type;
  }

  get getTimeElapsed(): TimeElapsed {
    return this.timeElapsed;
  }

  //Setters

  set setName(value: string) {
    this.name = value;
  }

  set setType(value: string) {
    this.type = value;
  }

  set setTimeElapsed(value: TimeElapsed) {
    this.timeElapsed = value;
  }
}
