


























































































































































  import emailBuilder from "../../../_helpers/email/emailBuilder";
  import onboardingEmailBodyEN from "../../../_helpers/email/onboardingEmailBodyEN";
  import onboardingEmailBodyES from "../../../_helpers/email/onboardingEmailBodyES";
  import { roleTypes } from "../../../_helpers/roleTypes";
  import ClientCore from "../../../models/core/ClientCore";
  import MailCore from "../../../models/core/MailCore";
  import PeopleCore from "../../../models/core/PeopleCore";
  import mailService from "../../../services/core/mailService";
  import SingleSelectType from "../../../view-models/SingleSelectType";
  import CapsuleLabelInput from "../../view_elements/CapsuleLabelInput.vue";
  import CapsuleMultiselect from "../../view_elements/CapsuleMultiselect.vue";
  import service from "../../../services/service";

  export default {
    name: "UserForm",
    components: { CapsuleLabelInput, CapsuleMultiselect },
    props: { id: String, username: String },
    data() {
      return {
        name: "",
        lastName: "",
        email: "",
        language: undefined,
        jobPosition: "",
        client: { icon: undefined, name: null, key: -1 },
        role: new SingleSelectType({
          key: roleTypes.user.name,
          name: this.$tc(roleTypes.user.i18n, 1)
        }),
        languageOptions: [
          new SingleSelectType({
            key: "ES",
            name: this.$t("spanish").toString()
          }),
          new SingleSelectType({
            key: "EN",
            name: this.$t("english").toString()
          })
        ],
        roleOptions: []
      };
    },
    created: async function () {
      await this.loadClients();
      this.setRoleOptions();
    },
    computed: {
      clientsList(): Array<SingleSelectType> {
        return this.getClients.map(
          c =>
            new SingleSelectType({
              key: c.getId,
              name: c.getName
            })
        );
      },
      validateEmail(): boolean {
        if (this.email) {
          const regExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
          return !regExp.test(this.email);
        } else {
          return false;
        }
      }
    },
    methods: {
      confirmDelete() {
        this.$bvModal.hide(this.id);
        this.$bvModal.show("modal-confirmation-delete-user");
      },
      resetValues() {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.setRoleOptions();
      },
      setRoleOptions() {
        for (const roleType in roleTypes) {
          this.roleOptions.push(
            new SingleSelectType({
              key: roleTypes[roleType].name,
              name: this.$tc(roleTypes[roleType].i18n, 1)
            })
          );
        }
      },
      async saveUser() {
        //Check if new client has been created
        if (this.client.key == -1) {
          await this.postClient(
            new ClientCore({
              name: this.client.name
            })
          );
          this.client.key = this.getClients.find(
            c => c.name === this.client.name
          ).getId;
        }
        if (this.username) {
          await this.putPeople(
            new PeopleCore({
              username: this.email,
              firstName: this.name,
              lastName: this.lastName,
              language: this.language.key,
              jobPosition: this.jobPosition,
              incorporationDate: this.getPeopleByUsername(this.username)
                .getIncorporationDate,
              roleId: this.role.key,
              clientId: this.client.key
            })
          );
        } else {
          await this.postPeople(
            new PeopleCore({
              username: this.email,
              firstName: this.name,
              lastName: this.lastName,
              language: this.language.key,
              jobPosition: this.jobPosition,
              incorporationDate: new Date(),
              roleId: this.role.key,
              clientId: this.client.key
            })
          );
          // Sending onboarding email only for new Sogeti users
          if (this.client.key == 1) {
            this.postMail(this.language.key, this.email);
          }
          this.$emit("resetSelected");
        }
      },
      async postMail(lang, username) {
        try {
          const response = await service.methods.getBlobEndpointAsync(
            "https://sogetisaportalhubp.blob.core.windows.net/sogetihub/SogetiHub_User_Guide.pdf"
          );
          if (!response) {
            throw new Error("Error getting the PDF file for email attachment");
          }
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = async () => {
            const base64data = reader.result;
            if (typeof base64data === "string") {
              await mailService.methods.postMailCore(
                new MailCore({
                  to: username,
                  subject: `${this.$t("onboardingEmailTitle", lang)} ${this.$t(
                    "onboardingEmailSubtitle",
                    lang
                  )}`,
                  body: emailBuilder.buildEmail(
                    this.$t("onboardingEmailTitle", lang),
                    this.$t("onboardingEmailSubtitle", lang),
                    lang === "ES"
                      ? onboardingEmailBodyES.build()
                      : onboardingEmailBodyEN.build(),
                    lang
                  ),
                  attachments: [
                    {
                      filename: "SogetiHub_User_Guide.pdf",
                      content: base64data.split(",")[1],
                      contentType: response.type
                    }
                  ]
                })
              );
            }
          };
        } catch (error) {
          throw new Error(
            "Error getting the PDF file for email attachment:",
            error
          );
        }
      },
      addNewClient(value) {
        this.client.key = -1;
        this.client.name = value;
        this.clientsList.push(this.client);
      },
      clientItemSelected() {
        if (this.client != null) {
          const foundClient = this.getClients.find(
            c => c.name === this.client.name
          );
          if (foundClient) {
            this.client.key = foundClient.getId;
          } else {
            this.client.key = -1;
          }
        }
      }
    },
    watch: {
      username() {
        if (this.username) {
          const userToEdit: PeopleCore = this.getPeopleByUsername(
            this.username
          );
          this.name = userToEdit.getFirstName;
          this.lastName = userToEdit.getLastName;
          this.email = userToEdit.getUsername;
          this.language = this.languageOptions.find(
            l => l.getKey == userToEdit.getLanguage
          );
          this.jobPosition =
            userToEdit.getJobPosition == this.$t("notSpecifiedJobPosition")
              ? ""
              : userToEdit.getJobPosition;
          this.client = this.clientsList.find(
            c => c.getKey == userToEdit.getClientId
          );
          this.role = this.roleOptions.find(
            r => r.getKey == userToEdit.getRoleId
          );
        } else this.resetValues();
      }
    }
  };
