
































































  import { getServiceIcon } from "../../_helpers/servicesExtensions";
  import Extension from "../../components/view_elements/Extension.vue";
  import HiredSwitch from "../../components/view_elements/HiredSwitch.vue";
  import ExtensionCore from "../../models/core/ExtensionCore";

  export default {
    name: "ExtensionsTab",
    components: { HiredSwitch, Extension },
    data() {
      return {
        text: {
          serviceNotHired: this.$t("serviceNotHired"),
          thereAreNoExtensions: this.$t("thereAreNoExtensions")
        },
        displayHiredOnly: true
      };
    },
    created: async function () {
      await this.loadCompatibleExtensionsByClientId(this.getUser.getClientId);
    },
    methods: {
      // Gets compatible extensions for a given serviceId.
      getExtensionsInServiceHired(serviceId: number): ExtensionCore[] {
        let serviceIsHired = false;
        const hiredServices = this.getHiredServicesByClientId(
          this.getUser.getClientId
        );
        for (const hiredService of hiredServices) {
          if (hiredService.id == serviceId) {
            serviceIsHired = true;
            break;
          }
        }
        if (serviceIsHired)
          return this.getExtensionServiceByServiceId(serviceId);
        else {
          return null;
        }
      },
      // Gets compatible extensions for a given serviceId.
      getExtensionsToShow(serviceId: number): ExtensionCore[] {
        if (this.displayHiredOnly)
          return this.getExtensionsInServiceHired(serviceId);
        else return this.getExtensionServiceByServiceId(serviceId);
      },
      getServiceIcon(serviceName: string): NodeRequire {
        return getServiceIcon(serviceName);
      }
    }
  };
