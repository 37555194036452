import service from "../service";
import baseEndpoint from "./serviceCSP";

const endpoint = baseEndpoint + "/PartnerCenter";

export default {
  components: { service: service },
  methods: {
    async getAccessToken(): Promise<string> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response;
    }
  }
};
