var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.subscription)?_c('div',[_c('b-row',{staticClass:"subscription-row",class:{
      'disabled-opacity': _vm.isSubscriptionDisabled
    },on:{"mouseover":function($event){_vm.subscriptionRowHovered = true},"mouseleave":function($event){_vm.subscriptionRowHovered = false}}},[_c('b-col',{staticClass:"subscriptions-col-cells",attrs:{"cols":"3"}},[_c('button',{staticClass:"btn button-hover collapse-transition pl-0",class:{ 'collapse-transition-open': _vm.showCollapse },on:{"click":function($event){_vm.showCollapse = !_vm.showCollapse}}},[_c('FontAwesomeIcon',{staticClass:"mr-3",staticStyle:{"height":"20","margin-left":"-1rem"},attrs:{"icon":"fa-solid fa-chevron-down"}}),_c('span',{staticClass:"text-truncate text-body sub-details-offerName",attrs:{"title":_vm.subscription.offerName}},[_vm._v(" "+_vm._s(_vm.subscription.offerName)+" ")])],1)]),_c('b-col',{staticClass:"subscriptions-col-cells",attrs:{"cols":"3"}},[(!_vm.editMode)?_c('span',{staticClass:"text-truncate sub-details-friendlyName"},[_vm._v(_vm._s(_vm.subscription.friendlyName))]):_c('p',{staticClass:"d-flex mb-0"},[_c('CapsuleLabelInput',{attrs:{"input":_vm.nickNameEdit,"placeholder":_vm.text.enterName},on:{"setValue":function (value) { return (_vm.nickNameEdit = value); }}}),(_vm.nickNameEdit != _vm.subscription.friendlyName)?_c('button',{staticClass:"btn button-hover",attrs:{"alt":""},on:{"click":function($event){return _vm.editSubscription(_vm.operationTypes.friendlyName.key, {
              friendlyName: _vm.nickNameEdit,
              autoRenewEnabled: _vm.subscription.autoRenewEnabled
            })}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"20px","color":"var(--main-bg-color)"},attrs:{"icon":"fas fa-check"}})],1):_vm._e()],1)]),_c('b-col',{staticClass:"subscriptions-col-cells",attrs:{"cols":"3"}},[(!_vm.editMode)?_c('div',[_c('span',{staticClass:"sub-details-quantity"},[_vm._v(_vm._s(("" + _vm.subscriptionsQuantity)))]),_c('span',[_vm._v(" "+_vm._s(("" + (_vm.text.licence))))]),(_vm.showRefundables)?_c('div',{staticClass:"cursor-pointer",staticStyle:{"color":"var(--main-bg-color)"}},[_c('span',{attrs:{"id":("licences-" + (_vm.subscription.id))}},[_vm._v(" "+_vm._s(((_vm.subscription.refundableQuantity.totalQuantity) + " " + (_vm.text.refundable))))])]):_vm._e(),(_vm.showRefundables)?_c('b-popover',{key:("licences-popover-" + (_vm.subscription.id)),attrs:{"target":("licences-" + (_vm.subscription.id)),"placement":"topright","triggers":"hover"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.text.refundableUpper))]},proxy:true}],null,false,1171083557)},[_c('b-container',_vm._l((_vm.subscription.refundableQuantity
                .details),function(licence,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(((licence.quantity) + " " + (_vm.text.licence) + " " + (_vm.text.until) + " " + (_vm.$moment( licence.allowedUntilDateTime ).format("DD/MM/YYYY HH:mm"))))+" ")])}),0)],1):_vm._e()],1):_c('div',{staticClass:"input-group"},[_c('button',{staticClass:"btn button-hover",attrs:{"disabled":_vm.quantityLicencesEdit == _vm.minimumLicences},on:{"click":function($event){return _vm.decrementLicences()}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"25"},attrs:{"icon":"fas fa-minus"}})],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quantityLicencesEdit),expression:"quantityLicencesEdit"}],staticClass:"input-number",attrs:{"id":("input-number-" + (_vm.subscription.id)),"type":"number","pattern":"[0-9]*","min":_vm.subscription.refundableQuantity
              ? _vm.minimumLicences
              : _vm.subscription.quantity,"max":300},domProps:{"value":(_vm.quantityLicencesEdit)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.quantityLicencesEdit=$event.target.value},function($event){_vm.quantityLicencesEdit = Number(_vm.quantityLicencesEdit)}]}}),_c('button',{staticClass:"btn button-hover",on:{"click":function($event){_vm.quantityLicencesEdit < 300 ? (_vm.quantityLicencesEdit += 1) : null}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"25"},attrs:{"icon":"fas fa-plus"}})],1),(_vm.quantityLicencesEdit != _vm.subscription.quantity)?_c('button',{staticClass:"btn button-hover",attrs:{"alt":""},on:{"click":function($event){return _vm.editSubscription(
              _vm.quantityLicencesEdit - _vm.subscription.quantity > 0
                ? _vm.operationTypes.increment.key
                : _vm.operationTypes.decrement.key,
              {
                quantity: _vm.quantityLicencesEdit,
                autoRenewEnabled: _vm.subscription.autoRenewEnabled
              }
            )}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"20px","color":"var(--main-bg-color)"},attrs:{"icon":"fa-solid fa-check"}})],1):_vm._e()])]),_c('b-col',{staticClass:"subscriptions-col-cells d-flex justify-content-between pr-0",attrs:{"cols":"3"}},[_c('div',[_c('p',{staticClass:"mb-auto",attrs:{"id":("status-" + (_vm.subscription.id))}},[_c('span',{staticClass:"sub-details-status",attrs:{"autoRenewDate":_vm.subscription.commitmentEndDate}},[_vm._v(_vm._s(_vm.statusText)+" ")]),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.text.suspendedToKnowMore),expression:"text.suspendedToKnowMore",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1"},[(_vm.subscription.status == _vm.subscriptionStatus.suspended.key)?_c('FontAwesomeIcon',{staticStyle:{"height":"18px","color":"var(--main-bg-color)"},attrs:{"icon":"fas fa-exclamation-triangle"}}):_vm._e()],1)]),_c('span',{staticClass:"info-text"},[_vm._v(" "+_vm._s(_vm.autoRenewText.statusColumn)+" ")])]),_c('div',[(
            !_vm.subscriptionHasBeenCancelled &&
            _vm.subscription.status == _vm.subscriptionStatus.active.key &&
            _vm.getUserPermissions.csp.write
          )?[(!_vm.editMode)?_c('button',{staticClass:"btn btn-sm button-hover show-when-hovered",class:{ 'mr-4': !_vm.pendingOperation },on:{"click":function($event){return _vm.openEditMode()}}},[(_vm.subscriptionRowHovered || _vm.showCollapse)?_c('FontAwesomeIcon',{attrs:{"icon":"fa-solid fa-pen"}}):_vm._e()],1):_c('button',{staticClass:"btn btn-sm button-hover",class:{ 'mr-4': !_vm.pendingOperation },on:{"click":function($event){return _vm.finishSubscriptionEdit()}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"20px","color":"var(--alert-color)"},attrs:{"icon":"fas fa-times"}})],1)]:_vm._e(),(_vm.pendingOperation)?_c('img',{staticClass:"pending-operation-icon float-right",staticStyle:{"margin-top":"0.4rem"},attrs:{"height":"15","src":require("../../../../assets/icons/services/csp/operations/hourglass-clock.svg")}}):_vm._e()],2)])],1),_c('transition',{attrs:{"name":"collapse"}},[(_vm.showCollapse)?_c('div',{staticClass:"subscription-details",class:{
        'disabled-opacity': _vm.isSubscriptionDisabled
      }},[_c('b-row',{staticStyle:{"margin-left":"20px"}},[_c('b-col',{staticClass:"d-flex"},[_c('b',[_vm._v(_vm._s(_vm.text.subscription)+" Id:")]),_vm._v("   "+_vm._s(_vm.subscription.id)+" ")]),_c('b-col',{staticClass:"d-flex",staticStyle:{"margin-left":"-30px"}},[_c('b',[_vm._v(_vm._s(_vm.text.status)+":")]),_vm._v("   "),_c('span',{class:{ 'edited-text': _vm.subscriptionHasBeenCancelled }},[_vm._v(" "+_vm._s(_vm.statusText)+" ")]),(_vm.subscription.status == _vm.subscriptionStatus.suspended.key)?_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.text.suspendedToKnowMore),expression:"text.suspendedToKnowMore",modifiers:{"hover":true,"top":true}}],staticClass:"ml-1"},[_c('FontAwesomeIcon',{staticStyle:{"height":"18px","color":"var(--main-bg-color)"},attrs:{"icon":"fas fa-exclamation-triangle"}})],1):_vm._e(),_vm._v("   ")])],1),_c('b-row',{staticStyle:{"margin-left":"20px","margin-top":"10px","min-height":"40px"}},[_c('b-col',{staticClass:"d-flex"},[_c('b',{staticClass:"align-self-center"},[_vm._v(_vm._s(_vm.text.duration)+":")]),_vm._v("   "),(!_vm.editMode || _vm.availableTermDurations.length <= 1)?_c('div',{staticClass:"align-self-center",class:{ 'edited-text': _vm.termDurationIsEdited }},[_vm._v(" "+_vm._s(_vm.termDurationText)+" ")]):_c('div',[_c('CapsuleMultiselect',{attrs:{"input":_vm.termDurationEdit,"options":_vm.availableTermDurations,"required":{ status: true },"type":"singleSelect"},on:{"setValue":function (value) {
                  _vm.termDurationEdit = value;
                  _vm.editSubscription(_vm.operationTypes.termDuration.key, {
                    duration: value.key,
                    autoRenewEnabled: _vm.subscription.autoRenewEnabled
                  });
                }}})],1)]),_c('b-col',{staticClass:"d-flex",staticStyle:{"margin-left":"-30px"}},[_c('b',{staticClass:"align-self-center"},[_vm._v(_vm._s(_vm.text.billing)+":")]),_vm._v("   "),(!_vm.editMode || _vm.availableBillingCyclesOptions.length <= 1)?_c('div',{staticClass:"align-self-center",class:{ 'edited-text': _vm.billingFrequencyIsEdited }},[_vm._v(" "+_vm._s((_vm.billingFrequencyText + " (" + (_vm.$t("nextBilling")) + " " + (_vm.$moment( this.subscription.billingCycleEndDate ).format("L")) + ")"))+" ")]):_c('div',[_c('CapsuleMultiselect',{attrs:{"input":_vm.billingFrequencyEdit,"options":_vm.availableBillingCyclesOptions,"required":{ status: true },"type":"singleSelect"},on:{"setValue":function (value) {
                  _vm.billingFrequencyEdit = value;
                  _vm.editSubscription(_vm.operationTypes.billingFrequency.key, {
                    frequency: value.key,
                    autoRenewEnabled: _vm.subscription.autoRenewEnabled
                  });
                }}})],1)])],1),_c('b-row',{staticStyle:{"margin-left":"20px","margin-top":"15px"}},[_c('b-col',[(
              _vm.subscription.status == _vm.subscriptionStatus.active.key &&
              _vm.getUserPermissions.csp.write
            )?_c('b-button',{staticClass:"button-small button-cancel",class:{
              'disabled-pointer': _vm.isSubscriptionDisabled
            },attrs:{"pill":"","disabled":_vm.subscriptionHasBeenCancelled},on:{"click":_vm.cancelSubscription}},[_vm._v(_vm._s(_vm.text.cancelSubscription)+" ")]):_vm._e()],1),_c('b-col',{staticStyle:{"margin-left":"-30px","margin-top":"10px"}},[_c('div',{class:{ 'edited-text': _vm.autoRenewPendingToDisabled }},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.autoRenewText.details)}}),(_vm.editMode)?_c('label',{staticClass:"switch mb-0 mx-2"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.autoRenewSwitch),expression:"autoRenewSwitch"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.autoRenewSwitch)?_vm._i(_vm.autoRenewSwitch,null)>-1:(_vm.autoRenewSwitch)},on:{"change":[function($event){var $$a=_vm.autoRenewSwitch,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.autoRenewSwitch=$$a.concat([$$v]))}else{$$i>-1&&(_vm.autoRenewSwitch=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.autoRenewSwitch=$$c}},function($event){return _vm.editSubscriptionAutorenew({
                    autoRenewEnabled: _vm.autoRenewSwitch
                  })}]}}),_c('span',{staticClass:"slider round"})]):_vm._e()])])],1)],1):_vm._e()])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }