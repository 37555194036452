import MailCore from "../../models/core/MailCore";
import service from "../service";
import baseEndpoint from "./serviceCore";

const endpoint = baseEndpoint + "/Mail";

export default {
  components: { service: service },
  methods: {
    async postMailCore(mail: MailCore): Promise<any> {
      await service.methods.postEndpointAsync(endpoint, mail);
    }
  }
};
