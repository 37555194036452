var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100",staticStyle:{"heigth":"40px"}},[(_vm.label)?_c('label',{staticClass:"input-label",class:{
      postpone: _vm.comeFrom == 'postpone',
      disabled: _vm.disabled,
      grey: _vm.background == 'grey',
      white: _vm.background == 'white'
    }},[_vm._v(_vm._s(_vm.label + (_vm.required.status ? " *" : "")))]):_vm._e(),((_vm.type)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input_),expression:"input_"}],staticClass:"input",class:{
      required: _vm.required.style,
      alert: _vm.alert
    },attrs:{"id":'input_' + _vm.id,"autocomplete":"off","maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"step":_vm.step,"accept":_vm.accept,"oninput":_vm.oninput,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.input_)?_vm._i(_vm.input_,null)>-1:(_vm.input_)},on:{"input":function($event){return _vm.$emit('input')},"change":[function($event){var $$a=_vm.input_,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.input_=$$a.concat([$$v]))}else{$$i>-1&&(_vm.input_=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.input_=$$c}},function($event){return _vm.$emit('change', $event)}],"focusout":function($event){return _vm.$emit('focusout')},"keydown":function($event){return _vm.$emit('keydown')},"keyup":function($event){return _vm.$emit('keyup')}}}):((_vm.type)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input_),expression:"input_"}],staticClass:"input",class:{
      required: _vm.required.style,
      alert: _vm.alert
    },attrs:{"id":'input_' + _vm.id,"autocomplete":"off","maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"step":_vm.step,"accept":_vm.accept,"oninput":_vm.oninput,"type":"radio"},domProps:{"checked":_vm._q(_vm.input_,null)},on:{"input":function($event){return _vm.$emit('input')},"change":[function($event){_vm.input_=null},function($event){return _vm.$emit('change', $event)}],"focusout":function($event){return _vm.$emit('focusout')},"keydown":function($event){return _vm.$emit('keydown')},"keyup":function($event){return _vm.$emit('keyup')}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.input_),expression:"input_"}],staticClass:"input",class:{
      required: _vm.required.style,
      alert: _vm.alert
    },attrs:{"id":'input_' + _vm.id,"autocomplete":"off","maxlength":_vm.maxlength,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"min":_vm.min,"max":_vm.max,"step":_vm.step,"accept":_vm.accept,"oninput":_vm.oninput,"type":_vm.type},domProps:{"value":(_vm.input_)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.input_=$event.target.value},function($event){return _vm.$emit('input')}],"change":function($event){return _vm.$emit('change', $event)},"focusout":function($event){return _vm.$emit('focusout')},"keydown":function($event){return _vm.$emit('keydown')},"keyup":function($event){return _vm.$emit('keyup')}}})])}
var staticRenderFns = []

export { render, staticRenderFns }