

































































































































































































































































































  import Multiselect from "vue-multiselect";
  import PeopleInfo from "./PeopleInfo.vue";

  export default {
    name: "CapsuleMultiselect",
    components: { Multiselect, PeopleInfo },
    props: {
      label: String,
      placeholder: String,
      input: [String, Array, Object], // Accepting only ONE of these types
      options: Array,
      tooltips: {
        type: Object,
        default: function () {
          return new Object();
        }
      },
      multiple: { type: Boolean, default: false },
      maxHeight: { type: Number, default: 165 },
      taggable: { type: Boolean, default: false },
      tagPlaceholder: String,
      disabled: { type: Boolean, default: false },
      searchable: { type: Boolean, default: true },
      required: {
        type: Object,
        default: function () {
          return { status: false, style: false };
        }
      },
      background: { type: String, default: "white" },
      type: String
    },
    data() {
      return {
        activeStyle: Boolean(false),
        text: {
          membersSelected: this.$t("membersSelected")
        }
      };
    },
    computed: {
      optionSelected: {
        get() {
          if (
            !this.input ||
            (this.type == "singleSelect" && this.input.getKey == undefined)
          ) {
            // if input is empty or is an undefined SingleSelectType
            return undefined;
          }
          return this.input;
        },
        set(value) {
          this.$emit("setValue", value);
        }
      }
    },
    methods: {
      searchableName(option): string {
        return option.getNaturalFullName();
      },
      searchableTenant(option): string {
        return option.name;
      },
      userJobPosition(user): string {
        if (user.getJobPosition) {
          return user.getJobPosition;
        } else {
          return this.getPeopleByUsername(user.getUsername).getJobPosition;
        }
      },
      getTooltipConfig(optionKey) {
        return {
          html: true,
          placement: "right",
          boundary: "viewport",
          delay: { show: 500, hide: 0 },
          content: () =>
            `<p class="mb-0" style="text-align: justify">${this.tooltips[optionKey]}</p>`
        };
      }
    }
  };
