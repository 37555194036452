import Moment from "moment";
import { i18n } from "../plugins/i18";

export const termsOptions = {
  triennial: {
    billing: "triennial",
    duration: "P3Y",
    i18n: "triennial"
  },
  annual: {
    billing: "annual",
    duration: "P1Y",
    i18n: "annual"
  },
  monthly: {
    billing: "monthly",
    duration: "P1M",
    i18n: "monthly"
  },
  none: {
    billing: "none",
    duration: "none",
    i18n: "na"
  }
};

export const mailDetails = {
  author: { key: "author", i18n: "author" },
  date: { key: "date", i18n: "date" },
  project: { key: "project", i18n: "project" },
  tenantId: { key: "tenantId", i18n: "tenantId" },
  subscriptionId: { key: "subscriptionId", i18n: "subscriptionId" },
  subscription: { key: "subscription", i18n: "nickname" },
  operation: { key: "operation", i18n: "operation" },
  reason: { key: "reason", i18n: "reason" },
  cancellationDate: { key: "cancellationDate", i18n: "cancellationDate" },
  quantity: { key: "quantity", i18n: "quantity" },
  duration: { key: "duration", i18n: "duration" },
  frequency: { key: "frequency", i18n: "frequency" }
};

export const operationTypes = {
  increment: {
    key: "increment",
    mailDetails: [mailDetails.quantity.key],
    sendMailToCSPTeam: false,
    editSubscription: true,
    pendingOperation: false,
    needReason: false,
    i18n: {
      subject: "licencesPurchase",
      ending: "refundableLicenceLink",
      endingCSPandPOCs: "",
      modalConfirmationTitle: "licencesPurchase",
      modalConfirmationMessage: "confirmationIncrement"
    }
  },
  decrement: {
    key: "decrement",
    mailDetails: [],
    sendMailToCSPTeam: false,
    editSubscription: true,
    pendingOperation: false,
    needReason: false,
    i18n: {
      subject: "licencesCancellation",
      ending: "",
      endingCSPandPOCs: "",
      modalConfirmationTitle: "licencesCancellation",
      modalConfirmationMessage: "confirmationDecrement"
    }
  },
  friendlyName: {
    key: "friendlyName",
    mailDetails: [],
    sendMailToCSPTeam: false,
    editSubscription: true,
    pendingOperation: false,
    needReason: false,
    i18n: {
      subject: "changeFriendlyName",
      ending: "",
      endingCSPandPOCs: "",
      modalConfirmationTitle: "confirmationTxt",
      modalConfirmationMessage: "confirmationChangeFriendlyName"
    }
  },
  cancellation: {
    key: "cancellation",
    mailDetails: [mailDetails.reason.key, mailDetails.cancellationDate.key],
    sendMailToCSPTeam: true,
    editSubscription: false,
    pendingOperation: true,
    needReason: false,
    i18n: {
      subject: "subscriptionCancellation",
      ending: "endingClientGeneral",
      endingCSPandPOCs: "contactWithClient",
      modalConfirmationTitle: "subscriptionCancellation",
      modalConfirmationMessage: "confirmationCancellation"
    }
  },
  autoRenewEnable: {
    key: "autoRenewEnable",
    mailDetails: [],
    sendMailToCSPTeam: false,
    editSubscription: true,
    pendingOperation: false,
    needReason: false,
    i18n: {
      subject: "autoRenewEnable",
      ending: "endingEmailEnable",
      endingCSPandPOCs: "",
      modalConfirmationTitle: "confirmationTxt",
      modalConfirmationMessage: "confirmationAutoRenewEnable"
    }
  },
  autoRenewDisable: {
    key: "autoRenewDisable",
    mailDetails: [mailDetails.reason.key],
    sendMailToCSPTeam: true,
    editSubscription: false,
    pendingOperation: true,
    needReason: true,
    i18n: {
      subject: "autoRenewDisable",
      ending: "endingClientGeneral",
      endingCSPandPOCs: "endingEmailDisable",
      modalConfirmationTitle: "confirmationTxt",
      modalConfirmationMessage: "confirmationAutoRenewDisable"
    }
  },
  termDuration: {
    key: "termDuration",
    mailDetails: [mailDetails.duration.key, mailDetails.reason.key],
    sendMailToCSPTeam: true,
    editSubscription: false,
    pendingOperation: true,
    needReason: true,
    i18n: {
      subject: "changeTermDuration",
      ending: "endingClientGeneral",
      endingCSPandPOCs: "endingDurationBilling",
      modalConfirmationTitle: "changeTermDuration",
      modalConfirmationMessage: "confirmationDurationBilling"
    }
  },
  billingFrequency: {
    key: "billingFrequency",
    mailDetails: [mailDetails.frequency.key, mailDetails.reason.key],
    sendMailToCSPTeam: true,
    editSubscription: false,
    pendingOperation: true,
    needReason: true,
    i18n: {
      subject: "changeBillingFrequency",
      ending: "endingClientGeneral",
      endingCSPandPOCs: "endingDurationBilling",
      modalConfirmationTitle: "changeBillingFrequency",
      modalConfirmationMessage: "confirmationDurationBilling"
    }
  },
  purchaseReceipt: {
    key: "purchaseReceipt",
    mailDetails: [],
    sendMailToCSPTeam: false,
    editSubscription: false,
    pendingOperation: false,
    needReason: false,
    i18n: {
      subject: "purchaseReceipt",
      operation: "productsPurchase",
      ending: "refundableLicenceLink",
      endingCSPandPOCs: ""
    }
  }
};

export const productTypes = {
  onlineServices: {
    key: "OnlineServices",
    name: "Online Services",
    i18nDesc: "cspTypeDescOnlineServices"
  }
};
// azure: {
//   key: "Azure",
//   name: "Azure",
//   i18nDesc: "cspTypeDescAzure"
// },
// software: {
//   key: "Software",
//   name: "Software",
//   i18nDesc: "cspTypeDescSoftware"
// }

export const subscriptionStatus = {
  active: {
    key: "active",
    i18n: "cspStatusActive"
  },
  cancelled: {
    key: "cancelled",
    i18n: "cspStatusCancelled"
  },
  suspended: {
    key: "suspended",
    i18n: "cspStatusSuspended"
  },
  expired: {
    key: "expired",
    i18n: "cspStatusExpired"
  },
  disabled: {
    key: "disabled",
    i18n: "cspStatusDisabled"
  },
  pending: {
    key: "pending",
    i18n: "cspStatusPending"
  },
  pendingCancellation: {
    key: "pendingCancellation",
    i18n: "cspStatusPendingCancellation"
  }
};

export function getOperationType(operationType: string): any {
  for (const type in operationTypes) {
    const operationItem = operationTypes[type];
    if (operationItem.key === operationType) {
      return operationItem;
    }
  }
  return null;
}

export function getTermByBilling(billing: string): any {
  return getTerm(true, billing);
}

export function getTermByDuration(duration: string): any {
  return getTerm(false, duration);
}

function getTerm(isBilling: boolean, value: string): any {
  for (const option in termsOptions) {
    const term = termsOptions[option];
    if (isBilling) {
      if (term.billing === value) return term;
    } else if (term.duration === value) return term;
  }
  return termsOptions.none;
}

export function getOperationInfo(
  opType: string,
  lang: string,
  username: string,
  projectName: string,
  tenantId: string,
  subscriptionId?: string,
  subscriptionFriendlyName?: string,
  operationValues?
) {
  return {
    author: {
      name: i18n.t(mailDetails.author.i18n, lang),
      value: username
    },
    date: {
      name: i18n.t(mailDetails.date.i18n, lang),
      value: Moment(new Date()).format("L")
    },
    project: {
      name: i18n.tc(mailDetails.project.i18n, 1, lang),
      value: projectName
    },
    tenantId: {
      name: i18n.t(mailDetails.tenantId.i18n, lang),
      value: tenantId
    },
    subscriptionId: {
      name: i18n.t(mailDetails.subscriptionId.i18n, lang),
      value: subscriptionId
    },
    subscription: {
      name: i18n.t(mailDetails.subscription.i18n, lang),
      value: subscriptionFriendlyName
    },
    operation: {
      name: i18n.tc(mailDetails.operation.i18n, 1, lang),
      value: i18n.tc(getOperationType(opType).i18n.subject, 1, lang)
    },
    ...operationValues
  };
}

export function getPurchaseInfo(
  lang: string,
  username: string,
  projectName: string,
  tenantId: string,
  purchaseProducts
) {
  return {
    details: {
      author: {
        name: i18n.t(mailDetails.author.i18n, lang),
        value: username
      },
      date: {
        name: i18n.t(mailDetails.date.i18n, lang),
        value: Moment(new Date()).format("L")
      },
      project: {
        name: i18n.tc(mailDetails.project.i18n, 1, lang),
        value: projectName
      },
      tenantId: {
        name: i18n.t(mailDetails.tenantId.i18n, lang),
        value: tenantId
      },
      operation: {
        name: i18n.tc(mailDetails.operation.i18n, 1, lang),
        value: i18n.t(operationTypes.purchaseReceipt.i18n.operation, lang)
      }
    },
    purchaseProducts: purchaseProducts
  };
}
