import TagDx from "../../models/deliveryexperience/TagDx";
import service from "../service";
import baseEndpoint from "./serviceDx";

const endpoint = baseEndpoint + "/Tag";

export default {
  components: { service: service },
  methods: {
    async getProjectTags(projectId: number): Promise<TagDx> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${projectId}`
      );
      return new TagDx(response);
    },
    async putProjectTags(modifiedProjectTags: TagDx): Promise<TagDx> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${modifiedProjectTags.getProjectId}`,
        modifiedProjectTags
      );
      return new TagDx(response);
    },
    async postProjectTags(ProjectTags: TagDx): Promise<TagDx> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        ProjectTags
      );
      return new TagDx(response);
    }
  }
};
