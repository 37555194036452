





















































































































































































  import $ from "jquery";
  import {
    getOperationInfo,
    getOperationType,
    getTermByDuration,
    mailDetails,
    operationTypes
  } from "../../../_helpers/cspOperations";
  import cspOperationEmailBody from "../../../_helpers/email/cspOperationEmailBody";
  import emailBuilder from "../../../_helpers/email/emailBuilder";
  import { eventStates, eventTypes } from "../../../_helpers/eventTypesStates";
  import notifications from "../../../_helpers/notifications";
  import { projectTypes } from "../../../_helpers/projectMetadata";
  import config from "../../../config";
  import MailCore from "../../../models/core/MailCore";
  import PendingOperation from "../../../models/csp/PendingOperationCSP";
  import EventDx from "../../../models/deliveryexperience/EventDx";
  import mailService from "../../../services/core/mailService";
  import SingleSelectType from "../../../view-models/SingleSelectType";
  import ConfirmationSubscriptionModal from "./ConfirmationSubscriptionModal.vue";
  import NewSubscriptions from "./NewSubscriptions.vue";
  import ReviewProducts from "./ReviewProducts.vue";
  import SubscriptionRow from "./subscription/SubscriptionRow.vue";

  export default {
    name: "Subscriptions",
    components: {
      SubscriptionRow,
      ConfirmationSubscriptionModal,
      NewSubscriptions,
      ReviewProducts
    },
    props: { tenant: null },
    data() {
      return {
        reason: null,
        cancellationDate: null,
        sortSubscription: { property: "offerName", ascending: true },
        dataToUpdateSubscription: {}, // Dynamic object containing static sub data along with operation-related data (note that 'reqBody' field will contain only the PartnerCenter API request body)
        updatingSubscription: undefined,
        pendingOperation: undefined,
        cartProducts: [],
        operationInfo: undefined,
        loading: false,
        text: {
          subscriptions: this.$tc("subscription", 2),
          subscription: this.$tc("subscription", 1),
          subscriptionName: this.$t("subscriptionName"),
          nickname: this.$t("nickname"),
          quantity: this.$t("quantity"),
          status: this.$t("status"),
          addSubscriptions: this.$t("addSubscriptions"),
          operation: this.$tc("operation", 1),
          project: this.$tc("project"),
          date: this.$t("date"),
          subscriptionCancellation: this.$tc("subscriptionCancellation", 1),
          subscriptionsLoading: this.$t("subscriptionsLoading"),
          client: this.$tc("client"),
          tenant: this.$tc("tenant"),
          requestDate: this.$t("requestDate"),
          reason: this.$t("reason"),
          cancellationDate: this.$t("cancellationDate"),
          autoRenewEnable: this.$t("autoRenewEnable"),
          autoRenewDisable: this.$t("autoRenewDisable"),
          duration: this.$tc("duration", 1),
          frequency: this.$tc("frequency", 1),
          pendingOperation: this.$t("pendingOperation"),
          monthly: this.$t("monthly"),
          annual: this.$t("annual"),
          triennial: this.$t("triennial")
        }
      };
    },
    async created() {
      this.loading = true;
      if (localStorage.cartProducts) {
        // As local storage is a plan string, we parse it with a reviver function to generate key values with custom models
        this.cartProducts = JSON.parse(
          localStorage.cartProducts,
          function (key, value) {
            // As duration is a duplicated key in nested objects, whose value may be a string or an object,
            // we use typeof to only parse it as SingleSelectType when it's an object
            if (
              (key == "billing" || key == "duration") &&
              typeof value == "object"
            ) {
              return new SingleSelectType({
                key: value.key,
                name: value.name
              });
            }
            if (key == "billingOptions") {
              return value.map(billingOption => {
                return new SingleSelectType({
                  key: billingOption.key,
                  name: billingOption.name
                });
              });
            }

            return value;
          }
        );
      }
      await this.loadPendingOperationsCSP();
      if (this.tenant) {
        await this.loadSubscriptionCSPIdsByTenantId(this.tenant.id);
      }
      this.$root.$on(
        "confirmCSPOperation",
        (dataToUpdateSubscription: Object) => {
          this.dataToUpdateSubscription = dataToUpdateSubscription;
          this.$bvModal.show("confirmation-subscription-modal");
        }
      );
      let dis = this;
      this.$root.$on("reviewNewProducts", () => {
        dis.$bvModal.show("review-products-modal");
      });
      this.$root.$on("emptyCart", () => {
        dis.cartProducts = [];
      });
      this.$root.$on("addToCart", (newProduct: Object) => {
        dis.cartProducts.push(newProduct);
      });
      this.$root.$on("editFromCart", (product: Object) => {
        const index = this.cartProducts.indexOf(product, 0);
        this.cartProducts[index] = product;
      });
      this.$root.$on("deleteFromCart", (product: Object) => {
        const index = this.cartProducts.indexOf(product, 0);
        if (index > -1) {
          this.cartProducts.splice(index, 1);
        }
      });
    },
    methods: {
      setSort(sortBy: string) {
        if (this.sortSubscription.property == sortBy)
          this.sortSubscription.ascending = !this.sortSubscription.ascending;
        else this.sortSubscription = { property: sortBy, ascending: true };
      },
      sortSubscriptionsRows() {
        // Parent container of the elements to sort
        const subsContainer = $("#subs-ctn");

        // Subscriptions rows in the container
        const subscriptionsRows = subsContainer
          .find(".subscription-row-comp")
          .toArray();

        //Sort elements
        subscriptionsRows.sort((subA, subB) => {
          const propertyA = $(subA)
            .find(`.sub-details-${this.sortSubscription.property}`)
            .text();
          const propertyB = $(subB)
            .find(`.sub-details-${this.sortSubscription.property}`)
            .text();
          let result =
            this.sortSubscription.property == "quantity"
              ? Number(propertyA) - Number(propertyB)
              : propertyA.localeCompare(propertyB);
          if (this.sortSubscription.property == "status" && result == 0) {
            const commitmentDateA = new Date(
              $(subA).find(`.sub-details-status`).attr("autoRenewDate")
            );
            const commitmentDateB = new Date(
              $(subB).find(`.sub-details-status`).attr("autoRenewDate")
            );
            result = commitmentDateA >= commitmentDateB ? 1 : -1;
          }
          return this.sortSubscription.ascending ? result : result * -1;
        });

        // Delete subscriptions rows from the container and add the sorted rows
        subsContainer.empty().append(subscriptionsRows);
      },
      watchSubscriptionsRowsLoading() {
        const nRows = document.getElementsByClassName(
          "subscription-row-comp"
        ).length;
        if (nRows) {
          this.loading = false;
          if (this.getSubscriptionsCSP.length == nRows) {
            this.$emit("subsLoaded");
            this.sortSubscriptionsRows();
          }
        }
      },
      async editSubscription(body: Object) {
        await this.putSubscriptionCSP({
          tenantId: this.dataToUpdateSubscription.customerId,
          subscriptionId: this.dataToUpdateSubscription.subscriptionId,
          body: body
        });
      },
      async confirmExecuteByOperationType(confirmationInfo) {
        this.reason = confirmationInfo.reason;
        this.cancellationDate = confirmationInfo.date;
        this.updatingSubscription = this.getSubscriptionCSPById(
          this.dataToUpdateSubscription.subscriptionId
        );
        this.pendingOperation = this.getPendingOperationCSPBySubscriptionId(
          this.dataToUpdateSubscription.subscriptionId
        );

        this.launchNotification("info");
        try {
          const opType = this.dataToUpdateSubscription.operationType;
          const operationType = getOperationType(opType);
          this.processOperation(opType, operationType);

          this.operationInfo = this.getOperationInfo(
            opType,
            this.getUser.language
          );

          // Always send mail to the author of the operation
          this.postMail(opType, this.getUser.language, this.getUser.username);
          // If production send to POCs and CSP if needed
          if (config.prodEnv) {
            const pocs = this.getPOCsByProjectId(this.$route.params.projectId)
              .filter(p => p.getUsername != this.getUser.username)
              .map(p => p.getUsername);
            pocs.forEach(poc => {
              const language = this.getPeopleByUsername(poc).language;
              this.postMail(opType, language, poc);
            });
            if (operationType.sendMailToCSPTeam)
              this.postMail(opType, "ES", config.cspMail);
          }

          const eventDesc = Object.values(this.operationInfo)
            .map(
              (d: { name: string; value: string }) =>
                `<b>· ${d.name}:</b> ${d.value}.`
            )
            .join("<br>");

          await this.postEventDx(
            new EventDx({
              name: this.$tc(operationType.i18n.subject, 1),
              description: eventDesc,
              amount: 0,
              createdDate: new Date(),
              confirmed: false,
              reported: false,
              eventType:
                eventTypes[projectTypes.CSP.name].events.cspOperation.type,
              projectId: this.$route.params.projectId,
              baseline: {
                version: this.getLastProjectVersionDx(
                  this.$route.params.projectId
                ).getVersionId,
                status: eventStates.occurred.name,
                startedDate: new Date(),
                endDate: null,
                affecteds: [],
                predecessors: []
              },
              occurred: true,
              author: this.getUser.getUsername,
              notificationsPolicy: notifications.notificationsPolicies.none.name
            })
          );
        } catch (error) {
          this.launchNotification("error");
          this.$log.error(error);
        }
      },
      async processOperation(opType, operationType) {
        // Performing the automated direct operation if it's an allowed edit operation (not pending)
        if (operationType.editSubscription) {
          await this.editSubscription(this.dataToUpdateSubscription.reqBody);
        }

        // Since the cancel sub operation is a pending operation, we only perform
        // the cancellation (decrement) of any refundable licences that may exist
        if (
          opType == operationTypes.cancellation.key &&
          this.dataToUpdateSubscription.refundables
        ) {
          await this.editSubscription({
            quantity:
              this.dataToUpdateSubscription.quantity -
              this.dataToUpdateSubscription.refundables,
            autoRenewEnabled: this.dataToUpdateSubscription.autoRenewEnabled
          });
        }

        // Handling the pending operation storage if the operation is
        // not allowed to be automatedly performed (pending)
        if (operationType.pendingOperation) {
          if (this.pendingOperation) this.updatePendingOperation(opType);
          else this.createPendingOperation(opType);
        }

        this.launchNotification("success");

        // Finishing subscription edit and therefore reloading its data
        const index = this.getSubscriptionsCSP.findIndex(
          subscription =>
            subscription.id == this.dataToUpdateSubscription.subscriptionId
        );
        this.$refs[`subscriptionRow${index}`][0].finishSubscriptionEdit();
      },
      launchNotification(type: string) {
        const alertMessage = {
          text:
            type == "success"
              ? this.$t("cspAlertSuccess")
              : type == "error"
              ? this.$t("cspAlertError")
              : this.$t("cspAlertInfo"),
          time: type == "info" ? 30 : 7,
          type: type
        };
        this.setMessage(alertMessage);
      },
      async updatePendingOperation(opType: string) {
        // Setting the specific operation pending value to the existing pending operation
        switch (opType) {
          case operationTypes.cancellation.key:
            this.pendingOperation.setCancelSub = true;
            break;
          case operationTypes.autoRenewDisable.key:
            this.pendingOperation.setDisableAutorenew = true;
            break;
          case operationTypes.termDuration.key:
            this.pendingOperation.setTermDuration =
              this.dataToUpdateSubscription.reqBody.duration;
            break;
          case operationTypes.billingFrequency.key:
            this.pendingOperation.setBillingFrequency =
              this.dataToUpdateSubscription.reqBody.frequency;
            break;
        }
        await this.putPendingOperationCSP(this.pendingOperation);
      },
      async createPendingOperation(opType: string) {
        const pending = new PendingOperation({
          subscriptionId: this.dataToUpdateSubscription.subscriptionId,
          tenantId: this.dataToUpdateSubscription.customerId
        });
        // Setting the specific operation pending value
        switch (opType) {
          case operationTypes.cancellation.key:
            pending.setCancelSub = true;
            break;
          case operationTypes.autoRenewDisable.key:
            pending.setDisableAutorenew = true;
            break;
          case operationTypes.termDuration.key:
            pending.setTermDuration =
              this.dataToUpdateSubscription.reqBody.duration;
            break;
          case operationTypes.billingFrequency.key:
            pending.setBillingFrequency =
              this.dataToUpdateSubscription.reqBody.frequency;
            break;
        }
        await this.postPendingOperationCSP(pending);
      },
      resetSubscriptionsValues() {
        const index = this.getSubscriptionsCSP.findIndex(
          subscription =>
            subscription.getId == this.dataToUpdateSubscription.subscriptionId
        );
        // Accessing first ('0') SubscriptionRow component, only one for every indexed ref
        this.$refs[`subscriptionRow${index}`][0].resetSubscriptionsValues();
      },
      async postMail(
        opType: string,
        lang: string,
        username: string
      ): Promise<any> {
        const subject =
          "[CSP Operation] " +
          this.updatingSubscription.offerName +
          " - " +
          this.$tc(getOperationType(opType).i18n.subject, 1, lang);

        const body = emailBuilder.buildEmail(
          this.$tc(getOperationType(opType).i18n.subject, 1, lang),
          this.updatingSubscription.offerName,
          cspOperationEmailBody.build(
            this.getNewOperationText(opType, lang, username),
            this.operationInfo,
            this.getMailEndingText(opType, lang, username),
            lang
          ),
          lang
        );

        return await mailService.methods.postMailCore(
          new MailCore({
            to: username,
            subject: subject,
            body: body
          })
        );
      },
      getOperationInfo(opType: string, lang: string): any {
        let operationDetails = {};
        for (const detail of getOperationType(opType).mailDetails) {
          let value;
          switch (detail) {
            case mailDetails.reason.key:
              value = this.reason;
              break;
            case mailDetails.cancellationDate.key:
              value = this.dateParsed(this.cancellationDate);
              break;
            case mailDetails.duration.key:
              value = this.$t(
                getTermByDuration(
                  this.dataToUpdateSubscription.reqBody.duration
                ).i18n,
                lang
              );
              break;
            case mailDetails.frequency.key:
              value = this.$t(
                this.dataToUpdateSubscription.reqBody.frequency,
                lang
              );
              break;
            case mailDetails.quantity.key:
              value = this.dataToUpdateSubscription.variation;
              break;
            default:
              value = this.dataToUpdateSubscription.reqBody[detail];
              break;
          }
          operationDetails[detail] = {
            name: this.$tc(detail, 1, lang),
            value: value
          };
        }

        return getOperationInfo(
          opType,
          lang,
          this.getUser.getUsername,
          this.getProjectById(this.$route.params.projectId).name,
          this.tenant.id,
          this.updatingSubscription.id,
          this.dataToUpdateSubscription.reqBody &&
            this.dataToUpdateSubscription.reqBody.friendlyName
            ? this.dataToUpdateSubscription.reqBody.friendlyName
            : this.updatingSubscription.friendlyName,
          operationDetails
        );
      },
      dateParsed(date): string {
        return this.$moment(date).format("L");
      },
      getNewOperationText(
        opType: string,
        lang: string,
        username: string
      ): string {
        const userType = this.getUserType(username);
        if (userType == "csp" || userType == "poc") {
          return this.$t("newOperationByClient", lang, {
            client: this.getClients.find(
              e =>
                e.id ==
                this.getProjectById(this.$route.params.projectId).clientId
            ).getName
          });
        } else return this.$t("newOperation", lang);
      },
      getMailEndingText(
        opType: string,
        lang: string,
        username: string
      ): string {
        const userType = this.getUserType(username);
        const ending =
          userType == "user"
            ? getOperationType(opType).i18n.ending
            : getOperationType(opType).i18n.endingCSPandPOCs;
        let result;
        if (userType == "user") {
          if (
            ending == operationTypes.increment.i18n.ending ||
            ending == operationTypes.autoRenewDisable.i18n.ending
          ) {
            result = this.$t(ending, lang, {
              link: config.redirectUri + this.$route.fullPath
            });
          } else {
            result = this.$t(ending, lang);
          }
        } else {
          if (ending == operationTypes.termDuration.i18n.endingCSPandPOCs) {
            result = this.$t(ending, lang, {
              type: this.$tc(getOperationType(opType).key, 2, lang)
            });
          } else {
            result = this.$t(ending, lang);
          }
        }
        return result;
      },
      getUserType(username: string): string {
        if (username == this.getUser.username) return "user";
        else if (username == config.cspMail) return "csp";
        else return "poc";
      }
    },
    watch: {
      tenant: async function () {
        if (this.tenant) {
          await this.clearSubscriptionsCSP();
          await this.loadSubscriptionCSPIdsByTenantId(this.tenant.id);
        }
      },
      cartProducts: {
        //Deep watcher to detect changes on cart products internal fields or
        //whole object overwrite (not via push/pop) as we change them from the cart
        handler() {
          // Save the cart products to local storage every time the cart changes
          localStorage.cartProducts = JSON.stringify(this.cartProducts);
        },
        deep: true
      },
      sortSubscription: {
        handler() {
          this.sortSubscriptionsRows();
        },
        deep: true
      }
    }
  };
