export default {
  namespaced: true,
  state: {
    language: "ES"
  },
  getters: {
    getLanguage: state => {
      return state.language;
    }
  },
  mutations: {
    setLanguage(state, lang) {
      state.language = lang;
    }
  },
  actions: {
    setLanguage({ commit }, lang) {
      if (typeof lang === "string") {
        commit("setLanguage", lang);
      } else {
        commit("setLanguage", "ES");
      }
    }
  }
};
