import { render, staticRenderFns } from "./PeopleHistogram.vue?vue&type=template&id=7646e26a&scoped=true&"
import script from "./PeopleHistogram.vue?vue&type=script&lang=ts&"
export * from "./PeopleHistogram.vue?vue&type=script&lang=ts&"
import style0 from "./PeopleHistogram.vue?vue&type=style&index=0&lang=css&"
import style1 from "./PeopleHistogram.vue?vue&type=style&index=1&id=7646e26a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7646e26a",
  null
  
)

export default component.exports