export interface IDocumentTable {
  name: string;
  author: string;
  hovered: boolean;
}

export default class DocumentTable {
  private name: string;
  private author: string;
  private hovered: boolean;

  constructor(value: IDocumentTable) {
    Object.assign(this, value);
  }

  //Getters
  get getName(): string {
    return this.name;
  }

  get getHovered(): boolean {
    return this.hovered;
  }

  get getAuthor(): string {
    return this.author;
  }

  //Setters

  set setName(value: string) {
    this.name = value;
  }

  set setHovered(value: boolean) {
    this.hovered = value;
  }

  set setAuthor(value: string) {
    this.author = value;
  }
}
