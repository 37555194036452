var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"proj-header",style:({ 'padding-left': _vm.expandNavbar ? '200px' : '' })},[_c('b-img',{attrs:{"id":"header","src":this.projectCore.getHeader != null
          ? this.projectCore.getHeader
          : require('../../assets/media/background-portal.png'),"fluid":""}}),_c('div',{attrs:{"id":"proj-info-ctn"}},[_c('b-img',{staticClass:"max-height: 10px;",attrs:{"id":"icon","src":this.projectCore.getIcon != null
            ? this.projectCore.getIcon
            : require('../../assets/icons/placeholders/default-project.png'),"fluid":""}}),_c('div',{class:_vm.projectTheme ? 'theme-light' : 'theme-dark',attrs:{"id":"proj-name-type-ctn"}},[_c('strong',{attrs:{"id":"proj-name"}},[_vm._v(" "+_vm._s(_vm.projectCore.getName)+" ")]),(
            _vm.projectCore.getDescription != '' &&
            _vm.projectCore.getDescription != undefined &&
            _vm.projectCore.getDescription != null
          )?_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",value:(_vm.projectCore.getDescription),expression:"projectCore.getDescription",modifiers:{"hover":true,"right":true}}],staticClass:"icon-information"},[_c('FontAwesomeIcon',{staticClass:"fa-sm",attrs:{"icon":"fas fa-info-circle"}})],1):_vm._e(),_c('p',[_vm._v(" "+_vm._s(_vm.getClientById(_vm.projectCore.getClientId).getName)+" | "+_vm._s(_vm.determineType(_vm.projectCore.getProjectType))+" ")])])],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.service != '' && _vm.extension != ''),expression:"service != '' && extension != ''"}],staticClass:"service-breadcrumb",style:({ 'padding-left': _vm.expandNavbar ? '15.5rem' : '' })},[_c('img',{attrs:{"src":_vm.getServiceIcon(_vm.service),"height":"25","width":"25","alt":""}}),(_vm.extension != this.servicesExtensions.defaultExtension.name)?_c('a',{staticClass:"underline-on-hover",staticStyle:{"color":"#212529","margin-left":"0.5em"},on:{"click":function($event){return _vm.goToService(_vm.service)}}},[_c('strong',[_vm._v(_vm._s(_vm.service))])]):_c('span',[_c('strong',{staticStyle:{"margin-left":"0.5em"}},[_vm._v(_vm._s(_vm.service))])]),(_vm.extension != this.servicesExtensions.defaultExtension.name)?_c('span',[_c('strong',[_vm._v(" | ")]),_c('img',{attrs:{"src":_vm.getExtensionIcon(_vm.extension),"height":"25","width":"25","alt":""}}),_c('strong',{staticStyle:{"margin-left":"0.5em"}},[_vm._v(_vm._s(_vm.extension))])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }