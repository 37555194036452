import * as dotenv from "dotenv";
dotenv.config();

export default class Configuration {
  static get CONFIG(): any {
    return {
      TENANT_ID: "$VUE_APP_TENANT_ID",
      CLIENT_ID: "$VUE_APP_CLIENT_ID",
      LOCAL_REDIRECT: "$VUE_APP_LOCAL_REDIRECT",
      API_CORE_ENDPOINT: "$VUE_APP_API_CORE_ENDPOINT",
      API_DELIVERY_EXPERIENCE_ENDPOINT:
        "$VUE_APP_API_DELIVERY_EXPERIENCE_ENDPOINT",
      API_CSP_ENDPOINT: "$VUE_APP_API_CSP_ENDPOINT",
      API_SPIDERLINKSCHECKER_ENDPOINT:
        "$VUE_APP_API_SPIDERLINKSCHECKER_ENDPOINT",
      API_PARTNER_CENTER_ENDPOINT: "$VUE_APP_API_PARTNER_CENTER_ENDPOINT",
      CORE_SCOPE: "$VUE_APP_CORE_SCOPE",
      AUTHORITY: "$VUE_APP_AUTHORITY",
      DELIVERYEXP_SCOPE: "$VUE_APP_DELIVERYEXP_SCOPE",
      SPIDERLINKSCHECKER_SCOPE: "$VUE_APP_SPIDERLINKSCHECKER_SCOPE",
      CSP_SCOPE: "$VUE_APP_CSP_SCOPE",
      PROD_ENV: "$VUE_APP_PROD_ENV",
      CSP_MAIL: "$VUE_APP_CSP_MAIL"
    };
  }
  static value(name: string): any {
    if (!(name in this.CONFIG)) {
      // console.log(`Configuration: There is no key named "${name}"`);
      return;
    }

    const value = this.CONFIG[name];

    if (!value) {
      // console.log(`Configuration: Value for "${name}" is not defined`);
      return;
    }

    if (value.startsWith("$VUE_APP_")) {
      // value was not replaced, it seems we are in development.
      // Remove $ and get current value from process.env
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        if (envValue == "true" || envValue == "false")
          return envValue === "true";
        return envValue;
      } else {
        // console.log(`Configuration: Environment variable "${envName}" is not defined`);
      }
    } else {
      // value was already replaced, it seems we are in production.
      // parsing possible boolean values
      if (value == "true" || value == "false") return value === "true";
      return value;
    }
  }
}
