import PeopleCore from "../models/core/PeopleCore";

export default class HelperFunctions {
  static splitName(fullName: string): any {
    const user = {
      firstName: "",
      lastName: ""
    };
    if (fullName.includes(",")) {
      fullName = fullName.replace(",", "");
      const splitName = fullName.split(" ");
      user.firstName = splitName.slice(-1).join(" ");
      user.lastName = splitName.slice(0, -1).join(" ");
    } else {
      const splitName = fullName.split(" ");
      if (fullName) {
        if (splitName.length < 2) {
          user.firstName = fullName;
        } else if (splitName.length === 2) {
          user.firstName = splitName.slice(0, -1).join(" ");
          user.lastName = splitName.slice(-1).join(" ");
        } else {
          user.firstName = splitName.slice(0, -2).join(" ");
          user.lastName = splitName.slice(-2).join(" ");
        }
      }
    }
    return user;
  }

  static getExternalUser(username: string): PeopleCore {
    return new PeopleCore({
      username: username,
      firstName: username,
      lastName: "",
      language: "ES",
      jobPosition: "Employee",
      incorporationDate: new Date(),
      roleId: "USER",
      clientId: -1
    });
  }

  static getUserFullName(firstName: string, lastName: string): string {
    if (lastName) {
      return lastName + ", " + firstName;
    } else {
      // If it's an external user, first name will be email
      return firstName;
    }
  }
}
