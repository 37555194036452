import * as msal from "@azure/msal-browser";
import Vue from "vue";
import helpers from "../_helpers/helperFunctions";
import config from "../config";
import PeopleCore from "../models/core/PeopleCore";
import { store } from "../store";

export default class AuthService {
  msalApp: msal.PublicClientApplication;
  loginRequest = {
    tenantId: config.tenant,
    scopes: []
  };

  logger = Vue.$log;

  constructor(scope: string) {
    const msalConfig: msal.Configuration = {
      auth: {
        clientId: config.clientId,
        redirectUri: config.redirectUri,
        authority: config.authority + config.tenant,
        navigateToLoginRequestUrl: true
      },
      cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
      },
      system: {
        loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
            if (containsPii) {
              return;
            }
            switch (level) {
              case msal.LogLevel.Error:
                this.logger.error(message);
                return;
              case msal.LogLevel.Verbose:
                this.logger.debug(message);
                return;
              case msal.LogLevel.Warning:
                this.logger.warn(message);
                return;
            }
          }
        }
      }
    };

    this.loginRequest.scopes.push(scope);
    this.msalApp = new msal.PublicClientApplication(msalConfig);
  }

  async registerAuthCallback() {
    await this.msalApp
      .handleRedirectPromise()
      .then(() => {
        const account = this.msalApp.getAllAccounts()[0];
        this.msalApp.setActiveAccount(account);
      })
      .catch(error => {
        this.logger.error(`Login Redirect failed: ${error}`);
      });
  }

  signIn(): Promise<void> {
    return this.msalApp.loginRedirect(this.loginRequest);
  }

  async signOut() {
    await this.msalApp.logoutRedirect();
  }

  async getToken(): Promise<void | msal.AuthenticationResult> {
    return await this.getTokenSilent()
      .then(accessTokenResponse => {
        return accessTokenResponse;
      })
      .catch(async error => {
        if (error instanceof msal.BrowserAuthError) {
          this.logger.warn("User login is required.");
          this.signIn();
        }

        if (error instanceof msal.InteractionRequiredAuthError) {
          this.logger.warn("Interaction is required.");
          this.msalApp.acquireTokenRedirect(this.loginRequest);
        }
      });
  }

  getTokenSilent(): Promise<msal.AuthenticationResult> {
    return this.msalApp.acquireTokenSilent(this.loginRequest);
  }

  /**
   * Although get token silent does not return a boolean value,
   * we expect that if there is a token, the condition evaluation
   * will be true, otherwise false.
   */
  async isAuthenticated(): Promise<msal.AuthenticationResult> {
    return await this.getTokenSilent();
  }

  getAccountAndSave(): Promise<void> {
    // Return a promise to sync data loaded by the welcome page and async requests.
    return new Promise<void>(async (resolve, reject) => {
      const user = this.msalApp.getAllAccounts()[0];
      if (user) {
        try {
          await store.dispatch("peopleCore/loadUser", user.username);
        } catch (err) {
          if (err.state == 404) {
            this.logger.warn(
              "User does not exist, so a temp one is created into local storage."
            );
            // if user not exists into DB, create a sample user that is only saved on localstorage
            const newUser: PeopleCore = this.parseAccountToPeopleCore(user);
            store.commit("peopleCore/saveUser", newUser);
          } else {
            this.logger.error(`Error getting user: ${err}`);
            reject(err);
          }
        }
        resolve();
      }
    });
  }

  private parseAccountToPeopleCore(user: msal.AccountInfo): PeopleCore {
    const name = helpers.splitName(user.name);

    return new PeopleCore({
      username: user.username,
      firstName: name.firstName,
      lastName: name.lastName,
      language: "EN",
      jobPosition: "Employee",
      incorporationDate: new Date(),
      roleId: "USER",
      clientId: -1
    });
  }
}
