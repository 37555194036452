var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:_vm.id,attrs:{"id":_vm.id,"size":"lg","scrollable":"","centered":"","modal-class":"modified-modal","header-class":"modified-modal-header","body-class":"modified-modal-body","content-class":"modified-modal-content","footer-class":"modified-modal-footer"},on:{"ok":_vm.save},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.text.permissionsManagement))])]},proxy:true},{key:"default",fn:function(){return [_c('b-row',[_c('b-table',{attrs:{"id":"people-permissions","primary-key":"person","sticky-header":"","items":_vm.peoplePermissions,"fields":_vm.fieldsNames},scopedSlots:_vm._u([{key:"head(member)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.label))])]}},{key:"head()",fn:function(data){return [_c('span',{staticClass:"center-content"},[_vm._v(_vm._s(data.label))])]}},{key:"cell(member)",fn:function(data){return [_c('PeopleInfo',{attrs:{"person":data.value}})]}},{key:"cell(all)",fn:function(data){return [_c('div',{staticClass:"center-content"},[_c('b-form-checkbox',{attrs:{"checked":_vm.allChecked(data.item, 'read')},on:{"change":function($event){return _vm.changeAll($event, data.item, 'read')}}},[_c('div',{staticClass:"cursor-pointer",style:(_vm.allChecked(data.item, 'read')
                    ? 'color: var(--main-bg-color)'
                    : 'color: grey')},[_c('FontAwesomeIcon',{attrs:{"icon":"fa-solid fa-eye"}})],1)]),_c('b-form-checkbox',{attrs:{"checked":_vm.allChecked(data.item, 'write')},on:{"change":function($event){return _vm.changeAll($event, data.item, 'write')}}},[_c('div',{staticClass:"cursor-pointer",style:(_vm.allChecked(data.item, 'write')
                    ? 'color: var(--main-bg-color)'
                    : 'color: grey')},[_c('FontAwesomeIcon',{attrs:{"icon":"fa-solid fa-pen"}})],1)])],1)]}},{key:"cell()",fn:function(data){return [_c('div',{staticClass:"center-content"},[_c('b-form-checkbox',{on:{"change":function($event){return _vm.read($event, data.value, data)}},model:{value:(data.value.read),callback:function ($$v) {_vm.$set(data.value, "read", $$v)},expression:"data.value.read"}},[_c('div',{staticClass:"cursor-pointer",style:(data.value.read
                    ? 'color: var(--main-bg-color)'
                    : 'color: grey')},[_c('FontAwesomeIcon',{attrs:{"icon":"fa-solid fa-eye"}})],1)]),_c('b-form-checkbox',{on:{"change":function($event){return _vm.write($event, data.value, data)}},model:{value:(data.value.write),callback:function ($$v) {_vm.$set(data.value, "write", $$v)},expression:"data.value.write"}},[_c('div',{staticClass:"cursor-pointer",style:(data.value.write
                    ? 'color: var(--main-bg-color)'
                    : 'color: grey')},[_c('FontAwesomeIcon',{attrs:{"icon":"fa-solid fa-pen"}})],1)])],1)]}}])})],1)]},proxy:true},{key:"modal-footer",fn:function(ref){
                    var cancel = ref.cancel;
                    var ok = ref.ok;
return [_c('b-button',{staticClass:"button-small button-cancel",attrs:{"pill":"","disabled":_vm.loading != ''},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.text.cancel)+" ")]),_c('b-button',{staticClass:"button-small button-save button-size mr-0",attrs:{"pill":""},on:{"click":ok}},[(!_vm.loading)?_c('p',[_vm._v(_vm._s(_vm.text.save))]):_c('b-spinner',{staticClass:"button-spinner"})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }