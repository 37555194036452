import { i18n } from "../../plugins/i18";
import emailBuilder from "./emailBuilder";

function build(
  newOperation: string,
  mailDetails: any,
  products: any,
  link: string,
  header: any,
  lang: string
): string {
  return `
      <p>
        ${newOperation}. ${i18n.t("emailAlert", lang)}
      </p>
      <p>&nbsp;</p>
      <p>${i18n.t("operationDetails", lang)}:</p>
      <p>
        ${emailBuilder.bodyDetailsList(mailDetails)}
      </p>
      <p>&nbsp;</p>
        ${buildProductsTable(header, products)}
      <p>&nbsp;</p>
      <p>${link}:</p>
    `;
}

function buildProductsTable(header: any, products: any): string {
  let productsBody = `<table style="border: 1px solid #FFFFFF; width: 100%; font-size: 14px;">
                        <tr>
                          <th style="text-align: left; width: 65%; padding-left: 5px">${header["name"]}</th>
                          <th style="text-align: center; width: 15%">${header["quantity"]}</th>
                          <th style="text-align: center; width: 20%">${header["price"]}</th>
                        </tr>`;
  products.forEach(product => {
    productsBody += `<tr>
                        <td style="text-align: left; width: 65%; padding-left: 5px"><span class="text-overflow-ellipsis text-overflow-nowrap">${product.name}</span></td> 
                        <td style="text-align: center; width: 15%">${product.quantity}</td>
                        <td style="text-align: center; width: 20%">${product.totalPrice}</td>
                      </tr>`;
  });
  productsBody += `</table>`;
  return productsBody;
}

export default {
  build
};
