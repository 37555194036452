




























































































































































































































































































































































































































































































































































































































  import { VueEditor } from "vue2-editor";
  import {
    eventStates,
    eventTypes,
    getEventType,
    getEventTypesByProjectType,
    getRequestEventType
  } from "../../../../_helpers/eventTypesStates";
  import helpers from "../../../../_helpers/helperFunctions";
  import notifications from "../../../../_helpers/notifications";
  import { projectTypes } from "../../../../_helpers/projectMetadata";
  import PeopleCore from "../../../../models/core/PeopleCore";
  import ProjectCore from "../../../../models/core/ProjectCore";
  import { IBaseLineDx } from "../../../../models/deliveryexperience/BaseLineDx";
  import DocumentDx from "../../../../models/deliveryexperience/DocumentDx";
  import EventDx from "../../../../models/deliveryexperience/EventDx";
  import NotificationDx from "../../../../models/deliveryexperience/NotificationDx";
  import PeopleDx from "../../../../models/deliveryexperience/PeopleDx";
  import Permissions from "../../../../models/deliveryexperience/Permissions/Permissions";
  import RequestDx from "../../../../models/deliveryexperience/RequestDx";
  import StageDx from "../../../../models/deliveryexperience/StageDx";
  import TagDx from "../../../../models/deliveryexperience/TagDx";
  import TeamDx from "../../../../models/deliveryexperience/TeamDx";
  import PeopleIcon from "../../../../view-models/PeopleIcon";
  import SingleSelectType from "../../../../view-models/SingleSelectType";
  import CapsuleInputDate from "../../../view_elements/CapsuleInputDate.vue";
  import CapsuleLabelInput from "../../../view_elements/CapsuleLabelInput.vue";
  import CapsuleMultiselect from "../../../view_elements/CapsuleMultiselect.vue";
  import DocsAttach from "../../../view_elements/DocsAttach.vue";
  import PeopleDefaultIcon from "../../../view_elements/PeopleDefaultIcon.vue";

  export default {
    name: "TimelineItemForm",
    components: {
      CapsuleInputDate,
      CapsuleLabelInput,
      VueEditor,
      DocsAttach,
      PeopleDefaultIcon,
      CapsuleMultiselect
    },
    props: {
      projectId: Number,
      type: String,
      action: { type: String, default: "create" },
      id: String,
      itemId: { type: String, default: undefined },
      projectType: String,
      currentVersionDxId: Number,
      projectCore: ProjectCore
    },
    data() {
      const currentDate: Date = new Date();
      const undefinedSingleSelect = {
        key: undefined,
        name: undefined
      };
      return {
        loading: "",
        lastVersion: String(""),
        eventStage: new SingleSelectType({
          key: undefined,
          name: ""
        }),
        timeAndMaterialsEventTypes: [
          eventTypes.PEOPLE.events.unassignment.type,
          eventTypes.PEOPLE.events.newIncorporation.type
        ],
        previousAffected: new SingleSelectType(undefinedSingleSelect),
        affectedEvent: new SingleSelectType(undefinedSingleSelect),
        affectedStage: new SingleSelectType(undefinedSingleSelect),
        related: new SingleSelectType(undefinedSingleSelect),
        typeSelected: new SingleSelectType(undefinedSingleSelect),
        hour: new SingleSelectType(undefinedSingleSelect),
        notificationsPolicy: new SingleSelectType({
          key: "ALL_MEMBERS",
          name: "All members"
        }),
        activeFields: {
          description: Boolean(false)
        },
        descriptionFocus: Boolean(false),
        eventStageStatus: undefined,
        eventStageStatusClass: undefined,
        eventStageOccurred: Boolean(false),
        createdEventId: null,
        createdEvent: null,
        eventToDelete: null,
        createdStageId: null,
        createdStage: null,
        stageToDelete: null,
        createdOrUpdatedEventId: null,
        createdOrUpdatedStageId: null,
        notifyCreatedDocs: false,
        notifyDeletedDocs: false,
        firstEventStageClass: Boolean(true),
        selectedEventStage: Boolean(false),
        selectedTag: null,
        docsVisibleToAll: Boolean(true),
        savedEventStage: Boolean(false),
        actualMembersBeforeUpdate: [], //Array of peopleIcon
        members: [], //List of peopleIcon[]
        membersCreated: 0,
        membersAmount: 0,
        peopleHasChanged_: {
          peopleToAdd: [],
          peopleToRemove: [],
          hasChanged: Boolean
        },
        date: currentDate,
        startDate: currentDate,
        endDate: currentDate,
        minStageDaysLength: 2,
        firstEndDateComputed: true,
        description: "",
        liabilityEconomicString: projectTypes.liabilityEconomic.name,
        liabilityFullString: projectTypes.liabilityFull.name,
        maxAmount: Number,
        oldEventIndex: "",
        peopleDxIds: [], ////Array of people user-names
        saveDocsAttached: false,
        amountUnits: null,
        showAmountCapacity: false,
        updateTimelineChartType: undefined,
        dismissSecs: 5,
        dismissCountDown: 0,
        eventReload: eventTypes[projectTypes.capacity.name].events.reload.type,
        eventConsume:
          eventTypes[projectTypes.capacity.name].events.consume.type,
        eventBilling:
          eventTypes[projectTypes.capacity.name].events.billing.type,
        eventTypesToltips: {},
        colorSelected: { color: "bright-purple" },
        colorsOptions: [
          { color: "bright-purple" },
          { color: "main-bg-color" },
          { color: "green" },
          { color: "light-claret" },
          { color: "tech-red" },
          { color: "secondary-color" }
        ],
        text: {
          alertText: this.$t("newVersionMsg"),
          newEvent: this.$t("newEvent"),
          newStage: this.$t("newStage"),
          editEvent: this.$t("editEvent"),
          editStage: this.$t("editStage"),
          name: this.$t("name"),
          enterName: this.$t("enterName"),
          type: this.$t("type"),
          selectType: this.$t("selectType"),
          members: this.$tc("member", 2),
          startDate: this.$t("startDate"),
          endDate: this.$t("endDate"),
          selectAnItem: this.$t("selectAnItem"),
          selectAnEvent: this.$t("selectAnEvent"),
          selectAStage: this.$t("selectAStage"),
          stage: this.$tc("stage", 1),
          date: this.$t("date"),
          hour: this.$tc("hour", 1),
          affectedEvents: this.$t("affectedEvents"),
          affectedStages: this.$t("affectedStages"),
          related: this.$t("related"),
          description: this.$t("description"),
          attachments: this.$tc("attachments", 2),
          save: this.$t("save"),
          cancel: this.$t("cancel"),
          delete: this.$t("delete"),
          selectEvents: this.$t("selectEvents"),
          selectStages: this.$t("selectStages"),
          pendingToOccur: this.$t(eventStates.pendingToOccur.i18n),
          pendingToConfirm: this.$t(eventStates.pendingToConfirm.i18n),
          occurred: this.$t(eventStates.occurred.i18n),
          cancelled: this.$t(eventStates.cancelled.i18n),
          amount: this.$t("amount"),
          maxInputAlert: this.$t("maxInputAlert"),
          eventAffecPred: this.$t("eventAffecPred"),
          stageAffecPred: this.$t("stageAffecPred"),
          minAmountAlert: this.$t("minAmountAlert"),
          author: this.$t("author"),
          messageAlertSuccess: this.$t("messageAlertSuccess"),
          tag: this.$tc("tag", 1),
          dropdownAdd: this.$t("dropdownAdd"),
          amountUnitsPlaceholder: this.$t("enterValue"),
          searchAMember: this.$t("searchAMember"),
          notifyTo: this.$t("notifyTo"),
          selectNotificationsPolicy: this.$t("selectNotificationsPolicy"),
          color: this.$t("color"),
          creatingSlowOperation: this.$t("creatingSlowOperation")
        }
      };
    },
    async mounted() {
      this.date = new Date();
      this.sortEventsByDate();
      this.sortStagesByDate();
    },
    computed: {
      isTeamEvent(): boolean {
        return (
          this.typeSelected.getKey ===
            eventTypes.PEOPLE.events.newIncorporation.type ||
          this.typeSelected.getKey ===
            eventTypes.PEOPLE.events.unassignment.type
        );
      },
      getUserCreator(): PeopleCore | undefined {
        if (this.action === "create") {
          return this.getUser;
        } else {
          if (this.type === "event") {
            if (
              this.eventSelected != undefined &&
              this.eventSelected.getAuthor != undefined
            ) {
              return this.getPeopleByUsername(this.eventSelected.getAuthor);
            }
          } else {
            if (
              this.stageSelected != undefined &&
              this.stageSelected.getAuthor != undefined
            ) {
              return this.getPeopleByUsername(this.stageSelected.getAuthor);
            }
          }
        }
        return undefined;
      },
      author: function (): PeopleIcon {
        return PeopleIcon.newPeopleIconFromCore(this.getUserCreator);
      },
      getItemCreatorName(): string {
        if (this.author != undefined) {
          return helpers.getUserFullName(
            this.author.getFirstName,
            this.author.getLastName
          );
        }
        return " ";
      },
      getProjectType(): string {
        if (this.projectType != undefined) {
          return this.projectType;
        }
        return "";
      },
      requestsDx: function (): RequestDx[] {
        return this.getRequestsDxByProjectId(this.projectId);
      },
      stagesDx: function (): StageDx[] {
        return this.getStagesDxByProjectId(this.projectId);
      },
      docsDx(): DocumentDx[] {
        if (this.eventStage.getKey) {
          if (this.type === "event") {
            return this.getDocumentsDxByEventId(this.eventStage.getKey);
          } else {
            return this.getDocumentsDxByStageId(this.eventStage.getKey);
          }
        }
        return [];
      },
      projectPeopleDx(): PeopleDx[] {
        return this.getPeopleDxByProjectId(
          Number(this.$route.params.projectId)
        );
      },
      peopleDxProjectUsernames(): string[] {
        return this.projectPeopleDx.map(personDx => personDx.getUsername);
      },
      peopleCoreAvailableWithoutTeam(): PeopleCore[] {
        return this.getAvailablePeople(
          this.getUser.getClientId,
          this.getTeamDxByProjectId(this.projectId).people
        );
      },
      peopleCoreAvailable(): PeopleCore[] {
        return this.getAvailablePeopleByClientIdAndProject(
          this.getUser.getClientId
        );
      },
      peopleToShowDropdown(): PeopleIcon[] {
        let people: PeopleIcon[] = [];
        if (
          this.typeSelected.getKey ==
          eventTypes.PEOPLE.events.newIncorporation.type
        ) {
          people = this.mapPeopleCoreToPeopleIcon(
            this.peopleCoreAvailableWithoutTeam
          ).filter(
            p =>
              !this.getTeamDxByProjectId(this.projectId).people.includes(
                p.getUsername
              )
          );
        } else if (
          this.typeSelected.getKey == eventTypes.PEOPLE.events.unassignment.type
        ) {
          people = this.mapTeamToPeopleIcon(
            this.getTeamDxByProjectId(this.projectId).people
          );
        } else {
          people = this.mapPeopleCoreToPeopleIcon(this.peopleCoreAvailable);
        }
        if (this.action === "create" && !this.isTeamEvent) {
          const index = people.findIndex(
            peopleIcon => peopleIcon.getUsername === this.author.username
          );
          if (index != -1) {
            people.splice(index, 1);
          }
        }
        return people;
      },
      eventSelected(): EventDx | undefined {
        if (this.eventStage.getKey) {
          return this.getEventsDxByProjectId(this.projectId).find(
            event => event.getId == this.eventStage.getKey
          );
        } else {
          return undefined;
        }
      },
      eventSelectedType(): SingleSelectType | undefined {
        if (this.eventSelected != undefined) {
          return this.eventTypesOptions.find(
            event => event.key == this.eventSelected.getEventType
          );
        } else {
          return undefined;
        }
      },
      stageSelected(): StageDx | undefined {
        if (this.eventStage.getKey) {
          return this.stagesDx.find(
            stageDx => stageDx.getId == this.eventStage.getKey
          );
        } else {
          return undefined;
        }
      },
      eventStageAffected(): SingleSelectType {
        if (this.eventSelected) {
          if (this.eventSelected.getLastHistoryBaseline.affecteds.length > 0) {
            const affected: EventDx = this.getEventsDxByProjectId(
              this.projectId
            ).find(
              event =>
                event.getId ==
                this.eventSelected.getLastHistoryBaseline.affecteds[0]
            );
            return new SingleSelectType({
              key: affected.getId,
              name: affected.getName,
              icon: this.eventTypesOptions.find(
                event => event.key == affected.getEventType
              ).icon
            });
          } else {
            return new SingleSelectType({
              key: undefined,
              name: undefined
            });
          }
        } else if (this.stageSelected) {
          if (this.stageSelected.getLastHistoryBaseline.affecteds.length > 0) {
            const affected: StageDx = this.stagesDx.find(
              stage =>
                stage.getId ==
                this.stageSelected.getLastHistoryBaseline.affecteds[0]
            );
            return new SingleSelectType({
              key: affected.getId,
              name: affected.getName
            });
          } else {
            return new SingleSelectType({
              key: undefined,
              name: undefined
            });
          }
        } else {
          return new SingleSelectType({
            key: undefined,
            name: undefined
          });
        }
      },
      eventRequestRelated(): SingleSelectType {
        const itemRelated = new SingleSelectType({
          key: undefined,
          name: undefined
        });
        if (this.eventSelected && this.eventSelected.getRelated) {
          let related = this.getEventsDxByProjectId(this.projectId).find(
            event => event.getId == this.eventSelected.getRelated
          );

          if (related) {
            itemRelated.setName = related.getName;
            itemRelated.setIcon = this.eventTypesOptions.find(
              event => event.key == related.getEventType
            ).icon;
          } else {
            related = this.requestsDx.find(
              request => request.getId == this.eventSelected.getRelated
            );
            itemRelated.setName = related.getIssue;
            itemRelated.setIcon =
              "services/deliveryexperience/timeline/" +
              getRequestEventType(related.getType).icon.normal;
          }
          itemRelated.setKey = related.getId;
        }

        return itemRelated;
      },
      eventStatusIsOccurred(): boolean {
        if (this.action === "create") {
          if (this.eventStageStatus == eventStates.occurred.name) {
            this.resetAffectedEvent();
            return true;
          }
        } else if (["edit", "edit_popup"].includes(this.action)) {
          if (this.eventStageStatus == eventStates.occurred.name) {
            this.resetAffectedEvent();
            return true;
          }
        }
        return false;
      },
      stageStatusIsOccurred(): boolean {
        if (this.action === "create") {
          if (this.eventStageStatus == eventStates.occurred.name) {
            this.resetAffectedStage();
            return true;
          }
        } else if (["edit", "edit_popup"].includes(this.action)) {
          if (this.eventStageStatus == eventStates.occurred.name) {
            this.resetAffectedStage();
            return true;
          }
        }
        return false;
      },
      sortedEventsTimeAndMaterials(): Object {
        return this.getEventsDxByProjectId(this.projectId)
          .filter(event =>
            this.timeAndMaterialsEventTypes.includes(event.getEventType)
          )
          .sort(function (a, b) {
            //Change 'a' and 'b' to order in descendent order.
            return (
              new Date(a.getLastHistoryBaseline.startedDate).getTime() -
              new Date(b.getLastHistoryBaseline.startedDate).getTime()
            );
          });
      },
      disableCapsuleEditForEvent(): boolean {
        if (["edit", "edit_popup"].includes(this.action)) {
          if (
            this.eventStage.getKey != undefined &&
            this.eventSelected != undefined
          ) {
            if (
              this.eventSelected.getLastHistoryBaseline.status !=
                eventStates.pendingToOccur.name &&
              !this.timeAndMaterialsEventTypes.includes(
                this.eventSelected.getEventType
              )
            ) {
              return true;
            }
          }
        }
        return false;
      },
      disableCapsuleEditForStage(): boolean {
        if (["edit", "edit_popup"].includes(this.action)) {
          if (
            this.eventStage.getKey != undefined &&
            this.stageSelected != undefined
          ) {
            if (
              this.stageSelected.getLastHistoryBaseline.status !=
              eventStates.pendingToOccur.name
            ) {
              return true;
            }
          }
        }
        return false;
      },
      //Disable 'save' button
      isDisabled(): boolean {
        if (this.eventStage.getName == "") {
          return true;
        }
        if (this.type == "event") {
          if (this.typeSelected.getKey == undefined || this.date == undefined) {
            return true;
          } else {
            switch (this.typeSelected.getKey) {
              case eventTypes[projectTypes.capacity.name].events.consume.type:
              case eventTypes[projectTypes.capacity.name].events.reload.type:
              case eventTypes[projectTypes.capacity.name].events.billing.type:
                if (
                  this.amountUnits == null ||
                  (this.typeSelected.getKey ==
                    eventTypes[projectTypes.capacity.name].events.consume
                      .type &&
                    this.amountUnits > this.maxAmount) ||
                  this.amountUnits == 0
                ) {
                  return true;
                }
                break;
              case eventTypes.PEOPLE.events.unassignment.type:
              case eventTypes.PEOPLE.events.newIncorporation.type:
                if (this.members.length <= 0) {
                  return true;
                }
                break;
              default:
                break;
            }
          }
        } else {
          //this.type == "stage"
          if (
            this.startDate == undefined ||
            this.endDate == undefined ||
            this.startDate.getTime() > this.endDate.getTime()
          ) {
            return true;
          }
        }
        return false;
      },
      sameDay(): Date {
        let result = new Date(this.startDate);
        if (!this.firstEndDateComputed) {
          result.setDate(result.getDate() - 1);
        }
        return this.applyStageDateMinLength(result);
      },
      datesFrom(): Date {
        return new Date(this.projectCore.startDate);
      },
      datesTo(): Date {
        return new Date(this.projectCore.endDate);
      },
      hours(): SingleSelectType[] {
        const endHour = this.$moment().set({ hour: 20, minute: 0 });
        let startHour = this.$moment().set({ hour: 8, minute: 0, second: 0 });
        let result = [];
        while (startHour <= endHour) {
          result.push(
            new SingleSelectType({
              key: startHour.format("HH:mm"),
              name: startHour.format("HH:mm")
            })
          );
          startHour.add(15, "minutes");
        }
        return result;
      },
      // Hour is mandatory
      getStartedDate(): Date {
        let startedDate = undefined;
        if (this.date) {
          startedDate = new Date(
            this.date.getFullYear(),
            this.date.getMonth(),
            this.date.getDate()
          );
          if (this.hour.getKey) {
            const hour: number = parseInt(this.hour.getKey.split(":")[0]);
            const minutes: number = parseInt(this.hour.getKey.split(":")[1]);
            startedDate.setHours(hour);
            startedDate.setMinutes(minutes);
          }
        }
        return startedDate;
      },
      eventTypesOptions(): SingleSelectType[] {
        const projectEvents: any[] = getEventTypesByProjectType(
          this.projectType
        );
        return Object.values(projectEvents)
          .filter(
            // We filter OUT the Virtual Visit event for non-Sogeti clients
            projectEvent =>
              !(
                projectEvent.type ===
                  eventTypes.COMMON.events.virtualVisit.type &&
                !this.getUser.isSogeti()
              )
          )
          .map(event => {
            this.eventTypesToltips[event.type] = this.$t(event.i18nDesc);
            return new SingleSelectType({
              key: event.type,
              name: event.name,
              icon: "services/deliveryexperience/timeline/" + event.icon.normal
            });
          });
      },
      stageSelector(): SingleSelectType[] {
        return this.stagesDx.map(
          stage =>
            new SingleSelectType({
              key: stage.getId,
              name: stage.getName
            })
        );
      },
      affectedEventsList(): SingleSelectType[] {
        if (this.date) {
          return this.getEventsDxByProjectId(this.projectId)
            .filter(
              event =>
                ![
                  eventTypes[projectTypes.capacity.name].events.reload.type,
                  eventTypes[projectTypes.capacity.name].events.billing.type,
                  eventTypes[projectTypes.capacity.name].events.consume.type,
                  eventTypes.PEOPLE.events.unassignment.type,
                  eventTypes.PEOPLE.events.newIncorporation.type
                ].includes(event.getEventType) &&
                event.getLastHistoryBaseline.status !=
                  eventStates.occurred.name &&
                new Date(event.getLastHistoryBaseline.startedDate).getTime() >
                  new Date(this.date).getTime() &&
                event.getLastHistoryBaseline.affecteds.length == 0 &&
                event.getLastHistoryBaseline.predecessors.length == 0
            )
            .map(
              event =>
                new SingleSelectType({
                  key: event.getId,
                  name: event.getName,
                  icon: this.eventTypesOptions.find(
                    type => type.key == event.getEventType
                  ).icon
                })
            );
        }
        return [];
      },
      affectedStagesList(): SingleSelectType[] {
        if (this.endDate) {
          return this.stagesDx
            .filter(
              stage =>
                stage.getLastHistoryBaseline.status !=
                  eventStates.occurred.name &&
                new Date(stage.getLastHistoryBaseline.startedDate).getTime() >
                  new Date(this.endDate).getTime() &&
                stage.getLastHistoryBaseline.affecteds.length == 0 &&
                stage.getLastHistoryBaseline.predecessors.length == 0
            )
            .map(
              stage =>
                new SingleSelectType({
                  key: stage.getId,
                  name: stage.getName
                })
            );
        }
        return [];
      },
      relatedEventsRequestsList(): SingleSelectType[] {
        const events = this.getEventsDxByProjectId(this.projectId).map(
          event =>
            new SingleSelectType({
              key: event.getId,
              name: event.getName,
              icon: this.eventTypesOptions.find(
                evType => evType.key == event.getEventType
              ).icon
            })
        );
        const requests = this.requestsDx.map(
          request =>
            new SingleSelectType({
              key: request.getId,
              name: request.getIssue,
              icon:
                "services/deliveryexperience/timeline/" +
                getRequestEventType(request.getType).icon.normal
            })
        );
        return events.concat(requests);
      },
      projectTagsDx(): TagDx {
        let projectTagsDx = this.getProjectTags;
        if (!Object.keys(projectTagsDx).length) {
          this.postTagsDx(
            new TagDx({
              projectId: this.projectId,
              tags: []
            })
          );
          projectTagsDx = this.getProjectTags;
        }
        return projectTagsDx;
      },
      amountUnit(): string {
        if (this.projectType == projectTypes.capacity.name && this.projectId) {
          const capacityProject = this.getProjectById(this.projectId);
          return this.getCapacityTypeById(capacityProject.getCapacityTypeId)
            .getName;
        } else return this.text.amountUnitsPlaceholder;
      }
    },
    methods: {
      setHour(value: SingleSelectType) {
        if (value) this.hour = value;
        else
          this.hour = new SingleSelectType({
            key: undefined,
            name: undefined
          });
      },
      getEvent(eventId: string): EventDx {
        return this.getEventsDxByProjectId(this.projectId).find(
          event => event.getId == eventId
        );
      },
      getStage(stageId: string): StageDx {
        return this.stagesDx.find(stage => stage.getId == stageId);
      },
      getPeopleCoreUsernames(peopleCore: PeopleCore[]): string[] {
        return peopleCore.map(personCore => personCore.getUsername);
      },
      mapPeopleCoreToPeopleIcon(peopleCore: PeopleCore[]): PeopleIcon[] {
        return peopleCore.map(person => {
          let foundUser = this.projectPeopleDx.find(
            p => p.getUsername == person.getUsername
          );
          if (!foundUser) {
            foundUser = person;
          }
          return PeopleIcon.newPeopleIconFromPeople(foundUser);
        });
      },
      mapTeamToPeopleIcon(team: string[]): PeopleIcon[] {
        return team.map(username => {
          let foundUser = this.projectPeopleDx.find(
            p => p.getUsername == username
          );
          if (!foundUser) {
            foundUser = this.getPeopleByUsername(username);
          }
          return PeopleIcon.newPeopleIconFromPeople(foundUser);
        });
      },
      mapPeopleToPeopleIcon(people): PeopleIcon[] {
        return people.map(person => PeopleIcon.newPeopleIconFromPeople(person));
      },
      showAffectedEvents(): boolean {
        if (this.eventSelected != undefined) {
          //eventSelected is an event predecessor or is not predecessor and not affected
          if (
            this.eventSelected.getLastHistoryBaseline.predecessors.length == 0
          ) {
            return true;
          }
          //if eventSelected has predecessors or affecteds
          return false;
        }
        //If any event selected return true
        return true;
      },
      showAffectedStages(): boolean {
        if (this.stageSelected != undefined) {
          // Selected stage is not a predecessor or affected by other event
          return (
            this.stageSelected.getLastHistoryBaseline.predecessors.length == 0
          );
        }
        //If any stage selected return true
        return true;
      },
      getEventSelector(): SingleSelectType[] {
        return this.getEventsDxByProjectId(this.projectId)
          .filter(
            eventDx =>
              ![
                eventTypes.PEOPLE.events.unassignment.type,
                eventTypes.PEOPLE.events.newIncorporation.type
              ].includes(eventDx.getEventType)
          )
          .map(
            event =>
              new SingleSelectType({
                key: event.getId,
                name: event.getName,
                icon: this.eventTypesOptions.find(
                  e => e.key == event.getEventType
                ).icon
              })
          );
      },
      getNotificationsPolicies(): SingleSelectType[] {
        let policies: SingleSelectType[] = [];
        for (const policy in notifications.notificationsPolicies) {
          policies.push(
            new SingleSelectType({
              key: notifications.notificationsPolicies[policy].name,
              name: this.$t(notifications.notificationsPolicies[policy].i18n)
            })
          );
        }
        return policies;
      },
      setStageStartDate(value: Date) {
        if (this.firstEndDateComputed) {
          // We switch it only the first time to prevent date picker's
          // datesFrom from enabling the day after current date
          this.firstEndDateComputed = false;
        }
        this.startDate = value;

        if (
          this.endDate &&
          this.$moment(this.endDate).diff(
            this.$moment(this.startDate),
            "days"
          ) < this.minStageDaysLength
        ) {
          this.endDate = this.applyStageDateMinLength(this.startDate);
        }
      },
      // Applies days offset as stage length minimum is 3 days
      applyStageDateMinLength(date: Date): Date {
        const offsetDate = new Date(date); // Cloning object to prevent changes by reference
        offsetDate.setDate(offsetDate.getDate() + this.minStageDaysLength);
        return offsetDate;
      },
      setRelatedRequest(requestDx: RequestDx) {
        this.related = new SingleSelectType({
          key: requestDx.getId,
          name: requestDx.getIssue,
          icon:
            "services/deliveryexperience/timeline/" +
            getRequestEventType(requestDx.getType).icon.normal
        });
      },
      getAmountLimit(): number {
        if (
          this.typeSelected.getKey ==
          eventTypes[projectTypes.capacity.name].events.consume.type
        ) {
          this.maxAmount = parseFloat(this.getRemainingAmount().toFixed(2));
        }
        return this.maxAmount;
      },
      getRemainingAmount(): number {
        let remainingAmount: number = 0;
        let correction: number = 0;
        this.getEventsDxByProjectId(this.projectId).forEach(event => {
          switch (event.getEventType) {
            case eventTypes[projectTypes.capacity.name].events.reload.type:
              remainingAmount += event.getAmount;
              break;
            case eventTypes[projectTypes.capacity.name].events.consume.type:
              remainingAmount -= event.getAmount;
              break;
          }
        });
        this.action == "edit_popup" &&
        this.eventSelectedType.getKey ==
          eventTypes[projectTypes.capacity.name].events.consume.type
          ? (correction = this.eventSelected.getAmount)
          : (correction = 0);
        return (remainingAmount += correction);
      },
      getNewEventData(): EventDx {
        const eventDx: EventDx = new EventDx({
          name: this.eventStage.getName,
          eventType: this.typeSelected.key,
          description: this.description,
          createdDate: new Date(),
          projectId: this.projectId,
          occurred: this.eventStageOccurred,
          confirmed: this.eventStageOccurred,
          reported: false,
          baseline: {
            version: this.lastVersion,
            status: this.eventStageStatus,
            startedDate: this.getStartedDate,
            endDate: null,
            affecteds: [],
            predecessors: []
          },
          author: this.getUser.getUsername,
          tag: this.selectedTag,
          related: this.related.getKey,
          notificationsPolicy: this.notificationsPolicy.key
        });

        if (this.amountUnits != null) {
          eventDx.setAmount = this.amountUnits;
        }

        if (this.isTeamEvent) {
          eventDx.setTamUser = this.members[0].getUsername;
        }

        return eventDx;
      },
      getNewStageData(): StageDx {
        return new StageDx({
          name: this.eventStage.getName,
          description: this.description,
          createdDate: new Date(),
          projectId: this.projectId,
          occurred: this.eventStageOccurred,
          confirmed: this.eventStageOccurred,
          reported: false,
          baseline: {
            version: this.lastVersion,
            status: this.eventStageStatus,
            startedDate: this.startDate,
            endDate: this.endDate,
            affecteds: [],
            predecessors: []
          },
          author: this.getUser.getUsername,
          tag: this.selectedTag,
          notificationsPolicy: this.notificationsPolicy.key,
          color: this.colorSelected["color"]
        });
      },
      resetAffectedEvent(): void {
        this.affectedEvent = new SingleSelectType({
          key: undefined,
          name: undefined
        });
      },
      resetAffectedStage(): void {
        this.affectedStage = new SingleSelectType({
          key: undefined,
          name: undefined
        });
      },
      getStatusClass(status: string): string | undefined {
        switch (status) {
          case eventStates.occurred.name:
            return "status-ocurred";
          case eventStates.pendingToConfirm.name:
            return "status-pending-confirm";
          case eventStates.pendingToOccur.name:
            return "status-pending-occur";
          case eventStates.cancelled.name:
            return "status-cancelled";
          default:
            return undefined;
        }
      },
      // If action == Create. Returns peopleIds to add in DocumentInfo
      async setPeopleDxIds() {
        let peopleDxInEventStage: PeopleDx[] = [];
        if (!this.docsVisibleToAll) {
          if (this.action === "create") {
            if (this.type === "event") {
              if (this.createdEventId) {
                peopleDxInEventStage = this.getPeopleDxByEventId(
                  this.createdEventId
                );
              }
            } else {
              if (this.createdStageId) {
                peopleDxInEventStage = this.getPeopleDxByStageId(
                  this.createdStageId
                );
              }
            }
          } else if (["edit", "edit_popup"].includes(this.action)) {
            if (this.type === "event") {
              if (this.eventStage.getKey) {
                peopleDxInEventStage = this.getPeopleDxByEventId(
                  this.eventStage.getKey
                );
              }
            } else {
              if (this.eventStage.getKey) {
                peopleDxInEventStage = this.getPeopleDxByStageId(
                  this.eventStage.getKey
                );
              }
            }
          }

          for (const personDx of peopleDxInEventStage) {
            this.peopleDxIds.push(personDx.getId);
          }
        }
      },
      addDaysFromstartDate(days: number): Date {
        const result = new Date(this.startDate);
        result.setDate(result.getDate() + days);
        return result;
      },
      // Return Array of people user-names of 'PeopleToAdd' and 'peopleToRemove' from eventDx/stageDx
      peopleHasChanged(): {
        peopleToAdd: PeopleIcon[];
        peopleToRemove: PeopleIcon[];
        hasChanged: Boolean;
      } {
        let result: {
          peopleToAdd: PeopleIcon[];
          peopleToRemove: PeopleIcon[];
          hasChanged: Boolean;
        } = {
          peopleToAdd: [],
          peopleToRemove: [],
          hasChanged: false
        };
        if (
          this.actualMembersBeforeUpdate.length > 0 ||
          this.members.length > 0
        ) {
          // PEOPLE CORE TO ADD IN EVENT
          for (const memberIcon of this.members) {
            if (
              !this.actualMembersBeforeUpdate.find(
                personIcon => personIcon.getUsername == memberIcon.getUsername
              )
            ) {
              result.peopleToAdd.push(memberIcon);
            }
          }
          // PEOPLE TO REMOVE FROM EVENT
          for (const memberIcon of this.actualMembersBeforeUpdate) {
            if (
              !this.members.find(
                personIcon => personIcon.getUsername == memberIcon.getUsername
              )
            ) {
              result.peopleToRemove.push(memberIcon);
            }
          }
        }
        if (result.peopleToAdd.length > 0 || result.peopleToRemove.length > 0) {
          result.hasChanged = true;
        }
        return result;
      },
      setCreatedOrUpdatedEventId(): string {
        if (this.type === "event" && this.action === "create") {
          this.createdOrUpdatedEventId = this.createdEventId;
        } else if (
          this.type === "event" &&
          ["edit", "edit_popup"].includes(this.action)
        ) {
          this.createdOrUpdatedEventId = this.eventStage.getKey;
        } else {
          this.createdOrUpdatedEventId = null;
        }
        return this.createdOrUpdatedEventId;
      },
      setCreatedOrUpdatedStageId(): string {
        if (this.type === "stage" && this.action === "create") {
          this.createdOrUpdatedStageId = this.createdStageId;
        } else if (
          this.type === "stage" &&
          ["edit", "edit_popup"].includes(this.action)
        ) {
          this.createdOrUpdatedStageId = this.eventStage.getKey;
        } else {
          this.createdOrUpdatedStageId = null;
        }
        return this.createdOrUpdatedStageId;
      },
      // Emit from DocsAttach component once attached docs are saved
      async docsUploadedHandler(
        documentsIds: string[],
        documentsNames: string[],
        notifyCreated: boolean,
        notifyDeleted: boolean
      ) {
        this.notifyCreatedDocs = notifyCreated;
        this.notifyDeletedDocs = notifyDeleted;
        if (notifyCreated || notifyDeleted) {
          if (this.eventSelected)
            await this.sendNotificationDx(this.eventSelected);
          if (this.stageSelected)
            await this.sendNotificationDx(this.stageSelected);
        }
        this.handleCloseSubmit();
      },
      //Save event/stage changes
      async save(bvModalEvent) {
        this.membersAmount = this.members.length;
        this.loading = "save";
        bvModalEvent.preventDefault();
        // If document is added wait until event/stage is created and then upload docs
        await this.saveEventStage();

        await this.setPeopleDxIds(); // Setting peopleDxIds for docs readers before saving docs
        if (this.type == "event") {
          await this.sendNotificationDx(
            this.action == "create" ? this.createdEvent : this.eventSelected
          );
        } else if (this.type == "stage") {
          await this.sendNotificationDx(
            this.action == "create" ? this.createdStage : this.stageSelected
          );
        }
        this.saveDocsAttached = true; // Triggers Docs saving due to prop change
      },
      async sendNotificationDx(item) {
        const notificationDx = new NotificationDx({
          itemName: item.getName,
          projectId: this.projectId,
          itemType: this.type == "event" ? item.getEventType : "stage",
          itemId: item.getId,
          recipients: notifications.getPeopleForRecipients(
            item.getNotificationsPolicy,
            this.projectPeopleDx,
            item.getAuthor,
            this.getUser.getUsername,
            item.getId
          ),
          action: this.getNotificationAction(),
          author: this.getUser.getUsername
        });
        if (notificationDx.recipients.length)
          await this.postNotificationDx(notificationDx);
      },
      getNotificationAction(): string | null {
        if (this.notifyCreatedDocs && this.notifyDeletedDocs)
          return "docChanged";
        else if (this.notifyCreatedDocs) return "docUploaded";
        else if (this.notifyDeletedDocs) return "docDeleted";
        else if (this.createdEvent || this.createdStage) return "created";
        else if (this.eventSelected || this.stageSelected) return "edited";
        else if (this.eventToDelete || this.stageToDelete) return "deleted";
        else return null;
      },
      handleCloseSubmit(): void {
        this.sortEventsByDate();
        this.sortStagesByDate();
        this.$emit("rerenderOverview");
        this.$nextTick(async () => {
          await this.$refs[this.id].hide();
        });
      },
      cancel(): void {
        this.selectedEventStage = false;
      },
      async showModal(): Promise<void> {
        this.lastVersion = await this.fetchLastProjectVersionDx(this.projectId);
        // If last version available is different from local storage last version
        // - Show alert:
        if (this.currentVersionDxId != this.lastVersion) {
          this.showAlert();
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs;
      },
      styleActiveOnField(field) {
        for (let i in this.activeFields) {
          this.activeFields[i] = false;
        }
        if (field != "") {
          this.activeFields[field] = true;
        }
      },
      sortEventsByDate(): void {
        this.getEventsDxByProjectId(this.projectId).sort((ev1, ev2) => {
          return new Date(ev1.getLastHistoryBaseline.startedDate).getTime() <
            new Date(ev2.getLastHistoryBaseline.startedDate).getTime()
            ? -1
            : ev1.getLastHistoryBaseline.startedDate >
              ev2.getLastHistoryBaseline.startedDate
            ? 1
            : 0;
        });
      },
      sortStagesByDate(): void {
        this.stagesDx.sort((st1, st2) => {
          return new Date(st1.getLastHistoryBaseline.startedDate).getTime() <
            new Date(st2.getLastHistoryBaseline.startedDate).getTime()
            ? -1
            : st1.getLastHistoryBaseline.startedDate >
              st2.getLastHistoryBaseline.startedDate
            ? 1
            : 0;
        });
      },
      resetValues(): void {
        if (this.action !== "edit") this.$emit("resetActionAndTypeValues");
        if (this.savedEventStage) {
          if (this.action === "edit")
            this.$emit("reOpenTimelineItemForm", this.action, this.type);
          else this.$root.$emit("rerenderTimelineItem", this.itemId, this.type);
        }
        Object.assign(this.$data, this.$options.data.apply(this));
      },
      //Relate people to event created
      async relatePeopleTo(eventStageId): Promise<any> {
        const eventStageMembers: PeopleDx[] =
          this.type === "event"
            ? this.getPeopleDxByEventId(eventStageId)
            : this.getPeopleDxByStageId(eventStageId);
        let personDxToUpdate: PeopleDx;
        if (this.action === "create") {
          if (!this.isTeamEvent) {
            personDxToUpdate = this.projectPeopleDx.find(
              personDx => personDx.getUsername == this.author.getUsername
            );
            this.type === "event"
              ? personDxToUpdate.addEvent(eventStageId)
              : personDxToUpdate.addStage(eventStageId);
            await this.putPeopleDx(personDxToUpdate);
            for (const memberEvent of this.members) {
              this.memberEventsUpdate(memberEvent, eventStageId);
            }
          } else {
            this.memberEventsUpdate(this.members[0], eventStageId);
            const teamDx: TeamDx = this.getTeamDxByProjectId(this.projectId);
            if (
              this.typeSelected.key ==
              eventTypes.PEOPLE.events.newIncorporation.type
            )
              teamDx.addPeople(this.members[0].getUsername);
            else teamDx.deletePeople(this.members[0].getUsername);
            await this.putTeamDx(teamDx);
          }
        }
        if (["edit", "edit_popup"].includes(this.action)) {
          for (const personIcon of this.peopleHasChanged_.peopleToAdd) {
            //If personIcon is member of projectDx
            this.memberEventsUpdate(personIcon, eventStageId);
          }
          //REMOVE PEOPLE FROM EVENT
          for (const personIcon of this.peopleHasChanged_.peopleToRemove) {
            personDxToUpdate = eventStageMembers.find(
              personDx => personDx.getUsername == personIcon.getUsername
            );
            this.type === "event"
              ? personDxToUpdate.removeEvent(eventStageId)
              : personDxToUpdate.removeStage(eventStageId);
            await this.putPeopleDx(personDxToUpdate);
          }
        }
      },
      async memberEventsUpdate(member: PeopleIcon, eventStageId: string) {
        let personDxToUpdate: PeopleDx;
        if (this.peopleDxProjectUsernames.includes(member.getUsername)) {
          personDxToUpdate = this.projectPeopleDx.find(
            personDx => personDx.getUsername == member.getUsername
          );
          this.type === "event"
            ? personDxToUpdate.addEvent(eventStageId)
            : personDxToUpdate.addStage(eventStageId);
          await this.putPeopleDx(personDxToUpdate);
        } else {
          personDxToUpdate = null;
          if (personDxToUpdate) {
            this.type === "event"
              ? personDxToUpdate.addEvent(eventStageId)
              : personDxToUpdate.addStage(eventStageId);
            await this.putPeopleDx(personDxToUpdate);
          } else {
            await this.postPeopleDx(
              new PeopleDx({
                userName: member.getUsername,
                jobPosition: member.getJobPosition,
                pointOfContact: false,
                events: this.type === "event" ? [eventStageId] : [],
                stages: this.type === "stage" ? [eventStageId] : [],
                permissions: new Permissions()
              })
            );
          }
        }
      },
      async saveEventStage(): Promise<void> {
        // EVENT
        if (this.type === "event") {
          this.updateTimelineChartType = "events";
          if (this.action === "create") {
            if (this.isTeamEvent && this.members.length > 1) {
              const membersAux = this.members;
              for (const member of membersAux) {
                this.members = [member];
                this.members.length = membersAux.length;
                await this.createEventAndRelateWithPeopleAndAffected();
                this.membersCreated += 1;
              }
              this.members = membersAux;
            } else {
              await this.createEventAndRelateWithPeopleAndAffected();
            }
          } else {
            await this.modifyEventAndRelationWithPeopleAndAffected();
          }
        }
        // STAGE
        else {
          this.updateTimelineChartType = "stages";
          if (this.action === "create") {
            await this.createStageAndRelateWithPeopleAndAffected();
          } else {
            await this.modifyStageAndRelationWithPeopleAndAffected();
          }
        }

        if (this.selectedTag) {
          await this.putTagsDx(this.selectedTag);
        }

        this.selectedEventStage = false;
        this.savedEventStage = true;
      },
      async createEventAndRelateWithPeopleAndAffected(): Promise<void> {
        try {
          const newEvent: EventDx = this.getNewEventData();
          //POST EVENT
          this.createdEventId = await this.postEventDx(newEvent);
          this.createdEvent = this.getEventDx(this.createdEventId);
          //PUT/POST PEOPLE
          await this.relatePeopleTo(this.createdEventId);
          //PUT AFFECTED EVENT BASELINE
          const affectedEvent: EventDx = this.getEvent(this.affectedEvent.key);
          //add relation with new affected Event
          if (this.affectedEvent.getKey != undefined) {
            await this.modifyRelationEventsDxWithAffected(
              this.createdEventId,
              this.createdEvent.getLastHistoryBaseline,
              affectedEvent.getId
            );
          }
          //PUT NEW EVENT AFFECTED BASELINE
          //Assign affected event
          if (this.affectedEvent.getKey) {
            //Affected event can not have affected. Not recursive, at the moment.
            await this.modifyRelationEventsDxWithPredecessor(
              affectedEvent.getId,
              affectedEvent.getLastHistoryBaseline,
              this.createdEventId
            );
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async modifyEventAndRelationWithPeopleAndAffected(): Promise<void> {
        try {
          const startedDate = this.getStartedDate;
          let modifiedEvent: EventDx = this.eventSelected;
          this.peopleHasChanged_ = this.peopleHasChanged();
          // PUT EVENT
          modifiedEvent.setName = this.eventStage.name;
          modifiedEvent.setDescription = this.description;
          modifiedEvent.setRelated = this.related.getKey;
          modifiedEvent.setTag = this.selectedTag;
          modifiedEvent.setNotificationsPolicy = this.notificationsPolicy.key;
          if (this.amountUnits)
            modifiedEvent.setAmount = parseFloat(
              parseFloat(this.amountUnits).toFixed(2)
            );
          await this.putEventDx(modifiedEvent);
          /* PUT EVENT BASELINE
                      - If event startedDate has changed or
                      - If affected event has changed
                      */
          const startedDateHasChanged: boolean =
            new Date(
              modifiedEvent.getLastHistoryBaseline.startedDate
            ).getTime() != startedDate.getTime();

          const affectedHasChanged: boolean =
            this.affectedEvent.getKey != this.previousAffected.getKey;

          // Not used now, buy maybe applies for timeline version control in the future
          // let versionHasChanged: boolean =
          //   modifiedEvent.getLastHistoryBaseline.version != this.lastVersion;

          if (affectedHasChanged || startedDateHasChanged) {
            // Set event new startedDate
            modifiedEvent.getLastHistoryBaseline.startedDate = startedDate;
            modifiedEvent.getLastHistoryBaseline.status =
              new Date().getTime() > startedDate.getTime()
                ? eventStates.occurred.name
                : eventStates.pendingToOccur.name;
            // Delete relation with previous affected event
            if (
              this.previousAffected.getKey != undefined &&
              affectedHasChanged
            ) {
              const lastPreviousBaseline: IBaseLineDx = this.getEvent(
                this.previousAffected.key
              ).getLastHistoryBaseline;
              await this.modifyRelationEventsDxWithPredecessor(
                this.previousAffected.getKey,
                lastPreviousBaseline,
                null
              );
            }
            // add relation with new affected Event
            if (this.affectedEvent.getKey != undefined) {
              let affectedEvent: EventDx = this.getEvent(
                this.affectedEvent.key
              );
              let affectedVersionHasChanged: boolean = false;
              // First update event and its Affected baselines to last Version:
              // Add new baseline to history in both Predecessor and Afected if they don't have it
              if (
                modifiedEvent.getLastHistoryBaseline.version != this.lastVersion
              ) {
                await this.modifyRelationEventsDxWithAffected(
                  modifiedEvent.getId,
                  modifiedEvent.getLastHistoryBaseline,
                  null
                );
                modifiedEvent.getLastHistoryBaseline.version = this.lastVersion;
              }
              if (
                affectedEvent.getLastHistoryBaseline.version != this.lastVersion
              ) {
                await this.modifyRelationEventsDxWithPredecessor(
                  affectedEvent.getId,
                  affectedEvent.getLastHistoryBaseline,
                  null
                );
                affectedEvent.getLastHistoryBaseline.version = this.lastVersion;
                affectedVersionHasChanged = true;
              }
              // Add relation with affected
              await this.modifyRelationEventsDxWithAffected(
                modifiedEvent.getId,
                modifiedEvent.getLastHistoryBaseline,
                affectedEvent.getId
              );
              // Put new affected event baseline
              if (affectedVersionHasChanged || affectedHasChanged) {
                await this.modifyRelationEventsDxWithPredecessor(
                  affectedEvent.getId,
                  affectedEvent.getLastHistoryBaseline,
                  modifiedEvent.getId
                );
              }
            }
            // TODO: if there is a new version add new baseline to event history and predecessor history
            else if (
              modifiedEvent.getLastHistoryBaseline.predecessors.length > 0
            ) {
              let predecessorEvent: EventDx = this.getEvent(
                modifiedEvent.getLastHistoryBaseline.predecessors[0]
              );
              let predecessorVersionHasChanged: boolean = false;
              // First update event and its predecessor baselines to last Version:
              // Add new baseline to history in both Predecessor and Afected if they don't have it
              if (
                modifiedEvent.getLastHistoryBaseline.version != this.lastVersion
              ) {
                await this.modifyRelationEventsDxWithPredecessor(
                  modifiedEvent.getId,
                  modifiedEvent.getLastHistoryBaseline,
                  null
                );
                modifiedEvent.getLastHistoryBaseline.version = this.lastVersion;
              }
              if (
                predecessorEvent.getLastHistoryBaseline.version !=
                this.lastVersion
              ) {
                await this.modifyRelationEventsDxWithAffected(
                  predecessorEvent.getId,
                  predecessorEvent.getLastHistoryBaseline,
                  null
                );
                predecessorEvent.getLastHistoryBaseline.version =
                  this.lastVersion;
                predecessorVersionHasChanged = true;
              }
              // Add relation with predecessor
              await this.modifyRelationEventsDxWithPredecessor(
                modifiedEvent.getId,
                modifiedEvent.getLastHistoryBaseline,
                predecessorEvent.getId
              );
              // Put new predecessor event baseline
              if (predecessorVersionHasChanged || affectedHasChanged) {
                await this.modifyRelationEventsDxWithAffected(
                  predecessorEvent.getId,
                  predecessorEvent.getLastHistoryBaseline,
                  modifiedEvent.getId
                );
              }
            } else {
              // If event has no affected or predecessor, just update baseline
              await this.modifyRelationEventsDxWithAffected(
                modifiedEvent.getId,
                modifiedEvent.getLastHistoryBaseline,
                null
              );
            }
          }

          // UPDATE PEOPLE
          if (this.peopleHasChanged_.hasChanged) {
            await this.relatePeopleTo(modifiedEvent.getId);
          }
          const message = {
            text: this.text.messageAlertSuccess,
            time: 3,
            type: "success"
          };
          this.setMessage(message);
        } catch (error) {
          this.$log.error(error);
        }
      },
      async deleteEvent(): Promise<void> {
        this.loading = "delete";
        this.$root.$emit("closeTimelineItem");
        try {
          const eventToDeleteId: string = this.eventStage.getKey;
          this.eventToDelete = this.getEventDx(eventToDeleteId);
          //Delete relation with Predecessor/Affected
          await this.deleteEventDxRelationWithPredecessorAffected(
            eventToDeleteId
          );
          //Remove from eventList of peopleDx:
          await this.deleteEventToPeopleDxEventList(eventToDeleteId);
          //Delete Event
          await this.deleteEventDx(eventToDeleteId);
          if (
            this.typeSelected.key == eventTypes.PEOPLE.events.unassignment.type
          ) {
            await this.deleteRelatedFutureTeamEvent(
              eventTypes.PEOPLE.events.newIncorporation.type
            );
          } else if (
            this.typeSelected.key ==
            eventTypes.PEOPLE.events.newIncorporation.type
          ) {
            await this.deleteRelatedFutureTeamEvent(
              eventTypes.PEOPLE.events.unassignment.type
            );
          }
          await this.sendNotificationDx(this.eventToDelete);
          this.loading = "";
        } catch (error) {
          this.$log.error(error);
        }
        this.updateTimelineChartType = "events";
        this.handleCloseSubmit();
        this.selectedEventStage = false;
      },
      async deleteRelatedFutureTeamEvent(relatedEventType) {
        const teamDx: TeamDx = this.getTeamDxByProjectId(this.projectId);
        const userEventFuture = this.sortedEventsTimeAndMaterials.find(
          event =>
            event.getEventType == relatedEventType &&
            event.getBaseline.startedDate >
              this.eventToDelete.getBaseline.startedDate &&
            event.getTamUser == this.eventToDelete.getTamUser
        );
        if (userEventFuture) {
          await this.deleteEventDxRelationWithPredecessorAffected(
            userEventFuture.getId
          );
          await this.deleteEventToPeopleDxEventList(userEventFuture.getId);
          this.deleteEventDx(userEventFuture.getId);
        } else {
          // Reverting the event action that we have deleted in case
          // there is not a counter-event in the future, to simulate the previous state of the team
          if (
            relatedEventType == eventTypes.PEOPLE.events.newIncorporation.type
          ) {
            teamDx.addPeople(this.eventToDelete.getTamUser);
          } else {
            teamDx.deletePeople(this.eventToDelete.getTamUser);
          }
          await this.putTeamDx(teamDx);
        }
      },
      async deleteEventDxRelationWithPredecessorAffected(
        eventId: string
      ): Promise<void> {
        const eventDxToDelete: EventDx = this.getEvent(eventId);
        const eventDxToDeleteBaseline: IBaseLineDx =
          eventDxToDelete.getLastHistoryBaseline;
        if (eventDxToDeleteBaseline.predecessors.length > 0) {
          const eventDxPredecessor: EventDx = this.getEvent(
            eventDxToDeleteBaseline.predecessors[0]
          );
          const eventDxPredecessorBaseline: IBaseLineDx =
            eventDxPredecessor.getLastHistoryBaseline;
          await this.modifyRelationEventsDxWithPredecessor(
            eventDxPredecessor.getId,
            eventDxPredecessorBaseline,
            null
          );
        }
        if (eventDxToDeleteBaseline.affecteds.length > 0) {
          const eventDxAffected: EventDx = this.getEvent(
            eventDxToDeleteBaseline.affecteds[0]
          );
          const eventDxAffectedBaseline: IBaseLineDx =
            eventDxAffected.getLastHistoryBaseline;
          await this.modifyRelationEventsDxWithAffected(
            eventDxAffected.getId,
            eventDxAffectedBaseline,
            null
          );
        }
      },
      async deleteEventToPeopleDxEventList(eventId: string): Promise<void> {
        const peopleDxInEvent: PeopleDx[] = this.getPeopleDxByEventId(eventId);
        let personDxToUpdate: PeopleDx = undefined;
        for (const personDx of peopleDxInEvent) {
          personDxToUpdate = personDx;
          personDxToUpdate.removeEvent(eventId);
          await this.putPeopleDx(personDxToUpdate);
        }
      },
      async modifyRelationEventsDxWithPredecessor(
        eventId: string,
        baseline: IBaseLineDx,
        predecessorId: string
      ): Promise<void> {
        let eventBaseline: IBaseLineDx = {
          version: this.lastVersion,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: null,
          affecteds: baseline.affecteds,
          predecessors: []
        };
        if (predecessorId) {
          eventBaseline.predecessors.push(predecessorId);
        }
        try {
          if (baseline.version == this.lastVersion) {
            await this.putEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          } else {
            await this.postEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async modifyRelationEventsDxWithAffected(
        eventId: string,
        baseline: IBaseLineDx,
        affectedId: string
      ): Promise<void> {
        let eventBaseline: IBaseLineDx = {
          version: this.lastVersion,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: null,
          affecteds: [],
          predecessors: baseline.predecessors
        };
        if (affectedId) {
          eventBaseline.affecteds.push(affectedId);
        }
        try {
          if (baseline.version == this.lastVersion) {
            await this.putEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          } else {
            await this.postEventDxBaseline({
              baseline: eventBaseline,
              eventId: eventId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async modifyRelationStagesDxWithPredecessor(
        stageId: string,
        baseline: IBaseLineDx,
        predecessorId: string
      ): Promise<void> {
        let stageBaseline: IBaseLineDx = {
          version: this.lastVersion,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: baseline.endDate,
          affecteds: baseline.affecteds,
          predecessors: []
        };
        if (predecessorId) {
          stageBaseline.predecessors.push(predecessorId);
        }
        try {
          if (baseline.version == this.lastVersion) {
            await this.putStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          } else {
            await this.postStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async modifyRelationStagesDxWithAffected(
        stageId: string,
        baseline: IBaseLineDx,
        affectedId: string
      ): Promise<void> {
        let stageBaseline: IBaseLineDx = {
          version: this.lastVersion,
          status: baseline.status,
          startedDate: baseline.startedDate,
          endDate: baseline.endDate,
          affecteds: [],
          predecessors: baseline.predecessors
        };
        if (affectedId) {
          stageBaseline.affecteds.push(affectedId);
        }
        try {
          if (baseline.version == this.lastVersion) {
            await this.putStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          } else {
            await this.postStageDxBaseline({
              baseline: stageBaseline,
              stageId: stageId
            });
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async createStageAndRelateWithPeopleAndAffected(): Promise<void> {
        try {
          const newStage: StageDx = this.getNewStageData();
          // POST STAGE
          this.createdStageId = await this.postStageDx(newStage);
          this.createdStage = this.getStageDx(this.createdStageId);
          // PUT/POST PEOPLE
          await this.relatePeopleTo(this.createdStageId);
          if (this.affectedStage.getKey) {
            const affectedStage: StageDx = this.getStage(
              this.affectedStage.key
            );
            let predecessors: string[] = [];
            predecessors.push(this.createdStageId);
            // PUT AFFECTED STAGE BASELINE
            await this.modifyRelationStagesDxWithAffected(
              this.createdStageId,
              this.createdStage.getLastHistoryBaseline,
              affectedStage.getId
            );
            // PUT PREDECESSOR STAGE BASELINE
            await this.modifyRelationStagesDxWithPredecessor(
              affectedStage.getId,
              affectedStage.getLastHistoryBaseline,
              this.createdStageId
            );
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async modifyStageAndRelationWithPeopleAndAffected(): Promise<void> {
        try {
          let modifiedStage: StageDx = this.stageSelected;
          this.peopleHasChanged_ = this.peopleHasChanged();
          // PUT STAGE if stage title or description is changed
          modifiedStage.setName = this.eventStage.name;
          modifiedStage.setDescription = this.description;
          modifiedStage.setTag = this.selectedTag;
          modifiedStage.setNotificationsPolicy = this.notificationsPolicy.key;
          modifiedStage.setColor = this.colorSelected["color"];
          await this.putStageDx(modifiedStage);
          /* PUT STAGE BASELINE
                      - If stage startDate/endDate has changed or
                      - If affected stage has changed
                      */
          const datesHaveChanged: boolean =
            new Date(
              modifiedStage.getLastHistoryBaseline.startedDate
            ).getTime() != this.startDate.getTime() ||
            new Date(modifiedStage.getLastHistoryBaseline.endDate).getTime() !=
              this.endDate.getTime();

          const affectedHasChanged: boolean =
            this.affectedStage.getKey != this.previousAffected.getKey;

          // Not used now, buy maybe applies for timeline version control in the future
          // let version_has_changed: boolean =
          //   modifiedStage.getLastHistoryBaseline.version != this.lastVersion;

          if (affectedHasChanged || datesHaveChanged) {
            // Set stage new startedDate and endDate
            modifiedStage.getLastHistoryBaseline.startedDate = this.startDate;
            modifiedStage.getLastHistoryBaseline.endDate = this.endDate;
            // Delete relation with previous affected stage
            if (
              this.previousAffected.getKey != undefined &&
              affectedHasChanged
            ) {
              const lastPreviousBaseline: IBaseLineDx = this.getStage(
                this.previousAffected.key
              ).getLastHistoryBaseline;
              await this.modifyRelationStagesDxWithPredecessor(
                this.previousAffected.getKey,
                lastPreviousBaseline,
                null
              );
            }
            // add relation with new affected Stage
            if (this.affectedStage.getKey != undefined) {
              const affectedStage: EventDx = this.getStage(
                this.affectedStage.key
              );
              let affectedVersionHasChanged: boolean = false;
              // First update event and its Affected baselines to last Version:
              // Add new baseline to history in both events if the don't have it
              if (
                modifiedStage.getLastHistoryBaseline.version != this.lastVersion
              ) {
                await this.modifyRelationStagesDxWithAffected(
                  modifiedStage.getId,
                  modifiedStage.getLastHistoryBaseline,
                  null
                );
                modifiedStage.getLastHistoryBaseline.version = this.lastVersion;
              }
              if (
                affectedStage.getLastHistoryBaseline.version != this.lastVersion
              ) {
                await this.modifyRelationStagesDxWithPredecessor(
                  affectedStage.getId,
                  affectedStage.getLastHistoryBaseline,
                  null
                );
                affectedStage.getLastHistoryBaseline.version = this.lastVersion;
                affectedVersionHasChanged = true;
              }
              // Add relation with affected
              await this.modifyRelationStagesDxWithAffected(
                modifiedStage.getId,
                modifiedStage.getLastHistoryBaseline,
                this.affectedStage.getKey
              );
              // Put new affected stage baseline
              if (affectedVersionHasChanged || affectedHasChanged) {
                await this.modifyRelationStagesDxWithPredecessor(
                  this.affectedStage.getKey,
                  affectedStage.getLastHistoryBaseline,
                  modifiedStage.getId
                );
              }
            }
            // TODO: if there is a new version add new baseline to event history and predecessor history
            else if (
              modifiedStage.getLastHistoryBaseline.predecessors.length > 0
            ) {
              let predecessorStage: EventDx = this.getStage(
                modifiedStage.getLastHistoryBaseline.predecessors[0]
              );
              let predecessorVersionHasChanged: boolean = false;
              // First update stage and its Affected baselines to last Version:
              // Add new baseline to history in both Predecessor and Afected if they don't have it
              if (
                modifiedStage.getLastHistoryBaseline.version != this.lastVersion
              ) {
                await this.modifyRelationStagesDxWithPredecessor(
                  modifiedStage.getId,
                  modifiedStage.getLastHistoryBaseline,
                  null
                );
                modifiedStage.getLastHistoryBaseline.version = this.lastVersion;
              }
              if (
                predecessorStage.getLastHistoryBaseline.version !=
                this.lastVersion
              ) {
                await this.modifyRelationStagesDxWithAffected(
                  predecessorStage.getId,
                  predecessorStage.getLastHistoryBaseline,
                  null
                );
                predecessorStage.getLastHistoryBaseline.version =
                  this.lastVersion;
                predecessorVersionHasChanged = true;
              }
              // Add relation with predecessor
              await this.modifyRelationStagesDxWithPredecessor(
                modifiedStage.getId,
                modifiedStage.getLastHistoryBaseline,
                predecessorStage.getId
              );
              // Put new predecessor stage baseline
              if (predecessorVersionHasChanged || affectedHasChanged) {
                await this.modifyRelationStagesDxWithAffected(
                  predecessorStage.getId,
                  predecessorStage.getLastHistoryBaseline,
                  modifiedStage.getId
                );
              }
            } else {
              // If stage has no affected, just update baseline
              await this.modifyRelationStagesDxWithAffected(
                modifiedStage.getId,
                modifiedStage.getLastHistoryBaseline,
                null
              );
            }
          }
          // UPDATE PEOPLE
          if (this.peopleHasChanged_.hasChanged) {
            await this.relatePeopleTo(modifiedStage.getId);
          }
          const message = {
            text: this.text.messageAlertSuccess,
            time: 3,
            type: "success"
          };
          this.setMessage(message);
        } catch (error) {
          this.$log.error(error);
        }
      },
      async deleteStage(): Promise<void> {
        this.loading = "delete";
        this.$root.$emit("closeTimelineItem");
        try {
          const stage_to_delete_id: string = this.eventStage.getKey;
          this.stageToDelete = this.getStageDx(stage_to_delete_id);
          // Delete relation with predecessor/affected
          await this.deleteStageDxRelationWithPredecessorAffected(
            stage_to_delete_id
          );
          // Remove from stageList of peopleDx:
          await this.deleteStageToPeopleDxEventList(stage_to_delete_id);
          // Delete Stage
          await this.deleteStageDx(stage_to_delete_id);
          await this.sendNotificationDx(this.stageToDelete);
        } catch (error) {
          this.$log.error(error);
        }
        this.updateTimelineChartType = "stages";
        this.handleCloseSubmit();
        this.selectedEventStage = false;
        this.loading = "";
      },
      async deleteStageDxRelationWithPredecessorAffected(
        stageId: string
      ): Promise<void> {
        const stageDxToDelete: StageDx = this.getStage(stageId);
        const stageDxToDeleteBaseline: IBaseLineDx =
          stageDxToDelete.getLastHistoryBaseline;
        if (stageDxToDeleteBaseline.predecessors.length > 0) {
          const stageDxPredecessor: StageDx = this.getStage(
            stageDxToDeleteBaseline.predecessors[0]
          );
          const stageDxPredecessorBaseline: IBaseLineDx =
            stageDxPredecessor.getLastHistoryBaseline;
          await this.modifyRelationStagesDxWithPredecessor(
            stageDxPredecessor.getId,
            stageDxPredecessorBaseline,
            null
          );
        }
        if (stageDxToDeleteBaseline.affecteds.length > 0) {
          const stageDxAffected: StageDx = this.getStage(
            stageDxToDeleteBaseline.affecteds[0]
          );
          const stageDxAffectedBaseline: IBaseLineDx =
            stageDxAffected.getLastHistoryBaseline;
          await this.modifyRelationStagesDxWithAffected(
            stageDxAffected.getId,
            stageDxAffectedBaseline,
            null
          );
        }
      },
      async deleteStageToPeopleDxEventList(stageId: string): Promise<void> {
        const peopleDxInStage: PeopleDx[] = this.getPeopleDxByStageId(stageId);
        let personDxToUpdate: PeopleDx = undefined;
        for (const personDx of peopleDxInStage) {
          personDxToUpdate = personDx;
          personDxToUpdate.removeStage(stageId);
          await this.putPeopleDx(personDxToUpdate);
        }
      },
      setEventStageStatusAndOccurred(date: Date): void {
        const currentDate = this.$moment().startOf("minute");
        if (this.$moment(date).startOf("minute") > currentDate) {
          this.eventStageStatus = eventStates.pendingToOccur.name;
          this.eventStageStatusClass = "status-pending-occur";
          this.eventStageOccurred = false;
        } else {
          this.eventStageStatus = eventStates.occurred.name;
          this.eventStageStatusClass = "status-ocurred";
          this.eventStageOccurred = true;
        }
      },
      addTag(value) {
        this.selectedTag = value;
      }
    },
    watch: {
      action: function () {
        if (this.action === "create") {
          this.date = new Date();
          this.startDate = new Date();
          this.endDate = this.startDate;
        }
        if (this.action === "edit_popup") {
          this.eventStage.setKey = this.itemId;
          if (this.type === "event") {
            this.eventStage.setName = this.getEvent(this.itemId).getName;
          } else if (this.type === "stage") {
            const stage = this.getStage(this.itemId);
            this.eventStage.setName = stage.getName;
            if (stage.getColor) {
              this.colorSelected = {
                color: stage.getColor
              };
            }
          }
        }
      },
      eventStage: {
        deep: true,
        handler(eventStage) {
          this.firstEventStageClass = true;
          if (["edit", "edit_popup"].includes(this.action)) {
            if (this.type === "event") {
              if (eventStage.getKey !== undefined) {
                let hour, min;
                this.actualMembersBeforeUpdate = this.mapPeopleToPeopleIcon(
                  this.getPeopleDxByEventId(eventStage.getKey)
                );
                this.members = this.mapPeopleToPeopleIcon(
                  this.getPeopleDxByEventId(eventStage.getKey)
                );
                if (this.eventSelected) {
                  this.eventStageStatus =
                    this.eventSelected.getLastHistoryBaseline.status;
                  this.eventStageStatusClass = this.getStatusClass(
                    this.eventSelected.getLastHistoryBaseline.status
                  );
                  this.eventStageOccurred = this.eventSelected.getOccurred;
                  this.date = new Date(
                    this.eventSelected.getLastHistoryBaseline.startedDate
                  );
                  const time = this.date;
                  hour = time.getHours();
                  if (hour < 10) hour = "0" + hour;
                  min = time.getMinutes();
                  if (min < 10) min = "0" + min;
                  this.hour.setKey = hour + ":" + min;
                  this.hour.setName = this.hour.getKey;
                  const eventType = getEventType(
                    this.eventSelected.getEventType
                  );
                  this.typeSelected = new SingleSelectType({
                    key: eventType.type,
                    name: eventType.name,
                    icon:
                      "services/deliveryexperience/timeline/" +
                      eventType.icon.normal
                  });
                  this.affectedEvent = this.eventStageAffected;
                  this.previousAffected = this.eventStageAffected;
                  this.related = this.eventRequestRelated;
                  this.description = this.eventSelected.getDescription;
                  this.selectedTag = this.eventSelected.getTag;
                  this.amountUnits = this.eventSelected.getAmount;
                  const policyItem = notifications.getNotificationsPolicy(
                    this.eventSelected.getNotificationsPolicy
                  );
                  this.notificationsPolicy = new SingleSelectType({
                    key: policyItem.name,
                    name: this.$t(policyItem.i18n)
                  });
                }
              }
            } else if (eventStage.getKey !== undefined) {
              this.actualMembersBeforeUpdate = this.mapPeopleToPeopleIcon(
                this.getPeopleDxByStageId(eventStage.getKey)
              );
              this.members = this.mapPeopleToPeopleIcon(
                this.getPeopleDxByStageId(eventStage.getKey)
              );
              if (this.stageSelected) {
                this.eventStageStatus =
                  this.stageSelected.getLastHistoryBaseline.status;
                this.eventStageStatusClass = this.getStatusClass(
                  this.stageSelected.getLastHistoryBaseline.status
                );
                this.eventStageOccurred = this.stageSelected.getOccurred;
                this.startDate = new Date(
                  this.stageSelected.getLastHistoryBaseline.startedDate
                );
                this.endDate = new Date(
                  this.stageSelected.getLastHistoryBaseline.endDate
                );
                this.affectedStage = this.eventStageAffected;
                this.previousAffected = this.eventStageAffected;
                this.description = this.stageSelected.getDescription;
                this.selectedTag = this.stageSelected.getTag;
                const policyItem = notifications.getNotificationsPolicy(
                  this.stageSelected.getNotificationsPolicy
                );
                this.notificationsPolicy = new SingleSelectType({
                  key: policyItem.name,
                  name: this.$t(policyItem.i18n)
                });
              }
            }
          }
          if (this.docsDx.length && this.docsDx[0].getPeople.length) {
            this.docsVisibleToAll = false;
          }
        }
      },
      typeSelected: {
        deep: true,
        handler(newValue, oldValue) {
          this.showAmountCapacity = false;
          if (newValue != undefined) {
            if (
              [
                eventTypes[projectTypes.capacity.name].events.reload.type,
                eventTypes[projectTypes.capacity.name].events.billing.type,
                eventTypes[projectTypes.capacity.name].events.consume.type
              ].includes(newValue.key)
            ) {
              this.showAmountCapacity = true;
            } else this.amountUnits = null;
          }
          const eventTypesToCheck = [
            eventTypes.PEOPLE.events.newIncorporation.type,
            eventTypes.PEOPLE.events.unassignment.type
          ];
          if (
            eventTypesToCheck.includes(newValue.key) ||
            eventTypesToCheck.includes(oldValue.key)
          )
            if (this.action === "create") this.members = [];
        }
      },
      //EVENT STATUS
      date: function () {
        if (this.date) {
          if (this.action === "create") {
            this.setEventStageStatusAndOccurred(this.date);
          } else if (["edit", "edit_popup"].includes(this.action)) {
            if (!this.firstEventStageClass) {
              this.setEventStageStatusAndOccurred(this.getStartedDate);
            }
          }
        }
      },
      hour: {
        deep: true,
        handler(hour) {
          if (hour.getKey) {
            if (this.action === "create") {
              this.setEventStageStatusAndOccurred(this.getStartedDate);
            } else if (["edit", "edit_popup"].includes(this.action)) {
              if (!this.firstEventStageClass) {
                this.setEventStageStatusAndOccurred(this.getStartedDate);
              } else {
                this.firstEventStageClass = false;
              }
            }
          }
        }
      },
      //STAGE STATUS
      endDate: function () {
        if (this.endDate) {
          if (this.action === "create") {
            this.setEventStageStatusAndOccurred(this.endDate);
          } else if (["edit", "edit_popup"].includes(this.action)) {
            if (!this.firstEventStageClass) {
              this.setEventStageStatusAndOccurred(this.endDate);
            } else {
              this.firstEventStageClass = false;
            }
          }
        }
      }
    }
  };
