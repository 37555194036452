var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'event-calendar-container': _vm.project.getProjectType == _vm.qbrString },staticStyle:{"height":"95%"}},[(_vm.project.getProjectType == _vm.timeAndMaterialsString)?_c('PeopleHistogram',{style:({ marginTop: _vm.comeFrom == 'project-overview' ? '-3px' : '8px' }),attrs:{"id":_vm.comeFrom + '-people-histogram-' + _vm.project.getId,"projectStartDate":_vm.project.getStartDate.split('T')[0],"events":_vm.sortedEventsTimeAndMaterials,"projectId":String(_vm.project.getId)}}):(_vm.project.getProjectType == _vm.capacityString)?_c('ProjectTypeCapacityKpi',{style:({ marginTop: _vm.comeFrom == 'project-overview' ? '-10px' : '' }),attrs:{"id":_vm.comeFrom + '-all-project-view-capacity-scatter-' + _vm.project.getId,"projectStartDate":_vm.project.getStartDate.split('T')[0],"events":_vm.sortedEventsCapacity,"unitType":_vm.getCapacityType,"modalView":_vm.modalView}}):(
      _vm.project.getProjectType == _vm.liabilityTemporaryString ||
      _vm.project.getProjectType == _vm.liabilityEcomonicString ||
      _vm.project.getProjectType == _vm.liabilityFullString
    )?_c('ProgressBar',{staticStyle:{"width":"80%","margin":"auto"},attrs:{"href":_vm.comeFrom +
      '-all-project-view-graphic-ref-' +
      _vm.project.getProjectType +
      '-' +
      _vm.project.getId,"id":_vm.comeFrom +
      '-all-project-view-graphic-id-' +
      _vm.project.getProjectType +
      '-' +
      _vm.project.getId,"project":_vm.project,"eventsDx":_vm.sortedEventsByDate,"stagesDx":_vm.sortedStagesByDate}}):(_vm.project.getProjectType == _vm.qbrString)?_c('EventDateCalendar',{style:([_vm.cameFromModal ? { 'min-height': '65vh' } : '']),attrs:{"id":_vm.comeFrom + 'event-date-calendar' + _vm.project.getId,"events":_vm.sortedEventsQbr}}):(_vm.project.getProjectType == _vm.cspString)?_c('ProjectTypeCspKpi',{attrs:{"id":_vm.project.getId.toString()}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }