import ClientServiceCore from "../../models/core/client/ClientServiceCore";
import ClientCore from "../../models/core/ClientCore";
import service from "../service";
import baseEndpoint from "./serviceCore";

const endpoint = baseEndpoint + "/Client";

export default {
  components: { service: service },
  methods: {
    async getClients(): Promise<ClientCore[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(client => new ClientCore(client));
    },
    async postClient(client: ClientCore): Promise<ClientCore> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        client
      );
      return new ClientCore(response);
    },
    async putClient(client: ClientCore): Promise<ClientCore> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${client.getId}`,
        client
      );
      return new ClientCore(response);
    },
    async deleteClient(id: number): Promise<any> {
      return await service.methods.deleteEndpointAsync(`${endpoint}/${id}`);
    },
    async postClientService(
      clientService: ClientServiceCore
    ): Promise<ClientCore> {
      const clientId = clientService.getClientId;
      const serviceId = clientService.getServiceId;
      const response = await service.methods.postEndPointWithoutDataAsync(
        `${endpoint}/${clientId}/service/${serviceId}`
      );
      return new ClientCore(response);
    },
    async deleteClientService(clientService: ClientServiceCore): Promise<any> {
      const clientId = clientService.getClientId;
      const serviceId = clientService.getServiceId;
      return await service.methods.deleteEndpointAsync(
        `${endpoint}/${clientId}/service/${serviceId}`
      );
    }
  }
};
