export interface IProjectState {
  id?: number;
  projectId: number;
  state: string;
  timestamp: Date;
}

export default class ProjectState {
  private id?: number;
  private projectId: number;
  private state: string;
  private timestamp: Date;

  constructor(dto: IProjectState) {
    Object.assign(this, dto);
  }

  get getId(): number {
    return this.id;
  }

  set setId(value: number) {
    this.id = value;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get getState(): string {
    return this.state;
  }

  set setState(value: string) {
    this.state = value;
  }

  get getTimeStamp(): Date {
    return this.timestamp;
  }

  set setTimeStamp(value: Date) {
    this.timestamp = value;
  }
}
