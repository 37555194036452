import Vue from "vue";
import VueRouter from "vue-router";
import { eventStates, eventTypes } from "../_helpers/eventTypesStates";
import { projectStates } from "../_helpers/projectMetadata";
import { servicesExtensions } from "../_helpers/servicesExtensions";
import authService from "../authentication/AuthServiceInst";
import PeopleCore from "../models/core/PeopleCore";
import ServiceCore from "../models/core/ServiceCore";
import ProjectServiceCore from "../models/core/project/ProjectServiceCore";
import ProjectStateCore from "../models/core/project/ProjectStateCore";
import EventDx from "../models/deliveryexperience/EventDx";
import PeopleDx from "../models/deliveryexperience/PeopleDx";
import Account from "../pages/account.vue";
import ErrorPage from "../pages/error.vue";
import ClientsTab from "../pages/home/clients.vue";
import ExtensionsTab from "../pages/home/extensions.vue";
import Home from "../pages/home/index.vue";
import ProjectsTab from "../pages/home/projects.vue";
import ServicesTab from "../pages/home/services.vue";
import UsersTab from "../pages/home/users.vue";
import Notifications from "../pages/notifications.vue";
import ProjectForm from "../pages/project-form.vue";
import CSPOperations from "../pages/services/csp/operations.vue";
import CSPProject from "../pages/services/csp/project.vue";
import DXplanner from "../pages/services/deliveryexperience/planner.vue";
import DXhome from "../pages/services/deliveryexperience/project.vue";
import ReportEvent from "../pages/services/deliveryexperience/report-event.vue";
import DXrequest from "../pages/services/deliveryexperience/requests.vue";
import ServicesIndex from "../pages/services/index.vue";
import QEHome from "../pages/services/qualityeye/project.vue";
import SLCHome from "../pages/services/spiderlinkschecker/project.vue";
import UnderConstructionPage from "../pages/under-construction.vue";
import WelcomePage from "../pages/welcome.vue";
import { store } from "../store";
import Singleton from "../utils/singleton";

Vue.use(VueRouter);

const notFoundPage = "notFoundPage";

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: "/",
      component: WelcomePage,
      meta: { requiresAuthentication: true }
    },
    {
      path: "/home",
      name: "index",
      component: Home,
      meta: { requiresAuthentication: true },
      redirect: "/home/projects",
      children: [
        {
          path: "projects",
          name: "projects",
          component: ProjectsTab,
          meta: { requiresAuthentication: true }
        },
        {
          path: "services",
          name: "services",
          component: ServicesTab,
          meta: { requiresAuthentication: true }
        },
        {
          path: "extensions",
          name: "extensions",
          component: ExtensionsTab,
          meta: { requiresAuthentication: true }
        },
        {
          path: "clients",
          name: "clients",
          component: ClientsTab,
          meta: { requiresAuthentication: true },
          async beforeEnter(to, from, next) {
            if (await validateAccess(false, null, false, null, true)) {
              next();
            } else {
              next({ name: notFoundPage });
            }
          }
        },
        {
          path: "users",
          name: "users",
          component: UsersTab,
          meta: { requiresAuthentication: true },
          async beforeEnter(to, from, next) {
            if (await validateAccess(false, null, false, null, true)) {
              next();
            } else {
              next({ name: notFoundPage });
            }
          }
        }
      ]
    },
    {
      path: "/project-form",
      component: ProjectForm,
      meta: { requiresAuthentication: true },
      async beforeEnter(to, from, next) {
        if (await validateAccess(true, null, false, null, false)) {
          window.scrollTo(0, 0);
          next();
        } else {
          next({
            name: notFoundPage
          });
        }
      }
    },
    {
      path: "/edit-project/:projectId",
      component: ProjectForm,
      props: true,
      meta: { requiresAuthentication: true },
      async beforeEnter(to, from, next) {
        if (
          await validateAccess(
            true,
            servicesExtensions.deliveryExperience.name,
            true,
            to.params.projectId,
            false
          )
        ) {
          window.scrollTo(0, 0);
          next();
        } else {
          next({
            name: notFoundPage
          });
        }
      }
    },
    {
      path: "/account",
      component: Account,
      meta: { requiresAuthentication: true }
    },
    {
      path: `/${servicesExtensions.deliveryExperience.url}/:projectId`,
      redirect: { name: "project-dx" },
      component: ServicesIndex,
      meta: {
        requiresAuthentication: true,
        checkHubService: servicesExtensions.deliveryExperience.name
      },
      children: [
        {
          path: "",
          name: "project-dx",
          component: DXhome,
          meta: {
            requiresAuthentication: true,
            checkHubService: servicesExtensions.deliveryExperience.name
          },
          children: [
            {
              path: "timeline",
              name: "timeline"
            },
            {
              path: "event/:idItem",
              name: "event"
            },
            {
              path: "stage/:idItem",
              name: "stage"
            },
            {
              path: "docs", //for now this route will be used to scroll to docs section
              name: "docs"
            }
          ],
          async beforeEnter(to, from, next) {
            await beforeEnterHubService(
              servicesExtensions.deliveryExperience.name,
              to,
              next
            );
          }
        },
        {
          path: `${servicesExtensions.deliveryExperience.extensions.planner.url}`,
          props: route => {
            return {
              ...route.params,
              ...{ projectId: Number.parseInt(route.params.projectId) }
            };
          },
          name: servicesExtensions.deliveryExperience.extensions.planner.url,
          component: DXplanner,
          meta: { requiresAuthentication: true },
          async beforeEnter(to, from, next) {
            await beforeEnterHubService(
              servicesExtensions.deliveryExperience.name,
              to,
              next
            );
          }
        },
        {
          path: `${servicesExtensions.deliveryExperience.extensions.requests.url}`,
          props: route => {
            return {
              ...route.params,
              ...{ projectId: Number.parseInt(route.params.projectId) }
            };
          },
          name: servicesExtensions.deliveryExperience.extensions.requests.url,
          component: DXrequest,
          meta: { requiresAuthentication: true },
          async beforeEnter(to, from, next) {
            await beforeEnterHubService(
              servicesExtensions.deliveryExperience.name,
              to,
              next
            );
          },
          children: [
            {
              path: `:idRequest`,
              name: "request"
            }
          ]
        }
      ]
    },
    {
      path: `/${servicesExtensions.qualityEye.url}/:projectId`,
      redirect: { name: "project-qe" },
      component: ServicesIndex,
      meta: {
        requiresAuthentication: true,
        checkHubService: servicesExtensions.qualityEye.name
      },
      children: [
        {
          path: "",
          name: "project-qe",
          component: QEHome,
          meta: { requiresAuthentication: true },
          async beforeEnter(to, from, next) {
            await beforeEnterHubService(
              servicesExtensions.qualityEye.name,
              to,
              next
            );
          }
        }
      ]
    },
    {
      path: `/${servicesExtensions.cspPanel.url}/:projectId`,
      redirect: { name: "project-csp" },
      component: ServicesIndex,
      meta: {
        requiresAuthentication: true,
        checkHubService: servicesExtensions.cspPanel.name
      },
      children: [
        {
          path: `${servicesExtensions.cspPanel.extensions.cspDashboard.url}`,
          name: "project-csp",
          component: CSPProject,
          meta: { requiresAuthentication: true },
          async beforeEnter(to, from, next) {
            await beforeEnterHubService(
              servicesExtensions.cspPanel.name,
              to,
              next
            );
          }
        },
        {
          path: `${servicesExtensions.cspPanel.extensions.cspOperations.url}`,
          name: "csp-operations",
          component: CSPOperations,
          meta: { requiresAuthentication: true },
          async beforeEnter(to, from, next) {
            await beforeEnterHubService(
              servicesExtensions.cspPanel.name,
              to,
              next
            );
          }
        }
      ]
    },
    {
      path: `/${servicesExtensions.spiderLinksChecker.url}/:projectId`,
      redirect: { name: "project-slc" },
      component: ServicesIndex,
      meta: {
        requiresAuthentication: true,
        checkHubService: servicesExtensions.spiderLinksChecker.name
      },
      children: [
        {
          path: `${servicesExtensions.spiderLinksChecker.extensions.analysis.url}`,
          name: "project-slc",
          component: SLCHome,
          meta: { requiresAuthentication: true },
          async beforeEnter(to, from, next) {
            await beforeEnterHubService(
              servicesExtensions.spiderLinksChecker.name,
              to,
              next
            );
          }
        }
      ]
    },
    {
      path: "/new-report/:projectId/:eventId",
      name: "newReport",
      props: true,
      component: ReportEvent,
      meta: { requiresAuthentication: true },
      //Check if event can generate new report
      async beforeEnter(to, from, next) {
        const validEvent: boolean = await checkEventReport(to.params.eventId);
        if (
          validEvent &&
          (await validateAccess(false, null, true, to.params.projectId, false))
        ) {
          next();
        } else {
          next({
            name: notFoundPage
          });
        }
      }
    },
    {
      path: "/account",
      component: Account,
      meta: { requiresAuthentication: true }
    },
    {
      path: "/notifications",
      component: Notifications,
      meta: { requiresAuthentication: true }
    },
    {
      path: "/under-construction",
      component: UnderConstructionPage,
      meta: { requiresAuthentication: true }
    },
    {
      path: "*",
      name: "redirectError",
      redirect: "/error",
      props: true
    },
    {
      path: "/error",
      name: notFoundPage,
      component: ErrorPage,
      props: true
    },
    {
      path: "/error/:code",
      name: "errorPage",
      component: ErrorPage,
      props: true
    }
  ]
});

async function beforeEnterHubService(serviceName, to, next) {
  if (
    await validateAccess(false, serviceName, true, to.params.projectId, false)
  ) {
    next();
  } else {
    next({
      name: notFoundPage
    });
  }
}

//Validate access route
async function validateAccess(
  checkClientId: Boolean,
  serviceName: String,
  checkUser: Boolean,
  projectId: string,
  checkAdminAccess: Boolean
) {
  let validateClientId: boolean;
  let validateService: boolean;
  let validateUserInProject: boolean;
  let validateAdminAccess: boolean;

  if (getUserLogged().isAdmin()) {
    return true;
  }

  if (!checkAdminAccess) validateAdminAccess = true;
  else validateAdminAccess = getUserLogged().isAdmin();

  if (!checkClientId) validateClientId = true;
  else {
    validateClientId = getUserLogged().isSogeti();
  }
  if (serviceName == null) validateService = true;
  else {
    validateService = await checkServiceInProject(
      parseInt(projectId),
      (await getServiceByName(serviceName)).getId
    );
  }
  if (!checkUser) validateUserInProject = true;
  else {
    validateUserInProject = await checkUserBelongToProject(projectId);
  }

  return (
    validateClientId &&
    validateService &&
    validateUserInProject &&
    validateAdminAccess
  );
}

// Check if the service is active in the project and project state is ACTIVE
async function checkServiceInProject(
  projectId: number,
  serviceId: number
): Promise<boolean> {
  try {
    const project: ProjectStateCore = await store.dispatch(
      "projectCore/loadProjectState",
      projectId.toString()
    );
    if (project.getState == projectStates.active.name) {
      const projectService: ProjectServiceCore = await store.dispatch(
        "projectCore/fetchProjectService",
        { serviceId: serviceId, projectId: projectId }
      );
      if (projectService.getServiceId == serviceId) {
        return true;
      }
    }
    return false;
  } catch (err) {
    Singleton.get().vue.$log.error(err);
    return false;
  }
}
// Check if event can generate new report
async function checkEventReport(eventId: string): Promise<boolean> {
  try {
    const event: EventDx = await store.dispatch("eventDx/loadEventDx", eventId);
    if (
      (event.getEventType == eventTypes.COMMON.events.kickOff.type ||
        event.getEventType == eventTypes.COMMON.events.followUp.type ||
        event.getEventType == eventTypes.COMMON.events.closing.type) &&
      (event.getLastHistoryBaseline.status ==
        eventStates.pendingToConfirm.name ||
        event.getOccurred)
    ) {
      return true;
    }
    return false;
  } catch (err) {
    this.$log.error(err);
    return false;
  }
}

// Check if user belong to the project routes he wants to access from the project
async function checkUserBelongToProject(projectId: String): Promise<boolean> {
  await store.dispatch("peopleDx/loadPeopleDx");
  const peopleOfProject: PeopleDx[] =
    store.getters["peopleDx/getPeopleDxByProjectId"](projectId);
  return peopleOfProject.some(
    people => people.getUsername === getUserLogged().getUsername
  );
}

/**
 * Gets the current logged user.
 * @returns PeopleCore
 */
function getUserLogged(): PeopleCore {
  return store.getters["peopleCore/getUser"];
}

/**
 * Reload all services to Vuex Store
 * @returns Promise
 */
async function loadAllServices(): Promise<any> {
  return await store.dispatch("serviceCore/loadServices");
}

/**
 * Gets a specific service after reloading all services, for further validation
 * of access authorization to a specific service, in case it is accessed by direct URL,
 * where the natural loading flow (home page) is avoided.
 * @param serviceName
 * @returns Promise<ServiceCore>
 */
async function getServiceByName(serviceName): Promise<ServiceCore> {
  await loadAllServices();
  return store.getters["serviceCore/getServiceByName"](serviceName);
}

router.beforeEach((to, from, next) => {
  store.dispatch("loadingComponents/clearLoadingComponents");
  Singleton.get().vue.$log.info(
    `User navigating to: ${to.path} ${
      Object.keys(to.params).length ? "| " + JSON.stringify(to.params) : ""
    }`
  );
  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    // this route requires auth, check if logged in
    const coreAuth = authService.get();
    coreAuth
      .isAuthenticated()
      .then(() => {
        // only proceed if authenticated
        next();
      })
      .catch(async err => {
        Vue.$log.error(`Route require authentication: ${err}`);
        await coreAuth.signIn();
      });
  } else {
    next();
  }
  if (to.matched.some(record => record.meta.checkHubService)) {
    beforeEnterHubService(to.meta.checkHubService, to, next);
  }
});

export default router;
