import ExtensionServiceCore from "../../models/core/extension/ExtensionServiceCore";
import ExtensionCore from "../../models/core/ExtensionCore";
import service from "../service";
import baseEndpoint from "./serviceCore";

const endpoint = baseEndpoint + "/Extension";

export default {
  components: { service: service },
  methods: {
    async getExtensions(): Promise<ExtensionCore[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(extension => new ExtensionCore(extension));
    },
    async getExtension(id: number): Promise<ExtensionCore> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new ExtensionCore(response);
    },
    async getClientCompatibleExtensions(
      id: number
    ): Promise<ExtensionServiceCore[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/client/${id}/compatible`
      );
      return response.map(extension => new ExtensionServiceCore(extension));
    },
    async getExtensionService(): Promise<ExtensionServiceCore[]> {
      const extensionsServices = await service.methods.getEndpointAsync(
        `${endpoint}/service`
      );
      return extensionsServices.map(
        extensionServices => new ExtensionServiceCore(extensionServices)
      );
    }
  }
};
