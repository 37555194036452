export interface ITagDx {
  id?: string;
  projectId: number;
  tags: string[];
}

export default class TagDx {
  private id?: string;
  private projectId: number;
  private tags: string[];

  constructor(dto: ITagDx) {
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get getTags(): string[] {
    return this.tags;
  }

  set setTags(value: string[]) {
    this.tags = value;
  }

  addTag(tag: string): void {
    if (this.tags.indexOf(tag) === -1) this.tags.push(tag);
  }

  removeTag(tag: string): void {
    const tagIndex: number = this.tags.indexOf(tag);
    if (tagIndex > -1) {
      this.tags.splice(tagIndex, 1);
    }
  }
}
