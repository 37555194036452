import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import CategoryCore from "../../../models/core/CategoryCore";
import categoryService from "../../../services/core/categoryService";
import { RootState } from "../../index";

const namespaced = true;

interface CategoryState {
  categories: CategoryCore[];
}

const state: CategoryState = {
  categories: []
};

const mutations: MutationTree<CategoryState> = {
  setCategories(state, categories: CategoryCore[]) {
    state.categories = categories;
  }
};

const getters: GetterTree<CategoryState, RootState> = {
  getCategories: (state): CategoryCore[] => {
    return state.categories;
  }
};

const actions: ActionTree<CategoryState, RootState> = {
  async loadCatalog({ dispatch }) {
    dispatch("loadCategories");
    dispatch("serviceCore/loadServices", null, { root: true });
    dispatch("extensionCore/loadExtensions", null, { root: true });
    dispatch("clientCore/loadClients", null, { root: true });
  },
  async loadCategories({ commit }) {
    const categories: CategoryCore[] =
      await categoryService.methods.getCategories();
    commit("setCategories", categories);
  }
};

export const categoryModuleCore: Module<CategoryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
