import Vue from "vue";
import Vuex from "vuex";
import { capacityTypeModuleCore } from "./modules/core/capacityTypeModule";
import { categoryModuleCore } from "./modules/core/categoryModule";
import { clientModuleCore } from "./modules/core/clientModule";
import { extensionModuleCore } from "./modules/core/extensionModule";
import { peopleModuleCore } from "./modules/core/peopleModule";
import { projectModuleCore } from "./modules/core/projectModule";
import { serviceModuleCore } from "./modules/core/serviceModule";
import { customerModuleCSP } from "./modules/csp/customerModule";
import { customerSummaryModuleCSP } from "./modules/csp/customerSummaryModule";
import { marginModuleCSP } from "./modules/csp/marginModule";
import { pendingOperationModuleCSP } from "./modules/csp/pendingOperationModule";
import { productModuleCSP } from "./modules/csp/productModule";
import { subscriptionModuleCSP } from "./modules/csp/subscriptionModule";
import { documentModuleDx } from "./modules/deliveryexperience/documentModule";
import { eventModuleDx } from "./modules/deliveryexperience/eventModule";
import { notificationModuleDx } from "./modules/deliveryexperience/notificationModule";
import { peopleModuleDx } from "./modules/deliveryexperience/peopleModule";
import { projectModuleDx } from "./modules/deliveryexperience/projectModule";
import { requestModuleDx } from "./modules/deliveryexperience/requestModule";
import { stageModuleDx } from "./modules/deliveryexperience/stageModule";
import { tagModuleDX } from "./modules/deliveryexperience/tagModule";
import { teamModuleDx } from "./modules/deliveryexperience/teamModule";
import { versionModuleDx } from "./modules/deliveryexperience/versionModule";
import languageModule from "./modules/language";
import loadingComponentsModule from "./modules/loadingComponents";
import messageModule from "./modules/message";
import { analysisModuleSLC } from "./modules/spiderlinkschecker/analysisModule";

Vue.use(Vuex);

export interface RootState {
  version: string;
}
// const vuexPersist = new VuexPersist({
//   key: "sogetihub",
//   storage: localStorage
// });

export const store = new Vuex.Store<RootState>({
  state: {
    version: "1.0.0"
  },
  modules: {
    /* GLOBAL */
    language: languageModule,
    loadingComponents: loadingComponentsModule,
    message: messageModule,
    /* CORE */
    capacityTypeCore: capacityTypeModuleCore,
    categoryCore: categoryModuleCore,
    clientCore: clientModuleCore,
    extensionCore: extensionModuleCore,
    peopleCore: peopleModuleCore,
    projectCore: projectModuleCore,
    serviceCore: serviceModuleCore,
    /* DELIVERY EXPERIENCE */
    documentDx: documentModuleDx,
    eventDx: eventModuleDx,
    notificationDx: notificationModuleDx,
    peopleDx: peopleModuleDx,
    projectDx: projectModuleDx,
    requestDx: requestModuleDx,
    stageDx: stageModuleDx,
    tagDx: tagModuleDX,
    teamDx: teamModuleDx,
    versionDx: versionModuleDx,
    /* CSP */
    customerCSP: customerModuleCSP,
    customerSummaryCSP: customerSummaryModuleCSP,
    marginCSP: marginModuleCSP,
    pendingOperationCSP: pendingOperationModuleCSP,
    productsCSP: productModuleCSP,
    subscriptionCSP: subscriptionModuleCSP,
    /* SPIDER LINKS CHECKER */
    analysisSLC: analysisModuleSLC
  }
  // plugins: [vuexPersist.plugin]
});
