import axios from "axios";
import AuthService from "../authentication";
import authServiceCSP from "../authentication/AuthServiceCSPInst";
import authServiceDX from "../authentication/AuthServiceDeliveryExpInst";
import authServiceCore from "../authentication/AuthServiceInst";
import authServiceSLC from "../authentication/AuthServiceSpiderLinksCheckerInst";
import authServiceUC from "../authentication/AuthServiceUnoConvInst";
import config from "../config";
import partnerCenterService from "../services/csp/partnerCenterService";
import Singleton from "../utils/singleton";

export default function setup() {
  const dxEndpoint = config.apiDxEndpoint;
  const coreEndpoint = config.apiCoreEndpoint;
  const spiderLinksCheckerEndpoint = config.apiSpiderLinksCheckerEndpoint;
  const partnerCenterEndpoint = config.apiPartnerCenterEndpoint;
  const unoconvEndpoint = config.unoconvEndpoint;
  const cspEndpoint = config.apiCSPEndpoint;

  axios.interceptors.request.use(
    async config => {
      // Set common request headers for every request
      config.headers["Content-Type"] = "application/json";
      try {
        if (config.url.includes(coreEndpoint)) {
          config.headers["Access-Control-Allow-Origin"] =
            coreEndpoint.substring(0, coreEndpoint.length - 4);
          return await getAuthToken(authServiceCore.get(), config);
        }

        if (config.url.includes(dxEndpoint)) {
          config.headers["Access-Control-Allow-Origin"] = dxEndpoint.substring(
            0,
            dxEndpoint.length - 4
          );
          return await getAuthToken(authServiceDX.get(), config);
        }

        if (config.url.includes(cspEndpoint)) {
          config.headers["Access-Control-Allow-Origin"] = cspEndpoint.substring(
            0,
            cspEndpoint.length - 4
          );
          return await getAuthToken(authServiceCSP.get(), config);
        }

        if (config.url.includes(spiderLinksCheckerEndpoint)) {
          return await getAuthToken(authServiceSLC.get(), config);
        }

        if (config.url.includes(partnerCenterEndpoint)) {
          config.headers["Authorization"] =
            await partnerCenterService.methods.getAccessToken(); //API PARTNER CENTER
          return config;
        }

        if (config.url.includes(unoconvEndpoint)) {
          return await getAuthToken(authServiceUC.get(), config);
        }

        return config;
      } catch (err) {
        return Promise.reject(err);
      }
    },
    err => {
      Singleton.get().vue.$log.error(
        `Error processing request in interceptor: ${err}`
      );
      return Promise.reject(err);
    }
  );
}

/**
 * Given an AuthService it will get an specific token for backend calls
 * @param authService
 * @param config
 */
async function getAuthToken(customService: AuthService, config): Promise<any> {
  try {
    const res = await customService.getTokenSilent();
    config.headers["Authorization"] = `Bearer ${res.accessToken}`;
  } catch (err) {
    Singleton.get().vue.$log.error(`Cannot get token: ${err}`);
  }
  return config;
}
