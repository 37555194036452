import StageDx from "../../models/deliveryexperience/StageDx";
import EventDx from "../../models/deliveryexperience/EventDx";
import AffectedEventStage from "./AffectedEventStage";
import TimeElapsed from "./TimeElapsed";

export interface IPredecessorWithAffected {
  event?: EventDx;
  stage?: StageDx;
  affected: AffectedEventStage;
  timeElapsed: TimeElapsed;
}

export default class PredecessorWithAffected {
  private event?: EventDx;
  private stage?: StageDx;
  private affected: AffectedEventStage;
  private timeElapsed: TimeElapsed;

  constructor(value: IPredecessorWithAffected) {
    Object.assign(this, value);
  }

  //Getters
  get getEvent(): EventDx {
    return this.event;
  }

  get getStage(): StageDx {
    return this.stage;
  }

  get getAffected(): AffectedEventStage {
    return this.affected;
  }

  get getTimeElapsed(): TimeElapsed {
    return this.timeElapsed;
  }

  //Setters
  set setEvent(value: EventDx) {
    this.event = value;
  }

  set setStage(value: StageDx) {
    this.stage = value;
  }

  set setAffected(value: AffectedEventStage) {
    this.affected = value;
  }

  set setTimeElapsed(value: TimeElapsed) {
    this.timeElapsed = value;
  }
}
