import RequestDx from "../../models/deliveryexperience/RequestDx";
import service from "../service";
import baseEndpoint from "./serviceDx";

const endpoint = baseEndpoint + "/Request";

export default {
  components: { service: service },
  methods: {
    async getRequests(): Promise<RequestDx[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(request => new RequestDx(request));
    },
    async getRequestsByProject(projectId: number): Promise<RequestDx[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/project/${projectId}`
      );
      return response.map(request => new RequestDx(request));
    },
    async getRequest(id: string): Promise<RequestDx> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new RequestDx(response);
    },
    async putRequest(modifiedRequest: RequestDx): Promise<RequestDx> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${modifiedRequest.getId}`,
        modifiedRequest
      );
      return new RequestDx(response);
    },
    async postRequest(request: RequestDx): Promise<RequestDx> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        request
      );
      return new RequestDx(response);
    },
    async deleteRequest(id: string): Promise<any> {
      return await service.methods.deleteEndpointAsync(`${endpoint}/${id}`);
    }
  }
};
