export const roleTypes = {
  admin: {
    name: "ADMIN",
    i18n: "admin"
  },
  manager: {
    name: "MANAGER",
    i18n: "manager"
  },
  user: {
    name: "USER",
    i18n: "user"
  }
};

export function getRoleTypeTranslation(roleName: string): string | null {
  for (const roleType in roleTypes) {
    if (roleTypes[roleType].name === roleName) {
      return roleTypes[roleType].i18n;
    }
  }
  return null;
}
