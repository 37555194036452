import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import TeamDx from "../../../models/deliveryexperience/TeamDx";
import teamServiceDx from "../../../services/deliveryexperience/teamService";
import { RootState } from "../../index";

interface ITeamState {
  teamsDx: TeamDx[];
}

const namespaced = true;

const state: ITeamState = {
  teamsDx: []
};

const getters: GetterTree<ITeamState, RootState> = {
  getTeamDxByProjectId:
    state =>
    (projectId: number): TeamDx => {
      return state.teamsDx.find(team => team.getProjectId == projectId);
    }
};

const mutations: MutationTree<ITeamState> = {
  setTeamsDx(state, teams: TeamDx[]): void {
    state.teamsDx = teams;
  },
  updateTeamDx(state, updatedTeamDx: TeamDx): void {
    const index = state.teamsDx.findIndex(t => t.getId === updatedTeamDx.getId);
    if (index != -1) {
      state.teamsDx.splice(index, 1, updatedTeamDx);
    }
  },
  deleteTeamDx(state, teamId: string): void {
    const index = state.teamsDx.findIndex(team => team.getId == teamId);
    state.teamsDx.splice(index, 1);
  }
};

const actions: ActionTree<ITeamState, RootState> = {
  async loadTeamsDx({ commit }): Promise<any> {
    const teams: TeamDx[] = await teamServiceDx.methods.getTeams();
    commit("setTeamsDx", teams);
  },
  async putTeamDx({ commit }, modifiedTeam: TeamDx): Promise<any> {
    const team: TeamDx = await teamServiceDx.methods.putTeam(modifiedTeam);
    commit("updateTeamDx", team);
  },
  async deleteTeamDxLocal({ commit }, teamId: TeamDx): Promise<any> {
    commit("deleteTeamDx", teamId);
  }
};

export const teamModuleDx: Module<ITeamState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
