import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import PeopleCore from "../../../models/core/PeopleCore";
import PeopleDx from "../../../models/deliveryexperience/PeopleDx";
import Permissions from "../../../models/deliveryexperience/Permissions/Permissions";
import peopleServiceDx from "../../../services/deliveryexperience/peopleService";
import { RootState } from "../../index";

interface IPeopleState {
  peopleDxInCurrentProject: PeopleDx;
  peoplesDx: PeopleDx[]; // PeopleDx from user client and Sogeti client.
  peopleDxColor: string[]; // To know Default Icon in PeopleDefaultIcon component.
}

const namespaced = true;

const state: IPeopleState = {
  peopleDxInCurrentProject: null,
  peoplesDx: [], // PeopleDx from user client and Sogeti client.
  peopleDxColor: [] // To know Default Icon in PeopleDefaultIcon component.
};

const getters: GetterTree<IPeopleState, RootState> = {
  getPeopleDxColorIds: (state): string[] => {
    return state.peopleDxColor;
  },
  getPeopleDxInCurrentProject: (state): PeopleDx => {
    return state.peopleDxInCurrentProject;
  },
  getUserPermissions: (state): Permissions => {
    return state.peopleDxInCurrentProject.getPermissions;
  },
  getPeopleDxById:
    state =>
    (peopleId: string): PeopleDx => {
      return state.peoplesDx.find(people => people.getId == peopleId);
    },
  getPeopleDxByProjectId:
    state =>
    (projectId: number): PeopleDx[] => {
      return state.peoplesDx.filter(people => people.getProjectId == projectId);
    },
  getPOCsByProjectId:
    state =>
    (projectId: number): PeopleDx[] => {
      return state.peoplesDx.filter(
        people => people.getProjectId == projectId && people.isPOC
      );
    },
  getPeopleDxByEventId:
    state =>
    (eventId: string): PeopleDx[] => {
      return state.peoplesDx.filter(people =>
        people.getEvents.includes(eventId)
      );
    },
  getPeopleDxByStageId:
    state =>
    (stageId: string): PeopleDx[] => {
      return state.peoplesDx.filter(people =>
        people.getStages.includes(stageId)
      );
    },
  getUserDxInProject:
    state =>
    (projectId: number, username: string): PeopleDx => {
      const foundPeople = state.peoplesDx.filter(
        people =>
          people.getProjectId == projectId && people.getUsername == username
      );
      if (foundPeople.length > 0) {
        return foundPeople[0];
      } else {
        return null;
      }
    }
};

const mutations: MutationTree<IPeopleState> = {
  setPeoplesDx(state, peoples: PeopleDx[]): void {
    peoples.forEach(p => {
      const index = state.peoplesDx.findIndex(
        people => people.getId === p.getId
      );
      if (index === -1) state.peoplesDx.push(p);
      else state.peoplesDx[index] = p;
    });
  },
  setPeopleDxInCurrentProject(state, peopleProject: PeopleDx): void {
    state.peopleDxInCurrentProject = peopleProject;
  },
  updatePeopleDx(state, updatedPeopleDx: PeopleDx): void {
    const index = state.peoplesDx.findIndex(
      p => p.getId === updatedPeopleDx.getId
    );
    if (index != -1) {
      state.peoplesDx.splice(index, 1, updatedPeopleDx);
    }
  },
  addPeopleDx(state, people: PeopleDx): void {
    state.peoplesDx.push(people);
  },
  deletePeopleDx(state, peopleId: string): void {
    const index = state.peoplesDx.findIndex(people => people.getId == peopleId);
    state.peoplesDx.splice(index, 1);
  },
  pushPeopleDxColor(state, peopleId: string): void {
    state.peopleDxColor.push(peopleId);
  }
};

const actions: ActionTree<IPeopleState, RootState> = {
  async loadPeopleDx({ commit }): Promise<any> {
    const peoples: PeopleDx[] = await peopleServiceDx.methods.getPeoples();
    commit("setPeoplesDx", peoples);
  },
  async loadPeopleDxInCurrentProject(
    { commit, rootGetters },
    projectId: number
  ): Promise<any> {
    const peopleCore: PeopleCore = rootGetters["peopleCore/getUser"];
    let peopleDx: PeopleDx = state.peoplesDx.find(
      people =>
        people.getUsername == peopleCore.getUsername &&
        people.getProjectId == projectId
    );
    if (peopleDx && peopleCore.isAdmin()) {
      peopleDx.setPermissions = new Permissions(undefined, true);
    } else if (peopleCore.isAdmin()) {
      peopleDx = new PeopleDx({
        userName: peopleCore.getUsername,
        events: [],
        stages: [],
        permissions: new Permissions(undefined, false)
      });
    }
    commit("setPeopleDxInCurrentProject", peopleDx);
  },
  async loadPeopleDxByProjectId({ commit }, projectId: number): Promise<any> {
    const peoples: PeopleDx[] =
      await peopleServiceDx.methods.getPeoplesByProject(projectId);
    commit("setPeoplesDx", peoples);
  },
  async loadPeopleDxByUsername({ commit }, username: string): Promise<any> {
    const peoples: PeopleDx[] =
      await peopleServiceDx.methods.getPeoplesByUsername(username);
    commit("setPeoplesDx", peoples);
  },
  async postPeopleDx({ commit }, newPeople: PeopleDx): Promise<any> {
    const people: PeopleDx = await peopleServiceDx.methods.postPeople(
      newPeople
    );
    commit("addPeopleDx", people);
    return people;
  },
  async putPeopleDx({ commit }, modifiedPeople: PeopleDx): Promise<any> {
    const people: PeopleDx = await peopleServiceDx.methods.putPeople(
      modifiedPeople
    );
    commit("updatePeopleDx", people);
  },
  async deletePeopleDx(
    { commit, dispatch, rootGetters },
    peopleId: string
  ): Promise<any> {
    await peopleServiceDx.methods.deletePeople(peopleId);
    commit("deletePeopleDx", peopleId);

    /* Cascade */
    for (const document of rootGetters["documentDx/getDocumentsDxByPeopleId"](
      peopleId
    )) {
      const index = document.getPeople.indexOf(peopleId);
      if (index != -1) {
        document.setPeople = document.getPeople.splice(index, 1);
        await dispatch("documentDx/updateDocumentDxLocal", document, {
          root: true
        });
      }
    }
  },
  updatePeopleDxLocal({ commit }, modifiedPeople: PeopleDx): void {
    commit("updatePeopleDx", modifiedPeople);
  },
  deletePeopleDxLocal({ commit }, peopleId: string): void {
    commit("deletePeopleDx", peopleId);
  },
  pushPeopleDxColorIdLocal({ commit }, peopleId: string): void {
    commit("pushPeopleDxColor", peopleId);
  }
};

export const peopleModuleDx: Module<IPeopleState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
