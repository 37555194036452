






















































  import Datepicker from "vuejs-datepicker";
  import { es } from "vuejs-datepicker/dist/locale";
  import { en } from "vuejs-datepicker/dist/locale";

  export default {
    name: "CapsuleInputDate",
    components: { Datepicker },
    props: {
      label: String,
      placeholder: { type: String, default: "--/--/--" },
      date: { type: Date, default: undefined },
      required: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      datesFrom: { type: Date, default: undefined },
      datesTo: { type: Date, default: undefined },
      dataName: String,
      from: String,
      background: { type: String, default: "white" },
      comeFrom: String
    },
    data() {
      return {
        es: es,
        en: en,
        //By default enable all dates
        disabledDates: { to: "", from: "" },
        activeStyle: Boolean(false)
      };
    },
    mounted() {
      if (this.datesFrom) {
        this.disabledDates.to = new Date(
          this.datesFrom.getFullYear(),
          this.datesFrom.getMonth(),
          this.datesFrom.getDate()
        );
        if (this.date_) {
          if (this.disabledDates.to.getTime() > this.date_.getTime()) {
            this.date_ = undefined;
          }
        }
      }
      if (this.datesTo) {
        this.disabledDates.from = new Date(
          this.datesTo.getFullYear(),
          this.datesTo.getMonth(),
          this.datesTo.getDate() + 1
        );
        if (this.date_) {
          if (this.disabledDates.from.getTime() < this.date_.getTime()) {
            this.date_ = undefined;
          }
        }
      }
    },
    computed: {
      languageSelected: function () {
        if (this.$i18n.locale == "EN") {
          return this.en;
        } else {
          return this.es;
        }
      },
      date_: {
        get() {
          return this.date;
        },
        set(value) {
          this.$emit("setValue", value);
        }
      }
    },
    methods: {
      /**
       * Effectively a toggle to show/hide the calendar
       * @return {mixed}
       */
      openPicker() {
        this.activeStyle = !this.activeStyle;
        this.$refs.datePicker.showCalendar();
      },
      closePicker() {
        this.activeStyle = false;
      }
    },
    watch: {
      datesFrom: function () {
        if (this.datesFrom) {
          this.disabledDates.to = new Date(
            this.datesFrom.getFullYear(),
            this.datesFrom.getMonth(),
            this.datesFrom.getDate() + 1
          );
        } else {
          this.disabledDates.to = new Date();
        }
        if (this.date_) {
          if (this.disabledDates.to.getTime() > this.date_.getTime()) {
            this.date_ = undefined;
          }
        }
      },
      datesTo: function () {
        if (this.datesTo) {
          this.disabledDates.from = new Date(
            this.datesTo.getFullYear(),
            this.datesTo.getMonth(),
            this.datesTo.getDate()
          );
          if (this.date_) {
            if (this.disabledDates.from.getTime() < this.date_.getTime()) {
              this.date_ = undefined;
            }
          }
        }
      }
    }
  };
