import VueI18n from "vue-i18n";
import { i18n } from "../plugins/i18";

const phasesAndScopes = [
  {
    id: 100,
    i18n: "methodPhaseProposal",
    scopes: [
      {
        id: 101,
        i18n: "methodProposalApproach",
        important: true
      },
      {
        id: 102,
        i18n: "methodCustomerOffer",
        important: true
      }
    ]
  },
  {
    id: 200,
    i18n: "methodPhaseStarting",
    scopes: [
      {
        id: 201,
        i18n: "methodPrerequirements",
        important: true
      },
      {
        id: 202,
        i18n: "methodProjectPlan",
        important: true
      },
      {
        id: 203,
        i18n: "methodTransparencyPlan",
        important: true
      },
      {
        id: 204,
        i18n: "methodKickOff",
        important: true
      }
    ]
  },
  {
    id: 300,
    i18n: "methodPhaseExecutionDevelopment",
    scopes: [
      {
        id: 301,
        i18n: "methodRequirementsFunctional",
        important: true
      },
      {
        id: 302,
        i18n: "methodRequirementsNonFunctional",
        important: true
      },
      {
        id: 303,
        i18n: "methodRisks",
        important: true
      },
      {
        id: 304,
        i18n: "methodGoodPractices",
        important: true
      },
      {
        id: 305,
        i18n: "methodEnvironmentsDeployment",
        important: true
      },
      {
        id: 306,
        i18n: "methodSoftwareAssetsManagement",
        important: true
      },
      {
        id: 307,
        i18n: "methodDesignArchitecture",
        important: true
      },
      {
        id: 308,
        i18n: "methodUXUI",
        important: true
      }
    ]
  },
  {
    id: 400,
    i18n: "methodPhaseExecutionQualityEngineering",
    scopes: [
      {
        id: 401,
        i18n: "methodQEStrategy",
        important: true
      },
      {
        id: 402,
        i18n: "methodReportingMetrics",
        important: true
      },
      {
        id: 403,
        i18n: "methodTestingPlan",
        important: true
      },
      {
        id: 404,
        i18n: "methodDefectManagement",
        important: true
      },
      {
        id: 405,
        i18n: "methodTestAutomation",
        important: true
      },
      {
        id: 406,
        i18n: "methodDataEnvironmentManagement",
        important: true
      },
      {
        id: 407,
        i18n: "methodTestware",
        important: true
      }
    ]
  },
  {
    id: 500,
    i18n: "methodPhaseExecutionReportingGovernance",
    scopes: [
      {
        id: 501,
        i18n: "methodCustomerSatisfactionAcceptance",
        important: true
      },
      {
        id: 502,
        i18n: "methodRelationshipMonitoringModel",
        important: true
      },
      {
        id: 503,
        i18n: "methodInternalMonitoring",
        important: true
      },
      {
        id: 504,
        i18n: "methodPlanningManagement",
        important: true
      },
      {
        id: 505,
        i18n: "methodKnowledgeManagement",
        important: true
      },
      {
        id: 506,
        i18n: "methodEconomicManagement",
        important: true
      },
      {
        id: 507,
        i18n: "methodTeamRolesResponsibilities",
        important: true
      },
      {
        id: 508,
        i18n: "methodSubcontracting",
        important: true
      },
      {
        id: 509,
        i18n: "methodChangesManagement",
        important: true
      }
    ]
  },
  {
    id: 600,
    i18n: "methodPhaseWarranty",
    scopes: [
      {
        id: 601,
        i18n: "methodWarrantyPlanning",
        important: true
      },
      {
        id: 602,
        i18n: "methodWarrantyExecution",
        important: true
      }
    ]
  },
  {
    id: 700,
    i18n: "methodPhaseClosing",
    scopes: [
      {
        id: 701,
        i18n: "methodClosingManagement",
        important: true
      },
      {
        id: 702,
        i18n: "methodPossibleSuccessCase",
        important: true
      }
    ]
  }
];

function getPhaseByScope(scopeId: number): any | undefined {
  for (const phase of phasesAndScopes) {
    for (const scope of phase.scopes) {
      if (scope.id == scopeId)
        return {
          phase: phase,
          scope: scope
        };
    }
  }
  return undefined;
}

function getScopeName(id): VueI18n.TranslateResult {
  for (const phase of phasesAndScopes) {
    for (const scope of phase.scopes) {
      if (scope.id == id) return scope ? i18n.t(scope.i18n) : "";
    }
  }
  return "";
}

export default {
  phasesAndScopes,
  getPhaseByScope,
  getScopeName
};
