export interface IProductCSP {
  id: string;
  title: string;
  skusLink: string;
  skusList: Object[];
  productType: String;
}

export default class ProductCSP {
  id: string;
  title: string;
  skusLink: string;
  skusList: Object[];
  productType: String;

  constructor(dto: IProductCSP) {
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }

  get getTitle(): string {
    return this.title;
  }

  set setTitle(value: string) {
    this.title = value;
  }

  get getSkusLink(): string {
    return this.skusLink;
  }

  set setSkusLink(value: string) {
    this.skusLink = value;
  }

  get getSkusList(): Object[] {
    return this.skusList;
  }

  set setSkusList(value: Object[]) {
    this.skusList = value;
  }

  get getProductType(): String {
    return this.productType;
  }

  set setProductType(value: String) {
    this.productType = value;
  }
}
