












































































  import { getRoleTypeTranslation } from "../../_helpers/roleTypes";
  import UserForm from "../../components/home/users/UserForm.vue";
  import CapsuleLabelInput from "../../components/view_elements/CapsuleLabelInput.vue";
  import CapsuleMultiselect from "../../components/view_elements/CapsuleMultiselect.vue";
  import ConfirmationModal from "../../components/view_elements/ConfirmationModal.vue";
  import PeopleInfo from "../../components/view_elements/PeopleInfo.vue";
  import PeopleIcon from "../../view-models/PeopleIcon";
  import SingleSelectType from "../../view-models/SingleSelectType";

  export default {
    name: "UsersTab",
    components: {
      UserForm,
      PeopleInfo,
      CapsuleMultiselect,
      CapsuleLabelInput,
      ConfirmationModal
    },
    props: { id: String },
    data() {
      return {
        UserFormId: "user-form",
        usernameSelected: "",
        userNameFilter: "",
        clientSelected: undefined,
        text: {
          searchUser: `${this.$t("search")} ${this.$tc("userLowCase", 1)}`,
          newUser: this.$tc("new", 1) + " " + this.$tc("user", 1).toLowerCase(),
          name: this.$t("name")
        },
        fieldsNames: [
          {
            label: this.$tc("user", 1),
            key: "peopleInfo",
            sortable: true,
            thClass: "position-sticky"
          },
          {
            label: "Email",
            key: "username",
            thClass: "position-sticky"
          },
          {
            label: this.$t("incorporation"),
            key: "incorporationDate",
            sortable: true,
            thClass: "position-sticky"
          },
          {
            label: this.$t("role"),
            key: "role",
            sortable: true,
            thClass: "position-sticky"
          },
          {
            label: this.$tc("client", 1),
            key: "clientName",
            sortable: true,
            thClass: "position-sticky"
          },
          { label: "", key: "editButton", thClass: "position-sticky" }
        ]
      };
    },
    created: async function () {
      await this.loadPeople();
      await this.loadClients();
    },
    computed: {
      clientsList(): Array<SingleSelectType> {
        return this.getClients.map(
          c =>
            new SingleSelectType({
              key: c.getId,
              name: c.getName
            })
        );
      },
      tableContentPeople(): {
        peopleInfo: PeopleIcon;
        userInformation: {
          firstName: string;
          lastName: string;
          jobPosition: string;
        };
        username: string;
        incorporationDate: Date;
        role: number;
        clientName: string;
      } {
        return this.getPeople
          .filter(p => {
            if (
              this.clientSelected &&
              p.getClientId != this.clientSelected.getKey
            )
              return false;

            return !(
              this.userNameFilter &&
              !p
                .getNaturalFullName()
                .toLowerCase()
                .includes(this.userNameFilter.toLowerCase())
            );
          })
          .map(p => {
            return {
              peopleInfo: PeopleIcon.newPeopleIconFromCore(p),
              username: p.getUsername,
              incorporationDate: p.getIncorporationDate,
              role: p.getRoleId,
              clientName: this.getClientById(p.getClientId).getName
            };
          });
      }
    },
    methods: {
      openUserForm(usernameSelected: String) {
        this.usernameSelected = usernameSelected;
        this.$bvModal.show(this.UserFormId);
      },
      getRoleTypeTranslation(roleName: string) {
        return this.$tc(getRoleTypeTranslation(roleName), 1);
      },
      compareElements(itemA, itemB, key) {
        if (key === "peopleInfo") {
          const nameA = itemA[key].firstName + itemA[key].lastName;
          const nameB = itemB[key].firstName + itemB[key].lastName;
          return nameB.localeCompare(nameA);
        } else if (key === "incorporationDate") {
          return (
            new Date(itemB[key]).getTime() - new Date(itemA[key]).getTime()
          );
        } else {
          return itemB[key].localeCompare(itemA[key]);
        }
      },
      performDeletePeople(username) {
        this.deletePeople(username);
        this.$nextTick(async () => {
          await this.$bvModal.hide("modal-confirmation-delete-user");
        });
      }
    }
  };
