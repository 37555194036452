var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"d-flex flex-column w-100 h-100"},[(_vm.docsToShow.length)?[_c('transition',{attrs:{"name":"bounce","mode":"out-in"}},[_c('div',{staticClass:"small-scrollbar",class:{
            'docs-table': _vm.docsToShow.length > 3
          }},[_c('b-table',{staticClass:"w-100",attrs:{"hover":"","items":_vm.docsToShow,"thead-class":"d-none","fields":_vm.docsTableFields,"head-variant":"light"},scopedSlots:_vm._u([{key:"cell(icon)",fn:function(data){return [_c('img',{staticStyle:{"margin-right":"1em"},attrs:{"src":_vm.docTypes.getFileIconByExtension(
                    data.item.name,
                    data.item.isLink
                  ),"height":"30","width":"30","alt":""}})]}},{key:"cell(filename)",fn:function(data){return [_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"height":"30px","width":"225px"}},[(data.item.isLink)?_c('a',{attrs:{"href":data.item.url,"target":"_blank"}},[_vm._v(_vm._s(data.item.name))]):_c('p',{staticClass:"text-truncate",staticStyle:{"margin":"0"},attrs:{"title":data.item.name}},[_vm._v(" "+_vm._s(data.item.name)+" ")])])]}},(_vm.methodology)?{key:"cell(artifactName)",fn:function(data){return [_vm._v(_vm._s(data.item.methodology.artifactName))]}}:null,{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex float-right"},[(
                    _vm.docsToUpload.some(
                      function (d) { return d.existingDoc && d.docDx.getId == data.item.getId; }
                    )
                  )?_c('button',{directives:[{name:"b-popover",rawName:"v-b-popover.hover",value:(_vm.getTooltipConfig(data.item)),expression:"getTooltipConfig(data.item)",modifiers:{"hover":true}}],staticClass:"btn btn-sm",on:{"click":function($event){_vm.findDocToUploadByDocDx(data.item).overwrite =
                      !_vm.findDocToUploadByDocDx(data.item).overwrite}}},[_c('FontAwesomeIcon',{staticClass:"fa-lg",style:({
                      height: '19px',
                      color: _vm.findDocToUploadByDocDx(data.item).overwrite
                        ? 'var(--main-bg-color)'
                        : 'var(--dark-gray-25)'
                    }),attrs:{"icon":"fas fa-history"}})],1):_vm._e(),_c('button',{staticClass:"btn btn-sm",on:{"click":function($event){return _vm.removeDoc(data.item)}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"20","color":"#ff304c"},attrs:{"icon":"fa-regular fa-trash-can"}})],1)])]}}],null,true)})],1)]),_c('div',{staticClass:"docs-drag-and-drop d-flex mt-2 w-100 align-items-center",class:{
          'docs-drag-and-drop-draggedover': _vm.draggingover != 0
        },on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){_vm.draggingover++},"dragleave":function($event){_vm.draggingover--},"drop":_vm.handleDrop}},[_c('div',{staticStyle:{"width":"fit-content"}},[_c('b-button',{staticClass:"button-hover",staticStyle:{"position":"relative","background-color":"transparent","border":"none"},on:{"dragenter":function($event){$event.stopPropagation();},"dragleave":function($event){$event.stopPropagation();}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"25"},attrs:{"icon":"fas fa-plus"}}),_c('input',{staticClass:"docs-file-input",attrs:{"type":"file","size":"60","id":"add-docs-input","multiple":""},on:{"change":_vm.handleSelect}})],1)],1),_c('div',{staticClass:"d-flex justify-content-center flex-column align-items-center w-100",on:{"click":_vm.triggerFileSelect}},[_c('div',{staticStyle:{"width":"fit-content","margin-left":"-2rem"}},[_c('div',{staticClass:"text-center mt-3",class:{ 'float-animation': _vm.draggingover != 0 }},[_c('FontAwesomeIcon',{staticStyle:{"height":"40","pointer-events":"none","opacity":"0.1"},attrs:{"icon":"fa-solid fa-cloud-arrow-up"}})],1),_c('p',{staticStyle:{"font-size":"12px","pointer-events":"none","max-width":"200px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.text.dragAndDropOr)+" ")])])])])]:(
        _vm.action == 'create' ||
        _vm.action == 'methodology' ||
        (_vm.action == 'edit' && (_vm.eventId || _vm.stageId || _vm.requestId))
      )?_c('div',{staticClass:"docs-drag-and-drop w-100 h-100 flex-column justify-content-center align-items-center",class:{
        'd-flex': !_vm.docsToShow.length,
        'docs-drag-and-drop-draggedover': _vm.draggingover != 0
      },on:{"dragover":function($event){$event.preventDefault();},"dragenter":function($event){_vm.draggingover++},"dragleave":function($event){_vm.draggingover--},"drop":_vm.handleDrop}},[_c('div',{class:{ 'float-animation': _vm.draggingover != 0 }},[_c('FontAwesomeIcon',{staticStyle:{"height":"60","opacity":"0.1","pointer-events":"none","padding-top":"2em"},attrs:{"icon":"fa-solid fa-cloud-arrow-up"}})],1),_c('p',{staticStyle:{"pointer-events":"none","max-width":"200px","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.text.dragAndDropOr)+" ")]),_c('b-button',{staticClass:"button-confirm",staticStyle:{"position":"relative","margin-bottom":"2em"},attrs:{"pill":""}},[_vm._v(" "+_vm._s(_vm.text.selectAFile)+" "),_c('input',{staticClass:"docs-file-input",attrs:{"type":"file","size":"60","multiple":""},on:{"change":_vm.handleSelect}})])],1):_c('div',{staticClass:"text-center text-muted",staticStyle:{"margin-top":"30%"}},[_c('p',{staticStyle:{"height":"fit-content"}},[_vm._v(_vm._s(_vm.text.selectAEventStage))])])],2),_c('b-alert',{staticStyle:{"position":"fixed","top":"2em","right":"3em","z-index":"1041"},attrs:{"show":_vm.dismissCountDown,"dismissible":"","fade":"","variant":"danger"},on:{"dismissed":function($event){_vm.dismissCountDown = 0},"dismiss-count-down":_vm.countDownChanged}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }