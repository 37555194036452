import Moment from "moment";
import VueLogger from "vuejs-logger";
import { store } from "../store";

const isProduction = process.env.NODE_ENV === "production";

function customFormatter(message: any): string {
  const preDefinedText = `${Moment.utc().format("YYYY-MM-DDTHH:mm:ss[Z]")} | ${
    store.getters["peopleCore/getUser"].getUsername
  } | `;
  return `${preDefinedText}${message}`;
}

export default {
  options: {
    isEnabled: true,
    logLevel: isProduction ? "error" : "debug",
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: "|",
    showConsoleColors: true
  },
  logger: VueLogger as any,
  formatter: customFormatter
};
