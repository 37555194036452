var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:_vm.id,attrs:{"id":_vm.id,"modal-class":"modified-modal","header-class":"modified-modal-header","body-class":"modified-modal-body","content-class":"modified-modal-content","footer-class":"modified-modal-footer","centered":""},on:{"ok":_vm.saveDocs,"hidden":_vm.resetValues},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.text.newDocument))])]},proxy:true},{key:"default",fn:function(){return [_c('b-row',{staticStyle:{"padding-bottom":"1rem"}},[_c('div',{staticStyle:{"width":"100%"}},[(!_vm.itemPreselected)?_c('button',{staticClass:"adddocument-buttongroup",on:{"click":function($event){_vm.isTimelineItemSelection = false;
            _vm.attachedTimelineItemTreeselectModal = undefined;}}},[_vm._v(" "+_vm._s(_vm.text.project)+" ")]):_vm._e(),_c('button',{staticClass:"adddocument-buttongroup",style:({
            width: _vm.itemPreselected ? '100%' : ''
          }),on:{"click":function($event){_vm.isTimelineItemSelection = true}}},[_vm._v(" "+_vm._s(_vm.text.timelineItem)+" ")]),_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"option-selected-decorator",style:({
              marginLeft:
                !_vm.isTimelineItemSelection || _vm.itemPreselected ? '0' : '50%',
              width: _vm.itemPreselected ? '100%' : ''
            })})])])]),_c('div',{staticClass:"mt-3",staticStyle:{"min-height":"180px"}},[(_vm.isTimelineItemSelection)?[_c('b-row',[_c('b-col',{staticClass:"p-0"},[_c('label',{staticClass:"input-label white",staticStyle:{"left":"0"}},[_vm._v(" "+_vm._s(_vm.text.timelineItem)+" ")]),_c('div',{staticClass:"treeselect",attrs:{"id":"treeselect"}},[_c('treeselect',{attrs:{"placeholder":_vm.text.selectAnItem,"options":_vm.stagesEventsRequests,"disable-branch-nodes":true,"maxHeight":150,"state":"invalid","disabled":_vm.eventId !== undefined ||
                  _vm.stageId !== undefined ||
                  _vm.requestId !== undefined,"required":""},on:{"select":_vm.setTreeselectedItemSubtype},scopedSlots:_vm._u([{key:"value-label",fn:function(ref){
                  var node = ref.node;
return [(
                      !['stages', 'events', 'requests'].includes(node.raw.id)
                    )?_c('label',[_c('div',{staticStyle:{"display":"flex"}},[_c('img',{staticClass:"img-treeselect",attrs:{"src":_vm.getTimelineItemIcon(node.raw.type, node.raw.color)}}),_c('span',[_vm._v(_vm._s(node.raw.label))])])]):_c('label',[_c('span',[_vm._v(_vm._s(node.raw.label))])])]}},{key:"option-label",fn:function(ref){
                    var node = ref.node;
return [(
                      !['stages', 'events', 'requests'].includes(node.raw.id)
                    )?_c('label',{staticClass:"vue-tree-select-option"},[_c('div',{staticStyle:{"display":"flex"}},[_c('img',{staticClass:"img-treeselect",attrs:{"src":_vm.getTimelineItemIcon(node.raw.type, node.raw.color)}}),_c('span',[_vm._v(_vm._s(node.raw.label))])])]):_c('label',{staticClass:"vue-tree-select-option"},[_c('span',[_vm._v(_vm._s(node.raw.label))])])]}}],null,false,3615522625),model:{value:(_vm.attachedTimelineItemTreeselectModal),callback:function ($$v) {_vm.attachedTimelineItemTreeselectModal=$$v},expression:"attachedTimelineItemTreeselectModal"}}),_c('div',{staticStyle:{"color":"red","font":"9pt Ubuntu","margin-top":"3px","height":"1.7rem","margin-bottom":"3px"}},[_vm._v(" "+_vm._s(_vm.timelineItemTypeFromSubtype === "request" && _vm.attachedTimelineItem.getStatus === _vm.requestStatesHelper.closed.name ? _vm.$t("noAttachDocsClosedRequests") : "")+" ")])],1)])],1),_c('b-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.attachedTimelineItemTreeselectModal),expression:"attachedTimelineItemTreeselectModal"}],staticClass:"readers-row-modal"},[_c('p',[_vm._v(_vm._s(_vm.text.readers)+":")]),_c('div',{staticStyle:{"display":"flex"}},[(
                !_vm.timelineItemDocReadersPeopleIcon.length &&
                !_vm.docsVisibleToAll &&
                _vm.timelineItemTypeFromSubtype != 'request'
              )?_c('p',{staticClass:"readers-text-modal"},[_vm._v(" "+_vm._s(_vm.text.noMembersForDocReaders)+" ")]):(
                _vm.docsVisibleToAll || _vm.timelineItemTypeFromSubtype === 'request'
              )?_c('p',{staticClass:"readers-text-modal"},[_vm._v(" "+_vm._s(_vm.text.all)+" ")]):_c('PeopleIcons',{attrs:{"people":_vm.timelineItemDocReadersPeopleIcon,"minimumShowedPeople":8}})],1)]),(
            _vm.timelineItemTypeFromSubtype &&
            _vm.timelineItemTypeFromSubtype != 'request'
          )?_c('b-row',[_c('div',{staticStyle:{"margin-top":"5px"}},[_c('b-form-checkbox',{attrs:{"plain":""},model:{value:(_vm.docsVisibleToAll),callback:function ($$v) {_vm.docsVisibleToAll=$$v},expression:"docsVisibleToAll"}},[_c('p',{staticStyle:{"margin":"0px","font-size":"9pt"}},[_vm._v(" "+_vm._s(_vm.$t("docsVisible"))+" ")]),_c('p',{staticClass:"info-text"},[_vm._v(" "+_vm._s(_vm.docsVisibleToAll ? _vm.$t("docsAllMembersVisible") : _vm.$t("docsSelectedItemMembersVisible"))+" ")])])],1)]):_vm._e()]:(!_vm.isTimelineItemSelection)?[_c('b-row',[_c('p',[_c('FontAwesomeIcon',{staticStyle:{"padding":"2px","height":"15","color":"#1a6791"},attrs:{"icon":"fas fa-info-circle"}}),_vm._v(" "+_vm._s(_vm.text.projectPeopleIncludedByDefault)+" ")],1)]),_c('b-row',[_c('CapsuleMultiselect',{attrs:{"input":_vm.readers,"options":_vm.getMembersOfProjects,"placeholder":_vm.text.readers,"multiple":true,"maxHeight":230,"type":"people"},on:{"setValue":function (value) { return (_vm.readers = value); }}})],1),_c('b-row',{staticStyle:{"margin-top":"1rem"}},[_c('PeopleIcons',{attrs:{"people":_vm.readers,"minimumShowedPeople":8}})],1)]:_vm._e()],2),_c('DocsAttach',{ref:"docsAttach",attrs:{"action":_vm.itemPreselected ? 'edit' : 'create',"projectId":_vm.projectId,"eventId":_vm.docsEventId,"stageId":_vm.docsStageId,"requestId":_vm.docsRequestId,"peopleDxIds":_vm.docsReaders,"saveDocsAttached":_vm.saveDocsAttached},on:{"docsUploaded":_vm.docsUploadedHandler,"docsReady":function (value) { return (_vm.docsReady = value); }}})]},proxy:true},{key:"modal-footer",fn:function(ref){
          var cancel = ref.cancel;
          var ok = ref.ok;
return [_c('b-button',{staticClass:"button-small button-confirm button-disabled",attrs:{"pill":"","disabled":_vm.loading},on:{"click":cancel}},[_vm._v(" "+_vm._s(_vm.text.cancel)+" ")]),_c('b-button',{staticClass:"button-small button-disabled",staticStyle:{"width":"104px"},attrs:{"pill":"","disabled":!_vm.saveButtonIsActive},on:{"click":ok}},[(!_vm.loading)?_c('p',[_vm._v(_vm._s(_vm.text.upload))]):_c('b-spinner',{staticClass:"button-spinner"})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }