import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import CustomerSummaryCSP from "../../../models/csp/CustomerSummaryCSP";
import customerSummaryService from "../../../services/csp/customerSummaryService";
import { RootState } from "../../index";

const namespaced = true;

interface CustomerSummaryState {
  customersSummary: CustomerSummaryCSP[];
}

const state: CustomerSummaryState = {
  customersSummary: []
};

const getters: GetterTree<CustomerSummaryState, RootState> = {
  getCustomersSummaryCSP: (state): CustomerSummaryCSP[] => {
    return state.customersSummary;
  },
  getCustomerSummaryCSPById:
    state =>
    (id: number): CustomerSummaryCSP => {
      const customerFind = state.customersSummary.find(
        summary => summary.getId == id
      );
      return customerFind;
      // return state.customersSummary.find(summary => {
      //   return summary.getId == id;
      // });
    }
};

const mutations: MutationTree<CustomerSummaryState> = {
  setCustomersSummary(state, customersSummary: CustomerSummaryCSP[]) {
    state.customersSummary = customersSummary;
  },
  addCustomerSummary(state, newSummary: CustomerSummaryCSP) {
    if (
      state.customersSummary.findIndex(
        summary => summary.getId == newSummary.getId
      ) == -1
    ) {
      state.customersSummary.push(newSummary);
    }
  }
};

const actions: ActionTree<CustomerSummaryState, RootState> = {
  async loadCustomersSummaryCSP({ commit }) {
    const customersSummary: CustomerSummaryCSP[] =
      await customerSummaryService.methods.getCustomersSummaryCSP();
    commit("setCustomersSummary", customersSummary);
  },
  async loadCustomerSummaryCSPById({ commit }, id: number) {
    const summary: CustomerSummaryCSP =
      await customerSummaryService.methods.getCustomerSummaryCSPById(id);
    commit("addCustomerSummary", summary);
  }
};

export const customerSummaryModuleCSP: Module<CustomerSummaryState, RootState> =
  {
    namespaced,
    state,
    getters,
    actions,
    mutations
  };
