import CapacityTypeCore from "../../models/core/CapacityTypeCore";
import service from "../service";
import baseEndpoint from "./serviceCore";

const endpoint = baseEndpoint + "/CapacityType";

export default {
  components: { service: service },
  methods: {
    async getCapacityTypes(): Promise<CapacityTypeCore[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(capacityType => new CapacityTypeCore(capacityType));
    },
    async getCapacityType(id: number): Promise<CapacityTypeCore> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new CapacityTypeCore(response);
    },
    async postCapacityType(
      capacityType: CapacityTypeCore
    ): Promise<CapacityTypeCore> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        capacityType
      );
      return new CapacityTypeCore(response);
    }
  }
};
