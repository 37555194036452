import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import ServiceCore from "../../../models/core/ServiceCore";
import ClientServiceCore from "../../../models/core/client/ClientServiceCore";
import ProjectServiceCore from "../../../models/core/project/ProjectServiceCore";
import serviceService from "../../../services/core/serviceService";
import { RootState } from "../../index";

const namespaced = true;

interface ServiceState {
  services: ServiceCore[];
  hiredServices: ClientServiceCore[];
  activeServices: ProjectServiceCore[];
}

const state: ServiceState = {
  services: [],
  hiredServices: [],
  activeServices: []
};

const mutations: MutationTree<ServiceState> = {
  setServices(state, services: ServiceCore[]) {
    state.services = services;
  },
  setHiredServices(state, hiredServices: ClientServiceCore[]) {
    state.hiredServices = hiredServices;
  },
  setActiveServices(state, activeServices: ProjectServiceCore[]) {
    state.activeServices = activeServices;
  }
};

const getters: GetterTree<ServiceState, RootState> = {
  getServices: (state): ServiceCore[] => {
    return state.services;
  },
  getServiceById:
    state =>
    (id: number): ServiceCore => {
      return state.services.find(service => service.getId == id);
    },
  getServiceByName:
    state =>
    (name: string): ServiceCore => {
      return state.services.find(service => service.getName == name);
    },
  getServicesByCategoryId:
    state =>
    (categoryId: number): ServiceCore[] => {
      return state.services.filter(
        service => service.getCategoryId == categoryId
      );
    },
  getActiveServicesByProjectId:
    state =>
    (projectId: number): ServiceCore[] => {
      const activeServicesIds: number[] = state.activeServices
        .filter(actService => actService.getProjectId == projectId)
        .map(actService => actService.getServiceId);
      return state.services.filter(service =>
        activeServicesIds.includes(service.getId)
      );
    },
  getPreactivatedServicesByProjectId:
    state =>
    (projectId: number): number[] => {
      const preactivatedServicesIds: number[] = state.activeServices
        .filter(
          preactivatedService =>
            preactivatedService.getProjectId == projectId &&
            preactivatedService.getPreactivated
        )
        .map(preactivatedService => preactivatedService.getServiceId);
      return preactivatedServicesIds;
    },
  getActiveServices: (state): ServiceCore[] => {
    let activeServices: ServiceCore[] = [];
    const activeServicesIds: number[] = [];
    for (const hiredService of state.hiredServices) {
      for (const activeService of state.activeServices) {
        if (hiredService.getServiceId == activeService.getServiceId) {
          activeServicesIds.push(hiredService.getServiceId);
          break;
        }
      }
    }
    activeServices = state.services.filter(service =>
      activeServicesIds.includes(service.getId)
    );
    return activeServices;
  },
  getHiredServicesByClientId:
    state =>
    (clientId: number): ServiceCore[] => {
      const hiredServicesIds: number[] = state.hiredServices
        .filter(hService => hService.getClientId == clientId)
        .map(hService => hService.getServiceId);
      return state.services.filter(service =>
        hiredServicesIds.includes(service.getId)
      );
    },
  getServicesByProjectId:
    state =>
    (projectId: number): ServiceCore[] => {
      const activeServicesIds: number[] = state.activeServices
        .filter(actService => actService.getProjectId == projectId)
        .map(actService => actService.getServiceId);
      return state.services.filter(service =>
        activeServicesIds.includes(service.getId)
      );
    }
};

const actions: ActionTree<ServiceState, RootState> = {
  async loadServices({ commit }) {
    const services: ServiceCore[] = await serviceService.methods.getServices();
    commit("setServices", services);
  },
  async loadHiredServicesByClientId(
    { commit },
    clientId: number
  ): Promise<any> {
    const hiredServices: ClientServiceCore[] =
      await serviceService.methods.getHiredServicesByClient(clientId);
    commit("setHiredServices", hiredServices);
    return hiredServices;
  },
  async loadActiveServicesByClientId({ commit }, clientId: number) {
    const activeServices: ProjectServiceCore[] =
      await serviceService.methods.getActiveServicesByClient(clientId);
    commit("setActiveServices", activeServices);
  }
};

export const serviceModuleCore: Module<ServiceState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
