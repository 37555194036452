



















  export default {
    name: "HiredSwitch",
    data() {
      return {
        hired: true,
        text: {
          hired: this.$tc("hired", 2),
          all: this.$tc("all", 1)
        }
      };
    },
    created: function () {
      this.$emit("switch", this.hired);
    }
  };
