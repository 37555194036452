























































































































































































































































































































































  import emailBuilder from "../../_helpers/email/emailBuilder";
  import projectIncorporationEmailBody from "../../_helpers/email/projectIncorporationEmailBody";
  import { eventStates, eventTypes } from "../../_helpers/eventTypesStates";
  import { notificationsPolicies } from "../../_helpers/notifications";
  import {
    getModelProjectType,
    getProjectThemeTranslation,
    getProjectTypeTranslation,
    projectThemes,
    projectTypes
  } from "../../_helpers/projectMetadata";
  import { servicesExtensions } from "../../_helpers/servicesExtensions";
  import CapacityType from "../../models/core/CapacityTypeCore";
  import ClientCore from "../../models/core/ClientCore";
  import MailCore from "../../models/core/MailCore";
  import PeopleCore from "../../models/core/PeopleCore";
  import ProjectCSP from "../../models/core/ProjectCSPCore";
  import ProjectCapacity from "../../models/core/ProjectCapacityCore";
  import ProjectPeopleCore from "../../models/core/project/ProjectPeopleCore";
  import ProjectServiceCore from "../../models/core/project/ProjectServiceCore";
  import CustomerSummaryCSP from "../../models/csp/CustomerSummaryCSP";
  import EventDx from "../../models/deliveryexperience/EventDx";
  import PeopleDx from "../../models/deliveryexperience/PeopleDx";
  import Permissions from "../../models/deliveryexperience/Permissions/Permissions";
  import ProjectDx from "../../models/deliveryexperience/ProjectDx";
  import TagDx from "../../models/deliveryexperience/TagDx";
  import mailService from "../../services/core/mailService";
  import PeopleIcon from "../../view-models/PeopleIcon";
  import SingleSelectType from "../../view-models/SingleSelectType";
  import CapsuleInputDate from "../view_elements/CapsuleInputDate.vue";
  import CapsuleLabelInput from "../view_elements/CapsuleLabelInput.vue";
  import CapsuleMultiselect from "../view_elements/CapsuleMultiselect.vue";
  import ImageCroppa from "../view_elements/ImageCroppa.vue";

  export default {
    name: "ProjectFormBasicInformation",
    components: {
      CapsuleInputDate,
      CapsuleLabelInput,
      CapsuleMultiselect,
      ImageCroppa
    },
    props: {
      id: Number,
      creating: Boolean,
      _selectedProjectType: SingleSelectType
    },
    data() {
      return {
        loading: false,
        projectId: "",
        croppaKey: 1, //update key to force ImageCroppa
        language: "",
        lastProjectVersionDx: undefined,
        projectName: "",
        projectDescription: "",
        today: null,
        startDate: null,
        endDate: null,
        selectedProjectType: this._selectedProjectType,
        projectTypesOptions: [],
        projectMembers: [],
        member: "",
        selectedUnit: null,
        amountUnits: null,
        client: null,
        customer: new SingleSelectType({
          key: undefined,
          name: undefined
        }),
        selectedClientId: null,
        capacityUnitInvalid: false,
        projectNameMaxLength: 50,
        projectDescriptionMaxLength: 255,
        existProjectName: false,
        theme: null,
        selectTheme: this.$t("selectTheme"),
        icon: null,
        header: null,
        loadedData: false,
        showImg: null,
        liabilityEconomicString: projectTypes.liabilityEconomic.name,
        liabilityFullString: projectTypes.liabilityFull.name,
        capacityString: projectTypes.capacity.name,
        cspString: projectTypes.CSP.name,
        typeProjectNotAvailable: false
      };
    },
    mounted: async function () {
      this.language = this.$i18n.locale;
      this.today = new Date();
      if (this.creating) this.selectedClientId = this.getUser.getClientId;
      else this.selectedClientId = this.getProjectById(this.id).getClientId;
    },
    async created() {
      if (!this.creating) {
        this.lastProjectVersionDx = await this.fetchLastProjectVersionDx(
          this.id
        );
        this.setData();
      } else {
        this.theme = new SingleSelectType({
          key: projectThemes.light.value,
          name: this.$t(projectThemes.light.i18n)
        });
      }
      // FOR takes every key (as string) of projectTypes json object
      for (const projectType in projectTypes) {
        if (projectTypes[projectType].name != projectTypes.liability.name) {
          this.projectTypesOptions.push(
            new SingleSelectType({
              key: projectTypes[projectType].name,
              name: this.$tc(projectTypes[projectType].i18n, 1)
            })
          );
        }
      }
      await this.loadCustomersSummaryCSP();
      this.loadedData = true;
    },
    computed: {
      peopleDxByProjectId(): PeopleDx[] {
        // Kept here to be used by its watcher, which would not trigger if the Vuex getter name is used
        return this.getPeopleDxByProjectId(this.id);
      },
      disabledSaveAndContinueButton(): boolean {
        if (
          this.projectName != "" &&
          !this.existProjectName &&
          this.selectedProjectType.key != null &&
          !this.typeProjectNotAvailable &&
          this.startDate &&
          this.client != null
        ) {
          const endDateRequired =
            (this.selectedProjectType.key ==
              projectTypes.liabilityTemporary.name ||
              this.selectedProjectType.key ==
                projectTypes.liabilityFull.name) &&
            !this.endDate;
          if (!this.creating && this.projectMembers.length == 0) {
            return true;
          }
          if (
            this.selectedProjectType.key == projectTypes.capacity.name &&
            (this.selectedUnit == null ||
              (this.getUser.isSogeti() && this.client == null))
          ) {
            return true;
          } else if (
            this.selectedProjectType.key == projectTypes.CSP.name &&
            !this.customer
          ) {
            return true;
          } else if (endDateRequired) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
      requiredEndDateField(): boolean {
        return (
          (this.selectedProjectType.key !=
            projectTypes.liabilityTemporary.name &&
            this.selectedProjectType.key != projectTypes.liabilityFull.name) ||
          this.selectedProjectType.key == null
        );
      },
      clientList(): ClientCore[] {
        let clients = [];
        for (const client of this.getClients) {
          clients.push(client.getName);
        }
        return clients;
      },
      themesList(): SingleSelectType[] {
        let themes = [];
        for (const theme of Object.values(projectThemes)) {
          themes.push(
            new SingleSelectType({
              key: theme.value,
              name: this.$t(theme.i18n)
            })
          );
        }
        return themes;
      },
      capacityTypesCoreFiltered(): string[] {
        let types = [];
        for (const capacityType of this.getCapacityTypes) {
          if (capacityType.getClientId == this.selectedClientId) {
            types.push(capacityType.getName);
          }
        }
        return types;
      },
      customersList(): SingleSelectType[] {
        const customers: any[] = this.getCustomersSummaryCSP;
        return Object.values(customers).map(
          summary =>
            new SingleSelectType({
              key: summary.getId,
              name: summary.customerName
            })
        );
      },
      projectTypesTooltips() {
        const tooltips = {};
        for (const projectType of Object.values(projectTypes)) {
          tooltips[projectType.name] = this.$t(projectType.i18nDesc);
        }
        return tooltips;
      }
    },
    methods: {
      mapPeopleCoreToPeopleIcon(
        peopleCoreAvailable: PeopleCore[]
      ): PeopleIcon[] {
        const peopleAvailable = peopleCoreAvailable.map(personCore =>
          PeopleIcon.newPeopleIconFromCore(personCore)
        );
        if (this.creating) {
          return peopleAvailable.filter(
            person => this.getUser.getUsername != person.getUsername
          );
        } else {
          return peopleAvailable;
        }
      },
      availablePeople(): PeopleIcon[] {
        if (this.getUser.isSogeti())
          return this.mapPeopleCoreToPeopleIcon(this.getPeople);
        else
          return this.mapPeopleCoreToPeopleIcon(
            this.getAvailablePeopleByClientIdAndProject(this.getUser.clientId)
          );
      },
      mapPeopleDxToPeopleIcon(peopleDx: PeopleDx[]): PeopleIcon[] {
        return peopleDx.map(personDx => {
          return PeopleIcon.newPeopleIconFromDx(personDx);
        });
      },
      async checkProjectName() {
        let res;
        try {
          const trimProjectName = this.projectName.trim();
          if (!this.creating) {
            if (
              trimProjectName != "" &&
              this.getProjectById(this.id) != undefined &&
              trimProjectName.toLowerCase() !==
                this.getProjectById(this.id).getName.toLowerCase()
            ) {
              res = await this.validateProjectName(
                trimProjectName.toLowerCase()
              );
            } else {
              this.existProjectName = false;
            }
          } else {
            if (trimProjectName != "") {
              res = await this.validateProjectName(
                trimProjectName.toLowerCase()
              );
            } else {
              this.existProjectName = false;
            }
          }
          if (res && res.status === 409) {
            this.existProjectName = true;
          } else {
            this.existProjectName = false;
          }
        } catch (e) {
          this.$log.error(e);
        }
      },
      uploadImageCropped(img) {
        this.header = img;
      },
      saveProject: async function () {
        this.loading = true;
        await this.$refs.croppaRef.changeCroppedImg();

        //Check if new client has been created
        if (this.selectedClientId == -1) {
          await this.postClient(
            new ClientCore({
              name: this.client
            })
          );
          this.selectedClientId = this.getClients.find(
            c => c.name === this.client
          ).getId;
        }

        // Check if projectName not exists
        try {
          let savedProjectCore = null;
          this.projectName = this.projectName.trim();
          let projectData = {
            name: this.projectName,
            description: this.projectDescription,
            startDate: new Date(this.startDate),
            endDate: this.endDate ? new Date(this.endDate) : null,
            closingDate: null,
            projectType: this.selectedProjectType.key,
            clientId: this.selectedClientId,
            icon: this.icon,
            header: this.header,
            theme: this.theme.key
          };
          if (!this.creating) {
            projectData["id"] = this.id;
          }
          let project = getModelProjectType(projectData);
          switch (project.getProjectType) {
            case projectTypes.liabilityEconomic.name:
            case projectTypes.liabilityTemporary.name:
            case projectTypes.liabilityFull.name:
            case projectTypes.timeAndMaterials.name:
            case projectTypes.QBR.name:
              break;
            case projectTypes.CSP.name:
              (project as ProjectCSP).setCustomerId = this.customer.key;
              break;
            case projectTypes.capacity.name:
              let capacityType: CapacityType = this.getCapacityTypes.find(
                capacity =>
                  capacity.clientId == projectData.clientId &&
                  capacity.name === this.selectedUnit
              );
              if (!capacityType) {
                capacityType = new CapacityType({
                  name: this.selectedUnit,
                  clientId: projectData.clientId
                });
                await this.postCapacityType(capacityType);
                capacityType = this.getCapacityTypes.find(
                  capacity => capacity.name === this.selectedUnit
                );
              }
              (project as ProjectCapacity).setCapacityTypeId =
                capacityType.getId;
              break;
          }
          try {
            if (this.creating)
              savedProjectCore = await this.postProject(project);
            else savedProjectCore = await this.putProject(project);
          } catch (error) {
            this.$log(error);
          }

          let dxId;
          for (const service of this.getServices) {
            if (service.getName == servicesExtensions.deliveryExperience.name) {
              dxId = service.getId;
              break;
            }
          }
          try {
            const projectInDeliveryExperience = this.creating
              ? new ProjectDx({
                  id: savedProjectCore.id,
                  links: []
                })
              : this.getProjectDx(savedProjectCore.getId);
            // create new projectDx or edit projectDx
            const savedProjectDx = this.creating
              ? await this.postProjectDx(projectInDeliveryExperience)
              : await this.putProjectDx(projectInDeliveryExperience);
            // create new empty Tag in creation of project
            if (this.creating) {
              if (this.amountUnits) {
                await this.postEventDx(
                  new EventDx({
                    name: this.$t("initialCapacity"),
                    eventType: eventTypes.CAPACITY.events.reload.type,
                    description: "",
                    createdDate: savedProjectCore.getStartDate,
                    amount: this.amountUnits,
                    projectId: savedProjectCore.getId,
                    occurred: true,
                    confirmed: true,
                    reported: false,
                    baseline: {
                      version: 1,
                      status: eventStates.occurred.name,
                      startedDate: savedProjectCore.getStartDate,
                      endDate: null,
                      affecteds: [],
                      predecessors: []
                    },
                    author: this.getUser.getUsername,
                    tag: null,
                    related: null,
                    notificationsPolicy: notificationsPolicies.none.name
                  })
                );
              }
              await this.postTagsDx(
                new TagDx({
                  projectId: savedProjectCore.id,
                  tags: []
                })
              );
            }
            // add and delete people to/from projectDx
            try {
              const peopleToAdd = this.projectMembers.filter(personSelected => {
                return !this.peopleDxByProjectId.find(personInProject => {
                  return (
                    personInProject.getUsername == personSelected.getUsername
                  );
                });
              });
              const peopleToDelete = this.peopleDxByProjectId.filter(
                personSelected => {
                  return !this.projectMembers.find(personInProject => {
                    return (
                      personInProject.getUsername == personSelected.getUsername
                    );
                  });
                }
              );

              await this.addPeopleToProject(
                peopleToAdd,
                projectInDeliveryExperience.getId,
                savedProjectCore.getName,
                savedProjectCore.getProjectType
              );
              if (!this.creating) {
                await this.deletePeopleInProject(
                  peopleToDelete,
                  projectInDeliveryExperience.getId
                );
              }
              this.$emit(
                "updatedSelectedProjectType",
                this.selectedProjectType
              );
              if (savedProjectDx && this.creating) {
                const projectServiceDX = new ProjectServiceCore({
                  projectId: savedProjectCore.id,
                  serviceId: dxId,
                  preactivated: true
                });
                await this.postProjectServices(projectServiceDX)
                  .then(() => {
                    this.$emit(
                      "goToServicesSection",
                      savedProjectCore.id,
                      this.selectedClientId
                    );
                  })
                  .catch(error => {
                    this.$log.error(error);
                  });
              } else {
                this.$emit(
                  "goToServicesSection",
                  this.id,
                  this.selectedClientId
                );
                if (peopleToAdd.length) this.$emit("loadPermissionsManagement");
              }
            } catch (error) {
              this.$log.error(error);
              this.$log.error("Error modifying people on DX project");
              // Delete Dx project?
            }
          } catch (error) {
            this.$log.error(error);
            this.$log.error("Error creating DX project");
            // Delete core project
            if (this.creating) {
              await this.deleteProject(savedProjectCore.id);
            }
          }
        } catch (error) {
          this.$log.error(error);
        }
        this.loading = false;
      },
      async addPeopleToProject(
        peopleToAdd,
        projectId,
        projectName,
        projectType
      ) {
        // Creating this variables to prevent shallow clonning
        if (this.creating) {
          const user = PeopleIcon.newPeopleIconFromCore(this.getUser);
          user.setClientId = this.selectedClientId;
          this.projectMembers.push(user);
          // Add project members
          for (const person of this.projectMembers) {
            await this.postPeopleDx(
              new PeopleDx({
                userName: person.getUsername,
                jobPosition: person.getJobPosition,
                projectId: projectId,
                pointOfContact: person.getUsername == this.getUser.getUsername,
                events: [],
                stages: [],
                permissions:
                  person.getUsername == this.getUser.getUsername ||
                  this.getPeopleByUsername(person.getUsername).isAdminOrMgr()
                    ? new Permissions(undefined, true)
                    : new Permissions()
              })
            );
            await this.postProjectPeople(
              new ProjectPeopleCore({
                projectId: projectId,
                username: person.getUsername
              })
            );
            this.postMail(
              this.getPeopleByUsername(person.getUsername).getLanguage,
              person.getUsername,
              projectName,
              projectType,
              projectId
            );
          }
        } else {
          for (const person of peopleToAdd) {
            try {
              await this.postPeopleDx(
                new PeopleDx({
                  userName: person.getUsername,
                  jobPosition: person.getJobPosition,
                  projectId: projectId,
                  pointOfContact: false,
                  events: [],
                  stages: [],
                  permissions: this.getPeopleByUsername(
                    person.getUsername
                  ).isAdminOrMgr()
                    ? new Permissions(undefined, true)
                    : new Permissions()
                })
              );
              await this.postProjectPeople(
                new ProjectPeopleCore({
                  projectId: projectId,
                  username: person.getUsername
                })
              );
            } catch (error) {
              this.$log.error(error);
            }
            this.postMail(
              this.getPeopleByUsername(person.getUsername).getLanguage,
              person.getUsername,
              projectName,
              projectType,
              projectId
            );
          }
        }
      },
      async deletePeopleInProject(peopleToDelete, projectId) {
        // creating this variables to prevent shallow clonning
        for (const person of peopleToDelete) {
          await this.deletePeopleDx(person.getId);
          await this.deleteProjectPeople(
            new ProjectPeopleCore({
              projectId: projectId,
              username: person.getUsername
            })
          );
        }
      },
      addUnitType(value) {
        // First character in upper case
        if (value[0] == value[0].toUpperCase()) {
          const aux = value.slice(1);
          // Rest in lower case
          if (aux == aux.toLowerCase()) {
            this.selectedUnit = value;
            this.capacityTypesCoreFiltered.push(value);
          } else {
            this.capacityUnitInvalid = true;
          }
        } else {
          this.capacityUnitInvalid = true;
        }
      },
      addNewClient(value) {
        this.selectedClientId = -1;
        this.client = value;
        this.clientList.push(value);
        this.selectedUnit = null; // We reset Capacity Units selected, if any, everytime we change the client
      },
      clientItemSelected() {
        if (this.client != null) {
          const foundClient = this.getClients.find(c => c.name === this.client);
          if (foundClient) {
            this.selectedClientId = foundClient.getId;
          } else {
            this.selectedClientId = -1;
          }
        }
        this.selectedUnit = null; // We reset Capacity Units selected, if any, everytime we change the client
      },
      cancel() {
        this.$emit("cancelBasicInformation");
      },
      async setData() {
        const project = this.getProjectById(this.id);
        if (project) {
          this.projectName = project.getName;
          this.selectedProjectType = new SingleSelectType({
            key: project.getProjectType,
            name: this.$tc(getProjectTypeTranslation(project.getProjectType), 1)
          });
          this.projectDescription = project.getDescription;
          this.client = this.getClientById(project.getClientId).getName;
          this.icon = project.getIcon;
          this.header = project.getHeader;
          this.theme = new SingleSelectType({
            key: project.getTheme,
            name: this.$t(getProjectThemeTranslation(project.getTheme))
          });
          this.startDate = new Date(project.getStartDate);
          if (project.getEndDate != null)
            this.endDate = new Date(project.getEndDate);
          if (!this.creating && project) {
            this.projectMembers = this.mapPeopleDxToPeopleIcon(
              this.peopleDxByProjectId
            );
          }
          switch (project.getProjectType) {
            case projectTypes.capacity.name:
              this.selectedUnit = this.getCapacityTypeById(
                project.getCapacityTypeId
              ).getName;
              break;
            case projectTypes.CSP.name:
              const customerSummary: CustomerSummaryCSP =
                this.getCustomerSummaryCSPById(project.getCustomerId);
              this.customer = new SingleSelectType({
                key: customerSummary.getId,
                name: customerSummary.getCustomerName
              });
              break;
          }
        }
      },
      onFileSelected(event) {
        const files = event.target.files || event.dataTransfer.files;
        if (files.length && files[0].type.startsWith("image/")) {
          const reader = new FileReader();
          if (event.target.id === "input_icon-form") {
            reader.onload = event => {
              this.icon = event.target.result;
            };
            (<HTMLInputElement>(
              document.getElementById("input_icon-form")
            )).style.color = "black";
          }
          if (event.target.id === "input_header-form") {
            reader.onload = event => {
              this.header = event.target.result;
            };
            (<HTMLInputElement>(
              document.getElementById("input_header-form")
            )).style.color = "black";
          }
          reader.readAsDataURL(files[0]);
        } else {
          if (event.target.id === "input_icon-form") {
            this.icon = null;
          }
          if (event.target.id === "input_header-form") {
            this.header = null;
          }
        }
      },
      determineType: function (type): string {
        switch (type) {
          case projectTypes.liabilityTemporary.name:
            return this.$tc(projectTypes.liabilityTemporary.i18n, 1);
          case projectTypes.liabilityEconomic.name:
            return this.$tc(projectTypes.liabilityEconomic.i18n, 1);
          case projectTypes.liabilityFull.name:
            return this.$tc(projectTypes.liabilityFull.i18n, 1);
          case projectTypes.timeAndMaterials.name:
            return this.$t(projectTypes.timeAndMaterials.i18n);
          case projectTypes.capacity.name:
            return this.$t(projectTypes.capacity.i18n);
          case projectTypes.QBR.name:
            return this.$tc(projectTypes.QBR.i18n, 1);
          case projectTypes.CSP.name:
            return this.$tc(projectTypes.CSP.i18n, 1);
          default:
            return this.$t("projectType");
        }
      },
      deleteImage(event) {
        if (event.target.id === "icon-delete") {
          const icon = <HTMLInputElement>(
            document.getElementById("input_icon-form")
          );
          this.icon = null;
          icon.value = null;
          icon.style.color = "grey";
        }
        if (event.target.id === "header-delete") {
          const header = <HTMLInputElement>(
            document.getElementById("input_header-form")
          );
          this.header = null;
          header.value = null;
          header.style.color = "grey";
        }
      },
      postMail(
        lang: string,
        username: string,
        projectName: string,
        projectType: string,
        projectId: string
      ) {
        mailService.methods.postMailCore(
          new MailCore({
            to: username,
            subject: `[SogetiHub] ${this.$t(
              "projectIncorporationEmailTitle",
              lang
            )}`,
            body: emailBuilder.buildEmail(
              this.$t("projectIncorporationEmailTitle", lang),
              "",
              projectIncorporationEmailBody.build(
                projectName,
                projectType,
                projectId,
                lang
              ),
              lang
            )
          })
        );
      }
    },
    watch: {
      selectedClientId: async function () {
        if (this.selectedClientId) {
          await this.loadHiredServicesByClientId(this.selectedClientId);
        }
        if (
          this.selectedProjectType &&
          this.selectedProjectType.name == projectTypes.CSP.name
        ) {
          this.typeProjectNotAvailable = !this.getHiredServicesByClientId(
            this.selectedClientId
          ).some(s => s.getName == servicesExtensions.cspPanel.name);
        } else {
          this.typeProjectNotAvailable = false;
        }
      },
      selectedProjectType() {
        if (
          this.creating &&
          this.selectedProjectType.name !=
            projectTypes.liabilityTemporary.name &&
          this.selectedProjectType.name != projectTypes.liabilityFull.name
        ) {
          this.endDate = null;
        }
        if (
          this.selectedProjectType.name == projectTypes.CSP.name &&
          this.selectedClientId
        ) {
          this.typeProjectNotAvailable = !this.getHiredServicesByClientId(
            this.selectedClientId
          ).some(s => s.getName == servicesExtensions.cspPanel.name);
        } else this.typeProjectNotAvailable = false;
      },
      capacityUnitInvalid: function () {
        if (this.capacityUnitInvalid) {
          let dis = this;
          setTimeout(function () {
            dis.capacityUnitInvalid = false;
          }, 4000);
        }
      },
      peopleDxByProjectId() {
        if (!this.creating && this.getProjectById(this.id)) {
          this.projectMembers = this.mapPeopleDxToPeopleIcon(
            this.peopleDxByProjectId
          );
        }
      }
    }
  };
