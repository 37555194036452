

















































































































































































































































































































































  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import docTypes from "../../../../_helpers/docTypes";
  import methodology from "../../../../_helpers/methodology";
  import DocumentDx from "../../../../models/deliveryexperience/DocumentDx";
  import MethodologyDx from "../../../../models/deliveryexperience/MethodologyDx";
  import utils from "../../../../utils/utils";
  import DocToUpload from "../../../../view-models/deliveryexperience/DocToUpload";
  import CapsuleLabelInput from "../../../view_elements/CapsuleLabelInput.vue";
  import DocsAttach from "../../../view_elements/DocsAttach.vue";

  export default {
    name: "methodology-modal",
    props: {
      id: String,
      projectId: Number,
      methodologyDocs: Array
    },
    components: {
      Treeselect,
      CapsuleLabelInput,
      DocsAttach
    },
    data() {
      return {
        uploadFields: [
          {
            label: this.$tc("phase", 1),
            key: "icon",
            thClass: "table-width-15",
            tdClass: "table-width-15"
          },
          {
            label: this.$t("name"),
            key: "filename",
            thClass: "table-width-45",
            tdClass: "table-width-45"
          },
          {
            label: this.$tc("artifact", 1),
            key: "artifactName",
            thClass: "table-width-40",
            tdClass: "table-width-40"
          }
        ],
        artifactToEditName: null,
        uploadedLink: "",
        invalidUrl: false,
        scopeTreeSelected: undefined,
        methodologyHelper: methodology,
        saveButtonDisabled: true,
        saveDocsAttached: false,
        showMethodologyDiagram: false,
        text: {
          scope: this.$tc("scope", 1),
          artifact: this.$tc("artifact", 1),
          selectScope: this.$t("selectScope"),
          addLink: this.$t("addLink"),
          methodology: this.$t("methodology"),
          close: this.$t("close"),
          cancel: this.$t("cancel"),
          save: this.$t("save")
        }
      };
    },
    mounted() {
      this.$root.$on("openModalWithMethodologyDiagram", () => {
        this.showMethodologyDiagram = !this.showMethodologyDiagram;
        this.$bvModal.show(this.id);
      });
    },
    computed: {
      scopes(): {
        id: number;
        label: string;
        scope: boolean;
        children: any;
      }[] {
        let result = [];
        let docs;
        for (const phase of this.methodologyHelper.phasesAndScopes) {
          docs = [];
          for (const scope of phase.scopes) {
            docs.push({
              ...scope,
              label: scope.i18n
            });
          }
          result.push({
            id: phase.id,
            label: phase.i18n,
            scope: true,
            children: docs
          });
        }
        return result;
      },
      methodologyScopeDocs(): DocumentDx[] {
        return this.methodologyDocs.filter(md => {
          return md.methodology.scope == this.scopeTreeSelected;
        });
      },
      methodologySelected(): MethodologyDx {
        return new MethodologyDx({
          phase: this.methodologyHelper.getPhaseByScope(this.scopeTreeSelected)
            .phase.id,
          scope: this.scopeTreeSelected,
          artifactName: ""
        });
      },
      missingDocs(): any[] {
        let missingDocs = [];
        for (const phase of this.methodologyHelper.phasesAndScopes) {
          for (const scope of phase.scopes) {
            if (
              scope.important &&
              !this.methodologyDocs.find(e => e.methodology.scope == scope.id)
            )
              missingDocs.push(scope);
          }
        }

        return missingDocs;
      },
      missingDocsTxt(): string {
        let first = true;
        let missingDocsTxt = this.$t("missingImportantArtifacts");
        for (const doc of this.missingDocs) {
          if (!first) missingDocsTxt = missingDocsTxt + ", ";
          else first = false;
          missingDocsTxt = missingDocsTxt + this.$t(doc.i18n);
        }
        missingDocsTxt = missingDocsTxt + ".";
        return missingDocsTxt;
      }
    },
    methods: {
      async addLink(): Promise<void> {
        const docDx = new DocumentDx({
          name: this.uploadedLink,
          url: this.uploadedLink,
          author: this.getUser.getUsername,
          projectId: this.projectId,
          people: [],
          isLink: true,
          methodology: this.methodologySelected
        });

        const existingLink = this.methodologyScopeDocs.find(
          d => d.url == docDx.getUrl
        );
        if (existingLink) {
          docDx.setId = existingLink.getId;
        }

        try {
          await this.postDocumentDxAndBlob({
            docDx,
            existingDoc: existingLink,
            overwrite: true // We overwrite same links by default, if already exist
          } as DocToUpload);
        } catch (error) {
          this.$log.error(error);
          return;
        }
      },
      saveDocs() {
        this.saveDocsAttached = true;
      },
      getFileIconByExtension: function (name: string): NodeRequire {
        return docTypes.getFileIconByExtension(name);
      },
      getLinkIcon(): NodeRequire {
        return docTypes.getDocIcon("link.png");
      },
      checkUrl() {
        this.invalidUrl =
          this.uploadedLink.length != 0 && !utils.isValidUrl(this.uploadedLink);
      },
      filterArtifactsByPhase(phaseId): DocumentDx[] {
        return this.methodologyDocs.filter(d => d.methodology.phase == phaseId);
      },
      isAlreadyUploaded(scopeId): DocumentDx | undefined {
        return this.methodologyDocs.find(d => d.methodology.scope == scopeId);
      },
      getPhaseByScope(scopeTreeSelected): string {
        const phaseAndScope =
          this.methodologyHelper.getPhaseByScope(scopeTreeSelected);
        return (
          this.$t(phaseAndScope.phase.i18n) +
          " - " +
          this.$t(phaseAndScope.scope.i18n)
        );
      },
      resetValues(): void {
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    }
  };
