export const requestStates = {
  open: {
    name: "Open",
    i18n: "requestStatusOpen"
  },
  resolved: {
    name: "Resolved",
    i18n: "requestStatusResolved"
  },
  closed: {
    name: "Closed",
    i18n: "requestStatusClosed"
  }
};
