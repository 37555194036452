import { ActionTree, GetterTree, Module, MutationTree } from "vuex";

import ExtensionServiceCore from "../../../models/core/extension/ExtensionServiceCore";
import ExtensionCore from "../../../models/core/ExtensionCore";
import extensionService from "../../../services/core/extensionService";
import { RootState } from "../../index";

const namespaced = true;

interface ExtensionState {
  extensions: ExtensionCore[];
  compatibleExtensions: ExtensionServiceCore[];
  extensionServices: ExtensionServiceCore[];
}
const state: ExtensionState = {
  extensions: [],
  compatibleExtensions: [],
  extensionServices: []
};

const mutations: MutationTree<ExtensionState> = {
  setExtensions(state, extensions: ExtensionCore[]) {
    state.extensions = extensions;
  },
  setCompatibleExtensions(state, compatibleExtensions: ExtensionServiceCore[]) {
    state.compatibleExtensions = compatibleExtensions;
  },
  setExtensionService(state, extensionServices: ExtensionServiceCore[]) {
    state.extensionServices = extensionServices;
  }
};

const getters: GetterTree<ExtensionState, RootState> = {
  getExtensions: (state): ExtensionCore[] => {
    return state.extensions;
  },
  getExtensionByName:
    state =>
    (name: string): ExtensionCore => {
      return state.extensions.find(extension => extension.getName == name);
    },
  getExtensionServiceByServiceId:
    state =>
    (serviceId: number): ExtensionCore[] => {
      const extensionServices: ExtensionServiceCore[] =
        state.extensionServices.filter(
          extServ => extServ.getServiceId == serviceId
        );
      const extensionServicesIds: number[] = [];
      extensionServices.forEach(element =>
        extensionServicesIds.push(element.getExtensionId)
      );
      return state.extensions.filter(extension =>
        extensionServicesIds.includes(extension.getId)
      );
    }
};

const actions: ActionTree<ExtensionState, RootState> = {
  async loadExtensions({ commit }) {
    const extensions: ExtensionCore[] =
      await extensionService.methods.getExtensions();
    commit("setExtensions", extensions);
  },
  async loadCompatibleExtensionsByClientId({ commit }, clientId: number) {
    const clientCompatibleExtensions: ExtensionServiceCore[] =
      await extensionService.methods.getClientCompatibleExtensions(clientId);
    commit("setCompatibleExtensions", clientCompatibleExtensions);
  },
  async loadExtensionService({ commit }) {
    const extensionServices: ExtensionServiceCore[] =
      await extensionService.methods.getExtensionService();
    commit("setExtensionService", extensionServices);
  }
};

export const extensionModuleCore: Module<ExtensionState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
