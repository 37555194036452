import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import RequestDx from "../../../models/deliveryexperience/RequestDx";
import requestServiceDx from "../../../services/deliveryexperience/requestService";
import { RootState } from "../../index";
import Singleton from "../../../utils/singleton";
interface IRequestState {
  requestsDx: RequestDx[];
}

const namespaced = true;

const state: IRequestState = {
  requestsDx: []
};

const getters: GetterTree<IRequestState, RootState> = {
  getRequestsDxByProjectId:
    state =>
    (projectId: number): RequestDx[] => {
      return state.requestsDx
        .filter(requestDx => requestDx.getProjectId == projectId)
        .sort((req1, req2) => (req1.getDate > req2.getDate ? 1 : -1));
    },
  getRequestsDxByProjectIdAndStatus:
    state =>
    (projectId: number, status: string): RequestDx[] => {
      return state.requestsDx
        .filter(
          requestDx =>
            requestDx.getProjectId == projectId && requestDx.getStatus == status
        )
        .sort((req1, req2) => (req1.getDate > req2.getDate ? 1 : -1));
    },
  getRequestDx:
    state =>
    (requestId: string): RequestDx => {
      return state.requestsDx.find(requestDx => requestDx.getId == requestId);
    }
};

const mutations: MutationTree<IRequestState> = {
  setRequestsDx(state, requests: RequestDx[]): void {
    requests.forEach(r => {
      const index = state.requestsDx.findIndex(
        request => request.getId === r.getId
      );
      if (index === -1) state.requestsDx.push(r);
      else state.requestsDx[index] = r;
    });
  },
  updateRequestDx(state, updatedRequest: RequestDx): void {
    const index = state.requestsDx.findIndex(
      r => r.getId === updatedRequest.getId
    );
    if (index != -1) {
      state.requestsDx.splice(index, 1, updatedRequest);
    }
  },
  addRequestDx(state, request: RequestDx): void {
    state.requestsDx.push(request);
  },
  deleteRequestDx(state, requestId: string): void {
    const index = state.requestsDx.findIndex(
      requestDx => requestDx.getId == requestId
    );
    state.requestsDx.splice(index, 1);
  }
};

const actions: ActionTree<IRequestState, RootState> = {
  async loadRequestsDxByProjectId({ commit }, projectId: number): Promise<any> {
    const requests: RequestDx[] =
      await requestServiceDx.methods.getRequestsByProject(projectId);
    commit("setRequestsDx", requests);
  },
  async loadRequestDx({ commit }, requestId: string): Promise<any> {
    const request: RequestDx = await requestServiceDx.methods.getRequest(
      requestId
    );
    commit("updateRequestDx", request);
  },
  async postRequestDx({ commit }, newRequest: RequestDx): Promise<RequestDx> {
    const request: RequestDx = await requestServiceDx.methods.postRequest(
      newRequest
    );
    commit("addRequestDx", request);
    return request;
  },
  async putRequestDx({ commit }, modifiedRequest: RequestDx): Promise<any> {
    const request: RequestDx = await requestServiceDx.methods.putRequest(
      modifiedRequest
    );
    commit("updateRequestDx", request);
    await sendNewComment(request);
  },
  async deleteRequestDx(
    { commit, dispatch, getters, rootGetters },
    requestId: string
  ): Promise<any> {
    await requestServiceDx.methods.deleteRequest(requestId);
    const deletedRequest: RequestDx = getters.getRequestDx(requestId);
    commit("deleteRequestDx", requestId);

    /* Cascade */
    for (const event of rootGetters["eventDx/getEventsDxByRelated"](
      requestId
    )) {
      event.setRelated = null;
      await dispatch("eventDx/updateEventDxLocal", event, { root: true });
    }

    for (const doc of rootGetters["documentDx/getDocumentsDxByRequestId"](
      requestId
    )) {
      await dispatch("documentDx/deleteDocumentDxLocal", doc.getId);
    }

    if (deletedRequest.getTag) {
      await dispatch(
        "tagDx/checkRemainingProjectTag",
        { tag: deletedRequest.getTag, projectId: deletedRequest.getProjectId },
        { root: true }
      );
    }
  },
  deleteRequestDxLocal({ commit }, requestId: string): void {
    commit("deleteRequestDx", requestId);
  }
};

async function sendNewComment(request: RequestDx) {
  if (request) {
    await Singleton.get().vue.$chatConnection.invoke(
      "newComment",
      request.getId,
      request.getThread.at(request.getThread.length - 1)
    );
  }
}

export const requestModuleDx: Module<IRequestState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
