























  import { servicesExtensions } from "../../../_helpers/servicesExtensions";

  export default {
    name: servicesExtensions.cspPanel.extensions.cspDashboard.name
      .toLowerCase()
      .replace(/\s+/g, ""),
    data() {
      return {
        loadedIFrame: false,
        cspURL: ""
      };
    },
    async created() {
      this.addLoadingComponent(this.$options.name);
      const projectId = Number(this.$route.params.projectId);
      await this.loadCustomerSummaryCSPById(
        this.getProjectById(projectId).getCustomerId
      );
      const customerURL = await this.getCustomerName(projectId);
      this.cspURL =
        "https://sogeti-app-cspdashboards-" +
        customerURL +
        ".azurewebsites.net";
      this.loadedIFrame = true;
      this.removeLoadingComponent(this.$options.name);
    },
    async mounted() {
      // Listen to messages sent from the iframe
      window.addEventListener("message", event => {
        // Verify that the message is valid and is of type 'iframeHeight'
        if (event.data && event.data.type === "iframeHeight") {
          // Get the iframe and set its height equal to the height sent by the iframe
          const iframe = this.$refs.iframe;
          iframe.style.height = event.data.height + "px";
        }
      });

      this.$emit("projectHeaderService", servicesExtensions.cspPanel.name);
      this.$emit(
        "projectHeaderExtension",
        servicesExtensions.cspPanel.extensions.cspDashboard.name
      );
    },
    methods: {
      async getCustomerName(projectId: number) {
        const customerId = this.getProjectById(projectId).getCustomerId;
        return this.getCustomerSummaryCSPById(customerId).getCustomerName;
      }
    }
  };
