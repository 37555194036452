export interface IMargin {
  customerId: string;
  marginLicences: number;
  marginAzure: number;
}

export default class Margin {
  private customerId: string;
  private marginLicences: number;
  private marginAzure: number;

  constructor(dto: IMargin) {
    Object.assign(this, dto);
  }

  get getCustomerId(): string {
    return this.customerId;
  }

  set setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  get getMarginLicences(): number {
    return this.marginLicences;
  }

  set setMarginLicences(marginLicences: number) {
    this.marginLicences = marginLicences;
  }

  get getMarginAzure(): number {
    return this.marginAzure;
  }

  set setMarginAzure(marginAzure: number) {
    this.marginAzure = marginAzure;
  }
}
