var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"edit-people-modal","size":"lg","centered":"","modal-class":"modified-modal","header-class":"modified-modal-header","body-class":"modified-modal-body","content-class":"modified-modal-content","footer-class":"modified-modal-footer"},on:{"hidden":function($event){(_vm.peopleAddEditDelete = []),
      (_vm.searchUser = ''),
      (_vm.memberEditing = ''),
      (_vm.loading = false)},"ok":_vm.saveChanges},scopedSlots:_vm._u([{key:"modal-header",fn:function(){return [_c('h5',[_vm._v(_vm._s(_vm.text.teamManagement))])]},proxy:true},{key:"default",fn:function(){return [_c('CapsuleLabelInput',{attrs:{"input":_vm.searchUser,"placeholder":_vm.text.searchSomeone,"maxlength":40},on:{"setValue":function (value) { return (_vm.searchUser = value); }}}),_c('div',[_c('b-row',{staticClass:"header-row"},[_c('b-col',{staticClass:"center-item font-weight-bold",attrs:{"cols":"4"}},[_vm._v(_vm._s(_vm.text.employee)+" ")]),_c('b-col',{staticClass:"center-item header-title font-weight-bold"},[_vm._v(_vm._s(_vm.text.jobPosition))]),_c('b-col',{staticClass:"center-item header-title font-weight-bold"},[_vm._v(_vm._s(_vm.text.projectRole))]),_c('b-col')],1),_c('div',{staticStyle:{"overflow":"scroll","max-height":"455px"}},_vm._l((_vm.usersList),function(member,index){return _c('b-row',{key:index,staticClass:"user-row",staticStyle:{"overflow":"scroll","max-height":"455px"},style:({
            borderBottom:
              index != _vm.usersList.length - 1 ? '#ececec solid 1px' : ''
          })},[_c('b-col',{attrs:{"cols":"4"}},[_c('PeopleInfo',{attrs:{"person":_vm.userPeopleIcon(member),"showJobPosition":false,"iconSize":35,"fontSize":1}})],1),_c('b-col',{staticClass:"center-item job-position-text"},[_vm._v(" "+_vm._s(_vm.getPeopleByUsername(member.getUsername).getJobPosition)+" ")]),(_vm.memberEditing === index)?_c('b-col',{staticClass:"center-item"},[_c('CapsuleLabelInput',{attrs:{"input":_vm.jobPositionEditing,"placeholder":_vm.text.enterProjectRole,"maxlength":50},on:{"setValue":function (value) { return (_vm.jobPositionEditing = value); }}})],1):_c('b-col',{staticClass:"center-item job-position-text"},[_vm._v(" "+_vm._s(_vm.getMemberJobPosition( _vm.getMemberUpdated(member.getUsername, index).getJobPosition ))+" ")]),_c('b-col',{staticClass:"center-item",staticStyle:{"justify-content":"center"}},[(_vm.memberEditing !== index)?_c('div',[_c('div',{class:_vm.isMemberAdded(member) ? 'member-icon' : '',staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.handleMemberClick(member, 'add')}}},[_c('div',{staticClass:"mx-1"},[(_vm.getMemberUpdated(member.getUsername, index).isPOC)?_c('font-awesome-icon',{staticClass:"fa-lg",staticStyle:{"color":"var(--main-bg-color)"},attrs:{"icon":"fa-solid fa-id-badge"}}):_vm._e()],1),_c('div',{staticClass:"mx-1",class:!_vm.isMemberAdded(member) ? 'button-hover' : ''},[_c('font-awesome-icon',{staticClass:"fa-lg",style:({
                      color: _vm.isMemberAdded(member)
                        ? 'var(--main-bg-color)'
                        : ''
                    }),attrs:{"icon":_vm.addOrAddedMemberIcon(member)}})],1)]),(_vm.isMemberAdded(member))?_c('div',{staticClass:"option-icons",staticStyle:{"display":"none"}},[(
                    _vm.getUser.isSogeti() &&
                    _vm.getPeopleByUsername(member.getUsername).isSogeti()
                  )?_c('div',{staticClass:"button-hover mx-1",on:{"click":function($event){_vm.editPOC(_vm.getMemberUpdated(member.getUsername, index))}}},[_c('font-awesome-icon',{staticClass:"fa-lg",style:({
                      color: _vm.getMemberUpdated(member.getUsername, index).isPOC
                        ? 'var(--main-bg-color)'
                        : 'var(--dark-gray-25)'
                    }),attrs:{"icon":"fa-solid fa-id-badge"}})],1):_vm._e(),_c('div',{staticClass:"button-hover mx-1",on:{"click":function($event){{
                      _vm.memberEditing = index;
                      _vm.jobPositionEditing =
                        _vm.getMemberUpdated(member.getUsername, index)
                          .getJobPosition == _vm.text.notSpecifiedJobPosition
                          ? ''
                          : _vm.getMemberUpdated(member.getUsername, index)
                              .getJobPosition;
                    }}}},[_c('font-awesome-icon',{staticClass:"fa-lg",attrs:{"icon":"fa-solid fa-pen"}})],1),_c('div',{staticClass:"button-hover mx-1",on:{"click":function($event){return _vm.handleMemberClick(member, 'delete')}}},[_c('font-awesome-icon',{staticClass:"fa-lg",staticStyle:{"color":"var(--tech-red)"},attrs:{"icon":"fa-regular fa-trash-can"}})],1)]):_vm._e()]):_c('div',{staticClass:"center-item"},[_c('div',{staticClass:"button-hover mx-1",on:{"click":function($event){_vm.editJobPosition(_vm.getMemberUpdated(member.getUsername, index))}}},[_c('font-awesome-icon',{staticClass:"fa-lg",staticStyle:{"height":"22px"},attrs:{"icon":"fas fa-check"}})],1),_c('div',{staticClass:"button-hover cancel-edit mx-1",on:{"click":function($event){_vm.memberEditing = ''}}},[_c('font-awesome-icon',{staticClass:"fa-lg",staticStyle:{"height":"22px"},attrs:{"icon":"fas fa-times"}})],1)])])],1)}),1)],1)]},proxy:true},{key:"modal-footer",fn:function(ref){
                    var ok = ref.ok;
                    var cancel = ref.cancel;
return [_c('b-button',{staticClass:"button-small button-cancel",attrs:{"pill":""},on:{"click":function($event){return cancel()}}},[_vm._v(" "+_vm._s(_vm.text.cancel)+" ")]),_c('b-button',{staticClass:"button-small button-save button-size button-disabled mr-0",attrs:{"pill":"","disabled":_vm.loading},on:{"click":ok}},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}]},[_vm._v(_vm._s(_vm.text.save))]),_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"button-spinner"})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }