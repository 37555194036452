import ProjectCore, { IProject } from "./ProjectCore";

export interface IProjectCSP extends IProject {
  customerId: number;
  totalSubscriptions?: number;
  licences?: number;
  azurePlans?: number;
  currentCost?: number;
}

export default class ProjectCSP extends ProjectCore {
  private customerId: number;
  private totalSubscriptions?: number;
  private licences?: number;
  private azurePlans?: number;
  private currentCost?: number;

  constructor(dto: IProjectCSP) {
    super(dto);
    Object.assign(this, dto);
  }

  get getCustomerId(): number {
    return this.customerId;
  }

  set setCustomerId(customerId: number) {
    this.customerId = customerId;
  }

  get getTotalSubscriptions(): number {
    return this.totalSubscriptions;
  }

  set setTotalSubscriptions(totalSubscriptions: number) {
    this.totalSubscriptions = totalSubscriptions;
  }

  get getLicences(): number {
    return this.licences;
  }

  set setLicences(licences: number) {
    this.licences = licences;
  }

  get getAzurePlans(): number {
    return this.azurePlans;
  }

  set setAzurePlans(azurePlans: number) {
    this.azurePlans = azurePlans;
  }

  get getCurrentCost(): number {
    return this.currentCost;
  }

  set setCurrentCost(currentCost: number) {
    this.currentCost = currentCost;
  }
}
