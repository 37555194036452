/**
 * Singleton is a class that ensures a single instance of itself is created.
 * This class also holds a reference to a Vue instance.
 */
class Singleton {
  // Holds the single instance of Singleton
  private static instance: Singleton;

  // Holds the Vue instance
  private vueInstance;

  /**
   * The Singleton constructor.
   * If a Singleton instance already exists, it returns that.
   * Otherwise, it creates a new Singleton instance and a reference to a Vue instance.
   * @param {Object} vue - An optional Vue instance.
   */
  private constructor(vue?) {
    if (Singleton.instance) {
      return Singleton.instance;
    }
    this.vueInstance = vue;
    Singleton.instance = this;
  }

  /**
   * Gets the Singleton instance, creating it if it doesn't exist.
   * @param {Object} vue - An optional Vue instance.
   * @returns {Singleton} The Singleton instance.
   */
  static get(vue?): Singleton {
    Singleton.instance = Singleton.instance || new Singleton(vue);
    return Singleton.instance;
  }

  /**
   * Gets the Vue instance.
   * @returns {Object} The Vue instance.
   */
  get vue() {
    return this.vueInstance;
  }
}

export default Singleton;
