









































































  import { eventTypes } from "../../../_helpers/eventTypesStates";
  import { servicesExtensions } from "../../../_helpers/servicesExtensions";
  import Subscriptions from "../../../components/services/csp/Subscriptions.vue";
  import RequestForm from "../../../components/services/deliveryexperience/request/RequestForm.vue";
  import CapsuleMultiselect from "../../../components/view_elements/CapsuleMultiselect.vue";
  import config from "../../../config";
  import CustomerCSP from "../../../models/csp/CustomerCSP";
  import SingleSelectType from "../../../view-models/SingleSelectType";

  export default {
    name: servicesExtensions.cspPanel.extensions.cspOperations.name
      .toLowerCase()
      .replace(/\s+/g, ""),
    components: { CapsuleMultiselect, Subscriptions, RequestForm },
    data() {
      return {
        loadedPage: false,
        showLoading: false,
        requestCSPType: new SingleSelectType({
          key: eventTypes.REQUEST.events.cspRequest.type,
          name: this.$tc(eventTypes.REQUEST.events.cspRequest.i18n, 1),
          icon:
            "services/deliveryexperience/timeline/" +
            eventTypes.REQUEST.events.cspRequest.icon.normal
        }),
        loadingIntervalId: undefined,
        requestFormId: "csp-request-form",
        subscriptionsKey: 0,
        cspURL: "",
        tenantSelected: undefined,
        tenants: [],
        textLoading: "",
        text: {
          selectTenant: this.$t("selectTenant"),
          tenant: this.$t("tenant"),
          directory: this.$t("directory"),
          refresh: this.$t("refresh"),
          loading: this.$t("loading"),
          request: this.$tc("request", 1),
          operationHistory: this.$t("operationHistory")
        }
      };
    },
    async created() {
      this.textLoading = this.text.loading;
      this.startLoading();
      this.addLoadingComponent(this.$options.name);
      await this.loadProject(Number(this.$route.params.projectId));
      await this.loadCustomersCSP();
      await this.loadMarginsCSP();
      await this.loadPendingOperationsCSP();
      this.tenants = await this.tenantList();
      this.tenantSelected = this.tenants[0];
      this.loadedPage = true;
      this.removeLoadingComponent(this.$options.name);
    },
    mounted() {
      this.$emit("projectHeaderService", servicesExtensions.cspPanel.name);
      this.$emit(
        "projectHeaderExtension",
        servicesExtensions.cspPanel.extensions.cspOperations.name
      );
    },
    computed: {
      multiselectLabel(): string {
        return this.text.directory + " (" + this.text.tenant + ")";
      }
    },
    methods: {
      goToTimeline() {
        this.$router.push(
          `/${servicesExtensions.deliveryExperience.url}/${this.$route.params.projectId}/timeline`
        );
      },
      getTimelineIcon(): NodeRequire {
        return require(`../../../assets/icons/services/${servicesExtensions.deliveryExperience.iconFolder}/${servicesExtensions.deliveryExperience.extensions.timeline.icon}`);
      },
      getRequestIcon(): NodeRequire {
        return require(`../../../assets/icons/services/${servicesExtensions.deliveryExperience.iconFolder}/${servicesExtensions.deliveryExperience.extensions.requests.icon}`);
      },
      openRequestForm() {
        this.$bvModal.show(this.requestFormId);
      },
      refreshSubscriptions() {
        this.subscriptionsKey++;
        this.startLoading();
      },
      startLoading() {
        this.showLoading = true;
        let numDots = 0;
        this.loadingIntervalId = setInterval(() => {
          numDots = (numDots % 3) + 1;
          this.textLoading = this.text.loading + ".".repeat(numDots);
        }, 1000);
      },
      stopLoading() {
        this.showLoading = false;
        clearInterval(this.loadingIntervalId);
      },
      async tenantList(): Promise<CustomerCSP[]> {
        const list = [];
        if (config.prodEnv) {
          const tenantsCSV = this.getClients.find(
            e =>
              e.id == this.getProjectById(this.$route.params.projectId).clientId
          ).tenant;
          if (tenantsCSV) {
            const tenantsIds = tenantsCSV.split(",");
            for (const tenant of tenantsIds) {
              list.push(this.getCustomersCSP.find(e => e.id == tenant));
            }
          }
        } else {
          list.push({
            id: "b3729cd9-53b3-4abd-b7fe-3dbbcb5ea489",
            name: "CSP Dashboard"
          });
          list.push({
            id: "667d438b-32ec-4266-b952-0458fae25c90",
            name: "Sogeti POC"
          });
          list.push({
            id: "a1e4d903-c0a4-4917-8d17-9a8e09c3ea9b",
            name: "CSPSBX Dashboard"
          });
        }
        return list;
      }
    },
    watch: {
      tenantSelected() {
        this.startLoading();
      }
    }
  };
