


















































  export default {
    data() {
      return {
        text: {
          welcomeBack: this.$t("welcomeBack"),
          enter: this.$t("enter"),
          reservedRights: this.$t("reservedRights")
        }
      };
    },
    created() {
      this.$root.$emit("hideHeaderFooter", true);
    },
    methods: {
      goToHomePage() {
        this.$root.$emit("hideHeaderFooter", false);
        this.$router.push("/home");
      }
    }
  };
