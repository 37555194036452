




















































































  import {
    eventStates,
    getTimelineItemIcon
  } from "../../../../_helpers/eventTypesStates";
  import notifications from "../../../../_helpers/notifications";
  import { IBaseLineDx } from "../../../../models/deliveryexperience/BaseLineDx";
  import EventDx from "../../../../models/deliveryexperience/EventDx";
  import NotificationDx from "../../../../models/deliveryexperience/NotificationDx";
  import StageDx from "../../../../models/deliveryexperience/StageDx";
  import CapsuleInputDate from "../../../view_elements/CapsuleInputDate.vue";
  import CapsuleLabelInput from "../../../view_elements/CapsuleLabelInput.vue";

  export default {
    name: "PostponeForm",
    components: { CapsuleLabelInput, CapsuleInputDate },
    props: {
      id: String,
      projectId: Number,
      eventStage: Object,
      isItEventOrStage: String,
      assignedVersionDx: String
    },
    data() {
      return {
        text: {
          postpone: this.$t("postpone"),
          differenceDays: this.$t("differenceDays"),
          startDate: this.$t("startDate"),
          assignedBaseline: this.$t("assignedBaseline"),
          cancel: this.$t("cancel"),
          save: this.$t("save")
        },
        updateTimelineChartType: undefined,
        startDateRequired: Boolean(false),
        actualDate: new Date(),
        date: undefined,
        difference_days: undefined,
        oneDayInMilliseconds: 86400000
      };
    },
    computed: {
      differenceDays: {
        get() {
          return this.difference_days;
        },
        set(value: Date) {
          const delayedDate = value;
          const initStartDate = new Date(
            this.eventStage.getLastHistoryBaseline.startedDate
          );
          this.difference_days = (
            (delayedDate.getTime() - initStartDate.getTime()) /
            this.oneDayInMilliseconds
          ).toFixed(0);
        }
      }
    },
    methods: {
      getTimelineItemIcon(timelineItemType: string): NodeRequire {
        return getTimelineItemIcon(timelineItemType);
      },
      setDate(value: Date) {
        this.date = value;
        //Also set Difference days between previous startDate and delayed startDate
        this.differenceDays = value;
      },
      getNewEndDate(lastEnd: string): Date {
        const lastEndDate = new Date(lastEnd);
        const diffDaysInMilliseconds =
          this.oneDayInMilliseconds * parseInt(this.difference_days);
        return new Date(lastEndDate.getTime() + diffDaysInMilliseconds);
      },
      async updateEventDx(): Promise<void> {
        //Update Predecessor event
        let updatedEvent: EventDx = this.eventStage;
        updatedEvent.setConfirmed = false;
        updatedEvent.setOccured = false;
        try {
          await this.putEventDx(updatedEvent);
        } catch (error) {
          this.$log.error(error);
        }
      },
      async updateStageDx(): Promise<void> {
        //Update Predecessor event
        let updatedStage: StageDx = this.eventStage;
        updatedStage.setConfirmed = false;
        updatedStage.setOccured = false;
        try {
          await this.putStageDx(updatedStage);
        } catch (error) {
          this.$log.error(error);
        }
      },
      async updateEventBaseline(): Promise<void> {
        const eventBaseline: {
          eventId: string;
          baseline: IBaseLineDx;
        } =
          //Event baseline
          {
            eventId: this.eventStage.getId,
            baseline: {
              version: this.assignedVersionDx,
              status: eventStates.pendingToOccur.name,
              startedDate: this.date,
              endDate: null,
              affecteds: this.eventStage.getLastHistoryBaseline.affecteds,
              predecessors: this.eventStage.getLastHistoryBaseline.predecessors
            }
          };
        try {
          if (
            this.eventStage.getLastHistoryBaseline.version ==
            this.assignedVersionDx
          ) {
            await this.putEventDxBaseline(eventBaseline);
          } else {
            await this.postEventDxBaseline(eventBaseline);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async updateStageBaseline(): Promise<void> {
        const stageBaseline: {
          stageId: string;
          baseline: IBaseLineDx;
        } =
          //Stage baseline
          {
            stageId: this.eventStage.getId,
            baseline: {
              version: this.assignedVersionDx,
              status: eventStates.pendingToOccur.name,
              startedDate: this.date,
              endDate: this.getNewEndDate(
                this.eventStage.getLastHistoryBaseline.endDate
              ),
              affecteds: this.eventStage.getLastHistoryBaseline.affecteds,
              predecessors: this.eventStage.getLastHistoryBaseline.predecessors
            }
          };
        try {
          if (
            this.eventStage.getLastHistoryBaseline.version ==
            this.assignedVersionDx
          ) {
            await this.putStageDxBaseline(stageBaseline);
          } else {
            await this.postStageDxBaseline(stageBaseline);
          }
        } catch (error) {
          this.$log.error(error);
        }
      },
      async postpone(bvModalEvent) {
        //prevent default closing
        bvModalEvent.preventDefault();
        this.startDateRequired = true;
        if (this.date != undefined) {
          if (this.isItEventOrStage == "event") {
            await this.updateEventDx();
            await this.updateEventBaseline();
            this.updateTimelineChartType = "events";
          } else {
            await this.updateStageDx();
            await this.updateStageBaseline();
            this.updateTimelineChartType = "stages";
          }
          this.sendNotificationDx();
          this.handleCloseSubmit();
        }
      },
      async sendNotificationDx() {
        const notificationDx = new NotificationDx({
          itemName: this.eventStage.getName,
          projectId: this.$route.params.projectId,
          itemType:
            this.isItEventOrStage == "event"
              ? this.eventStage.getEventType
              : "stage",
          itemId: this.eventStage.getId,
          recipients: this.getRecipients(),
          action: "rescheduled",
          author: this.getUser.getUsername
        });
        if (notificationDx.recipients.length)
          await this.postNotificationDx(notificationDx);
      },
      getRecipients(): string[] {
        let item;
        switch (this.isItEventOrStage) {
          case "event":
            item = this.getEventDx(this.eventStage.getId);
            break;
          case "stage":
            item = this.getStageDx(this.eventStage.getId);
            break;
        }
        return notifications.getPeopleForRecipients(
          item.getNotificationsPolicy,
          this.getPeopleDxByProjectId(this.$route.params.projectId),
          item.getAuthor,
          this.getUser.getUsername,
          item.getId
        );
      },
      resetValues() {
        this.date = undefined;
        this.difference_days = undefined;
        this.startDateRequired = false;
        this.updateTimelineChartType = undefined;
      },
      async handleCloseSubmit(): Promise<void> {
        await this.$emit("updateTimeline", this.updateTimelineChartType);
        this.$nextTick(async () => {
          await this.$refs[this.id].hide();
        });
      }
    }
  };
