







































  export default {
    name: "CapsuleLabelInput",
    props: {
      id: String,
      type: String,
      label: String,
      input: null,
      placeholder: String,
      maxlength: Number,
      disabled: { type: Boolean, default: false },
      alert: { type: Boolean, default: false },
      required: {
        type: Object,
        default: function () {
          return { status: false, style: false };
        }
      },
      min: Number,
      max: Number,
      step: Number,
      oninput: String,
      background: { type: String, default: "white" },
      comeFrom: String,
      accept: String
    },
    computed: {
      input_: {
        get() {
          return this.input;
        },
        set(value) {
          this.$emit("setValue", value);
        }
      }
    }
  };
