import config from "../../config";
import { i18n } from "../../plugins/i18";
import { getEventTypes } from "../eventTypesStates";
import {
  getProjectTypeDescription,
  getProjectTypeTranslation,
  projectTypes
} from "../projectMetadata";
import { servicesExtensions } from "../servicesExtensions";
import emailBuilder from "./emailBuilder";

function build(
  projectName: string,
  projectType: string,
  projectId: string,
  lang: string
): string {
  return `
    <p>${i18n.t("projectIncorporationEmailGreeting", lang, {
      project: projectName
    })}</p>
    <p>
      ${emailBuilder.bodyDetailsList(projectDetails(projectType, lang))}
    </p>
    <p>&nbsp;</p>
    <p style="text-align: center"><img width="50%" src="${
      "https://sogetisaportalhubp.blob.core.windows.net/sogetihub/email-project-incorporation/" +
      getProjectKpiImage(projectType)
    }"></p>
    <p>&nbsp;</p>
    <p>${i18n.t("projectIncorporationEmailEnding", lang, {
      link:
        config.redirectUri +
        "/" +
        servicesExtensions.deliveryExperience.url +
        "/" +
        projectId
    })}</p>
    <p>&nbsp;</p>
    <p><a href="https://sogetisaportalhubp.blob.core.windows.net/sogetihub/SogetiHub%20Onboarding.mp4"><img style="width: 100%" src="https://sogetisaportalhubp.blob.core.windows.net/sogetihub/SogetiHub%20Onboarding.png"></img></a></p>
  `;
}

function projectDetails(projectType: string, lang: string): object {
  const projectDetails = {
    projectType: {
      name: i18n.t("projectType", lang),
      value: i18n.tc(getProjectTypeTranslation(projectType), 1, lang) + "."
    },
    projectTypeDesc: {
      name: i18n.t("description", lang),
      value: i18n.t(getProjectTypeDescription(projectType), lang)
    }
  };

  for (const event of Object.values(getEventTypes(projectType))) {
    projectDetails[event["type"]] = {
      name: i18n.tc("event", 1, lang) + " - " + event["name"],
      value: i18n.t(event["i18nDesc"], lang)
    };
  }

  return projectDetails;
}

/**
 * This function returns the project KPI image by the project type.
 * These images are located in assets/email folder although they are
 * loaded from the Azure Blob Storage, but preserved locally for version control.
 */
function getProjectKpiImage(projectType: string): string {
  switch (projectType) {
    case projectTypes.liabilityTemporary.name:
    case projectTypes.liabilityEconomic.name:
      return "project-kpi-liability.png";
    case projectTypes.liabilityFull.name:
      return "project-kpi-liability-full.png";
    case projectTypes.timeAndMaterials.name:
      return "project-kpi-tam.png";
    case projectTypes.capacity.name:
      return "project-kpi-capacity.png";
    case projectTypes.QBR.name:
      return "project-kpi-qbr.png";
    case projectTypes.CSP.name:
      return "project-kpi-csp.png";
    default:
      return "";
  }
}

export default {
  build
};
