import ProjectCSP from "../models/core/ProjectCSPCore";
import ProjectCapacity from "../models/core/ProjectCapacityCore";
import ProjectCore from "../models/core/ProjectCore";
import ProjectLiabilityEconomic from "../models/core/ProjectLiabilityEconomicCore";
import ProjectLiabilityFull from "../models/core/ProjectLiabilityFullCore";
import ProjectLiabilityTemporary from "../models/core/ProjectLiabilityTemporaryCore";
import ProjectQBR from "../models/core/ProjectQBRCore";
import ProjectTimeAndMaterials from "../models/core/ProjectTimeAndMaterialsCore";

export const projectTypes = {
  liability: {
    name: "LIABILITY",
    i18n: "liability",
    i18nDesc: "liabilityDesc"
  },
  liabilityEconomic: {
    name: "LIABILITY_ECONOMIC",
    i18n: "liabilityEconomic",
    i18nShort: "liabEconomic",
    i18nDesc: "liabilityDesc"
  },
  liabilityTemporary: {
    name: "LIABILITY_TEMPORARY",
    i18n: "liabilityTemporary",
    i18nShort: "liabTemporary",
    i18nDesc: "liabilityDesc"
  },
  liabilityFull: {
    name: "LIABILITY_FULL",
    i18n: "liabilityFull",
    i18nShort: "liabFull",
    i18nDesc: "liabilityDesc"
  },
  timeAndMaterials: {
    name: "TIME_AND_MATERIALS",
    i18n: "timeAndMaterials",
    i18nDesc: "timeAndMaterialsDesc"
  },
  capacity: {
    name: "CAPACITY",
    i18n: "capacity",
    i18nDesc: "capacityDesc"
  },
  QBR: {
    name: "QBR",
    i18n: "qbr",
    i18nDesc: "qbrDesc"
  },
  CSP: {
    name: "CSP",
    i18n: "csp",
    i18nDesc: "cspDesc"
  }
};

export const projectStates = {
  active: {
    name: "ACTIVE",
    i18n: "active"
  },
  closed: {
    name: "CLOSED",
    i18n: "closed"
  },
  removed: {
    name: "REMOVED",
    i18n: "removed"
  }
};

export const projectThemes = {
  light: {
    value: true,
    i18n: "light"
  },
  dark: {
    value: false,
    i18n: "dark"
  }
};

export function getStateTranslation(state: string): string {
  switch (state) {
    case projectStates.active.name:
      return projectStates.active.i18n;
    case projectStates.closed.name:
      return projectStates.closed.i18n;
    case projectStates.removed.name:
      return projectStates.removed.i18n;
    default:
      throw new Error(state + " state not found");
  }
}

export function getProjectTypeTranslation(projectType: string): string | null {
  for (const projType in projectTypes) {
    if (projectTypes[projType].name === projectType) {
      return projectTypes[projType].i18n;
    }
  }
  return null;
}

export function getProjectTypeDescription(projectType: string): string | null {
  for (const projType in projectTypes) {
    if (projectTypes[projType].name === projectType) {
      return projectTypes[projType].i18nDesc;
    }
  }
  return null;
}

export function getProjectThemeTranslation(themeBool: Boolean): string {
  if (themeBool == projectThemes.light.value) return projectThemes.light.i18n;
  else return projectThemes.dark.i18n;
}

export function getModelProjectType(
  project
):
  | ProjectCore
  | ProjectLiabilityEconomic
  | ProjectLiabilityTemporary
  | ProjectLiabilityFull
  | ProjectTimeAndMaterials
  | ProjectCapacity
  | ProjectQBR
  | ProjectCSP {
  switch (project.projectType) {
    case projectTypes.liabilityEconomic.name:
      return new ProjectLiabilityEconomic(project);
    case projectTypes.liabilityTemporary.name:
      return new ProjectLiabilityTemporary(project);
    case projectTypes.liabilityFull.name:
      return new ProjectLiabilityFull(project);
    case projectTypes.timeAndMaterials.name:
      return new ProjectTimeAndMaterials(project);
    case projectTypes.capacity.name:
      return new ProjectCapacity(project);
    case projectTypes.QBR.name:
      return new ProjectQBR(project);
    case projectTypes.CSP.name:
      return new ProjectCSP(project);
    default:
      return new ProjectCore(project);
  }
}
