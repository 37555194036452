export interface IReadWrite {
  read: Boolean;
  write: Boolean;
}
export default class ReadWrite {
  private read: Boolean;
  private write: Boolean;

  constructor(dto: IReadWrite) {
    Object.assign(this, dto);
  }

  get getRead(): Boolean {
    return this.read;
  }

  set setRead(value: Boolean) {
    this.read = value;
  }

  get getWrite(): Boolean {
    return this.write;
  }

  set setWrite(value: Boolean) {
    this.write = value;
  }
}
