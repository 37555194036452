export interface ISingleSelectType {
  key: any;
  name: string;
  icon?: string;
}

export default class SingleSelectType {
  private key: any;
  private name: string;
  private icon?: string;

  constructor(value: ISingleSelectType) {
    Object.assign(this, value);
  }

  get getKey(): any {
    return this.key;
  }

  get getName(): string {
    return this.name;
  }

  get getIcon(): string {
    return this.icon;
  }

  set setKey(value: any) {
    this.key = value;
  }

  set setName(value: string) {
    this.name = value;
  }

  set setIcon(value: string) {
    this.icon = value;
  }
}
