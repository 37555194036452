import config from "../../config";

function build(): string {
  return `
    <p>You have been signed up on SogetiHub and now you can <b>access the platform</b> by clicking on the button below.</p>
    <p>&nbsp;</p>
    <p><a href="${config.redirectUri}"><button style="padding: 1rem; color: white; background-color: #0070ad !important; border-radius: 1rem;"><b>Access SogetiHub</b></button></a></p>
    <p>&nbsp;</p>
    <p style="text-align: justify">If you are part of an <b>existing project</b> on the platform, you will see it soon on the home page. Otherwise, if you wish to create new projects, an administrator will create them for you so that you can access them soon, but first, <b>provide the following details for each of them</b> to <a href="mailto:elliot.ribas-garcia@sogeti.comsubject=[SogetiHub] Project setup request">elliot.ribas-garcia@sogeti.com</a>:</p>
    <p>
      <ul>
        <li>Your <b>job position/profile</b> (you can edit it from "Account" in the platform)</li>
        <li>Project <b>name</b></li>
        <li>Project brief <b>description</b></li>
        <li>Project <b>type</b>:</li>
          <ul>
            <li>Economic/Temporary/Full Liability (closed/scoped)</li>
            <li>Capacity (based on hours bag, working days, etc.)</li>
            <li>Time &amp; Materials (provide assigned execution team members)</li>
            <li>CSP (Microsoft Cloud Subscriptions &amp; Product Licenses self-management) </li>
            <li>QBR (focused on Quarterly Business Reviews)</li>
          </ul>
        <li><b>Client</b> ("Sogeti" for internal projects or client name)</li>
        <li>Project team <b>members</b>:</li>
          <ul>
            <li>Email</li>
            <li>Project Role/Profile</li>
            <li>Client name</li>
          </ul>
        <li><b>Start</b> Date</li>
        <li><b>End</b> Date (optional)</li>
        <li><b>Icon</b> image (square, optional) </li>
        <li><b>Header </b>image (rectangular, optional)</li>
      </ul>
      <style>
        li {
          line-height: 1.5;
          word-break: break-word;
          font-size: 14px;
          mso-line-height-alt: 21px;
        }
      </style>
    </p>
    <p>&nbsp;</p>
    <p>For now, discover <b>SogetiHub's functionalities</b> in this short video:</p>
    <p>&nbsp;</p>
    <p><a href="https://sogetisaportalhubp.blob.core.windows.net/sogetihub/SogetiHub%20Onboarding.mp4"><img style="width: 100%" src="https://sogetisaportalhubp.blob.core.windows.net/sogetihub/SogetiHub%20Onboarding.png"></img></a></p>
    <p>&nbsp;</p>
    <p>SogetiHub is a platform developed by SogetiLabs Spain, <b>in beta state</b>. For any question, issue or suggestion, you can send it from <b>Requests</b> inside your project in the platform.</p>
  `;
}

export default {
  build
};
