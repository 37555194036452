var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"thread-box"},[_c('div',{staticClass:"thread-content"},[(_vm.thread != undefined)?_vm._l((_vm.thread),function(comment,index){return _c('b-row',{key:'thread-comment-' + index,staticClass:"thread-comment",class:_vm.getUser.getUsername !==
          _vm.getPeopleByUsername(comment.author).getUsername
            ? 'thread-comment-box-other'
            : ''},[_c('div',{staticClass:"thread-comment-box",style:({
            'background-color':
              _vm.getUser.getUsername ===
              _vm.getPeopleByUsername(comment.author).getUsername
                ? 'var(--main-bg-color)'
                : 'var(--dark-color)'
          })},[_c('div',{staticClass:"comment-text"},[_vm._v(_vm._s(comment.comment))]),_c('div',{staticClass:"comment-date"},[_vm._v(" "+_vm._s(_vm.getDate(comment.date))+" ")])]),_c('PeopleDefaultIcon',{staticClass:"people-icon-panel",attrs:{"people":_vm.authorIcon(comment.author),"size":30}})],1)}):_vm._e()],2),_c('div',{staticClass:"thread-input"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.newComment),expression:"newComment"}],staticClass:"comment-area-input",attrs:{"disabled":!_vm.active},domProps:{"value":(_vm.newComment)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();return _vm.saveComment.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.newComment=$event.target.value}}}),_c('button',{staticClass:"btn btn-primary btn-input",attrs:{"type":"button","disabled":!_vm.active},on:{"click":_vm.saveComment}})])])}
var staticRenderFns = []

export { render, staticRenderFns }