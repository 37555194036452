











  export default {
    name: "message",
    computed: {
      colorAlert(): string {
        if (this.getType === "success") return "alert-msg-success";
        else if (this.getType === "error") return "alert-msg-error";
        else if (this.getType === "info") return "alert-msg-info";
        return "";
      }
    }
  };
