









































































































































  import {
    getOperationType,
    getPurchaseInfo,
    getTermByBilling,
    getTermByDuration
  } from "../../../_helpers/cspOperations";
  import cspPurchaseEmailBody from "../../../_helpers/email/cspPurchaseEmailBody";
  import emailBuilder from "../../../_helpers/email/emailBuilder";
  import { eventStates, eventTypes } from "../../../_helpers/eventTypesStates";
  import notifications from "../../../_helpers/notifications";
  import { projectTypes } from "../../../_helpers/projectMetadata";
  import config from "../../../config";
  import MailCore from "../../../models/core/MailCore";
  import EventDx from "../../../models/deliveryexperience/EventDx";
  import mailService from "../../../services/core/mailService";
  import SingleSelectType from "../../../view-models/SingleSelectType";
  import CapsuleLabelInput from "../../view_elements/CapsuleLabelInput.vue";
  import CapsuleMultiselect from "../../view_elements/CapsuleMultiselect.vue";

  export default {
    name: "ReviewProducts",
    props: {
      id: String,
      tenantId: String,
      cartProducts: Array
    },
    components: { CapsuleMultiselect, CapsuleLabelInput },
    data() {
      return {
        rerenderTable: 0,
        productsToPurchase: [],
        lineItems: [],
        purchaseInfo: Object,
        productToEditNickname: null,
        fields: [
          {
            label: `${this.$t("name")} | ${this.$t("nickname")}`,
            key: "name",
            thClass: "d-flex justify-content-start align-items-center",
            thStyle: { width: "100%" },
            tdClass: "text-truncate",
            tdStyle: { width: "27%" }
          },
          {
            label: this.$tc("duration", 1),
            key: "duration",
            thClass: "align-middle",
            thStyle: { width: "12%" },
            tdClass: "align-middle",
            tdStyle: { width: "12%" }
          },
          {
            label: this.$t("billing"),
            key: "billing",
            thClass: "align-middle",
            thStyle: { width: "12%" },
            tdClass: "align-middle",
            tdStyle: { width: "12%" }
          },
          {
            label: this.$t("quantity"),
            key: "quantity",
            thClass: "align-middle",
            thStyle: { width: "8%" },
            tdClass: "align-middle",
            tdStyle: { width: "8%" }
          },
          {
            label: this.$t("unitPrice"),
            key: "unitPrice",
            thClass: "align-middle",
            thStyle: { width: "12%" },
            tdClass: "align-middle",
            tdStyle: { width: "12%" }
          },
          {
            label: this.$t("totalPrice"),
            key: "totalPrice",
            thClass: "align-middle",
            thStyle: { width: "12%" },
            tdClass: "align-middle",
            tdStyle: { width: "12%" }
          },
          {
            label: "",
            key: "actions",
            thClass: "table-width-5",
            tdClass: "table-width-5"
          }
        ]
      };
    },
    created: async function () {
      await this.setProductsToPurchase();
    },
    computed: {
      getSalesMargin(): number {
        const margin = this.getMarginCSPByCustomerId(this.tenantId);
        return margin ? margin.marginLicences : 0;
      }
    },
    methods: {
      getTermsDurationProduct(termsDuration): SingleSelectType[] {
        const termsMap = [...new Set(termsDuration.map(t => t.duration))].map(
          (duration: string) => {
            const term = getTermByDuration(duration);
            return new SingleSelectType({
              key: term.duration,
              name: this.$t(term.i18n)
            });
          }
        );
        return termsMap;
      },
      async updateProductPrices(product) {
        product.unitPrice = await this.fetchCSPProductPrice({
          productId: product.productId,
          skuId: product.id,
          billingCycle: product.billing.key,
          termDuration: product.duration.key
        });
        product.unitPrice += product.unitPrice * this.getSalesMargin;
        product.totalPrice = product.unitPrice * parseInt(product.quantity);
      },
      async changeBillingCycle(billingCycleToChange, productToPurchase) {
        productToPurchase.billing = billingCycleToChange;
        await this.updateProductPrices(productToPurchase);
        this.editProductFromCart(productToPurchase);
      },
      async changeTermDuration(
        termDurationToChange: SingleSelectType,
        productToPurchase
      ) {
        // Get the term-billings combinations for the selected termDurationToChange
        const termsByDuration = productToPurchase.terms.filter(
          t => t.duration == termDurationToChange.getKey
        );
        // Update the term duration to the new selected
        productToPurchase.duration = termDurationToChange;

        // Check if there aren't some combination existing in the values of terms
        if (
          !termsByDuration.some(
            t =>
              t.billingCycle.toLowerCase() == productToPurchase.billing.getKey
          )
        ) {
          // Take the first element as a sample of a valid term-billing combination
          // and then update the billing of the project
          const firstTerm = getTermByDuration(termsByDuration[0].duration);
          productToPurchase.billing = new SingleSelectType({
            key: firstTerm.billing,
            name: this.$t(firstTerm.i18n)
          });
        }
        // Update billingOptions for the selected termDurationToChange
        productToPurchase.billingOptions = termsByDuration.map(t => {
          const billingCycle = t.billingCycle.toLowerCase();
          return new SingleSelectType({
            key: billingCycle,
            name: this.$t(billingCycle)
          });
        });
        await this.updateProductPrices(productToPurchase);
        this.editProductFromCart(productToPurchase);
      },
      async changeQuantity(productToPurchase) {
        const min = productToPurchase.minimumQuantity;
        const max =
          productToPurchase.maximumQuantity > 300
            ? 300
            : productToPurchase.maximumQuantity;
        const input = document.getElementById(
          `sku-quantity-${productToPurchase.productId}-${productToPurchase.id}`
        ) as HTMLInputElement;
        if (input.value < min) input.value = min;
        else if (input.value > max) input.value = max;
        productToPurchase.quantity = parseInt(input.value);
        await this.updateProductPrices(productToPurchase);
        this.editProductFromCart(productToPurchase);
      },
      restrictionsQuantity(item) {
        const min = item.minimumQuantity;
        const max = item.maximumQuantity > 300 ? 300 : item.maximumQuantity;
        const input = document.getElementById(
          `sku-quantity-${item.productId}-${item.id}`
        ) as HTMLInputElement;
        if (input.value < min) input.value = min;
        else if (input.value > max) input.value = max;
        item.quantity = parseInt(input.value);
        item.totalPrice = item.unitPrice * parseInt(input.value);
      },
      async purchaseProducts() {
        this.launchNotification("info");
        try {
          interface LineItem {
            id: number;
            friendlyName: string;
            catalogItemId: string;
            productSku: string;
            quantity: number;
            termDuration?: string;
            billingCycle?: string;
          }
          for (const [index, product] of this.productsToPurchase.entries()) {
            const lineItem: LineItem = {
              id: index,
              friendlyName: product.friendlyName,
              catalogItemId: product.catalogItemId,
              productSku: product.id,
              quantity: product.quantity,
              termDuration: product.duration.key,
              billingCycle: product.billing.key
            };
            this.lineItems.push(lineItem);
          }
          const cartId = await this.createCSPCart({
            tenantId: this.tenantId,
            body: { lineItems: this.lineItems }
          });

          await this.purchaseCSPOrder({
            tenantId: this.tenantId,
            cartId: cartId
          });
          this.launchNotification("success");

          this.purchaseInfo = getPurchaseInfo(
            this.getUser.language,
            this.getUser.getUsername,
            this.getProjectById(this.$route.params.projectId).name,
            this.tenantId,
            await this.getPurchaseReceipt()
          );

          this.postMail(this.getUser.language, this.getUser.username);
          if (config.prodEnv) {
            const pocs = this.getPOCsByProjectId(this.$route.params.projectId)
              .filter(p => p.getUsername != this.getUser.username)
              .map(p => p.getUsername);
            pocs.forEach(poc => {
              const language = this.getPeopleByUsername(poc).language;
              this.postMail(language, poc);
            });
          }

          await this.postEventDx(
            new EventDx({
              name: this.$tc(
                getOperationType("purchaseReceipt").i18n.subject,
                1
              ),
              description: this.generatePurchaseDescriptionHtml(),
              amount: 0,
              createdDate: new Date(),
              confirmed: false,
              reported: false,
              eventType:
                eventTypes[projectTypes.CSP.name].events.cspOperation.type,
              projectId: this.$route.params.projectId,
              baseline: {
                version: this.getLastProjectVersionDx(
                  this.$route.params.projectId
                ).getVersionId,
                status: eventStates.occurred.name,
                startedDate: new Date(),
                endDate: null,
                affecteds: [],
                predecessors: []
              },
              occurred: true,
              author: this.getUser.getUsername,
              notificationsPolicy: notifications.notificationsPolicies.none.name
            })
          );

          this.$root.$emit("emptyCart");
          this.lineItems = [];
          this.resetValues();
        } catch (error) {
          this.launchNotification("error");
          this.$log.error(error);
        }
      },
      launchNotification(type: string) {
        const alertMessage = {
          text:
            type == "success"
              ? this.$t("cspAlertSuccess")
              : type == "error"
              ? this.$t("cspAlertError")
              : this.$t("cspAlertInfo"),
          time: type == "info" ? 30 : 7,
          type: type
        };
        this.setMessage(alertMessage);
      },
      postMail(lang: string, username: string): Promise<any> {
        const opType = "purchaseReceipt";
        const subject =
          "[CSP Operation] " +
          this.$t(getOperationType(opType).i18n.subject, lang);

        const body = emailBuilder.buildEmail(
          this.$t(getOperationType(opType).i18n.subject, lang),
          "",
          cspPurchaseEmailBody.build(
            this.getNewOperationText(lang, username),
            this.purchaseInfo.details,
            this.purchaseInfo.purchaseProducts,
            this.$t(getOperationType(opType).i18n.ending, lang, {
              link: config.redirectUri + this.$route.fullPath
            }),
            {
              name: this.$t("name", lang),
              quantity: this.$t("quantity", lang),
              price: this.$t("totalPrice", lang)
            },
            lang
          ),
          lang
        );

        return mailService.methods.postMailCore(
          new MailCore({
            to: username,
            subject: subject,
            body: body
          })
        );
      },
      generatePurchaseDescriptionHtml(): string {
        const descriptionDetails = Object.values(this.purchaseInfo.details)
          .map(
            (d: { name: string; value: string }) =>
              `<b>· ${d.name}:</b> ${d.value}.`
          )
          .join("<br>");

        let productsTable =
          '<table border="1" style="border: 1px solid #FFFFFF; width: 100%; font-size: 14px;">';

        productsTable += `<tr>
          <th style="text-align: left; width: 65%; padding-left: 5px">
          ${this.$t("name")}</th>
          <th style="text-align: center; width: 15%">${this.$t("quantity")}</th>
          <th style="text-align: center; width: 20%">${this.$t("price")}</th>
          </tr>`;

        productsTable += this.purchaseInfo.purchaseProducts
          .map(
            product =>
              `<tr><td style="text-align: left; width: 65%; padding-left: 5px">
                <span class="text-overflow-ellipsis text-overflow-nowrap">${product.name}</span></td>
                <td style="text-align: center; width: 15%">${product.quantity}</td>
                <td style="text-align: center; width: 20%">${product.totalPrice}</td></tr>`
          )
          .join();

        productsTable += "</table>";

        return descriptionDetails + productsTable;
      },
      getNewOperationText(lang: string, username: string): string {
        const userType = this.getUserType(username);
        if (userType == "csp" || userType == "poc") {
          return this.$t("newOperationByClient", lang, {
            client: this.getClients.find(
              e =>
                e.id ==
                this.getProjectById(this.$route.params.projectId).clientId
            ).getName
          });
        } else return this.$t("newOperation", lang);
      },
      getUserType(username: string): string {
        if (username == this.getUser.username) return "user";
        else if (username == config.cspMail) return "csp";
        else return "poc"; // Point of Contact
      },
      async getPurchaseReceipt() {
        const productsList = [];
        for (const product of this.cartProducts) {
          productsList.push({
            name: product.title,
            quantity: product.quantity,
            totalPrice: isNaN(product.totalPrice)
              ? "-"
              : product.totalPrice.toFixed(2)
          });
        }
        return productsList;
      },
      async setProductsToPurchase() {
        this.productsToPurchase = [];
        for (const product of this.cartProducts) {
          // It sets newProduct as a reference of a product of cartProducts
          // to set the values of the prop without using emits (because props are immutable)
          let newProduct = product;

          // Set the initial billing options for the initial term duration
          newProduct.billingOptions = newProduct.terms
            .filter(t => {
              return t.duration == newProduct.duration.key;
            })
            .map(t => {
              const billingCycle = t.billingCycle.toLowerCase();
              return new SingleSelectType({
                key: billingCycle,
                name: this.$t(getTermByBilling(billingCycle).i18n)
              });
            });

          this.updateProductPrices(newProduct);
          this.productsToPurchase.push(newProduct);
        }
      },
      editProductFromCart(product) {
        ++this.rerenderTable;
        this.$root.$emit("editFromCart", product);
      },
      removeProductFromCart(product) {
        this.$root.$emit("deleteFromCart", product);
      },
      async delay(ms: number) {
        await new Promise(resolve => setTimeout(resolve, ms));
      },
      resetValues() {
        this.productsToPurchase = [];
        this.lineItems = [];
      }
    },
    watch: {
      cartProducts: async function () {
        await this.setProductsToPurchase();
      }
    }
  };
