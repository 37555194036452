
























  export default {
    name: "UnderConstructionPage",
    data() {
      return {
        componentCreated: false,
        text: {
          underConstruction: this.$t("underConstructionTitle"),
          description: this.$t("underConstructionText")
        }
      };
    },
    created: async function () {
      await this.loadCatalog();
      await this.loadProjectsByClient(this.getUser.getClientId);
      await this.loadHiredServicesByClientId(this.getUser.getClientId);
      await this.loadActiveServicesByClientId(this.getUser.getClientId);
      this.componentCreated = true;
    }
  };
