












  import { servicesExtensions } from "../../../_helpers/servicesExtensions";
  export default {
    name: servicesExtensions.qualityEye.name.toLowerCase().replace(/\s+/g, ""),
    data() {
      return {
        loadedIFrame: false
      };
    },
    async mounted() {
      this.addLoadingComponent(this.$options.name);
      this.loadedIFrame = true;
      this.$emit("projectHeaderService", servicesExtensions.qualityEye.name);
      this.$emit(
        "projectHeaderExtension",
        servicesExtensions.defaultExtension.name
      );
      this.removeLoadingComponent(this.$options.name);
    }
  };
