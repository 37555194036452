




























































































































































































































































































































































  import {
    getTermByBilling,
    getTermByDuration,
    operationTypes,
    subscriptionStatus
  } from "../../../../_helpers/cspOperations";
  import PendingOperationCSP from "../../../../models/csp/PendingOperationCSP";
  import SubscriptionCSP from "../../../../models/csp/SubscriptionCSP";
  import SubscriptionData from "../../../../view-models/csp/SubscriptionData";
  import SingleSelectType from "../../../../view-models/SingleSelectType";
  import CapsuleLabelInput from "../../../view_elements/CapsuleLabelInput.vue";
  import CapsuleMultiselect from "../../../view_elements/CapsuleMultiselect.vue";

  export default {
    name: "SubscriptionRow",
    components: { CapsuleLabelInput, CapsuleMultiselect },
    props: {
      subscriptionId: String,
      tenantId: String
    },
    data() {
      return {
        operationTypes: operationTypes,
        cspSubscription: undefined as SubscriptionCSP, // Real Subscription values coming from CSP/Partner Center API
        pendingOperation: undefined as PendingOperationCSP, // Temporal pending operation subscription values
        subscription: undefined as SubscriptionData, // Subscription view model for display purposes taking values from CSP & Pending Subs
        showCollapse: false,
        subscriptionRowHovered: false,
        intervalAction: null,
        editMode: false,
        nickNameEdit: null,
        quantityLicencesEdit: 0 as number,
        autoRenewSwitch: undefined,
        termDurationEdit: null,
        billingFrequencyEdit: null,
        subscriptionStatus: subscriptionStatus,
        text: {
          subscription: this.$tc("subscription", 1),
          pendingCancellation: this.$t("cspStatusPendingCancellation"),
          billing: this.$t("billing"),
          cancelSubscription: this.$t("cancelSubscription"),
          duration: this.$tc("duration", 1),
          autorenewOn: this.$t("autorenewOn"),
          autorenew: this.$t("autorenew"),
          expiresOn: this.$t("expiresOn"),
          expires: this.$t("expires"),
          status: this.$t("status"),
          suspendedToKnowMore: this.$t("suspendedToKnowMore"),
          licence: this.$t("licence"),
          until: this.$t("until"),
          refundableUpper: this.$tc("refundable", 1),
          refundable: this.$tc("refundable", 2),
          enterName: this.$t("enterName"),
          triennial: this.$t("triennial"),
          annual: this.$t("annual"),
          monthly: this.$t("monthly"),
          na: this.$t("na")
        }
      };
    },
    async created() {
      await this.reloadSubscription();
      if (this.autoRenewPendingToDisabled) this.autoRenewSwitch = false;
      else this.autoRenewSwitch = this.subscription.autoRenewEnabled;
      this.$emit("subRowLoaded");
    },
    computed: {
      statusText(): string {
        if (this.subscriptionHasBeenCancelled)
          return this.text.pendingCancellation;
        else return this.$t(subscriptionStatus[this.subscription.status].i18n);
      },
      autoRenewText(): {
        statusColumn: string;
        details: string;
      } {
        const autoRenewEnabled =
          this.subscription.status == subscriptionStatus.active.key &&
          this.autoRenewSwitch;
        const autoRenewDate = this.$moment(this.subscription.commitmentEndDate)
          .add("1", "d")
          .format("L");
        return {
          statusColumn: `${
            autoRenewEnabled ? this.text.autorenewOn : this.text.expiresOn
          } ${autoRenewDate}`,
          details: `<b>${
            autoRenewEnabled ? this.text.autorenew : this.text.expires
          }:</b> ${autoRenewDate}`
        };
      },
      minimumLicences(): number {
        return (
          this.subscription.quantity -
          (this.subscription.refundableQuantity
            ? this.subscription.refundableQuantity.totalQuantity
            : 0)
        );
      },
      termDurationText(): string {
        return this.text[
          getTermByDuration(this.subscription.termDuration).i18n
        ];
      },
      billingFrequencyText(): string {
        return this.text[getTermByBilling(this.subscription.billingCycle).i18n];
      },
      termDurationIsEdited(): boolean {
        return this.pendingOperation && this.pendingOperation.termDuration;
      },
      billingFrequencyIsEdited(): boolean {
        return this.pendingOperation && this.pendingOperation.billingFrequency;
      },
      autoRenewPendingToDisabled(): boolean {
        return this.pendingOperation && this.pendingOperation.disableAutorenew;
      },
      subscriptionHasBeenCancelled(): boolean {
        return this.pendingOperation && this.pendingOperation.cancelSub;
      },
      subscriptionsQuantity(): number {
        if (
          this.subscription.refundableQuantity &&
          this.subscriptionHasBeenCancelled
        ) {
          return this.minimumLicences;
        }
        return this.subscription.quantity;
      },
      showRefundables(): boolean {
        return (
          this.subscription.refundableQuantity &&
          this.subscription.refundableQuantity.totalQuantity &&
          !this.isSubscriptionDisabled
        );
      },
      isSubscriptionDisabled(): boolean {
        return (
          this.subscription.status == this.subscriptionStatus.disabled.key ||
          this.subscription.status == this.subscriptionStatus.suspended.key ||
          this.subscriptionHasBeenCancelled
        );
      },
      availableTermDurations(): any[] {
        let options = [];
        if (this.subscription && this.cspSubscription.getTerms) {
          options = [
            ...new Set(this.cspSubscription.getTerms.map(t => t.duration))
          ].map((duration: string) =>
            this.getSingleSelectTypeDuration(duration)
          );
        }
        return options;
      },
      availableBillingCyclesOptions(): any[] {
        let options = [];
        if (this.termDurationEdit != null && this.cspSubscription.getTerms) {
          // Get the term-billings combinations for the subscription
          const termsByDuration = this.cspSubscription.getTerms.filter(
            t => t.duration == this.termDurationEdit.getKey
          );

          // Warning error when some term does not have its billing cycle combination.
          // This will cause following code to break and therefore edit mode will not be opened.
          if (termsByDuration.some(o => !o.hasOwnProperty("billingCycle"))) {
            this.$emit("errorNotification");
          }

          // Update billingOptions for the susbscription
          options = termsByDuration.map(t => {
            const billingCycle = t.billingCycle.toLowerCase();
            return new SingleSelectType({
              key: billingCycle,
              name: this.$t(billingCycle)
            });
          });
        }
        return options;
      }
    },
    methods: {
      setSubscriptionValues() {
        const sub = this.cspSubscription;
        const pending = this.pendingOperation;
        let termDuration = sub.termDuration;
        let billingCycle = sub.billingCycle;
        let autoRenewEnabled = sub.autoRenewEnabled;

        if (pending) {
          if (pending.termDuration) termDuration = pending.termDuration;
          if (pending.billingFrequency) billingCycle = pending.billingFrequency;
          if (pending.disableAutorenew) autoRenewEnabled = false;
        }

        this.subscription = new SubscriptionData({
          id: sub.id,
          offerName: sub.offerName,
          friendlyName: sub.friendlyName,
          termDuration: termDuration,
          billingCycle: billingCycle,
          billingCycleEndDate: sub.billingCycleEndDate,
          commitmentEndDate: sub.commitmentEndDate,
          autoRenewEnabled: autoRenewEnabled,
          quantity: sub.quantity,
          refundableQuantity: sub.refundableQuantity,
          status: sub.status
        });
      },
      async checkSubscriptionPendingOperations() {
        this.loadPendingOperationCSPBySubscriptionId(this.subscriptionId);
        this.pendingOperation = this.getPendingOperationCSPBySubscriptionId(
          this.subscriptionId
        );

        if (this.pendingOperation) {
          let updated = false;

          // Checking if actual Partner Center CSP subscription match the pending values to reset them
          if (
            this.pendingOperation.getCancelSub &&
            this.cspSubscription.getStatus ==
              this.subscriptionStatus.cancelled.key
          ) {
            this.pendingOperation.setCancelSub = false;
            updated = true;
          }

          if (
            this.pendingOperation.getDisableAutorenew &&
            !this.cspSubscription.getAutoRenewEnabled
          ) {
            this.pendingOperation.setDisableAutorenew = false;
            updated = true;
          }

          if (
            this.pendingOperation.getTermDuration ===
            this.cspSubscription.getTermDuration
          ) {
            this.pendingOperation.setTermDuration = null;
            updated = true;
          }

          if (
            this.pendingOperation.getBillingFrequency ===
            this.cspSubscription.getBillingCycle
          ) {
            this.pendingOperation.setBillingFrequency = null;
            updated = true;
          }

          if (updated) {
            // Back End will check nulls. If all -> DELETE; else -> UPDATE; then RETURN to Vuex store
            await this.putPendingOperationCSP(this.pendingOperation);
            this.pendingOperation = this.getPendingOperationCSPBySubscriptionId(
              this.subscriptionId
            );
          }
        }
      },
      openEditMode() {
        this.showCollapse = true;
        this.editMode = true;
        this.nickNameEdit = this.subscription.friendlyName;
        this.quantityLicencesEdit = this.subscription.quantity;
        this.termDurationEdit = this.getSingleSelectTypeDuration(
          this.subscription.termDuration
        );
        this.billingFrequencyEdit = this.getSingleSelectTypeCycle(
          this.subscription.billingCycle
        );
      },
      // Method that sends the parameters needed to update subscription in parent component after modal confirmation,
      // IMPORTANT: send always autoRenewEnabled property because it is setted to false if it is not send!!!!
      editSubscription(operationType: string, values: Object) {
        const subOperationData = {
          customerId: this.tenantId,
          subscriptionId: this.subscriptionId,
          operationType: operationType,
          reqBody: values
        };

        if (
          operationType == operationTypes.increment.key ||
          operationType == operationTypes.decrement.key
        ) {
          subOperationData["variation"] = Math.abs(
            this.quantityLicencesEdit - this.subscription.quantity
          );
        }
        this.$root.$emit("confirmCSPOperation", subOperationData);
      },
      editSubscriptionAutorenew(values: Object) {
        this.$root.$emit("confirmCSPOperation", {
          customerId: this.tenantId,
          subscriptionId: this.subscriptionId,
          operationType: this.autoRenewSwitch
            ? operationTypes.autoRenewEnable.key
            : operationTypes.autoRenewDisable.key,
          date: this.subscription.billingCycleEndDate,
          reqBody: values
        });
      },
      async cancelSubscription() {
        this.$root.$emit("confirmCSPOperation", {
          customerId: this.tenantId,
          subscriptionId: this.subscriptionId,
          operationType: operationTypes.cancellation.key,
          quantity: this.subscription.quantity,
          refundables: this.subscription.refundableQuantity
            ? this.subscription.refundableQuantity.totalQuantity
            : 0,
          autoRenewEnabled: this.subscription.autoRenewEnabled
        });
      },
      resetSubscriptionsValues() {
        this.autoRenewSwitch = this.subscription.autoRenewEnabled;
        this.termDurationEdit = this.getSingleSelectTypeDuration(
          this.subscription.termDuration
        );
        this.billingFrequencyEdit = this.getSingleSelectTypeCycle(
          this.subscription.billingCycle
        );
      },
      async reloadSubscription() {
        await this.loadSubscriptionCSPByTenantIdAndSubscriptionId({
          tenantId: this.tenantId,
          subscriptionId: this.subscriptionId
        });
        this.cspSubscription = this.getSubscriptionCSPById(this.subscriptionId);

        await this.loadSubscriptionCSPAvailabilities({
          subscriptionId: this.subscriptionId,
          endpoint: this.cspSubscription.getLinks.availability.uri
        });

        // Reloading this subscription pending operations, if had any, and checking if still pending
        await this.checkSubscriptionPendingOperations();

        this.setSubscriptionValues();
      },
      decrementLicences() {
        if (this.subscription.refundableQuantity) {
          if (this.quantityLicencesEdit > this.minimumLicences)
            this.quantityLicencesEdit -= 1;
        } else if (this.quantityLicencesEdit > this.subscription.quantity)
          this.quantityLicencesEdit -= 1;
      },
      async finishSubscriptionEdit() {
        this.editMode = false;
        await this.reloadSubscription();
      },
      getSingleSelectTypeDuration(duration: string): SingleSelectType {
        const option = getTermByDuration(duration);
        return new SingleSelectType({
          key: option.duration,
          name: this.$t(option.i18n)
        });
      },
      getSingleSelectTypeCycle(billing: string): SingleSelectType {
        const option = getTermByBilling(billing);
        return new SingleSelectType({
          key: option.billing,
          name: this.$t(option.i18n)
        });
      }
    },
    watch: {
      subscriptionId: async function () {
        await this.reloadSubscription();
      }
    }
  };
