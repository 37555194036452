import { render, staticRenderFns } from "./ProjectCardMedia.vue?vue&type=template&id=4ac28189&scoped=true&"
import script from "./ProjectCardMedia.vue?vue&type=script&lang=ts&"
export * from "./ProjectCardMedia.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectCardMedia.vue?vue&type=style&index=0&id=4ac28189&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ac28189",
  null
  
)

export default component.exports