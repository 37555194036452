import utils from "../../utils/utils";
import MethodologyDx from "./MethodologyDx";

export interface IDocumentDx {
  id?: string;
  name: string;
  url?: string;
  author: string;
  creationDate?: Date;
  lastModifiedDate?: Date;
  projectId: number;
  people?: string[];
  stageId?: string;
  eventId?: string;
  requestId?: string;
  isLink?: boolean;
  methodology?: MethodologyDx;
}

export default class DocumentDx {
  private id?: string;
  private name: string;
  private url?: string;
  private author: string;
  private creationDate: Date;
  private lastModifiedDate: Date;
  private projectId: number;
  private people?: string[];
  private stageId?: string;
  private eventId?: string;
  private requestId?: string;
  private isLink?: boolean;
  private methodology?: MethodologyDx;

  constructor(dto: IDocumentDx) {
    Object.assign(this, dto);
    if (!dto.id) {
      this.id = utils.newGUID();
      if (!this.isLink) this.url = this.id;
    }
    if (!dto.creationDate) {
      this.creationDate = new Date();
    }
  }

  getBaseFilename(): string {
    /**
     * Gets base filename from a possible copy "filename (X).ext", where X=copy number.
     *
     * If match, captures "filename" and the extension ".ext", then returns "filename.ext".
     * Else, returns the filename as it is originally ("filename.ext").
     *
     * abc (1).txt -> abc.txt
     * abc.txt -> abc.txt
     */
    const match = this.name.match(/(.+) \(\d+\)(\..+)/);
    return match ? match[1] + match[2] : this.name;
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }

  get getName(): string {
    return this.name;
  }

  set setName(value: string) {
    this.name = value;
  }

  get getUrl(): string {
    return this.url;
  }

  set setUrl(value: string) {
    this.url = value;
  }

  get getAuthor(): string {
    return this.author;
  }

  set setAuthor(value: string) {
    this.author = value;
  }

  get getCreationDate(): Date {
    return this.creationDate;
  }

  set setCreationDate(value: Date) {
    this.creationDate = value;
  }

  get getLastModifiedDate(): Date {
    return this.lastModifiedDate;
  }

  set setLastModifiedDate(value: Date) {
    this.lastModifiedDate = value;
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(value: number) {
    this.projectId = value;
  }

  get getPeople(): string[] {
    return this.people;
  }

  set setPeople(value: string[]) {
    this.people = value;
  }

  get getStageId(): string {
    return this.stageId;
  }

  set setStageId(value: string) {
    this.stageId = value;
  }

  get getEventId(): string {
    return this.eventId;
  }

  set setEventId(value: string) {
    this.eventId = value;
  }

  get getRequestId(): string {
    return this.requestId;
  }

  set setRequestId(value: string) {
    this.requestId = value;
  }

  get isExternalLink(): boolean {
    return this.isLink;
  }

  set setExternalLink(value: boolean) {
    this.isLink = value;
  }

  get getMethodology(): MethodologyDx {
    return this.methodology;
  }

  set setMethodology(value: MethodologyDx) {
    this.methodology = value;
  }
}
