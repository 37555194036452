











































































  import moment from "moment";
  import { autoscroll } from "vue-autoscroll";
  import { dragscroll } from "vue-dragscroll";
  import ProjectCore from "../../../../models/core/ProjectCore";
  import EventDx from "../../../../models/deliveryexperience/EventDx";
  import ReadWrite from "../../../../models/deliveryexperience/Permissions/ReadWrite";
  import RequestDx from "../../../../models/deliveryexperience/RequestDx";
  import StageDx from "../../../../models/deliveryexperience/StageDx";
  import TimelineItem from "./TimelineItem.vue";

  export default {
    name: "TimelineCarousel",
    directives: {
      dragscroll,
      autoscroll
    },
    components: {
      TimelineItem
    },
    props: {
      comeFrom: String,
      projectCore: ProjectCore,
      versionDx: Number,
      eventsDx: Array,
      stagesDx: Array,
      requestsDx: Array,
      showStages: Boolean,
      filterTags: []
    },
    data() {
      return {
        hover: "hover",
        loadedData: Boolean(false),
        currentDate: new Date(),
        orderedStages: [],
        chartScroll: 0,
        ignoreClicks: false,
        selected: undefined,
        text: {
          noItems: this.$t("noItems"),
          newEvent: this.$t("newEvent"),
          newStage: this.$t("newStage")
        }
      };
    },
    computed: {
      permissionsRequests(): ReadWrite {
        return this.getUserPermissions.requests;
      },
      permissionsEvents(): ReadWrite {
        return this.getUserPermissions.events;
      },
      permissionsStages(): ReadWrite {
        return this.getUserPermissions.stages;
      },
      todayItemIndex(): number {
        const that = this;
        const today = new Date();
        let recentCard;
        this.orderedTimelineItems.forEach(item => {
          if (item.hasOwnProperty("date")) {
            if (moment(today).isAfter(item.date)) recentCard = item;
          } else {
            if (
              moment(today).isAfter(
                item.getBaselineByVersion(that.versionDx).startedDate
              )
            )
              recentCard = item;
          }
        });
        const index = this.orderedTimelineItems
          .map(function (x) {
            return x.id;
          })
          .indexOf(recentCard.id);
        return index;
      },
      orderedTimelineItems(): (EventDx | RequestDx | StageDx)[] {
        let list = [];
        this.eventsDx.forEach(event => {
          if (this.filterTags.length) {
            for (const tag of this.filterTags) {
              if (event.getTag == tag) {
                list.push(event);
              }
            }
          } else list.push(event);
        });
        this.requestsDx.forEach(request => {
          if (this.filterTags.length) {
            for (const tag of this.filterTags) {
              if (request.getTag == tag) {
                list.push(request);
              }
            }
          } else list.push(request);
        });
        if (this.showStages) {
          this.stagesDx.forEach(stage => {
            if (this.filterTags.length) {
              for (const tag of this.filterTags) {
                if (stage.getTag == tag) {
                  list.push(stage);
                }
              }
            } else list.push(stage);
          });
        }
        const that = this;
        list.sort(function (a, b) {
          let dateA;
          let dateB;
          if (a.hasOwnProperty("date")) dateA = new Date(a.date);
          else
            dateA = new Date(
              a.getBaselineByVersion(that.versionDx).startedDate
            );
          if (b.hasOwnProperty("date")) dateB = new Date(b.date);
          else
            dateB = new Date(
              b.getBaselineByVersion(that.versionDx).startedDate
            );
          return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
        });
        return list;
      }
    },
    async mounted() {
      this.loadedData = true;
      if (
        this.orderedTimelineItems.length &&
        this.chartScroll === 0 &&
        this.selected == undefined
      ) {
        this.showToday();
      }
      const that = this;
      const inputElem = document.querySelectorAll(".timeline-item-info");
      inputElem.forEach(element => {
        element.addEventListener(
          "click",
          function (event) {
            if (that.ignoreClicks) event.stopPropagation();
          },
          true
        );
      });
    },
    methods: {
      showToday: function () {
        this.scrollByItemId(this.getIdByDate(new Date()));
      },
      getStageEventRequestType: function (id): string {
        let type;
        this.stagesDx.forEach(stage => {
          if (stage.id == id) type = "stage";
        });
        this.requestsDx.forEach(request => {
          if (request.id == id) type = "request";
        });
        this.eventsDx.forEach(event => {
          if (event.id == id) type = "event";
        });
        return type;
      },
      getIdByDate(date: Date): any {
        const that = this;
        let recentCard;
        this.orderedTimelineItems.forEach(item => {
          if (item.hasOwnProperty("date")) {
            if (moment(date).isAfter(item.date)) recentCard = item;
          } else {
            if (
              moment(date).isAfter(
                item.getBaselineByVersion(that.versionDx).startedDate
              )
            )
              recentCard = item;
          }
        });
        return recentCard.id;
      },
      getIndexById(id): number {
        const index = this.orderedTimelineItems
          .map(function (x) {
            return x.id;
          })
          .indexOf(id);
        return index;
      },
      scrollByItemId(id: number) {
        const offset = 400;
        const cardSize = 410;
        const toleranceOffset = 10;
        const timelineContainer = document.getElementById(
          "timeline-external-ctn"
        );
        const index = this.getIndexById(id) - 1;
        const scrollToCard = index * cardSize;
        const cardAlreadyVisible =
          timelineContainer.scrollLeft > scrollToCard + offset ||
          timelineContainer.scrollLeft < scrollToCard - offset;
        if (index <= 0) this.chartScroll = 0;
        else if (this.chartScroll == scrollToCard && cardAlreadyVisible)
          this.chartScroll = scrollToCard + toleranceOffset;
        else if (cardAlreadyVisible) this.chartScroll = scrollToCard;
      },
      selectItem(id: string, type: string) {
        let eventId, stageId, requestId;
        this.selected = id;
        const timelineContainer = document.getElementById(
          "timeline-external-ctn"
        );
        this.chartScroll = timelineContainer.scrollLeft;
        switch (type) {
          case "event":
            eventId = id;
            break;
          case "request":
            requestId = id;
            break;
          case "stage":
            stageId = id;
            break;
        }
        this.$root.$emit("emitStageId", stageId);
        this.$root.$emit("emitRequestId", requestId);
        this.$root.$emit("emitEventId", eventId);
        this.scrollByItemId(id);
        this.$emit("timelineItemSelectionChanged", {
          id: id,
          type: this.getStageEventRequestType(id)
        });
      },
      deselectItem(event) {
        // to prevent the trigger of card click when clicking on the x button
        if (event) event.stopPropagation();
        this.selected = undefined;
        this.$root.$emit("emitRequestId", undefined);
        this.$root.$emit("emitEventId", undefined);
        this.$root.$emit("emitStageId", undefined);
        this.$emit("timelineItemSelectionChanged", {
          id: undefined,
          type: undefined
        });
      },
      timeoutClick() {
        setTimeout(() => {
          this.ignoreClicks = false;
        }, 100);
      },
      openTimelineItemForm(type) {
        this.$emit("openTimelineItemForm", type);
      }
    },
    watch: {
      /**
       * This watcher should be triggered specially when the items are filtered
       */
      orderedTimelineItems: function () {
        this.showToday(); // Go back to today's date scroll position
        this.deselectItem(); // Close previous selected item, if any
      }
    }
  };
