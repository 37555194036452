
























  export default {
    name: "ErrorPage",
    props: {
      code: {
        type: String,
        default: "404"
      }
    },
    data() {
      return {
        componentCreated: false,
        error:
          Number(this.code) == 404
            ? "404"
            : Number(this.code) == 403
            ? "403"
            : Number(this.code) == 500
            ? "500"
            : "Error",
        text: {
          title:
            Number(this.code) == 404
              ? this.$t("error404title")
              : Number(this.code) == 403
              ? this.$t("error403title")
              : Number(this.code) == 500
              ? this.$t("error500title")
              : this.$t("errorTitle"),
          description:
            Number(this.code) == 404
              ? this.$t("error404text")
              : Number(this.code) == 403
              ? this.$t("error403text")
              : Number(this.code) == 500
              ? this.$t("error500text")
              : this.$t("errorText")
        }
      };
    },
    created: async function () {
      await this.loadCatalog();
      await this.loadProjectsByClient(this.getUser.getClientId);
      await this.loadHiredServicesByClientId(this.getUser.getClientId);
      await this.loadActiveServicesByClientId(this.getUser.getClientId);
      this.componentCreated = true;
    }
  };
