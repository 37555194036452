import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import helpers from "../../../_helpers/helperFunctions";
import authService from "../../../authentication/AuthServiceInst";
import PeopleCore from "../../../models/core/PeopleCore";
import ProjectPeopleCore from "../../../models/core/project/ProjectPeopleCore";
import peopleService from "../../../services/core/peopleService";
import { RootState } from "../../index";

const namespaced = true;

interface PeopleState {
  user: PeopleCore;
  people: PeopleCore[];
  projectPeople: ProjectPeopleCore[];
}

const state: PeopleState = {
  user: null,
  people: [],
  projectPeople: []
};

const getters: GetterTree<PeopleState, RootState> = {
  getUser: (state): PeopleCore => {
    return state.user;
  },
  getPeople: (state): PeopleCore[] => {
    return state.people.sort((a, b) =>
      a.getLastName.localeCompare(b.getLastName)
    );
  },
  getPeopleByUsernamesList:
    state =>
    (usernames: string[]): PeopleCore[] => {
      return state.people
        .filter(person => usernames.includes(person.getUsername))
        .sort((a, b) => a.getLastName.localeCompare(b.getLastName));
    },
  getProjectPeople: (state): ProjectPeopleCore[] => {
    return state.projectPeople;
  },
  getProjectPeopleByUsername:
    state =>
    (username: string): ProjectPeopleCore[] => {
      return state.projectPeople.filter(
        projectPeople => projectPeople.getUsername == username
      );
    },
  getPeopleByUsername:
    state =>
    (username: string): PeopleCore => {
      let personCore = state.people.find(
        person => person.getUsername == username
      );
      if (personCore === undefined) {
        personCore = helpers.getExternalUser(username);
      }
      return personCore;
    },
  getAvailablePeople:
    state =>
    (loggedClientId: number, exceptPeople: string[]): PeopleCore[] => {
      // Client ID 1 == Sogeti Client.
      // We allow Sogeti users to add every user to the project team
      // while clients can only add people with the same clientId,
      // while we exclude those people that were already added in the project
      return state.people
        .filter(
          person =>
            ((loggedClientId != 1 && person.getClientId == loggedClientId) ||
              loggedClientId == 1) &&
            !exceptPeople.includes(person.getUsername)
        )
        .sort((a, b) => a.getLastName.localeCompare(b.getLastName));
    },
  getAvailablePeopleByClientIdAndProject:
    state =>
    (loggedClientId: number, exceptPeople: string[] = []): PeopleCore[] => {
      // Client ID 1 == Sogeti Client.
      // Gets all PeopleCore of the client of logged user and Sogeti client as well,
      // while substracting those that exists in the project people, if passed any as parameter.
      return state.people
        .filter(
          person =>
            (person.getClientId == loggedClientId || person.isSogeti()) &&
            !exceptPeople.includes(person.getUsername)
        )
        .sort((a, b) => a.getLastName.localeCompare(b.getLastName));
    }
};

const mutations: MutationTree<PeopleState> = {
  saveUser(state, user: PeopleCore) {
    state.user = user;
  },
  setPeople(state, people: PeopleCore[]) {
    state.people = people;
  },
  addPerson(state, newPerson: PeopleCore) {
    state.people.push(newPerson);
  },
  setProjectsByPerson(state, projectPeople: ProjectPeopleCore[]) {
    state.projectPeople = projectPeople;
  },
  addPersonInProject(state, newPersonInProject: ProjectPeopleCore) {
    state.projectPeople.push(newPersonInProject);
  },
  updatePerson(state, updatedPerson: PeopleCore) {
    const index = state.people.findIndex(
      p => p.getUsername === updatedPerson.getUsername
    );
    if (index != -1) {
      state.people.splice(index, 1, updatedPerson);
    }
  },
  deletePerson(state, username: string) {
    const index = state.people.findIndex(
      people => people.getUsername == username
    );
    state.people.splice(index, 1);
  },
  deletePersonFromProject(state, personInProjectToDelete: ProjectPeopleCore) {
    const index = state.projectPeople.findIndex(
      projectPeople =>
        projectPeople.getProjectId == personInProjectToDelete.getProjectId &&
        projectPeople.getUsername == personInProjectToDelete.getUsername
    );
    state.projectPeople.splice(index, 1);
  },
  logOut(state) {
    state.user = null;
  }
};

const actions: ActionTree<PeopleState, RootState> = {
  async loadPeople({ commit }) {
    const people: PeopleCore[] = await peopleService.methods.getPeople();
    commit("setPeople", people);
  },
  async loadPeoplesCoreByClientIdAndSogeti(
    { commit },
    clientId: number
  ): Promise<any> {
    let peoples: PeopleCore[] = [];
    if (clientId != 1) {
      //If client is different of Sogeti
      peoples = await peopleService.methods.getPeopleByClientId(clientId);
    }
    const peoplesSogeti: PeopleCore[] =
      await peopleService.methods.getPeopleByClientId(1);
    commit("setPeople", peoples.concat(peoplesSogeti));
  },
  async loadProjectsPeopleCoreByUsername(
    { commit },
    username: string
  ): Promise<any> {
    const projects: ProjectPeopleCore[] =
      await peopleService.methods.getProjectsByUsername(username);
    commit("setProjectsByPerson", projects);
  },
  async postPeople({ commit }, person: PeopleCore) {
    const newPerson: PeopleCore = await peopleService.methods.postPeople(
      person
    );
    commit("addPerson", newPerson);
  },
  async postProjectPeople({ commit }, projectPeopleCore: ProjectPeopleCore) {
    const newProjectPeople: ProjectPeopleCore =
      await peopleService.methods.postProjectPeople(projectPeopleCore);
    commit("addPersonInProject", newProjectPeople);
  },
  async putPeople({ commit }, person: PeopleCore) {
    const updatedPerson: PeopleCore = await peopleService.methods.putPeople(
      person
    );
    commit("updatePerson", updatedPerson);
  },
  async deletePeople({ commit }, username: string) {
    await peopleService.methods.deletePeople(username);
    commit("deletePerson", username);
  },
  async deleteProjectPeople({ commit }, projectPeopleCore: ProjectPeopleCore) {
    await peopleService.methods.deleteProjectPeople(projectPeopleCore);
    commit("deletePersonFromProject", projectPeopleCore);
  },
  async logOut({ commit }) {
    await authService.get().signOut();
    commit("logOut");
  },
  async loadUser({ commit }, username: string) {
    const user: PeopleCore = await peopleService.methods.getUser(username);
    commit("saveUser", user);
  }
};

export const peopleModuleCore: Module<PeopleState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
