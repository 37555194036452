import { IBaseLineDx } from "../../models/deliveryexperience/BaseLineDx";
import StageDx from "../../models/deliveryexperience/StageDx";
import service from "../service";
import baseEndpoint from "./serviceDx";

const endpoint = baseEndpoint + "/Stage";

export default {
  components: { service: service },
  methods: {
    async getStages(): Promise<StageDx[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(stage => new StageDx(stage));
    },

    async getStageData(id: string): Promise<StageDx> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new StageDx(response);
    },

    async putStage(modifiedStage: StageDx): Promise<StageDx> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${modifiedStage.getId}`,
        modifiedStage
      );
      return new StageDx(response);
    },
    async putBaseline(data: {
      stageId: string;
      baseline: IBaseLineDx;
    }): Promise<StageDx> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${data.stageId}/update-baseline`,
        data.baseline
      );
      return new StageDx(response);
    },
    async postBaseline(data: {
      stageId: string;
      baseline: IBaseLineDx;
    }): Promise<StageDx> {
      const response = await service.methods.postEndpointAsync(
        `${endpoint}/${data.stageId}/add-baseline`,
        data.baseline
      );
      return new StageDx(response);
    },
    async postStage(stage: StageDx): Promise<StageDx> {
      const response = await service.methods.postEndpointAsync(endpoint, stage);

      return new StageDx(response);
    },
    async deleteStage(id: string): Promise<any> {
      return await service.methods.deleteEndpointAsync(`${endpoint}/${id}`);
    },
    async getStagesByProjectId(projectId: number): Promise<StageDx[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/project/${projectId}`
      );
      return response.map(stage => new StageDx(stage));
    }
  }
};
