import ReadWrite from "./ReadWrite";

export interface IPermissions {
  bookmarks: ReadWrite;
  requests: ReadWrite;
  events: ReadWrite;
  stages: ReadWrite;
  docs: ReadWrite;
  people: ReadWrite;
  planner: ReadWrite;
  csp: ReadWrite;
}
export default class Permissions {
  private bookmarks: ReadWrite;
  private requests: ReadWrite;
  private events: ReadWrite;
  private stages: ReadWrite;
  private docs: ReadWrite;
  private people: ReadWrite;
  private planner: ReadWrite;
  private csp: ReadWrite;

  constructor(dto?: IPermissions, defaultWrite: boolean = false) {
    if (dto) {
      Object.assign(this, dto);
    } else {
      const readWrite = new ReadWrite({ read: true, write: defaultWrite });
      this.bookmarks = readWrite;
      this.requests = readWrite;
      this.events = readWrite;
      this.stages = readWrite;
      this.docs = readWrite;
      this.people = readWrite;
      this.planner = readWrite;
      this.csp = readWrite;
    }
  }

  get getBookmarks(): ReadWrite {
    return this.bookmarks;
  }
  set setBookmarks(value: ReadWrite) {
    this.bookmarks = value;
  }

  get getRequests(): ReadWrite {
    return this.requests;
  }
  set setRequests(value: ReadWrite) {
    this.requests = value;
  }

  get getEvents(): ReadWrite {
    return this.events;
  }
  set setEvents(value: ReadWrite) {
    this.events = value;
  }

  get getStages(): ReadWrite {
    return this.stages;
  }
  set setStages(value: ReadWrite) {
    this.stages = value;
  }

  get getDocs(): ReadWrite {
    return this.docs;
  }
  set setDocs(value: ReadWrite) {
    this.docs = value;
  }

  get getPeople(): ReadWrite {
    return this.people;
  }
  set setPeople(value: ReadWrite) {
    this.people = value;
  }

  get getPlanner(): ReadWrite {
    return this.planner;
  }
  set setPlanner(value: ReadWrite) {
    this.planner = value;
  }

  get getCSP(): ReadWrite {
    return this.csp;
  }

  set setCSP(value: ReadWrite) {
    this.csp = value;
  }
}
