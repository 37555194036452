import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import analyticsService from "../../../services/spiderlinkschecker/analyticsService";
import AnalysisResult from "../../../view-models/spiderlinkschecker/AnalysisResult";
import { RootState } from "../../index";

const namespaced = true;

interface IAnalyzeState {
  analysisResults: AnalysisResult[];
}

const state: IAnalyzeState = {
  analysisResults: []
};

const mutations: MutationTree<IAnalyzeState> = {
  addAnalysis(state, analysis: AnalysisResult): void {
    state.analysisResults.push(analysis);
  }
};

const getters: GetterTree<IAnalyzeState, RootState> = {
  getLastAnalysisResult: (state): AnalysisResult => {
    return state.analysisResults.at(-1);
  }
};

const actions: ActionTree<IAnalyzeState, RootState> = {
  async loadLastAnalysis({ commit }, queryOptions: any): Promise<any> {
    const queryResult: object = await analyticsService.methods.getLast(
      queryOptions.projectid
    );
    const analysisResult = new AnalysisResult(queryResult);
    commit("addAnalysis", analysisResult);
  },
  async loadPreviousAnalysis({ commit }, queryOptions: any): Promise<any> {
    const queryResult: object = await analyticsService.methods.getPrevious(
      queryOptions.projectid,
      queryOptions.currentid
    );
    const analysisResult = new AnalysisResult(queryResult);
    commit("addAnalysis", analysisResult);
  },
  async loadNextAnalysis({ commit }, queryOptions: any): Promise<any> {
    const queryResult: object = await analyticsService.methods.getNext(
      queryOptions.projectid,
      queryOptions.currentid
    );
    const analysisResult = new AnalysisResult(queryResult);
    commit("addAnalysis", analysisResult);
  },
  async postAnalysis({ commit }, body: any): Promise<any> {
    const queryResult: object = await analyticsService.methods.postScrap(body);
    const analysisResult = new AnalysisResult(queryResult);
    commit("addAnalysis", analysisResult);
  }
};

export const analysisModuleSLC: Module<IAnalyzeState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
