







































  import ProjectRow from "./ProjectRow.vue";

  export default {
    name: "ProjectsList",
    components: { ProjectRow },
    props: {
      projects: Array, // List of project core
      state: String // project filter state (active, closed, deleted)
    },
    data() {
      return {
        text: {
          name: this.$t("name"),
          mainContacts: this.$t("mainContacts"),
          client: this.$tc("client", 1),
          startDate: this.$t("startDate"),
          endDate: this.$t("endDate"),
          closingDate: this.$t("closingDate"),
          projectType: this.$t("projectType"),
          thereAreNoProjects: this.$t("thereAreNoProjects")
        }
      };
    }
  };
