import { getModelProjectType } from "../../_helpers/projectMetadata";
import ProjectCore from "../../models/core/ProjectCore";
import ProjectServiceCore from "../../models/core/project/ProjectServiceCore";
import ProjectStateCore from "../../models/core/project/ProjectStateCore";
import service from "../service";

import baseEndpoint from "./serviceCore";

const endpoint = baseEndpoint + "/Projects";
const endpointProjectState = baseEndpoint + "/ProjectState";

export default {
  methods: {
    async postProject(project: ProjectCore): Promise<ProjectCore> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        project
      );
      return getModelProjectType(response);
    },
    async putProject(modifiedProject: ProjectCore): Promise<ProjectCore> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${modifiedProject.getId}`,
        modifiedProject
      );
      return getModelProjectType(response);
    },
    async getProjects(): Promise<ProjectCore[]> {
      const response = await service.methods.getEndpointAsync(`${endpoint}`);
      return response.map(project => getModelProjectType(project));
    },
    async getProject(projectId: number): Promise<ProjectCore> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${projectId}`
      );
      return getModelProjectType(response);
    },
    async postProjectState(
      projectState: ProjectStateCore
    ): Promise<ProjectStateCore> {
      const response = await service.methods.postEndpointAsync(
        endpointProjectState,
        projectState
      );
      return new ProjectStateCore(response);
    },
    async deleteProject(projectId: number): Promise<any> {
      return await service.methods.deleteEndpointAsync(
        `${endpoint}/${projectId}`
      );
    },
    async getProjectStates(): Promise<ProjectStateCore[]> {
      const response = await service.methods.getEndpointAsync(
        endpointProjectState
      );
      return response.map(projectState => new ProjectStateCore(projectState));
    },
    async getProjectState(projectId: number): Promise<ProjectStateCore> {
      const response = await service.methods.getEndpointAsync(
        `${endpointProjectState}/project/${projectId}`
      );
      return new ProjectStateCore(response);
    },
    async getProjectsByClient(clientId: number): Promise<ProjectCore[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/client/${clientId}`
      );
      return response.map(project => getModelProjectType(project));
    },
    async getProjectService(data: {
      serviceId: number;
      projectId: number;
    }): Promise<ProjectServiceCore> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${data.projectId}/service/${data.serviceId}`
      );
      return new ProjectServiceCore(response);
    },
    async postProjectServices(
      projectServiceCore: ProjectServiceCore
    ): Promise<ProjectServiceCore> {
      const projectId = projectServiceCore.getProjectId;
      const serviceId = projectServiceCore.getServiceId;
      const formData: FormData = new FormData();
      const preactivated = projectServiceCore.getPreactivated
        ? "true"
        : "false";
      formData.append("preactivated", preactivated);

      const response = await service.methods.postEndpointFormDataAsync(
        `${endpoint}/${projectId}/service/${serviceId}`,
        formData
      );
      return new ProjectServiceCore(response);
    },
    async deleteProjectServices(
      projectServiceCore: ProjectServiceCore
    ): Promise<any> {
      const projectId = projectServiceCore.getProjectId;
      const serviceId = projectServiceCore.getServiceId;
      return await service.methods.deleteEndpointAsync(
        `${endpoint}/${projectId}/service/${serviceId}`
      );
    },
    async validateProjectName(projectName: string): Promise<any> {
      return await service.methods.getEndpointAsync(
        `${endpoint}/validate/${projectName}`
      );
    }
  }
};
