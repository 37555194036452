






























































































































































































































  import docTypes from "../../_helpers/docTypes";
  import utils from "../../utils/utils";
  import DocumentDx from "../../models/deliveryexperience/DocumentDx";
  import MethodologyDx from "../../models/deliveryexperience/MethodologyDx";
  import DocToUpload from "../../view-models/deliveryexperience/DocToUpload";

  export default {
    name: "DocsAttach",
    props: {
      action: { type: String, default: "create" },
      projectId: Number,
      peopleDxIds: { type: Array, default: () => [] },
      eventId: { type: String, default: null },
      stageId: { type: String, default: null },
      requestId: { type: String, default: null },
      methodology: MethodologyDx,
      methodologyScopeDocs: Array<DocumentDx>,
      saveDocsAttached: { type: Boolean, default: false }
    },
    data() {
      return {
        docTypes: docTypes,
        docsToShow: [], // Docs to reactively show in the table
        docsToUpload: [] as DocToUpload[], // Object array with the uploaded files by select/drag&drop (not saved) and its DocumentDx object.
        docsToUpdate: [], // DocsDx that exist in the DB (only filled when editing), which will be updated
        docsToDelete: [], // DocsDx that exist in the DB (only filled when editing), which will be deleted
        draggingover: 0,
        notifyCreatedDocs: false,
        notifyDeletedDocs: false,
        docsTableFields: ["icon", "filename", "actions"],
        text: {
          dragAndDropOr: this.$t("dragAndDropOr"),
          selectAFile: this.$t("selectAFile"),
          fileTooLarge: this.$t("fileTooLarge"),
          fileTooShort: this.$t("fileTooShort"),
          fileFormat: this.$t("fileFormat"),
          selectAEventStage: this.$t("selectAEventStage"),
          versionOverwrite: this.$t("versionOverwrite"),
          versionCopy: this.$t("versionCopy")
        },
        alertText: "",
        dismissCountDown: 0,
        dismissSecs: 3,
        maxFileSize: Number(21000000)
      };
    },
    mounted() {
      if (this.stageId) {
        this.docsToShow = this.docsDxStage;
      } else if (this.eventId) {
        this.docsToShow = this.docsDxEvent;
      } else if (this.requestId) {
        this.docsToShow = this.docsDxRequest;
      } else if (this.action === "methodology") {
        this.docsToShow = this.methodologyScopeDocs;
      }
      // We will only fill the docsToUpdate the first time (when is empty),
      // with the existing docs of the item if we are editing
      if (this.action === "edit" && !this.docsToUpdate.length) {
        // Using spread syntax (...arr) to avoid references between docsToShow & docsToUpdate
        this.docsToUpdate.push(...this.docsToShow);
      }
    },
    computed: {
      docsDxEvent(): DocumentDx[] {
        return this.getDocumentsDxByEventId(this.eventId);
      },
      docsDxStage(): DocumentDx[] {
        return this.getDocumentsDxByStageId(this.stageId);
      },
      docsDxRequest(): DocumentDx[] {
        return this.getDocumentsDxByRequestId(this.requestId);
      }
    },
    methods: {
      messageAlert: function (text) {
        this.alertText = text;
        this.dismissCountDown = this.dismissSecs;
      },
      // Count down for the alert component.
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown;
      },
      triggerFileSelect() {
        document.getElementById("add-docs-input").click();
      },
      /**
       * Get the document (item/file) when action of Drag and Drop is activated.
       * Checks the number of documents uploaded (max 1) and the size of document uploaded.
       */
      handleDrop: function (ev) {
        // Prevent default behavior (Prevent file from being opened)
        ev.stopPropagation();
        ev.preventDefault();

        let files = [];

        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          // If dropped items aren't files, reject them
          files = ev.dataTransfer.items;
          let fileList: File[] = [];
          for (const oneFile of files) {
            if (oneFile.kind === "file") {
              const file = oneFile.getAsFile();
              fileList.push(file);
            }
          }
          files = fileList;
        } else {
          // Use DataTransfer interface to access the file(s)
          files = ev.dataTransfer.files;
        }
        if (files.length) this.addDoc(files);

        // Pass event to removeDropData for cleanup
        this.removeDropData(ev);
      },
      // Removes the Drop Data (items/files).
      removeDropData: function (ev) {
        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to remove the drop data
          ev.dataTransfer.items.clear();
        } else {
          // Use DataTransfer interface to remove the drop data
          ev.dataTransfer.clearData();
        }
      },
      // Uploads a document from file selection.
      handleSelect: function (ev) {
        const files = ev.target.files;
        if (files.length) this.addDoc(files);
        // Clearing input's value in case that same file is selected again to trigger onchange
        ev.target.value = null;
      },
      addDoc: async function (files) {
        for (const file of files) {
          if (file) {
            if (file.size > this.maxFileSize) {
              this.messageAlert(this.text.fileTooLarge);
            } else if (file.size === 0) {
              this.messageAlert(this.text.fileTooShort);
            } else if (utils.getContentType(file.name) == "undefined") {
              this.messageAlert(this.text.fileFormat);
            } else {
              const docDx = new DocumentDx({
                name: file.name,
                author: this.getUser.getUsername,
                people: this.peopleDxIds,
                projectId: this.projectId,
                eventId: this.eventId,
                stageId: this.stageId,
                requestId: this.requestId,
                methodology: this.methodology
              });
              this.docsToUpload.push({
                file,
                docDx,
                existingDoc: this.getExistingDoc(docDx),
                overwrite: true
              } as DocToUpload);
              this.docsToShow.push(docDx);
            }
          }
        }

        if (this.docsToUpload.length) {
          this.draggingover = 0;
        }
      },
      // Removes the doc selected from docsToShow table to render the new list.
      // Adds the doc selected to docsToDelete to be deleted when the prop deleteDocs is set to true.
      removeDoc: async function (docToRemove: DocumentDx) {
        const i = this.docsToShow.findIndex(d => docToRemove.getId == d.getId);

        this.docsToShow.splice(i, 1);

        // IF WE ARE EDITING, THE SAME DOC MAY BE TO UPDATE --> REMOVED FROM DOCS TO UPDATE
        if (this.action === "edit") {
          this.docsToUpdate.splice(i, 1);
        }

        const indexRemoveFromUpload = this.docsToUpload.findIndex(
          d => docToRemove.getId == d.docDx.getId
        );

        if (indexRemoveFromUpload >= 0) {
          // IF DOC HAS BEEN JUST UPLOADED NOW -> REMOVED FROM DOCS TO UPLOAD
          this.docsToUpload.splice(indexRemoveFromUpload, 1);
        } else {
          // IF DOC WAS ALREADY UPLOADED (RETRIEVED FROM DB) -> ADDED FOR DELETION
          this.docsToDelete.push(docToRemove);
        }
      },
      async createDocumentsAction(): Promise<{
        ids: string[];
        names: string[];
      }> {
        let createdDocumentsIds: string[] = [];
        let createdDocumentsNames: string[] = [];

        for (const upload of this.docsToUpload) {
          // Creation of Blob & DocumentDx
          // Setting last readers selected right before saving (only for project Docs)
          // and the event/stage/request id just created if the doc is attached to it
          upload.docDx.setPeople = this.peopleDxIds;
          upload.docDx.setStageId = this.stageId;
          upload.docDx.setEventId = this.eventId;
          upload.docDx.setRequestId = this.requestId;

          let currentDoc = this.docsToShow.find(
            d => d.getId == upload.docDx.getId
          );

          if (upload.existingDoc) {
            this.applyOverwrite(upload);
          }

          try {
            const createdDocumentId = await this.postDocumentDxAndBlob(upload);
            // Finding current displayed doc by name as it still did not have an id and updating it with created id
            currentDoc.setId = createdDocumentId;
            if (this.action == "edit") this.notifyCreatedDocs = true; // We will only notify new docs upload on editing
            createdDocumentsIds.push(createdDocumentId);
            createdDocumentsNames.push(upload.file.name);
          } catch (error) {
            this.$log.error(error);
          }
        }

        return {
          ids: createdDocumentsIds,
          names: createdDocumentsNames
        };
      },
      async updateDocumentsAction(): Promise<void> {
        for (const docToUpdate of this.docsToUpdate) {
          // Update document info
          docToUpdate.setPeople = this.peopleDxIds;
          docToUpdate.setStageId = this.stageId;
          docToUpdate.setEventId = this.eventId;
          docToUpdate.setRequestId = this.requestId;
          try {
            await this.putDocumentDx(docToUpdate);
          } catch (error) {
            this.$log.error(error);
          }
        }
      },
      async deleteDocumentsAction(): Promise<any> {
        for (const doc of this.docsToDelete) {
          await this.deleteDocumentAction(doc);
        }
      },
      deleteDocumentAction: async function (doc: DocumentDx) {
        try {
          await this.deleteDocumentDx(doc.getId);
          this.notifyDeletedDocs = true;
        } catch (error) {
          this.$log.error(error);
        }
      },
      performDocAction: async function () {
        const createdDocs = await this.createDocumentsAction();
        await this.updateDocumentsAction();
        await this.deleteDocumentsAction();

        this.$emit(
          "docsUploaded",
          createdDocs.ids,
          createdDocs.names,
          this.notifyCreatedDocs,
          this.notifyDeletedDocs
        );
      },
      getExistingDoc: function (docDx: DocumentDx): DocumentDx {
        return this.docsToShow.find(d => d.getName == docDx.getName);
      },
      getExistingDocNewCopyName: function (docDx: DocumentDx): string {
        const baseFilename = docDx.getBaseFilename();
        const extIndex = baseFilename.lastIndexOf(".");
        const fileName = baseFilename.slice(0, extIndex);

        const regex = new RegExp(`${fileName} \\((\\d+)\\)(\\..+)$`);
        let highestCopy = 0;

        this.docsToShow.forEach(d => {
          const match = d.getName.match(regex);
          if (match) {
            const copyNumber = parseInt(match[1]);
            if (copyNumber && copyNumber > highestCopy) {
              highestCopy = copyNumber;
            }
          }
        });

        return fileName + ` (${++highestCopy})` + baseFilename.slice(extIndex);
      },
      applyOverwrite: function (upload: DocToUpload) {
        if (upload.overwrite) {
          upload.docDx.setId = upload.existingDoc.getId;
          upload.docDx.setUrl = upload.existingDoc.getUrl;
        } else {
          upload.docDx.setName = this.getExistingDocNewCopyName(upload.docDx);
        }
      },
      setItemDocsToShowAndUpate: function (itemDocsDx: DocumentDx[]) {
        if (this.action === "edit") {
          // Using spread syntax (...arr) to avoid references between docsToShow & docsToUpdate
          this.docsToShow = [...itemDocsDx];
          this.docsToUpdate = [...itemDocsDx];
        }
      },
      findDocToUploadByDocDx(docDx: DocumentDx): DocToUpload {
        return this.docsToUpload.find(u => u.docDx.getId == docDx.getId);
      },
      getTooltipConfig(doc) {
        return {
          placement: "left",
          boundary: "viewport",
          delay: { show: 0, hide: 500 },
          content: this.findDocToUploadByDocDx(doc).overwrite
            ? this.text.versionOverwrite
            : this.text.versionCopy
        };
      }
    },
    watch: {
      methodologyScopeDocs: {
        handler() {
          this.docsToShow = this.methodologyScopeDocs;
        },
        deep: true
      },
      stageId: function () {
        if (this.stageId) {
          this.setItemDocsToShowAndUpate(this.docsDxStage);
        }
      },
      eventId: function () {
        if (this.eventId) {
          this.setItemDocsToShowAndUpate(this.docsDxEvent);
        }
      },
      requestId: function () {
        if (this.requestId) {
          this.setItemDocsToShowAndUpate(this.docsDxRequest);
        }
      },
      saveDocsAttached: async function () {
        if (this.saveDocsAttached) {
          await this.performDocAction();
        }
      },
      docsToUpload: function () {
        this.$emit(
          "docsReady",
          this.docsToUpload.length || this.docsToDelete.length
        );
      },
      docsToDelete: function () {
        this.$emit(
          "docsReady",
          this.docsToUpload.length || this.docsToDelete.length
        );
      }
    }
  };
