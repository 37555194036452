









































































































































  import { requestStates } from "../../../_helpers/requestStates";
  import { servicesExtensions } from "../../../_helpers/servicesExtensions";
  import RequestForm from "../../../components/services/deliveryexperience/request/RequestForm.vue";
  import RequestRow from "../../../components/services/deliveryexperience/request/RequestRow.vue";
  import TimelineItemForm from "../../../components/services/deliveryexperience/timeline/TimelineItemForm.vue";
  import FilterDropdown from "../../../components/view_elements/FilterDropdown.vue";
  import RequestDx from "../../../models/deliveryexperience/RequestDx";
  import TagDx from "../../../models/deliveryexperience/TagDx";
  import Singleton from "../../../utils/singleton";
  import * as signalR from "@microsoft/signalr";
  import config from "../../../config";

  export default {
    name: servicesExtensions.deliveryExperience.extensions.requests.name.toLowerCase(),
    props: { projectId: Number },
    components: { RequestForm, RequestRow, TimelineItemForm, FilterDropdown },
    data() {
      return {
        text: {
          request: this.$tc("request", 1),
          type: this.$t("type"),
          author: this.$t("author"),
          date: this.$t("date"),
          documents: this.$tc("document", 2),
          thereAreNoRequests: this.$t("thereAreNoRequests"),
          status: this.$t("status"),
          requestStatusOpen: this.$t("requestStatusOpen"),
          requestStatusResolved: this.$t("requestStatusResolved"),
          requestStatusClosed: this.$t("requestStatusClosed"),
          tag: this.$tc("tag", 1),
          tags: this.$tc("tag", 2),
          filterTags: this.$t("filterTags"),
          all: this.$tc("all", 2)
        },
        requestFormId: "dx-request-form-from-requests-page",
        requestIdToEdit: "",
        timelineItemFormId: "dx-requests-timeline-item-form",
        projectType: "",
        currentVersionDxId: undefined as Number,
        loadedData: Boolean(false),
        requestIndexRow: null,
        allSelected: false,
        projectTags: TagDx,
        tagFilterSelected: [],
        filter: {
          open: Boolean(true),
          resolved: Boolean(true),
          closed: Boolean(true)
        },
        permissionsRequests: {},
        currentProjectId: Number(this.$route.params.projectId)
      };
    },
    async created() {
      this.addLoadingComponent(this.$options.name);
      this.projectTags = this.getProjectTags.getTags;
    },
    async mounted() {
      this.permissionsRequests = this.getUserPermissions.requests;
      const currentVersionDxId = this.getLastProjectVersionDx(
        this.projectId
      ).getVersionId;
      this.projectType = this.getProjectById(this.projectId).getProjectType;
      this.currentVersionDxId = currentVersionDxId ? currentVersionDxId : 1;
      this.loadedData = true;
      this.removeLoadingComponent(this.$options.name);
      this.$nextTick(function () {
        if (this.$route.params.idRequest != null) {
          document
            .getElementById("request-row-" + this.requestIndexRow)
            .scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
      this.$emit(
        "projectHeaderService",
        servicesExtensions.deliveryExperience.name
      );
      this.$emit(
        "projectHeaderExtension",
        servicesExtensions.deliveryExperience.extensions.requests.name
      );
      Singleton.get().vue.$chatConnection = new signalR.HubConnectionBuilder()
        .withUrl(config.apiDxEndpoint + "/chat", {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets
        })
        .build();
      await Singleton.get().vue.$chatConnection.start();
    },
    computed: {
      projectDxRequests(): RequestDx[] {
        return this.getRequestsDxByProjectId(this.projectId).reverse();
      },
      filterRequests(): RequestDx[] {
        const requestsFiltered = this.projectDxRequests.filter(
          request =>
            (this.filter.open == true &&
              (request.getStatus == requestStates.open.name ||
                request.getStatus == undefined)) ||
            (this.filter.resolved == true &&
              request.getStatus == requestStates.resolved.name) ||
            (this.filter.closed == true &&
              request.getStatus == requestStates.closed.name)
        );
        if (this.tagFilterSelected.length) {
          return requestsFiltered.filter(request => {
            if (request.getTag != undefined) {
              for (const tag of this.tagFilterSelected) {
                if (request.getTag == tag) return true;
              }
            }
            return false;
          });
        }
        return requestsFiltered;
      }
    },
    methods: {
      editRequestStatus(index: number, status: string) {
        this.filterRequests[index].setStatus = status;
      },
      openRequestForm(requestId: string) {
        this.requestIdToEdit = requestId;
        this.$bvModal.show(this.requestFormId);
      },
      createRelatedEvent(requestDx: RequestDx) {
        this.$refs.setRelatedTimelineItemForm.setRelatedRequest(requestDx);
        this.$bvModal.show(this.timelineItemFormId);
      },
      showCollapse(index, requestDx: RequestDx): boolean {
        if (this.$route.params.idRequest == requestDx.getId) {
          this.requestIndexRow = index;
          return true;
        }
        return false;
      },
      evalTextVariable(key): string | undefined {
        switch (key) {
          case "open":
            return this.text.requestStatusOpen;
          case "resolved":
            return this.text.requestStatusResolved;
          case "closed":
            return this.text.requestStatusClosed;
          default:
            return undefined;
        }
      },
      filterByTypeSwitch(filterType) {
        this.filter[filterType] = !this.filter[filterType];
      },
      filterByTags(selectedTags) {
        this.tagFilterSelected = selectedTags;
      },
      async toggleRequestThread(requestId: string) {
        await this.loadRequestDx(requestId);
      }
    }
  };
