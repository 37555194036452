
































































  export default {
    name: "FilterDropdown",
    props: {
      id: String,
      title: String,
      iconSize: { type: Number, default: 20 },
      lightGrey: { type: Boolean, default: true },
      headerText: String,
      allButton: { type: Boolean, default: true },
      allText: String,
      popoverText: String,
      options: Array<string>
    },
    data() {
      return {
        selected: [],
        allSelected: false,
        text: {
          clear: this.$t("clear")
        }
      };
    },
    mounted() {
      this.setupFilterListeners();
    },
    methods: {
      changeFilter() {
        this.allSelected = this.options.length == this.selected.length;
        this.$emit("changeFilter", this.selected);
      },
      selectAll() {
        if (this.allSelected)
          this.selected = this.options[0].value
            ? this.options.map(opt => opt.value)
            : this.options;
        else this.selected = [];
        this.changeFilter();
      },
      clear() {
        this.selected = [];
        this.changeFilter();
      },
      setupFilterListeners() {
        Array.from(
          document.querySelectorAll(`#${this.id} .custom-control`)
        ).forEach(el =>
          el.addEventListener("click", function () {
            const input = this.getElementsByTagName("input")[0];
            input.click();
            input.blur();
          })
        );
      }
    },
    watch: {
      options: function () {
        // When options are dynamic, the attached listeners must be updated
        this.setupFilterListeners();
      }
    }
  };
