import utils from "../utils/utils";
import { projectTypes } from "./projectMetadata";

export const eventTypes = {
  [projectTypes.liability.name]: {
    type: projectTypes.liability.name,
    events: {
      changeRequest: {
        type: "CHANGE_REQUEST",
        name: "Change Request",
        icon: {
          normal: "change.png",
          fire: "change-fired.png",
          fireOff: "change-fireoff.png"
        },
        i18nDesc: "eventDescChangeRequest"
      }
    }
  },
  [projectTypes.capacity.name]: {
    type: projectTypes.capacity.name,
    events: {
      reload: {
        type: "RELOAD",
        name: "Reload",
        icon: {
          normal: "reload.png",
          fire: "reload-fired.png",
          fireOff: "reload-fireoff.png"
        },
        i18nDesc: "eventDescReload"
      },
      billing: {
        type: "BILLING",
        name: "Billing",
        icon: {
          normal: "billing.png",
          fire: "billing.png",
          fireOff: "billing.png"
        },
        i18nDesc: "eventDescBilling"
      },
      consume: {
        type: "CONSUME",
        name: "Consume",
        icon: {
          normal: "consumption.png",
          fire: "consumption-fired.png",
          fireOff: "consumption-fireoff.png"
        },
        i18nDesc: "eventDescConsume"
      }
    }
  },
  [projectTypes.timeAndMaterials.name]: {
    type: projectTypes.timeAndMaterials.name,
    events: {}
  },
  [projectTypes.QBR.name]: {
    type: projectTypes.QBR.name,
    events: {
      [projectTypes.QBR.name]: {
        type: projectTypes.QBR.name,
        name: projectTypes.QBR.name,
        icon: {
          normal: "qbr.png",
          fire: "qbr-fired.png",
          fireOff: "qbr-fireoff.png"
        },
        i18nDesc: "eventDescQBR"
      }
    }
  },
  [projectTypes.CSP.name]: {
    type: projectTypes.CSP.name,
    events: {
      cspOperation: {
        type: "CSP_OPERATION",
        name: "CSP Operation",
        icon: {
          normal: "csp_operation.png",
          fire: "csp_operation.png",
          fireOff: "csp_operation.png"
        },
        i18nDesc: "eventDescCSPOperation"
      }
    }
  },
  COMMON: {
    type: "COMMON",
    events: {
      activity: {
        type: "ACTIVITY",
        name: "Activity",
        icon: {
          normal: "activity.png",
          fire: "activity-fired.png",
          fireOff: "activity-fireoff.png"
        },
        i18nDesc: "eventDescActivity"
      },
      kickOff: {
        type: "KICK_OFF",
        name: "Kick Off",
        icon: {
          normal: "kick-off.png",
          fire: "kick-off-fired.png",
          fireOff: "kick-off-fireoff.png"
        },
        i18nDesc: "eventDescKickOff"
      },
      followUp: {
        type: "FOLLOW_UP",
        name: "Follow Up",
        icon: {
          normal: "follow-up.png",
          fire: "follow-up-fired.png",
          fireOff: "follow-up-fireoff.png"
        },
        i18nDesc: "eventDescFollowUp"
      },
      milestone: {
        type: "MILESTONE",
        name: "Milestone",
        icon: {
          normal: "milestone.png",
          fire: "milestone-fired.png",
          fireOff: "milestone-fireoff.png"
        },
        i18nDesc: "eventDescMilestone"
      },
      closing: {
        type: "CLOSING",
        name: "Closing",
        icon: {
          normal: "closing.png",
          fire: "closing-fired.png",
          fireOff: "closing-fireoff.png"
        },
        i18nDesc: "eventDescClosing"
      },
      majorIssue: {
        type: "MAJOR_ISSUE",
        name: "Major Issue",
        icon: {
          normal: "issue-error.png",
          fire: "issueerror-fired.png",
          fireOff: "issueerror-fireoff.png"
        },
        i18nDesc: "eventDescMajorIssue"
      },
      minorIssue: {
        type: "MINOR_ISSUE",
        name: "Minor Issue",
        icon: {
          normal: "issue-warning.png",
          fire: "issuewarning-fired.png",
          fireOff: "issuewarning-fireoff.png"
        },
        i18nDesc: "eventDescMinorIssue"
      },
      proposalResolution: {
        type: "PROPOSAL_RESOLUTION",
        name: "Proposal/Resolution",
        icon: {
          normal: "proposal-resolution.png",
          fire: "proposal-resolution-fired.png",
          fireOff: "proposal-resolution-fireoff.png"
        },
        i18nDesc: "eventDescProposalResolution"
      },
      [projectTypes.QBR.name]: {
        type: projectTypes.QBR.name,
        name: projectTypes.QBR.name,
        icon: {
          normal: "qbr.png",
          fire: "qbr-fired.png",
          fireOff: "qbr-fireoff.png"
        },
        i18nDesc: "eventDescQBR"
      },
      virtualVisit: {
        type: "VIRTUAL_VISIT",
        name: "Virtual Visit",
        icon: {
          normal: "virtual-visit.png",
          fire: "virtual-visit-fired.png",
          fireOff: "virtual-visit-fireoff.png"
        },
        i18nDesc: "eventDescVirtualVisit"
      },
      workshopTraining: {
        type: "WORKSHOP_TRAINING",
        name: "Workshop/Training",
        icon: {
          normal: "workshop-training.png",
          fire: "workshop-training-fired.png",
          fireOff: "workshop-training-fireoff.png"
        },
        i18nDesc: "eventDescWorkshopTraining"
      }
    }
  },
  PEOPLE: {
    type: "PEOPLE",
    events: {
      newIncorporation: {
        type: "NEW_INCORPORATION",
        name: "New Incorporation",
        icon: {
          normal: "new-incorporation.png",
          fire: "newemployee-fired.png",
          fireOff: "newemployee-fireoff.png"
        },
        i18nDesc: "eventDescNewIncorporation"
      },
      unassignment: {
        type: "UNASSIGNMENT",
        name: "Unassignment",
        icon: {
          normal: "unassignment.png",
          fire: "unassignment-fired.png",
          fireOff: "unassignment-fireoff.png"
        },
        i18nDesc: "eventDescUnassignment"
      }
    }
  },
  REQUEST: {
    type: "REQUEST",
    events: {
      serviceRequest: {
        type: "SERVICE_REQUEST",
        name: "Service Request",
        i18n: "serviceRequest",
        icon: {
          normal: "request-service-request.png",
          fire: "",
          fireOff: ""
        },
        i18nDesc: "requestDescServiceRequest"
      },
      questionRequest: {
        type: "QUESTION",
        name: "Question",
        i18n: "question",
        icon: {
          normal: "request-question.png",
          fire: "",
          fireOff: ""
        },
        i18nDesc: "requestDescQuestion"
      },
      virtualVisitRequest: {
        type: "VIRTUAL_VISIT_REQUEST",
        name: "Virtual visit",
        i18n: "virtualVisit",
        icon: {
          normal: "request-virtual-visit.png",
          fire: "",
          fireOff: ""
        },
        i18nDesc: "requestDescVirtualVisitRequest"
      },
      cspRequest: {
        type: "CSP_REQUEST",
        name: "CSP",
        i18n: "csp",
        icon: {
          normal: "request-csp.png",
          fire: "",
          fireOff: ""
        }
      }
    }
  }
};

export const eventStates = {
  occurred: {
    name: "OCCURRED",
    i18n: "occurred"
  },
  pendingToOccur: {
    name: "PENDING_TO_OCCUR",
    i18n: "pendingToOccur"
  },
  pendingToConfirm: {
    name: "PENDING_TO_CONFIRM",
    i18n: "pendingToConfirm"
  },
  cancelled: {
    name: "CANCELLED",
    i18n: "cancelled"
  }
};

// Auxiliar function for getEventTypesByProjectType to get list of events by its type.
export function getEventTypes(type: string): any {
  switch (type) {
    case projectTypes.liabilityEconomic.name:
    case projectTypes.liabilityFull.name:
    case projectTypes.liabilityTemporary.name:
      return eventTypes[projectTypes.liability.name].events;
    default:
      return eventTypes[type].events;
  }
}

export function getRequestTypesByProjectType(projectType: string): {
  type: string;
  i18n: string;
  icon: string;
}[] {
  const requestsByProjectType = [];
  for (const requestType in eventTypes.REQUEST.events) {
    const request = eventTypes.REQUEST.events[requestType];
    if (
      projectType == projectTypes.CSP.name ||
      requestType != eventTypes.REQUEST.events.cspRequest.type
    ) {
      requestsByProjectType.push({
        type: request.type,
        i18n: request.i18n,
        icon: request.icon.normal
      });
    }
  }
  return requestsByProjectType;
}

export function getEventType(eventType: string): any {
  for (const projType in eventTypes) {
    const projTypeEvents = eventTypes[projType].events;
    for (const event in projTypeEvents) {
      if (projTypeEvents[event].type === eventType) {
        return projTypeEvents[event];
      }
    }
  }
  return null;
}

export function getTimelineItemTypeFromSubtype(subtype: string): string {
  for (const projType in eventTypes) {
    const projTypeEvents = eventTypes[projType].events;
    for (const event in projTypeEvents) {
      if (projTypeEvents[event].type === subtype) {
        if (projType === "REQUEST") return "request";
        else return "event";
      }
    }
  }
  return "stage";
}

export function getEventName(eventType: string): string {
  for (const projType in eventTypes) {
    const projTypeEvents = eventTypes[projType].events;
    for (const event in projTypeEvents) {
      if (projTypeEvents[event].type === eventType) {
        return projTypeEvents[event].name;
      }
    }
  }

  return null;
}

export function getEventTypesByProjectType(projectType: string): any {
  const commonEventTypes = utils.jsonConcat(
    getEventTypes("COMMON"),
    getEventTypes("PEOPLE")
  );
  switch (projectType) {
    case projectTypes.liabilityEconomic.name:
    case projectTypes.liabilityFull.name:
    case projectTypes.liabilityTemporary.name:
      return utils.jsonConcat(
        commonEventTypes,
        getEventTypes(projectTypes.liability.name)
      );
    case projectTypes.timeAndMaterials.name:
      return utils.jsonConcat(
        commonEventTypes,
        getEventTypes(projectTypes.timeAndMaterials.name)
      );
    case projectTypes.capacity.name:
      return utils.jsonConcat(
        commonEventTypes,
        getEventTypes(projectTypes.capacity.name)
      );
    case projectTypes.QBR.name:
      return utils.jsonConcat(
        commonEventTypes,
        getEventTypes(projectTypes.QBR.name)
      );
    case projectTypes.CSP.name:
      return utils.jsonConcat(
        commonEventTypes,
        getEventTypes(projectTypes.CSP.name)
      );
    default:
      return commonEventTypes;
  }
}

export function getRequestEventType(requestType: string): any {
  const projTypeEvents = getEventTypes("REQUEST");
  for (const e in projTypeEvents) {
    if (projTypeEvents[e].type === requestType) {
      return projTypeEvents[e];
    }
  }
  return null;
}

export function getTimelineItemIcon(
  itemType: string,
  color?: string
): NodeRequire | undefined {
  const projectType = getTimelineItemTypeFromSubtype(itemType);
  if (projectType == "event" || projectType == "request") {
    const icon = getEventType(itemType).icon.normal;
    return require("../assets/icons/services/deliveryexperience/timeline/" +
      icon);
  } else if (projectType == "stage") {
    return require(`../assets/icons/services/deliveryexperience/timeline/stage-planner${
      color ? "-" + color : ""
    }.png`);
  }
  return undefined;
}

export function getTimelineItemIconFired(
  itemType: string,
  firedOn: boolean,
  color?: string
): NodeRequire | undefined {
  const projectType = getTimelineItemTypeFromSubtype(itemType);
  if (projectType == "event") {
    const icon = firedOn
      ? getEventType(itemType).icon.fire
      : getEventType(itemType).icon.fireOff;
    return require("../assets/icons/services/deliveryexperience/timeline/fired/" +
      icon);
  } else if (projectType == "stage") {
    return firedOn
      ? require("../assets/icons/services/deliveryexperience/timeline/fired/fired-stage-planner.png")
      : require(`../assets/icons/services/deliveryexperience/timeline/stage-planner${
          color ? "-" + color : ""
        }.png`);
  }
  return undefined;
}
