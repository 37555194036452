import service from "../service";
import baseEndpoint from "./serviceSlc";

export default {
  methods: {
    getTest: async function () {
      return service.methods.getEndpointAsync(baseEndpoint + "/test");
    },
    postScrap: async function (body): Promise<any> {
      const data = {
        base_url: body.url,
        depth: body.depth,
        project_id: body.projectid
      };
      if (body.username != null && body.password != null) {
        data["username"] = body.username;
        data["password"] = body.password;
      }
      return service.methods.postEndpointAsync(baseEndpoint + "/scrap", data);
    },
    getHistory: async function (projectid: any) {
      return service.methods.getEndpointAsync(
        baseEndpoint + "/history/" + projectid
      );
    },
    getLast: async function (projectid: any) {
      return service.methods.getEndpointAsync(
        baseEndpoint + "/last/" + projectid
      );
    },
    getPrevious: async function (projectid: any, currentid: any) {
      return service.methods.getEndpointAsync(
        baseEndpoint + "/previous/" + projectid + "/" + currentid
      );
    },
    getNext: async function (projectid: any, currentid: any) {
      return service.methods.getEndpointAsync(
        baseEndpoint + "/next/" + projectid + "/" + currentid
      );
    }
  }
};
