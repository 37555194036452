var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PeoplePermissions',{attrs:{"id":"permissions-management-modal-people"}}),_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"font-weight-bold font-color-purple",staticStyle:{"display":"-webkit-box"},attrs:{"cols":!_vm.itemSelected ? '7' : ''}},[(!_vm.itemSelected)?[_c('img',{staticClass:"mr-3",attrs:{"src":_vm.getExtensionIcon,"height":"25","width":"25"}}),_c('p',[_vm._v(" "+_vm._s(_vm.text.peopleTitle)+" ")])]:(_vm.eventId != undefined)?[_c('b-img',{staticClass:"mr-3",attrs:{"src":_vm.getTimelineItemIcon(_vm.itemSelected.getEventType),"height":"25","width":"25"}}),_c('p',[_vm._v(_vm._s(_vm.text.eventPeopleTitle))])]:(_vm.stageId != undefined)?[_c('b-img',{staticStyle:{"margin-right":"10px"},attrs:{"width":"30px","src":_vm.getTimelineItemIcon(undefined, _vm.itemSelected.color)}}),_c('p',[_vm._v(_vm._s(_vm.text.stagePeopleTitle))])]:[_c('b-img',{staticStyle:{"margin-right":"15px"},attrs:{"width":"25px","height":"25px","src":_vm.getTimelineItemIcon(_vm.itemSelected.getType)}}),_c('p',[_vm._v(_vm._s(_vm.text.requestPeopleTitle))])]],2),(
        !_vm.itemSelected &&
        _vm.permissionsPeople.write &&
        _vm.user.isSogeti() &&
        _vm.user.isAdminOrMgr()
      )?_c('b-col',{staticClass:"pr-1",staticStyle:{"text-align":"end"},attrs:{"cols":"5"}},[(_vm.user.isSogeti() && _vm.user.isAdminOrMgr())?_c('button',{staticClass:"btn btn-sm button-hover",on:{"click":function($event){return _vm.openPeoplePermissions()}}},[_c('FontAwesomeIcon',{staticStyle:{"height":"17"},attrs:{"icon":"fa-solid fa-user-lock"}})],1):_vm._e(),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.edit-people-modal",modifiers:{"edit-people-modal":true}}],staticClass:"btn btn-sm button-hover",staticStyle:{"padding-right":"0.35rem"}},[_c('FontAwesomeIcon',{staticStyle:{"height":"20"},attrs:{"icon":"fas fa-gear"}})],1)]):_vm._e()],1),(_vm.permissionsPeople.read)?[(!_vm.itemSelected)?_c('b-row',[_c('b-col',[_c('button',{staticClass:"adddocument-buttongroup",on:{"click":function($event){_vm.teamSelected = true}}},[_vm._v(" "+_vm._s(_vm.text.projectTeam)+" ")]),_c('button',{staticClass:"adddocument-buttongroup",on:{"click":function($event){_vm.teamSelected = false}}},[_vm._v(" "+_vm._s(_vm.text.members)+" ")]),_c('div',{staticClass:"option-selected-people",style:({
            marginLeft: _vm.teamSelected ? '0' : '44%'
          })})])],1):_vm._e(),_c('div',{staticClass:"people-card-ctn vertical-scrollbar small-scrollbar"},[(_vm.itemSelected)?_c('div',[_c('div',[_vm._v(_vm._s(_vm.text.author))]),_c('div',{staticClass:"people-card d-flex"},[_c('PeopleInfo',{attrs:{"person":_vm.selectedItemAuthor}})],1),(_vm.eventId || _vm.stageId)?_c('div',{staticStyle:{"padding-top":"1em"}},[_vm._v(" "+_vm._s(_vm.text.members)+" ")]):_vm._e(),(_vm.textNoMembers)?_c('p',{staticClass:"no-members"},[_vm._v(" "+_vm._s(_vm.textNoMembers)+" ")]):_vm._e()]):_vm._e(),_c('section',[(_vm.itemSelected || (!_vm.itemSelected && !_vm.teamSelected))?_vm._l((_vm.peopleToShow.people),function(people_,index){return _c('div',{key:'person' + index,staticClass:"people-card d-flex",staticStyle:{"justify-content":"space-between"}},[_c('PeopleInfo',{attrs:{"person":people_}}),(
                _vm.getTeamDxByProjectId(_vm.projectDx.getId).getPeople.includes(
                  people_.username
                )
              )?_c('div',[_c('img',{attrs:{"width":"12","src":require("../../../assets/icons/services/deliveryexperience/people/user-helmet-safety.svg"),"title":_vm.text.teamMember}})]):_vm._e(),(people_.isPOC)?_c('div',{staticStyle:{"width":"12px"}},[_c('a',{staticClass:"point-of-contact",attrs:{"id":("point-of-contact-" + index),"href":'mailto:' + people_.username}},[_c('FontAwesomeIcon',{attrs:{"icon":"fa-solid fa-id-badge"}})],1),_c('b-popover',{key:("popover-people-" + index),attrs:{"target":("point-of-contact-" + index),"triggers":"hover","placement":"bottom","boundary":"viewport"}},[_vm._v(_vm._s(_vm.$t("pointOfContact"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("writeEmail")))])],1):_vm._e()],1)}):[(_vm.getTeamDxByProjectId(_vm.projectDx.getId).getPeople.length)?_vm._l((_vm.getTeamDxByProjectId(_vm.projectDx.getId)
                .getPeople),function(people){return _c('div',{key:people,staticClass:"people-card d-flex"},[_c('PeopleInfo',{attrs:{"person":_vm.getPeopleIcon(people)}})],1)}):_c('div',{staticClass:"info-text mt-4",domProps:{"innerHTML":_vm._s(_vm.text.noTeamMembers)}})]],2)])]:_c('div',{staticClass:"info-text"},[_vm._v(" "+_vm._s(_vm.$t("lackOfPermissionsSection"))+" ")]),_c('AddDeletePeople')],2)}
var staticRenderFns = []

export { render, staticRenderFns }