var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"projects-container responsive-view"},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[(_vm.getUser.isAdminOrMgr() && _vm.getUser.isSogeti())?_c('b-button',{staticClass:"button-small button-confirm mt-0",attrs:{"pill":""},on:{"click":function($event){return _vm.goToCreateProject()}}},[_vm._v(_vm._s(_vm.text.newProject))]):_vm._e(),(
            _vm.getUser.isAdmin() &&
            _vm.filter.state == _vm.removedString &&
            _vm.getRemovedProjects().length
          )?_c('b-button',{staticClass:"button-small button-confirm mt-0",attrs:{"pill":""},on:{"click":function($event){return _vm.$bvModal.show('modal-confirmation-all')}}},[_vm._v(_vm._s(_vm.text.deleteAll))]):_vm._e()],1),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"btn-group button-other button-height mr-4"},[_vm._l((_vm.filter.type),function(value,projectType){return _c('label',{key:projectType,staticClass:"btn btn-default projectTypeFilter",class:value ? 'select-filter' : 'unselect-filter'},[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"checkbox"},domProps:{"checked":value},on:{"click":function($event){_vm.checkFilter(projectType),
                  _vm.checkAllUnselected(),
                  _vm.filterUserProjects()}}}),_vm._v(" "+_vm._s(_vm.evalTextVariable(projectType.toLowerCase()))+" ")])}),_c('b-dropdown',{staticStyle:{"height":"2.4rem"},attrs:{"right":"","split":"","id":"state-filter"}},_vm._l(([
                _vm.activeString,
                _vm.closedString,
                _vm.removedString
              ]),function(projectState){return _c('b-dropdown-item',{key:projectState,staticClass:"filter-state",attrs:{"active":_vm.filter.state == projectState},on:{"click":function($event){(_vm.filter.state = projectState), _vm.filterUserProjects()}}},[_vm._v(" "+_vm._s(_vm.evalTextVariable(projectState.toLowerCase()))+" ")])}),1),_c('div',[_c('b-dropdown',{staticStyle:{"height":"2.4rem"},attrs:{"no-caret":"","id":"sort-filter"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('FontAwesomeIcon',{staticClass:"fa-lg fa-rotate-90",attrs:{"icon":"exchange-alt"}})]},proxy:true}])},[_c('b-dropdown-item',{staticClass:"sort-state",attrs:{"active":_vm.sort == 1},on:{"click":function($event){_vm.sort = 1}}},[_c('FontAwesomeIcon',{staticClass:"fa-lg",attrs:{"icon":"sort-alpha-down"}})],1),_c('b-dropdown-item',{staticClass:"sort-state",attrs:{"active":_vm.sort == 2},on:{"click":function($event){_vm.sort = 2}}},[_c('FontAwesomeIcon',{staticClass:"fa-lg",attrs:{"icon":"sort-alpha-up"}})],1),_c('b-dropdown-item',{staticClass:"sort-state",attrs:{"active":_vm.sort == 3},on:{"click":function($event){_vm.sort = 3}}},[_c('FontAwesomeIcon',{staticClass:"fa-flip-horizontal fa-lg",attrs:{"icon":"history"}})],1),_c('b-dropdown-item',{staticClass:"sort-state",attrs:{"active":_vm.sort == 4},on:{"click":function($event){_vm.sort = 4}}},[_c('FontAwesomeIcon',{staticClass:"fa-lg",attrs:{"icon":"history"}})],1)],1)],1)],2),_c('div',{staticStyle:{"align-self":"center"},on:{"click":function($event){return _vm.switchView()}}},[_c('div',{staticClass:"switch-button"},[_c('div',{staticClass:"switch-button-ctn",style:({ marginLeft: _vm.view == 'list' ? '4px' : '38px' })}),_c('b-img',{staticClass:"switch-button-list",attrs:{"src":_vm.view == 'list'
                  ? require('../../assets/icons/buttons/grid-2.svg')
                  : require('../../assets/icons/buttons/grid-2-white.svg')}}),(_vm.view == 'card')?_c('FontAwesomeIcon',{staticStyle:{"color":"#0070ac","height":"15","margin":"10px 10px 0 0","z-index":"2"},attrs:{"icon":"fa-solid fa-list"}}):_c('FontAwesomeIcon',{staticStyle:{"color":"white","height":"15","margin":"10px 10px 0 0"},attrs:{"icon":"fa-solid fa-list"}})],1)])])],1),_c('b-row',[_c('b-col',{staticStyle:{"padding-left":"25px","padding-top":"1.5rem"}},[_c('h5',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.getSubtitleText())+" ")])]),_c('b-col',{staticClass:"d-flex align-items-end",staticStyle:{"justify-content":"end"},attrs:{"cols":"3"}},[_c('div',[_c('CapsuleLabelInput',{attrs:{"input":_vm.filter.projectName,"id":"search-projectname-input","placeholder":_vm.text.searchProject},on:{"setValue":function (value) { return (_vm.filter.projectName = value); }}})],1)])],1),(_vm.view == 'list')?_c('ProjectsOverview',{key:_vm.key,attrs:{"projects":_vm.projectsToDisplay,"projectsDx":_vm.getProjectsDx,"mountedParent":_vm.mountedParent}}):_c('ProjectsList',{key:_vm.key,attrs:{"projects":_vm.projectsToDisplay,"state":_vm.filter.state}})],1),_c('ConfirmationModal',{attrs:{"id":"modal-confirmation-all","titleConfirmation":_vm.$t('wantToConfirmAllProjectsDeletingPermanently'),"messageConfirmation":_vm.$t('messageDeletePermanentlyAllProjects')},on:{"confirm":_vm.deletePermanentlyProjects}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }