import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import TagDx from "../../../models/deliveryexperience/TagDx";
import tagService from "../../../services/deliveryexperience/tagService";
import { RootState } from "../../index";

interface ITagState {
  tagDx: TagDx;
}

const namespaced = true;

const state: ITagState = {
  tagDx: null
};

const getters: GetterTree<ITagState, RootState> = {
  getProjectTags: (state): TagDx => {
    return state.tagDx;
  }
};

const mutations: MutationTree<ITagState> = {
  setProjectTagsDx(state, tags: TagDx): void {
    state.tagDx = tags;
  },
  updateProjectTagsDx(state, updatedTags: TagDx): void {
    state.tagDx = updatedTags;
  },
  deleteTagDx(state, tag: string): void {
    state.tagDx.removeTag(tag);
  }
};

const actions: ActionTree<ITagState, RootState> = {
  async loadTagsDx({ commit }, projectId: number): Promise<any> {
    const tags: TagDx = await tagService.methods.getProjectTags(projectId);
    commit("setProjectTagsDx", tags);
  },
  async postTagsDx({ commit }, newTags: TagDx): Promise<any> {
    const tags: TagDx = await tagService.methods.postProjectTags(newTags);
    commit("setProjectTagsDx", tags);
  },
  async putTagsDx({ commit }, newTag: string): Promise<any> {
    const modifiedTags = state.tagDx;
    modifiedTags.addTag(newTag);
    const tags: TagDx = await tagService.methods.putProjectTags(modifiedTags);
    commit("updateProjectTagsDx", tags);
  },
  async checkRemainingProjectTag(
    { commit, rootGetters },
    itemInfo: { tag: string; projectId: string }
  ): Promise<any> {
    const events = rootGetters["eventDx/getEventsDxByProjectId"](
      itemInfo.projectId
    ).filter(e => e.getTag && e.getTag === itemInfo.tag);

    const stages = rootGetters["stageDx/getStagesDxByProjectId"](
      itemInfo.projectId
    ).filter(s => s.getTag && s.getTag === itemInfo.tag);

    const requests = rootGetters["requestDx/getRequestsDxByProjectId"](
      itemInfo.projectId
    ).filter(r => r.getTag && r.getTag === itemInfo.tag);

    if (!events.length && !stages.length && !requests.length) {
      commit("deleteTagDx", itemInfo.tag);
    }
  }
};

export const tagModuleDX: Module<ITagState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
