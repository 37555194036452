export interface IProject {
  id?: number;
  name: string;
  states?: any[];
  description?: string;
  startDate: Date;
  closingDate: Date;
  projectType: string;
  clientId: number;
  endDate?: Date;
  icon: File;
  header: File;
  theme: boolean;
}

export default class Project {
  private id?: number;
  private name: string;
  private states?: any[];
  private description?: string;
  private startDate: Date;
  private closingDate: Date;
  private projectType: string;
  private clientId: number;
  private endDate?: Date;
  private icon: File;
  private header: File;
  private theme: boolean;

  constructor(dto: IProject) {
    Object.assign(this, dto);
  }

  get getId(): number {
    return this.id;
  }

  set setId(id: number) {
    this.id = id;
  }

  get getName(): string {
    return this.name;
  }

  set setName(name: string) {
    this.name = name;
  }

  get getStates(): any[] {
    return this.states;
  }

  set setStates(states: any[]) {
    this.states = states;
  }

  get getDescription(): string {
    return this.description;
  }

  set setDescription(description: string) {
    this.description = description;
  }

  get getStartDate(): Date {
    return this.startDate;
  }

  set setStartDate(startDate: Date) {
    this.startDate = startDate;
  }

  get getClosingDate(): Date {
    return this.closingDate;
  }

  set setClosingDate(closingDate: Date) {
    this.closingDate = closingDate;
  }

  get getProjectType(): string {
    return this.projectType;
  }

  set setProjectType(projectType: string) {
    this.projectType = projectType;
  }

  get getClientId(): number {
    return this.clientId;
  }

  set setClientId(clientId: number) {
    this.clientId = clientId;
  }

  get getEndDate(): Date {
    return this.endDate;
  }

  set setEndDate(endDate: Date) {
    this.endDate = endDate;
  }

  get getIcon(): File {
    return this.icon;
  }

  set setIcon(value: File) {
    this.icon = value;
  }

  get getHeader(): File {
    return this.header;
  }

  set setHeader(value: File) {
    this.header = value;
  }

  get getTheme(): boolean {
    return this.theme;
  }

  set setTheme(value: boolean) {
    this.theme = value;
  }
}
