import People from "../models/core/PeopleCore";
import PeopleDx from "../models/deliveryexperience/PeopleDx";
import { store } from "../store";

export interface IPeopleIcon {
  username: string;
  firstName: string;
  lastName: string;
  clientId: number;
  jobPosition: string;
  pointOfContact?: boolean;
}

export default class PeopleIcon {
  private username: string;
  private firstName: string;
  private lastName: string;
  private clientId: number;
  private jobPosition: string;
  private pointOfContact?: boolean;

  constructor(value: IPeopleIcon) {
    Object.assign(this, value);
  }

  static newPeopleIconFromPeople(person: any) {
    if (person instanceof PeopleDx) {
      return PeopleIcon.newPeopleIconFromDx(person);
    }
    if (person instanceof People) {
      return PeopleIcon.newPeopleIconFromCore(person);
    }
    return null;
  }

  static newPeopleIconFromDx(
    peopleDx: PeopleDx,
    peopleCore?: People
  ): PeopleIcon {
    if (peopleCore == undefined) {
      peopleCore = store.getters["peopleCore/getPeopleByUsername"](
        peopleDx.getUsername
      );
    }
    return new PeopleIcon({
      username: peopleDx.getUsername,
      firstName: peopleCore.getFirstName,
      lastName: peopleCore.getLastName,
      clientId: peopleCore.getClientId,
      jobPosition: peopleDx.getJobPosition,
      pointOfContact: peopleDx.isPOC
    });
  }

  static newPeopleIconFromCore(peopleCore: People) {
    return new PeopleIcon({
      username: peopleCore.getUsername,
      firstName: peopleCore.getFirstName,
      lastName: peopleCore.getLastName,
      clientId: peopleCore.getClientId,
      jobPosition: peopleCore.getJobPosition
    });
  }

  //Getters
  get getUsername(): string {
    return this.username;
  }

  get getFirstName(): string {
    return this.firstName;
  }

  get getLastName(): string {
    return this.lastName;
  }

  get getClientId(): number {
    return this.clientId;
  }

  get getJobPosition(): string {
    return this.jobPosition;
  }

  get isPOC(): boolean {
    return this.pointOfContact;
  }
  //Setters

  set setUsername(value: string) {
    this.username = value;
  }

  set setFirstName(value: string) {
    this.firstName = value;
  }

  set setLastName(value: string) {
    this.lastName = value;
  }

  set setClientId(value: number) {
    this.clientId = value;
  }

  set setJobPosition(value: string) {
    this.jobPosition = value;
  }

  set setPOC(value: boolean) {
    this.pointOfContact = value;
  }

  getNaturalFullName(): string {
    return this.getFirstName + " " + this.getLastName;
  }
}
