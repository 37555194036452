









































































































































































































  import {
    getExtensionIcon,
    getExtensionUrl,
    getServiceByName,
    getServiceIcon,
    getServiceUrl,
    servicesExtensions
  } from "../../_helpers/servicesExtensions";
  import ExtensionCore from "../../models/core/ExtensionCore";
  import ProjectCore from "../../models/core/ProjectCore";
  import ServiceCore from "../../models/core/ServiceCore";
  export default {
    name: "ServiceNavbarExtensions",
    props: {
      displayedProjects: Array
    },
    data() {
      return {
        servicesExtensions,
        expandNavbar: false,
        expandedProjectSelector: false,
        text: {
          selectAProject: "",
          noProjectsNavbar: this.$t("noProjectsNavbar"),
          services: this.$tc("service", 2),
          projects: this.$t("projects")
        },
        selectedProjectId: this.$route.params.projectId,
        expandedExtension: [],
        haveExtension: []
      };
    },
    mounted: function () {
      this.text.selectAProject = this.getProjectById(
        this.$route.params.projectId
      ).getName;
      this.setData();
    },
    computed: {
      projectsSelector: function (): [] {
        let result = this.displayedProjects;
        result.sort((p1, p2) => {
          return p1.name < p2.name ? -1 : p1.name > p2.name ? 1 : 0;
        });
        return result;
      },
      projectActiveServices(): ServiceCore[] {
        return this.getServicesByProjectId(
          parseInt(this.$route.params.projectId)
        );
      },
      extensions(): ExtensionCore[][] {
        let extensions = [];
        for (let i = 0; i < this.projectActiveServices.length; i++) {
          const serviceExtensions = this.extensionsInService(
            this.projectActiveServices[i].getId
          );
          extensions[i] = serviceExtensions.filter(ext => !ext.getEmbedded);
          // Inserting at first position DX Home as default extension for Delivery Experience service
          if (this.projectActiveServices[i].isDeliveryExperience()) {
            extensions[i].unshift(
              new ExtensionCore({
                id: 0,
                name: servicesExtensions.deliveryExperience.extensions.dxHome
                  .name,
                embedded: false
              })
            );
          }
        }

        return extensions;
      },
      currentServiceId(): Number {
        for (const service in servicesExtensions) {
          const serviceUrl = servicesExtensions[service].url;
          if (
            serviceUrl.length &&
            this.$route.path.includes(`/${serviceUrl}`)
          ) {
            return this.getServiceByName(servicesExtensions[service].name)
              .getId;
          }
        }
        return 0;
      },
      currentExtensionId(): Number {
        for (const service in servicesExtensions) {
          for (const extension in servicesExtensions[service].extensions) {
            if (
              this.$route.path.includes(
                `/${servicesExtensions[service].extensions[extension].url}`
              )
            ) {
              const extensionName =
                servicesExtensions[service].extensions[extension].name;
              if (
                extensionName !=
                servicesExtensions.deliveryExperience.extensions.dxHome.name
              ) {
                return this.getExtensionByName(extensionName).getId;
              }
            }
          }
        }
        return 0;
      }
    },
    methods: {
      async changeProject(project: ProjectCore) {
        this.expandNavbar = false;
        this.text.selectAProject = project.getName;
        this.selectedProjectId = project.getId;
        await this.$emit("loadedData"); // Changing to false before loading Vuex store data
        await this.loadEventsDxByProjectId(project.getId);
        await this.loadStagesDxByProjectId(project.getId);
        await this.loadRequestsDxByProjectId(project.getId);
        await this.loadTagsDx(project.getId);
        await this.loadPeopleDxInCurrentProject(project.getId);
        await this.$emit("loadedData"); // Changing again to true after having loaded Vuex store data
        this.setData();
      },
      setData() {
        this.expandedExtension = [];
        for (let i = 0; i < this.projectActiveServices.length; i++) {
          // is needed to change this part if two services have got same extension
          if (this.projectActiveServices[i].getId == this.currentServiceId) {
            this.expandedExtension.push(true);
          } else {
            this.expandedExtension.push(false);
          }
          this.haveExtension[i] =
            this.extensions[i].filter(ext => !ext.getEmbedded).length !== 0;
        }
      },
      goToService(service) {
        if (!service) {
          this.$router.push("/home/projects");
        } else {
          this.$router.push(
            `/${getServiceUrl(service.getName)}/${this.$route.params.projectId}`
          );
        }
      },
      goToExtension(extension, dependentServiceName: string) {
        const selectedService = getServiceByName(dependentServiceName);
        // Added bypass for DX Home as it does not have custom URL
        if (
          !this.$router.currentRoute.fullPath.includes(
            getExtensionUrl(extension.getName)
          ) ||
          extension.getName ==
            servicesExtensions.deliveryExperience.extensions.dxHome.name
        ) {
          this.$router.push(
            `/${selectedService.url}/${
              this.$route.params.projectId
            }/${getExtensionUrl(extension.getName)}`
          );
        }
      },
      extensionsInService(serviceId: number): ExtensionCore[] {
        return this.getExtensionServiceByServiceId(serviceId);
      },
      changeExpandedStatus: function (index) {
        // Change all to false except the selected one
        this.expandedExtension.forEach(
          (el, i) => (this.expandedExtension[i] = i == index)
        );
      },
      isExtensionSelected(extension, service): boolean {
        return (
          service.getId == this.currentServiceId &&
          extension.getId == this.currentExtensionId
        );
      },
      isServiceSelected(service): boolean {
        return this.currentServiceId == service.getId;
      },
      getServiceIcon(serviceName: string): NodeRequire {
        return getServiceIcon(serviceName);
      },
      getExtensionIcon(extensionName: string): NodeRequire {
        return getExtensionIcon(extensionName);
      },
      getServiceUrl(extensionName: string): string {
        return getServiceUrl(extensionName);
      },
      getExtensionUrl(extensionName: string): string {
        return getExtensionUrl(extensionName);
      }
    },
    async destroyed() {
      await this.$root.$emit("expandNavbar", false);
    },
    watch: {
      async expandNavbar() {
        if (!this.expandNavbar) {
          this.expandedProjectSelector = false; // Closing the project selector when navbar is collapsed
        }
        await this.$root.$emit("expandNavbar", this.expandNavbar); // Transmits it to index.vue to change page sections left padding
        await this.$root.$emit("rerenderTimeline");
      }
    }
  };
