import PeopleDx from "../models/deliveryexperience/PeopleDx";

export const notificationsPolicies = {
  allMembers: {
    name: "ALL_MEMBERS",
    i18n: "allMembers"
  },
  onlySelectedMembers: {
    name: "ONLY_SELECTED_MEMBERS",
    i18n: "onlySelectedMembers"
  },
  pointsOfContact: {
    name: "POINTS_OF_CONTACT",
    i18n: "projectPointOfContact"
  },
  itemAuthor: {
    name: "ITEM_AUTHOR",
    i18n: "author"
  },
  none: {
    name: "NONE",
    i18n: "nobody"
  }
};

export const actions = {
  created: {
    name: "created",
    i18nShort: "notificationCreated",
    i18nLong: "createdItem"
  },
  edited: {
    name: "edited",
    i18nShort: "notificationEdited",
    i18nLong: "editedItem"
  },
  deleted: {
    name: "deleted",
    i18nShort: "notificationDeleted",
    i18nLong: "deletedItem"
  },
  docUploaded: {
    name: "docUploaded",
    i18nShort: "notificationDocUploaded",
    i18nLong: "docUpload"
  },
  docDeleted: {
    name: "docDeleted",
    i18nShort: "notificationDocDeleted",
    i18nLong: "docDelete"
  },
  docChanged: {
    name: "docChanged",
    i18nShort: "notificationDocChanged",
    i18nLong: "docChange"
  },
  docUploadedProject: {
    name: "docUploadedProject",
    i18nShort: "notificationDocUploadedProject",
    i18nLong: "docUpload"
  },
  docDeletedProject: {
    name: "docDeletedProject",
    i18nShort: "notificationDocDeletedProject",
    i18nLong: "docDelete"
  },
  rescheduled: {
    name: "rescheduled",
    i18nShort: "notificationRescheduled",
    i18nLong: "reSchedule"
  },
  thread: {
    name: "thread",
    i18nShort: "notificationThread",
    i18nLong: "newCommentThread"
  }
};

function getNotificationsPolicy(policyName: string): any | null {
  for (const policy in notificationsPolicies) {
    const policyItem = notificationsPolicies[policy];
    if (policyItem.name === policyName) {
      return policyItem;
    }
  }
  return null;
}

function getPeopleForRecipients(
  policyName: string,
  projectPeople: PeopleDx[],
  author: string,
  user: string,
  itemId?: string
): string[] {
  let recipients;
  switch (policyName) {
    case "ALL_MEMBERS":
      recipients = projectPeople.map(p => p.getUsername);
      break;
    case "ONLY_SELECTED_MEMBERS":
      recipients = projectPeople
        .filter(
          p => p.getEvents.includes(itemId) || p.getStages.includes(itemId)
        )
        .map(p => p.getUsername);
      break;
    case "POINTS_OF_CONTACT":
      recipients = projectPeople.filter(p => p.isPOC).map(p => p.getUsername);
      break;
    case "ITEM_AUTHOR":
      recipients = [author];
      break;
    default:
      recipients = [];
      break;
  }
  const index = recipients.indexOf(user);
  if (index >= 0) recipients.splice(index, 1);
  return recipients;
}

function getTranslationByActionName(name: string): any {
  for (const action in actions) {
    if (actions[action].name == name) return actions[action];
  }
  return null;
}

export default {
  notificationsPolicies,
  actions,
  getNotificationsPolicy,
  getPeopleForRecipients,
  getTranslationByActionName
};
