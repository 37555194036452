import PeopleDx from "../../models/deliveryexperience/PeopleDx";
import service from "../service";
import baseEndpoint from "./serviceDx";

const endpoint = baseEndpoint + "/People";

export default {
  components: { service: service },
  methods: {
    async getPeoples(): Promise<PeopleDx[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(people => new PeopleDx(people));
    },

    async getPeople(id: string): Promise<PeopleDx> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${id}`
      );
      return new PeopleDx(response);
    },
    async getPeoplesByProject(projectId: number): Promise<PeopleDx[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/project/${projectId}`
      );
      return response.map(people => new PeopleDx(people));
    },
    async getPeoplesByUsername(username: string): Promise<PeopleDx[]> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/email/${username}`
      );
      return response.map(people => new PeopleDx(people));
    },
    async putPeople(modifiedPeople: PeopleDx): Promise<PeopleDx> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${modifiedPeople.getId}`,
        modifiedPeople
      );
      return new PeopleDx(response);
    },
    async postPeople(people: PeopleDx): Promise<PeopleDx> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        people
      );
      return new PeopleDx(response);
    },

    async deletePeople(id: string): Promise<any> {
      return await service.methods.deleteEndpointAsync(`${endpoint}/${id}`);
    }
  }
};
