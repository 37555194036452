export interface IProjectPeople {
  projectId: number;
  username: string;
}

export default class ProjectPeople {
  private projectId: number;
  private username: string;

  constructor(dto: IProjectPeople) {
    Object.assign(this, dto);
  }

  get getProjectId(): number {
    return this.projectId;
  }

  set setProjectId(projectId: number) {
    this.projectId = projectId;
  }

  get getUsername(): string {
    return this.username;
  }

  set setUsername(username: string) {
    this.username = username;
  }
}
