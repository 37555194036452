








































  import PeopleIcon from "../../view-models/PeopleIcon";
  import helpers from "../../_helpers/helperFunctions";
  import PeopleDefaultIcon from "./PeopleDefaultIcon.vue";

  export default {
    name: "PeopleInfo",
    components: { PeopleDefaultIcon },
    props: {
      person: PeopleIcon,
      iconSize: { type: Number, default: 30 },
      bold: { type: Boolean, default: true },
      fontSize: { type: Number, default: 0.9 },
      showJobPosition: { type: Boolean, default: true },
      showClient: { type: Boolean, default: true },
      onlyName: { type: Boolean, default: false }
    },
    computed: {
      jobPosition: function (): string {
        return this.person.getJobPosition
          ? this.person.getJobPosition
          : this.getPeopleByUsername(this.person.getUsername).getJobPosition;
      },
      fullName: function (): string {
        return helpers.getUserFullName(
          this.person.firstName,
          this.person.lastName
        );
      },
      clientName: function (): string {
        //Check if people is an external user (its clientId will be -1)
        const peopleClient = this.getClientById(this.person.getClientId);
        if (peopleClient == undefined) return this.$t("external");
        else return peopleClient.getName;
      }
    }
  };
