export interface ISubscriptionCSP {
  id: number;
  friendlyName: string;
  quantity: number;
  billingType: string;
  status: string;
  offerName: string;
  billingCycleEndDate: string;
  commitmentEndDate: string;
  termDuration: string;
  billingCycle: string;
  refundableQuantity?: Object;
  autoRenewEnabled: boolean;
  productId: string;
  links: Object;
  terms?: Array<Object>;
}

export default class SubscriptionCSP {
  id: string;
  friendlyName: string;
  quantity: number;
  billingType: string;
  status: string;
  offerName: string;
  billingCycleEndDate: string;
  commitmentEndDate: string;
  termDuration: string;
  billingCycle: string;
  refundableQuantity?: Object;
  autoRenewEnabled: boolean;
  productId: string;
  links: Object;
  terms?: Array<Object>;

  constructor(dto: ISubscriptionCSP) {
    Object.assign(this, dto);
  }

  get getId(): string {
    return this.id;
  }

  set setId(value: string) {
    this.id = value;
  }

  get getFriendlyName(): string {
    return this.friendlyName;
  }

  set setFriendlyName(value: string) {
    this.friendlyName = value;
  }

  get getQuantity(): number {
    return this.quantity;
  }

  set setQuantity(value: number) {
    this.quantity = value;
  }

  get getBillingType(): string {
    return this.billingType;
  }

  set setBillingType(value: string) {
    this.billingType = value;
  }

  get getStatus(): string {
    return this.status;
  }

  set setStatus(value: string) {
    this.status = value;
  }

  get getOfferName(): string {
    return this.offerName;
  }

  set setOfferName(value: string) {
    this.offerName = value;
  }

  get getBillingCycleEndDate(): string {
    return this.billingCycleEndDate;
  }

  set setBillingCycleEndDate(value: string) {
    this.billingCycleEndDate = value;
  }

  get getCommitmentEndDate(): string {
    return this.commitmentEndDate;
  }

  set setCommitmentEndDate(value: string) {
    this.commitmentEndDate = value;
  }

  get getTermDuration(): string {
    return this.termDuration;
  }

  set setTermDuration(value: string) {
    this.termDuration = value;
  }

  get getBillingCycle(): string {
    return this.billingCycle;
  }

  set setBillingCycle(value: string) {
    this.billingCycle = value;
  }

  get getRefundableQuantity(): Object {
    return this.refundableQuantity;
  }

  set setRefundableQuantity(value: Object) {
    this.refundableQuantity = value;
  }

  get getAutoRenewEnabled(): boolean {
    return this.autoRenewEnabled;
  }

  set setAutoRenewEnabled(value: boolean) {
    this.autoRenewEnabled = value;
  }

  get getProductId(): string {
    return this.productId;
  }

  set setProductId(value: string) {
    this.productId = value;
  }

  set setLinks(value: Object) {
    this.links = value;
  }

  get getLinks(): Object {
    return this.links;
  }

  set setTerms(value: Array<Object>) {
    this.terms = value;
  }

  get getTerms(): Array<Object> {
    return this.terms;
  }
}
