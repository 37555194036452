









































































  import { projectStates } from "../../_helpers/projectMetadata";
  import ProjectCore from "../../models/core/ProjectCore";
  import ProjectStateCore from "../../models/core/project/ProjectStateCore";
  import ConfirmationModal from "./ConfirmationModal.vue";

  export default {
    name: "ContextMenu",
    components: { ConfirmationModal },
    props: {
      project: ProjectCore,
      projectState: String
    },
    data() {
      return {
        activeStateString: projectStates.active.name,
        closedStateString: projectStates.closed.name,
        removedStateString: projectStates.removed.name,
        action: "",
        text: {
          configuration: this.$t("configuration"),
          modify: this.$t("modify"),
          close: this.$t("close"),
          remove: this.$t("delete"),
          restore: this.$t("restore"),
          deletePermanently: this.$t("deletePermanently"),
          confirm: this.$t("confirm"),
          cancel: this.$t("cancel")
        }
      };
    },
    computed: {
      confirmationModalText(): {
        titleConfirmation: string;
        messageConfirmation: string;
      } {
        switch (this.action) {
          case "closeProject":
            return {
              titleConfirmation: this.$t("wantToConfirmProjectClosing"),
              messageConfirmation: this.$t("messageCloseProject")
            };
          case "removeProject":
            return {
              titleConfirmation: this.$t("wantToConfirmProjectDeleting"),
              messageConfirmation: this.$t("messageRemoveProject")
            };
          case "restoreProject":
            return {
              titleConfirmation: this.$t("wantToConfirmProjectRestoring"),
              messageConfirmation: this.$t("messageRestoreProject")
            };
          default:
            return {
              titleConfirmation: this.$t(
                "wantToConfirmProjectDeletingPermanently"
              ),
              messageConfirmation: this.$t("messageDeletePermanentlyProject")
            };
        }
      }
    },
    methods: {
      editProject() {
        this.$router.push("/edit-project/" + this.project.id);
      },
      collapse() {
        this.$emit("collapse");
      },
      async modifyProject() {
        const projectToChangeClosingDate = await this.getProjectById(
          this.project.getId
        );

        if (this.action == "deleteProject") {
          await this.deleteProjectDx(this.project.getId);
          await this.deleteProject(this.project.getId);
        } else {
          if (this.action == "closeProject")
            projectToChangeClosingDate.setClosingDate = new Date();
          else if (this.action == "restoreProject")
            projectToChangeClosingDate.setClosingDate = null;
          // We only set closing date if project was not already closed (had a closing date)
          else if (
            this.action == "removeProject" &&
            projectToChangeClosingDate.getClosingDate == null
          )
            projectToChangeClosingDate.setClosingDate = new Date();
          await this.putProject(projectToChangeClosingDate);

          let stateProject = "";
          switch (this.action) {
            case "closeProject":
              stateProject = projectStates.closed.name;
              break;
            case "removeProject":
              stateProject = projectStates.removed.name;
              break;
            case "restoreProject":
              stateProject = projectStates.active.name;
              break;
          }
          const newProjectState: ProjectStateCore = new ProjectStateCore({
            projectId: this.project.getId,
            state: stateProject,
            timestamp: new Date()
          });

          await this.postProjectState(newProjectState);
        }
        this.$nextTick(async () => {
          await this.$bvModal.hide("modal-confirmation-" + this.project.getId);
        });
      }
    }
  };
