


















































































































































































































  import emailBuilder from "../../../../_helpers/email/emailBuilder";
  import projectIncorporationEmailBody from "../../../../_helpers/email/projectIncorporationEmailBody";
  import CapsuleLabelInput from "../../../../components/view_elements/CapsuleLabelInput.vue";
  import MailCore from "../../../../models/core/MailCore";
  import ProjectPeopleCore from "../../../../models/core/project/ProjectPeopleCore";
  import PeopleDx from "../../../../models/deliveryexperience/PeopleDx";
  import Permissions from "../../../../models/deliveryexperience/Permissions/Permissions";
  import mailService from "../../../../services/core/mailService";
  import PeopleIcon from "../../../../view-models/PeopleIcon";
  import PeopleInfo from "../../../view_elements/PeopleInfo.vue";

  export default {
    name: "AddDeletePeople",
    components: { CapsuleLabelInput, PeopleInfo },
    data() {
      return {
        jobPositionEditing: "",
        memberEditing: "",
        searchUser: "",
        peopleAddEditDelete: [],
        loading: false,
        text: {
          notSpecifiedJobPosition: this.$t("notSpecifiedJobPosition"),
          enterProjectRole: this.$t("enterProjectRole"),
          teamManagement: this.$t("teamManagement"),
          searchSomeone: this.$t("searchSomeone"),
          cancel: this.$t("cancel"),
          save: this.$t("save"),
          employee: this.$t("employee"),
          jobPosition: this.$t("jobPosition"),
          projectRole: this.$t("projectRole")
        }
      };
    },
    computed: {
      usersList(): PeopleDx[] {
        const peopleToAdd = this.peopleAddEditDelete.filter(
          p => p.action == "add"
        );
        if (this.searchUser) {
          const searchTerm = this.searchUser.toLowerCase().split(" ");
          // Filter eligible users that contain searchTerm string in name or last name
          const eligiblePeople = this.getAvailablePeople(
            this.getUser.getClientId,
            []
          )
            .filter(
              pCore =>
                this.userNameMatch(pCore, searchTerm) &&
                !this.isMemberAdded(pCore)
            )
            .map(
              pCore =>
                new PeopleDx({
                  userName: pCore.getUsername,
                  projectId: this.$route.params.projectId,
                  events: [],
                  stages: [],
                  permissions: pCore.isAdminOrMgr()
                    ? new Permissions(undefined, true)
                    : new Permissions()
                })
            )
            .filter(
              eligiblePerson =>
                !peopleToAdd.some(
                  toAddPerson =>
                    toAddPerson.peopleDx.getUsername ==
                    eligiblePerson.getUsername
                )
            );

          // Filter added users that contain searchTerm string in name or last name and concatenate eligiblePeople
          const addedPeople = this.getPeopleDxByProjectId(
            this.$route.params.projectId
          ) //add to the members of project users that are going to be added but they are not in BD
            .concat(peopleToAdd.map(p => p.peopleDx))
            .filter(
              pDx =>
                this.userNameMatch(
                  this.getPeopleByUsername(pDx.getUsername),
                  searchTerm
                ) && this.isMemberAdded(pDx)
            );

          return addedPeople.concat(eligiblePeople);
        }
        //search user functionality is not activated and it returns members of project
        else
          return (
            this.getPeopleDxByProjectId(this.$route.params.projectId)
              .filter(pDx => this.isMemberAdded(pDx))
              //add to the members of project users that are going to be added but they are not in BD
              .concat(peopleToAdd.map(p => p.peopleDx))
              .sort((p1, p2) => {
                const p1Core = this.getPeopleByUsername(p1.getUsername);
                const p2Core = this.getPeopleByUsername(p2.getUsername);
                return p1Core.getFirstName < p2Core.getFirstName
                  ? -1
                  : p1Core.getFirstName > p2Core.getFirstName
                  ? 1
                  : p1Core.getLastName < p2Core.getLastName
                  ? -1
                  : p1Core.getLastName > p2Core.getLastName
                  ? 1
                  : 0;
              })
          );
      }
    },
    methods: {
      async saveChanges(ev) {
        this.loading = true;
        ev.preventDefault();
        let added = false;
        for (const people of this.peopleAddEditDelete) {
          switch (people.action) {
            case "add":
              const peopleDx = people.peopleDx;
              await this.postPeopleDx(peopleDx);
              await this.postProjectPeople(
                new ProjectPeopleCore({
                  projectId: this.$route.params.projectId,
                  username: peopleDx.getUsername
                })
              );
              added = true;
              const projectCore = this.getProjectById(
                this.$route.params.projectId
              );
              this.postMail(
                this.getPeopleByUsername(peopleDx.getUsername).getLanguage,
                peopleDx.getUsername,
                projectCore.getName,
                projectCore.getProjectType,
                projectCore.getId
              );
              break;
            case "edit":
              await this.putPeopleDx(people.peopleDx);
              break;
            case "delete":
              await this.deletePeopleDx(people.peopleDx.getId);
              await this.deleteProjectPeople(
                new ProjectPeopleCore({
                  projectId: this.$route.params.projectId,
                  username: people.peopleDx.getUsername
                })
              );
              break;
          }
        }
        this.$nextTick(async () => {
          await this.$bvModal.hide("edit-people-modal");
        });
        this.loading = false;
        if (added) this.$bvModal.show("permissions-management-modal-people");
      },
      userPeopleIcon(user: PeopleDx): PeopleIcon {
        return PeopleIcon.newPeopleIconFromDx(user);
      },
      addOrAddedMemberIcon(member: PeopleDx): string {
        for (const person of this.peopleAddEditDelete) {
          if (person.peopleDx.userName === member.getUsername) {
            switch (person.action) {
              case "add":
              case "edit":
                return "fa-solid fa-user-check";
              case "delete":
                return "fa-solid fa-user-plus";
            }
          }
        }
        const peopleInThisProject = this.getPeopleDxByProjectId(
          this.$route.params.projectId
        );
        for (const person of peopleInThisProject) {
          if (person.userName === member.getUsername) {
            return "fa-solid fa-user-check";
          }
        }
        return "fa-solid fa-user-plus";
      },
      /**
       * Returns peopleDx if username is in peopleAddEditDelete array
       * Otherwise returns peopleDx from usersList computed property thanks to index
       */
      getMemberUpdated(username: string, index: number): PeopleDx {
        const editedMemberIndex = this.peopleAddEditDelete.findIndex(
          p => p.peopleDx.getUsername == username && p.action != "delete"
        );
        if (editedMemberIndex === -1) return this.usersList[index];
        else return this.peopleAddEditDelete[editedMemberIndex].peopleDx;
      },
      getMemberJobPosition(jobPosition: string): string {
        return jobPosition ? jobPosition : this.text.notSpecifiedJobPosition;
      },
      addEditDeletePeople(peopleDx: PeopleDx, action: string) {
        const peopleIndex = this.peopleAddEditDelete.findIndex(
          p => p.peopleDx.getUsername == peopleDx.getUsername
        );
        switch (action) {
          case "add":
            if (peopleIndex !== -1) {
              const act = this.peopleAddEditDelete[peopleIndex].action;
              this.peopleAddEditDelete.splice(peopleIndex, 1);
              if (act != "delete")
                this.peopleAddEditDelete.push({
                  peopleDx: peopleDx,
                  action: action
                });
            } else {
              this.peopleAddEditDelete.push({
                peopleDx: peopleDx,
                action: action
              });
            }
            break;
          case "edit":
            let act = action;
            if (peopleIndex !== -1) {
              act = this.peopleAddEditDelete[peopleIndex].action;
              this.peopleAddEditDelete.splice(peopleIndex, 1);
            }
            this.peopleAddEditDelete.push({
              peopleDx: peopleDx,
              action: act
            });
            break;
          case "delete":
            if (peopleIndex !== -1) {
              const act = this.peopleAddEditDelete[peopleIndex].action;
              this.peopleAddEditDelete.splice(peopleIndex, 1);
              if (act != "add")
                this.peopleAddEditDelete.push({
                  peopleDx: peopleDx,
                  action: action
                });
            } else {
              this.peopleAddEditDelete.push({
                peopleDx: peopleDx,
                action: action
              });
            }
            break;
        }
      },
      editJobPosition(peopleDx: PeopleDx) {
        const editedPeople: PeopleDx = new PeopleDx({
          id: peopleDx.getId,
          userName: peopleDx.getUsername,
          jobPosition: this.jobPositionEditing,
          projectId: peopleDx.getProjectId,
          pointOfContact: peopleDx.isPOC,
          permissions: peopleDx.getPermissions,
          events: peopleDx.getEvents,
          stages: peopleDx.getStages
        });
        this.addEditDeletePeople(editedPeople, "edit");
        this.jobPositionEditing = "";
        this.memberEditing = "";
      },
      editPOC(peopleDx: PeopleDx) {
        const editedPeople: PeopleDx = new PeopleDx({
          id: peopleDx.getId,
          userName: peopleDx.getUsername,
          jobPosition: peopleDx.getJobPosition,
          projectId: peopleDx.getProjectId,
          pointOfContact: !peopleDx.isPOC,
          permissions: peopleDx.getPermissions,
          events: peopleDx.getEvents,
          stages: peopleDx.getStages
        });
        this.addEditDeletePeople(editedPeople, "edit");
      },
      postMail(
        lang: string,
        username: string,
        projectName: string,
        projectType: string,
        projectId: string
      ) {
        mailService.methods.postMailCore(
          new MailCore({
            to: username,
            subject: `[SogetiHub] ${this.$t(
              "projectIncorporationEmailTitle",
              lang
            )}`,
            body: emailBuilder.buildEmail(
              this.$t("projectIncorporationEmailTitle", lang),
              "",
              projectIncorporationEmailBody.build(
                projectName,
                projectType,
                projectId,
                lang
              ),
              lang
            )
          })
        );
      },
      isMemberAdded(member: PeopleDx) {
        return (
          this.peopleAddEditDelete.find(
            p => p.peopleDx == member && p.action == "add"
          ) ||
          this.getPeopleDxByProjectId(this.$route.params.projectId)
            .filter(
              p =>
                !this.peopleAddEditDelete.find(
                  pAED =>
                    pAED.peopleDx.getUsername == p.getUsername &&
                    (pAED.action == "delete" || pAED.action == "add")
                )
            )
            .find(p => p.getUsername == member.getUsername)
        );
      },
      handleMemberClick(member: PeopleDx, action: string) {
        this.addEditDeletePeople(member, action);
        if (action == "add") {
          this.searchUser = "";
        }
      },
      userNameMatch(person, searchTerm) {
        const fullName =
          `${person.getFirstName} ${person.getLastName}`.toLowerCase();
        const nameSplit = fullName.split(" ");
        return searchTerm.every(search =>
          nameSplit.some(namePart => namePart.includes(search))
        );
      }
    },
    watch: {
      searchUser: function () {
        if (this.searchUser) this.memberEditing = "";
      }
    }
  };
