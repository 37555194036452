export interface ICustomerSummary {
  id: number;
  customerName: string;
  totalSubscriptions: number;
  licences: number;
  azurePlans: number;
  currentCost: number;
}

export default class CustomerSummary {
  id: number;
  customerName: string;
  totalSubscriptions: number;
  licences: number;
  azurePlans: number;
  currentCost: number;

  constructor(dto: ICustomerSummary) {
    Object.assign(this, dto);
  }

  get getId(): number {
    return this.id;
  }

  set setId(id: number) {
    this.id = id;
  }

  get getCustomerName(): string {
    return this.customerName;
  }

  set setCustomerName(customerName: string) {
    this.customerName = customerName;
  }

  get getTotalSubscriptions(): number {
    return this.totalSubscriptions;
  }

  set setTotalSubscriptions(totalSubscriptions: number) {
    this.totalSubscriptions = totalSubscriptions;
  }

  get getLicences(): number {
    return this.licences;
  }

  set setLicences(licences: number) {
    this.licences = licences;
  }

  get getAzurePlans(): number {
    return this.azurePlans;
  }

  set setAzurePlans(azurePlans: number) {
    this.azurePlans = azurePlans;
  }

  get getCurrentCost(): number {
    return this.currentCost;
  }

  set setCurrentCost(currentCost: number) {
    this.currentCost = currentCost;
  }
}
