import { render, staticRenderFns } from "./project-form.vue?vue&type=template&id=6a6cad63&scoped=true&"
import script from "./project-form.vue?vue&type=script&lang=ts&"
export * from "./project-form.vue?vue&type=script&lang=ts&"
import style0 from "./project-form.vue?vue&type=style&index=0&id=6a6cad63&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a6cad63",
  null
  
)

export default component.exports