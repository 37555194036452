var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{attrs:{"cols":"7"}},[_c('b-row',{staticStyle:{"padding-left":"0","display":"table"}},[_c('p',{staticStyle:{"font":"bold 20px Ubuntu"}},[_vm._v(" "+_vm._s(_vm.text.newReport)+" ")]),_c('b',[_vm._v(_vm._s(_vm.text.participantsAndDocs))])]),_c('b-row',{key:_vm.key,staticStyle:{"margin-top":"4rem","margin-bottom":"1.5rem"}},[_c('b-col',{attrs:{"cols":"6"}},[_c('b-row',[_c('p',{staticStyle:{"font":"bold 11pt Ubuntu"}},[_vm._v(_vm._s(_vm.text.participants))])]),_c('b-row',[_c('div',{staticClass:"new-report-people-card-ctn small-scrollbar"},[(_vm.participantsFiltered.length > 0)?_vm._l((_vm.participantsFiltered),function(people_,index){return _c('div',{key:'person' + index,staticClass:"new-report-people-card d-flex",style:({
                'border-bottom-style':
                  index == Object.keys(_vm.participantsFiltered).length - 1
                    ? 'solid'
                    : 'none'
              }),on:{"mouseover":function($event){_vm.participantIndexHovered = index},"mouseleave":function($event){_vm.participantIndexHovered = null}}},[_c('div',[_c('PeopleInfo',{attrs:{"person":people_,"iconSize":35}})],1),_c('div',{staticStyle:{"margin":"auto","margin-top":"8px"},style:({
                  visibility:
                    _vm.participantIndexHovered == index ? 'visible' : 'hidden'
                })},[_c('button',{staticClass:"btn btn-sm",on:{"click":function($event){return _vm.removeParticipant(people_.username)}}},[_c('FontAwesomeIcon',{staticStyle:{"opacity":"0.5","height":"20","color":"#ff304c"},attrs:{"icon":"fa-regular fa-trash-can"}})],1)])])}):[_c('div',{staticClass:"new-report-people-card d-flex",staticStyle:{"border-bottom-style":"solid","padding":"0 18.04px"}},[_c('p',{staticStyle:{"margin-top":"auto","margin-bottom":"auto"}},[_vm._v(" "+_vm._s(_vm.text.thereAreNoParticipants)+" ")])])]],2)])],1),_c('b-col',{staticStyle:{"padding-left":"0px"},attrs:{"cols":"6"}},[_c('b-row',{staticStyle:{"margin-left":"0"}},[_c('p',{staticStyle:{"font":"bold 11pt Ubuntu"}},[_vm._v(_vm._s(_vm.text.documents))])]),_c('b-row',[_c('div',{staticClass:"new-report-docs-table small-scrollbar"},[_c('b-table',{attrs:{"id":"new-report-docs-table","hover":"","show-empty":"","items":_vm.documentsList,"fields":_vm.fieldsName,"head-variant":"new-report-docs-table-head"},on:{"row-hovered":_vm.onHover,"row-unhovered":_vm.onUnHover},scopedSlots:_vm._u([{key:"cell(extension)",fn:function(data){return [_c('div',[(_vm.getFileIconByExtension(data.item.name) != '')?[_c('img',{attrs:{"src":_vm.getFileIconByExtension(data.item.name),"width":"30","height":"30","alt":""}})]:_vm._e()],2)]}},{key:"cell(name)",fn:function(data){return [_c('div',[_c('p',[_vm._v(_vm._s(data.item.name))])])]}},{key:"cell(remove)",fn:function(data){return [_c('button',{staticClass:"btn btn-sm",class:{ hidden: !data.item.hovered },on:{"click":function($event){return _vm.removeDoc(data.item)}}},[_c('FontAwesomeIcon',{staticStyle:{"opacity":"0.5","height":"20"},attrs:{"icon":"fa-regular fa-trash-can"}})],1)]}},{key:"empty",fn:function(){return [_c('div',[_c('p',[_vm._v(_vm._s(_vm.text.thereAreNoDocs))])])]},proxy:true}])})],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }