import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { productTypes } from "../../../_helpers/cspOperations";
import ProductCSP from "../../../models/csp/ProductCSP";
import cartService from "../../../services/csp/cartService";
import {
  default as productsCSP,
  default as productsService
} from "../../../services/csp/productsService";
import { RootState } from "../../index";

const namespaced = true;

interface ProductState {
  products: ProductCSP[];
}

const state: ProductState = {
  products: []
};

const getters: GetterTree<ProductState, RootState> = {
  getProductsCSP: (state): ProductCSP[] => {
    return state.products;
  },
  getProductCSPSkus:
    state =>
    (productId: string): Object[] => {
      return state.products.find(p => p.getId == productId).getSkusList;
    }
};

const mutations: MutationTree<ProductState> = {
  setProducts(state, products: ProductCSP[]) {
    state.products = products;
  },
  setSkus(state, productSkus: { productId: string; skus: [] }) {
    const index = state.products.findIndex(p => p.id == productSkus.productId);
    if (index >= 0) state.products[index].setSkusList = productSkus.skus;
  }
};

const actions: ActionTree<ProductState, RootState> = {
  async loadProductsCSPByType({ commit }, type: string) {
    let products = await productsCSP.methods.getProducts(type);
    // To include only OnlineServiceNCE products
    if (type === productTypes.onlineServices.key) {
      products = products.filter(
        p => p.productType === productTypes.onlineServices.key + "NCE"
      );
    }

    commit("setProducts", products);
  },
  async loadProductCSPSkusByProductId({ commit }, productId: string) {
    const product = state.products.find(p => p.getId == productId);
    if (!product.getSkusList.length) {
      const skus = await productsCSP.methods.getProductSkus(productId);
      commit("setSkus", { productId: productId, skus: skus });
      return skus;
    }
    return product.getSkusList;
  },
  async fetchCSPProductCatalogItemIdAndTerms({}, product: any) {
    return await productsCSP.methods.getProductAvailabilities(
      product.productId,
      product.skuId
    );
  },
  async fetchCSPProductPrice({}, productData: any) {
    const response = await productsService.methods.getProductPrice(productData);
    return response;
  },
  async createCSPCart({}, cartData: any) {
    const cartId = await cartService.methods.createCart(
      cartData.tenantId,
      cartData.body
    );
    return cartId;
  },
  async purchaseCSPOrder({}, purchaseData: any) {
    const response = await cartService.methods.purchaseOrder(
      purchaseData.tenantId,
      purchaseData.cartId
    );
    return response;
  }
};

export const productModuleCSP: Module<ProductState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
