export interface IPredecessorLink {
  id: string;
  status: string;
  startedDate: Date;
  left: number;
}

export default class PredecessorLink {
  private id: string;
  private status: string;
  private startedDate: Date;
  private left: number;

  constructor(value: IPredecessorLink) {
    Object.assign(this, value);
  }

  //Getters
  get getId(): string {
    return this.id;
  }

  get getStartedDate(): Date {
    return this.startedDate;
  }

  get getStatus(): string {
    return this.status;
  }

  get getLeft(): number {
    return this.left;
  }

  //Setters
  set setId(value: string) {
    this.id = value;
  }

  set setStartedDate(value: Date) {
    this.startedDate = value;
  }

  set setStatus(value: string) {
    this.status = value;
  }

  set setLeft(value: number) {
    this.left = value;
  }
}
