var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-container',{staticClass:"d-flex",staticStyle:{"background-size":"101%"},style:({
      backgroundImage: 'url(' + _vm.backgroundURL + ')',
      height: '177px',
      'background-repeat': 'no-repeat',
      'background-position': 'center'
    }),attrs:{"fluid":""}},[_c('div',{staticClass:"row align-self-center",staticStyle:{"margin-left":"13%","color":"white","font-size":"2em"},attrs:{"id":"banner-img"}},[_c('b-img',{staticStyle:{"height":"280px","width":"280px","margin-top":"100px"},attrs:{"id":"imge","src":require('../../assets/media/banner-img.png'),"alt":""}})],1),_c('div',{staticClass:"col align-self-center headline",staticStyle:{"margin-top":"30px"},attrs:{"id":"bannerf"}},[_c('p',{staticStyle:{"color":"white","font":"bold 30px Ubuntu"}},[_vm._v(" Digital Services Experience ")]),_c('p',{staticStyle:{"color":"white","font":"lighter 15px Ubuntu"}},[_vm._v("SogetiLabs Spain")])])]),_c('b-container',{staticClass:"p-0 cont responsive-view",attrs:{"fluid":""}},[_c('b-navbar',{attrs:{"type":"light"}},[_c('b-navbar-nav',{staticClass:"mx-auto text-center"},[_c('b-nav-item',{class:{ 'home-selected': _vm.menuOption == 'projects' },attrs:{"to":"projects"}},[_vm._v(_vm._s(_vm.text.projects)+" "),_c('div',{staticClass:"home-rectangle mx-auto",style:({
              visibility: _vm.menuOption == 'projects' ? 'visible' : 'hidden'
            })})]),_c('b-nav-item',{class:{ 'home-selected': _vm.menuOption == 'services' },attrs:{"to":"services"}},[_vm._v(_vm._s(_vm.text.services)+" "),_c('div',{staticClass:"home-rectangle mx-auto",style:({
              visibility: _vm.menuOption == 'services' ? 'visible' : 'hidden'
            })})]),_c('b-nav-item',{class:{ 'home-selected': _vm.menuOption == 'extensions' },attrs:{"to":"extensions"}},[_vm._v(_vm._s(_vm.text.extensions)+" "),_c('div',{staticClass:"home-rectangle mx-auto",style:({
              visibility: _vm.menuOption == 'extensions' ? 'visible' : 'hidden'
            })})]),(_vm.getUser.isAdmin())?_c('b-nav-item',{class:{ 'home-selected': _vm.menuOption == 'clients' },attrs:{"to":"clients"}},[_vm._v(_vm._s(_vm.text.clients)+" "),_c('div',{staticClass:"home-rectangle mx-auto",style:({
              visibility: _vm.menuOption == 'clients' ? 'visible' : 'hidden'
            })})]):_vm._e(),(_vm.getUser.isAdmin())?_c('b-nav-item',{class:{ 'home-selected': _vm.menuOption == 'users' },attrs:{"to":"users"}},[_vm._v(_vm._s(_vm.text.users)+" "),_c('div',{staticClass:"home-rectangle mx-auto",style:({
              visibility: _vm.menuOption == 'users' ? 'visible' : 'hidden'
            })})]):_vm._e()],1)],1),(_vm.componentCreated)?[_c('router-view')]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }