export default {
  namespaced: true,
  state: {
    text: "",
    time: 0,
    type: ""
  },
  getters: {
    getText: state => {
      return state.text;
    },

    getTime: state => {
      return state.time;
    },

    getType: state => {
      return state.type;
    }
  },
  mutations: {
    setMessage(state, message) {
      state.text = message.text;
      state.time = message.time;
      state.type = message.type;
    },
    setDefault(state) {
      state.text = "";
      state.time = 0;
      state.type = "";
    }
  },
  actions: {
    setMessage({ commit }, message) {
      commit("setMessage", message);
      setTimeout(() => {
        commit("setDefault");
      }, message.time * 1000);
    }
  }
};
