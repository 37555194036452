export interface IClientService {
  clientId: number;
  serviceId: number;
}

export default class ClientService {
  private clientId: number;
  private serviceId: number;

  constructor(dto: IClientService) {
    Object.assign(this, dto);
  }

  get getClientId(): number {
    return this.clientId;
  }

  set setClientId(clientId: number) {
    this.clientId = clientId;
  }

  get getServiceId(): number {
    return this.serviceId;
  }

  set setServiceId(serviceId: number) {
    this.serviceId = serviceId;
  }
}
