



















































































































































































































































































































































































  import { autoscroll } from "vue-autoscroll";
  import { dragscroll } from "vue-dragscroll";
  import {
    eventStates,
    eventTypes,
    getEventTypesByProjectType,
    getRequestEventType
  } from "../../../../_helpers/eventTypesStates";
  import { projectTypes } from "../../../../_helpers/projectMetadata";
  import ProjectCore from "../../../../models/core/ProjectCore";
  import { IBaseLineDx } from "../../../../models/deliveryexperience/BaseLineDx";
  import EventDx from "../../../../models/deliveryexperience/EventDx";
  import ReadWrite from "../../../../models/deliveryexperience/Permissions/ReadWrite";
  import RequestDx from "../../../../models/deliveryexperience/RequestDx";
  import StageDx from "../../../../models/deliveryexperience/StageDx";
  import PredecessorLink from "../../../../view-models/deliveryexperience/PredecessorLink";
  import TimelineItem from "./TimelineItem.vue";

  export default {
    name: "TimelineChart",
    directives: {
      dragscroll,
      autoscroll
    },
    components: {
      TimelineItem
    },
    props: {
      comeFrom: String,
      projectCore: ProjectCore,
      versionDx: Number,
      eventsDx: Array,
      stagesDx: Array,
      requestsDx: Array,
      showStages: Boolean,
      monthsNum: Number,
      filterTags: []
    },
    data() {
      return {
        text: {
          noItems: this.$t("noItems"),
          newEvent: this.$t("newEvent"),
          newStage: this.$t("newStage"),
          daysFor: this.$t("daysFor"),
          left: this.$t("left"),
          samedayEvents: this.$t("samedayEvents")
        },
        hover: "hover",
        loadedData: false,
        showPopover: true,
        showItemsPopovers: false,
        dragscrolling: false,
        currentDate: new Date(),
        fixedFinalDate: false,
        projectStartDate: undefined as Date,
        projectEndDate: undefined as Date,
        distanceCurrentDate: 0,
        twoDaysInMilliseconds: 172800000,
        monthWidth: 0,
        timelineContainerWidth: 0,
        nextEvent: {
          id: 0,
          target: "",
          days: 0,
          name: ""
        },
        projectEventTypes: [],
        requestIcons: [],
        timelineItemInfo: {
          item: Object(undefined),
          show: false,
          type: "",
          projectType: ""
        },
        eventLinks: {
          predecessors: undefined as PredecessorLink,
          affecteds: []
        },
        firstTimelineChartItemDate: new Date(),
        lastTimelineChartItemDate: new Date(),
        firstStageStartedDate: new Date(),
        lastStageEndDate: new Date(),
        orderedTimelineChartItems: [],
        orderedTimelineChartItemsAux: [],
        // Array with n dimensions
        orderedStages: [],
        showRequest: true,
        // Variables to manage chart horizontal autoscroll
        chartScroll: 0,
        currentScroll: 0,
        itemsScrollOffset: 0,
        currentDateScrollOffset: 0,
        liabilityTemporaryString: projectTypes.liabilityTemporary.name,
        liabilityFullString: projectTypes.liabilityFull.name
      };
    },
    created: async function () {
      this.updateDataProject();
      this.getProjectEventTypes();
      this.groupItems(this.orderEvs());
      this.orderStages();
      this.findNextEvent();
      this.loadedData = true;
    },
    async mounted() {
      this.calculateTimelineWidths();
      window.addEventListener("resize", this.calculateTimelineWidths);

      // We actualize components at this point to initialize distanceCurrentDate before setting scroll
      this.refreshComponents();

      if (this.chartScroll === 0) {
        this.chartScroll =
          this.distanceCurrentDate - this.currentDateScrollOffset;
      }

      if (this.comeFrom == "timeline" || this.comeFrom == "planner-1") {
        this.awaitClosePopover();
      }

      if (window.addEventListener) {
        window.addEventListener("resize", this.updateSize, false);
      }

      let dis = this;
      this.$root.$on("rerenderTimeline", function () {
        dis.updateSize();
      });
      this.$root.$on("closeTimelineItem", function () {
        dis.closeTimelineItem();
      });
      this.$root.$on(
        "rerenderTimelineItem",
        function (id: String, type: String) {
          dis.openTimelineItem(id, type);
        }
      );
      this.$root.$on("openTooltips", function (showTooltips: Boolean) {
        dis.showItemsPopovers = showTooltips;
      });

      const timeline = document.getElementById("timeline-external-ctn");
      this.$nextTick(function () {
        if (this.$route.params.idItem) {
          this.openTimelineItem(this.$route.params.idItem, this.$route.name);
        } else if (this.$route.name == "timeline") {
          timeline.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        }
      });

      timeline.addEventListener("scroll", () => {
        const stages = document.querySelectorAll(
          ".stage"
        ) as NodeListOf<HTMLElement>;
        for (const stage of stages) {
          const stageTitle = stage.querySelector(".stage-title") as HTMLElement;
          if (stageTitle) {
            const stageLeftEdge = stage.getBoundingClientRect().left;
            const stageRightEdge = stage.getBoundingClientRect().right;
            const chartLeftEdge = timeline.getBoundingClientRect().left;

            if (stageLeftEdge <= chartLeftEdge) {
              if (stageRightEdge - stageTitle.clientWidth <= chartLeftEdge) {
                // Stage pushes text to overflow out
                stageTitle.style.left = `${
                  stage.clientWidth - stageTitle.clientWidth
                }px`;
              } else {
                // Stage starts overflow
                stageTitle.style.left = `${
                  timeline.scrollLeft - stage.offsetLeft
                }px`;
              }
            } else {
              // Stage is moving towards left limit but still completely visible, title remains still
              stageTitle.style.left = "0px";
            }
          }
        }
      });
    },
    updated() {
      this.monthWidth = this.timelineContainerWidth / this.monthsNum;
    },
    computed: {
      existTimelineItems: function (): boolean {
        return (
          this.orderedTimelineChartItems.length || this.orderedStages.length
        );
      },
      // Returns all the project lifespan dates from starting to end month
      projectTimelineDates: function (): Date[] {
        let projectTimelineDates: Date[] = [];
        const sDate: Date = new Date(this.projectCore.getStartDate);
        let eDate: Date = new Date(this.projectCore.getEndDate);
        if (typeof this.projectCore.getEndDate !== "string") {
          eDate = new Date(
            this.currentDate.getFullYear() + 1,
            this.currentDate.getMonth(),
            1
          );
          if (eDate < this.lastTimelineChartItemDate) {
            eDate = this.lastTimelineChartItemDate;
          }
        }
        let iDate = sDate;
        while (iDate <= eDate || projectTimelineDates.length < 9) {
          projectTimelineDates.push(iDate);
          iDate = new Date(iDate.getFullYear(), iDate.getMonth() + 1, 1);
        }
        return projectTimelineDates;
      },
      endDate(): Date {
        let eDate: Date = new Date(this.projectCore.getEndDate);
        if (typeof this.projectCore.getEndDate !== "string") {
          eDate = new Date(
            this.currentDate.getFullYear() + 1,
            this.currentDate.getMonth(),
            1
          );
          if (eDate < this.lastTimelineChartItemDate) {
            eDate = this.lastTimelineChartItemDate;
          }
        }
        return eDate;
      },
      getTimelineItemId(): string {
        if (this.timelineItemInfo.item != undefined) {
          return this.timelineItemInfo.item.id;
        } else {
          return null;
        }
      },
      showCurrentDate: function (): boolean {
        return this.currentDate <= this.endDate;
      },
      permissionsRequests(): ReadWrite {
        return this.getUserPermissions.requests;
      },
      permissionsEvents(): ReadWrite {
        return this.getUserPermissions.events;
      },
      permissionsStages(): ReadWrite {
        return this.getUserPermissions.stages;
      }
    },
    methods: {
      getPopoverBoundary(): HTMLElement {
        return document.getElementById("timeline-chart-" + this.comeFrom);
      },
      calculateTimelineWidths(): void {
        const externalContainer = document.getElementById(
          "timeline-external-ctn"
        );
        if (externalContainer) {
          this.timelineContainerWidth = externalContainer.offsetWidth;
          this.monthWidth = this.timelineContainerWidth / this.monthsNum;
          this.currentDateScrollOffset = this.timelineContainerWidth / 3;
          this.itemsScrollOffset = this.timelineContainerWidth / 4;
        }
      },
      getDaysInMonth(monthDate: Date): number {
        return new Date(
          monthDate.getFullYear(),
          monthDate.getMonth() + 1,
          0
        ).getDate();
      },
      updateSize() {
        this.refreshComponents();
      },
      async refreshStages() {
        await this.orderStages();
        this.setStages();
      },
      async refreshTimelineItems() {
        this.fixScroll();
        await this.groupItems(this.orderEvs());
        this.setEventsRequests();
        this.setEventsPredecessorAndAffectedLinks();
      },
      refreshComponents: function () {
        this.setCurrentDateLine();
        this.setLifetimeLine();
        this.setEventsRequests();
        this.setStages();
        this.setEventsPredecessorAndAffectedLinks();
      },
      setCurrentDateLine: function () {
        if (this.$refs.timelineCtn != undefined) {
          this.distanceCurrentDate = this.calculateChartPosition(
            this.currentDate
          );
          if (this.showCurrentDate) {
            this.$refs.currentDate.style.left = `${this.distanceCurrentDate}px`;
          }
        }
      },
      setLifetimeLine: function () {
        if (
          this.$refs.timelineCtn != undefined &&
          (this.projectCore.getProjectType ===
            projectTypes.liabilityTemporary.name ||
            this.projectCore.getProjectType === projectTypes.liabilityFull.name)
        ) {
          if (this.fixedFinalDate) {
            const gradient =
              this.distanceCurrentDate -
              this.calculateChartPosition(this.projectStartDate);
            const startDateLeftOffset = this.calculateChartPosition(
              this.projectStartDate
            );
            const endDateLeftOffset = this.calculateChartPosition(
              this.projectEndDate
            );

            let background;
            if (this.distanceCurrentDate > 0)
              background = `linear-gradient(to right, var(--secondary-color) 0, var(--secondary-color) ${gradient}px, var(--secondary-color-20) ${gradient}px, var(--secondary-color-20) 100%)`;
            else if (this.distanceCurrentDate === -2)
              background = "var(--secondary-color)";
            else background = "var(--secondary-color-20)";

            this.$refs.lifetimeLine.style.width = `${
              endDateLeftOffset - startDateLeftOffset
            }px`;
            this.$refs.lifetimeLine.style.left = `${startDateLeftOffset}px`;
            this.$refs.lifetimeLine.style.background = background;
          } else {
            this.$refs.lifetimeLine.style.display = "none";
          }
        }
      },
      setStages: function () {
        if (
          this.$refs.timelineCtn != undefined &&
          this.permissionsStages.read
        ) {
          // Define with depending on how many months selected
          let stageElement = undefined;
          for (let i = 0; i < this.orderedStages.length; i++) {
            const row = this.orderedStages[i]; // row(array of stages) of orderedStages (array of rows)
            const marginTop = i * 0;
            // margin between stage rows
            if (row.length > 0) {
              for (let st = 0; st < row.length; st++) {
                stageElement = document.getElementById(
                  "timeline-stage-" + this.comeFrom + "-" + row[st].getId
                );
                const baselineSelected: IBaseLineDx = row[
                  st
                ].getBaselineByVersion(this.versionDx);
                const startDate: Date = new Date(baselineSelected.startedDate);
                const endDate: Date = new Date(baselineSelected.endDate);
                if (this.showStages) {
                  // Distance from the start of the timeline until the start/end of the stage
                  const leftDistance = this.calculateChartPosition(startDate);
                  const rightDistance = this.calculateChartPosition(endDate);

                  const stageWidth = rightDistance - leftDistance;

                  if (
                    this.currentDate >= startDate &&
                    this.currentDate <= endDate
                  ) {
                    const gradient = this.distanceCurrentDate - leftDistance;
                    switch (this.distanceCurrentDate) {
                      case -1:
                        stageElement.style.background = `var(--${
                          row[st].getColor ? row[st].getColor : "bright-purple"
                        }-20)`;
                        break;
                      case -2:
                        stageElement.style.background = `var(--${
                          row[st].getColor ? row[st].getColor : "bright-purple"
                        })`;
                        break;
                      default:
                        stageElement.style.border = "0px";
                        stageElement.style.background = `linear-gradient(to right, var(--${
                          row[st].getColor ? row[st].getColor : "bright-purple"
                        }) 0, var(--${
                          row[st].getColor ? row[st].getColor : "bright-purple"
                        }) ${gradient}px, var(--${
                          row[st].getColor
                        }-20) ${gradient}px, var(--${
                          row[st].getColor ? row[st].getColor : "bright-purple"
                        }-20) 100%)`;
                        break;
                    }
                  } else if (this.currentDate > endDate) {
                    stageElement.style.background = `var(--${
                      row[st].getColor ? row[st].getColor : "bright-purple"
                    })`;
                  } else {
                    stageElement.style.background = `var(--${
                      row[st].getColor ? row[st].getColor : "bright-purple"
                    }-20)`;
                  }
                  stageElement.style.marginTop = `${marginTop}px !important`;
                  stageElement.style.left = `${leftDistance}px`;
                  stageElement.style.width = `${stageWidth}px`;
                  stageElement.style.visibility = "visible";
                } else {
                  stageElement.style.visibility = "hidden";
                }
              }
            }
          }
        }
      },
      stageTitleFits(stageStartDate: string, stageEndDate: string): boolean {
        const sDate = this.$moment(stageStartDate).startOf("day");
        const eDate = this.$moment(stageEndDate).startOf("day");
        const diffDays = eDate.diff(sDate, "days") + 1; // +1 to include start date day
        switch (this.monthsNum) {
          case 1:
            return diffDays > 4;
          case 3:
            return diffDays > 7;
          case 6:
            return diffDays > 15;
          case 9:
            return diffDays > 22;
          default:
            return true;
        }
      },
      // Computes the number of months difference between two dates
      monthDiff(dateFrom, dateTo): number {
        const monthDiff =
          dateTo.getMonth() -
          dateFrom.getMonth() +
          12 * (dateTo.getFullYear() - dateFrom.getFullYear());
        return monthDiff <= 0 ? 0 : monthDiff;
      },
      calculateChartPosition: function (
        itemDate: Date,
        extraOffset: number = 0
      ): Number {
        /**
         * Calculation of the left offset from project start date month to item date month (Day 1 of each month).
         * - monthWidth: Chart month width based on chart selected months view, equal for each month
         * - monthDiff(): Absolute number of months difference between project start date month and item date month
         */
        const itemMonthPosition =
          this.monthWidth * this.monthDiff(this.projectStartDate, itemDate);

        // Calculation of the item's month day width based on the total number of days of that month
        const dayWidth = this.monthWidth / this.getDaysInMonth(itemDate);

        // Calculation of the item's left offset within the item's start date month
        const itemMonthDayPosition = itemDate.getDate() * dayWidth;

        return itemMonthPosition + itemMonthDayPosition - extraOffset;
      },
      setEventsRequests: function (): void {
        if (
          this.$refs.timelineCtn != undefined &&
          this.permissionsEvents.read
        ) {
          this.eventLinks.predecessors = [];
          this.eventLinks.affecteds = [];
          let event;
          let eventElement = undefined;

          for (let ev = 0; ev < this.orderedTimelineChartItems.length; ev++) {
            // Check if there is an array of events in the same day
            if (this.orderedTimelineChartItems[ev].hasOwnProperty("initDate")) {
              event = this.orderedTimelineChartItems[ev].items[0];
              eventElement = document.getElementById(
                "item-group-" + this.comeFrom + "-" + event.id
              );
            } else {
              event = this.orderedTimelineChartItems[ev];
              eventElement = document.getElementById(
                "item-" + this.comeFrom + "-" + event.id
              );
            }

            const eventDate = new Date(event.date);
            // Position the item in the chart based on its date and centered by its own width (half)
            const eventPosition = this.calculateChartPosition(
              eventDate,
              eventElement.offsetWidth / 2
            );
            // SET EVENTS LINKS
            let items = [];
            if (this.orderedTimelineChartItems[ev].hasOwnProperty("initDate"))
              items = this.orderedTimelineChartItems[ev].items;
            else {
              items.push(this.orderedTimelineChartItems[ev]);
            }
            for (const it of items) {
              if (it.type == "event") {
                const eventBaseline: IBaseLineDx = this.eventsDx
                  .find(eventDx => eventDx.getId == it.id)
                  .getBaselineByVersion(this.versionDx);
                // If event is predecessor of an event
                if (eventBaseline.affecteds.length > 0) {
                  this.eventLinks.predecessors.push(
                    new PredecessorLink({
                      id: it.id,
                      status: eventBaseline.status,
                      startedDate: new Date(eventBaseline.startedDate),
                      left: eventPosition
                    })
                  );
                }
                // If event is affected of an event
                if (eventBaseline.predecessors.length > 0) {
                  this.eventLinks.affecteds.push({
                    id: it.id,
                    predecessorId: eventBaseline.predecessors[0],
                    left: eventPosition
                  });
                }
              }
            }

            // SET EVENT OPACITY DEPENDING ON CURRENT DATE
            if (this.currentDate.getTime() < eventDate.getTime()) {
              eventElement.style.opacity = 0.2;
              eventElement.style.top = `${this.$refs.timelineItem[
                ev
              ].getAttribute("top")}`;
            } else {
              eventElement.style.top = `${eventElement.getAttribute("top")}px`;
            }
            eventElement.style.left = `${eventPosition}px`;
          }
        }
      },
      setEventsPredecessorAndAffectedLinks: function () {
        if (this.$refs.firedLinks != undefined) {
          this.$refs.firedLinks.innerHTML = "<div ref='firedLinks'></div>";
          for (let i in this.eventLinks.predecessors) {
            if (
              this.eventLinks.predecessors[i].getStatus ==
              eventStates.pendingToConfirm.name
            ) {
              const node = document.createElement("div");
              node.classList.add("item-link");
              if (
                this.currentDate.getTime() >
                  this.eventLinks.predecessors[i].getStartedDate.getTime() &&
                this.currentDate.getTime() <=
                  this.eventLinks.predecessors[i].getStartedDate.getTime() +
                    this.twoDaysInMilliseconds
              ) {
                node.classList.add("item-link-fire");
              } else {
                node.classList.add("item-link-fireoff");
              }
              node.id = `${this.comeFrom}-predecessor-${this.eventLinks.predecessors[i].getId}`;
              node.style.left = `${
                this.eventLinks.predecessors[i].getLeft + 10
              }px`;
              this.$refs.firedLinks.appendChild(node);
            }
          }
        }
      },
      getTimelineItemObject(
        id: string,
        type: string
      ): EventDx | RequestDx | StageDx | null {
        switch (type) {
          case "event":
            return this.eventsDx.find(event => event.getId == id);
          case "request":
            return this.requestsDx.find(request => request.getId == id);
          case "stage":
            return this.stagesDx.find(stage => stage.getId == id);
          default:
            return null;
        }
      },
      showToday: function () {
        this.closeTimelineItem();
        this.chartScroll =
          this.distanceCurrentDate - this.currentDateScrollOffset;
      },
      openTimelineItem: function (id: string, type: string): void {
        if (!this.dragscrolling) {
          this.closeTimelineItem();
          const timelineItem: any = this.getTimelineItemObject(id, type);
          let showDiv: boolean = false;
          if (timelineItem) {
            if (this.comeFrom == "timeline") {
              const rect = document
                .getElementById("timeline-chart-timeline")
                .getBoundingClientRect();
              window.scrollTo({
                top: rect.bottom - rect.top + 170,
                behavior: "smooth"
              });
              // As the TimelineItem opening causes a component update wich triggers
              // the chart autoscroll, we need to set the scroll position to the
              // selected item in the chart
              this.chartScroll = this.calculateChartPosition(
                new Date(
                  type === "request"
                    ? timelineItem.date
                    : timelineItem.getLastHistoryBaseline.startedDate
                ),
                this.itemsScrollOffset
              );
            }
            showDiv = true;
            this.$emit("timelineItemSelectionChanged", { id: id, type: type });
          }
          this.timelineItemInfo = {
            item: timelineItem,
            type: type,
            projectType: this.projectCore.getProjectType,
            show: showDiv
          };
          if (type === "event") {
            this.$root.$emit("emitEventId", id);
          } else if (type === "stage") {
            this.$root.$emit("emitStageId", id);
          } else if (type === "request") {
            this.$root.$emit("emitRequestId", id);
          }
        }
      },
      closeTimelineItem: function () {
        this.timelineItemInfo = {
          show: false,
          item: undefined,
          type: undefined,
          projectType: undefined
        };
        this.$root.$emit("emitEventId", undefined);
        this.$root.$emit("emitStageId", undefined);
        this.$root.$emit("emitRequestId", undefined);
        const timelineExternalContainer = document.getElementById(
          "timeline-external-ctn"
        );
        if (timelineExternalContainer != null) {
          this.chartScroll = timelineExternalContainer.scrollLeft;
        }
        this.$emit("timelineItemSelectionChanged", {
          id: undefined,
          type: undefined
        });
      },
      showEventLink(predecessorId: string) {
        const link = document.getElementById(
          this.comeFrom + "-predecessor-" + predecessorId
        );
        if (link) {
          const predecessor: PredecessorLink =
            this.eventLinks.predecessors.find(
              pre => pre.getId == predecessorId
            );
          const affected = this.eventLinks.affecteds.find(
            aff => aff.predecessorId == predecessorId
          );

          link.style.left = `${predecessor.getLeft + 10}px`;
          if (affected != undefined) {
            link.style.width = `${affected.left - predecessor.getLeft - 9}px`;
          } else {
            link.style.width = `${
              this.timelineContainerWidth - predecessor.getLeft - 9
            }px`;
          }
        }
      },
      hideEventLink(predecessorId: string) {
        const link = document.getElementById(
          this.comeFrom + "-predecessor-" + predecessorId
        );
        if (link) {
          const predecessor: PredecessorLink =
            this.eventLinks.predecessors.find(
              pre => pre.getId == predecessorId
            );
          link.style.left = `${predecessor.getLeft + 10}px`;
          link.style.width = "0px";
        }
      },
      highlightAffected(predecessorId: string) {
        const affected = this.eventLinks.affecteds.find(
          aff => aff.predecessorId == predecessorId
        );
        const predecessor: PredecessorLink = this.eventLinks.predecessors.find(
          pre => pre.getId == predecessorId
        );
        if (affected != undefined) {
          const element = document.getElementById(
            `item-group-${this.comeFrom}-${affected.id}`
          );
          if (element) {
            if (
              this.currentDate.getTime() >
                predecessor.getStartedDate.getTime() &&
              this.currentDate.getTime() <=
                predecessor.getStartedDate.getTime() +
                  this.twoDaysInMilliseconds
            ) {
              element.classList.add("item-link-fire");
            } else {
              element.classList.add("item-link-fireoff");
            }
          }
        }
      },
      undoHighlightAffected(predecessorId: string) {
        const affected = this.eventLinks.affecteds.find(
          aff => aff.predecessorId == predecessorId
        );
        if (affected != undefined) {
          const element = document.getElementById(
            `item-group-${this.comeFrom}-${affected.id}`
          );
          if (element) {
            element.classList.remove("item-link-fire");
            element.classList.remove("item-link-fireoff");
          }
        }
      },
      updateDataProject: function () {
        this.currentDate = new Date();
        this.projectStartDate = new Date(this.projectCore.getStartDate);
        if (this.projectCore.getEndDate !== null) {
          this.projectEndDate = new Date(this.projectCore.getEndDate);
          this.fixedFinalDate = true;
        } else {
          this.fixedFinalDate = false;
        }
        this.timelineItemInfo = {
          show: false,
          item: undefined,
          type: undefined,
          projectType: undefined
        };
      },
      // Order events and requests (ascending by date) into same array and return it
      orderEvs(): {
        type: string;
        id: string;
        name: string;
        date: Date;
        top: number;
        width: number;
        iconSrc: string;
        tag: string;
      }[] {
        // Type = { event, request }
        let timelineChartItems: {
          type: string;
          id: string;
          name: string;
          date: Date;
          top: number;
          width: number;
          iconSrc: string;
          tag: string;
        }[] = [];

        let requestDxItems: {
          type: string;
          id: string;
          name: string;
          date: Date;
          top: number;
          width: number;
          iconSrc: string;
          tag: string;
        }[] = [];

        let eventsDxItems: {
          type: string;
          id: string;
          name: string;
          date: Date;
          top: number;
          width: number;
          iconSrc: string;
          tag: string;
        }[] = [];

        for (const requestDx of this.requestsDx) {
          requestDxItems.push({
            type: "request",
            id: requestDx.getId,
            name: requestDx.getIssue,
            date: requestDx.getDate,
            top: 0,
            width: 30,
            iconSrc:
              "services/deliveryexperience/timeline/" +
              getRequestEventType(requestDx.getType).icon.normal,
            tag: requestDx.getTag
          });
        }

        // First filter events that has baseline with selected version or posterior.
        let eventsCopied: EventDx[] = this.eventsDx.filter(eventDx =>
          eventDx.getBaselineByVersion(this.versionDx)
        );
        // Make a copy of it
        eventsCopied = eventsCopied.map(
          eventDx => new EventDx(JSON.parse(JSON.stringify(eventDx)))
        );

        for (const eventDx of eventsCopied) {
          const eventDxBaseline: IBaseLineDx = eventDx.getBaselineByVersion(
            this.versionDx
          );
          const eventDxIcon: { icon: string; width: number; top: number } =
            this.getIcon(
              eventDx.getEventType,
              eventDxBaseline.status,
              eventDxBaseline.startedDate
            );
          eventsDxItems.push({
            type: "event",
            id: eventDx.getId,
            name: eventDx.getName,
            date: eventDxBaseline.startedDate,
            top: eventDxIcon.top,
            width: eventDxIcon.width,
            iconSrc: eventDxIcon.icon,
            tag: eventDx.getTag
          });
        }

        timelineChartItems = [];
        if (this.permissionsEvents.read) {
          timelineChartItems = timelineChartItems.concat(eventsDxItems);
        }
        if (this.permissionsRequests.read) {
          timelineChartItems = timelineChartItems.concat(requestDxItems);
        }

        timelineChartItems.sort(function (a, b) {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
        });
        if (timelineChartItems.length > 0) {
          this.firstTimelineChartItemDate = new Date(
            timelineChartItems[0].date
          );
          this.lastTimelineChartItemDate = new Date(
            timelineChartItems[timelineChartItems.length - 1].date
          );
        } else {
          this.firstTimelineChartItemDate = new Date();
          this.lastTimelineChartItemDate = new Date();
        }
        //Array of timelineChartItem instead of EventDx[], in order to include RequestsDx
        this.orderedTimelineChartItemsAux = timelineChartItems; //Save a copy
        return timelineChartItems;
      },
      /* Group timeline items popover close handling */
      groupPopoverCloseHandler: function (e) {
        let clickOverEventGroup = false;
        // Check if click target is inside any of the event groups containers
        const eventGroupContainers =
          document.getElementsByClassName("item-group-ctn");
        for (let i = 0; i < eventGroupContainers.length; i++) {
          if (clickOverEventGroup) {
            break;
          }
          clickOverEventGroup = eventGroupContainers[i].contains(
            e.target as HTMLElement
          );
        }

        // Blur the event group popover triggers ('plus' icon) if
        // there's a click outside of the group
        if (!clickOverEventGroup) {
          document
            .querySelectorAll("[id^='item-group']")
            .forEach((el: HTMLElement) => el.blur());
        }
      },
      /* Group timeline items depending on date nearness for each months view */
      groupItems(
        items: {
          type: string;
          id: string;
          name: string;
          date: Date;
          top: number;
          width: number;
          iconSrc: string;
          tag: string[];
        }[]
      ) {
        let itemsToDisplay = []; // Will contain ordered individual & grouped timeline items

        let groupedItems: {
          items: {
            type: string;
            id: string;
            name: string;
            date: Date;
            top: number;
            width: number;
            iconSrc: string;
            tag: string[];
          }[];
          initDate: Date;
          endDate: Date;
        } = { items: [], initDate: null, endDate: null };
        if (this.filterTags.length) {
          let result = [];
          for (const item of items) {
            if (item.tag != undefined) {
              for (const tag of this.filterTags) {
                if (item.tag == tag) {
                  result.push(item);
                  break;
                }
              }
            }
          }
          items = result;
        }
        /* Grouping algorithm */
        let next: number;
        for (let e = 0; e < items.length; e++) {
          next = e + 1;
          const currentItem = items[e];
          if (
            next < items.length &&
            this.groupCondition(currentItem, items[next]) &&
            !this.groupDaysLimit(groupedItems.items[0], items[next])
          ) {
            // If current iteration item and next one dates are close,
            // and if it already exists a current group with items in and the date of the first item and the "next" item date
            // aren't to far too (groupDaysLimit), we push current one to a group
            groupedItems.items.push(currentItem);
          } else {
            // Otherwise, if current iteration item is NOT close to the NEXT one (or if it's actually the last one),
            // we must check if it WAS close the previous iteration item, that is, if there are items in the group.
            if (groupedItems.items.length) {
              groupedItems.items.push(currentItem);

              // As we will close the group below, we set group's init & end date
              groupedItems.initDate = groupedItems.items[0].date;
              groupedItems.endDate =
                groupedItems.items[groupedItems.items.length - 1].date;

              itemsToDisplay.push(JSON.parse(JSON.stringify(groupedItems)));
              groupedItems.items = []; // Closing group
            } else {
              // If current iteration item did finally not belong to any group, we push it as individual
              itemsToDisplay.push(currentItem);
            }
          }
        }

        this.orderedTimelineChartItems = itemsToDisplay;
      },
      /* Check if two timeline items dates are close enough to be grouped */
      groupCondition(event, nextEvent): boolean {
        let condition;
        const eventDate = this.$moment(event.date).startOf("day");
        const nextEventDate = this.$moment(nextEvent.date).startOf("day");
        const differenceDays = nextEventDate.diff(eventDate, "days");

        /* Group depending on months view:
          - months = 1 => Group same day events/requests
          - months = 3 => Group events/requests in a range of 3 days
          - months = 6 => Group events/requests in a range of 5 days
          - months = 9 => Group events/requests in a range of 7 days
        */
        switch (this.monthsNum) {
          case 3:
            condition = differenceDays < 3;
            break;
          case 6:
            condition = differenceDays < 5;
            break;
          case 9:
            condition = differenceDays < 7;
            break;
          default:
            condition = differenceDays == 0;
        }
        return condition;
      },
      // Check if an existing group is not longer than determined days
      groupDaysLimit(firstItem, nextItem): boolean {
        // If there's no element in the group yet, firstItem will have been passed as undefined.
        if (firstItem != undefined) {
          const firstDate = this.$moment(firstItem.date).startOf("day");
          const nextDate = this.$moment(nextItem.date).startOf("day");
          const differenceDays = nextDate.diff(firstDate, "days");
          return differenceDays >= this.monthsNum;
        } else {
          return false;
        }
      },
      orderStages() {
        let dis = this;
        let filteredStages = this.stagesDx;
        if (this.filterTags.length) {
          let result = [];
          for (const stage of this.stagesDx) {
            if (stage.getTag != undefined) {
              for (const tag of this.filterTags) {
                if (stage.getTag == tag) {
                  result.push(stage);
                  break;
                }
              }
            }
          }
          filteredStages = result;
        }
        // First filter stages that has baseline with selected version or posterior.
        let stagesCopied: StageDx[] = filteredStages.filter(stageDx =>
          stageDx.getBaselineByVersion(this.versionDx)
        );
        // Sort by date
        stagesCopied.sort(function (a, b) {
          const baselineSelectedA: IBaseLineDx = a.getBaselineByVersion(
            dis.versionDx
          );
          const baselineSelectedB: IBaseLineDx = b.getBaselineByVersion(
            dis.versionDx
          );
          const startA = new Date(
            new Date(baselineSelectedA.startedDate).getFullYear(),
            new Date(baselineSelectedA.startedDate).getMonth(),
            new Date(baselineSelectedA.startedDate).getDate()
          );
          const startB = new Date(
            new Date(baselineSelectedB.startedDate).getFullYear(),
            new Date(baselineSelectedB.startedDate).getMonth(),
            new Date(baselineSelectedB.startedDate).getDate()
          );
          const endA = new Date(
            new Date(baselineSelectedA.endDate).getFullYear(),
            new Date(baselineSelectedA.endDate).getMonth(),
            new Date(baselineSelectedA.endDate).getDate()
          );
          const endB = new Date(
            new Date(baselineSelectedB.endDate).getFullYear(),
            new Date(baselineSelectedB.endDate).getMonth(),
            new Date(baselineSelectedB.endDate).getDate()
          );
          return startA > startB
            ? 1
            : startA < startB
            ? -1
            : endA > endB
            ? 1
            : endA < endB
            ? -1
            : 0;
        });

        let tempOrderedStages = [];
        // Make a copy of it
        let projectStages: StageDx[] = stagesCopied.map(
          stageDx => new StageDx(JSON.parse(JSON.stringify(stageDx)))
        );
        if (projectStages.length > 0) {
          this.firstStageStartedDate = new Date(
            projectStages[0].getBaselineByVersion(this.versionDx).startedDate
          );
          this.lastStageEndDate = new Date(
            projectStages[projectStages.length - 1].getBaselineByVersion(
              this.versionDx
            ).endDate
          );
        } else {
          this.firstStageStartedDate = new Date();
          this.lastStageEndDate = new Date();
        }

        // Sorting the stages
        for (let i = 0; i < projectStages.length; i++) {
          const baselineSelected1: IBaseLineDx = projectStages[
            i
          ].getBaselineByVersion(this.versionDx);
          let found = false;
          let j = 0;
          while (!found && j < tempOrderedStages.length) {
            const baselineSelected2: IBaseLineDx = tempOrderedStages[j][
              tempOrderedStages[j].length - 1
            ].getBaselineByVersion(this.versionDx);
            if (
              new Date(
                new Date(baselineSelected1.startedDate).getFullYear(),
                new Date(baselineSelected1.startedDate).getMonth(),
                new Date(baselineSelected1.startedDate).getDate()
              ) >
              new Date(
                new Date(baselineSelected2.endDate).getFullYear(),
                new Date(baselineSelected2.endDate).getMonth(),
                new Date(baselineSelected2.endDate).getDate()
              )
            ) {
              tempOrderedStages[j].push(projectStages[i]);
              found = true;
            }
            j++;
          }

          if (!found) {
            tempOrderedStages.push([projectStages[i]]);
          }
        }
        this.orderedStages = tempOrderedStages;
      },
      getProjectEventTypes() {
        this.projectEventTypes = getEventTypesByProjectType(
          this.projectCore.getProjectType
        );
      },
      getIcon(
        type: string,
        status: string,
        startedDate: Date
      ): { icon: string; width: number; top: number } {
        const eventStartDate = new Date(startedDate);
        let result: { icon: string; width: number; top: number };
        let eventIcons: { normal: string; fire: string; fireOff: string };
        for (const event in this.projectEventTypes) {
          if (this.projectEventTypes[event].type === type) {
            eventIcons = this.projectEventTypes[event].icon;
            break;
          }
        }

        if (
          status == eventStates.pendingToConfirm.name &&
          type != eventTypes[projectTypes.capacity.name].events.billing.type
        ) {
          const firedFolder = "services/deliveryexperience/timeline/fired/";
          // If event startDate is inside window of two days
          if (
            this.currentDate.getTime() > eventStartDate.getTime() &&
            this.currentDate.getTime() <=
              eventStartDate.getTime() + this.twoDaysInMilliseconds
          ) {
            result = {
              icon: firedFolder + eventIcons.fire,
              width: 40,
              top: -19
            };
          } // If event startDate is outside window of two days
          else {
            result = {
              icon: firedFolder + eventIcons.fireOff,
              width: 40,
              top: -15
            };
          }
        } else {
          result = {
            icon: "services/deliveryexperience/timeline/" + eventIcons.normal,
            width: 30,
            top: 0
          };
        }
        return result;
      },
      stagePendingToConfirmClass(
        status: string,
        endDate: Date
      ): { "stage-fire": boolean; "stage-fireoff": boolean } {
        const stageClass: string = this.stagePendingToConfirm(status, endDate);
        if (stageClass == "stage-fire") {
          return { "stage-fire": true, "stage-fireoff": false };
        } else if (stageClass == "stage-fireoff") {
          return { "stage-fire": false, "stage-fireoff": true };
        } else {
          return { "stage-fire": false, "stage-fireoff": false };
        }
      },
      stagePendingToConfirm(status: string, endDate: Date): string | undefined {
        const stageEndDate = new Date(endDate);
        if (status == eventStates.pendingToConfirm.name) {
          if (
            this.currentDate.getTime() > stageEndDate.getTime() &&
            this.currentDate.getTime() <=
              stageEndDate.getTime() + this.twoDaysInMilliseconds
          ) {
            return "stage-fire";
          } else {
            return "stage-fireoff";
          }
        }
        return undefined;
      },
      showStageLink(predecessorFired: string, affectedId: string) {
        const linkedAffected = document.getElementById(
          `timeline-stage-${this.comeFrom}-${affectedId}`
        );
        if (
          linkedAffected != undefined &&
          predecessorFired != undefined &&
          affectedId != undefined
        ) {
          if (predecessorFired == "stage-fire") {
            linkedAffected.classList.add("stage-linked-fire");
          } else {
            linkedAffected.classList.add("stage-linked-fireoff");
          }
        }
      },
      hideStageLink(predecessorFired: string, affectedId: string) {
        const linkedAffected = document.getElementById(
          `timeline-stage-${this.comeFrom}-${affectedId}`
        );
        if (
          linkedAffected != undefined &&
          predecessorFired != undefined &&
          affectedId != undefined
        ) {
          if (predecessorFired == "stage-fire") {
            linkedAffected.classList.remove("stage-linked-fire");
          } else {
            linkedAffected.classList.remove("stage-linked-fireoff");
          }
        }
      },
      findNextEvent: function () {
        if (this.comeFrom == "timeline" || this.comeFrom == "planner-1") {
          let items;
          let found = false;
          let i = 0;
          while (!found && i < this.orderedTimelineChartItems.length) {
            if (this.orderedTimelineChartItems[i].hasOwnProperty("initDate")) {
              items = this.orderedTimelineChartItems[i].items;
              for (const item of items) {
                found = this.setNextEvent(
                  item,
                  `item-group-${this.comeFrom}-${items[0].id}`
                );
              }
            } else {
              const individualItem = this.orderedTimelineChartItems[i];
              found = this.setNextEvent(
                individualItem,
                `item-${this.comeFrom}-${individualItem.id}`
              );
            }
            i++;
          }
        }
      },
      setNextEvent: function (item, target): boolean {
        if (item.type == "event" && new Date(item.date) >= this.currentDate) {
          this.nextEvent.target = target;
          this.nextEvent.id = item.id;
          this.nextEvent.days = this.$moment(item.date)
            .startOf("day")
            .diff(
              this.$moment(this.currentDate.getTime()).startOf("day"),
              "days"
            );
          this.nextEvent.name = item.name;
          return true;
        }
        return false;
      },
      awaitClosePopover: async function () {
        this.showPopover = true;
        setTimeout(() => (this.showPopover = false), 5000);
      },
      openTimelineItemForm(type) {
        this.$emit("openTimelineItemForm", type);
      },
      dragscrollEndTimeout: function () {
        setTimeout(() => (this.dragscrolling = false), 200);
      },
      /**
       * This method ensures that chart scroll stays as the
       * last manually moved scroll position, to prevent scroll
       * jump when, for example, popovers are triggered or
       * items filters are applied.
       */
      fixScroll: function () {
        this.chartScroll = this.currentScroll;
      }
    },
    watch: {
      versionDx: async function () {
        await this.groupItems(this.orderEvs());
        await this.orderStages();
        this.refreshComponents();
      },
      eventsDx: async function () {
        this.refreshTimelineItems();
      },
      stagesDx: async function () {
        this.refreshStages();
      },
      requestsDx: async function () {
        this.refreshTimelineItems();
      },
      monthsNum: async function () {
        await this.groupItems(this.orderedTimelineChartItemsAux);
        this.refreshComponents();
        this.chartScroll =
          this.distanceCurrentDate - this.currentDateScrollOffset;
      },
      /**
       * Due to popovers reactive rendering, vue-autoscroll detects those DOM updates in the
       * div under which popover are rendered, and where v-autoscroll directive is set.
       *
       * Therefore, autoscroll applies the chartScroll value, causing the chart to jump
       * to where is set at the moment of showing all the popovers.
       *
       * To prevent this behaviour, we store the current chart scroll via onscroll
       * event handler and set it here to chartScroll variable, so autoscroll applies it
       * exactly where the scroll is located at that moment.
       */
      showItemsPopovers: function () {
        this.fixScroll();
      },
      showStages: function () {
        this.setStages();
      },
      filterTags: async function () {
        this.refreshTimelineItems();
        this.refreshStages();
      },
      // In case user starts scrolling before popover closes, we force it to close
      dragscrolling: function () {
        if (this.dragscrolling) {
          this.showPopover = false;
        }
      }
    }
  };
