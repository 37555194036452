





















  import RadialProgressChart from "@bunetz/radial-progress-chart-with-tooltip";
  import { projectTypes } from "../../../_helpers/projectMetadata";
  import StageDx from "../../../models/deliveryexperience/StageDx";

  export default {
    name: "ProgressBar",
    props: {
      project: Object,
      href: String,
      id: String,
      eventsDx: Array,
      stagesDx: Array
    },
    data() {
      return {
        chart: Object,
        legend: [],
        text: {
          eventsCompleted: this.$t("eventsCompleted"),
          stagesCompleted: this.$t("stagesCompleted"),
          lifetime: this.$t("lifetime")
        }
      };
    },
    mounted() {
      this.createLiabilityRadialProgress();
    },
    methods: {
      async createLiabilityRadialProgress() {
        this.chart = await new RadialProgressChart("#" + this.id, {
          diameter: 200,
          stroke: {
            width: 30,
            gap: 2
          },
          series: this.progressBarSeries(this.project.getProjectType)
        });
      },
      progressBarSeries(projectType) {
        let series = [
          {
            title: this.text.eventsCompleted,
            labelStart: "\uF560",
            value: this.getEventsCompleted(),
            color: "var(--green)"
          },
          {
            title: this.text.stagesCompleted,
            labelStart: "\uF274",
            value: this.getStagePercentage(),
            color: "var(--bright-purple)"
          }
        ];
        if (projectType != projectTypes.liabilityEconomic.name) {
          series.push({
            title: this.text.lifetime,
            labelStart: "\uF1DA",
            value: this.getLifetimePercentage(),
            color: this.getLifetimeColor()
          });
        }
        this.legend = series;
        return series;
      },
      getCurrentStage: function (): StageDx {
        let currentStage: StageDx = null;
        if (this.project.getId != undefined) {
          for (const stage of this.stagesDx) {
            if (
              new Date(stage.getLastHistoryBaseline.startedDate) < new Date() &&
              new Date(stage.getLastHistoryBaseline.endDate) > new Date()
            )
              currentStage = stage;
          }
        }
        return currentStage;
      },
      getEventsCompleted: function (): number {
        let eventsCompleted = 0;
        let totalEvents = 0;
        if (this.project.getId != undefined) {
          const actualDate = new Date().getTime();
          for (const event of this.eventsDx) {
            ++totalEvents;
            if (
              new Date(event.getLastHistoryBaseline.startedDate).getTime() <
              actualDate
            )
              ++eventsCompleted;
          }
          if (totalEvents != 0)
            eventsCompleted = (eventsCompleted / totalEvents) * 100;
        }
        return Math.round(eventsCompleted);
      },
      getStagePercentage: function (): number {
        let stagesCompleted = 0;
        let totalStages = 0;
        if (this.project.getId != undefined) {
          const actualDate = new Date().getTime();
          for (const stage of this.stagesDx) {
            ++totalStages;
            if (
              new Date(stage.getLastHistoryBaseline.endDate).getTime() <
              actualDate
            )
              ++stagesCompleted;
          }
          if (totalStages != 0)
            stagesCompleted = (stagesCompleted / totalStages) * 100;
        }
        return Math.round(stagesCompleted);
      },
      getLifetimePercentage: function (): number {
        let lifetimePercentage = 0;
        if (this.project.getId != undefined) {
          const startDate = new Date(this.project.getStartDate);
          let endDate = null;
          if (this.project.getEndDate != null) {
            endDate = new Date(this.project.getEndDate);
          } else {
            endDate = new Date(startDate);
            endDate.setFullYear(startDate.getFullYear() + 1);
          }
          const currentDate = new Date();
          if (startDate.getTime() < currentDate.getTime()) {
            lifetimePercentage =
              ((currentDate.getTime() - startDate.getTime()) /
                (endDate.getTime() - startDate.getTime())) *
              100;
          } else {
            lifetimePercentage = 0;
          }
        }
        return Math.round(lifetimePercentage);
      },
      getLifetimeColor: function (): string | null {
        let color = null;
        if (this.project.getId != undefined) {
          if (this.getLifetimePercentage() > 100) {
            color = "rgba(255, 48, 76, 0.8)";
          } else {
            color = "rgba(0, 112, 173, 0.8)";
          }
        }
        return color;
      }
    }
  };
