import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import MarginCSP from "../../../models/csp/MarginCSP";
import marginService from "../../../services/csp/marginService";
import { RootState } from "../../index";

const namespaced = true;

interface MarginState {
  margins: MarginCSP[];
}

const state: MarginState = {
  margins: []
};

const mutations: MutationTree<MarginState> = {
  setMargins(state, margins: MarginCSP[]) {
    state.margins = margins;
  },
  addMargin(state, newMargin: MarginCSP) {
    state.margins.push(newMargin);
  },
  updateMargin(state, margin: MarginCSP) {
    const index = state.margins.findIndex(
      m => m.getCustomerId === margin.getCustomerId
    );
    if (index != -1) {
      state.margins.splice(index, 1, margin);
    }
  }
};

const getters: GetterTree<MarginState, RootState> = {
  getMarginCSPByCustomerId:
    state =>
    (customerId: string): MarginCSP => {
      return state.margins.find(margin => margin.getCustomerId == customerId);
    }
};

const actions: ActionTree<MarginState, RootState> = {
  async loadMarginsCSP({ commit }) {
    const margins: MarginCSP[] = await marginService.methods.getMargins();
    commit("setMargins", margins);
  },
  async postMarginCSP({ commit }, margin: MarginCSP) {
    const newMargin: MarginCSP = await marginService.methods.postMargin(margin);
    commit("addMargin", newMargin);
  },
  async putMarginCSP({ commit }, margin: MarginCSP) {
    const updateMargin: MarginCSP = await marginService.methods.putMargin(
      margin
    );
    commit("updateMargin", updateMargin);
  }
};

export const marginModuleCSP: Module<MarginState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
