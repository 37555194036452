



































































































































































































































































  import Treeselect from "@riophae/vue-treeselect";
  import "@riophae/vue-treeselect/dist/vue-treeselect.css";
  import {
    getTimelineItemIcon,
    getTimelineItemTypeFromSubtype
  } from "../../../../_helpers/eventTypesStates";
  import helpers from "../../../../_helpers/helperFunctions";
  import notifications from "../../../../_helpers/notifications";
  import { requestStates } from "../../../../_helpers/requestStates";
  import NotificationDx from "../../../../models/deliveryexperience/NotificationDx";
  import PeopleDx from "../../../../models/deliveryexperience/PeopleDx";
  import PeopleIcon from "../../../../view-models/PeopleIcon";
  import DocsAttach from "../../../view_elements/DocsAttach.vue";
  import PeopleIcons from "../../../view_elements/PeopleIcons.vue";
  import CapsuleMultiselect from "../../../view_elements/CapsuleMultiselect.vue";

  export default {
    name: "AddDocumentModal",
    props: {
      id: String,
      projectId: Number,
      eventId: String,
      stageId: String,
      requestId: String,
      itemPreselected: Object // Prop used to reduce triple check for ev/st/req IDs existence
    },
    components: {
      Treeselect,
      PeopleIcons,
      DocsAttach,
      CapsuleMultiselect
    },
    data() {
      return {
        requestStatesHelper: requestStates,
        isTimelineItemSelection: false, // Is true for timeline item seflection (both external and treeselect selections) and false for project selection
        timelineItemSubtype: undefined,
        attachedTimelineItem: { type: undefined, id: undefined },
        attachedTimelineItemTreeselectModal: undefined,
        docsVisibleToAll: false,
        loading: false,
        saveDocsAttached: false,
        docsReady: false,
        readers: [],
        text: {
          all: this.$tc("all", 1),
          stage: this.$tc("stages"),
          event: this.$tc("events"),
          request: this.$tc("requests"),
          upload: this.$t("upload"),
          newDocument: this.$t("newDocument"),
          cancel: this.$t("cancel"),
          timelineItem: this.$tc("timelineItem"),
          selectAnItem: this.$t("selectAnItem"),
          readers: this.$t("readers"),
          noMembersForDocReaders: this.$t("noMembersForDocReaders"),
          project: this.$tc("project", 1),
          projectPeopleIncludedByDefault: this.$t(
            "projectPeopleIncludedByDefault"
          )
        }
      };
    },
    computed: {
      /**
       * Gets and formats all events/stages/requests to display in treeselect format
       * on the timeline item selection
       */
      stagesEventsRequests(): {
        id: string;
        label: string;
        children: Object;
      }[] {
        let stages = [];
        let events = [];
        let requests = [];
        for (const stage of this.getStagesDxByProjectId(this.projectId)) {
          stages.push({
            id: stage.getId,
            label: stage.getName,
            type: "stage",
            color: stage.getColor
          });
        }
        for (const event of this.getEventsDxByProjectId(this.projectId)) {
          events.push({
            id: event.getId,
            label: event.getName,
            type: event.getEventType
          });
        }
        for (const request of this.getRequestsDxByProjectId(this.projectId)) {
          requests.push({
            id: request.getId,
            label: request.getIssue,
            type: request.getType
          });
        }
        return [
          { id: "stages", label: this.text.stage, children: stages },
          { id: "events", label: this.text.event, children: events },
          { id: "requests", label: this.text.request, children: requests }
        ];
      },
      timelineItemTypeFromSubtype(): string {
        return getTimelineItemTypeFromSubtype(this.timelineItemSubtype);
      },
      getPeopleDxIdsOfProject(): string[] {
        return this.getPeopleDxByProjectId(this.projectId).map(
          personDx => personDx.getId
        );
      },
      getMembersOfProjects(): PeopleIcon[] {
        return this.getPeopleDxByProjectId(this.projectId).map(people => {
          return PeopleIcon.newPeopleIconFromDx(people);
        });
      },
      timelineItemDocReadersPeopleIcon(): PeopleIcon[] {
        return this.mapPeopleDxToPeopleIcon(
          this.getTimelineItemDocReadersPeopleDx()
        );
      },
      readersPeopleIcon(): PeopleIcon[] {
        return this.readers.map(reader =>
          PeopleIcon.newPeopleIconFromPeople(reader)
        );
      },
      docsEventId(): string {
        if (this.eventId) {
          return this.eventId;
        } else {
          return this.timelineItemTypeFromSubtype === "event" &&
            this.attachedTimelineItem
            ? this.attachedTimelineItem.getId
            : null;
        }
      },
      docsStageId(): string {
        if (this.stageId) {
          return this.stageId;
        } else {
          return this.timelineItemTypeFromSubtype === "stage" &&
            this.attachedTimelineItem
            ? this.attachedTimelineItem.getId
            : null;
        }
      },
      docsRequestId(): string {
        if (this.requestId) {
          return this.requestId;
        } else {
          return this.timelineItemTypeFromSubtype === "request" &&
            this.attachedTimelineItem
            ? this.attachedTimelineItem.getId
            : null;
        }
      },
      docsReaders(): string[] {
        if (this.isTimelineItemSelection) {
          if (
            !this.docsVisibleToAll &&
            this.timelineItemTypeFromSubtype !== "request"
          ) {
            return this.getTimelineItemDocReadersPeopleDx().map(
              personDx => personDx.getId
            );
          }
        } else if (this.readers.length > 0) {
          //project document but select specific readers
          return this.getPeopleDxByProjectId(this.projectId)
            .filter(people => {
              return this.readers.some(
                reader => people.getUsername == reader.getUsername
              );
            })
            .map(personDx => personDx.getId);
        }
        return [];
      },
      saveButtonIsActive(): boolean {
        if (this.loading) return false;
        if (this.isTimelineItemSelection) {
          if (
            this.attachedTimelineItem &&
            this.timelineItemTypeFromSubtype === "request" &&
            this.attachedTimelineItem.getStatus === requestStates.closed.name
          )
            return false;
          if (this.attachedTimelineItemTreeselectModal && this.docsReady) {
            return true;
          }
        } else {
          if (this.docsReady) {
            return true;
          }
        }
        return false;
      }
    },
    methods: {
      setTreeselectedItemSubtype(node): void {
        this.timelineItemSubtype = node.type;
      },
      getTimelineItemDocReadersPeopleDx(): PeopleDx[] {
        let itemDocReaders: PeopleDx[] = [];
        if (this.attachedTimelineItem) {
          switch (this.timelineItemTypeFromSubtype) {
            case "event":
              itemDocReaders = this.getPeopleDxByEventId(
                this.attachedTimelineItem.getId
              );
              break;
            case "stage":
              itemDocReaders = this.getPeopleDxByStageId(
                this.attachedTimelineItem.getId
              );
              break;
            case "request":
            default:
              return [];
          }
        }
        return itemDocReaders;
      },
      mapPeopleDxToPeopleIcon(peopleDx: PeopleDx[]): PeopleIcon[] {
        return peopleDx.map(personDx => {
          return PeopleIcon.newPeopleIconFromDx(personDx);
        });
      },
      getReaderName(firstName, lastName): string {
        return helpers.getUserFullName(firstName, lastName);
      },
      getTimelineItemIcon(
        timelineItemType: string,
        color?: string
      ): NodeRequire {
        return getTimelineItemIcon(timelineItemType, color);
      },
      async sendNotificationDx() {
        const notificationDx = new NotificationDx({
          itemName: this.getNotificationItemName(),
          projectId: this.projectId,
          itemType: this.getNotificationItemType(),
          itemId: this.isTimelineItemSelection
            ? this.attachedTimelineItem.getId
            : "none",
          recipients: this.getNotificationRecipients(),
          action: this.isTimelineItemSelection
            ? "docUploaded"
            : "docUploadedProject",
          author: this.getUser.getUsername
        });
        if (notificationDx.recipients.length)
          await this.postNotificationDx(notificationDx);
      },
      getNotificationItemName(): string | undefined {
        if (this.isTimelineItemSelection) {
          switch (this.timelineItemTypeFromSubtype) {
            case "event":
            case "stage":
              return this.attachedTimelineItem.getName;
            case "request":
              return this.attachedTimelineItem.getIssue;
            default:
              return undefined;
          }
        } else return "none";
      },
      getNotificationItemType(): string | undefined {
        if (this.isTimelineItemSelection) {
          switch (this.timelineItemTypeFromSubtype) {
            case "event":
              return this.attachedTimelineItem.getEventType;
            case "stage":
              return "stage";
            case "request":
              return this.attachedTimelineItem.getType;
            default:
              return undefined;
          }
        } else return "project";
      },
      getNotificationRecipients(): string[] {
        if (!this.isTimelineItemSelection) {
          const recipients = this.getPeopleDxByProjectId(this.projectId).map(
            p => p.userName
          );
          const index = recipients.indexOf(this.getUser.getUsername);
          if (index >= 0) recipients.splice(index, 1);
          return recipients;
        } else {
          return notifications.getPeopleForRecipients(
            this.attachedTimelineItem.getNotificationsPolicy,
            this.getPeopleDxByProjectId(this.projectId),
            this.attachedTimelineItem.getAuthor,
            this.getUser.getUsername,
            this.attachedTimelineItem.getId
          );
        }
      },
      transferSelect(ev) {
        this.$refs.docsAttach.handleSelect(ev);
      },
      transferDrop(ev) {
        this.$refs.docsAttach.handleDrop(ev);
      },
      async saveDocs(bvModalEvent): Promise<void> {
        bvModalEvent.preventDefault();
        this.loading = true;
        this.saveDocsAttached = true;
      },
      async docsUploadedHandler() {
        await this.sendNotificationDx();
        this.$nextTick(async () => {
          await this.$refs[this.id].hide();
        });
      },
      resetValues(): void {
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    watch: {
      attachedTimelineItemTreeselectModal: function () {
        if (!this.attachedTimelineItemTreeselectModal) {
          // Resets treeselect model after clearing the treeselect selection
          this.attachedTimelineItem = undefined;
        } else {
          if (this.timelineItemTypeFromSubtype === "event")
            this.attachedTimelineItem = this.getEventDx(
              this.attachedTimelineItemTreeselectModal
            );
          else if (this.timelineItemTypeFromSubtype === "stage")
            this.attachedTimelineItem = this.getStageDx(
              this.attachedTimelineItemTreeselectModal
            );
          else
            this.attachedTimelineItem = this.getRequestDx(
              this.attachedTimelineItemTreeselectModal
            );
        }
      },
      itemPreselected: function () {
        if (this.itemPreselected) {
          this.attachedTimelineItemTreeselectModal = this.itemPreselected.getId;
          this.attachedTimelineItem = this.itemPreselected;
          this.isTimelineItemSelection = true;
        } else {
          this.attachedTimelineItemTreeselectModal = undefined;
          this.isTimelineItemSelection = false;
        }

        if (this.eventId != undefined) {
          this.timelineItemSubtype = this.itemPreselected.getEventType;
        }

        if (this.stageId != undefined) {
          this.timelineItemSubtype = "stage";
        }

        if (this.requestId != undefined) {
          this.timelineItemSubtype = this.itemPreselected.getType;
        }
      }
    }
  };
