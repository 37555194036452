import axios from "axios";
import ProductCSP from "../../models/csp/ProductCSP";
import service from "../service";
import baseEndpointCSP from "./serviceCSP";
import baseEndpointPartnerCenter from "./servicePartnerCenter";

export default {
  methods: {
    getProducts: async function (type: string) {
      const endpoint = `${baseEndpointPartnerCenter}/products?country=es&targetView=${type}`;
      return await axios
        .get(endpoint)
        .then(response => {
          return response.data.items.map(i => {
            return new ProductCSP({
              id: i.id,
              title: i.title,
              skusLink: i.links.skus.uri,
              skusList: [],
              productType: i.productType.id
            });
          });
        })
        .catch(error => {
          return error;
        });
    },
    getProductSkus: async function (product) {
      const endpoint =
        baseEndpointPartnerCenter + `/products/${product}/skus?country=es`;
      return await axios.get(endpoint).then(response => {
        return response.data.items.map(i => {
          return {
            id: i.id,
            productId: i.productId,
            title: i.title,
            description: i.description,
            minimumQuantity: i.minimumQuantity,
            maximumQuantity: i.maximumQuantity,
            catalogItemId: null
          };
        });
      });
    },
    getProductAvailabilities: async function (productId, skuId) {
      const endpoint =
        baseEndpointPartnerCenter +
        `/products/${productId}/skus/${skuId}/availabilities?country=es`;
      return await axios.get(endpoint).then(response => {
        return {
          catalogItemId: response.data.items[0].catalogItemId,
          terms: response.data.items[0].terms.map(t => {
            return { duration: t.duration, billingCycle: t.billingCycle };
          })
        };
      });
    },
    getProductPrice: async function (productData: {
      productId: string;
      skuId: string;
      billingCycle: string;
      termDuration: string;
    }) {
      const endpoint =
        baseEndpointCSP +
        `/Pricesheet?productId=${productData.productId}&skuId=${productData.skuId}&billingCycle=${productData.billingCycle}&termDuration=${productData.termDuration}`;
      const response = await service.methods.getEndpointAsync(endpoint);
      return response;
    }
  }
};
