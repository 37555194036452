

































































































































  import {
    getTermByBilling,
    getTermByDuration,
    productTypes
  } from "../../../_helpers/cspOperations";
  import SingleSelectType from "../../../view-models/SingleSelectType";
  import CapsuleLabelInput from "../../view_elements/CapsuleLabelInput.vue";
  import CapsuleMultiselect from "../../view_elements/CapsuleMultiselect.vue";
  import ProductRow from "./product/ProductRow.vue";

  export default {
    name: "NewSubscriptions",
    components: { ProductRow, CapsuleMultiselect, CapsuleLabelInput },
    props: {
      id: String,
      tenantId: String,
      cartProducts: Array
    },
    data() {
      return {
        cartFields: [
          {
            label: this.$t("product"),
            key: "product",
            thClass: "cart-field-product",
            tdClass: "cart-field-product"
          },
          {
            label: this.$t("quantity"),
            key: "quantity",
            thClass: "cart-field-quantity align-middle",
            tdClass: "cart-field-quantity align-middle"
          },
          { label: "", key: "actions" }
        ],
        loadingProducts: true,
        searchProduct: "",
        productTypeSelected: new SingleSelectType({
          key: productTypes.onlineServices.key,
          name: productTypes.onlineServices.name
        }),
        text: {
          addSubscriptions: this.$t("addSubscriptions"),
          emptyCart: this.$t("emptyCart"),
          cart: this.$t("cart"),
          reviewOrder: this.$t("reviewOrder"),
          close: this.$t("close"),
          search: this.$t("search"),
          type: this.$t("type"),
          searchProduct: this.$t("searchProduct"),
          selectType: this.$t("selectType"),
          noProductsMatchSearch: this.$t("noProductsMatchSearch")
        }
      };
    },
    async created() {
      await this.loadProductsCSPByType(this.productTypeSelected.key);
      this.loadingProducts = false;
    },
    methods: {
      addProductToCart(productSku, quantity: number) {
        const billing = productSku.terms[0].billingCycle.toLowerCase();
        const duration = productSku.terms[0].duration;

        const productSkuMapped = {
          ...productSku,
          billing: new SingleSelectType({
            key: billing,
            name: this.$t(getTermByBilling(billing).i18n)
          }),
          duration: new SingleSelectType({
            key: duration,
            name: this.$t(getTermByDuration(duration).i18n)
          }),
          quantity: quantity
        };
        this.$root.$emit("addToCart", productSkuMapped);
      },
      removeProductFromCart(product) {
        this.$root.$emit("deleteFromCart", product);
      },
      goToOrderReview() {
        this.$root.$emit("reviewNewProducts");
      },
      resetValues() {
        Object.assign(this.$data, this.$options.data.apply(this));
      }
    },
    computed: {
      productTypesOptions(): SingleSelectType[] {
        const types = [];
        for (const productType of Object.values(productTypes)) {
          types.push(
            new SingleSelectType({
              key: productType.key,
              name: productType.name
            })
          );
        }
        return types;
      },
      productTypesTooltips() {
        const tooltips = {};
        for (const productType of Object.values(productTypes)) {
          tooltips[productType.key] = this.$t(productType.i18nDesc);
        }
        return tooltips;
      },
      productsFiltered(): boolean {
        return this.getProductsCSP.filter(p => {
          if (
            this.searchProduct &&
            !new String(p.getTitle.toLowerCase()).includes(
              this.searchProduct.toLowerCase()
            )
          ) {
            return false;
          }
          return true;
        });
      }
    },
    watch: {
      productTypeSelected: async function () {
        if (this.productTypeSelected) {
          // To prevent console errors on reselect same item
          this.loadingProducts = true;
          await this.loadProductsCSPByType(this.productTypeSelected.key);
          this.loadingProducts = false;
        }
      }
    }
  };
