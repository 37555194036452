import PendingOperationCSP from "../../models/csp/PendingOperationCSP";
import service from "../service";
import baseEndpoint from "./serviceCSP";

const endpoint = baseEndpoint + "/PendingOperation";

export default {
  components: { service: service },
  methods: {
    async getPendingOperations(): Promise<PendingOperationCSP[]> {
      const response = await service.methods.getEndpointAsync(endpoint);
      return response.map(
        pendingOperation => new PendingOperationCSP(pendingOperation)
      );
    },

    async getPendingOperationBySubscriptionId(
      subscriptionId: string
    ): Promise<PendingOperationCSP> {
      const response = await service.methods.getEndpointAsync(
        `${endpoint}/${subscriptionId}`
      );
      return response.hasOwnProperty("subscriptionId")
        ? new PendingOperationCSP(response)
        : null;
    },
    async postPendingOperation(
      pendingOperation: PendingOperationCSP
    ): Promise<PendingOperationCSP> {
      const response = await service.methods.postEndpointAsync(
        endpoint,
        pendingOperation
      );
      return new PendingOperationCSP(response);
    },
    async putPendingOperation(
      pendingOperation: PendingOperationCSP
    ): Promise<PendingOperationCSP> {
      const response = await service.methods.putEndpointAsync(
        `${endpoint}/${pendingOperation.getSubscriptionId}`,
        pendingOperation
      );
      /**
       * Pending operation may have been deleted if sent with reset values
       * after CSP subcription values had matched. So response may have
       * returned empty, therefore we pass null back to the view.
       * */
      return response ? new PendingOperationCSP(response) : null;
    }
  }
};
